<ng-template #saveReportTemplate> 
	<div class="container-fluid bold">
        <div >
            <form [formGroup]="saveReportForm"> 
                <br> <br> 
                <div class="form-group row">
                    <label for="reportName" class="col-sm-4 col-form-label">Report Name </label>
                    <div class="col-sm-8">
                            <input type="text" class="form-control bold"  name="reportName" 
                            formControlName="reportName"  id="reportName" [(ngModel)]="reportName"> 
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-0">
                        <button type="button" class="btn btn-primary btn-sm" (click)="saveReportBtnClickEvent()"> <i class="far fa-save"></i>  Save Report</button>
                    </div>  
                    <div class="form-group col-md-0">
                        <button type="button" class="btn btn-primary btn-sm" (click)="canceltemplateClickEvent()"> <i class="far fa-times"></i> Cancel</button>
                    </div>
                </div>  
            </form>
        </div>
    </div>
</ng-template>

<ng-template #searchResultTemplate> 
	<div class="container-fluid bold">
        <div >
            <form [formGroup]="searchResultForm"> 
                <br> <br>
                <p>Search results for {{ currentticker.Ticker | translate}}</p>
                <div class="form-group row">
                    <!-- <label for="selectedTickers" class="col-sm-4 col-form-label">Recommended Proxies</label> -->
                    <div class="col-sm-12">
                            <select  size="10"  class="form-control bold" id="searchresults" formControlName="searchresults"   >
                                <option *ngFor="let item of TiingoTickerInfo" [ngValue]="item.ticker" > 
                                    {{ item.ticker + ' : ' + item.name }}
                                </option>
                            </select> 
                    </div> 
                </div>
                <div class="form-row">
                    <div class="form-group col-md-0">
                        <button type="button" class="btn btn-primary btn-sm" (click)="selectResultBtnClickEvent()"> <i class="far fa-check-circle"></i> Select Ticker</button>
                    </div>  
                    <div class="form-group col-md-0">
                        <button type="button" class="btn btn-primary btn-sm" (click)="canceltemplateClickEvent()"> <i class="far fa-times"></i> Cancel</button>
                    </div>
                </div>  
            </form>
        </div>
    </div>
</ng-template>

<ng-template #recommendedProxyTemplate>
	<div class="container-fluid bold">
        <div>
            <form [formGroup]="recommendedProxyForm"> 
                <br> <br>
                <p>{{ currentticker.Ticker | translate}} - Symbol doesn't exist. Please select a proxy</p>
                <div class="form-group row">
                    <label for="selectedTickers" class="col-sm-4 col-form-label">Recommended Proxies</label>
                    <div class="col-sm-8">
                            <select  size="6"  class="form-control bold" id="recommendedTickers" formControlName="recommendedTickers"   >
                                <option *ngFor="let item of recommendedproxies" [ngValue]="item.Proxy" >
                                    {{item.Proxy}}
                                </option>
                            </select> 
                    </div> 
                </div>
                <div class="form-row">
                    <div class="form-group col-md-0">
                        <button type="button" class="btn btn-primary btn-sm" (click)="selectProxyBtnClickEvent()"> <i class="far fa-check-circle"></i> Select Proxy</button>
                    </div> 
                    <div class="form-group col-md-0">
                        <button type="button" class="btn btn-primary btn-sm" (click)="addProxyBtnClickEvent(addProxyTemplate)"> <i class="fas fa-plus"></i> Add Proxy</button>
                    </div> 
                    <div class="form-group col-md-0">
                        <button type="button" class="btn btn-primary btn-sm" (click)="canceltemplateClickEvent()"> <i class="far fa-times"></i> Cancel</button>
                    </div>
                </div>  
            </form>
        </div>
    </div>
</ng-template>

<ng-template #addProxyTemplate>
	<div class="container-fluid bold">
        <div>
            <br> 
            <div *ngIf="showMsg">
                <p>{{ currentticker.Ticker | translate}} - Symbol doesn't exist. Please add a proxy</p>
            </div>
            <br>
            <form [formGroup]="addProxyForm">
                <div class="form-group row">
                    <label for="tickerSymbol" class="col-sm-4 col-form-label">Ticker Symbol</label>
                    <div class="col-sm-8">
                            <input type="text" class="form-control bold"  name="tickerSymbol" 
                            formControlName="tickerSymbol"  id="tickerSymbol" [(ngModel)]="currentticker.Ticker"
                            (ngModelChange)="TickerToUppercase($event)" > 
                    </div>
                </div>

                <div class="form-group row">
                    <label for="tickerSymbol" class="col-sm-4 col-form-label">Proxy Symbol</label>
                    <div class="col-sm-8">
                            <input type="text" class="form-control bold"  name="proxySymbol" 
                            formControlName="proxySymbol"  id="proxySymbol"  [(ngModel)]="currentticker.Proxy"
                            (ngModelChange)="ProxyToUppercase($event)" > 
                    </div>
                </div>

                <div class="form-group row">
                    <label for="tickerSymbol" class="col-sm-4 col-form-label">Notes</label> 
                    <div class="col-sm-8">
                        <textarea class="form-control bold"  rows="6"
                                name="comment" 
                                formControlName="comment"  id="comment"  [(ngModel)]="currentticker.Notes" ></textarea> 
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-0">
                        <button type="button" class="btn btn-primary btn-sm"
                            (click)="saveProxyBtnClickEvent()"> <i class="far fa-save"></i> Save</button>
                    </div>
                    <div class="form-group col-md-0">
                        <button type="button" class="btn btn-primary btn-sm"
                            (click)="canceltemplateClickEvent()"> <i class="far fa-times"></i> Cancel</button>
                    </div>
                </div>  
            </form>
        </div>
    </div>
</ng-template>

<div class="main-panel pt-0 pb-4" >

	<div class="container-fluid bold">
		<div class="col-sm-12 px-0">
			<h3 class="light-header bold">
                Risk Graph
                <button type="button" class="btn btn-primary btn-sm float-right" (click)="exitBtnClickEvent()">
					<i class="fas fa-sign-out"></i> Exit
                </button>
                <button type="button" class="btn btn-primary btn-sm mr-1 float-right" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
                    {{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
                </button>
			</h3>
            <hr class="mt-0 mb-1 pb-3" /> 
			
			<form [formGroup]="riskGraphForm"  >
                <div class="container border rounded p-3">     
                    <div class="form-group row">
                            <label for="tickerSymbol" class="col-sm-2 col-form-label">Ticker Symbol</label>
                            <div class="col-sm-6">
                                    <input type="text" class="form-control bold"  name="tickerSymbol" 
                                    formControlName="tickerSymbol"  id="tickerSymbol" [(ngModel)]="currentticker.Ticker"
                                    (ngModelChange)="TickerToUppercase($event)" >  
                            </div>
                            <div class="form-group col-md-0">
                                    <!-- <button type="button" class="btn btn-primary btn-md btn-w-200" >Select Symbol</button> -->
                                    <button type="button" class="btn btn-primary btn-sm" style=" width: 130px;"  (click)="selectBtnClickEvent()"> <i class="far fa-check-circle"></i> Select Symbol</button>                                   
                            </div> &nbsp;
                            <div class="form-group col-md-0">
                                <button type="button" class="btn btn-primary btn-sm" style=" width: 130px;"  (click)="searchSymbolBtnClickEvent()"> <i class="fas fa-search"></i> Search Symbol</button>
                            </div>
                    </div>
                    
                    <div class="form-group row">
                        <label for="selectedTickers" class="col-sm-2 col-form-label">Selected Tickers</label>
                        <div class="col-sm-6">
                                <select  size="6" class="form-control bold" id="selectedTickers" formControlName="selectedTickers"  >
                                        <!-- <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option> -->

                                        <option *ngFor="let item of selectedproxies" [ngValue]="item.Proxy" class="bold"> 
                                                {{item.Proxy}}
                                        </option>
                                </select>
                        </div>
                        <div > 
                            <button type="button" class="btn btn-primary btn-sm "  style=" width: 130px; margin-bottom: 3px;" (click)="addProxyBtnClickEvent(addProxyTemplate,false)"> <i class="fas fa-plus"></i> Add Proxy</button><br>  
                            <button type="button" class="btn btn-primary btn-sm " style=" width: 130px;  " (click)="deleteBtnClickEvent()"> <i class="fas fa-minus"></i>  Delete Symbol</button>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-2 col-form-label">Time Period From</label>
                        <div class="col-sm-2">
                                <input type="text" class="form-control  bold" bsDatepicker id="1" 
                                [bsConfig]="{containerClass:'theme-default'}" (bsValueChange)="onFromDateChange($event)"
                                [bsValue]= "fromDate"    value="{{ fromDate | date:'MM/dd/yyyy' }}">
                        </div>   
                        <label for="inputPassword" class="col-sm-2 col-form-label text-right">To</label>
                        <div class="col-sm-2">
                                <input type="text" class="form-control bold" bsDatepicker id="2" 
                                [bsConfig]="{containerClass:'theme-default'}" (bsValueChange)="onToDateChange($event)"
                                [bsValue]= "toDate"    value="{{ toDate | date:'MM/dd/yyyy' }}">
                        </div>
                    </div>
        
        
                    <div class="form-group row">
                        <label for="noofDropDowns" class="col-sm-2 col-form-label">No. Of Drawdowns</label>
                        <div class="col-sm-6">
                                <input type="text" class="form-control bold"   name="noofDropDowns" 
                                formControlName="noofDropDowns"  id="noofDropDowns" [(ngModel)] = "noOfDrawdowns" > 
                        </div> 
                    </div> 

                    <div class="form-group row">
                        <label for="scale" class="col-sm-2 col-form-label">Scale</label>
                        <div class="col-sm-2">     
                            <input type="radio" name="scale" formControlName="scale" value="2" [checked]="(this.bLogarithmic == 2 )"
                                  > <label for="Arithmetic" class="col-sm-2 col-form-label"> Arithmetic</label> 
                        </div>
                        <div class="col-sm-2">
                            <input type="radio" name="scale" formControlName="scale" value="1" [checked]="(this.bLogarithmic == 1 )"
                                   > <label for="Logarithmic" class="col-sm-2 col-form-label"> Logarithmic</label>  	
                        </div>	 
                    </div>
                </div>  
                <div class="container border rounded p-3">       
                    <br/>
                    <h5 class="light-header bold">
						Custom Drawdowns
					</h5>
					<hr class="mt-0 mb-3" />
                    <!-- <hr class="mt-0 mb-1 pb-3" />     -->
                     
                    <div class="form-group row">
                        <label for="LTCoption" class="col-sm-2 col-form-label">Add Auto Generated</label>
                        <div class="col-sm-1">
                            <input type="radio" name="addAutoGenerated" value="true"  formControlName="addAutoGenerated"     [checked]="(this.bAddAutoGenerated == 1 )"
                                   > <label for="Yes" class="col-sm-1 col-form-label"> Yes</label>  	
                                </div>
                        <div class="col-sm-1">     
                            <input type="radio" name="addAutoGenerated" value="false"  formControlName="addAutoGenerated"    [checked]="(this.bAddAutoGenerated == 0 )"
                                  > <label for="No" class="col-sm-1 col-form-label"> No </label> </div>	 
                    </div>

                    <div class="form-group row"> 
                        <label for="inputPassword" class="col-sm-2 col-form-label">Start</label>
                        <div class="col-sm-2">
                                <input type="text" class="form-control  bold" bsDatepicker id="1" 
                                [bsConfig]="{containerClass:'theme-default'}" (bsValueChange)="onFromDateCustomChange($event)"
                                [bsValue]= "fromDateCustom"    value="{{ fromDateCustom | date:'MM/dd/yyyy' }}">
                        </div>   
                        <label for="inputPassword" class="col-sm-2 col-form-label text-right">End</label>
                        <div class="col-sm-2">
                                <input type="text" class="form-control bold" bsDatepicker id="2" 
                                [bsConfig]="{containerClass:'theme-default'}" (bsValueChange)="onToDateCustomChange($event)"
                                [bsValue]= "toDateCustom"    value="{{ toDateCustom | date:'MM/dd/yyyy' }}">
                        </div>

                        <div>
                            <button type="button" class="btn btn-primary btn-sm btn-sm " style=" width: 130px;"  (click)="selectDrawdownBtnClickEvent()"> <i class="far fa-check-circle"></i> Add </button>
                        </div>
                    </div> 

                    <div class="form-group row">
                        <label for="selectedDrawdowns" class="col-sm-2 col-form-label">Custom Drawdowns</label>
                        <div class="col-sm-6">
                                <select  size="6" class="form-control bold" id="selectedDrawdowns" formControlName="selectedDrawdowns"  >
                                    <option *ngFor="let item of customDrawdowns" [ngValue]="item" class="bold"> 
                                                {{item}}
                                    </option>
                                </select>
                        </div>
                        <div > 
                            <!-- <button type="button" class="btn btn-primary btn-sm "  style=" width: 130px; margin-bottom: 3px;" (click)="addProxyBtnClickEvent(addProxyTemplate,false)"> <i class="fas fa-plus"></i> Add Proxy</button><br>   -->
                            <button type="button" class="btn btn-primary btn-sm " style=" width: 130px;  " (click)="deleteDrawdownBtnClickEvent()"> <i class="fas fa-minus"></i>  Delete </button>
                        </div>
                    </div>
                </div>     
                <div class="container border rounded p-3">         
                    <div class="form-row">
                        <div class="form-group col-md-0">
                            <button type="button" class="btn btn-primary btn-sm" (click)="generateReportBtnClickEvent()">  <i class="far fa-file"></i>  Generate Report</button>
                            <!-- <button type="button" class="btn btn-primary btn-md" > Generate Report</button> -->
                        </div> 
                        <div class="form-group col-md-0">
                            <button type="button" class="btn btn-primary btn-sm" (click)="printReportBtnClickEvent()">  <i class="far fa-file-pdf"></i>  Print Report</button>
                        </div> 
                        <div class="form-group col-md-0">
                            <button type="button" class="btn btn-primary btn-sm" (click)="openReportNamePopup()">  <i class="far fa-save"></i>  Save Report</button>
						</div> 
                        <div class="form-group col-md-0">
                            <button type="button" class="btn btn-primary btn-sm" (click)="cancelBtnClickEvent()"> <i class="far fa-times"></i> Cancel</button>
                        </div>
                    </div> 
                </div> 
			</form> 
		</div>
    </div> 
</div>