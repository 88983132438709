export class BusinessCalculatorInput {
    constructor( 
        public BusinessPlanID?: string,
        public BusinessPlanName?: string,
        public OrganizationID?: string,
        public OwnerID?: string,
        public NoOfYears?: Number,
        public StartingCash?: Number,
        public StartDate?: string, 
        public BusinessInsurances?: Array<BusinessInsurance>,
        public EmployeeDetails?: Array<EmployeeDetail>,
        public PayrollTaxes?: PayrollTaxes,
        public OtherPayrollItems?: OtherPayrollItems,
        public Rent?: Array<RentPerYear>,
        public FinancialProducts?: FinancialProducts,
        public RepData?: Array<RepData>,
        public OtherExpenses?: OtherExpenses,
        public OwnersTake?: OwnersTake,
        public OtherRevenue?: Array<OtherRevenuePerYear>
    ) {}
}

export class BusinessInsurance {
    constructor( 
        public BusinessPlanID?: string,
        public InsuranceID?: string,
        public Year?: string,
        public EAndOInsurance?: Number,
        public BusinessLiabilityInsurance?: Number,
        public WorkersCompensationInsurance?: Number,
        public OtherInsuranceCosts?: Number
    ) {}
}

export class EmployeeDetail {
    constructor( 
        public EmployeeTypeID?: string,
        public MoreInfo?: string,
        public EmployeeCounts?: Array<EmployeeCount>,
        public Employees?: Array<Employee>
    ) {}
}

export class Employee{
    constructor(
        public EmployeeID?: string,
        public EmployeeDetailID?: string,
        public EmployeeTypeID?: string,
        public RepDataID?: string,
        public EmployeeFirstName?: string,
        public EmployeeLastName?: string,
        public AnnualSalary?: Number,
        public StartYear?: string,
        public StartMonth?: string,
        public StartMonthInWords?: string,
        public EndYear?: string,
        public EndMonth?: string,
        public EndMonthInWords?: string,
        public EmptyEndYear?: boolean,
        public Description?: string,
    ) {}
}

export class EmployeeCount {
    constructor( 
        public EmployeeDetailID?: string,
        public EmployeeCountID?: string,
        public Year?: string,
        public Month?: string,
        public EmpCount?: Number
    ) {}
}

export class PayrollTaxes {
    constructor( 
        public SocialSecurity?: Number,
        public Medicare?: Number,
        public FederalUnemployment?: Number,    
        public StateUnemployment?: Number
    ) {}
}

export class OtherPayrollItems {
    constructor( 
        public COLAIncrease?: Number,
        public StaffPerformanceBonusPool?: Number,
        public InsuranceBenefits?: Number,
        public RetirementBenefits?: Number
    ) {}
}

export class RentPerYear {
    constructor( 
        public Year?: string,
        public TotalSquareFootage?: Number,
        public Cost?: Number
    ) {}
}

export class FinancialProducts {
    constructor( 
        public FinancialProductID?: string,
        public TotalAUMFee?: Number,
        public ExistingAUM?: Number,
        public FinancialProductDetails?: Array<FinancialProductDetail>
    ) {}
}

export class FinancialProductDetail {
    constructor( 
        public FinancialProductDetailID?: string,
        public FinancialProductID?: string,
        public AnnuityName?: string,
        public TotalAUMFee?: Number,
        public CommissionpercentageOnANN?: Number,
        public TrailPercentage?: Number,
        public TrailYears?: Number,
		public ProductSalesMix?: Number,
    ) {}
}

export class RepData {
    constructor( 
        public RepDataID?: string,
        public AgentID?: string,
        public IsOwner?: Boolean,
        public bAddedToEmployee?: Boolean,
        public RepFirstName?: string,
        public RepLastName?: string,
        public BaseSalary?: Number,
        public RepStartDate?: Date,
        public RepEndDate?: Date,
        public RepPayPercentage?: Number,
        public RepDataDetails?: Array<RepDataPerYear>,
        public RepDataAddtionalChannels?: Array<RepDataAddtionalChannel>,
    ) {}
}

export class RepDataPerYear {
    constructor( 
        public Year?: string,
        public Month?: string,
        public ClassesPerMonth?: Number,
        public MailDropPerMonth?: Number,
        public CostPerMailer?: Number,
        public CostPerEvent?: Number,
        public TotalCostPerMonth?: Number,
        public TotalBURegisteredPerClass?: Number,
        public CancelRate?: Number,
        public TotalAttendees?: Number,
        public BookingRate?: Number,
        public NumberOfFirstAppts?: Number,
        public CloseRate?: Number,
        public FirstApptsConvertingToClose?: Number,
        public ClosesPerMonth?: Number,
        public TotalAssetsPerClose?: Number,
        public PercentOfAssetsToANN?: Number,
        public AUMPerClose?: Number,
        public ANNPerClose?: Number,
        public FeePerClientPerMonth?: Number,
        public TrailYears?: Number,
        public ANNCommRevPerClose?: Number,
        public ANNTrailPerYear?: Number,
        public AUMFeePerMonth?: Number,
        public ANNCommPerMonth?: Number,
        public ANNTrailForFutureYearsPerMonth?: Number,

        public NoOfNewClients?: Number,
        public MonthlyAUMSales?: Number,
        public MonthlyANNSales?: Number,
        public TotalClients?: Number,
        public TotalAUMSales?: Number,
        public TotalANNSales?: Number,
        public ANNComms?: Number,
        public AUMFees?: Number,
        public ANNTrailsYear1?: Number,
        public ANNTrailsYear2?: Number,
        public TotalIncome?: Number,
        public RepANNComms?: Number,
        public RepAUMFees?: Number,
        public RepANNTrailsYear1?: Number,
        public RepANNTrailsYear2?: Number,
        public TotalRepPay?: Number
    ) {}
}

export class RepDataAddtionalChannel {
    constructor( 
        public RepDataAdditionalChannelID?: string,
        public RepDataID?: string,
        public MarketingChannelID?: string,
        public ChannelExpenditure?: Number,
        public CostPerEvent?: Number,
        public TotalEventsPerMonth?: Number,
        public LeadsPerEvent?: Number,
        public LeadsConvertedToProspects?: Number,
        public TotalNewProspectsPerMonth?: Number,
        public NumberOfEW101Booked?: Number,
        public NumberOfEW101Attended?: Number,
        public FirstAppointmentBooked?: Number,
        public FirstAppointmentAttended?: Number,
        public SecondAppointmentBooked?: Number,
        public SecondAppointmentAttended?: Number,
        public ThirdAppointmentBooked?: Number,
        public ThirdAppointmentAttended?: Number,
        public Closed?: Number,
        public ClosingPercentage?: Number,
        public TotalAssetsPerClose?: Number,
        public PercentOfAssetsToANN?: Number,


        public AUMPerClose?: Number,
        public ANNPerClose?: Number,
        public FeePerClientPerMonth?: Number,
        public ANNTrailPerYear?: Number,
        public AUMFeePerMonth?: Number,
        public ANNCommPerMonth?: Number,
        public ANNTrailForFutureYearsPerMonth?: Number
    ) {}
}

export class OtherExpenses {
    constructor( 
        public ITBase?: Number,
        public ITPerEE?: Number,
        public LicensingBase?: Number,
        public LicensingRate?: Number,
        public Other?: Number,
        public ProServices?: Number,
        public SuppliesBase?: Number,
        public SuppliesPerEE?: Number,
        public ComplianceServices?: Number,
        public TravelMealsTraining?: Array<TravelMealsTrainingPerYear>
    ) {}
}

export class TravelMealsTrainingPerYear {
    constructor(  
        public Year?: string,
        public EventCost?: Number,
        public TrainingCost?: Number
    ) {}
}

export class OwnersTake {
    constructor(  
        public MonthToStartOwnersTake?: Number,
        public FirstYearMonthlyNETIncomeNeeds?: Number,
        public PercentageOfNetIncomes?: Array<PercentageOfNetIncomePerYear>,
        public FederalIncomeTax?: Number,
        public StateIncomeTax?: Number
    ) {}
}

export class PercentageOfNetIncomePerYear {
    constructor( 
        public Year?: string,
        public PercentageOfNetIncome?: Number
    ) {}
}

export class OtherRevenuePerYear {
    constructor( 
        public Year?: string,
        public Month?: string,
        public OtherRevenueAmount?: Number
    ) {}
}
 