
<div class="container-fluid">
    <button type="button" class="btn btn-primary btn-sm float-right mr-5 mt-4" (click)="exitBtnClickEvent()">
        <i class="fas fa-sign-out"></i> Exit
    </button>
    <button type="button" class="btn btn-primary btn-sm mr-1 float-right mt-4" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
      {{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
    </button>
	<div class="row">
       <div class="row fp4fa-wrapper h-100 w-100 p-4 m-0" [ngClass]="{'expand': status, 'resize': !status, 'status resize': status && resizestatus  }" >
        <nav aria-label="breadcrumb">
           <ol class="breadcrumb">
            <!--  <li class="breadcrumb-item"><a href="#">Home</a></li> -->
             <li class="breadcrumb-item active" aria-current="page">Company Configuration</li>
           </ol>
         </nav>
<div class="row w-100 m-0 mb-5 justify-content-center">
  <div class="col-md-10">
        <div class="row">
          <div class="col-md-12 bg-light text-center">
            <button type="button" class="btn btn-primary btn-sm  mr-1 at-right" (click)="AddBusinessPlan()"> <i class="far fa-plus"></i> Add New Business Plan</button>
            <button type="button" class="btn btn-primary btn-sm" (click)="EditBusinessPlan()"> <i class="far fa-edit"></i> Edit Existing Business Plan</button>
          </div> 
        </div>

       <!--  <div class="form-row">
            <div class="form-group col-md-0 float-right">
              <button type="button" class="btn btn-primary btn-sm" (click)="AddBusinessPlan()"> <i class="far fa-plus"></i> Add New Business Plan</button>
            </div>
            <div class="form-group col-md-0 float-right">
              <button type="button" class="btn btn-primary btn-sm" (click)="EditBusinessPlan()"> <i class="far fa-edit"></i> Edit Existing Business Plan</button>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6 bg-light">
            <div class="card widget mb-3 mt-3">
              <!--<div class="card-header">
                <div class="w-100 position-relative">
                  <h2 class="m-0 font-weight-bold ff-eagle title2 mt-2">Login</h2>
                  <p class="mb-2">COH user </p>
                </div>
              </div>-->
              <div class="card-body p-5">
                <form [formGroup]="iSForm">
                  <div class="form-group" [hidden] = "!addbusinessplan">
                    <label class="small mb-1" for="">Business Plan Name<i class="fas fa-question-circle ml-2 text-muted" tooltip="Name for the scenario to be illustrated.  This will be the name the scenario is saved under. "></i></label>
                    <input class="form-control py-4" formControlName="businessplanname" id="" type="text" [(ngModel)]="businessplanname"
                      placeholder="Enter name for the Business Plan" (keyup)="onBusinessPlanNameChange($event)">
                  </div>
                  <div class="form-group" [hidden] = "addbusinessplan">
                  <label class="small mb-1" for="">Business Plan Name<i class="fas fa-question-circle ml-2 text-muted" tooltip="Name for the scenario to be illustrated.  This will be the name the scenario is saved under. "></i></label>
                    <div class="form-row">
                      <select class="form-control col-sm-10 ml-1" width="100%" placeholder="Select Business Plan Name"
                      (change)=setBusinessPlanId($event) [(ngModel)]="existingbusinessplanid" formControlName="existingbusinessplanid">
                        <option *ngFor="let businessPlanname of businessPlansArray"
                        [value]="businessPlanname.id">
                          {{businessPlanname.text}}
                        </option>
                      </select>
                      <button class="btn btn-link green-link ml-3" tooltip ="Delete Selected Business Plan" type="button" (click)="deleteBusinessPlan()"><i class="far fa-trash-alt pr-1"></i></button>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="small mb-1" for="">No of Years<i class="fas fa-question-circle ml-2 text-muted" tooltip="Enter the number of years you would like to project the scenario for.  Whole numbers only. "></i></label>
                    <input class="form-control py-4" formControlName="noofyears" id="" type="text" [(ngModel)]="noofyears"
                      placeholder="Enter no of years to generate Business Planning Calculator" (keyup)="onNoOfYearsChange($event)">
                  </div>
                  <div class="form-group">
                    <label class="small mb-1" for="">Starting Cash ($)<i class="fas fa-question-circle ml-2 text-muted" tooltip="Amount of money to be invested in creating this business. "></i></label>
                    <input class="form-control py-4" formControlName="startingcash" id="" type="text" [(ngModel)]="startingcash"
                      placeholder="Enter amout of starting cash $" (keyup)="onStartingCash($event)">
                  </div>
                  <!-- <div class="mt-4">
                    <label class="small mb-1 text-muted" for=""><i class="fas fa-info-circle icon-1x pr-2"></i> Enter number of year which you want to generate Business Planning Calculator</label>
                  </div> -->
                  <!-- error field -->
                  <!--div class="form-group has-error">
                      <label class="small" for="inputError">Input with error</label>
                      <input type="text" class="form-control py-4" id="inputError">
                      <span class="help-block text-danger">Please correct the error</span>
                  </div-->
                  <!-- error field -->


                </form>
              </div>

            </div>
          </div>
        </div>
    <div class="form-group   mt-2 mb-0">
      <button class="btn  float-right common-btn ghoust-btn" (click)="LoadMarketingProduction()"> Continue <i class="fas fa-arrow-right pl-2"></i></button>
      <!--a class="btn btn-primary float-right" href="index.html">Login</a-->
    </div>
  </div>
</div>
</div>
</div>

<div class="bottom-bar d-flex hide" [ngClass]="{'visible': saveactionshow, 'notvisible': !saveactionshow  }">  
  <div class="row action-bar align-self-end position-relative">
    <span class="close d-block p-1" (click)="closeBottombar()"><i class="fal fa-times"></i></span>
    <div class="col-md-6">
      <label class="small mb-1 text-muted onelinelabel" for=""><i class="fas fa-info-circle icon-1x pr-2"></i> Click here to save your Business Plan.</label>
    </div>
    <div class="col-md-6">
      <button class="btn  float-right common-btn border-btn" (click)="SaveBusinessPlan(true)"> Save Business Plan </button>
    </div>
  </div> 
</div>
</div>

