export class Ftastrategy {
    constructor( 
        public FTAStrategyID?: string,
        public FTAStrategyIdentity?: string,
        public FTAStrategyName?: string,
        public BeginDate?: string,
        public SplitDate?: string,
        public SeparationName?: string,
        public Disclosure?: string,
        public CreatedBy?: string,
        public CreatedOn?: Date,
        public bDeleted?: any,
        public DeletedBy?: string,
        public DeletedOn?: Date
    ) {}
}

export class FTAStrategyDetail {
    constructor( 
        public FTAStrategyDetailID?: string,
        public FTAStrategyID?: string,
        public DataDate?: string,
        public DataValue?: string
    ) {}
}
