<div>
	<div class="py-2 px-0 mt-2">
		<ul class="col-sm-12 px-0 py-2 pb-5 list-group mt-2"> 
			<div *ngIf="moduleType==0">
				<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == componentNames.cli_inf, 'custom-list-item-dark': selectedMenuItem != componentNames.cli_inf}"
					(click)="sideItemClick(componentNames.cli_inf,'')" *ngIf="isShowClientInfo">
					<h6 class="flaot-left ml-4 mb-0 sidebar-list-text">
						{{ 'CLIINFO.LBL_TITLE' | translate}}
					</h6>
				</li>
			<!--	<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == componentNames.inc_sou, 'custom-list-item-dark': selectedMenuItem != componentNames.inc_sou}"
					(click)="sideItemClick(componentNames.inc_sou)">
					<h6 class="flaot-left ml-4 mb-0 sidebar-list-text">
						{{ 'INCSOU.LBL_TITLE' | translate}}
					</h6>
				</li>
				<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == componentNames.inc_fro_ass, 'custom-list-item-dark': selectedMenuItem != componentNames.inc_fro_ass}"
					(click)="sideItemClick(componentNames.inc_fro_ass)">
					<h6 class="flaot-left ml-4 mb-0 sidebar-list-text">
						{{ 'INCASS.LBL_TITLE' | translate}}
					</h6>
				</li>
				<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == componentNames.inc_lif_rep, 'custom-list-item-dark': selectedMenuItem != componentNames.inc_lif_rep}"
					(click)="sideItemClick(componentNames.inc_lif_rep)">
					<h6 class="flaot-left ml-4 mb-0 sidebar-list-text">
						{{ 'LONGRE.LBL_TITLE' | translate}}
					</h6>
				</li>
				-->
				<div *ngFor="let item of dsbList | orderBy : 'orderNum'; let i=index">
					<li class="list-group-item border-0 rounded-0 list-item-bg px-0 py-3 custom-list-item-dark list-cursor"
						(click)='expandDynamicItem(item)' *ngIf="isShowClientInfo">
						<div class="sidebar-list-text " aria-controls="commissioningSubMenu"
							[attr.aria-expanded]="(expandedDynItem != item.text)">
							<h6 *ngIf="editingDynItem != item.id" class="flaot-left ml-4 mb-0 sidebar-list-text">
								{{ item.text }}
								<i class="fa fa-chevron-down float-right mr-3"
									[ngClass]="{'animate-arrow-0': (expandedDynItem != item.text), 'animate-arrow-180': (expandedDynItem == item.text)}"
									aria-hidden="true"></i>
							</h6>
							<input *ngIf="editingDynItem == item.id" class="form-control form-control-sm col-sm-11 mx-1"
								type="text" [placeholder]="item.text" (click)="$event.stopPropagation();" 
								id="item_text_{{item.id}}">
							<div *ngIf="editingDynItem != item.id" class="ml-4 mb-0 mt-2">
								<button class="btn btn-sm btn-sidebar-icon mr-2 p-0"
									(click)="$event.stopPropagation();copyItem(item)"  tooltip ="Copy">
									<i class="fal fa-copy"></i>
								</button>
								<button class="btn btn-sm btn-sidebar-icon mx-2 p-0"
									(click)="$event.stopPropagation();editItem(item)" [disabled]="!item.canEdit" tooltip ="Edit">
									<i class="fal fa-edit"></i>
								</button>
								<button class="btn btn-sm btn-sidebar-icon mx-2 p-0"
									(click)="$event.stopPropagation();deleteItem(item)" [disabled]="!item.canDelete" tooltip ="Delete">
									<i class="fal fa-trash-alt"></i>
								</button>
								<button class="btn btn-sm btn-sidebar-icon mx-2 p-0" *ngIf="((dsbList.length > 1) && (i != 0)) && item.canMove"
									(click)="$event.stopPropagation();itemMoveUp(item)" tooltip ="Move Up">
									<i class="fal fa-arrow-up"></i>
								</button>
								<button class="btn btn-sm btn-sidebar-icon mx-2 p-0" *ngIf="((dsbList.length > 1) && (i != (dsbList.length - 1))) && item.canMove"
									(click)="$event.stopPropagation();itemMoveDown(item)" tooltip ="Move Down">
									<i class="fal fa-arrow-down"></i>
								</button>
							</div>
							<div *ngIf="editingDynItem == item.id" class="ml-4 mb-0 mt-2">
								<button class="btn btn-sm btn-sidebar-icon mr-2 p-0"
									(click)="$event.stopPropagation();exitEditMode(item)" tooltip ="Cancel">
									<i class="fal fa-times"></i>
								</button>
								<button class="btn btn-sm btn-sidebar-icon mr-2 ml-2 p-0"
									(click)="$event.stopPropagation();saveSideBarItem(item)" tooltip ="Save">
									<i class="fal fa-save"></i>
								</button>
							</div>
						</div>
					</li>
					
					<ul class="col-sm-12 px-0 py-0 list-group rounded-0" [collapse]="(expandedDynItem != item.text)"
						[isAnimated]="true" id="commissioningSubMenu" *ngIf="isShowClientInfo">


					<li class="list-group-item border-0 rounded-0 px-0 py-2 list-item-bg list-cursor"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == componentNames.inc_sou, 'custom-list-item-dark': selectedMenuItem != componentNames.inc_sou}"
					(click)="sideItemClick(componentNames.inc_sou,item.id)" *ngIf="isShowClientInfo">
						<h6 class="flaot-left mb-0 ml-5">
							{{ 'INCSOU.LBL_TITLE' | translate}}
						</h6>
					</li>

					<li class="list-group-item border-0 rounded-0 px-0 py-2 list-item-bg list-cursor"
						[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == componentNames.inc_fro_ass, 'custom-list-item-dark': selectedMenuItem != componentNames.inc_fro_ass}"
						(click)="sideItemClick(componentNames.inc_fro_ass,item.id)" *ngIf="isShowClientInfo">
						<h6 class="flaot-left mb-0 ml-5">
							{{ 'INCASS.LBL_TITLE' | translate}}
						</h6>
					</li>
					<li class="list-group-item border-0 rounded-0 px-0 py-2 list-item-bg list-cursor"
						[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == componentNames.inc_lif_rep, 'custom-list-item-dark': selectedMenuItem != componentNames.inc_lif_rep}"
						(click)="sideItemClick(componentNames.inc_lif_rep,item.id)" *ngIf="isShowClientInfo">
						<h6 class="flaot-left mb-0 ml-5">
							{{ 'LONGRE.LBL_TITLE' | translate}}
						</h6>
					</li>
					<!-- Hiding Wealth Report as per the Client request on 6/6/2022-->
					<!-- <li class="list-group-item border-0 rounded-0 px-0 py-2 list-item-bg list-cursor"
						[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == componentNames.wealth_rep, 'custom-list-item-dark': selectedMenuItem != componentNames.wealth_rep}"
						(click)="sideItemClick(componentNames.wealth_rep,item.id)" *ngIf="isShowClientInfo">
						<h6 class="flaot-left mb-0 ml-5">
							{{ 'WEALTHRE.LBL_TITLE' | translate}}
						</h6>
					</li> -->
					

					<!--
						<li class="list-group-item border-0 rounded-0 px-0 py-2 list-item-bg list-cursor"
							(click)="dynamicSideItemClick('dye_inc_sou', componentNames.inc_sou)"
							[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'dye_inc_sou', 'sub-menu-item-list': selectedMenuItem != 'dye_inc_sou'}">
							<h6 class="flaot-left mb-0 ml-5">
								{{ 'INCSOU.LBL_TITLE' | translate}}
							</h6>
						</li>
						<li class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor"
							(click)="dynamicSideItemClick('dye_inc_fro_ass', componentNames.inc_fro_ass)"
							[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'dye_inc_fro_ass', 'sub-menu-item-list': selectedMenuItem != 'dye_inc_fro_ass'}">
							<h6 class="flaot-left mb-0 ml-5">
								{{ 'INCASS.LBL_TITLE' | translate}}
							</h6>
						</li>
						<li class="list-group-item rounded-0 border-0 px-0 py-2 list-item-bg list-cursor"
							(click)="dynamicSideItemClick('dye_inc_lif_rep', componentNames.inc_lif_rep)"
							[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == 'dye_inc_lif_rep', 'sub-menu-item-list': selectedMenuItem != 'dye_inc_lif_rep'}">
							<h6 class="flaot-left mb-0 ml-5">
								{{ 'LONGRE.LBL_TITLE' | translate}}
							</h6>
						</li>

					-->
					</ul> 
				</div>
				<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
						[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == componentNames.ris_gra, 'custom-list-item-dark': selectedMenuItem != componentNames.ris_gra}"
						(click)="sideItemClick(componentNames.ris_gra,'')" *ngIf="isShowRiskInvestmentGraphs">
						<h6 class="flaot-left ml-4 mb-0 sidebar-list-text">
							{{ 'RISKGRAPH.LBL_TITLE' | translate}}
						</h6>
				</li>	
				<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
						[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == componentNames.inv_rep, 'custom-list-item-dark': selectedMenuItem != componentNames.inv_rep}"
						(click)="sideItemClick(componentNames.inv_rep,'')" *ngIf="isShowNewInvestmentReport">
						<h6 class="flaot-left ml-4 mb-0 sidebar-list-text">
							{{ 'INVREP.LBL_TITLE' | translate}}
						</h6>
				</li>	
				<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
						[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == componentNames.old_inv_rep, 'custom-list-item-dark': selectedMenuItem != componentNames.old_inv_rep}"
						(click)="sideItemClick(componentNames.old_inv_rep,'')" *ngIf="isHideOldInvestmentReport">
						<h6 class="flaot-left ml-4 mb-0 sidebar-list-text">
							{{ 'OLDINVREP.LBL_TITLE' | translate}}
						</h6>
				</li>	

			</div>
			<div  *ngIf="moduleType==1" >
				<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == componentNames.dashboard, 'custom-list-item-dark': selectedMenuItem != componentNames.dashboard}"
					(click)="sideItemClick(componentNames.dashboard,'')">
					<h6 class="flaot-left ml-4 mb-0 sidebar-list-text">
						{{ 'FP4FA.DASHBOARD_LBL_TITLE' | translate}}
					</h6>
				</li>
				<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == componentNames.company_config, 'custom-list-item-dark': selectedMenuItem != componentNames.company_config}"
					(click)="sideItemClick(componentNames.company_config,'')">
					<h6 class="flaot-left ml-4 mb-0 sidebar-list-text">
						{{ 'FP4FA.CONFIG_LBL_TITLE' | translate}}
					</h6>
				</li>	
				<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == componentNames.marketing_production, 'custom-list-item-dark': selectedMenuItem != componentNames.marketing_production}"
					(click)="sideItemClick(componentNames.marketing_production,'')">
					<h6 class="flaot-left ml-4 mb-0 sidebar-list-text">
						{{ 'FP4FA.MARKETING_PRODUCTION_LBL_TITLE' | translate}}
					</h6>
				</li>
				<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == componentNames.revenue, 'custom-list-item-dark': selectedMenuItem != componentNames.revenue}"
					(click)="sideItemClick(componentNames.revenue,'')">
					<h6 class="flaot-left ml-4 mb-0 sidebar-list-text">
						{{ 'FP4FA.REVENUE_LBL_TITLE' | translate}}
					</h6>
				</li>
				<li class="list-group-item border-0 rounded-0 px-0 py-3 list-item-bg list-cursor"
					[ngClass]="{'sidebar-item-active-dark': selectedMenuItem == componentNames.expense, 'custom-list-item-dark': selectedMenuItem != componentNames.expense}"
					(click)="sideItemClick(componentNames.expense,'')">
					<h6 class="flaot-left ml-4 mb-0 sidebar-list-text">
						{{ 'FP4FA.EXPENSE_LBL_TITLE' | translate}}
					</h6>
				</li>
						
			</div> 
		</ul>

		<div *ngIf="isShowFP4FA" class="position-absolute bottom-align p-2 w-100" style="position: fixed !important;bottom: 0px;
		right: 0px;z-index: 12;background-color: #343a40;">  
			<div  *ngIf="moduleType==0">
				<button class="btn  float-right common-btn border-btn w-100" (click)="loadModule()"> FP4FA </button>
			</div>
			<div  *ngIf="moduleType==1">
				<button class="btn  float-right common-btn border-btn w-100" (click)="loadModule()"> Retirement plans </button>
			</div>
		</div> 
	</div>
</div>