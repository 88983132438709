<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg">
	<app-header></app-header>
</nav>
<div class="main-panel pt-0 pb-4">
    <div class="container-fluid" #main [style.padding-top.px]="paddingtop">
        <div class="col-sm-12 px-0 align-self-end">
          <div class="row align-items-center">
            <div class="col-sm-9">
              <h3 class="light-header bold" [hidden] = "isInvestmentGraph">
                {{ 'INVESTMENT_REPORT.LBL_TITLE' | translate}}
              </h3>
              <h3 class="light-header bold" [hidden] = "isRiskGraph">
                {{ 'RISK_GRAPH_REPORT.LBL_TITLE' | translate}}
              </h3>
              <h3 class="light-header bold" [hidden] = "isArchived">
                {{ 'ARCHIVED_OLD_INVESTMENT_REPORT.LBL_TITLE' | translate}}
              </h3>
            </div>
            <div class="col-sm-3">
              <button type="button" class="btn btn-primary btn-sm float-right" (click)="exitBtnClickEvent()">
                <i class="fas fa-sign-out"></i> Exit
              </button>
              <button type="button" class="btn btn-primary btn-sm mr-1 float-right" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
                {{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
              </button>
            </div>
          </div>
          <hr class="mt-0 mb-3" />
        </div>
        <div class="pt-3">
          <div class="container border rounded p-3 bold"> 
          <!--Blend List Start-->
          <h5 class="light-header bold" [hidden] = "isInvestmentGraph">
            {{ 'INVESTMENT_REPORT.LBL_TABLE_TITLE' | translate}}
          </h5>
          <h5 class="light-header bold" [hidden] = "isRiskGraph">
            {{ 'RISK_GRAPH_REPORT.LBL_TABLE_TITLE' | translate}}
          </h5>
          <h5 class="light-header bold" [hidden] = "isArchived">
            {{ 'ARCHIVED_OLD_INVESTMENT_REPORT.LBL_TABLE_TITLE' | translate}}
          </h5>
          <hr class="mt-0 mb-0" />
          
          <div class="pt-3">
              <form [formGroup]="iSForm">
               <div class="form-group row" [hidden] = "!isArchived">
                <label for="ClientName" class="col-sm-2 col-form-label">Client Name</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control bold" id="clientnamefilter" autocomplete="off"
                  formControlName="clientnamefilter" [(ngModel)]="clientnamefilter" (keyup)="searchBtnClickEvent()"/> 
                </div>
              </div>
              <div class="form-group row">
                <label for="ReportName" class="col-sm-2 col-form-label">Report Name</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control bold" id="reportnamefilter" autocomplete="off"
                  formControlName="reportnamefilter" [(ngModel)]="reportnamefilter" (keyup)="searchBtnClickEvent()"/> 
                </div>
                <div class="col-sm-5">
                  <div class="form-row">
                    <!-- <div class="form-group col-md-0">
                      <button type="button" class="btn btn-primary btn-sm" (click)="searchBtnClickEvent()"> <i class="far fa-search"></i> Search</button>
                    </div> -->
                    <div class="form-group col-md-0">
                      <button type="button" class="btn btn-primary btn-sm" (click)="cancelBtnClickEvent()"> <i class="far fa-times"></i> Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
              </form>
              
              <div [hidden] = "!isArchived">
              <button type="button" class="btn btn-primary btn-sm" (click) = "viewSeparately()"> <i class="far fa-file"></i> View Separately
              </button>
              &nbsp;
              <button type="button" class="btn btn-primary btn-sm" (click) = "viewCombined()"> <i class="far fa-file"></i> View Combined
              </button>
              &nbsp;
              <button type="button" class="btn btn-primary btn-sm" (click) = "printSeparately()"> <i class="far fa-file-pdf"></i> Print Separately
              </button>
              &nbsp;
              <button type="button" class="btn btn-primary btn-sm" (click) = "printCombined()"> <i class="far fa-file-pdf"></i> Print Combined
              </button>
              <br><br>
              </div>
              <div id="grid-wrapper" class="col-sm-12 px-0" style="width: 100%; overflow: hidden;">
                <div class="col-sm-12 px-0" >
                  <ag-grid-angular style="width: 100%; height: 500px; " class="ag-theme-balham bold saved-reports-grid"
                    [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" [rowData]="rowData"
                    (gridSizeChanged)="gridSizeChanged($event)" (cellClicked)="onCellClicked($event)" (selectionChanged)="onSelectionChanged($event)"
                    [animateRows]="true" [isExternalFilterPresent]="isExternalFilterPresent"
                    [doesExternalFilterPass]="doesExternalFilterPass"
                    [headerHeight]="60" [gridOptions]="gridOptions" >
                  </ag-grid-angular>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>