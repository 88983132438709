
export class Proxy {
    constructor(
        public ProxyID?: string,
        public Ticker?: string,
        public Proxy?: string,
        public Notes?: string,
        public IsFTA?: boolean,
        public CreatedBy?: string,
        public CreatedOn?: Date
    ) {}
}