import { Injectable } from '@angular/core';
import { Constants } from '../util/constants';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Agent } from '../models/agent.model';

@Injectable({
  providedIn: 'root'
})
export class MondayallocationrequestDataService {

  constructor(private http: HttpClient) { }

    mondayAllocationRequestData(allocationdata : FormData, loggedAgent:Agent): Observable<any> {
      var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
      const httpOptions = {
        headers: new HttpHeaders({ 
           'authorization': auth,
           'Accept': 'application/json',
        })
          }
       return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/saveallocationrequest ', allocationdata , httpOptions).pipe()
    }

    getcompanyadvisors(organizationid : string, loggedAgent:Agent): Observable<any> {
      var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
      const httpOptions = {
        headers: new HttpHeaders({ 
           'authorization': auth,
           'Accept': 'application/json',
        })
      }
      return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getcompanyadvisors/' + organizationid, null , httpOptions).pipe()
    }

    getallocationhistory(Email : FormData, loggedAgent:Agent): Observable<any> {
      var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
      const httpOptions = {
        headers: new HttpHeaders({ 
           'authorization': auth,
           'Accept': 'application/json',
        })
      }
      return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getallocationhistory', Email, httpOptions).pipe()
    }
}
