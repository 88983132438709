<div class="container-fluid">
	<div class="row">
		<main role="main" class="px-0 col-md-10 vh-100 main-wrapper db-wrapper" [ngClass]="{'col-md-10': isSideBar, 'col-md-12': !isSideBar}" > 
      <div class="row fp4fa-wrapper scroll-wrapper-db h-100 w-100 p-4 m-0" [ngClass]="{'expand': status, 'resize': !status, 'status resize': status && resizestatus  }" >
      <div class="scroll-wrapper w-100" >
        <button type="button" class="btn btn-primary btn-sm float-right mr-4" (click)="exitBtnClickEvent()">
          <i class="fas fa-sign-out"></i> Exit
        </button>
        <button type="button" class="btn btn-primary btn-sm mr-1 float-right" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
          {{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
        </button>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
            <!-- <li class="breadcrumb-item active" aria-current="page">{{currentStepperName}}</li> -->
          </ol>
        </nav>
        <div class="row w-100 m-0 justify-content-center db-content">
          <div class="col-md-12">   
            <div class="row w-100 m-0">
              <div class="col-md-12">
                 <div class="card widget">
                    <div class="card-body p-5">
                      <form [formGroup]="iSForm">
                        <div class="form-group">
                          <label class="small mb-2 ml-1" for="">Business Plan Name</label>
                          <select class="form-control col-sm-5 ml-1" width="100%" placeholder="Select Business Plan Name"
                          (change)=setBusinessPlanId($event) [(ngModel)]="existingbusinessplanid" formControlName="existingbusinessplanid">
                            <option *ngFor="let businessPlanname of businessPlansArray"
                            [value]="businessPlanname.id">
                              {{businessPlanname.text}}
                            </option>
                          </select>
                        </div>
                      </form>
                    </div>
                 </div>                 
              </div>
            </div>   

            <!-- Allocation of expenses and expense categories charts -->
            <div class="row w-100 m-0 mb-5">
              <div class="col-md-6">
                 <div class="card widget mb-3 mt-3">
                    <div _ngcontent-ols-c2="" class="card-body p-5">
                      <h5><b>Allocation of Expenses</b></h5>
                      <br>
                      <label class="" for="">Year</label>
                      <select class="form-control col-md-4 mb-5" [(ngModel)]="yearforallocationofexpenses" (change)="getAllocationOfExpensesData()"> 
                        <option *ngFor="let item of let item of years;" [value]="item">
                          {{item}}
                        </option>
                      </select>
                      <div [chart]="allocationOfExpensesChart"></div>                       
                    </div>
                 </div>                 
              </div>
              <div  class="col-md-6">
                <div class="card widget mb-3 mt-3">
                  <div _ngcontent-ols-c2="" class="card-body p-5">
                    <h5><b>Expenses Per Each Year</b></h5>
                    <br>
                    <label class="" for="">Expense Category</label>
                    <select class="form-control mb-5" [(ngModel)]="expensecategory" (change)="getExpenseCategoriesData()"> 
                      <option *ngFor="let item of let item of expensecategories;" [value]="item">
                        {{item}}
                      </option>
                    </select>
                    <div [chart]="expensecategoriesChart"></div>                       
                  </div>
               </div>                
             </div>        
            </div>

            <!-- Monthyl cash flow graph-->
            <div class="row w-100 m-0 mb-5">
              <div  class="col-md-12">
                <div class="card widget mb-3 mt-3">
                  <div _ngcontent-ols-c2="" class="card-body p-5">
                    <h5><b>Monthly Cash Flow</b></h5>
                    <br>
                    <select class="form-control col-md-4 mb-5" [(ngModel)]="yearformonthlycashflow" (change)="getMonthlyCashFlowData()"> 
                      <option *ngFor="let item of let item of years;" [value]="item">
                        {{item}}
                      </option>
                    </select>
                    <div [chart]="monthlyCashFlowChart"></div>                       
                  </div>
              </div>                
            </div>     
            </div>

            <!-- Total AUM growth and total client growth charts -->
            <div class="row w-100 m-0 mb-5">
              <div  class="col-md-6">
                <div class="card widget mb-3 mt-3">
                  <div _ngcontent-ols-c2="" class="card-body p-5">
                    <h5><b>Revenue by Annuity</b></h5>
                    <br>
                    <div [chart]="revenueByAnnuityChart"></div>                       
                  </div>
                </div>                
              </div>  
              <div  class="col-md-6">
                <div class="card widget mb-3 mt-3">
                  <div _ngcontent-ols-c2="" class="card-body p-5">
                    <h5><b>Total AUM Growth</b></h5>
                    <br>
                    <div [chart]="totalAUMGrowthChart"></div>                       
                  </div>
                </div>                
              </div> 
            </div>

            <!-- Revenue by annuity and product mix -->
            <div class="row w-100 m-0 mb-5">
              <div  class="col-md-12">
                <div class="card widget mb-3 mt-3">
                  <div _ngcontent-ols-c2="" class="card-body p-5">
                    <h5><b>Total Client Growth</b></h5>
                    <br>
                    <div [chart]="totalClientGrowthChart"></div>                       
                  </div>
                </div>                
              </div>
            </div>

            <!-- Product mix and marketing mix charts -->
            <div class="row w-100 m-0 mb-5">
              <div class="col-md-6">
                <div class="card widget mb-3 mt-3">
                    <div _ngcontent-ols-c2="" class="card-body p-5">
                      <h5><b>Product Mix</b></h5>
                      <br>
                      <div [chart]="productMixChart"></div>                       
                    </div>
                </div>                 
              </div>   
              <div class="col-md-6">
                <div class="card widget mb-3 mt-3">
                    <div _ngcontent-ols-c2="" class="card-body p-5">
                      <h5><b>Marketing Channel</b></h5>
                      <br>
                      <div [chart]="marketingChannelChart"></div>                       
                    </div>
                </div>                 
              </div>     
            </div>


            <!-- Owner's take monthly graph -->
            <div class="row w-100 m-0 mb-5">
              <div  class="col-md-12">
                <div class="card widget mb-3 mt-3">
                  <div _ngcontent-ols-c2="" class="card-body p-5">
                    <h5><b>Owner's Take Monthly Graph</b></h5>
                    <br>
                    <select class="form-control col-md-4 mb-5" [(ngModel)]="yearforownerstake" (change)="getOwnersTakeMonthlyData()"> 
                      <option *ngFor="let item of let item of years;" [value]="item">
                        {{item}}
                      </option>
                    </select>
                    <div [chart]="ownersTakeMonthlyChart"></div>                       
                  </div>
              </div>                
            </div>     
            </div>
            



          </div>
        </div>
      </div>  
      <!-- <div class="right-bar">
        <div class="right-bar-wrapper w-100">
        <div class="tab-list">
          <ul class="list-group list-group-flush">
            <li class="list-group-item tab" (click)="toggleRightbar()" ><i class="fas fa-calculator"></i></li>   
          </ul>
        </div>
        <header>
          <div class="btn-group m-1" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-link common-btn icon-btn move-left" (click)="resizeRightbar()"><i class="fas fa-arrow-alt-circle-left fa-lg" tooltip="Click Here to view results" style="font-size: 24px"></i></button>
            <button type="button" class="btn btn-link common-btn icon-btn move-right" (click)="resizeRightbar()"><i class="fas fa-arrow-alt-circle-right fa-lg" tooltip="Click Here to view inputs and results side by side" style="font-size: 24px"></i></button>
            <p class="d-inline p-2 m-0 bold" style="font-size: 16px">Business Planning Calculator</p>
            <button type="button" class="btn btn-link green-link common-btn icon-btn" (click)="refreshCalculator()"><i class="fas fa-redo pr-2 fa-lg"></i> <p class="d-inline p-2 m-0" style="font-size: 16px">Refresh </p></button>
          </div>   
        </header>
        <app-calculator></app-calculator>
      </div>
      </div> -->
    </div>
  </main>
</div>
</div>