import { Component, OnInit,TemplateRef,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'; 
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { ClientInfoDataService } from '../client-info/client-info-data.service';
import { CommonFunctions } from '../../util/common-functions';
import { AlertService } from '../../util/alert/alert.service';
import { Agent } from '../../models/agent.model';  
import { Proxy } from '../../models/proxy.model';  
import { TiingoTickerInfo } from '../../models/tiingo-ticker-info';
import { InvestmentReportRequest } from '../../models/investment-report-request.model';  
import { LocalStorage } from '../../util/localstorage.service';
import { BsModalService,BsModalRef}   from 'ngx-bootstrap/modal';
import { Constants } from '../../util/constants' 
import { typeaheadAnimation } from 'ngx-bootstrap/typeahead/typeahead-animations';
import { ProductList } from 'src/app/models/product-list.model';

@Component({
    selector: 'app-old-investment-report',
	templateUrl: './old-investment-report.component.html',
	styleUrls: ['./old-investment-report.component.scss'],
	providers: [DatePipe]
})
export class OldInvestmentReportComponent implements OnInit { 
	public riskGraphForm: FormGroup;
	public recommendedProxyForm: FormGroup;
	public addProxyForm: FormGroup;
	public searchResultForm: FormGroup;
	public saveReportForm: FormGroup;
	public loggedAgent: Agent 
	public ModalRef : BsModalRef 
	public recommendedproxies :   Array<Proxy> = []
	public selectedproxies :   Array<Proxy> = []
	public currentticker:Proxy = new Proxy() 
	public showMsg : boolean = false
	public fromDate : Date = new Date("2000/01/01")
	public toDate : Date = new Date()
	public noOfDrawdowns : number = 2
	public productlist : any;
	public indexlist : any; 
	public tickerblendlist : any; 
	public selectedProducts: any = []
	public selectedIndex: any
	public selectedTickerBlend: any
	public TiingoTickerInfo : Array<TiingoTickerInfo> =[]
	public reportName : any; 
	public isShowDashboard: boolean = false
	private commonFunctions: CommonFunctions = new CommonFunctions
 
	@ViewChild('recommendedProxyTemplate') recommendedProxyTemplate: TemplateRef<any>
	@ViewChild('addProxyTemplate') addProxyTemplate: TemplateRef<any>
	@ViewChild('searchResultTemplate') searchResultTemplate: TemplateRef<any>
	@ViewChild('saveReportTemplate') saveReportTemplate: TemplateRef<any>

	constructor( private router: Router,  private ldService: ClientInfoDataService,
		private formBuilder: FormBuilder, private alertService: AlertService,
		private bsModalService :BsModalService) { 
		 
	}


	ngOnInit() { 
		this.alertService.clear()
		this.riskGraphForm = this.formBuilder.group({
				tickerSymbol: new FormControl(null),
				selectedTickers: new FormControl(null) ,
				products: new FormControl(null) ,
				index: new FormControl(null) ,
				tickerblend: new FormControl(null) ,
				fromDate: new FormControl(null) ,
				toDate: new FormControl(null) ,
				noofDropDowns: new FormControl(null)
			});   
	
		this.recommendedProxyForm = this.formBuilder.group({
			recommendedTickers: new FormControl(null) 
			});   
	
		this.addProxyForm = this.formBuilder.group({
				tickerSymbol: new FormControl(null),
				proxySymbol: new FormControl(null) ,
				comment: new FormControl(null)
			});    

		this.searchResultForm = this.formBuilder.group({
				searchresults: new FormControl(null) 
			});  
		
		this.saveReportForm = this.formBuilder.group({
			reportName: new FormControl(null) 
			});  


	
	this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
	if(this.loggedAgent.UserRoleID== Constants.UserRoleID.Superuser)
	this.isShowDashboard = true
	this.getProductData()
	
	}

	getProductData()
	{
		this.ldService.getproductlistforoldinvestmentreport(this.loggedAgent).subscribe(data => 
			{ 
				this.productlist = data  
			},
		error => {
				this.alertService.clear()
				this.alertService.error('Error occured while retrieving data')
				});  
		 

		this.ldService.getindexlistforinvestmentreport(this.loggedAgent).subscribe(data => 
			{ 
				this.indexlist = data    
			},
		error => {
				this.alertService.clear()
				this.alertService.error('Error occured while retrieving data')
				}); 
				
		this.ldService.gettickerblendlistforinvestmentreport(this.loggedAgent).subscribe(data => 
			{ 
				this.tickerblendlist = data    
			},
		error => {
				this.alertService.clear()
				this.alertService.error('Error occured while retrieving data')
				}); 
		
	}
	 
	
	selectBtnClickEvent()
	{ 
	
	  this.alertService.clear()
	   
	   //Validate ticker Symbol
	  if (this.riskGraphForm.controls.tickerSymbol.value == "" || 
			   this.riskGraphForm.controls.tickerSymbol.value == null )
		  {  
			  this.alertService.error('Invalid Ticker Symbol')
			  return false
		  } 
		  else 
		  {
			this.ldService.hashistoricaldata(this.riskGraphForm.controls.tickerSymbol.value, this.loggedAgent).subscribe(hasdata => 
				{ 
					if (hasdata)
					{
						let tempSelected : Proxy = new Proxy
						tempSelected.Ticker = this.riskGraphForm.controls.tickerSymbol.value 
						tempSelected.Proxy = this.riskGraphForm.controls.tickerSymbol.value 
						this.selectedproxies.push(tempSelected) 
						this.currentticker = new Proxy
	
						
					}
					else 
					{ 
						this.ldService.getrecommendedproxies(this.riskGraphForm.controls.tickerSymbol.value, this.loggedAgent).subscribe(data => 
							{ 
								this.recommendedproxies = data   
								 
								if (this.recommendedproxies.length>0)
								{
									//this.addProxyBtnClickEvent(this.recommendedProxyTemplate)
									this.ModalRef = this.bsModalService.show(this.recommendedProxyTemplate)
								}
								else
								{
									this.showMsg = true
									this.addProxyBtnClickEvent(this.addProxyTemplate,true)
								}
							},
						error => {
								this.alertService.clear()
								this.alertService.error('Error occured while retrieving data')
								});  
					} 
				},
				error => {
					//console.log(error)
					this.alertService.clear()
					this.alertService.error('Error occured while retrieving data')
					});  
		  } 
	   
	} 

searchSymbolBtnClickEvent()
{
	//clear previous selections
	this.searchResultForm = this.formBuilder.group({
		searchresults: new FormControl(null) 
		});   

	this.alertService.clear()
	this.ldService.gettiingoutilitysearch(this.riskGraphForm.controls.tickerSymbol.value, this.loggedAgent).subscribe(data => 
		{ 
			this.TiingoTickerInfo = data   
			if (this.TiingoTickerInfo.length>0)
			{ 
				this.ModalRef = this.bsModalService.show(this.searchResultTemplate, { class: 'modal-lg' })
			}
			else
			{
				this.alertService.clear()
				this.alertService.info('No data found')
			}
			

			// if (this.recommendedproxies.length>0)
			// {
			// 	//this.addProxyBtnClickEvent(this.recommendedProxyTemplate)
			// 	this.ModalRef = this.bsModalService.show(this.recommendedProxyTemplate)
			// }
			// else
			// {
			// 	this.showMsg = true
			// 	this.addProxyBtnClickEvent(this.addProxyTemplate,true)
			// }
		},
	error => {
			this.alertService.clear()
			this.alertService.error('Error occured while retrieving data')
			}); 
}
	
	saveProxyBtnClickEvent()
	{
	  this.alertService.clear()
	   
		   //Validate ticker Symbol
		  if (this.addProxyForm.controls.tickerSymbol.value == "" || 
			   this.addProxyForm.controls.tickerSymbol.value == null )
		{  
			this.alertService.error('Invalid Ticker Symbol')
			return false
		} 
	
		if (this.addProxyForm.controls.proxySymbol.value == "" || 
		  this.addProxyForm.controls.proxySymbol.value == null )
		{  
			this.alertService.error('Invalid Proxy Symbol')
			return false
		} 
	 
	
		//Assign values 
		let newProxy : Proxy = new Proxy()
		newProxy.ProxyID =this.commonFunctions.getnewguid() 
		newProxy.Ticker = this.addProxyForm.controls.tickerSymbol.value 
		newProxy.Proxy = this.addProxyForm.controls.proxySymbol.value 
	  
		if (this.addProxyForm.controls.comment.value == "" || 
		  this.addProxyForm.controls.comment.value == null )
		{  
			newProxy.Notes = ""
		} 
		else
		{
			newProxy.Notes = this.addProxyForm.controls.comment.value
		}
	
		
		if (this.loggedAgent.OrganizationID == Constants.FTAOrganizationID) {
			newProxy.IsFTA =  true
		}
		else
		{
			newProxy.IsFTA =  false
		}	
		newProxy.CreatedBy =this.loggedAgent.AgentID
		newProxy.CreatedOn = new Date()
	
	
	
		this.ldService.saveproxy(newProxy, this.loggedAgent).subscribe(data => 
			{ 
				this.alertService.clear()
				//this.alertService.success('Successfully saved') 
	 
				// check whether proxy added to previously selected ticker
				let oPrevSelected : Proxy = new Proxy()
				oPrevSelected = this.selectedproxies.find( x=> x.Ticker  === this.currentticker.Ticker) 
				//if (oPrevSelected)
				if (typeof oPrevSelected === 'undefined') 
				{
					let tempSelected : Proxy = new Proxy
					tempSelected.Ticker = newProxy.Ticker  
					tempSelected.Proxy = newProxy.Proxy 
					this.selectedproxies.push(tempSelected) 
				}
				else
				{
					oPrevSelected.Proxy = newProxy.Proxy
				}
	
				this.currentticker= new Proxy
				this.ModalRef.hide()
			},
		error => {
				this.alertService.clear()
				this.alertService.error('Error occured while retrieving data')
				});  
	
	}
	
	addProxyBtnClickEvent(template : TemplateRef<any>, showmsg : boolean)
	{
		this.showMsg = showmsg
		if (this.showMsg == false)
		{ 
			if (this.selectedproxies.length>0)
			{ 
				let oselected : Proxy = new Proxy()
				oselected = this.selectedproxies.find( x=> x.Ticker  === this.riskGraphForm.controls.selectedTickers.value || 
													x.Proxy  === this.riskGraphForm.controls.selectedTickers.value ) 
	 
				this.currentticker = new Proxy() 
				this.currentticker.Ticker =   oselected.Ticker 
				this.currentticker.Proxy =   oselected.Proxy 
			}  
		}
	
		if (typeof this.ModalRef !== 'undefined') 
		{
				this.ModalRef.hide();
		} 
		this.ModalRef = this.bsModalService.show(template)  
	}
	
	canceltemplateClickEvent()
	{ 
		this.currentticker = new Proxy
		this.ModalRef.hide();
		this.alertService.clear()
	}
	 
	
	deleteBtnClickEvent()
	  { 
		let tempTicker : string = this.riskGraphForm.controls.selectedTickers.value
		this.selectedproxies.splice(this.selectedproxies.findIndex(x=> x.Ticker  === tempTicker), 1)
	  }
	
	  onFromDateChange(event: any) { 
		this.fromDate = new Date(event); 
	  }
	
	  onToDateChange(event: any) { 
		this.toDate = new Date(event); 
	  }
	
	  printReportBtnClickEvent()
	  {
	
		/*if (this.selectedproxies.length<=0)
		{
			this.alertService.error('Select tickers to generate report.')
			return false
		}*/
	
		//Assign product list
		let productlist :Array<ProductList> = []
		if (typeof this.selectedProducts === 'undefined') 
		{
			productlist = []
		}
		else
		{
			productlist = this.selectedProducts 
		}

		//Assign Index
		let indexlist:Array<ProductList> = []   
		if  (typeof this.selectedIndex  === 'undefined') 
		{
			indexlist =  []
		} 
		else
		{
			indexlist = this.selectedIndex
		}

		//Assign Ticker Blend
		let tickerblendlist:Array<ProductList> = []   
		if  (typeof this.selectedTickerBlend  === 'undefined') 
		{
			tickerblendlist =  []
		} 
		else
		{
			tickerblendlist = this.selectedTickerBlend
		}
		
		
		if (this.selectedproxies.length<=0) // when there's no ticker selected
		{ 
			if (productlist.length<=0 && indexlist.length<=0 && tickerblendlist.length<=0)
			{
				this.alertService.error('Select Ticker/Ticker Blend/Product/Index to generate report.')
				return false
			}
			else
			{
				this.alertService.clear()
				this.alertService.info('Generating report. Please wait.')			

				let investmentreportrequest : InvestmentReportRequest = new InvestmentReportRequest()
				investmentreportrequest.AgentID = this.loggedAgent.AgentID
				investmentreportrequest.Tickers =  ""
				investmentreportrequest.StartDate = this.fromDate
				investmentreportrequest.EndDate =this.toDate 
				investmentreportrequest.ProductList = productlist
				investmentreportrequest.IndexList = indexlist
				investmentreportrequest.TickerBlendList = tickerblendlist

				//console.log(investmentreportrequest)  
			
				setTimeout(() => { 
					this.ldService.getgeneratedoldinvestmentreportpdf(investmentreportrequest, this.loggedAgent).subscribe(data => {  
			
						const blob = new Blob([data], {type: 'application/pdf'}); 
						let a = document.createElement("a"); 
						document.body.appendChild(a);
						let url = window.URL.createObjectURL(blob);
						a.href = url;
						a.download = 'InvestmentReport_' +  this.commonFunctions.formatDatetime(new Date(), 'mm-dd-yyyy') +'.pdf'; // gives it a name via an a tag
						a.click();
						window.URL.revokeObjectURL(url);
			
						this.alertService.clear() 
					},
						error => { 
							this.alertService.clear()
							this.alertService.error('Error occured while generating pdf report')
					}); 
				}, 50);
			}
		}
		else
		{
			for (var i =0 ; i <=this.selectedproxies.length-1 ; ++i) { 
				let ticker : string = this.selectedproxies[i].Proxy

				this.alertService.clear()
				this.alertService.info('Generating PDF report. Please wait.')			

				let investmentreportrequest : InvestmentReportRequest = new InvestmentReportRequest()
				investmentreportrequest.AgentID = this.loggedAgent.AgentID
				investmentreportrequest.Tickers =  ticker
				investmentreportrequest.StartDate = this.fromDate
				investmentreportrequest.EndDate =this.toDate 
				investmentreportrequest.ProductList = productlist
				investmentreportrequest.IndexList = indexlist
				investmentreportrequest.TickerBlendList = tickerblendlist

				//console.log(investmentreportrequest)  
			
				setTimeout(() => { 
					this.ldService.getgeneratedoldinvestmentreportpdf(investmentreportrequest, this.loggedAgent).subscribe(data => {  
			 
						const blob = new Blob([data], {type: 'application/pdf'}); 
						let a = document.createElement("a"); 
						document.body.appendChild(a);
						let url = window.URL.createObjectURL(blob);
						a.href = url;
						a.download = 'InvestmentReport_' + ticker.replace(",","-") + "_"+ this.commonFunctions.formatDatetime(new Date(), 'mm-dd-yyyy') +'.pdf'; // gives it a name via an a tag
						a.click();
						window.URL.revokeObjectURL(url);
			
						this.alertService.clear() 
					},
						error => { 
							this.alertService.clear()
							this.alertService.error('Error occured while generating pdf report')
					}); 
				}, 50);
			} 
		}
		
	  }

	  generateReportBtnClickEvent()
	  {
	
		/*if (this.selectedproxies.length<=0)
		{
			this.alertService.error('Select tickers to generate report.')
			return false
		}*/
	
		//Assign product list
		let productlist :Array<ProductList> = []
		if (typeof this.selectedProducts === 'undefined') 
		{
			productlist = []
		}
		else
		{
			productlist = this.selectedProducts 
		}

		//Assign Index
		let indexlist:Array<ProductList> = []   
		if  (typeof this.selectedIndex  === 'undefined') 
		{
			indexlist =  []
		} 
		else
		{
			indexlist = this.selectedIndex
		}

		//Assign Ticker Blend
		let tickerblendlist:Array<ProductList> = []   
		if  (typeof this.selectedTickerBlend  === 'undefined') 
		{
			tickerblendlist =  []
		} 
		else
		{
			tickerblendlist = this.selectedTickerBlend
		}
		
		if (this.selectedproxies.length<=0) // when there's no ticker selected
		{ 
			if (productlist.length<=0 && indexlist.length<=0 && tickerblendlist.length<=0)
			{
				this.alertService.error('Select Ticker/Ticker Blend/Product/Index to generate report.')
				return false
			}
			else
			{
				this.alertService.clear()
				this.alertService.info('Generating report. Please wait.')			

				let investmentreportrequest : InvestmentReportRequest = new InvestmentReportRequest()
				investmentreportrequest.AgentID = this.loggedAgent.AgentID
				investmentreportrequest.Tickers =  ""
				investmentreportrequest.StartDate = this.fromDate
				investmentreportrequest.EndDate =this.toDate 
				investmentreportrequest.ProductList = productlist
				investmentreportrequest.IndexList = indexlist
				investmentreportrequest.TickerBlendList = tickerblendlist

				console.log(investmentreportrequest)  
			
				setTimeout(() => { 
					this.ldService.getgeneratedoldinvestmentreportpdf(investmentreportrequest, this.loggedAgent).subscribe(data => {  
			
						var file = new Blob([data], {type: 'application/pdf'});
						var fileURL = URL.createObjectURL(file);
						window.open(fileURL);  
			
						this.alertService.clear() 
					},
						error => { 
							this.alertService.clear()
							this.alertService.error('Error occured while generating pdf report')
					}); 
				}, 50);
			}
		}
		else
		{
			for (var i =0 ; i <=this.selectedproxies.length-1 ; ++i) { 
				let ticker : string = this.selectedproxies[i].Proxy

				this.alertService.clear()
				this.alertService.info('Generating report. Please wait.')			

				let investmentreportrequest : InvestmentReportRequest = new InvestmentReportRequest()
				investmentreportrequest.AgentID = this.loggedAgent.AgentID
				investmentreportrequest.Tickers =  ticker
				investmentreportrequest.StartDate = this.fromDate
				investmentreportrequest.EndDate =this.toDate 
				investmentreportrequest.ProductList = productlist
				investmentreportrequest.IndexList = indexlist
				investmentreportrequest.TickerBlendList = tickerblendlist

				//console.log(investmentreportrequest)  
			
				setTimeout(() => { 
					this.ldService.getgeneratedoldinvestmentreportpdf(investmentreportrequest, this.loggedAgent).subscribe(data => {  
			
						var file = new Blob([data], {type: 'application/pdf'});
						var fileURL = URL.createObjectURL(file);
						window.open(fileURL);  
			
						this.alertService.clear() 
					},
						error => { 
							this.alertService.clear()
							this.alertService.error('Error occured while generating pdf report')
					}); 
				}, 50);
			} 
		}
		
	}

	saveReportBtnClickEvent()
	{ 
  
	  /*if (this.selectedproxies.length<=0)
	  {
		  this.alertService.error('Select tickers to generate report.')
		  return false
	  }*/

	  if (typeof this.reportName === 'undefined' || this.reportName ==="") 
	  {
		this.alertService.error('Please enter report name.')
		return false
	  }

	  // alert user when there's multiple tickers 
	  if (this.selectedproxies.length>1){
		if (!confirm("You have selected " +this.selectedproxies.length +
				" tickers and it will generate " +this.selectedproxies.length +
				" PDF reports. \n Are you sure you want to save all reports as '"+this.reportName+ "'?")) {  
					return false		
		} 
	  }
	  //Assign product list
	  let productlist :Array<ProductList> = []
	  if (typeof this.selectedProducts === 'undefined') 
	  {
		  productlist = []
	  }
	  else
	  {
		  productlist = this.selectedProducts 
	  }

	//Assign Index
	let indexlist:Array<ProductList> = []   
	if  (typeof this.selectedIndex  === 'undefined') 
	{
		indexlist =  []
	} 
	else
	{
		indexlist = this.selectedIndex
	}

	//Assign Ticker Blend
	let tickerblendlist:Array<ProductList> = []   
	if  (typeof this.selectedTickerBlend  === 'undefined') 
	{
		tickerblendlist =  []
	} 
	else
	{
		tickerblendlist = this.selectedTickerBlend
	}
	  
	  if (this.selectedproxies.length<=0) // when there's no ticker selected
	  { 
		if (productlist.length<=0 && indexlist.length<=0 && tickerblendlist.length<=0)
		{
			this.alertService.error('Select Ticker/Ticker Blend/Product/Index to generate report.')
			return false
		}
		  else
		  {
			  this.alertService.clear()
			  this.alertService.info('Saving report. Please wait.')			

			  let investmentreportrequest : InvestmentReportRequest = new InvestmentReportRequest()
			  investmentreportrequest.AgentID = this.loggedAgent.AgentID
			  investmentreportrequest.Tickers =  ""
			  investmentreportrequest.StartDate = this.fromDate
			  investmentreportrequest.EndDate =this.toDate 
			  investmentreportrequest.ProductList = productlist
			  investmentreportrequest.IndexList  = indexlist
			  investmentreportrequest.TickerBlendList = tickerblendlist
			  investmentreportrequest.ReportName = this.reportName 
			  investmentreportrequest.ClientID = localStorage.getItem(LocalStorage.SELECTED_CLIENTID)  

			  setTimeout(() => { 
				  this.ldService.saveinvestmentreport(investmentreportrequest, this.loggedAgent).subscribe(data => {  
		    
					  this.alertService.clear() 
					  this.alertService.success('Successfully saved.')
					  this.reportName=""	
					  this.ModalRef.hide()
				  },
					  error => { 
						  this.alertService.clear()
						  this.alertService.error('Error occured while saving report')
				  }); 
			  }, 50);
		  }
	  }
	  else
	  {
		  for (var i =0 ; i <=this.selectedproxies.length-1 ; ++i) { 
			  let ticker : string = this.selectedproxies[i].Proxy

			  this.alertService.clear()
			  this.alertService.info('Saving report. Please wait.')			

			  let investmentreportrequest : InvestmentReportRequest = new InvestmentReportRequest()
			  investmentreportrequest.AgentID = this.loggedAgent.AgentID
			  investmentreportrequest.Tickers =  ticker
			  investmentreportrequest.StartDate = this.fromDate
			  investmentreportrequest.EndDate =this.toDate 
			  investmentreportrequest.ProductList = productlist
			  investmentreportrequest.IndexList = indexlist
			  investmentreportrequest.TickerBlendList = tickerblendlist
			  investmentreportrequest.ReportName = this.reportName 
			  investmentreportrequest.ClientID = localStorage.getItem(LocalStorage.SELECTED_CLIENTID)  
			  
			  setTimeout(() => { 
				  this.ldService.saveinvestmentreport(investmentreportrequest, this.loggedAgent).subscribe(data => {  
		    
					  this.alertService.clear() 
					  this.alertService.success('Successfully saved.')	
					  this.reportName=""
					  this.ModalRef.hide()
				  },
					  error => { 
						  this.alertService.clear()
						  this.alertService.error('Error occured while saving report')
				  }); 
			  }, 50);
		  } 
	  }
	  
  }

  openReportNamePopup()
  { 
		this.alertService.clear()

		if ((typeof this.selectedproxies === 'undefined' || this.selectedproxies.length<=0) && 
			(typeof this.selectedProducts === 'undefined' || this.selectedProducts.length<=0) && 
			(typeof this.selectedIndex === 'undefined' || this.selectedIndex.length<=0) && 
			(typeof this.selectedTickerBlend === 'undefined' || this.selectedTickerBlend.length<=0))
		{
			this.alertService.error('Select Ticker/Ticker Blend/Product/Index to save report.')
			return false
		}

		this.ModalRef = this.bsModalService.show(this.saveReportTemplate)
  }

	 
	
	cancelBtnClickEvent()
	  {
		this.recommendedproxies = []
		this.selectedproxies  = []
		this.currentticker = new Proxy()  
		this.fromDate  = new Date("2000/01/01")
		this.toDate  = new Date()
		this.noOfDrawdowns  = 2
		this.selectedProducts = []
		this.selectedIndex= []
		this.selectedTickerBlend= []
		this.reportName=""
		this.alertService.clear()
	
	  }
	
	openModal(template : TemplateRef<any>)
	  {
		this.ModalRef = this.bsModalService.show(template)
	  }
	 
	
	selectProxyBtnClickEvent()
		{ 
			let tempSelected : Proxy = new Proxy
			tempSelected.Ticker = this.currentticker.Ticker
			tempSelected.Proxy = this.recommendedProxyForm.controls.recommendedTickers.value 
			this.selectedproxies.push(tempSelected) 
			this.currentticker = new Proxy 
			this.ModalRef.hide(); 
		}

		
	selectResultBtnClickEvent()
	{   

		if (this.searchResultForm.controls.searchresults.value === null) 
		{
			this.alertService.clear()
			this.alertService.error('Invalid ticker')
			return
		} 

		let tempSelected : Proxy = new Proxy
		tempSelected.Ticker = this.searchResultForm.controls.searchresults.value  
		tempSelected.Proxy = this.searchResultForm.controls.searchresults.value  
		this.selectedproxies.push(tempSelected) 
		this.currentticker = new Proxy
		this.ModalRef.hide(); 
		this.alertService.clear()
	}
	
	TickerToUppercase(event: any) { 
		if (typeof event !== 'undefined') 
			{
				this.currentticker.Ticker = event.toString().toUpperCase(); 
			}
		}
	
	ProxyToUppercase(event: any) { 
		if (typeof event !== 'undefined') 
			{
				this.currentticker.Proxy = event.toString().toUpperCase(); 
			}
		}

	changeProductValue($event: any) {
		this.fromDate  = new Date("2000/01/01")

		if (this.selectedProducts.length>0)
		{ 
			this.selectedProducts.forEach((element: any) => {  
				let product  : ProductList = element
				if( new Date(product.BeginDate)  > new Date(this.fromDate)){
					this.fromDate = new Date(product.BeginDate)  
				}  
			});
		} 
		//console.log($event);
		//console.log("##########3 " + this.selectedProduct[0]['ProductName']) 
			 
	}

	changeIndexValue($event: any) {
		    //console.log($event);
			//console.log("##########www " + this.selectedIndex['IndexName'])
		}	
		
	changeTickerBlendValue($event: any) {
		//console.log($event);
		//console.log("##########www " + this.selectedIndex['IndexName'])
	}	
	

	exitBtnClickEvent()
	{
	  localStorage.setItem(LocalStorage.SELECTED_CLIENTID, "");
	  //localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(new Client));
	  localStorage.removeItem(LocalStorage.CURRENT_CLIENT);
	  this.router.navigateByUrl('landing'); 
	}

	//load the superuser dashboard page
	public navigateSuperUserDashboard()
	{
		this.router.navigateByUrl('superuserdashboard'); 
	}
}
	
	