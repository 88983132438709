<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg">
	<app-header></app-header>
</nav>
<div class="main-panel pt-0 pb-4">
	<div class="container-fluid" #main [style.padding-top.px]="paddingtop">
		<div class="col-sm-12 px-0 align-self-end">
			<div class="row align-items-center">
				<div class="col-sm-8">
				</div>
				<div class="col-sm-4 p-2 text-right">
					
					<button type="button" class="btn btn-primary btn-sm  mr-1 at-right" (click)="loadRetirementPlans()">
						{{ 'LANDING.LBL_TITLE' | translate }}
					</button>	
					<button *ngIf="isShowFP4FA" type="button" class="btn btn-primary btn-sm " (click)="loadFP4FA()">
						{{ 'FP4FA.LBL_TITLE' | translate }}
					</button>
				</div> 
			</div>
			<hr class="mt-0 mb-3" />
		</div>
    <div class="container-fluid">
        <div class="row">
          <main role="main" class="px-0 col-md-10 vh-100 main-wrapper db-wrapper" [ngClass]="{'col-md-10': isSideBar, 'col-md-12': !isSideBar}" > 
            <div class="row scroll-wrapper-db w-100 p-4 m-0" [ngClass]="{'expand': status, 'resize': !status, 'status resize': status && resizestatus  }" >
            <div class="w-100" >
              <div class="row w-100 m-0 justify-content-center db-content pr-5 pl-5">
                <div class="col-md-12">     
                    <div class="row w-100 m-0">
                        <div class="col-md-3 col-sm-6 col-xs-12">
                            <div class="mini-stat clearfix bg-organizations rounded">
                                <span class="mini-stat-icon"><i class="fas fa-sitemap fg-organizations"></i></span>
                                <div class="mini-stat-info">
                                    <span>{{totalorganizations}}</span>
                                    Total Organizations
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-xs-12">
                            <div class="mini-stat clearfix bg-agents rounded">
                                <span class="mini-stat-icon"><i class="fas fa-user-tie fg-agents"></i></span>
                                <div class="mini-stat-info">
                                    <span>{{totalagents}}</span>
                                    Total Agents
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-xs-12">
                            <div class="mini-stat clearfix bg-clients rounded">
                                <span class="mini-stat-icon"><i class="fas fa-user fg-clients"></i></span>
                                <div class="mini-stat-info">
                                    <span>{{totalclients}}</span>
                                    Total Clients
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                            <div class="mini-stat clearfix bg-plans rounded">
                                <span class="mini-stat-icon"><i class="fas fa-tasks fg-plans"></i></span>
                                <div class="mini-stat-info">
                                    <span>{{totalplans}}</span>
                                    Total Plans
                                </div>
                            </div>
                        </div>        
                  </div>

                  
                  <!-- Plans running out of money-->
                  <div class="row w-100 m-0">
                    <div  class="col-md-12">
                      <div class="superuser-dashboard-card widget mb-3 mt-3">
                          <div class="row pl-5 pr-5 pb-1 pt-5">
                            <div class="col-md-12">
                              <h5><b>Plans Running Out of Money</b></h5>
                            </div>
                          </div>
                          <div class="row pl-5 pr-5 pb-1 pt-0">
                            <div class="col-md-8">
                              <button type="button" class="btn btn-primary btn-sm mr-1 at-right" (click)="expandAllOrganizations()">
                                <i class="fas fa-plus"></i> Expand All
                              </button>
                              <button type="button" class="btn btn-primary btn-sm mr-1 at-right" (click)="collapseAllOrganizations()">
                                <i class="fas fa-minus"></i> Collapse All
                              </button>
                            </div>
                          </div>
                          <div class="row pr-5 pl-5 pb-5 pt-4">
                            <div class="col-md-6">
                              <!-- <div class="table-responsive">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">Organization Name</th>
                                      <th scope="col"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let item of plansrunningoutofmoney">
                                      <td class="td-plans">{{item.OrganizationName}}</td>   
                                      <td class="td-plans"><div class="badge badge-opacity-success">View</div></td>                  
                                    </tr> 
                                  </tbody>
                                </table>
                              </div> -->
                              <ul class="bullet-line-list-organization"
                              *ngFor="let organization of plansrunningoutofmoney.OrganizationHierarchyList;">
                                <li class="custom">
                                  <span class="structureindicator" tooltip="Organization"></span>
                                  <div class="d-flex justify-content-between">
                                    <div style="font-size: 18px;font-weight: bold">{{organization.OrganizationName}}</div>
                                    <div type="button" class="badge badge-opacity-success" id="organization.OrganizationID"
                                    (click)="collapseOrganizationByID(organization.OrganizationID)">
                                    {{ organization.isCollapse ? 'Expand' : 'Collapse' }}
                                    </div>
                                  </div>
                                  <div *ngIf="!organization.isCollapse">
                                  <ul class="bullet-line-list-agent" *ngFor="let agent of organization.AgentHierarchyList;" >
                                    <li>
                                      <span class="structureindicator" tooltip="Agent"></span>
                                      <div class="d-flex justify-content-between">
                                        <div style="font-size: 18px;">{{agent.AgentName}}</div>
                                      </div>
                                      <ul class="bullet-line-list-client" *ngFor="let client of agent.ClientHierarchyList;">
                                        <li>
                                          <span class="structureindicator" tooltip="Client"></span>
                                          <div class="d-flex justify-content-between">
                                            <div style="font-size: 18px;">{{client.ClientName}}</div>
                                          </div>
                                          <ul class="bullet-line-list-plan" *ngFor="let plan of client.PlanHierarchyList;">
                                            <li>
                                              <span class="structureindicator" tooltip="Plan"></span>
                                              <div class="d-flex justify-content-between">
                                                <div style="font-size: 18px;">{{plan.PlanName}}</div>
                                              </div>
                                              <ul class="bullet-line-list-asset" *ngFor="let asset of plan.AssetHierarchyList;">
                                                <li>
                                                  <span class="structureindicator" tooltip="Asset"></span>
                                                  <div class="d-flex justify-content-between">
                                                    <div style="font-size: 18px;">{{asset.AssetName}}</div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                  </div>
                              </ul>
                            </div>
                          </div> 
                      </div>                
                    </div>     
                  </div>

                  <hr/>

                  <!-- Select an organization-->
                  <div class="row w-100 m-0">
                    <div class="col-md-12">
                      <div class="superuser-dashboard-card widget mt-3">
                        <div _ngcontent-ols-c2="" class="card-body p-5">
                          <h5 class="element1"><b>Select Organization</b></h5>
                          <select class="form-control col-md-4 element2" [(ngModel)]="selectedorganization" (change)="onSelectOrganization()"> 
                            <option *ngFor="let item of organizations;" [value]="item.OrganizationID">
                              {{item.OrganizationName}}
                            </option>
                          </select>  
                          <div class="row w-100 m-0">
                            <div class="col-md-3 mb-3 mt-4">
                              <div class="card widget mb-3 mt-4">
                                  <div _ngcontent-ols-c2="" class="card-body" style="text-align: center">
                                    <p class="statistics-title">Agents</p>
                                    <h3 class="rate-percentage">{{totalnoofagents}}</h3>                 
                                  </div>
                              </div>                 
                            </div>
                            <div class="col-md-3 mb-3 mt-4">
                              <div class="card widget mb-3 mt-4">
                                  <div _ngcontent-ols-c2="" class="card-body" style="text-align: center">
                                    <p class="statistics-title">Clients</p>
                                    <h3 class="rate-percentage">{{totalnoofclients}}</h3>                    
                                  </div>
                              </div>                 
                            </div>
                            <div class="col-md-3 mb-3 mt-4">
                              <div class="card widget mb-3 mt-4">
                                  <div _ngcontent-ols-c2="" class="card-body" style="text-align: center">
                                    <p class="statistics-title">Plans</p>
                                    <h3 class="rate-percentage">{{totalnoofplans}}</h3>                    
                                  </div>
                              </div>                 
                            </div>  
                            <div class="col-md-3 mb-3 mt-4">
                              <div class="card widget mb-3 mt-4">
                                  <div _ngcontent-ols-c2="" class="card-body" style="text-align: center">
                                    <p class="statistics-title">Presented to Client</p>
                                    <h3 class="rate-percentage">{{planspresentedtoclient}}</h3>                    
                                  </div>
                              </div>                 
                            </div>      
                          </div>   
                          <h5 class="element1"><b>Select Agent</b></h5>
                          <select class="form-control col-md-4 element2" [(ngModel)]="selectedagent" (change)="onSelectAgent()"> 
                            <option value="All">
                              All
                            </option>
                            <option *ngFor="let item of agents;" [value]="item.Id">
                              {{item.Name}}
                            </option>
                          </select>  
                          <div class="row w-100 m-0">
                              <div  class="col-md-12">
                                <div _ngcontent-ols-c2="" class="card-body p-5">
                                  <div [chart]="plans"></div>                       
                                </div>                
                            </div>     
                          </div>               
                        </div>
                      </div>                
                    </div>     
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </main>
      </div>
    </div>
  </div>
</div>