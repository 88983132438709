import { Injectable } from '@angular/core';
import { Constants } from '../util/constants';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginCredential } from '../models/login-credential.model';
import { map } from 'rxjs/operators' 
import { Agent } from '../models/agent.model';

@Injectable({
	providedIn: 'root'
})
export class SuperuserDashboardDataService {

	constructor(private http: HttpClient ) { }	   

getorganizations(loggedAgent: Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getorganizations',null , httpOptions).pipe()
  }
  
  getlongevityplansummary(loggedAgent: Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.get<any>(environment.server_URL+ environment.router_prefix + '/getlongevityplansummary', httpOptions).pipe()
  }
  
  getorganizationplansummary(loggedAgent: Agent,organizationId: string): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.get<any>(environment.server_URL+ environment.router_prefix + '/getorganizationplansummary/' + organizationId, httpOptions).pipe()
	}
}
