<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg">
	<app-header></app-header>
</nav>
<div class="main-panel pt-0 pb-4">
    <div class="container-fluid" #main [style.padding-top.px]="paddingtop">
        <div class="col-sm-12 px-0 align-self-end">
          <div class="row align-items-center">
            <div class="col-sm-9">
              <h3 class="light-header bold">
                {{ 'FTASTRATEGY.LBL_TITLE' | translate}}
              </h3>
            </div>
            <div class="col-sm-3">
              <button type="button" class="btn btn-primary btn-sm float-right" (click)="exitBtnClickEvent()">
                <i class="fas fa-sign-out"></i> Exit
              </button>
              <button type="button" class="btn btn-primary btn-sm mr-1 float-right" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
                {{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
              </button>
            </div>
          </div>
          <hr class="mt-0 mb-3" />
        </div>
        <div class="pt-3">
          <div class="container border rounded p-3 bold"> 
              <form>
                  <div class="form-group row">
                      <label for="file" class="col-sm-2 col-form-label">Upload Data File</label>
                    <div class="col-sm-5"> 
                       <input type="file" class="form-control bold" id="file"  autocomplete="off"
                       (change)="handleFileInput($event)" value="file" accept=".xlsx, .xls"/> 
                    </div>
                    <div class="float-left col-sm-2">
                    <button type="button" class="btn btn-primary btn-sm float-left" (click)="uploadFileToActivity()">
                        <i class="fas fa-upload"></i> Upload
                    </button>
                    </div>
                    <div class="float-right col-sm-3">
                      <button type="button" class="btn btn-primary btn-sm float-right ml-3" style="width: 210px;" (click)="editCommonDisclosureBtn()">
                          <i class="far fa-file-alt"></i>  Update Common Disclosure
                      </button>
                    </div>
                  </div>
              </form>
              <hr class="mt-0 mb-3" />
          <!--FTA strategy List Start-->
          <h5 class="light-header bold">
            {{ 'FTASTRATEGY.LBL_TABLE_TITLE' | translate}}
  
            <div class="float-right">
              <button type="button" class="btn btn-primary btn-sm float-right ml-1" (click)="deleteBtnClickEvent()">
                <i class="fas fa-minus"></i> Delete
              </button>
              <button type="button" class="btn btn-primary btn-sm float-right ml-1" (click)="copyBtnClickEvent()" >
                  <i class="fas fa-copy"></i> Copy
                </button>
              <button type="button" class="btn btn-primary btn-sm float-right ml-1" (click)="newBtnClickEvent()">
                <i class="fas fa-plus"></i> New
              </button>
              
            </div>
          </h5>
          <hr class="mt-0 mb-3" />
          
          <div id="grid-wrapper" class="col-sm-12 px-0" style="width: 100%; overflow: hidden;">
              <div class="col-sm-12 px-0" >
                <ag-grid-angular style="width: 100%; height: 300px; " class="ag-theme-balham bold"
                  [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" [rowData]="rowData"
                  (gridSizeChanged)="gridSizeChanged($event)"
                  (cellClicked)="onCellClicked($event)" 
                  [headerHeight]="60" [gridOptions]="gridOptions" >
                </ag-grid-angular>
              </div>
          </div> 
          </div> 
          <div class="container border rounded p-3 bold">
            <h5 class="light-header bold">
              {{ 'FTASTRATEGY.LBL_FORM_TITLE' | translate}}
            </h5>
            <hr class="mt-0 mb-3" />
            <form [formGroup]="iSForm">
              <div class="form-group row">
                <label for="Id" class="col-sm-2 col-form-label">ID</label>
                <div class="col-sm-10"> 
                   <input type="text" class="form-control bold" id="Identity"  autocomplete="off"
                         [(ngModel)]="identity"  formControlName="identity"/> 
                </div>
              </div>
              <div class="form-group row">
                <label for="Name" class="col-sm-2 col-form-label">Name</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control bold" id="name"   autocomplete="off"
                        [(ngModel)]="name"  formControlName="name"/> 
                </div>
              </div>
              <div class="form-group row">
                <label for="Begin_date" class="col-sm-2 col-form-label">Begin Date</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control bold" bsDatepicker [ngModel] = "begindate"
                    (bsValueChange)="onBeginDateChange($event)" formControlName="begindate"
                    value="{{ begindate | date:'MM/dd/yyyy' }}"
                    [bsConfig]="{ containerClass: 'theme-default' }"
                    id="begindate">
                </div>
              </div>
              <div class="form-group row">
                <label for="Split_date" class="col-sm-2 col-form-label">Split Date</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control bold" bsDatepicker [ngModel] = "splitdate"
                    (bsValueChange)="onSplitDateChange($event)" formControlName="splitdate"
                    value="{{ splitdate | date:'MM/dd/yyyy' }}"
                    [bsConfig]="{ containerClass: 'theme-default' }"
                    id="splitdate">
                </div>
              </div>
              <div class="form-group row">
                <label for="Separation_Name" class="col-sm-2 col-form-label">Separation Name</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control bold" id="separationname" autocomplete="off"
                        [(ngModel)]="separationname"  formControlName="separationname"/> 
                </div>
              </div>
              <div class="form-group row">
                <label for="disclosure" class="col-sm-2 col-form-label">Disclosure</label>
                <div class="col-sm-10">
                    <textarea class="form-control bold"  rows="15" [(ngModel)]="disclosure" formControlName="disclosure"
                    name="disclosure" id="disclosure"></textarea> 
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-0">
                  <button type="button" class="btn btn-primary btn-sm" (click)="saveBtnClickEvent()"> <i class="far fa-save"></i> Save</button>
                </div>
                <div class="form-group col-md-0">
                  <button type="button" class="btn btn-primary btn-sm" (click)="editBtnClickEvent()"> <i class="far fa-dollar-sign"></i> Edit Data</button>
                </div>
                <div class="form-group col-md-0">
                  <button type="button" class="btn btn-primary btn-sm" (click)="cancelBtnClickEvent()"> <i class="far fa-times"></i> Cancel</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <ng-template #editFTAStrategyDetails>
      <div class="modal-header">
          <h5 class="modal-title">Edit FTA Strategy Data</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelModal()">
            <span aria-hidden="true">×</span>
          </button>
      </div>
      <div class="modal-body">
      <div class="container-fluid bold">
          <h6>{{name}}</h6>
            <div>
              <div id="grid-wrapper" class="col-sm-12 px-0" style="width: 100%; overflow: hidden;">
                <div class="col-sm-12 px-0" >
                  <ag-grid-angular style="width: 100%; height: 300px; " class="ag-theme-balham bold"
                    [columnDefs]="columnDefs2" (gridReady)="onGridReady2($event)" [rowData]="rowData2"
                    (gridSizeChanged)="gridSizeChanged($event)"
                    (cellValueChanged)="onCellValueChanged($event)" 
                    [headerHeight]="60" [gridOptions]="gridOptions2" >
                  </ag-grid-angular>
                </div>
            </div>
            </div>
        </div>
      </div>
      <div class="modal-footer">
          <div class="form-group col-md-0">
              <button type="button" class="btn btn-primary btn-sm" (click)="saveStrategyDetails()" [disabled]="invalidDataVal"> <i class="far fa-save"></i> Save</button>
            </div>
            <div class="form-group col-md-0">
              <button type="button" class="btn btn-primary btn-sm" (click)="cancelModal()"> <i class="far fa-times"></i> Cancel</button>
            </div>
      </div>
    </ng-template>

    <ng-template #editCommonDisclosure>
      <div class="modal-header">
          <h5 class="modal-title">Edit Common Disclosure</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelDisclosureModal()">
            <span aria-hidden="true">×</span>
          </button>
      </div>
      <div class="modal-body">
      <form [formGroup]="iSForm">
        <div class="container-fluid bold">
            <textarea class="form-control bold"  rows="18" [(ngModel)]="commondisclosure" formControlName="commondisclosure"
            name="commondisclosure" id="commondisclosure"></textarea> 
        </div>
      </form>
      </div>
      <div class="modal-footer">
          <div class="form-group col-md-0">
              <button type="button" class="btn btn-primary btn-sm" (click)="saveCommonDisclosure()" [disabled]="invalidDataVal"> <i class="far fa-save"></i> Save</button>
            </div>
            <div class="form-group col-md-0">
              <button type="button" class="btn btn-primary btn-sm" (click)="cancelDisclosureModal()"> <i class="far fa-times"></i> Cancel</button>
            </div>
      </div>
    </ng-template>