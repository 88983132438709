
<div class="container-fluid">
	<div class="row">
		<main role="main" class="px-0 col-md-10 vh-100 main-wrapper" [ngClass]="{'col-md-10': isSideBar, 'col-md-12': !isSideBar}" >
      
      
      <div class="row fp4fa-wrapper h-100 w-100 p-4 m-0" [ngClass]="{'expand': status, 'resize': !status, 'status resize': status && resizestatus  }" >

     <div class="scroll-wrapper  w-100" >
        <button type="button" class="btn btn-primary btn-sm float-right mr-3" (click)="exitBtnClickEvent()">
            <i class="fas fa-sign-out"></i> Exit
        </button>
        <button type="button" class="btn btn-primary btn-sm mr-1 float-right" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
          {{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
        </button>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Expense</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{currentStepperName}}</li>
          </ol>
        </nav>
<div class="row vh-100 w-100 m-0 justify-content-center">
  <div class="col-md-12">


    <div id="stepper-expense" class="bs-stepper">
      <div class="bs-stepper-header table-responsive">
        <div class="step" data-target="#wizard-employees" (click)="changeStepper(0)">
          <button class="step-trigger">
            <span class="bs-stepper-label">Employees</span>
          </button>
        </div>
        <div class="line"></div>
        <div class="step" data-target="#wizard-pt" (click)="changeStepper(1)">
          <button class="step-trigger">
            <span class="bs-stepper-label">Payroll Taxes</span>
          </button>
        </div>
        <div class="line"></div>
        <div class="step" data-target="#wizard-opt" (click)="changeStepper(2)">
          <button class="step-trigger">
            <span class="bs-stepper-label">Other Payroll Items</span>
          </button>
        </div>
        <div class="line"></div>
       <!--  <div class="step" data-target="#wizard-rpp" (click)="changeStepper(3)">
          <button class="step-trigger">
            <span class="bs-stepper-label">Rep Pay Percentage</span>
          </button>
        </div>
        <div class="line"></div> -->
        <div class="step" data-target="#wizard-rent" (click)="changeStepper(3)">
          <button class="step-trigger">
            <span class="bs-stepper-label">Rent</span>
          </button>
        </div>
        <div class="line"></div>
        <div class="step" data-target="#wizard-insurance" (click)="changeStepper(4)">
          <button class="step-trigger">
            <span class="bs-stepper-label">Business Insurance</span>
           <!--  <div class="progress w-100">
              <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div> -->
          </button>
        </div>
        <div class="line"></div>
        <div class="step" data-target="#wizard-oe" (click)="changeStepper(5)">
          <button class="step-trigger">
            <span class="bs-stepper-label">Other Expenses</span>
          </button>
        </div>
      </div>
      <div class="row w-100 m-0 p-0 bs-stepper-content">
        <form class="w-100" (ngSubmit)="onSubmit()" [formGroup]="iSForm">
          <div id="wizard-employees" class="content  w-100 mt-3 p-0">
            <div class="card widget p-4">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group pt-1">
                    <label for="">Employee Type<i class="fas fa-question-circle ml-2 text-muted" tooltip="Enter the category of employee, if not available, choose other. "></i></label>
                    <select class="form-control"
                    (change)=setEmployeeType($event) [(ngModel)]="emptype" formControlName="emptype"> 
                      <option *ngFor="let employeetype of employeetypesArray" 
                      [value]="employeetype.id">
                        {{employeetype.text}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3 pt-4" [hidden] ="hidemoreinfo">
                  <div class="form-group pt-2">
                    <div class="input-group value-helper">
                    <input type="text" class="form-control br" id="" placeholder="" (blur)="onBlur()" 
                    [(ngModel)]="moreinfo" id="moreinfo" formControlName="moreinfo"
                      (keyup)="Validations($event)" (focus)="onFocus()" (keyup)="setMoreInfo()"/>
                   </div>
                  </div>
                </div>
                <div class="col-md-3 pt-4">
                  <div class="form-group pt-3">
                    <button type="button" class="btn btn-primary btn-sm" (click)="addEmployee()"> <i class="far fa-plus"></i> Add Employee</button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Jan</th>
                      <th scope="col">Feb</th>
                      <th scope="col">Mar</th>
                      <th scope="col">Apr</th>
                      <th scope="col">May</th>
                      <th scope="col">Jun</th>
                      <th scope="col">Jul</th>
                      <th scope="col">Aug</th>
                      <th scope="col">Sep</th>
                      <th scope="col">Oct</th>
                      <th scope="col">Nov</th>
                      <th scope="col">Dec</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of years;">
                      <th scope="row">{{item}}</th>
                      <td class="text-right" *ngFor="let count of empCountArray;">
                          <!-- <span id="{{item}},{{count.Month}}"></span> -->
                        <input type="text" class="form-control" placeholder="" 
                        (keyup)="getEmpCount($event,item,count.Month)" id="{{item}},{{count.Month}}" disabled/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
            </div>
            <div class="form-group   mt-4 mb-0">
              <button (click)="next()" class="btn  float-right common-btn ghoust-btn"> Continue <i class="fas fa-arrow-right pl-2"></i></button>
             <!--  <button (click)="next()" class="btn  float-right common-btn ghoust-btn black"><i class="fas fa-arrow-left pr-2"></i> Company Configuration </button>  -->
            </div>
            <form>
              <div class="row">
                <label for="staticEmail" class="col-sm-4 col-form-label" [hidden] = "!hidesummary"><b>Employee Summary</b></label>
                <div class="form-group col-md-12 mb-0" *ngFor="let employee of employeesArray;">
                  <div class="row ml-1 mb-0">
                    <button class="btn btn-link green-link" tooltip ="Delete Selected Employee" type="button" (click)="deleteEmployee(employee,false)"><i class="far fa-trash-alt pr-1"></i></button>
                    <label class="col-sm-10 col-form-label">{{employee.Description}}</label>
                  </div>
                </div>
              </div>           
            </form>
          </div>
          <div id="wizard-pt" class="content pb-5">
          <div class="card widget mb-3 mt-3">
            <!--<div class="card-header">
              <div class="w-100 position-relative">
                <h2 class="m-0 font-weight-bold ff-eagle title2 mt-2">Login</h2>
                <p class="mb-2">COH user </p>
              </div>
            </div>-->
            <div class="card-body p-5">
                <div class="form-group col-md-5 pl-0">
                  <label class="small mb-1 onelinelabel" for="">Social Security (Baseline Payroll Tax Estimates - {{socialsecuritydefault}}%)<i class="fas fa-question-circle ml-2 text-muted" tooltip="Federal Social Security tax rate "></i></label>
                  
                    <div class="input-group value-helper">
                      <input type="text" class="form-control" placeholder="" aria-label="Username" 
                      formControlName="socialsecurity" [(ngModel)]="socialsecurity" id="socialsecurity" autocomplete="off"
                      (keyup)="Validations($event)" aria-describedby="basic-addon1" style="text-align:right;">
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon1">%</span>
                      </div>
                      
                    </div>
                </div>
                <div class="form-group col-md-5 pl-0">
                  <label class="small mb-1" for="">Medicare (Baseline Payroll Tax Estimates - {{medicaredefault}}%)<i class="fas fa-question-circle ml-2 text-muted" tooltip="Federal Medicare tax rate "></i></label>
                  <div class="input-group value-helper">
                    <input type="text" class="form-control" placeholder="" aria-label="Username" 
                    formControlName="medicare" [(ngModel)]="medicare" id="medicare" autocomplete="off"
                    (keyup)="Validations($event)" aria-describedby="basic-addon1" style="text-align:right;">
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon1">%</span>
                    </div>
                    
                  </div>
                </div>
                <div class="form-group col-md-5 pl-0">
                  <label class="small mb-1 onelinelabel" for="">Federal Unemployment (Baseline Payroll Tax Estimates - {{federalunemploymentdefault}}%)<i class="fas fa-question-circle ml-2 text-muted" tooltip="Your federal unemployment tax rate "></i></label>
                  <div class="input-group value-helper">
                    <input type="text" class="form-control" placeholder="" aria-label="Username" autocomplete="off"
                    formControlName="federalunemployment" [(ngModel)]="federalunemployment" id="federalunemployment"
                    (keyup)="Validations($event)" aria-describedby="basic-addon1" style="text-align:right;">
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon1">%</span>
                    </div>
                    
                  </div>
                </div>
                <div class="form-group col-md-5 pl-0">
                  <label class="small mb-1 onelinelabel" for="">State Unemployment (Baseline Payroll Tax Estimates - {{stateunemploymentdefault}}%)<i class="fas fa-question-circle ml-2 text-muted" tooltip="Your state unemployment tax rate "></i></label>
                  <div class="input-group value-helper">
                    <input type="text" class="form-control" placeholder="" aria-label="Username" autocomplete="off"
                    formControlName="stateunemployment" [(ngModel)]="stateunemployment" id="stateunemployment"
                    (keyup)="Validations($event)" aria-describedby="basic-addon1" style="text-align:right;">
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon1">%</span>
                    </div>
                    
                  </div>
                </div>
                
                <!-- error field -->
                <!--div class="form-group has-error">
                    <label class="small" for="inputError">Input with error</label>
                    <input type="text" class="form-control py-4" id="inputError">
                    <span class="help-block text-danger">Please correct the error</span>
                </div-->
                <!-- error field -->
      
            </div>
            
          </div>
          <div class="form-group   mt-2 mb-0">
            <button class="btn  float-right common-btn ghoust-btn" (click)="next()"> Continue <i class="fas fa-arrow-right pl-2"></i></button>
            <!--a class="btn btn-primary float-right" href="index.html">Login</a-->
          </div>            
          </div>
          <div id="wizard-opt" class="content">
            <div class="card widget mb-3 mt-3">
              
              <div class="card-body p-5">
                  <div class="form-group col-md-5 pl-0">
                    <label class="small mb-1" for="">COLA Increase (Starting in Year 2)<i class="fas fa-question-circle ml-2 text-muted" tooltip="Cost of Living Adjustment for Employee Salaries "></i></label>
                    
                      <div class="input-group value-helper">
                        <input type="text" class="form-control" placeholder="" aria-label="Username" autocomplete="off"
                        formControlName="COLAIncrease" [(ngModel)]="COLAIncrease" id="COLAIncrease"
                        (keyup)="Validations($event)" aria-describedby="basic-addon1" style="text-align:right;">
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon1">%</span>
                        </div>
                        
                      </div>
                  </div>
                  <div class="form-group col-md-5 pl-0">
                    <label class="small mb-1" for="">Staff Performance Bonus Pool<i class="fas fa-question-circle ml-2 text-muted" tooltip="Percentage of Gross Revenue available for discretionary staff bonuses "></i></label>
                    <div class="input-group value-helper">
                      <input type="text" class="form-control" placeholder="" aria-label="Username" autocomplete="off"
                      formControlName="staffperformancebonuspool" [(ngModel)]="staffperformancebonuspool" id="staffperformancebonuspool"
                        (keyup)="Validations($event)"  aria-describedby="basic-addon1" style="text-align:right;"> 
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon1">%</span>
                      </div>
                      
                    </div>
                  </div>
                  <div class="form-group col-md-5 pl-0">
                    <label class="small mb-1" for="">Insurance Benefits<i class="fas fa-question-circle ml-2 text-muted" tooltip="Insurance benefits cost per employee per month "></i></label>
                    <div class="input-group value-helper">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">$</span>
                      </div>
                      <input type="text" class="form-control br" placeholder="" aria-label="Username" autocomplete="off"
                      formControlName="insurancebenefit" [(ngModel)]="insurancebenefit" id="insurancebenefit"
                        (keyup)="Validations($event)"  aria-describedby="basic-addon1">                       
                    </div>
                  </div>
                  <div class="form-group col-md-5 pl-0">
                    <label class="small mb-1" for="">Retirement Benefits<i class="fas fa-question-circle ml-2 text-muted" tooltip="Retirement benefits employer match on retirement savings per employee per year based on 3% match. "></i></label>
                    <div class="input-group value-helper">
                      <input type="text" class="form-control" placeholder="" aria-label="Username" autocomplete="off"
                      formControlName="retirementbenefit" [(ngModel)]="retirementbenefit" id="retirementbenefit"
                        (keyup)="Validations($event)"  aria-describedby="basic-addon1" style="text-align:right;"> 
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon1">%</span>
                      </div>     
                    </div>
                  </div>
              </div>
              
            </div>
            <div class="form-group   mt-2 mb-0">
              <button class="btn  float-right common-btn ghoust-btn" (click)="next()"> Continue <i class="fas fa-arrow-right pl-2"></i></button>
              <!--a class="btn btn-primary float-right" href="index.html">Login</a-->
            </div>
          </div>
      <!--     <div id="wizard-rpp" class="content">
            <div class="card widget mb-3 mt-3">
              
              <div class="card-body p-5">
                  <div class="form-group col-md-5 pl-0">
                    <label class="small mb-1" for="">Rep Pay Percentage</label>
                    
                      <div class="input-group value-helper">
                        <input type="text" class="form-control" placeholder="" aria-label="Username" 
                        formControlName="reppaypercentage" [(ngModel)]="reppaypercentage" id="reppaypercentage"
                        (keyup)="Validations($event)" aria-describedby="basic-addon1" style="text-align:right;">
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon1">%</span>
                        </div>
                        
                      </div>
                  </div>
              </div>
              
            </div>
            <div class="form-group   mt-2 mb-0">
              <button class="btn  float-right common-btn ghoust-btn" (click)="next()"> Continue <i class="fas fa-arrow-right pl-2"></i></button> 
            </div>
          </div> -->
          <div id="wizard-rent" class="content text-center">
            <div class="card widget mb-3 mt-4">
                <div class="row">
                  <div class="col-md-8">
                    <table class="table m-5">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">Total Square Footage/Yr<i class="fas fa-question-circle ml-2 text-muted" tooltip="Total office square footage per year "></i></th>
                          <th scope="col">Cost/ft2/yr ($)<i class="fas fa-question-circle ml-2 text-muted" tooltip="Average cost per square foot for leased/rented spac "></i></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of rent;">
                          <th scope="row">{{item.Year}}</th>
                          <td><input type="text" class="form-control" 
                            formControlName="totalsquarefootageperyr" id="TotalSquareFootage,{{item.Year}}"
                            (keyup)="setRentPerYear($event)" placeholder="" autocomplete="off"/></td>
                          <td><input type="text" class="form-control"  
                            formControlName="costperft2peryr" id="Cost,{{item.Year}}"
                            (keyup)="setRentPerYear($event)" placeholder="" autocomplete="off"/></td>                         
                        </tr>                        
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
            <div class="form-group mt-2 mb-0">
              <button class="btn  float-right common-btn ghoust-btn" (click)="next()"> Continue <i class="fas fa-arrow-right pl-2"></i></button>
              <!--a class="btn btn-primary float-right" href="index.html">Login</a-->
            </div>
          </div>
          <div id="wizard-insurance" class="content">
            <div class="card widget mb-3 mt-4">
              <!-- <div class="row ml-5 mt-5 mb-0">
                  <div class="col-md-6">
                  </div>
                  <div class="col-md-4 float-right">
                  <button type="button" class="btn btn-primary btn-sm" (click)="FillFirstValue()" style="margin-left: 260px;"> Fill with first year data</button>
                  </div>
                  
              </div> -->
              <div class="row mt-5 mb-0 mr-4">
                  <div class="col-md-11 bg-white text-right ml-5">
                    <button type="button" class="btn btn-primary btn-sm at-right" (click)="FillFirstValue()"> Fill with first year data</button>
                  </div> 
              </div>
              <div class="row" style="margin-right: 30px">
                <div class="col-md-11">
                  <div class="table ml-5 mt-5">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="row"></th>
                          <th scope="row" style="vertical-align: top;">
                            <div class="row-head-with-more position-relative">
                              <span tooltip="Annual insurance premium amount ">Errors and Omissions (E&O) Insurance ($) </span>               
                              <div class="btn-group position-absolute more-actions" dropdown>
                                <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                  <li role="menuitem"><button class="btn" (click)="OpenFillDownModal(templatefillacross,'EAndOInsurance')">Fill down value</button></li> 
                                  <li role="menuitem"><a class="dropdown-item" (click)="ClearDown('EAndOInsurance')">Clear down values</a></li>  
                                </ul>
                              </div>
                            </div>
                          </th>
                          <th scope="row" style="vertical-align: top;">
                            <div class="row-head-with-more position-relative">
                              <span tooltip="Annual insurance premium amount ">Business Liability Insurance ($) </span>               
                              <div class="btn-group position-absolute more-actions" dropdown>
                                <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                  <li role="menuitem"><button class="btn" (click)="OpenFillDownModal(templatefillacross,'BusinessLiabilityInsurance')">Fill down value</button></li> 
                                  <li role="menuitem"><a class="dropdown-item" (click)="ClearDown('BusinessLiabilityInsurance')">Clear down values</a></li>  
                                </ul>
                              </div>
                            </div>
                          </th>
                          <th scope="row" style="vertical-align: top;">
                            <div class="row-head-with-more position-relative">
                              <span tooltip="Annual insurance premium amount ">Worker’s Compensation Insurance ($) </span>               
                              <div class="btn-group position-absolute more-actions" dropdown>
                                <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                  <li role="menuitem"><button class="btn" (click)="OpenFillDownModal(templatefillacross,'WorkersCompensationInsurance')">Fill down value</button></li> 
                                  <li role="menuitem"><a class="dropdown-item" (click)="ClearDown('WorkersCompensationInsurance')">Clear down values</a></li>  
                                </ul>
                              </div>
                            </div>
                          </th>
                          <th scope="row" style="vertical-align: top;">
                            <div class="row-head-with-more position-relative">
                              <span tooltip="Annual insurance premium amount ">Other Insurance Costs ($) </span>               
                              <div class="btn-group position-absolute more-actions" dropdown>
                                <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                  <li role="menuitem"><button class="btn" (click)="OpenFillDownModal(templatefillacross,'OtherInsuranceCosts')">Fill down value</button></li>
                                  <li role="menuitem"><a class="dropdown-item" (click)="ClearDown('OtherInsuranceCosts')">Clear down values</a></li>  
                                </ul>
                              </div>
                            </div>
                          </th>
                          </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of travelmealstrainingperyear;">
                          <th scope="row">{{item.Year}}</th>
                          <td><input type="text" class="form-control" id="EAndOInsurance,{{item.Year}}"
                            (keyup)="setBusinessInsurances($event)" style="width:90%"  placeholder="" autocomplete="off"/></td>
                          <td><input type="text" class="form-control" id="BusinessLiabilityInsurance,{{item.Year}}"
                            (keyup)="setBusinessInsurances($event)" style="width:90%"  placeholder="" autocomplete="off"/></td>
                          <td><input type="text" class="form-control" id="WorkersCompensationInsurance,{{item.Year}}"
                            (keyup)="setBusinessInsurances($event)" style="width:90%"  placeholder="" autocomplete="off"/></td>
                          <td><input type="text" class="form-control br" id="OtherInsuranceCosts,{{item.Year}}"
                            (keyup)="setBusinessInsurances($event)" style="width:90%" placeholder="" autocomplete="off"/></td>
                        </tr>                    
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt-2 mb-0">
              <button class="btn  float-right common-btn ghoust-btn" (click)="next()"> Continue <i class="fas fa-arrow-right pl-2"></i></button>
              <!--a class="btn btn-primary float-right" href="index.html">Login</a-->
            </div>
          </div>
          <div id="wizard-oe" class="content ">
            <div class="card widget mb-3 mt-3 p-5">
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <label class="float-left" for="">IT (Base)<i class="fas fa-question-circle ml-2 text-muted" tooltip="IT expense base amount for office per month "></i></label>
                    <div class="input-group value-helper">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">$</span>
                      </div>
                    <input type="text" class="form-control br" formControlName="itbase" (keyup)="Validations($event)"
                    [(ngModel)]="itbase" id="itbase" placeholder="" autocomplete="off"/>
                  </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label class="float-left" for="">IT (Per EE)<i class="fas fa-question-circle ml-2 text-muted" tooltip="IT expense increase per employee per month "></i></label>
                    <div class="input-group value-helper">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">$</span>
                      </div>
                    <input type="text" class="form-control br" formControlName="itperee" (keyup)="Validations($event)"
                    [(ngModel)]="itperee" id="itperee" placeholder="" autocomplete="off"/>
                  </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label class="float-left" for="">Licensing (Base per Agent)<i class="fas fa-question-circle ml-2 text-muted" tooltip="Base rate of licensing costs annually per agent. "></i></label>
                    <div class="input-group value-helper">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">$</span>
                        </div>
                    <input type="text" class="form-control br" formControlName="licensingbase" (keyup)="Validations($event)"
                    [(ngModel)]="licensingbase" id="licensingbase" placeholder="" autocomplete="off"/>
                  </div>
                  </div>
                </div>

                <div class="col-md-5">
                  <div class="form-group">
                    <label class="float-left" for="">Licensing Cost (Annual Growth Rate)<i class="fas fa-question-circle ml-2 text-muted" tooltip="Growth rate of licensing cost per year, typically runs higher than inflation. "></i></label>
                    <div class="input-group value-helper">
                    <input type="text" class="form-control br" formControlName="licensingrate" (keyup)="Validations($event)"
                    [(ngModel)]="licensingrate" id="licensingrate" placeholder="" autocomplete="off" style="text-align:right;"/>
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon1">%</span>
                    </div>
                  </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label class="float-left" for="">Professional Services – Legal and Accounting<i class="fas fa-question-circle ml-2 text-muted" tooltip="Average annual legal and accounting expenses "></i></label>
                    <div class="input-group value-helper">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">$</span>
                        </div>
                    <input type="text" class="form-control br" formControlName="proservices" (keyup)="Validations($event)"
                    [(ngModel)]="proservices" id="proservices" placeholder="" autocomplete="off"/>
                  </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label class="float-left" for="">Compliance Services<i class="fas fa-question-circle ml-2 text-muted" tooltip="This is an annual amount for ongoing compliance consulting.  If you use our service, it is $99/month. "></i></label>
                    <div class="input-group value-helper">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">$</span>
                        </div>
                    <input type="text" class="form-control br" formControlName="complianceservices" (keyup)="Validations($event)"
                    [(ngModel)]="complianceservices" id="complianceservices" placeholder="" autocomplete="off"/>
                  </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label class="float-left" for="">Supplies (Base)<i class="fas fa-question-circle ml-2 text-muted" tooltip="Office supply base amount for office per month "></i></label>
                    <div class="input-group value-helper">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">$</span>
                        </div>
                    <input type="text" class="form-control br" formControlName="suppliesbase" (keyup)="Validations($event)"
                    [(ngModel)]="suppliesbase" id="suppliesbase" placeholder="" autocomplete="off"/>
                  </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label class="float-left" for="">Supplies (Per EE)<i class="fas fa-question-circle ml-2 text-muted" tooltip="Office supply increase per employee per month "></i></label>
                    <div class="input-group value-helper">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">$</span>
                        </div>
                    <input type="text" class="form-control br" formControlName="suppliesperee" (keyup)="Validations($event)"
                    [(ngModel)]="suppliesperee" id="suppliesperee" placeholder="" autocomplete="off"/>
                  </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label class="float-left" for="">Other<i class="fas fa-question-circle ml-2 text-muted" tooltip="Other expenses not listed separately in this program. "></i></label>
                    <div class="input-group value-helper">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">$</span>
                        </div>
                    <input type="text" class="form-control br" formControlName="other" (keyup)="Validations($event)"
                    [(ngModel)]="other" id="other" placeholder="" autocomplete="off"/>
                  </div>
                  </div>
                </div>
            </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">Travel/Meals ($)<i class="fas fa-question-circle ml-2 text-muted" tooltip="Travel and meal expenses expected per year, outside of Advisormax training events " data-placement="bottom"></i></th>
                          <th scope="col">Training($)<i class="fas fa-question-circle ml-2 text-muted" tooltip="Advisormax recommends each rep come to 4 quarterly trainings, plus a Saint Louis trip for 1 on 1 training.  We estimate each of these trips at a cost of $1000 to $1,500 depending on travel arrangements " data-placement="bottom"></i></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of travelmealstrainingperyear;">
                          <th scope="row">{{item.Year}}</th>
                          <td><input type="text" class="form-control" id="TravelMealsPerYear,{{item.Year}}"
                            (keyup)="setTravelMealsTrainingExpenses($event)" placeholder="" autocomplete="off"/></td>
                          <td><input type="text" class="form-control" id="TrainingPerYear,{{item.Year}}"
                            (keyup)="setTravelMealsTrainingExpenses($event)" placeholder="" autocomplete="off"/></td>
                        </tr>                    
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>             
            </div>
            <div class="form-group   mt-2 mb-0">
              <button class="btn  float-right common-btn ghoust-btn" (click)="next()"> Generate Business Planning Calculator <i class="fas fa-arrow-right pl-2"></i></button>
              <!--a class="btn btn-primary float-right" href="index.html">Login</a-->
            </div>        
          </div>      
        </form>
      </div>
    </div> 
  </div>
</div>   
</div>  

<div class="bottom-bar d-flex hide" [ngClass]="{'visible': saveactionshow, 'notvisible': !saveactionshow  }">  
  <div class="row action-bar align-self-end position-relative">
    <span class="close d-block p-1" (click)="closeBottombar()"><i class="fal fa-times"></i></span>
    <div class="col-md-6">
      <label class="small mb-1 text-muted onelinelabel" for=""><i class="fas fa-info-circle icon-1x pr-2"></i> Click here to save your Business Plan.</label>
    </div>
    <div class="col-md-6">
      <button class="btn  float-right common-btn border-btn" (click)="SaveBusinessPlan(true)"> Save Business Plan </button>
    </div>
  </div> 
</div>  

<div class="right-bar">
  <div class="right-bar-wrapper w-100">
    <div class="tab-list">
      <ul class="list-group list-group-flush" tooltip="Show Business Planning Calculator" data-placement="left" [hidden]="status">
        <li class="list-group-item tab" style="border-width: 0 0 0px;" (click)="toggleRightbar()"><i class="fas fa-calculator"></i></li>
        <li class="list-group-item tab icon-btn" style="border-width: 0 0 0px;" (click)="toggleRightbar()" ><i class="fas fa-arrow-left fa-lg" [hidden]="status"></i></li>
      </ul>
      <ul class="list-group list-group-flush" tooltip="Hide Business Planning Calculator" data-placement="right" [hidden]="!status">
        <li class="list-group-item tab" style="border-width: 0 0 0px;" (click)="toggleRightbar()"><i class="fas fa-calculator" data-placement="right" style="font-size: 24px"></i></li>
        <li class="list-group-item tab icon-btn" style="border-width: 0 0 0px;" (click)="toggleRightbar()"><i class="fas fa-arrow-right fa-lg" [hidden]="!status"></i></li>
      </ul>
    </div>
    <header>
      <div class="btn-group m-1" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-link common-btn icon-btn move-left" (click)="resizeRightbar()"><i class="fas fa-arrow-alt-circle-left fa-lg" tooltip="Click Here to view results" data-placement="bottom" style="font-size: 24px"></i></button>
        <button type="button" class="btn btn-link common-btn icon-btn move-right" (click)="resizeRightbar()"><i class="fas fa-arrow-alt-circle-right fa-lg" tooltip="Click Here to view inputs and results side by side" data-placement="bottom" style="font-size: 24px"></i></button>
        <p class="d-inline p-2 m-0 bold" style="font-size: 16px">Business Planning Calculator</p>
        <button type="button" class="btn btn-link green-link common-btn icon-btn" (click)="refreshCalculator()"><i class="fas fa-redo pr-2 fa-lg"></i> <p class="d-inline p-2 m-0" style="font-size: 16px">Refresh </p></button>
      </div>
      
    </header>
  <app-calculator></app-calculator>  
</div>
</div>
</div>
</main>
</div>
</div>

<ng-template #templateaddEmployeeData>
  <div class="modal-lg">
  <div class="modal-header">
      <h5 class="modal-title">Add Employee</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelModal()">
        <span aria-hidden="true">×</span>
      </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="iSForm">
      <div class="card-body">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                  <label class="" for="">Employee type<i class="fas fa-question-circle ml-2 text-muted" tooltip="Enter the category of employee, if not available, choose other. "></i></label>
                  <div class="input-group value-helper">
                      <!-- <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">$</span>
                      </div> -->
                      <select class="form-control" [(ngModel)]="employeetype" formControlName="employeetype"> 
                          <option *ngFor="let employeetype of employeetypesArray" 
                          [value]="employeetype.id">
                            {{employeetype.text}}
                          </option>
                      </select>
                  </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                  <label class="" for="">Employee First Name<i class="fas fa-question-circle ml-2 text-muted" tooltip="Enter employee first name "></i></label>
                  <div class="input-group value-helper">
                      <!-- <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">$</span>
                      </div> -->
                      <input type="text" class="form-control br" [(ngModel)]="employeefirstname"
                    formControlName="employeefirstname" placeholder="" autocomplete="off"/>
                  </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                  <label class="" for="">Employee Last Name<i class="fas fa-question-circle ml-2 text-muted" tooltip="Enter employee last name "></i></label>
                  <div class="input-group value-helper">
                      <!-- <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">$</span>
                      </div> -->
                      <input type="text" class="form-control br" [(ngModel)]="employeelastname"
                  formControlName="employeelastname" placeholder="" autocomplete="off"/>
                  </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                  <label class="" for="">Annual Salary<i class="fas fa-question-circle ml-2 text-muted" tooltip="Annual gross salary for employee "></i></label>
                  <div class="input-group value-helper">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">$</span>
                      </div>
                      <input type="text" class="form-control br" id="" placeholder="" autocomplete="off" (blur)="onBlur()" 
                  [(ngModel)]="annualsalary" id="annualsalary" formControlName="annualsalary"
                    (keyup)="Validations($event)" (focus)="onFocus()"/>
                  </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                  <label class="" for="">Start Year<i class="fas fa-question-circle ml-2 text-muted" tooltip="Year in which employee is added to payroll "></i></label>
                  <div class="input-group value-helper">
                      <!-- <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">$</span>
                      </div> -->
                      <select class="form-control" [(ngModel)]="startyear" formControlName="startyear"> 
                        <option *ngFor="let item of let item of years;" [value]="item">
                          {{item}}
                        </option>
                      </select>
                  </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                  <label class="" for="">Start Month<i class="fas fa-question-circle ml-2 text-muted" tooltip="Month in which employee is added to payroll "></i></label>
                  <div class="input-group value-helper">
                      <!-- <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">$</span>
                      </div> -->
                      <select class="form-control" [(ngModel)]="startmonth" formControlName="startmonth"> 
                        <option [value]="1">January</option>
                        <option [value]="2">February</option>
                        <option [value]="3">March</option>
                        <option [value]="4">April</option>
                        <option [value]="5">May</option>
                        <option [value]="6">June</option>
                        <option [value]="7">July</option>
                        <option [value]="8">August</option>
                        <option [value]="9">September</option>
                        <option [value]="10">October</option>
                        <option [value]="11">November</option>
                        <option [value]="12">December</option>
                      </select>
                  </div>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                  <label class="" for="">End Year<i class="fas fa-question-circle ml-2 text-muted" tooltip="Year in which employee is terminated. Leave blank to include employee in all years. "></i></label>
                  <div class="input-group value-helper">
                      <!-- <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">$</span>
                      </div> -->
                      <select class="form-control" [(ngModel)]="endyear" formControlName="endyear"> 
                        <option *ngFor="let item of let item of years;" [value]="item">
                          {{item}}
                        </option>
                      </select>
                  </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                  <label class="" for="">End Month<i class="fas fa-question-circle ml-2 text-muted" tooltip="Month in which employee is terminated. Leave blank to include employee in all years. "></i></label>
                  <div class="input-group value-helper">
                      <!-- <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">$</span>
                      </div> -->
                      <select class="form-control" [(ngModel)]="endmonth" formControlName="endmonth"> 
                        <option [value]="1">January</option>
                        <option [value]="2">February</option>
                        <option [value]="3">March</option>
                        <option [value]="4">April</option>
                        <option [value]="5">May</option>
                        <option [value]="6">June</option>
                        <option [value]="7">July</option>
                        <option [value]="8">August</option>
                        <option [value]="9">September</option>
                        <option [value]="10">October</option>
                        <option [value]="11">November</option>
                        <option [value]="12">December</option>
                      </select>
                  </div>
                </div>
            </div>
          </div>
        </div>
  </form>

  </div>
  <div class="modal-footer">
      <div class="form-group col-md-0">
          <button type="button" class="btn btn-primary btn-sm" (click)="saveEmployee(none,true)"> <i class="far fa-plus"></i> Add</button>
        </div>
        <div class="form-group col-md-0">
          <button type="button" class="btn btn-primary btn-sm" (click)="cancelModal()"> <i class="far fa-times"></i> Cancel</button>
        </div>
  </div>
</div>
</ng-template>

<ng-template #templatefilldown> 
    <div class="modal-header">
      <h4 class="modal-title pull-left">Fill Down</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="ModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="iSForm">
        <div class="form-group row">
          <label for="inputPassword" class="col-sm-2 col-form-label">Value</label>
          <div class="col-sm-3">
            <input type="text" class="form-control" id="fillDownValue"  
            [(ngModel)]="fillDownValue" formControlName="fillDownValue" (keyup)="CommaFormatted($event)"  >
          </div>
        </div>
        <div class="form-group row">
        
          <div class="col-md-3  ml-auto">
            <button type="submit" class="btn btn-primary  mb-2 w-100"  (click)="FillDown()">Fill</button>
          </div>
        </div>
      </form>
    </div> 
</ng-template>
