
<div class="container-fluid">
	<div class="row">
	
		<main role="main" class="px-0 col-md-10 vh-100 main-wrapper" [ngClass]="{'col-md-10': isSideBar, 'col-md-12': !isSideBar}" >
      
      
      <div class="row fp4fa-wrapper h-100 w-100 p-4 m-0" [ngClass]="{'expand': status, 'resize': !status, 'status resize': status && resizestatus  }" >

     <div class="scroll-wrapper w-100" >
        <button type="button" class="btn btn-primary btn-sm float-right mr-3" (click)="exitBtnClickEvent()">
            <i class="fas fa-sign-out"></i> Exit
        </button>
        <button type="button" class="btn btn-primary btn-sm mr-1 float-right" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
          {{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
        </button>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Revenue</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{currentStepperName}}</li>
          </ol>
        </nav>
<div class="row vh-100 w-100 m-0 justify-content-center">
  <div class="col-md-12">


    <div id="stepper-revenue" class="bs-stepper">
      <div class="bs-stepper-header table-responsive">
        <div class="step" data-target="#wizard-ot" (click)="changeStepper(0)">
          <button class="step-trigger">
            <span class="bs-stepper-label">Owners Take</span>
          </button>
        </div>
        <div class="line"></div>
        <div class="step" data-target="#wizard-or" (click)="changeStepper(1)">
          <button class="step-trigger">
            <span class="bs-stepper-label">Other Revenue</span>
          </button>
        </div>
        
      </div>
      <div class="row w-100 m-0 p-0 bs-stepper-content">
        <form class="w-100" (ngSubmit)="onSubmit()">
          <div id="wizard-ot" class="content  w-100 mt-3 p-0">
            <div class="card widget p-5">
              <form  [formGroup]="ownersTakeForm"> 
              <div class="row"> 
                <div class="form-group col-md-4 pl-0">
                  <label class="small mb-1" for="">Month to start owner's take<i class="fas fa-question-circle ml-2 text-muted" tooltip="The month in which you will need to start drawing income from the business "></i></label> 
                    <div class="input-group value-helper"> 
                      <input type="text" class="form-control  br" placeholder="" 
                      formControlName="monthToStartOwnersTake" [(ngModel)]="monthToStartOwnersTake" id="monthToStartOwnersTake"
                      (keyup)="CommaFormatted($event)" autocomplete="off"
                      (change)="AssignOwnersTake($event)" aria-describedby="basic-addon1"> 
                    </div>
                   
                </div> 

                <div class="form-group col-md-4 pl-0">
                  <label class="small mb-1" for="">First Year Monthly NET Income Needs<i class="fas fa-question-circle ml-2 text-muted" tooltip="Total amount of income you need to draw from business "></i></label>
                  
                    <div class="input-group value-helper">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">$</span>
                      </div>
                      <input type="text" class="form-control br" placeholder="" 
                      formControlName="firstYearMonthlyNETIncomeNeeds" [(ngModel)]="firstYearMonthlyNETIncomeNeeds" id="firstYearMonthlyNETIncomeNeeds"
                      (keyup)="CommaFormatted($event)" autocomplete="off"
                      (change)="AssignOwnersTake($event)" aria-describedby="basic-addon1"> 
                    </div>
                   
                </div> 
               
              </div>

              <div class="row">
                <div class="col-md-5">
                <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Percentage of Net Income (%)<i class="fas fa-question-circle ml-2 text-muted" tooltip="Percentage of Net Income the owner plans to take from the business "></i></th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let year of ownerstakelist">
                      <th scope="row">{{year.Year}}</th>
                      <td><input type="text" class="form-control text-right"  placeholder="" value="{{year.PercentageOfNetIncome}}"   id="percentageofNetIncome" (keyup)="FormatPercentageofNetIncome($event)" (change)="AssignPercentageofNetIncome($event,year.Year)" autocomplete="off"></td>                      
                    </tr> 
                  </tbody>
                </table>
              </div>

            </div>
              </div> 

              <div class="row">


                <div class="form-group col-md-4 pl-0">
                  <label class="small mb-1" for="">Federal Income Tax<i class="fas fa-question-circle ml-2 text-muted" tooltip="Effective federal tax rate "></i></label> 
                    <div class="input-group value-helper">  
                      <input type="text" class="form-control  br text-right" placeholder="" 
                      formControlName="federalIncomeTax" [(ngModel)]="federalIncomeTax" id="federalIncomeTax"
                      (keyup)="CommaFormatted($event)" autocomplete="off"
                      (change)="AssignOwnersTake($event)" aria-describedby="basic-addon1">
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon1">%</span>
                      </div> 
                    </div>
                   
                </div> 

                <div class="form-group col-md-4 pl-0">
                  <label class="small mb-1" for="">State Income Tax<i class="fas fa-question-circle ml-2 text-muted" tooltip="Effective state tax rate "></i></label> 
                    <div class="input-group value-helper">  
                      <input type="text" class="form-control br text-right" placeholder="" 
                      formControlName="stateIncomeTax" [(ngModel)]="stateIncomeTax" id="stateIncomeTax"
                      (keyup)="CommaFormatted($event)" autocomplete="off"
                      (change)="AssignOwnersTake($event)" aria-describedby="basic-addon1">
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon1">%</span>
                      </div> 
                    </div>
                   
                </div>  
              </div>
              </form>
            </div>
            <div class="form-group   mt-4 mb-0">
              <button (click)="next()" class="btn  float-right common-btn ghoust-btn"> Continue <i class="fas fa-arrow-right pl-2"></i></button>
            </div>
           
          </div>
          <ng-template #templatefillacross> 
              <div class="modal-header">
                <h4 class="modal-title pull-left">Fill Across</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form [formGroup]="fillAcrossForm">
                  <div class="form-group row">
                    <label for="inputPassword" class="col-sm-2 col-form-label">Value</label>
                    <div class="col-sm-3">
                      <input type="text" class="form-control" id="fillAcrossValue"  
                      [(ngModel)]="fillAcrossValue" formControlName="fillAcrossValue" (keyup)="CommaFormatted($event)"  >
                    </div>
                  </div>
                  <div class="form-group row">
                  
                    <div class="col-md-3  ml-auto">
                      <button type="submit" class="btn btn-primary  mb-2 w-100"  (click)="FillAcross()">Fill</button>
                    </div>
                  </div>
                </form>
              </div> 
          </ng-template>       
        <div class="row w-100 m-0 p-0 bs-stepper-content">
          <form class="w-100" (ngSubmit)="onSubmit()" [formGroup]="otherRevenue">
            <div id="wizard-or" class="content  w-100 mt-3 p-0">
              <div class="card widget p-4"> 
                <div class="row">
                    <div class="col-md-4 pt-4">
                      <div class="form-group pt-2">
                        <label class="small mb-1" for="">Existing AUM<i class="fas fa-question-circle ml-2 text-muted" tooltip="Existing AUM "></i></label>
                        
                          <div class="input-group value-helper">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">$</span>
                            </div>
                            <input type="text" class="form-control br" placeholder="" 
                            formControlName="existingAUM" [(ngModel)]="existingAUM" id="existingAUM"
                            (keyup)="CommaFormatted($event)" autocomplete="off"
                            (change)="AssignExistingAUM($event)" aria-describedby="basic-addon1"> 
                          </div>
                      </div> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 pt-4">
                        <div class="form-group pt-2">
                          <label for="">Monthly Amount to fill in all months/years<i class="fas fa-question-circle ml-2 text-muted" tooltip="Amount of gross revenue earned outside of annuity and investment sales. "></i></label>
                          <div class="input-group value-helper">
                          <input type="text" class="form-control br" id="fillAllValue" placeholder="" 
                          [(ngModel)]="fillAllValue" formControlName="fillAllValue"
                           id="fillAllValue" (keyup)="CommaFormatted($event)"/>
                         </div>
                        </div>
                    </div>
                    <div class="col-md-3 pt-5">
                      <div class="form-group pt-3">
                        <button type="button" class="btn btn-primary btn-sm" (click)="FillAll()"> Fill All</button>
                      </div>
                    </div>
                </div>
                <div class="row">
                  <div class="table-responsive">
                  <table class="table rep-tabel">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">
                          <div class="row-head-with-more position-relative">
                              <span>Jan ($) </span>               
                              <div class="btn-group position-absolute more-actions" dropdown>
                                <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options" data-placement="bottom"><i class="fal fa-ellipsis-v"></i></button>
                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                  <li role="menuitem"><button class="btn" (click)="OpenFillDownModal(templatefilldown,'Jan')">Fill down value</button></li> 
                                  <li role="menuitem"><a class="dropdown-item" (click)="ClearDown('Jan')">Clear down values</a></li>  
                                </ul>
                              </div>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="row-head-with-more position-relative">
                                <span>Feb ($) </span>               
                                <div class="btn-group position-absolute more-actions" dropdown>
                                  <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options" data-placement="bottom"><i class="fal fa-ellipsis-v"></i></button>
                                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                    <li role="menuitem"><button class="btn" (click)="OpenFillDownModal(templatefilldown,'Feb')">Fill down value</button></li> 
                                    <li role="menuitem"><a class="dropdown-item" (click)="ClearDown('Feb')">Clear down values</a></li>  
                                  </ul>
                                </div>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="row-head-with-more position-relative">
                                <span>Mar ($) </span>               
                                <div class="btn-group position-absolute more-actions" dropdown>
                                  <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options" data-placement="bottom"><i class="fal fa-ellipsis-v"></i></button>
                                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                    <li role="menuitem"><button class="btn" (click)="OpenFillDownModal(templatefilldown,'Mar')">Fill down value</button></li> 
                                    <li role="menuitem"><a class="dropdown-item" (click)="ClearDown('Mar')">Clear down values</a></li>  
                                  </ul>
                                </div>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="row-head-with-more position-relative">
                                <span>Apr ($) </span>               
                                <div class="btn-group position-absolute more-actions" dropdown>
                                  <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options" data-placement="bottom"><i class="fal fa-ellipsis-v"></i></button>
                                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                    <li role="menuitem"><button class="btn" (click)="OpenFillDownModal(templatefilldown,'Apr')">Fill down value</button></li> 
                                    <li role="menuitem"><a class="dropdown-item" (click)="ClearDown('Apr')">Clear down values</a></li>  
                                  </ul>
                                </div>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="row-head-with-more position-relative">
                                <span>May ($) </span>               
                                <div class="btn-group position-absolute more-actions" dropdown>
                                  <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options" data-placement="bottom"><i class="fal fa-ellipsis-v"></i></button>
                                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                    <li role="menuitem"><button class="btn" (click)="OpenFillDownModal(templatefilldown,'May')">Fill down value</button></li> 
                                    <li role="menuitem"><a class="dropdown-item" (click)="ClearDown('May')">Clear down values</a></li>  
                                  </ul>
                                </div>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="row-head-with-more position-relative">
                                <span>Jun ($) </span>               
                                <div class="btn-group position-absolute more-actions" dropdown>
                                  <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options" data-placement="bottom"><i class="fal fa-ellipsis-v"></i></button>
                                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                    <li role="menuitem"><button class="btn" (click)="OpenFillDownModal(templatefilldown,'Jun')">Fill down value</button></li> 
                                    <li role="menuitem"><a class="dropdown-item" (click)="ClearDown('Jun')">Clear down values</a></li>  
                                  </ul>
                                </div>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="row-head-with-more position-relative">
                                <span>Jul ($) </span>               
                                <div class="btn-group position-absolute more-actions" dropdown>
                                  <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options" data-placement="bottom"><i class="fal fa-ellipsis-v"></i></button>
                                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                    <li role="menuitem"><button class="btn" (click)="OpenFillDownModal(templatefilldown,'Jul')">Fill down value</button></li> 
                                    <li role="menuitem"><a class="dropdown-item" (click)="ClearDown('Jul')">Clear down values</a></li>  
                                  </ul>
                                </div>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="row-head-with-more position-relative">
                                <span>Aug ($) </span>               
                                <div class="btn-group position-absolute more-actions" dropdown>
                                  <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options" data-placement="bottom"><i class="fal fa-ellipsis-v"></i></button>
                                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                    <li role="menuitem"><button class="btn" (click)="OpenFillDownModal(templatefilldown,'Aug')">Fill down value</button></li> 
                                    <li role="menuitem"><a class="dropdown-item" (click)="ClearDown('Aug')">Clear down values</a></li>  
                                  </ul>
                                </div>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="row-head-with-more position-relative">
                                <span>Sep ($) </span>               
                                <div class="btn-group position-absolute more-actions" dropdown>
                                  <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options" data-placement="bottom"><i class="fal fa-ellipsis-v"></i></button>
                                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                    <li role="menuitem"><button class="btn" (click)="OpenFillDownModal(templatefilldown,'Sep')">Fill down value</button></li> 
                                    <li role="menuitem"><a class="dropdown-item" (click)="ClearDown('Sep')">Clear down values</a></li>  
                                  </ul>
                                </div>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="row-head-with-more position-relative">
                                <span>Oct ($) </span>               
                                <div class="btn-group position-absolute more-actions" dropdown>
                                  <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options" data-placement="bottom"><i class="fal fa-ellipsis-v"></i></button>
                                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                    <li role="menuitem"><button class="btn" (click)="OpenFillDownModal(templatefilldown,'Oct')">Fill down value</button></li> 
                                    <li role="menuitem"><a class="dropdown-item" (click)="ClearDown('Oct')">Clear down values</a></li>  
                                  </ul>
                                </div>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="row-head-with-more position-relative">
                                <span>Nov ($) </span>               
                                <div class="btn-group position-absolute more-actions" dropdown>
                                  <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options" data-placement="bottom"><i class="fal fa-ellipsis-v"></i></button>
                                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                    <li role="menuitem"><button class="btn" (click)="OpenFillDownModal(templatefilldown,'Nov')">Fill down value</button></li> 
                                    <li role="menuitem"><a class="dropdown-item" (click)="ClearDown('Nov')">Clear down values</a></li>  
                                  </ul>
                                </div>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="row-head-with-more position-relative">
                                <span>Dec ($) </span>               
                                <div class="btn-group position-absolute more-actions" dropdown>
                                  <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options" data-placement="bottom"><i class="fal fa-ellipsis-v"></i></button>
                                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                    <li role="menuitem"><button class="btn" (click)="OpenFillDownModal(templatefilldown,'Dec')">Fill down value</button></li> 
                                    <li role="menuitem"><a class="dropdown-item" (click)="ClearDown('Dec')">Clear down values</a></li>  
                                  </ul>
                                </div>
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr *ngFor="let year of yeardatalist">
                        <th scope="row">
                          <div class="row-head-with-more position-relative">
                            <span>{{year.Year}} </span>               
                            <div class="btn-group position-absolute more-actions" dropdown>
                              <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options" data-placement="top"><i class="fal fa-ellipsis-v"></i></button>
                              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,year.Year)">Fill across value</button></li> 
                                <li role="menuitem"><a class="dropdown-item" (click)="ClearAcross(year.Year)">Clear across values</a></li>  
                              </ul>
                            </div>
                          </div>
                        </th>
                          <td> 
                              <input type="text" class="form-control"  value={{GetOtherRevenueAmount(year.Year,1)}}   
                                  (keyup)="FormatOtherRevenueAmount($event)" (change)="AssignOtherRevenue($event,year.Year,1)" 
                                  aria-describedby="basic-addon1">  
                          </td>
                          <td> 
                              <input type="text" class="form-control"  value={{GetOtherRevenueAmount(year.Year,2)}}   
                                  (keyup)="FormatOtherRevenueAmount($event)" (change)="AssignOtherRevenue($event,year.Year,2)" 
                                  aria-describedby="basic-addon1">  
                          </td>
                          <td> 
                              <input type="text" class="form-control"  value={{GetOtherRevenueAmount(year.Year,3)}}   
                                  (keyup)="FormatOtherRevenueAmount($event)" (change)="AssignOtherRevenue($event,year.Year,3)" 
                                  aria-describedby="basic-addon1">  
                          </td>
                          <td> 
                              <input type="text" class="form-control"  value={{GetOtherRevenueAmount(year.Year,4)}}   
                                  (keyup)="FormatOtherRevenueAmount($event)" (change)="AssignOtherRevenue($event,year.Year,4)" 
                                  aria-describedby="basic-addon1">  
                          </td>
                          <td> 
                              <input type="text" class="form-control"  value={{GetOtherRevenueAmount(year.Year,5)}}   
                                  (keyup)="FormatOtherRevenueAmount($event)" (change)="AssignOtherRevenue($event,year.Year,5)" 
                                  aria-describedby="basic-addon1">  
                          </td>
                          <td> 
                              <input type="text" class="form-control"  value={{GetOtherRevenueAmount(year.Year,6)}}   
                                  (keyup)="FormatOtherRevenueAmount($event)" (change)="AssignOtherRevenue($event,year.Year,6)" 
                                  aria-describedby="basic-addon1">  
                          </td>
                          <td> 
                              <input type="text" class="form-control"  value={{GetOtherRevenueAmount(year.Year,7)}}   
                                  (keyup)="FormatOtherRevenueAmount($event)" (change)="AssignOtherRevenue($event,year.Year,7)" 
                                  aria-describedby="basic-addon1">  
                          </td>
                          <td> 
                              <input type="text" class="form-control"  value={{GetOtherRevenueAmount(year.Year,8)}}   
                                  (keyup)="FormatOtherRevenueAmount($event)" (change)="AssignOtherRevenue($event,year.Year,8)" 
                                  aria-describedby="basic-addon1">  
                          </td>
                          <td> 
                              <input type="text" class="form-control"  value={{GetOtherRevenueAmount(year.Year,9)}}   
                                  (keyup)="FormatOtherRevenueAmount($event)" (change)="AssignOtherRevenue($event,year.Year,9)" 
                                  aria-describedby="basic-addon1">  
                          </td>
                          <td> 
                              <input type="text" class="form-control"  value={{GetOtherRevenueAmount(year.Year,10)}}   
                                  (keyup)="FormatOtherRevenueAmount($event)" (change)="AssignOtherRevenue($event,year.Year,10)" 
                                  aria-describedby="basic-addon1">  
                          </td>
                          <td> 
                              <input type="text" class="form-control"  value={{GetOtherRevenueAmount(year.Year,11)}}   
                                  (keyup)="FormatOtherRevenueAmount($event)" (change)="AssignOtherRevenue($event,year.Year,11)" 
                                  aria-describedby="basic-addon1">  
                          </td>
                          <td> 
                              <input type="text" class="form-control"  value={{GetOtherRevenueAmount(year.Year,12)}}   
                                  (keyup)="FormatOtherRevenueAmount($event)" (change)="AssignOtherRevenue($event,year.Year,12)" 
                                  aria-describedby="basic-addon1">  
                          </td> 
                        </tr>  
                    </tbody>
                  </table>
                </div>
                </div>
                
              </div> 
              <div class="form-group   mt-2 mb-0">
                <button class="btn  float-right common-btn ghoust-btn" (click)="next()"> Continue <i class="fas fa-arrow-right pl-2"></i></button>
                <!--a class="btn btn-primary float-right" href="index.html">Login</a-->
              </div>       
            </div>
          </form>
        </div>
        </form>
      </div>
    </div>


    
  </div>
</div>
        
     
</div>  

 
 <div class="bottom-bar d-flex hide" [ngClass]="{'visible': saveactionshow, 'notvisible': !saveactionshow  }">  
  <div class="row action-bar align-self-end position-relative">
    <span class="close d-block p-1" (click)="closeBottombar()"><i class="fal fa-times"></i></span>
    <div class="col-md-6">
      <label class="small mb-1 text-muted onelinelabel" for=""><i class="fas fa-info-circle icon-1x pr-2"></i> Click here to save your Business Plan.</label>
    </div>
    <div class="col-md-6">
      <button class="btn  float-right common-btn border-btn" (click)="SaveBusinessPlan(true)"> Save Business Plan </button>
    </div>
  </div> 
</div>  

<div class="right-bar">
  <div class="right-bar-wrapper w-100">
    <div class="tab-list">
      <ul class="list-group list-group-flush" tooltip="Show Business Planning Calculator" data-placement="left" [hidden]="status">
        <li class="list-group-item tab" style="border-width: 0 0 0px;" (click)="toggleRightbar()"><i class="fas fa-calculator"></i></li>
        <li class="list-group-item tab icon-btn" style="border-width: 0 0 0px;" (click)="toggleRightbar()" ><i class="fas fa-arrow-left fa-lg" [hidden]="status"></i></li>
      </ul>
      <ul class="list-group list-group-flush" tooltip="Hide Business Planning Calculator" data-placement="right" [hidden]="!status">
        <li class="list-group-item tab" style="border-width: 0 0 0px;" (click)="toggleRightbar()"><i class="fas fa-calculator" data-placement="right" style="font-size: 24px"></i></li>
        <li class="list-group-item tab icon-btn" style="border-width: 0 0 0px;" (click)="toggleRightbar()"><i class="fas fa-arrow-right fa-lg" [hidden]="!status"></i></li>
      </ul>
    </div>
  <header>
    <div class="btn-group m-1" role="group" aria-label="Basic example">
      <button type="button" class="btn btn-link common-btn icon-btn move-left" (click)="resizeRightbar()"><i class="fas fa-arrow-alt-circle-left fa-lg" tooltip="Click Here to view results" data-placement="bottom" style="font-size: 24px"></i></button>
      <button type="button" class="btn btn-link common-btn icon-btn move-right" (click)="resizeRightbar()"><i class="fas fa-arrow-alt-circle-right fa-lg" tooltip="Click Here to view inputs and results side by side" data-placement="bottom" style="font-size: 24px"></i></button>
      <p class="d-inline p-2 m-0 bold" style="font-size: 16px">Business Planning Calculator</p>
      <button type="button" class="btn btn-link green-link common-btn icon-btn" (click)="refreshCalculator()"><i class="fas fa-redo pr-2 fa-lg"></i> <p class="d-inline p-2 m-0" style="font-size: 16px">Refresh </p></button>
    </div>
    
  </header>
  <app-calculator></app-calculator>
</div>
</div>


      </div>
      
			
		</main>
	</div>
</div>

<ng-template #templatefilldown> 
  <div class="modal-header">
    <h4 class="modal-title pull-left">Fill Down</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="fillDownForm">
      <div class="form-group row">
        <label for="inputPassword" class="col-sm-2 col-form-label">Value</label>
        <div class="col-sm-3">
          <input type="text" class="form-control" id="fillDownValue"  
          [(ngModel)]="fillDownValue" formControlName="fillDownValue" (keyup)="CommaFormatted($event)"  >
        </div>
      </div>
      <div class="form-group row">
      
        <div class="col-md-3  ml-auto">
          <button type="submit" class="btn btn-primary  mb-2 w-100"  (click)="FillDown()">Fill</button>
        </div>
      </div>
    </form>
  </div> 
</ng-template>