import { Injectable } from '@angular/core';
import { Constants } from '../../util/constants';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginCredential } from '../../models/login-credential.model';
import { Agent } from '../../models/agent.model';
import { Client } from '../../models/client.model';
import { Choice } from '../../models/choice.model';
import { Proxy } from '../../models/proxy.model';
import { RiskGraphRequest } from '../../models/risk-graph-request.model'; 
import { InvestmentReportRequest } from '../../models/investment-report-request.model'; 
import { map } from 'rxjs/operators' 
import { HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ClientInfoDataService {

	constructor(private http: HttpClient ) { }

    
    getclientbyid(clientID : string ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getclientbyid/' + clientID,null , httpOptions).pipe()
	}

	saveclient(client : Client ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/saveclient',JSON.stringify(client) , httpOptions).pipe()
	}

	getincomeforlifereportdata(choiceid : string ,client : Client,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			}),
			observe: 'response' as 'response'
		} 
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getincomeforlifereportdata/' + choiceid,JSON.stringify(client) , httpOptions).pipe()
	}

	getgeneratedpdf(choiceid : string ,client : Client,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/pdf', 
				 'Content-type': 'application/json' 
				 
			}),
			responseType: 'arraybuffer'
			//responseType: 'application/pdf'
		}   

		 return this.http.post(environment.server_URL+ environment.router_prefix + '/getgeneratedpdf/' + choiceid,JSON.stringify(client) , {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/pdf', 
				 'Content-type': 'application/json' 				 
			}),
			responseType: 'arraybuffer' 
		}) // had to add inline httpoptions
		// return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getgeneratedpdf/' + choiceid,JSON.stringify(client) , httpOptions )
	
	}

	deleteclient(client : Client ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				'authorization': auth,
				'Accept': 'application/json',
				'Content-type': 'application/json'
		   })
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/deleteclient', JSON.stringify(client),  httpOptions).pipe()
		 
	
		} 
		
	updatechoice(choicelist : Array<Choice> ,loggedAgent:Agent): Observable<any> {
			var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
			const httpOptions = {
				headers: new HttpHeaders({ 
					 'authorization': auth,
					 'Accept': 'application/json',
					 'Content-type': 'application/json'
				})
			}
			return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/updatechoice',JSON.stringify(choicelist) , httpOptions).pipe()
		}

	deletechoice(choiceID : string ,loggedAgent:Agent): Observable<any> {
			var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
			const httpOptions = {
				headers: new HttpHeaders({ 
					 'authorization': auth
				})
			}
			 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/deletechoice/' + choiceID,null , httpOptions).pipe()
			 
		
			} 
			
	updateaccounttables(choiceid : string ,client : Client,loggedAgent:Agent): Observable<any> { 
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
			const httpOptions = {
				headers: new HttpHeaders({ 
					 'authorization': auth,
					 'Accept': 'application/json',
					 'Content-type': 'application/json'
				})
			}
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/updateaccounttables/' + choiceid,JSON.stringify(client) , httpOptions).pipe()
	}

	hashistoricaldata(ticker : string ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
		} 
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/hashistoricaldata/' + ticker,null , httpOptions).pipe()
	}

	getrecommendedproxies(ticker : string ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getrecommendedproxies/' + ticker,null , httpOptions).pipe()
	}

	saveproxy(proxy : Proxy ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password) 

		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/saveproxy',JSON.stringify(proxy) , httpOptions).pipe()
	}

	getgeneratedriskgraphpdf(riskgraphrequest : RiskGraphRequest,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/pdf', 
				 'Content-type': 'application/json' 
				 
			}),
			responseType: 'arraybuffer'
			//responseType: 'application/pdf'
		}   
 
		 return this.http.post(environment.server_URL+ environment.router_prefix + '/getgeneratedriskgraph',JSON.stringify(riskgraphrequest) , {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/pdf', 
				 'Content-type': 'application/json' 				 
			}),
			responseType: 'arraybuffer' 
		}) // had to add inline httpoptions
		// return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getgeneratedpdf/' + choiceid,JSON.stringify(client) , httpOptions )
	
	}

	getproductlistforoldinvestmentreport(loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
		} 
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getproductlistforoldinvestmentreport/' + loggedAgent.AgentID,null , httpOptions).pipe()
	}

	getproductlistforinvestmentreport(loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
		} 
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getproductlistforinvestmentreport/' + loggedAgent.AgentID,null , httpOptions).pipe()
	}

	getindexlistforinvestmentreport(loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
		} 
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getindexlistforinvestmentreport/' + loggedAgent.AgentID,null , httpOptions).pipe()
	}

	gettickerblendlistforinvestmentreport(loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
		} 
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/gettickerblendlistforinvestmentreport/' + loggedAgent.AgentID,null , httpOptions).pipe()
	}

	getindexes(loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
        }
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getindexes',null , httpOptions).pipe()
	}


	getgeneratedinvestmentreportpdf(investmentreportrequest : InvestmentReportRequest,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/pdf', 
				 'Content-type': 'application/json' 
				 
			}),
			responseType: 'arraybuffer'
			//responseType: 'application/pdf'
		}   

		 return this.http.post(environment.server_URL+ environment.router_prefix + '/getgeneratedinvestmentreport',JSON.stringify(investmentreportrequest) , {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/pdf', 
				 'Content-type': 'application/json' 				 
			}),
			responseType: 'arraybuffer' 
		}) // had to add inline httpoptions
		// return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getgeneratedpdf/' + choiceid,JSON.stringify(client) , httpOptions )
	
	}

	getgeneratedoldinvestmentreportpdf(investmentreportrequest : InvestmentReportRequest,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/pdf', 
				 'Content-type': 'application/json' 
				 
			}),
			responseType: 'arraybuffer'
			//responseType: 'application/pdf'
		}   

		 return this.http.post(environment.server_URL+ environment.router_prefix + '/getgeneratedoldinvestmentreport',JSON.stringify(investmentreportrequest) , {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/pdf', 
				 'Content-type': 'application/json' 				 
			}),
			responseType: 'arraybuffer' 
		}) // had to add inline httpoptions
		// return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getgeneratedpdf/' + choiceid,JSON.stringify(client) , httpOptions )
	
	}

	gettiingoutilitysearch(ticker : string ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
		} 
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/gettiingoutilitysearch/' + ticker,null , httpOptions).pipe()
	}

	saveinvestmentreport(investmentreportrequest : InvestmentReportRequest,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/saveinvestmentreport',JSON.stringify(investmentreportrequest) , httpOptions).pipe()
	}

	saveriskgraph(riskgraphrequest : RiskGraphRequest,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/saveriskgraph',JSON.stringify(riskgraphrequest) , httpOptions).pipe()
	}

	getorganizations(loggedAgent: Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getorganizations',null , httpOptions).pipe()
  	}

  	getagents(selectedorganization: String, loggedAgent: Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				'authorization': auth,
				'Accept': 'application/json',
				'Content-type': 'application/json'
			})
		}
	 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getagents/' + selectedorganization,null , httpOptions).pipe()
	}

	getwealthreportdata(choiceid : string ,client : Client,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
		} 
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getwealthreportdata/' + choiceid,JSON.stringify(client) , httpOptions).pipe()
	}

	generatewealthreportpdf(choiceid : string ,client : Client,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/pdf', 
				 'Content-type': 'application/json' 
				 
			}),
			responseType: 'arraybuffer'
			//responseType: 'application/pdf'
		}   

		 return this.http.post(environment.server_URL+ environment.router_prefix + '/generatewealthreportpdf/' + choiceid,JSON.stringify(client) , {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/pdf', 
				 'Content-type': 'application/json' 				 
			}),
			responseType: 'arraybuffer' 
		}) // had to add inline httpoptions
		// return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getgeneratedpdf/' + choiceid,JSON.stringify(client) , httpOptions )
	
	}

	getassettypes(loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
        }
		 return this.http.get<any>(environment.server_URL+ environment.router_prefix + '/getassettypes', httpOptions).pipe()
	}

	updatepresentedtoclient(choiceid : string ,client : Client,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/updatepresentedtoclient/' + choiceid,JSON.stringify(client),httpOptions).pipe()
	}

	undopresentedtoclient(choiceid : string, client : Client,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/undopresentedtoclient/' + choiceid ,JSON.stringify(client),httpOptions).pipe()
	}
}
