import { Choice } from './choice.model';
import { IncomeSourceAccount } from './income-source-accout.model';
import { IncomeFromAsset } from './income-from-asset.model';
import { IncomeForLife } from './income-for-life.model';

export class Client {
    constructor(
        public ClientID?: string,
        public AgentID?: string,
        public OrganizationID?: string,
        public ClientFirstName?: string,
        public ClientLastName?: string,
        public ClientDOB?: Date,
        public ClientRetirementAge?: Number,
        public SpouseFirstName?: string,
        public SpouseLastName?: string,
        public SpouseDOB?: Date,
        public SpouseRetirementAge?: Number,
        public GeneratedOn?: Date,
        public Comment?: string,
        public FinancialConcerns?: string,
        public ClientRetirementDate?: Date,
        public SpouseRetirementDate?: Date,
        public Choice?: Array<Choice>,
        public IncomeSourceAccount?: Array<IncomeSourceAccount>,
        public IncomeFromAsset?: Array<IncomeFromAsset>,
        public IncomeForLife?: Array<IncomeForLife>,
        public ClientFullName?: string,
        public CreatedBy?: string,
        public bDeleted?: boolean,
        public DeletedBy?: string,
        public DeletedOn?: Date,
    ) { }
}