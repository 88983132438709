import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';
import { Constants } from '../../util/constants' 
import { Client } from '../../models/client.model';
import { Agent } from '../../models/agent.model';
import { LocalStorage } from '../../util/localstorage.service'; 
import { ClientInfoDataService } from './../client-info/client-info-data.service';
import { AlertService } from '../../util/alert/alert.service';
import { Choice } from 'src/app/models/choice.model';
import { SharedServiceService } from '../../util/shared-service.service';
import { Chart, StockChart } from 'angular-highcharts';
import { WealthReport, WealthReportData, TotalNetWorthData } from 'src/app/models/wealth-report.model';
import { IncomeForLife } from 'src/app/models/income-for-life.model';
import { IncomeFromAsset } from 'src/app/models/income-from-asset.model';
import { IncomeSourceAccount } from 'src/app/models/income-source-accout.model';
import { GridOptions } from 'ag-grid-community';
import { CustomHeader} from '../../util//customHeader'
import { CommonFunctions } from 'src/app/util/common-functions';

@Component({
	selector: 'app-wealth-report',
	templateUrl: './wealth-report.component.html',
	styleUrls: ['./wealth-report.component.scss'],
	providers: [DatePipe]
})
export class WealthReportComponent implements OnInit {

	public client:Client
	public loggedAgent: Agent 
	public selectedChoiceID: string = "";
	public currentChoice: Choice = new Choice()
	public AnnuityBalanceData: Array<any>
	public InvestmentBalanceData: Array<any>
	public TotalNetWorthData: Array<any>
	public years: Array<string>
	public wealthReport: any;
	public incomeForLifeList : Array<IncomeForLife> =  []
	private commonFunctions: CommonFunctions = new CommonFunctions 
	public disablePrintBtn: boolean

	//ag grid
	private gridApi: any;
	private gridColumnApi: any;
  
	public columnDefs: any;
	public rowData: any;	
	public gridOptions : GridOptions 
	public currentSelectedColumn;
//#region "constructor,  and ngOnInit"
	constructor(private router: Router,  private ldService: ClientInfoDataService,
					 private alertService: AlertService,
					 private sharedService: SharedServiceService) {
		this.gridOptions = { 
        components: {
          agColumnHeader: CustomHeader
        },
        defaultColDef: { 
          headerComponentParams: {
            setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
            getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          } 
        } 
      };

      this.columnDefs = [
        { 
          headerName: 'Year', 
		  field: 'Year',
		  cellStyle: {textAlign: "center"} 
        },
        { 
          headerName: 'Annuity Balance', 
          field: 'AnnuityBalance',
          cellStyle: {textAlign: "center"}
        },
        { 
          headerName: 'Investment Balance', 
          field: 'InvestmentBalance',
          cellStyle: {textAlign: "center"} 
        },
        { 
          headerName: 'Total Net Worth', 
          field: 'TotalNetWorth',
          cellStyle: {textAlign: "center"} 
        }
      ];
	}
	

ngOnInit() {

	this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
	this.selectedChoiceID = localStorage.getItem(LocalStorage.SELECTED_CHOICEID)  
		
	this.client   = JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) as Client; 
	this.currentChoice =this.client.Choice.find(x => x.ChoiceID == this.selectedChoiceID) 

	let activeTabName = this.client.Choice.find(x => x.ChoiceID == this.selectedChoiceID).ChoiceName
	this.alertService.clear()
	if (activeTabName !== Constants.CURRENT_CHOICE_NAME)
	{      
	   this.alertService.warn('Warning! You are editing the '+ activeTabName +' Tab, new tabs should be created from the '+ Constants.CURRENT_CHOICE_NAME +' tab.')      
	} 
	this.years = []
	this.AnnuityBalanceData = []
	this.InvestmentBalanceData = []
	this.TotalNetWorthData = []
	this.loadReportData()
	
}
//#endregion

//#region "ag grid related methods"
onGridReady(params: any) { 
	this.gridApi  = params.api;
	this.gridColumnApi = params.columnApi; 
	setTimeout(() => { 
		this.loadReportData()
	}, 50);
}

getCurrentSelectedColumn() {
return this.currentSelectedColumn;
}

setCurrentSelectedColumn(colId) {
this.currentSelectedColumn = colId;
}

gridSizeChanged(params: any) {
	//  console.info(params)
	setTimeout(() => { 
		params.api.sizeColumnsToFit();
	}, 50);  
}
//#endregion

//#region "load report data"

loadReportData()
{
	this.alertService.clear()
	this.assignDataForIncomeForLifeReport()
	setTimeout(() => { 
		this.ldService.getwealthreportdata(this.selectedChoiceID,this.client, this.loggedAgent).subscribe(data => {
			this.years = []
			this.AnnuityBalanceData = []
			this.InvestmentBalanceData = []
			this.TotalNetWorthData = []
			if(data.WealthReportDataList != undefined){
				data.WealthReportDataList.forEach((year:WealthReportData) => {
					this.years.push(year.Year)
					this.AnnuityBalanceData.push(year.AnnuityBalance)
					this.InvestmentBalanceData.push(year.InvestmentBalance)
					this.TotalNetWorthData.push(year.TotalNetWorth)
				})
				this.prepareWealthGraph(this.AnnuityBalanceData,this.InvestmentBalanceData,this.TotalNetWorthData)
			}

			this.rowData = []
			if(data.WealthReportNumericData.TotalNetWorthDataList != undefined){
				data.WealthReportDataList.forEach((totalnetworthdata:TotalNetWorthData) => { 
				
					// Added by Dinithi to display 2 decimal places
					this.rowData.push({Year: totalnetworthdata.Year, 
						AnnuityBalance:  this.commonFunctions.CurrencyFormatWithTwoDecimals( Number(totalnetworthdata.AnnuityBalance).toFixed(2)) ,
						InvestmentBalance:this.commonFunctions.CurrencyFormatWithTwoDecimals( Number(totalnetworthdata.InvestmentBalance).toFixed(2)),
						TotalNetWorth: this.commonFunctions.CurrencyFormatWithTwoDecimals( Number(totalnetworthdata.TotalNetWorth).toFixed(2))})

					/*this.rowData.push({Year: totalnetworthdata.Year, 
						AnnuityBalance: "$"+ this.commonFunctions.AddThousandSeparatorFormat(parseFloat(totalnetworthdata.AnnuityBalance.toString()).toFixed(2)).toString() ,
						InvestmentBalance: "$"+ this.commonFunctions.AddThousandSeparatorFormat(parseFloat(totalnetworthdata.InvestmentBalance.toString()).toFixed(2)).toString(),
						TotalNetWorth: "$"+ this.commonFunctions.AddThousandSeparatorFormat(parseFloat(totalnetworthdata.TotalNetWorth.toString()).toFixed(2)).toString()})
					*/
					})
			}
			this.gridOptions.api.setRowData(this.rowData); 
		},
		error => {
			this.alertService.clear()
			this.alertService.error('Error occured while generating report')
		}); 
	}, 50);
}
//#endregion

//#region "assign incomelifereport data"
assignDataForIncomeForLifeReport()
{
	this.incomeForLifeList = []   
	if (typeof this.client.IncomeSourceAccount !== 'undefined') {
		this.client.IncomeSourceAccount.filter(x => x.ChoiceID == this.selectedChoiceID).forEach((oIncomeSourceAccount:IncomeSourceAccount) => { 
			
			let incomeForLife :IncomeForLife = new IncomeForLife()
			incomeForLife.ChoiceID = oIncomeSourceAccount.ChoiceID
			incomeForLife.DisplayOrder = oIncomeSourceAccount.DisplayOrder
			incomeForLife.IncomeID = oIncomeSourceAccount.IncomeSourceID
			incomeForLife.Owner = oIncomeSourceAccount.Owner
			incomeForLife.SourceType = Constants.IncomeType.IncomeSources 
			incomeForLife.Source = oIncomeSourceAccount.Source
			incomeForLife.AssetType = -1
			incomeForLife.StartYear = oIncomeSourceAccount.StartYear
			incomeForLife.EndYear = oIncomeSourceAccount.EndYear
			incomeForLife.COLAFlag = oIncomeSourceAccount.COLAFlag
			incomeForLife.COLAPct = oIncomeSourceAccount.COLAPct
			incomeForLife.SurvivorBenefitFlag = String(oIncomeSourceAccount.SurvivorBenefitFlag)
			incomeForLife.SurvivorBenefitPct = oIncomeSourceAccount.SurvivorBenefitPct
			incomeForLife.Amount = oIncomeSourceAccount.AnnualAmount
			incomeForLife.StartDate = oIncomeSourceAccount.StartDate
			incomeForLife.EndDate = oIncomeSourceAccount.EndDate
			this.incomeForLifeList.push(incomeForLife)
			
		});  
	}

	if (typeof this.client.IncomeFromAsset !== 'undefined') {
		this.client.IncomeFromAsset.filter(x => x.ChoiceID == this.selectedChoiceID).forEach((oIncomeFromAsset:IncomeFromAsset) => { 
			
			let StartYear = new Date (oIncomeFromAsset.StartDate).getFullYear()
			let StartMonth = new Date(oIncomeFromAsset.StartDate).getMonth() 

			let incomeForLife :IncomeForLife  = new IncomeForLife()
			incomeForLife.ChoiceID = oIncomeFromAsset.ChoiceID
			incomeForLife.DisplayOrder = oIncomeFromAsset.DisplayOrder
			incomeForLife.IncomeID = oIncomeFromAsset.IFAID
			incomeForLife.Owner = oIncomeFromAsset.Owner
			incomeForLife.SourceType =  Constants.IncomeType.IncomeFromAssets
			incomeForLife.Source = oIncomeFromAsset.Source
			incomeForLife.AssetType = Number(oIncomeFromAsset.AssetType)
			incomeForLife.StartYear = oIncomeFromAsset.StartYear
			incomeForLife.COLAFlag = oIncomeFromAsset.COLAFlag
			incomeForLife.COLAPct = oIncomeFromAsset.COLAPct
			incomeForLife.SurvivorBenefitFlag = oIncomeFromAsset.SurvivorshipHow
			incomeForLife.SurvivorBenefitPct = oIncomeFromAsset.SurvivorshipPct
			incomeForLife.Amount = oIncomeFromAsset.AccountValue
			incomeForLife.PayoutPct = oIncomeFromAsset.PayoutPct
			incomeForLife.StartDate = formatDate(new Date(new Date(StartYear,StartMonth,1).setMonth( StartMonth + Number(oIncomeFromAsset.YearsOfDeferral))), 'MM/dd/yyyy', 'en') // oIncomeFromAsset.StartDate
			incomeForLife.EndDate = new Date(8640000000000000).toString() //max date 
			//LTC
			incomeForLife.HasLTC = oIncomeFromAsset.HasLTC
			incomeForLife.LTCNotAvailableFor = oIncomeFromAsset.LTCNotAvailableFor
			incomeForLife.LTCMultiplier = oIncomeFromAsset.LTCMultiplier
			incomeForLife.LTCPeriod = oIncomeFromAsset.LTCPeriod 
			this.incomeForLifeList.push(incomeForLife) 
			//Monthly Deposit 
			incomeForLife.ClientMonthlyDeposit = oIncomeFromAsset.ClientMonthlyDeposit
			incomeForLife.ClientMonthlyDepositStartDate = oIncomeFromAsset.ClientMonthlyDepositStartDate
			incomeForLife.ClientMonthlyDepositEndDate = oIncomeFromAsset.ClientMonthlyDepositEndDate
			incomeForLife.SpouseMonthlyDeposit =oIncomeFromAsset.SpouseMonthlyDeposit
			incomeForLife.SpouseMonthlyDepositStartDate = oIncomeFromAsset.SpouseMonthlyDepositStartDate
			incomeForLife.SpouseMonthlyDepositEndDate = oIncomeFromAsset.SpouseMonthlyDepositEndDate

			//Other info needed for calculation in addtional monthly deposit
			incomeForLife.ChoiceID = oIncomeFromAsset.ChoiceID
			incomeForLife.Deposit = oIncomeFromAsset.Deposit
			incomeForLife.BonusFlag = oIncomeFromAsset.BonusFlag
			incomeForLife.BonusPct = oIncomeFromAsset.BonusPct
			incomeForLife.RollUpHow = oIncomeFromAsset.RollUpHow
			incomeForLife.RollUpPct = oIncomeFromAsset.RollUpPct
			incomeForLife.MonthsOfDeferral = oIncomeFromAsset.YearsOfDeferral // Even though this named as YearsOdDeferral it contains MonthsOfDeferral

			 

		});  
	} 

	this.client.IncomeForLife = this.incomeForLifeList   
}
//#endregion

//#region "net worth change over time graph"
prepareWealthGraph(AnnuityBalanceData: any,InvestmentBalanceData: any,TotalNetWorthData: any) {
	this.alertService.clear()
	this.wealthReport = new Chart({
	  chart: {
		type: 'line'
	  },
	  title: {
		text: '',
		style: {
		 font: '14px "Eagle"'
		}
	  },
	  plotOptions: {
		line: {
			allowPointSelect: true,
			cursor: 'pointer',
			dataLabels: {
				enabled: false
			},
			showInLegend: true
		}
	  },
	  credits: {
		enabled: false
	  },
	  xAxis: {
		categories: this.years
	  },
  
	  yAxis: {
		  title: {
			  text: ''
		  },
		  labels: {
			formatter: function() {
				return  "$" + this.value;
			}
		},
	  },
	  colors: ['#50B432','#009dd1','#000000'],
	  series: [
		{
		  	name: "Annuity Balance $",
		  	type: 'line',
		  	data: AnnuityBalanceData
		},
		{
		  	name: "Investment Balance $",
		  	type: 'line',
		  	data: InvestmentBalanceData
		},
		{
			name: "Total Net Worth $",
			type: 'line',
			data: TotalNetWorthData
		}
	  ]
	});
  }
//#endregion

//#region "print report"
printBtnClickEvent()
{
	this.disablePrintBtn = true
	this.alertService.info('Generating report. Please wait.');
	setTimeout(() => { 
		this.ldService.generatewealthreportpdf(this.selectedChoiceID,this.client, this.loggedAgent).subscribe(data => {  
			var file = new Blob([data], {type: 'application/pdf'});
			var fileURL = URL.createObjectURL(file);
			window.open(fileURL);
			this.alertService.clear()
			this.disablePrintBtn = false
		},
		error => {
			this.alertService.clear()
			this.alertService.error('Error occured while generating pdf report')
			this.disablePrintBtn = false
		}); 
	}, 50);
}
//#endregion
exitBtnClickEvent(){
	localStorage.setItem(LocalStorage.SELECTED_CLIENTID, "");
	localStorage.removeItem(LocalStorage.CURRENT_CLIENT);
	this.router.navigateByUrl('landing'); 
}	 
}