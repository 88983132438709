import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../util/localstorage.service'; 
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Agent } from '../models/agent.model';
import { SavedReport } from '../models/savedreport.model';
import { AlertService } from '../util/alert/alert.service';
import { CommonFunctions } from '../util/common-functions';
import { GridOptions, RowNode } from 'ag-grid-community'
import { CustomHeader} from '../util//customHeader'
import { ReportsDataService } from './reports-data.service';
import { BsModalService,BsModalRef}   from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { CustomCellComponent } from './custom-cell/custom-cell.component';
import { SharedServiceService } from '../util/shared-service.service';
import { Constants } from '../util/constants'; 
  
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit {
  
  public paddingtop: number = 60;
  public selectedChoiceID: string = "";
  public iSForm: FormGroup;
  public SavedReport: SavedReport
  public id: any
  public name:any
  public clientname:any
  public reportnamefilter:any
  public clientnamefilter:any
  public reporttype: any
  public isRiskGraph:any
  public isInvestmentGraph:any
  public isArchived:any
  public createdby:any
  public createdon:any
  public loggedAgent: Agent 
  public deleteMode: boolean
  private gridApi: any;
  private gridColumnApi: any;

  public columnDefs: any;
  public rowData: any;	
  public gridOptions : GridOptions  
  public currentSelectedColumn;

  public viewReportsArray = []
  public printReportsArray = []
  public printReportsName
  public isShowDashboard: boolean = false
  private commonFunctions: CommonFunctions = new CommonFunctions
  orderID :number = 0  

  @ViewChild('editFTAStrategyDetails') editFTAStrategyDetails: TemplateRef<any>
  public ModalRef : BsModalRef 
  getRowStyle: (params: any) => { "font-weight": string; };

  constructor( private router: Router,
    private formBuilder: FormBuilder, private alertService: AlertService, private reportsService: ReportsDataService,
    private bsModalService :BsModalService, private sharedService: SharedServiceService) {
      
      this.loggedAgent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent;
      // check whther the user is Investment Only - Only have forms access

      this.gridOptions = { 
		  columnDefs: this.columnDefs,
        components: {
          agColumnHeader: CustomHeader
        },
        context: {
          componentParent: this
        },
        rowHeight: 35,
        animateRows: true,
        rowSelection: 'multiple',
        defaultColDef: { 
          headerComponentParams: {
            setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
            getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          },
          filter: true,
          resizable: true,
        } 
      };

   }

 
  ngOnInit() {
   this.loggedAgent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent;  	
   if(this.loggedAgent.UserRoleID== Constants.UserRoleID.Superuser)
      this.isShowDashboard = true

   this.iSForm = this.formBuilder.group({
    name: new FormControl(null),
    reportnamefilter: new FormControl(null),
    clientnamefilter: new FormControl(null)
  } ); 
    this.loadData()
  
        var self = this;
	      this.columnDefs = [
        {
          headerName: "",
          field: "Checkbox", 
          width: 30, 
          editable:true,
          cellStyle: {textAlign: "center"},
          headerCheckboxSelection: function(params) {
            var headerinput = document.createElement('input');
            headerinput.type="checkbox";
            headerinput.style.alignContent="center";
            return headerinput;		
          },
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          // cellRenderer: function(params) {
          //   var input = document.createElement('input');
          //   input.type="checkbox";
          //   input.setAttribute('id',params.data.Id);
          //   if(self.selectedAllItems){
          //     input.checked = true
          //   } else if(self.deselectedAllItems){
          //     input.checked = false
          //   }
          //   input.addEventListener('click', function (event) {
          //     self.selectedAllItems = false
          //     self.deselectedAllItems = false
          //     params.value=!params.value;
          //     if(params.value == false){
          //       params.node.setDataValue('Checked', "NotChecked");	
          //     } else{
          //       params.node.setDataValue('Checked', "Checked");	
          //     }
              				
          //   });
          //   return input;						
          // }
        },
        { 
          headerName: '', 
          field: 'Checked',
          hide: true
        },
        { 
          headerName: 'ID', 
          field: 'ReportID',
          hide: true
          //sortable: true, 
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Client Name', 
          field: 'ClientName',
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Report Name', 
          field: 'ReportName',
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Report Type', 
          field: 'ReportType', 
          hide: true
         // sortable: true, 
         // filter: true,
         // cellClass: "rag-yellow"
        },
        { 
          headerName: 'Created By', 
          field: 'CreatedBy', 
          hide: true
         // sortable: true, 
         // filter: true,
         // cellClass: "rag-yellow"
        },
        { 
          headerName: 'Created On', 
          field: 'CreatedOn', 
          hide: !this.isArchived
         // sortable: true, 
         // filter: true,
         // cellClass: "rag-yellow"
        },
        { 
          headerName: '', 
          field: 'view_delete',
          width: 200,
          cellStyle: {textAlign: "center"},
          cellRendererFramework: CustomCellComponent,
        } ,
      ];
	  
  }

  loadData()
	{
    this.reporttype = JSON.parse(localStorage.getItem(LocalStorage.REPORT_TYPE))
    if(this.reporttype == Constants.SavedReportType.OldZephyr){
      this.isArchived = false
      this.isInvestmentGraph = true
      this.isRiskGraph = true
    } else if(this.reporttype == Constants.SavedReportType.RiskGraph){
      this.isRiskGraph = false
      this.isInvestmentGraph = true
      this.isArchived = true
    } else if (this.reporttype == Constants.SavedReportType.InvestmentReport){
      this.isInvestmentGraph = false
      this.isRiskGraph = true
      this.isArchived = true 
    }
		this.reportsService.getreports(this.loggedAgent, this.reporttype).subscribe(data => this.getData(data))
		setTimeout(()=> {
			this.gridOptions.api.setColumnDefs(this.columnDefs)
      this.gridOptions.api.sizeColumnsToFit();
		}, 50); 
	}

  public getData(data: any) {
    let reports: Array<any>=[] 
    data.forEach((element: any) => {
      var datePipe = new DatePipe('en-US');
      let createdOn = datePipe.transform(element.CreatedOn, 'MM/dd/yyyy');

      reports.push({Checked: "NotChecked",Id: element.ReportID, ClientName: element.ClientName ,ReportName: element.ReportName,
        ReportType: element.ReportType, CreatedBy: element.CreatedBy, CreatedOn: createdOn})

    });
    if(!this.isRiskGraph || !this.isInvestmentGraph){
      this.gridOptions.columnApi.setColumnVisible('ClientName', true)
      this.gridOptions.columnApi.setColumnVisible('Checkbox', true)
    } else{
      this.gridOptions.columnApi.setColumnVisible('ClientName', false)
      this.gridOptions.columnApi.setColumnVisible('Checkbox', false)
    }
    this.gridOptions.api.sizeColumnsToFit();
    setTimeout(()=> {
      this.gridApi.forEachNode(node => {
        if ( node.rowIndex  === 0 ){ 
          this.id = node.data.Id
          this.clientname = node.data.ClientName
          this.name = node.data.ReportName
          this.createdby = node.data.CreatedBy
          this.createdon = node.data.CreatedOn
          this.deleteMode = false
        } 
      }); 
    
    }, 50); 
   
    this.rowData = reports;
    return this.rowData;
  }


  onGridReady(params: any) { 
		this.gridApi  = params.api;
		this.gridColumnApi = params.columnApi; 
		setTimeout(() => { 
        this.loadData()     
      	//params.api.sizeColumnsToFit();
    }, 50);

  }

  getCurrentSelectedColumn() {
    return this.currentSelectedColumn;
    }
    
  setCurrentSelectedColumn(colId) {
    this.currentSelectedColumn = colId;
    }

  gridSizeChanged(params: any) {
		params.api.sizeColumnsToFit();
  }

  selectAllReports(){
    setTimeout(()=> {
      this.gridApi.forEachNode(node => {
        node.data.Checked = "Checked"
      });   
    }, 50);
  }

  deselectAllReports(){
    setTimeout(()=> {
      this.gridApi.forEachNode(node => {
        node.data.Checked = "NotChecked"
      }); 
    }, 50);
  }

  selectedReports(){
    setTimeout(()=> {
      this.gridApi.forEachNode(node => {
        node.data.Checked = "NotChecked"
      }); 
      this.gridOptions.api.getSelectedRows().forEach(node => {
        node.Checked = "Checked"
      }); 
    }, 50);
  }

  viewBtnClickEvent(data: any){ 
    this.alertService.clear()
    this.alertService.info('Opening report. Please wait.')
  
    setTimeout(() => {
      this.id = data.Id 
      this.reportsService.getsavedreportforpreview(this.id, this.reporttype, this.loggedAgent).subscribe(data => {  
   
        var file = new Blob([data], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL); 
        this.alertService.clear() 
      },
        error => { 
          this.alertService.clear()
          this.alertService.error('Error occured while opening pdf report')
      }); 
    }, 50);
  }

  printBtnClickEvent(Data: any){
    this.alertService.clear()
    this.alertService.info('Opening PDF report. Please wait.')
  
    setTimeout(() => { 
      this.id = Data.Id
      this.reportsService.getsavedreportforpreview(this.id, this.reporttype, this.loggedAgent).subscribe(data => {  
        this.name = Data.ReportName + '.pdf'
        const blob = new Blob([data], {type: 'application/pdf'}); 
        let a = document.createElement("a"); 
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = this.name
        a.click();
        window.URL.revokeObjectURL(url);
  
        this.alertService.clear() 
      },
        error => { 
          this.alertService.clear()
          this.alertService.error('Error occured while opening pdf report')
      }); 
    }, 50);
   
  }

  viewSeparately(){
    this.viewReportsArray = []
    this.gridApi.forEachNode(node => {
    if(node.data.Checked == "Checked"){
      setTimeout(()=> {
        this.SavedReport = new SavedReport()
        this.SavedReport.ReportID = node.data.Id
        this.SavedReport.ReportType = this.reporttype

        this.viewReportsArray.push(this.SavedReport)
        this.viewBtnClickEvent(node.data)
      }, 50); 
    }
    });    
    if(this.viewReportsArray != undefined || this.viewReportsArray.length === 0){
      this.alertService.clear()
      this.alertService.info('Please select reports to proceed ')
    }
  }

  printSeparately(){
    this.printReportsArray = []
    this.gridApi.forEachNode(node => {
    if(node.data.Checked == "Checked"){
      setTimeout(()=> {
        this.SavedReport = new SavedReport()
        this.SavedReport.ReportID = node.data.Id
        this.SavedReport.ReportType = this.reporttype

        this.printReportsArray.push(this.SavedReport)
        this.printBtnClickEvent(node.data)
      }, 50); 
    }
    });    
    if(this.printReportsArray != undefined || this.printReportsArray.length === 0){
      this.alertService.clear()
      this.alertService.info('Please select reports to proceed ')
    }
  }


  viewCombined(){
    this.viewReportsArray = []
    this.gridApi.forEachNode(node => {
      if(node.data.Checked == "Checked"){
        this.SavedReport = new SavedReport()
        this.SavedReport.ReportID = node.data.Id
        this.SavedReport.ReportType = this.reporttype

        this.viewReportsArray.push(this.SavedReport)
      }
    });    
    if(this.viewReportsArray != undefined || this.viewReportsArray.length === 0){
      this.alertService.clear()
      this.alertService.info('Opening report. Please wait..')
      setTimeout(() => { 
        this.reportsService.getcombinedsavedreportforpreview(this.viewReportsArray, this.loggedAgent).subscribe(data => {  
          var file = new Blob([data], {type: 'application/pdf'});
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL); 
          this.alertService.clear() 
        },
          error => { 
            this.alertService.clear()
            this.alertService.error('Error occured while opening pdf report')
        }); 
      }, 50);
    } else{
      this.alertService.clear()
      this.alertService.info('Please select reports to proceed')
    }

  }

  printCombined(){
    this.printReportsArray = []
    this.printReportsName = ""
    this.gridApi.forEachNode(node => {
      if(node.data.Checked == "Checked"){
        this.printReportsName = this.printReportsName + node.data.ReportName + "_"

        this.SavedReport = new SavedReport()
        this.SavedReport.ReportID = node.data.Id
        this.SavedReport.ReportType = this.reporttype

        this.printReportsArray.push(this.SavedReport)
      }
    });  
    if(this.printReportsArray != undefined || this.printReportsArray.length === 0){
      this.alertService.clear()
      this.alertService.info('Opening PDF report. Please wait..')   
      setTimeout(() => {
        this.reportsService.getcombinedsavedreportforpreview(this.printReportsArray, this.loggedAgent).subscribe(data => {  
          const blob = new Blob([data], {type: 'application/pdf'}); 
          let a = document.createElement("a"); 
          document.body.appendChild(a);
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = this.printReportsName.slice(0, -1) + '.pdf'
          a.click();
          window.URL.revokeObjectURL(url);
          this.alertService.clear()
        },
          error => { 
            this.alertService.clear()
            this.alertService.error('Error occured while opening pdf report')
        }); 
      }, 50);
    } else{
      this.alertService.clear()
      this.alertService.info('Please select reports to proceed')
    }
  }
  
  deleteBtnClickEvent(data: any){
    this.id = data.Id
    this.alertService.clear()
    if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return    
    if (confirm("Are you sure you want to delete selected record?")) {  
      this.reportsService.deletesavedreport(this.id, this.reporttype, this.loggedAgent).subscribe(data => {  
        this.alertService.clear() 
        this.alertService.success('Successully deleted')
        this.loadData()
      },
        error => { 
          this.alertService.clear()
          this.alertService.error('Error occured while deleting pdf report')
          this.loadData()
      });
    }    
  }

  public onCellClicked(event: any){
    event.node.setSelected(true);
  }

  public onSelectionChanged(event: any){
    if(this.gridOptions.api.getSelectedRows().length == this.rowData.length){
      this.selectAllReports() 
    } else if(this.gridOptions.api.getSelectedRows().length == 0){
      this.deselectAllReports()
    } else{
      this.selectedReports()
    }
  }

  cancelBtnClickEvent(){
    this.reportnamefilter = null
    this.clientnamefilter = null
    this.externalFilterChanged()
  }
 
  searchBtnClickEvent(){
    this.externalFilterChanged()
  }

  externalFilterChanged() {
    this.gridApi.onFilterChanged();
  }

  isExternalFilterPresent() {
    return true;
  }

  doesExternalFilterPass = (node: RowNode): boolean => {
    var ReportName = node.data.ReportName.toLowerCase()
    var ClientName = node.data.ClientName.toLowerCase()

    var isReportFilterNotPresent = (this.reportnamefilter == null || this.reportnamefilter == undefined || this.reportnamefilter == "")
    var isClientFilterNotPresent = (this.clientnamefilter == null || this.clientnamefilter == undefined || this.clientnamefilter == "")

        //To check whether the filter is true
    var isReportFilterTrue : boolean = false
    if (!isReportFilterNotPresent)
      isReportFilterTrue = (ReportName.includes(this.reportnamefilter.toLowerCase()))

    var isClientFilterTrue : boolean = false
    if (!isClientFilterNotPresent)
      isClientFilterTrue = (ClientName.includes(this.clientnamefilter.toLowerCase()))

    // Return true for node which fulfil filter criteria
    if ((isReportFilterNotPresent || (!isReportFilterNotPresent && isReportFilterTrue)) &&
        (isClientFilterNotPresent || (!isClientFilterNotPresent && isClientFilterTrue)))
    {
      return true
    } else{
      node.data.Checked == "NotChecked"
      node.setSelected(false)
    }
  }

  exitBtnClickEvent()
  {
    if(JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) != null){
      this.sharedService.changeSideBarRequest()   
			this.router.navigateByUrl('home')
    } else{
      this.router.navigateByUrl('landing'); 
    }
  }

  //load the superuser dashboard page
	public navigateSuperUserDashboard()
	{
		this.router.navigateByUrl('superuserdashboard'); 
	}

}