export class WealthReport {
    constructor( 
        public WealthReportDataList?: Array<WealthReportData>,
        public WealthReportNumericData?: NumericData
    ) {}
}

export class WealthReportData {
    constructor( 
        public Year?: string,
        public AnnuityBalance?: Number,
        public InvestmentBalance?: Number,
        public TotalNetWorth?: Number
    ) {}
}

export class NumericData {
    constructor( 
        public AnnuityBalanceDataList?: Array<AnnuityBalanceData>,
        public InvestmentBalanceDataList?: Array<InvestmentBalanceData>,
        public TotalNetWorthDataList?: Array<TotalNetWorthData>
    ) {}
}

export class AnnuityBalanceData {
    constructor( 
        public Year?: Number,
        public StartValue?: Number,
        public RiderCharge?: Number,
        public Deposits?: Number,
        public Withdrawals?: Number,
        public Growth?: Number,
        public FinalBalance?: Number
    ) {}
}

export class InvestmentBalanceData {
    constructor( 
        public Year?: Number,
        public StartValue?: Number,
        public Deposits?: Number,
        public Withdrawals?: Number,
        public Growth?: Number,
        public FinalBalance?: Number
    ) {}
}

export class TotalNetWorthData {
    constructor( 
        public Year?: Number,
        public AnnuityBalance?: Number,
        public InvestmentBalance?: Number,
        public TotalNetWorth?: Number
    ) {}
}