import {
	Component,
	OnInit,
	ElementRef,
	ViewChild, 
	SimpleChanges,
	ViewContainerRef,
  ComponentRef,
  ComponentFactoryResolver,
  OnDestroy,
  TemplateRef
} from '@angular/core';
import { Router } from '@angular/router'; 
import { Constants } from '../../util/constants'; 
import { LocalStorage } from '../../util/localstorage.service'; 
import { Agent } from '../../models/agent.model'; 
import Stepper from 'bs-stepper';
import { AlertService } from 'src/app/util/alert/alert.service';
import { CommonFunctions } from '../../util/common-functions';
import { FP4FADataService } from '../fp4fa-data.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms'; 
import { SharedServiceService } from '../../util/shared-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Chart, StockChart } from 'angular-highcharts';
import { BusinessCalculatorOutput } from 'src/app/models/business-calculator-output.model';
import { BusinessCalculatorDefaults } from 'src/app/models/business-calculator-defaults.model';
import { BusinessCalculatorInput } from 'src/app/models/business-calculator-input.model';
import { BusinessPlanDashboard } from 'src/app/models/buiness-plan-dashboard.model';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})


export class DashboardComponent implements OnInit  {

//#region "Variable declaration"
  name = 'Angular';
  public isSideBar: boolean = true
  public modalRef: BsModalRef;
  public loggedAgent: Agent 
  public currentStepperIndex: any  
  public currentStepperName:any       
  public saveactionshow :boolean = true
  private businesscalculatorinput: BusinessCalculatorInput = new BusinessCalculatorInput()
  private businesscalculatoroutput: BusinessCalculatorOutput = new BusinessCalculatorOutput()
  private businessplandashboard: BusinessPlanDashboard = new BusinessPlanDashboard()
  public years: Array<string>
  public months: Array<any>;
  public noofyears: string;
  public iSForm: FormGroup;
  public businessPlansArray: Array<any> = []
  public existingbusinessplanid: string

  //allocation of expenses chart
  public allocationOfExpensesData: Array<any>;
  public allocationOfExpensesChart: any;
  public yearforallocationofexpenses: string;

  //product mix chart
  public productMixData: Array<any>;
  public productMixChart: any;

  //marketing channel chart
  public marketingChannelData: Array<any>;
  public marketingChannelChart: any;

  //expense categories chart
  public expensecategoriesData: Array<any>;
  public expensecategoriesChart: any;
  public expensecategory: string;
  public expensecategories: Array<any>;

  //total AUM growth chart
  public totalAUMGrowthData: Array<any>;
  public totalAUMGrowthChart: any;
  public totalAUMGrowth: string;

  //total client growth chart
  public totalClientGrowthData: Array<any>;
  public totalClientGrowthChart: any;
  public totalClientGrowth: string;

  //revenue by annuity chart
  public revenueByAnnuityData: Array<any>;
  public revenueByAnnuityChart: any;
  public revenueByAnnuity: string;

  //owner's take monthly chart
  public ownersTakeMonthlyData: Array<any>;
  public ownersTakeMonthlyChart: any;
  public ownersTakeMonthly: string;
  public yearforownerstake: string;

  //monthly cash flow chart
  public monthlyCashFlowRevenueData: Array<any>;
  public monthlyCashFlowExpenseData: Array<any>;
  public monthlyCashFlowCashRemainingData: Array<any>;
  public monthlyCashFlowChart: any;
  public monthlyCashFlow: string;
  public yearformonthlycashflow: string;

  public isShowDashboard: boolean = false
  status: boolean = false;
  resizestatus: boolean = false;


//#region "constructor,  and ngOnInit"
  constructor(private resolver: ComponentFactoryResolver,private router: Router,
              private alertService: AlertService,private FP4FAdataService: FP4FADataService,
              private formBuilder: FormBuilder,private sharedService: SharedServiceService,
              private modalService: BsModalService) 
  {

    this.iSForm = this.formBuilder.group({
      year: new FormControl(null),
      businessplanname: new FormControl(null),
			existingbusinessplanid: new FormControl(null)
		} ); 
  }

  ngOnDestroy()
  {
    this.alertService.clear();
  } 

  ngOnInit() 
  { 
    this.loggedAgent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
    if(this.loggedAgent.UserRoleID== Constants.UserRoleID.Superuser)
      this.isShowDashboard = true
    this.businesscalculatorinput = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_INPUT)) as BusinessCalculatorInput;
    
    //get all business plans
    this.getAllBusinessPlans()

    //generate the business calculator
    this.generateBusinessCalc()
  }  
//#endregion

//#region business plan related methods
getAllBusinessPlans(){
  this.alertService.clear()
  this.FP4FAdataService.getallbusinessplansbyagentid(this.loggedAgent).subscribe(data => {
    this.businessPlansArray = []
    if(data != null && data != undefined){
      var i
      for(i=0; i < data.length; i++){
      this.businessPlansArray.push({id: data[i].BusinessPlanID, text: data[i].BusinessPlanName})
      } 
    }
    if(this.businessPlansArray != undefined && this.businessPlansArray.length != 0){
      this.existingbusinessplanid = this.businesscalculatorinput.BusinessPlanID;
      if(this.businesscalculatorinput.BusinessPlanName != undefined && this.businesscalculatorinput.BusinessPlanName != ""){
        //this.setBusinessPlanId("")
      } else{
        this.existingbusinessplanid = this.businessPlansArray[0].id
      }
     
    }
    return this.businessPlansArray   	
  },
  error => { 
    this.alertService.error('An error occurred while getting FP4FA data')
  });
}

setBusinessPlanId(e: any){
  this.alertService.clear()
  this.businesscalculatorinput = new BusinessCalculatorInput()
  if(this.existingbusinessplanid != undefined && this.existingbusinessplanid != ""){
    this.FP4FAdataService.getbusinessplanbyid(this.existingbusinessplanid, this.loggedAgent).subscribe(data => {
      this.businesscalculatorinput  = data 
      localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
      localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
      this.FP4FAdataService.generatebusinesscalculator(this.loggedAgent , this.businesscalculatorinput).subscribe(data => {
        this.businesscalculatoroutput = new BusinessCalculatorOutput  
        this.businesscalculatoroutput  = data 	 
        localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_OUTPUT, JSON.stringify(this.businesscalculatoroutput)); 
        //creating the years array
        this.CreatingYearArray();

        //creating the expense categories array
        this.CreatingExpenseCategoriesArray()

        //creating the months array
        this.CreatingMonthsArray()
        
        this.yearforallocationofexpenses = this.years[0]
        this.yearforownerstake = this.years[0]
        this.yearformonthlycashflow = this.years[0]
        this.expensecategory = this.expensecategories[0]
        this.getAllocationOfExpensesData();
        this.getExpenseCategoriesData();
        this.getBusinessPlanDashboard(); 
        this.getProductMixData();
        
      },
      error => { 
        this.alertService.error('An error occurred while getting FP4FA data')
      });   
      
    },
    error => { 
      this.alertService.error('An error occurred while getting FP4FA data')
    });
  } else{
    
  }

}

generateBusinessCalc(){
  this.FP4FAdataService.generatebusinesscalculator(this.loggedAgent , this.businesscalculatorinput).subscribe(data => {
    this.businesscalculatoroutput = new BusinessCalculatorOutput  
    this.businesscalculatoroutput  = data 	 
    localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_OUTPUT, JSON.stringify(this.businesscalculatoroutput)); 
    //creating the years array
    this.CreatingYearArray();

    //creating the expense categories array
    this.CreatingExpenseCategoriesArray()

    //creating the months array
    this.CreatingMonthsArray()
    
    this.yearforallocationofexpenses = this.years[0]
    this.yearforownerstake = this.years[0]
    this.yearformonthlycashflow = this.years[0]
    this.expensecategory = this.expensecategories[0]
    this.getAllocationOfExpensesData();
    this.getExpenseCategoriesData();
    this.getBusinessPlanDashboard(); 
    this.getProductMixData();
  },
  error => { 
    this.alertService.error('An error occurred while getting FP4FA data')
  });
}

getBusinessPlanDashboard(){
  this.FP4FAdataService.getbusinessplandashboard(this.existingbusinessplanid, this.loggedAgent).subscribe(data => {
    this.businessplandashboard = new BusinessPlanDashboard
    this.businessplandashboard  = data 

    this.getRevenueByAnnuityData();
    this.getTotalAUMGrowthData();
    this.getTotalClientGrowthData();
    this.getOwnersTakeMonthlyData();
    this.getMonthlyCashFlowData();
    this.getMarketingChannelData();
  },
  error => { 
    this.alertService.error('An error occurred while getting FP4FA data')
  });

}
//#endregion

//#region creating year and expense category arrays

//creating the years array
CreatingYearArray(){
    this.alertService.clear()
    let noofyears = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_INPUT)).NoOfYears
    let i;
		this.years = []
		let year = new Date().getFullYear();
		for(i = 0; i < parseInt(noofyears); i++){
			this.years.push(year.toString())
			year = year + 1						
    }
}

CreatingExpenseCategoriesArray(){
  this.alertService.clear()
  this.expensecategories = []
  this.expensecategories= [
    Constants.ExpenseCategories.Advertising,
    Constants.ExpenseCategories.Employees,
    Constants.ExpenseCategories.AdvisorPay,
    Constants.ExpenseCategories.PayrollTaxes,
    Constants.ExpenseCategories.COLAIncrease,
    Constants.ExpenseCategories.StaffPerformanceBonusPool,
    Constants.ExpenseCategories.InsuranceBenefits,
    Constants.ExpenseCategories.RetirementBenefits,
    Constants.ExpenseCategories.Rent,
    Constants.ExpenseCategories.EAndOInsurance,
    Constants.ExpenseCategories.BusinessLiabilityInsurance,
    Constants.ExpenseCategories.WorkersCompensationInsurance,
    Constants.ExpenseCategories.OtherInsuranceCosts,
    Constants.ExpenseCategories.OtherExpenses]
}

CreatingMonthsArray(){
  this.alertService.clear()
  this.months = []
  this.months= [
    Constants.Months.Jan,
    Constants.Months.Feb,
    Constants.Months.Mar,
    Constants.Months.Apr,
    Constants.Months.May,
    Constants.Months.Jun,
    Constants.Months.Jul,
    Constants.Months.Aug,
    Constants.Months.Sep,
    Constants.Months.Oct,
    Constants.Months.Nov,
    Constants.Months.Dec]
}
//#endregion

//#region setting allocation of expenses chart data related methods
getAllocationOfExpensesData() {
  this.alertService.clear()
  this.allocationOfExpensesData = []

  if(this.businesscalculatoroutput.YearData != undefined){
    this.businesscalculatoroutput.YearData.forEach((dataperyear:any) => {
      if(dataperyear.Year == this.yearforallocationofexpenses){
        this.allocationOfExpensesData.push(
        {
          "name" : Constants.ExpenseCategories.Advertising,
          "y" : dataperyear.Advertising
        },
        {
          "name" : Constants.ExpenseCategories.Employees,
          "y" : dataperyear.Employees
        },
        {
          "name" : Constants.ExpenseCategories.AdvisorPay,
          "y" : dataperyear.AdvisorPay
        },
        {
          "name" : Constants.ExpenseCategories.PayrollTaxes,
          "y" : dataperyear.PayrollTaxes
        },
        {
          "name" : Constants.ExpenseCategories.COLAIncrease,
          "y" : dataperyear.COLAIncrease
        },
        {
          "name" : Constants.ExpenseCategories.StaffPerformanceBonusPool,
          "y" : dataperyear.StaffPerformanceBonusPool
        },
        {
          "name" : Constants.ExpenseCategories.InsuranceBenefits,
          "y" : dataperyear.InsuranceBenefits
        },
        {
          "name" : Constants.ExpenseCategories.RetirementBenefits,
          "y" : dataperyear.RetirementBenefits
        },
        {
          "name" : Constants.ExpenseCategories.Rent,
          "y" : dataperyear.Rent
        },
        {
          "name" : Constants.ExpenseCategories.EAndOInsurance,
          "y" : dataperyear.EAndOInsurance
        },
        {
          "name" : Constants.ExpenseCategories.BusinessLiabilityInsurance,
          "y" : dataperyear.BusinessLiabilityInsurance
        },
        {
          "name" : Constants.ExpenseCategories.WorkersCompensationInsurance,
          "y" : dataperyear.WorkersCompensationInsurance
        },
        {
          "name" : Constants.ExpenseCategories.OtherInsuranceCosts,
          "y" : dataperyear.OtherInsuranceCosts
        },
        {
          "name" : Constants.ExpenseCategories.OtherExpenses,
          "y" : dataperyear.OtherExpenses
        }
        )
      }
    })
  }
  this.prepareAllocationOfExpensesChart(this.allocationOfExpensesData);
}
 
prepareAllocationOfExpensesChart(allocationOfExpensesData: any) {
  this.alertService.clear()
   this.allocationOfExpensesChart = new Chart({
     chart : {
       plotBackgroundColor: null,
       plotBorderWidth: null,
       plotShadow: false,
       type : 'pie'
     },
     title: {
       text: '',
       style: {
        font: '14px "Eagle"'
       }
     },
     credits: {
      enabled: false
      },
     plotOptions: {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: false
          },
          showInLegend: true
      },
     },
     legend: {
      enabled: true,
      verticalAlign: 'bottom'           
    },
    colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'],
    series: [{
       name: 'Expenses',
       colorByPoint: true,
       type:undefined,
       data: allocationOfExpensesData
     }]
  });
 }
//#endregion

//#region setting product mix chart data related methods
getProductMixData() {
  this.alertService.clear()
  this.productMixData = []

  this.businesscalculatorinput.FinancialProducts.FinancialProductDetails.forEach((element:any) => {
    this.productMixData.push(
      {
        "name" : element.AnnuityName,
        "y" : element.ProductSalesMix
      })
  })
  this.prepareProductMixChart(this.productMixData);
}
 
prepareProductMixChart(productMixData: any) {
  this.alertService.clear()
   this.productMixChart = new Chart({
     chart : {
       plotBackgroundColor: null,
       plotBorderWidth: null,
       plotShadow: false,
       type : 'pie'
     },
     title: {
       text: '',
       style: {
        font: '14px "Eagle"'
       }
     },
     credits: {
      enabled: false
      },
     plotOptions: {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: false
          },
          showInLegend: true
      },
     },
     legend: {
      enabled: true,
      verticalAlign: 'bottom'           
    },
    colors: ['#c670e6','#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'],
     series: [{
       name: 'Product Mix',
       colorByPoint: true,
       type:undefined,
       data: productMixData
     }]
  });
}
//#endregion

//#region setting marketing channel chart data related methods
getMarketingChannelData() {
  this.alertService.clear()
  this.marketingChannelData = []


  if(this.businessplandashboard.BPDMarketingChannelData != undefined){
    this.businessplandashboard.BPDMarketingChannelData.forEach((element:any) => {
      this.marketingChannelData.push(
        {
          "name" : element.MarketingChannel,
          "y" : element.Expenditure
        })
    })
  }
  this.prepareMarketingChannelChart(this.marketingChannelData);
}
 
prepareMarketingChannelChart(marketingChannelData: any) {
  this.alertService.clear()
   this.marketingChannelChart = new Chart({
     chart : {
       plotBackgroundColor: null,
       plotBorderWidth: null,
       plotShadow: false,
       type : 'pie'
     },
     title: {
       text: '',
       style: {
        font: '14px "Eagle"'
       }
     },
     credits: {
      enabled: false
      },
     plotOptions: {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: false
          },
          showInLegend: true
      },
     },
     legend: {
      enabled: true,
      verticalAlign: 'bottom'           
    },
    colors: ['#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'],
     series: [{
       name: 'Channel Expenditure',
       colorByPoint: true,
       type:undefined,
       data: marketingChannelData
     }]
  });
}
//#endregion

//#region setting expense categories chart data related methods
getExpenseCategoriesData() {
  this.alertService.clear()
  this.expensecategoriesData = []
  if(this.businesscalculatoroutput.YearData != undefined){
    this.businesscalculatoroutput.YearData.forEach((dataperyear:any) => {
      switch (this.expensecategory) {
        case Constants.ExpenseCategories.Advertising:
          this.expensecategoriesData.push(dataperyear.Advertising)
          break;
        case Constants.ExpenseCategories.Employees:
            this.expensecategoriesData.push(dataperyear.Employees)
          break;
        case Constants.ExpenseCategories.AdvisorPay:
            this.expensecategoriesData.push(dataperyear.AdvisorPay)
          break;
        case Constants.ExpenseCategories.PayrollTaxes:
            this.expensecategoriesData.push(dataperyear.PayrollTaxes)
          break;
        case Constants.ExpenseCategories.COLAIncrease:
            this.expensecategoriesData.push(dataperyear.COLAIncrease)
          break;
        case Constants.ExpenseCategories.StaffPerformanceBonusPool:
            this.expensecategoriesData.push(dataperyear.StaffPerformanceBonusPool)
          break;
        case Constants.ExpenseCategories.InsuranceBenefits:
            this.expensecategoriesData.push(dataperyear.InsuranceBenefits)
          break;
        case Constants.ExpenseCategories.RetirementBenefits:
            this.expensecategoriesData.push(dataperyear.RetirementBenefits)
          break;
        case Constants.ExpenseCategories.Rent:
            this.expensecategoriesData.push(dataperyear.Rent)
          break;
        case Constants.ExpenseCategories.EAndOInsurance:
            this.expensecategoriesData.push(dataperyear.EAndOInsurance)
          break;
        case Constants.ExpenseCategories.BusinessLiabilityInsurance:
            this.expensecategoriesData.push(dataperyear.BusinessLiabilityInsurance)
          break;
        case Constants.ExpenseCategories.WorkersCompensationInsurance:
            this.expensecategoriesData.push(dataperyear.WorkersCompensationInsurance)
          break;
        case Constants.ExpenseCategories.OtherInsuranceCosts:
            this.expensecategoriesData.push(dataperyear.OtherInsuranceCosts)
          break;
        case Constants.ExpenseCategories.OtherExpenses:
            this.expensecategoriesData.push(dataperyear.OtherExpenses)
          break;
        default:
          break;
      }
    })
  }
  this.prepareExpenseCategoriesChart(this.expensecategoriesData);
}
 
prepareExpenseCategoriesChart(expensecategoriesData: any) {
  this.alertService.clear()
  this.expensecategoriesChart = new Chart({
    chart: {
      type: 'coloumn'
    },
    title: {
      text: '',
      style: {
       font: '14px "Eagle"'
      }
    },
    plotOptions: {
      bar: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: true
          },
          showInLegend: true
      }
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: this.years
    },

    yAxis: {
        title: {
            text: ''
        }
    },
    colors: ['#c70553'],
    series: [
      {
        name: this.expensecategory,
        type: 'column',
        data: expensecategoriesData
      }
    ]
  });
}
//#endregion

//#region setting total AUM growth chart data related methods
getTotalAUMGrowthData() {
  this.alertService.clear()
  this.totalAUMGrowthData = []
  var i = 0
  while (i < this.years.length) { this.totalAUMGrowthData[i++] = 0; }
  if(this.businessplandashboard.BPDYearlyData != undefined){
    this.years.forEach((year:any,index) => {
      this.businessplandashboard.BPDYearlyData.forEach((e:any) => {
        if(e.Year === year){this.totalAUMGrowthData[index] = e.AUMComm}
      })
    })
  }
  this.prepareTotalAUMGrowthChart(this.totalAUMGrowthData);
}
 
prepareTotalAUMGrowthChart(totalAUMGrowthData: any) {
  this.alertService.clear()
  this.totalAUMGrowthChart = new Chart({
    chart: {
      type: 'coloumn'
    },
    title: {
      text: '',
      style: {
       font: '14px "Eagle"'
      }
    },
    plotOptions: {
      bar: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: true
          },
          showInLegend: true
      }
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: this.years
    },

    yAxis: {
        title: {
            text: ''
        }
    },
    colors: ['#50B432'],
    series: [
      {
        name: "AUM",
        type: 'column',
        data: totalAUMGrowthData
      }
    ]
  });
}
//#endregion

//#region setting total client growth chart data related methods
getTotalClientGrowthData() {
  this.alertService.clear()
  this.totalClientGrowthData = []
  var i = 0
  while (i < this.years.length) { this.totalClientGrowthData[i++] = 0; }
  if(this.businessplandashboard.BPDYearlyData != undefined){
    this.years.forEach((year:any,index) => {
      this.businessplandashboard.BPDYearlyData.forEach((e:any) => {
        if(e.Year === year){this.totalClientGrowthData[index] = e.NoOfClients}
      })
    })
  }
  this.prepareTotalClientGrowthChart(this.totalClientGrowthData);
}
 
prepareTotalClientGrowthChart(totalClientGrowthData: any) {
  this.alertService.clear()
  this.totalClientGrowthChart = new Chart({
    chart: {
      type: 'coloumn'
    },
    title: {
      text: '',
      style: {
       font: '14px "Eagle"'
      }
    },
    plotOptions: {
      bar: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: true
          },
          showInLegend: true
      }
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: this.years
    },

    yAxis: {
        title: {
            text: ''
        }
    },
    colors: ['#058DC7'],
    series: [
      {
        name: "No of Clients",
        type: 'column',
        data: totalClientGrowthData
      }
    ]
  });
}
//#endregion

//#region setting revenue by annuity chart data related methods
getRevenueByAnnuityData() {
  this.alertService.clear()
  this.revenueByAnnuityData = []
  var i = 0
  while (i < this.years.length) { this.revenueByAnnuityData[i++] = 0; }
  if(this.businessplandashboard.BPDYearlyData != undefined){
    this.years.forEach((year:any) => {
      this.businessplandashboard.BPDYearlyData.forEach((e:any,index) => {
        if(e.Year === year){this.revenueByAnnuityData[index] = e.ANNComm}
      })
    })
  }
  this.prepareRevenueByAnnuityChart(this.revenueByAnnuityData);
}
 
prepareRevenueByAnnuityChart(revenueByAnnuityData: any) {
  this.alertService.clear()
  this.revenueByAnnuityChart = new Chart({
    chart: {
      type: 'coloumn'
    },
    title: {
      text: '',
      style: {
       font: '14px "Eagle"'
      }
    },
    plotOptions: {
      bar: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: true
          },
          showInLegend: true
      }
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: this.years
    },

    yAxis: {
        title: {
            text: ''
        }
    },
    colors: ['#c79a05'],
    series: [
      {
        name: "Revenue By Annuity",
        type: 'column',
        data: revenueByAnnuityData
      }
    ]
  });
}
//#endregion

//#region setting owner's take monthly chart data related methods
getOwnersTakeMonthlyData() {
  this.alertService.clear()
  this.ownersTakeMonthlyData = []

  var i = 0
  while (i < 12) { this.ownersTakeMonthlyData[i++] = 0; }
  if(this.businessplandashboard.BPDMonthlyData != undefined){
    for (var i= 1; i < 13; i++){
      this.businessplandashboard.BPDMonthlyData.forEach((e:any) => {
        if(e.Year === this.yearforownerstake && e.Month == i.toString()){this.ownersTakeMonthlyData[i-1] = e.NetOwnersTake}
      })
    }
  }
  this.prepareOwnersTakeMonthlyChart(this.ownersTakeMonthlyData);
}
 
prepareOwnersTakeMonthlyChart(ownersTakeMonthlyData: any) {
  this.alertService.clear()
  this.ownersTakeMonthlyChart = new Chart({
    chart: {
      type: 'coloumn'
    },
    title: {
      text: '',
      style: {
       font: '14px "Eagle"'
      }
    },
    plotOptions: {
      bar: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: true
          },
          showInLegend: true
      }
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: this.months
    },

    yAxis: {
        title: {
            text: ''
        }
    },
    colors: ['#e68f70'],
    series: [
      {
        name: "Owner's Take",
        type: 'column',
        data: ownersTakeMonthlyData
      }
    ]
  });
}
//#endregion

//#region setting monthly cash flow chart data related methods
getMonthlyCashFlowData() {
  this.alertService.clear()
  this.monthlyCashFlowRevenueData = []
  this.monthlyCashFlowExpenseData = []
  this.monthlyCashFlowCashRemainingData = []
  var i = 0
  while (i < 12) { this.monthlyCashFlowRevenueData[i] = 0;this.monthlyCashFlowExpenseData[i] = 0;this.monthlyCashFlowCashRemainingData[i] = 0; i++}
  if(this.businessplandashboard.BPDMonthlyData != undefined){
    for (var i= 1; i < 13; i++){
      this.businessplandashboard.BPDMonthlyData.forEach((e:any) => {
        if(e.Year === this.yearformonthlycashflow && e.Month == i.toString()){
          this.monthlyCashFlowRevenueData[i-1] = e.Revenue
          this.monthlyCashFlowExpenseData[i-1] = e.TotalExpense
          this.monthlyCashFlowCashRemainingData[i-1] = e.EndingCash
        }
      })
    }
  }
  this.prepareMonthlyCashFlowChart(this.monthlyCashFlowRevenueData, this.monthlyCashFlowExpenseData, this.monthlyCashFlowCashRemainingData);
}
 
prepareMonthlyCashFlowChart(monthlyCashFlowRevenueData: any, monthlyCashFlowExpenseData: any, monthlyCashFlowCashRemainingData: any) {
  this.alertService.clear()
  this.monthlyCashFlowChart = new Chart({
    chart: {
      type: 'coloumn'
    },
    title: {
      text: '',
      style: {
       font: '14px "Eagle"'
      }
    },
    plotOptions: {
      bar: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: true
          },
          showInLegend: true
      }
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: this.months
    },

    yAxis: {
        title: {
            text: ''
        }
    },
    colors: ['#50B432', '#ED561B', '#058DC7'],
    series: [
      {
        name: "Revenue",
        type: 'column',
        data: monthlyCashFlowRevenueData
      },
      {
        name: "Expense",
        type: 'column',
        data: monthlyCashFlowExpenseData
      },
      {
        name: "Cash Remaining",
        type: 'column',
        data: monthlyCashFlowCashRemainingData
      }
    ]
  });
}
//#endregion


exitBtnClickEvent()
{
  this.router.navigateByUrl('landingfp4fa'); 
}

//load the superuser dashboard page
public navigateSuperUserDashboard()
{
	this.router.navigateByUrl('superuserdashboard'); 
}

}




