<section class="calculator-container">
  <div class="row m-0 mobile-scroll" *ngIf="showCalculatorGrid">
    <div class="col-md-12 col-sm-11 col-xs-12 mt-2">
      <div class="scrolling outer">
        <div class="inner">
          <table class="table   table-condensed">
            <tr>
              <th class="border-top-0"></th>
              <td  *ngFor="let year of yeardatalist" class="auto font-weight-bold heading text-right"> {{ year.Year }}</td> 
            </tr> 
            <tr>
              <th class="heading">Starting Cash</th>
              <td  *ngFor="let year of yeardatalist" class="auto blue text-right"> {{ commonFunctions.currencyFormatWithNegative(year.StartingCash) }}</td>  
            </tr>
            <tr>
              <th class="heading">Income</th>
              <td  *ngFor="let year of yeardatalist" class="auto green text-right"> {{ commonFunctions.currencyFormatWithNegative(year.Income) }}</td>  
            </tr>
            <tr>
              <th class="heading">Existing AUM</th>
              <td  *ngFor="let year of yeardatalist" class="auto green text-right"> {{ commonFunctions.currencyFormatWithNegative(year.ExistingAUM) }}</td>  
            </tr>
            <tr>
              <th class="heading">Advertising</th>
              <td  *ngFor="let year of yeardatalist" class="auto red text-right"> {{ commonFunctions.currencyFormatWithNegative(year.Advertising) }}</td>  
            </tr>
            <tr>
              <th class="heading">Employees</th>
              <td  *ngFor="let year of yeardatalist" class="auto red text-right"> {{ commonFunctions.currencyFormatWithNegative(year.Employees) }}</td>  
            </tr>
            <tr>
              <th class="heading">Advisor Pay</th> 
              <td  *ngFor="let year of yeardatalist" class="auto red text-right"> {{ commonFunctions.currencyFormatWithNegative(year.AdvisorPay) }}</td>  
            </tr>
            <tr>
              <th class="heading">Payroll Taxes</th>
              <td  *ngFor="let year of yeardatalist" class="auto red text-right"> {{ commonFunctions.currencyFormatWithNegative(year.PayrollTaxes) }}</td>  
            </tr>
            <tr>
              <th class="heading">COLA Increase ( Starting in Year 2)</th>
              <td  *ngFor="let year of yeardatalist" class="auto red text-right"> {{ commonFunctions.currencyFormatWithNegative(year.COLAIncrease) }}</td>  
            </tr>
            <tr>
              <th class="heading ">Staff Performance Bonus Pool</th>
              <td  *ngFor="let year of yeardatalist" class="auto red text-right"> {{ commonFunctions.currencyFormatWithNegative(year.StaffPerformanceBonusPool) }}</td>  
            </tr>
            <tr>
              <th class="heading">Insurance Benefits</th>
              <td  *ngFor="let year of yeardatalist" class="auto red text-right"> {{ commonFunctions.currencyFormatWithNegative(year.InsuranceBenefits) }}</td>  
            </tr>
            <tr>
              <th class="heading">Retirement Benefits</th>
              <td  *ngFor="let year of yeardatalist" class="auto red text-right"> {{ commonFunctions.currencyFormatWithNegative(year.RetirementBenefits) }}</td>  
            </tr>
            <tr>
              <th class="heading">Rent</th>
              <td  *ngFor="let year of yeardatalist" class="auto red text-right"> {{ commonFunctions.currencyFormatWithNegative(year.Rent) }}</td>  
            </tr>
            <tr>
              <th class="heading">Errors and Omissions Insurance </th>
              <td  *ngFor="let year of yeardatalist" class="auto red text-right"> {{ commonFunctions.currencyFormatWithNegative(year.EAndOInsurance) }}</td>  
            </tr>
            <tr>
              <th class="heading">Business Liability Insurance </th>
              <td  *ngFor="let year of yeardatalist" class="auto red text-right"> {{ commonFunctions.currencyFormatWithNegative(year.BusinessLiabilityInsurance) }}</td>  
            </tr>
            <tr>
              <th class="heading">Worker’s Compensation Insurance </th>
              <td  *ngFor="let year of yeardatalist" class="auto red text-right"> {{ commonFunctions.currencyFormatWithNegative(year.WorkersCompensationInsurance) }}</td>  
            </tr>
            <tr>
              <th class="heading">Other Insurance Costs</th>
              <td  *ngFor="let year of yeardatalist" class="auto red text-right"> {{ commonFunctions.currencyFormatWithNegative(year.OtherInsuranceCosts) }}</td>  
            </tr>
            <tr>
              <th class="heading">Other Expenses</th>
              <td  *ngFor="let year of yeardatalist" class="auto red text-right"> {{ commonFunctions.currencyFormatWithNegative(year.OtherExpenses) }}</td>  
            </tr>
            <tr>
              <th class="heading">Total Expenses</th>
              <td  *ngFor="let year of yeardatalist" class="auto red text-right"> {{ commonFunctions.currencyFormatWithNegative(year.TotalExpenses) }}</td>  
            </tr>
            <tr>
              <th class="heading">Net Income</th>
              <td  *ngFor="let year of yeardatalist" class="auto green text-right"> {{ commonFunctions.currencyFormatWithNegative(year.NetIncome) }}</td>  
            </tr>
            <tr>
              <th class="heading">Gross Owners Take</th>
              <td  *ngFor="let year of yeardatalist" class="auto green text-right"> {{ commonFunctions.currencyFormatWithNegative(year.GrossOwnersTake) }}</td>  
            </tr> 
            <tr>
              <th class="heading">Owner’s Federal Income tax</th>
              <td  *ngFor="let year of yeardatalist" class="auto darkred text-right"> {{ commonFunctions.currencyFormatWithNegative(year.OwnersFederalIncomeTax) }}</td>  
            </tr>
            <tr>
              <th class="heading">Owner’s State Income tax</th>
              <td  *ngFor="let year of yeardatalist" class="auto darkred text-right"> {{ commonFunctions.currencyFormatWithNegative(year.OwnersStateIncomeTax) }}</td>  
            </tr>
            <tr>
              <th class="heading">Net Owner’s Take</th>
              <td  *ngFor="let year of yeardatalist" class="auto darkgreen text-right"> {{ commonFunctions.currencyFormatWithNegative(year.NetNetOwnersTake) }}</td>  
            </tr>
            <tr>
              <th class="heading">Ending Cash</th>
              <td  *ngFor="let year of yeardatalist" class="auto green text-right"> {{ commonFunctions.currencyFormatWithNegative(year.EndingCash) }}</td>  
            </tr>
            <tr>
              <th class="heading">Max Draw Down</th>
              <td class="auto yellow"><span>{{ maxDrawDown }} </span></td>
              <td class="auto yellow"><span>{{ maxDrawDownMonth }} </span></td>
              <td class="auto"><span> </span></td>
              <td class="auto"><span> </span></td>
              <td class="auto"><span></span></td>
            </tr>
            <tr>
              <th class="heading">Low Positive Cash Balance</th>
              <td class="auto yellow"><span>{{ lowPositiveCashBalance }}</span></td>
              <td class="auto yellow"><span>{{ lowPositiveCashBalanceMonth }}</span></td>
              <td class="auto"><span> </span></td>
              <td class="auto"><span> </span></td>
              <td class="auto"><span></span></td>
            </tr> 
            
          </table>
        </div>
      </div>
    </div>
  </div>
</section>



<!-- <section class="calculator-container">
  <div class="row m-0">
    <div class="col-md-12 col-sm-11 col-xs-12 mt-2">
      <div class="scrolling outer">
        <div class="inner">
          <table class="table   table-condensed">
            <tr>
              <th class="border-top-0"></th>
              <td class="auto font-weight-bold heading">2020</td>
              <td class="auto font-weight-bold heading">2021</td>
              <td class="auto font-weight-bold heading">2022</td>
              <td class="auto font-weight-bold heading">2023</td>
              <td class="auto font-weight-bold heading">2024</td>
            </tr>
            <tr>
              <th class="heading">Starting Cash</th>
              <td class="auto blue"><span>$ 40,000 </span></td>
              <td class="auto blue"><span>$ 40,000 </span></td>
              <td class="auto blue"><span>$ 40,000 </span></td>
              <td class="auto blue"><span>$ 40,000 </span></td>
              <td class="auto blue"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Income</th>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Advertising</th>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Employees</th>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Advisor Pay</th>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Payroll Taxes</th>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">COLO Increase ( Year 2-5)</th>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Staff Performance Bonus Pool</th>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Rent</th>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Insurance</th>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Other Expenses</th>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Total Expenses</th>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
              <td class="auto red"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Net Income</th>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Gross Owners Take</th>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Owner’s Federal Income tax</th>
              <td class="auto darkred"><span>$ 40,000 </span></td>
              <td class="auto darkred"><span>$ 40,000 </span></td>
              <td class="auto darkred"><span>$ 40,000 </span></td>
              <td class="auto darkred"><span>$ 40,000 </span></td>
              <td class="auto darkred"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Owner’s State Income tax</th>
              <td class="auto darkred"><span>$ 40,000 </span></td>
              <td class="auto darkred"><span>$ 40,000 </span></td>
              <td class="auto darkred"><span>$ 40,000 </span></td>
              <td class="auto darkred"><span>$ 40,000 </span></td>
              <td class="auto darkred"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Net Owner’s Take</th>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Ending Cash</th>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
              <td class="auto green"><span>$ 40,000 </span></td>
            </tr>
            <tr>
              <th class="heading">Max Draw Down</th>
              <td class="auto yellow"><span>$ 40,000 </span></td>
              <td class="auto"><span> </span></td>
              <td class="auto"><span> </span></td>
              <td class="auto"><span> </span></td>
              <td class="auto"><span></span></td>
            </tr>
            <tr>
              <th class="heading">Low Positive Cash Balance</th>
              <td class="auto yellow"><span>$ 40,000 </span></td>
              <td class="auto"><span> </span></td>
              <td class="auto"><span> </span></td>
              <td class="auto"><span> </span></td>
              <td class="auto"><span></span></td>
            </tr>
            
          </table>
        </div>
      </div>
    </div>
  </div>
</section> -->