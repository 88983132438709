<div class="main-panel pt-0 pb-4">
	<div class="container-fluid">
		<div class="col-sm-12 px-0">
			<h3 class="light-header bold">
				{{ 'WEALTHRE.LBL_TITLE' | translate}}
				<button type="button" class="btn btn-primary btn-sm float-right ml-2" (click)="exitBtnClickEvent()">
						<i class="fas fa-sign-out"></i> Exit
					</button>
				<button type="button" class="btn btn-primary btn-sm float-right" (click)="printBtnClickEvent()"> 
					<i class="fas fa-print"></i> Print
				</button>
				
			</h3>
			<hr class="mt-0 mb-1" />
			<div class="pt-3">
				<!-- <div class="container border rounded p-3 bold"> -->
					<h5 class="light-header bold">
						{{ 'WEALTHRE.LBL_GRAPH_TITLE' | translate}}
					</h5>
					<!-- <hr class="mt-0 mb-3" /> -->
					<div id="grid-wrapper" class="col-sm-12 px-0" style="width: 100%; overflow: hidden;">
						<div class="card widget mb-3 mt-3">
							<div _ngcontent-ols-c2="" class="card-body p-5">
								<div [chart]="wealthReport"></div>                       
							</div>
						</div> 
						<h5 class="light-header bold">
							{{ 'WEALTHRE.LBL_GRID_TITLE' | translate}}
						</h5>
						<div class="card widget mb-3 mt-3">
							<div id="grid-wrapper" class="col-sm-12 px-0" style="width: 100%; overflow: hidden;">
								<div class="col-sm-12 px-0" >
								  <ag-grid-angular style="width: 100%; height: 300px; " class="ag-theme-balham bold"
									[columnDefs]="columnDefs" (gridReady)="onGridReady($event)" [rowData]="rowData"
									(gridSizeChanged)="gridSizeChanged($event)"
									[headerHeight]="60" [gridOptions]="gridOptions" >
								  </ag-grid-angular>
								</div>
							</div> 
						</div> 
					</div>
				<!-- </div> -->
			</div>
		</div>
	</div>
</div>