import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../../util/localstorage.service'; 
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Choice } from '../../models/choice.model';
import { Client } from '../../models/client.model';
import { Agent } from '../../models/agent.model';
import { IncomeSourceAccount } from '../../models/income-source-accout.model';
import { ClientInfoDataService } from './../client-info/client-info-data.service';
import { AlertService } from '../../util/alert/alert.service';
import { CommonFunctions } from '../../util/common-functions';
import { Constants } from '../../util/constants' 
import {formatDate} from '@angular/common'; 
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { GridOptions } from 'ag-grid-community'
import { CustomHeader} from '../../util//customHeader'

@Component({
  selector: 'app-income-source',
  templateUrl: './income-source.component.html',
  styleUrls: ['./income-source.component.scss'] 
})  

export class IncomeSourceComponent implements OnInit {
  
//#region "Variable declaration"
  public isShowDashboard: boolean = false
  public selectedChoiceID: string = "";
  public ownerlist :Array<any> = []
  public startyearlist :Array<any> = []
  public endyearlist :Array<any> = []
  public owner: any
  public source:any
  public startdatemonth:any
  public enddatemonth:any
  public client:Client
  public loggedAgent: Agent 
  public incomeSourceAccountList : Array<IncomeSourceAccount> =  []
  public incomeSourceAccount  :  IncomeSourceAccount = new IncomeSourceAccount
  public colaFlag:any
  public survivorBenefitFlag:any
  public isSurvivorBenefitActive: boolean = false
  public colaPct:any
  public survivorBenefitPct:any
  public monthlyAmount : any
  public annualAmount:any
  public activeTabName :any  
  public bISCopyMode :boolean

  public iSForm: FormGroup;
  private hasSpouse:boolean

  private gridApi: any;
	private gridColumnApi: any;

	public columnDefs: any;
  public rowData: any;	
  public gridOptions : GridOptions 
  public currentSelectedColumn;
  
  private mISStartDate:Date
  private mISEndDate:Date
  private currentISID:string

  private commonFunctions: CommonFunctions = new CommonFunctions
  orderID :number = 0  
  public isPresentedToClient: boolean = false
//#endregion

//#region "constructor,  and ngOnInit"
  constructor( private router: Router,  private ldService: ClientInfoDataService,
    private formBuilder: FormBuilder, private alertService: AlertService) { 

      this.gridOptions = { 
        components: {
          agColumnHeader: CustomHeader
        },
        defaultColDef: { 
          headerComponentParams: {
            setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
            getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          } 
        } 
      };

      this.columnDefs = [
       /* { 
          headerName: 'IncomeSourceID', 
          field: 'IncomeSourceID'//, 
         // sortable: true, 
          //width: 125,
         // filter: true //,
         // cellClass: "rag-gray"
        },*/
        { 
          headerName: 'Owner', 
          field: 'Owner'//, 
         // sortable: true, 
         // width: 125,
         // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Source', 
          field: 'Source'//, 
         // sortable: true, 
         // width: 125,
         // filter: true,
         // cellClass: "rag-blue"
        },
        { 
          headerName: 'Monthly Amount', 
          field: 'MonthlyAmount',
          cellStyle: {textAlign: "right"}//,  
         // sortable: true, 
         // filter: true,
         // cellClass: "rag-yellow"
        },
        { 
          headerName: 'Annual Amount', 
          field: 'AnnualAmount',
          cellStyle: {textAlign: "right"}
          //, 
         // sortable: true, 
         // filter: true,
          //cellClass: "rag-yellow"
        }  
      ];
  }

  ngOnInit() {

    let clientFromStorage: Client = new Client
    this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent;
		if(this.loggedAgent.UserRoleID== Constants.UserRoleID.Superuser)
			this.isShowDashboard = true
    this.selectedChoiceID = localStorage.getItem(LocalStorage.SELECTED_CHOICEID)  
     
    this.client   = JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) as Client;  
    if (typeof this.client.IncomeSourceAccount === 'undefined') 
        this.incomeSourceAccountList = [] 
    else
        this.incomeSourceAccountList = JSON.parse(JSON.stringify(this.client.IncomeSourceAccount ));
 
 
  // this.client.IncomeSourceAccount.filter(x => x.ChoiceID == this.selectedChoiceID).forEach((element:IncomeSourceAccount) => { 
     // console.log(element.IncomeSourceID) 
   // });

   this.activeTabName = this.client.Choice.find(x => x.ChoiceID == this.selectedChoiceID).ChoiceName
    
   this.alertService.clear()
   if (this.activeTabName !== Constants.CURRENT_CHOICE_NAME)
   {      
      this.alertService.warn('Warning! You are editing the '+ this.activeTabName +' Tab, new tabs should be created from the '+ Constants.CURRENT_CHOICE_NAME +' tab.')      
   } 
     
    this.loadIncomeSourceStaticDataForNew()
    this.loadIncomeSourceStartYearList(this.client.ClientDOB,null)


   this.iSForm = this.formBuilder.group({
			owner: new FormControl(null) ,
      source: new FormControl(null),
      startdate: new FormControl(null),
      enddate: new FormControl(null),
      cola: new FormControl(null),
      survivorbenefit: new FormControl(null),
      colapct: new FormControl(null),
      survivorbenefitpct: new FormControl(null),
      monthlyamount: new FormControl(null),
      annualamount: new FormControl(null)
    } );   
    let currentChoice = this.client.Choice.find(x => x.ChoiceID == this.selectedChoiceID) 
    if(currentChoice.PresentedToClient){
      this.isPresentedToClient = true
      this.iSForm.disable()
    }
  }
//#endregion

//#region Ag grid
  getCurrentSelectedColumn() {
    return this.currentSelectedColumn;
  }
    
  setCurrentSelectedColumn(colId) {
    this.currentSelectedColumn = colId;
  }

  onGridReady(params: any) { 
		this.gridApi  = params.api;
		this.gridColumnApi = params.columnApi; 
		setTimeout(() => { 
        this.loadgrid()     
      	//params.api.sizeColumnsToFit();
    }, 50);

    setTimeout(()=> {
      params.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));  
      if (params.api.getDisplayedRowCount()>0)
      {
        var selectedIncomeSourceID : string = params.api.getSelectedRows()[0]["IncomeSourceID"];
        this.loadIncomeSource(selectedIncomeSourceID,false);   
      } 
   }, 500);  
    //params.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true)); 

   /*  params.api.forEachNode( function(node) {
      // select the node
      node.setSelected(true);
    }); */ 
  }

  gridSizeChanged(params: any) {
		// console.info(params)
		params.api.sizeColumnsToFit();
  }
  
  public onCellClicked(event: any) {
    var index = event.rowIndex
    // console.log('[onCellClicked] (event.data) : ' + JSON.stringify(event.data)); 
    if(this.rowData.findIndex(x=> x.IncomeSourceID  === "") != -1 && this.rowData.findIndex(x=> x.IncomeSourceID === "") != index){
      if (confirm("You have unsaved data. Are you sure you want to select this record?")) {  
        this.rowData.splice(this.rowData.findIndex(x=> x.IncomeSourceID == ""), 1) 
        this.gridOptions.api.setRowData(this.rowData); 
        var selectedIncomeSourceID : string = event.data.IncomeSourceID
        this.loadIncomeSource(selectedIncomeSourceID,false) 
        this.gridOptions.onRowDataChanged = params => {
          this.gridApi.forEachNode(node => {
            if ( node.rowIndex === index ){ 
              node.setSelected(true)
            } 
          }); 
        }  
             
      }
    } else{
      event.node.setSelected(true);
      var selectedIncomeSourceID : string = event.data.IncomeSourceID
      this.loadIncomeSource(selectedIncomeSourceID,false) 
    }
      
  }

  onSelectionChanged(event: any){  

    if (this.bISCopyMode) return
   // var selectedIncomeSourceID : string = event.data.IncomeSourceID
   if (typeof this.gridApi.getSelectedRows()[0] === 'undefined') return

    if (this.gridApi.getSelectedRows()[0]["IncomeSourceID"]==="")
    {
      this.loadIncomeSourceStaticDataForNew()
    }
    else
    {
      this.loadIncomeSource(this.gridApi.getSelectedRows()[0]["IncomeSourceID"],false)
    }
  } 

  loadgrid()
  {
     let tempString: Array<any>=[] 
     if (  typeof this.client.IncomeSourceAccount !== 'undefined' && this.client.IncomeSourceAccount.length>0)
    {
   
        let selectedIncomeSourceAccount:Array<IncomeSourceAccount>=[]
        selectedIncomeSourceAccount = this.client.IncomeSourceAccount.filter(x => x.ChoiceID == this.selectedChoiceID) as Array<IncomeSourceAccount>
 
        let sortedIncomeSourceAccount:Array<IncomeSourceAccount>=[]

        sortedIncomeSourceAccount =selectedIncomeSourceAccount.sort((a, b) => Number(a.DisplayOrder) - Number(b.DisplayOrder));
       // sortedIncomeSourceAccount =selectedIncomeSourceAccount.sort(x => Number( x.DisplayOrder)) as Array<IncomeSourceAccount>
   
        sortedIncomeSourceAccount.forEach((element:IncomeSourceAccount) => { 
        let monthlyAmount =Number(element.AnnualAmount)/12
        let annualAmount = Number(element.AnnualAmount) 
      
        tempString.push({IncomeSourceID: element.IncomeSourceID, Owner:element.Owner,
        Source: element.Source, MonthlyAmount: this.commonFunctions.currencyFormat(monthlyAmount),
        AnnualAmount:this.commonFunctions.currencyFormat(annualAmount)})

        });        
  }
    this.rowData=tempString 
  }
//#endregion

//#region "methods to load data and initialize"
  loadIncomeSourceStaticDataForNew()
  {   
    this.ownerlist =[]
    this.ownerlist.push({text: this.client.ClientFirstName.trim() + " " + this.client.ClientLastName.trim() })
     
    if (this.client.SpouseFirstName != "" && this.client.SpouseLastName != "" && 
          typeof this.client.SpouseFirstName !== 'undefined'  && 
          typeof this.client.SpouseLastName !== 'undefined' )  
   
    { 
      this.hasSpouse=true
      this.ownerlist.push({text: this.client.SpouseFirstName.trim() + " " + this.client.SpouseLastName.trim() })
      this.ownerlist.push({text: this.client.ClientFirstName.trim().substring(0,1) + "&" + this.client.SpouseFirstName.trim().substring(0,1) + " " + this.client.SpouseLastName.trim() })
    }
    else
    {
      this.hasSpouse=false
    } 
    this.owner = ""
    this. intializeIncomeSourceAccountDetails()
    
  }

  intializeIncomeSourceAccountDetails()
   {
    this.mISStartDate = new Date()
    this.mISEndDate = new Date(8640000000000000) //max date 

    this.colaFlag = 0
    this.colaPct = "0.00%"
    this.survivorBenefitFlag = 0
    this.survivorBenefitPct = "0.00%"
    this.monthlyAmount = 0
    this.annualAmount = "$" + "0" //"0.00" remove cents
    this.currentISID =""

    this.startdatemonth = ""
    this.enddatemonth = -1

    this.source = ""  

    if (typeof this.client.IncomeSourceAccount === 'undefined') 
        this.incomeSourceAccountList  = [] 

  
  // cbSource.Text = ""  

   this.bISCopyMode = false 
  }

  loadIncomeSource(selectedIncomeSourceID:string,bCopy:boolean)
  {

    this.incomeSourceAccount = new IncomeSourceAccount()
    this.incomeSourceAccount= this.client.IncomeSourceAccount.find(x => x.IncomeSourceID === selectedIncomeSourceID)
     
    this.owner = this.incomeSourceAccount.Owner
    this.source = this.incomeSourceAccount.Source

    this.mISStartDate =new Date(this.incomeSourceAccount.StartDate)
    this.mISEndDate = new Date(this.incomeSourceAccount.EndDate) 

    if (bCopy)
    {
        this.currentISID =""
    }
    else
    {
        this.currentISID = this.incomeSourceAccount.IncomeSourceID
    }

 

    if (typeof this.client.SpouseLastName === 'undefined' )
    {
      this.loadIncomeSourceStartYearList(this.client.ClientDOB, this.incomeSourceAccount)
      this.startdatemonth = this.getAgeInMonths(this.client.ClientDOB,new Date(this.incomeSourceAccount.StartDate))
      this.enddatemonth = this.getAgeInMonths(this.client.ClientDOB,new Date(this.incomeSourceAccount.EndDate))
    }
    else
    {
        if (this.incomeSourceAccount.Owner.trim().replace(" ","")==this.client.SpouseFirstName.trim() + this.client.SpouseLastName.trim())
        {      
          this.loadIncomeSourceStartYearList(this.client.SpouseDOB, this.incomeSourceAccount)      
          this.startdatemonth = this.getAgeInMonths(this.client.SpouseDOB,new Date(this.incomeSourceAccount.StartDate))
          this.enddatemonth = this.getAgeInMonths(this.client.SpouseDOB,new Date(this.incomeSourceAccount.EndDate))
      
        }
        else
        { 
          this.loadIncomeSourceStartYearList(this.client.ClientDOB, this.incomeSourceAccount)
          this.startdatemonth = this.getAgeInMonths(this.client.ClientDOB,new Date(this.incomeSourceAccount.StartDate))
          this.enddatemonth = this.getAgeInMonths(this.client.ClientDOB,new Date(this.incomeSourceAccount.EndDate))
        }  
    }

    this.startdatemonth=this.startyearlist.find(x=>x.value===this.startdatemonth).value 
 
    if (new Date(this.incomeSourceAccount.EndDate).getFullYear()!=9999)
    { 
        this.enddatemonth=this.endyearlist.find(x=>x.value===this.enddatemonth).value
    }
    else
    {
      this.enddatemonth = -1
    } 

    if (this.incomeSourceAccount.COLAFlag == true) { 
        this.colaFlag = 1
        this.colaPct = Number(this.incomeSourceAccount.COLAPct).toFixed(2) + "%" 
    }
    else{
        this.colaFlag = 0
        this.colaPct = "0.00%"
    }    

    if (this.incomeSourceAccount.SurvivorBenefitFlag == true) { 
      this.survivorBenefitFlag = 1
      this.survivorBenefitPct = Number(this.incomeSourceAccount.SurvivorBenefitPct).toFixed(2) + "%" 
    }
    else{ 
      this.survivorBenefitFlag = 0
      this.survivorBenefitPct = "0.00%"
    }    

    let tempMonthlyAmount:number = Number(this.incomeSourceAccount.AnnualAmount)/12
    this.monthlyAmount = this.commonFunctions.currencyFormat(tempMonthlyAmount) // "$" + tempMonthlyAmount.toFixed(2) 
    this.annualAmount =  this.commonFunctions.currencyFormat(this.incomeSourceAccount.AnnualAmount)+ " yearly"  //"$" + Number(this.incomeSourceAccount.AnnualAmount).toFixed(2) + " yearly"  

    //load copied data row to the grid 
    if (bCopy)
    {

      this.loadgrid()

      let monthlyAmount =Number(this.incomeSourceAccount.AnnualAmount)/12
      let annualAmount = Number(this.incomeSourceAccount.AnnualAmount) 

/*       this.rowData.push({IncomeSourceID: "",Owner: this.incomeSourceAccount.Owner,
      Source: this.incomeSourceAccount.Source, MonthlyAmount: '$' + Number(monthlyAmount).toFixed(2),
      AnnualAmount:'$' + Number(annualAmount).toFixed(2)}) */

      this.rowData.push({IncomeSourceID: "",Owner: this.incomeSourceAccount.Owner,
      Source: this.incomeSourceAccount.Source, MonthlyAmount: this.commonFunctions.currencyFormat(monthlyAmount),
      AnnualAmount: this.commonFunctions.currencyFormat(annualAmount)})

      setTimeout(()=> {
      //this.gridApi.forEachNode(node => node.rowIndex ? (this.gridApi.getDisplayedRowCount()-1) : node.setSelected(true));  
      this.gridApi.forEachNode(node => {
        if ( node.rowIndex  === (this.gridApi.getDisplayedRowCount()-1) ){ 
          node.setSelected(true)
          this.gridApi.ensureIndexVisible(Number(node.rowIndex));
        } 
      }); 
    
    }, 50);   
       /*  //Add a blank row when click on save
        Dim newrow As String()
        newrow = New String() {"", cbOwner.Text, cbSource.Text, FormatCurrency(txtMonthlyAmount.Text), FormatCurrency(lblYearlyIncomeSource.Text)}
        dgvIncomeSource.Rows.Add(newrow)

        Dim LastRowIndex As Integer = dgvIncomeSource.Rows.Count - 1
        If LastRowIndex >= 0 Then
            dgvIncomeSource.Rows(LastRowIndex).Selected = True
            dgvIncomeSource.CurrentCell = dgvIncomeSource.Rows(LastRowIndex).Cells(1)
            EnableISMoveUpDown()
        End If */
   }  
    
    if(this.source == "Social Security" || this.source == "Employment Income"){
      this.isSurvivorBenefitActive = true
      this.survivorBenefitFlag = 0
      this.survivorBenefitPct = "0.00%"
    }else{
      this.isSurvivorBenefitActive = false
    }
     
  }

  loadIncomeSourceStartYearList(DOB: Date, oIncomeSourceAccount : IncomeSourceAccount)
  {
 
     var CurrentAge : number
     var CurrentYear : number
     var CurrentMonth : number
     var CurrentAgeInMonths : number

     var StartYear : number = -99
     var StartMonth : number = -99

     this.startyearlist = []
     this.endyearlist = [] 

     if (oIncomeSourceAccount!=null) 
     {
         if( this.mISStartDate.getFullYear() <= new Date().getFullYear() )
         {
             StartYear = this.mISStartDate.getFullYear()
             StartMonth =  this.mISStartDate.getMonth() +1
         }
         else
         {
             StartYear = new Date().getFullYear()
             StartMonth = new Date().getMonth() +1
         } 


         CurrentAge = StartYear - new Date(DOB).getFullYear()
         CurrentYear = StartYear  
        // StartMonth = 1   

         //CurrentAgeInMonths =this.getAgeInMonths(DOB, StartYear +"/" + StartMonth + "/"+ 1) // DateDiff(DateInterval.Month, DOB, New Date(StartYear, StartMonth, 1))
         CurrentAgeInMonths =this.getAgeInMonths(DOB, new Date(StartYear, StartMonth-1, 1)) //NOTE have to pass Startdate-1 as javascript start month from 0
          
       }
     else
     {    

      // alert(new Date().getMonth())
       CurrentAge =  new Date().getFullYear() - new Date(DOB).getFullYear()
       CurrentYear =  new Date().getFullYear() 
       StartYear = CurrentYear
       StartMonth =  new Date().getMonth()+1 // add one as javascript start month from 0 

       CurrentAgeInMonths = this.getAgeInMonths(DOB, new Date()) // DateDiff(DateInterval.Month, DOB, Date.Today)
     } 
     

     this.startyearlist.push({value:"", text:""})
     this.endyearlist.push({value:-1, text:""})

     var count : number = 0
     // For i As Integer = CurrentAge To 100
     for (let i = CurrentAge; i <= 100; i++)
     { 
         if (CurrentYear == StartYear)  
         { 
           CurrentMonth = StartMonth
         }
         else
         {
           CurrentMonth = 1
         } 

         //For iMonth As Integer = CurrentMonth To 12
         for (let iMonth = CurrentMonth; iMonth <= 12; iMonth++)
         {
             var MonthString : String = "" 
            switch (iMonth)
            {
                 case 1:
                     MonthString = "January"
                     break;
                 case 2:
                     MonthString = "February"
                     break;
                 case 3:
                     MonthString = "March"
                     break;
                 case 4:
                     MonthString = "April"
                     break;
                 case 5:
                     MonthString = "May"
                     break;
                 case 6:
                     MonthString = "June"
                     break;
                 case 7:
                     MonthString = "July"
                     break;
                 case 8:
                     MonthString = "August"
                     break;
                 case 9:
                     MonthString = "September"
                     break;
                 case 10:
                     MonthString = "October"
                     break;
                 case 11:
                     MonthString = "November"
                     break;
                 case 12:
                     MonthString = "December"
                     break;
             } 
          
             var displayAge : number
             displayAge = Math.floor( CurrentAgeInMonths / 12 )
             
            // 26, (September 2022) AGE xx.

             this.startyearlist.push({value:CurrentAgeInMonths, text: count + ", ("+ MonthString + " " + CurrentYear + ")"  + " AGE " + displayAge})
             this.endyearlist.push({value:CurrentAgeInMonths, text: count + ", ("+ MonthString + " " + CurrentYear + ")"  + " AGE " + displayAge})
           
             //this.startyearlist.push({value:CurrentAgeInMonths, text: count + " ("+ MonthString + "," + CurrentYear + ")"  })
             //this.endyearlist.push({value:CurrentAgeInMonths, text:  MonthString + "," + CurrentYear + " " + "(Age=" + displayAge + ")"})

             //comboSourceStartYear.Add(CurrentAgeInMonths, MonthString & "," & CurrentYear & " " & "(Age=" & displayAge & ")")
             //comboSourceEndYear.Add(CurrentAgeInMonths, MonthString & "," & CurrentYear & " " & "(Age=" & displayAge & ")")
             CurrentAgeInMonths += 1 
             count += 1 
         } 
         CurrentAge += 1
         CurrentYear += 1 

     }   
  }

  /* 
  loadIncomeSourceStartYearListOld(DOB: Date, oIncomeSourceAccount : IncomeSourceAccount)
  {
  
      var CurrentAge : number
      var CurrentYear : number
      var CurrentMonth : number
      var CurrentAgeInMonths : number

      var StartYear : number = -99
      var StartMonth : number = -99

      this.startyearlist = []
      this.endyearlist = []

      if (oIncomeSourceAccount!=null) 
      {
          if( this.mISStartDate.getFullYear() < new Date().getFullYear() )
          {
              StartYear = this.mISStartDate.getFullYear()
          }
          else
          {
              StartYear = new Date().getFullYear()
          } 


          CurrentAge = StartYear - new Date(DOB).getFullYear()
          CurrentYear = StartYear  
          StartMonth = 1   

          //CurrentAgeInMonths =this.getAgeInMonths(DOB, StartYear +"/" + StartMonth + "/"+ 1) // DateDiff(DateInterval.Month, DOB, New Date(StartYear, StartMonth, 1))
          CurrentAgeInMonths =this.getAgeInMonths(DOB, new Date(StartYear, StartMonth-1, 1)) //NOTE have to pass Startdate-1 as javascript start month from 0
           
        }
      else
      {    

       // alert(new Date().getMonth())
        CurrentAge =  new Date().getFullYear() - new Date(DOB).getFullYear()
        CurrentYear =  new Date().getFullYear() 
        StartYear = CurrentYear
        StartMonth =  new Date().getMonth()+1 // add one as javascript start month from 0

        CurrentAgeInMonths = this.getAgeInMonths(DOB, new Date()) // DateDiff(DateInterval.Month, DOB, Date.Today)
      } 

      this.startyearlist.push({value:"", text:""})
      this.endyearlist.push({value:-1, text:""})

      var count : number = 0
      // For i As Integer = CurrentAge To 100
      for (let i = CurrentAge; i <= 100; i++)
      { 
          if (CurrentYear == StartYear)  
          {
            CurrentMonth = StartMonth
          }
          else
          {
            CurrentMonth = 1
          } 

          //For iMonth As Integer = CurrentMonth To 12
          for (let iMonth = CurrentMonth; iMonth <= 12; iMonth++)
          {
              var MonthString : String = "" 
             switch (iMonth)
             {
                  case 1:
                      MonthString = "January"
                      break;
                  case 2:
                      MonthString = "February"
                      break;
                  case 3:
                      MonthString = "March"
                      break;
                  case 4:
                      MonthString = "April"
                      break;
                  case 5:
                      MonthString = "May"
                      break;
                  case 6:
                      MonthString = "June"
                      break;
                  case 7:
                      MonthString = "July"
                      break;
                  case 8:
                      MonthString = "August"
                      break;
                  case 9:
                      MonthString = "September"
                      break;
                  case 10:
                      MonthString = "October"
                      break;
                  case 11:
                      MonthString = "November"
                      break;
                  case 12:
                      MonthString = "December"
                      break;
              } 
           
              var displayAge : number
              displayAge = Math.floor( CurrentAgeInMonths / 12 )
              

              this.startyearlist.push({value:CurrentAgeInMonths, text: count + ", ("+ MonthString + " " + CurrentYear + ")"  + " AGE " + displayAge})
              this.endyearlist.push({value:CurrentAgeInMonths, text: count + ", ("+ MonthString + " " + CurrentYear + ")"  + " AGE " + displayAge})
           

              //this.startyearlist.push({value:CurrentAgeInMonths, text:  MonthString + "," + CurrentYear + " " + "(Age=" + displayAge + ")"})
              //this.endyearlist.push({value:CurrentAgeInMonths, text:  MonthString + "," + CurrentYear + " " + "(Age=" + displayAge + ")"})
 
              CurrentAgeInMonths += 1 
              count += 1 
          } 
          CurrentAge += 1
          CurrentYear += 1 

      }  
  }
 */
  
  getAgeInMonths(dob:Date,toThisDate: any) {
    var toDate = new Date(toThisDate);
    var birthDate= new Date(dob);
    var years = toDate.getFullYear() - birthDate.getFullYear(); 
    
    var m = toDate.getMonth() - birthDate.getMonth(); 
    var age = years * 12 + m; 
    return age;
  }

  getNextOrder(incometype :string) : any{ 
    var currentOrderID :number = 0
    if (incometype == Constants.TABLE_NAME.IncomeSources)  
    { 
      if (typeof this.client.IncomeSourceAccount === 'undefined')
          currentOrderID = 0 
      else
      {
        var tempIncomeSourceAccount :Array<IncomeSourceAccount> =[]
        tempIncomeSourceAccount = this.client.IncomeSourceAccount.filter(x=> x.ChoiceID= this.selectedChoiceID) 

        if (tempIncomeSourceAccount.length > 0)  
          currentOrderID = Math.max.apply(Math, tempIncomeSourceAccount.map(function(o) { return o.DisplayOrder; }))
            //currentOrderID = tempIncomeSourceAccount.Max(Function(g) g.DisplayOrder)
        else
            currentOrderID = 0 
      }
    
    }
    else  if (incometype == Constants.TABLE_NAME.IncomeFromAssets)  
    {
       /* var tempIncomeFromAsset As New List(Of Models.IncomeFromAsset)
        tempIncomeFromAsset = oClient.IncomeFromAsset.Where(Function(x) x.ChoiceID.ToString = mSelectedChoiceID.ToString()).ToList
        If tempIncomeFromAsset.Count > 0 Then
            currentOrderID = tempIncomeFromAsset.Max(Function(g) g.DisplayOrder) + 20 ' Adding 20 to make sure new IncomeFromAssest goes to last
        Else
            currentOrderID = 0 + 20 ' Adding 20 to make sure new IncomeFromAssest goes to last
        End If*/
    }
    else if (incometype == Constants.TABLE_NAME.Choice)     
    {      
      /*  if (this.client.Choice.length > 0 )
            currentOrderID = Math.max.apply(Math, this.client.Choice.map(function(o) { return o.DisplayOrder; }))
        else
            currentOrderID = 0  */        
    }


    this.orderID = currentOrderID + 1 //iOrder + 1
    return this.orderID

  }

  searchinarray(nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
     
        if (myArray[i].value === nameKey) {
          //console.log(myArray[i].value)
            return myArray[i].value;
        }
    }
  }
//#endregion

//#region "on input field changes"
  changeCOLAFlag(colaFlag: any){
  if (colaFlag == '1') { 
    this.incomeSourceAccount.COLAFlag =true
  }
  else
  {
    this.incomeSourceAccount.COLAFlag =false
    this.colaPct = "0.00%"
  }
  }

  changeSurvivorBenefitFlag(survivorBenefitFlag: any){
    if (survivorBenefitFlag == '1') { 
      this.incomeSourceAccount.SurvivorBenefitFlag =true 
    }
    else
    {
      this.incomeSourceAccount.SurvivorBenefitFlag =false 
      this.survivorBenefitPct = "0.00%"
    }
  }

  ownerSelectedValueChanged(ownerVal: any)
   {  
       if (ownerVal=="") return 
       
       this.intializeIncomeSourceAccountDetails()
 

       if (typeof this.client.SpouseFirstName === 'undefined')
       {
          this.loadIncomeSourceStartYearList(this.client.ClientDOB,null) 
       }
       else
       {
          if (ownerVal.toString().trim().replace(" ","")==this.client.SpouseFirstName.toString().trim() + this.client.SpouseLastName.toString().trim())
            this.loadIncomeSourceStartYearList(this.client.SpouseDOB,null) 
          else  
            this.loadIncomeSourceStartYearList(this.client.ClientDOB,null) 
       }
       
  }

  monthlyAmountChanged(monthlyamt:any)
  { 

    if(!isNaN(monthlyamt.toString().replace('$','').replace(/,/g,'')))
    {
      this.monthlyAmount = this.commonFunctions.currencyFormat(Number(monthlyamt.toString().replace('$','').replace(/,/g,'')))
      //this.annualAmount = "$" + (Number(this.iSForm.controls.monthlyamount.value.toString().replace("$","").replace(/,/g,''))*12).toFixed(2)+ " yearly"  
      this.annualAmount = this.commonFunctions.currencyFormat(Number(this.iSForm.controls.monthlyamount.value.toString().replace("$","").replace(/,/g,''))*12) + " yearly"  
    }  
    else
    {
     // this.annualAmount = Number(0).toFixed(2)+ " yearly"  // removing cents
      this.annualAmount = Number(0)+ " yearly"  
    }
    
  }

  onMonthlyAmountTextChange(monthlyamt:any)
  {
    if(!isNaN(monthlyamt.toString().replace('$','').replace(/,/g,'')))
    {
      //this.annualAmount = "$" + (Number(this.iSForm.controls.monthlyamount.value.toString().replace("$","").replace(/,/g,''))*12).toFixed(2)+ " yearly"  
      this.annualAmount = this.commonFunctions.currencyFormat(Number(monthlyamt.replace("$","").replace(/,/g,''))*12) + " yearly"  
    }  
    else
    {
     // this.annualAmount = Number(0).toFixed(2)+ " yearly"   // removing cents
      this.annualAmount = Number(0)+ " yearly"  
    }
  }

  onMonthlyAmountKeyDown()
  {
    console.log("keydone")

    /* console.log( $(this).val(function(index, value))

    $(this).val(function(index, value) {
      return value
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      ;
    }); */
  }
//#endregion

//#region "button click events"
  saveBtnClickEvent()
  {  
    // Validation
    if (this.validateIncomeSource()){

      //Assign Data  
    this.setIncomeSource() 
  
    //Save Client to database
    this.saveClient() 

    this.bISCopyMode =false

    localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(this.client));  
    this.loadgrid()    

    setTimeout(()=> {
      this.gridApi.forEachNode(node => {
        var tempselectedIncomeSourceID : string = node.data.IncomeSourceID
        if ( tempselectedIncomeSourceID === this.currentISID){  
          node.setSelected(true)
          this.gridApi.ensureIndexVisible(Number(node.rowIndex));
        } 
      });  


      /* this.gridApi.forEachNode(node => {
        if ( node.rowIndex  === (this.gridApi.getDisplayedRowCount()-1) ){ 
          node.setSelected(true)
        } 
      });  */
    
    }, 50);   

    }
  }

  cancelBtnClickEvent()
  {
    this.owner = ""
    this.loadIncomeSourceStaticDataForNew()
  }

  ownersClicked()
  { 
   /*  if (!this.bISCopyMode)
    this.incomeSourceAccount.Owner=""
 */
  }
    
  sourcesClicked()
  { 
    if(this.source == "Social Security" || this.source == "Employment Income"){
      this.isSurvivorBenefitActive = true
      this.survivorBenefitFlag = 0
      this.survivorBenefitPct = "0.00%"
    }else{
      this.isSurvivorBenefitActive = false
    }
    // if (!this.bISCopyMode)
    //   this.incomeSourceAccount.Source=""
        // this.incomeSourceAccount.Source=""
  }

  colapctsClicked(){
    this.colaPct=""
  }

  survivorbenefitpctsClicked(){
    this.survivorBenefitPct=""
  }

  newBtnClickEvent()
  { 

    this.owner = ""
    this.loadIncomeSourceStaticDataForNew()
    this.loadgrid()

    /*  this.rowData.push({IncomeSourceID: "",Owner: "",
    Source: "", MonthlyAmount: '$' + Number(0).toFixed(2),
    AnnualAmount:'$' + Number(0).toFixed(2)}) */ // remove cents

    this.rowData.push({IncomeSourceID: "",Owner: "",
      Source: "", MonthlyAmount: '$' + Number(0) ,
      AnnualAmount:'$' + Number(0)})

    setTimeout(()=> {
      //this.gridApi.forEachNode(node => node.rowIndex ? (this.gridApi.getDisplayedRowCount()-1) : node.setSelected(true));  
      this.gridApi.forEachNode(node => {
        if ( node.rowIndex  === (this.gridApi.getDisplayedRowCount()-1) ){ 
          node.setSelected(true)
        } 
      }); 
    
    }, 50);    

  
  }

  copyBtnClickEvent()
  {
    if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return

    if (this.gridApi.getSelectedRows()[0]["IncomeSourceID"]==="")
    {
      this.loadIncomeSourceStaticDataForNew()
      return
    } 

    this.bISCopyMode = true
    this.loadIncomeSource(this.gridApi.getSelectedRows()[0]["IncomeSourceID"],true)
    this.currentISID =""
    
  }

  deleteBtnClickEvent()
  { 
      
    if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return

    let selectedID = this.gridApi.getSelectedRows()[0]["IncomeSourceID"]     

    if (confirm("Are you sure you want to delete selected record?")) {   

      if(selectedID == ""){
        this.rowData.splice(this.rowData.findIndex(x=> x.IncomeSourceID  === selectedID), 1)
      }else{
        this.incomeSourceAccountList.splice(this.incomeSourceAccountList.findIndex(x=> x.IncomeSourceID  === selectedID), 1)
      }
    
      setTimeout(()=> {  
        this.client.IncomeSourceAccount = this.incomeSourceAccountList
        
          //Save Client to database
          this.saveClient()  

          localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(this.client));  
          this.owner = ""
          this.loadIncomeSourceStaticDataForNew()
          this.loadgrid()    

          setTimeout(()=> {
            //this.gridApi.forEachNode(node => node.rowIndex ? (this.gridApi.getDisplayedRowCount()-1) : node.setSelected(true));  
            this.gridApi.forEachNode(node => {
              if ( node.rowIndex  === 0 ){ 
                node.setSelected(true)
              } 
            });           
          }, 50);   
      }, 50);   

    
    }
  }

  moveupBtnClickEvent()
  {
    if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return

    let   SelectedISID =this.gridApi.getSelectedRows()[0]["IncomeSourceID"]  

    if (  typeof this.client.IncomeSourceAccount !== 'undefined' && this.client.IncomeSourceAccount.length>0)
    {
        let selectedIncomeSourceAccount:Array<IncomeSourceAccount>=[]
        selectedIncomeSourceAccount = this.client.IncomeSourceAccount.filter(x => x.ChoiceID == this.selectedChoiceID)

        let sortedIncomeSourceAccount:Array<IncomeSourceAccount>=[]
        //sortedIncomeSourceAccount =selectedIncomeSourceAccount.sort(x => Number(x.DisplayOrder)) 
        sortedIncomeSourceAccount =selectedIncomeSourceAccount.sort((a, b) => Number(a.DisplayOrder) - Number(b.DisplayOrder));

        let bGetNextID :boolean = false
        let NextID :string = ""

        for (var i = sortedIncomeSourceAccount.length-1; i >=0  ; --i) {
          if (bGetNextID)
          {
              NextID = sortedIncomeSourceAccount[i].IncomeSourceID;
              break
          }

          if (sortedIncomeSourceAccount[i].IncomeSourceID == SelectedISID) 
          {
              bGetNextID = true
          }
      }  

    let CurerntIncomeSourceAccount :IncomeSourceAccount 
    let NextIncomeSourceAccount: IncomeSourceAccount 
    let CurrentDisplayOrder:Number
    let NextDisplayOrder:Number

    CurerntIncomeSourceAccount = this.client.IncomeSourceAccount.find(x => x.IncomeSourceID === SelectedISID)
    NextIncomeSourceAccount =this.client.IncomeSourceAccount.find(x => x.IncomeSourceID === NextID)

    if(typeof NextIncomeSourceAccount === 'undefined') return // you are in the first row

    CurrentDisplayOrder = CurerntIncomeSourceAccount.DisplayOrder
    NextDisplayOrder = NextIncomeSourceAccount.DisplayOrder

    this.client.IncomeSourceAccount.find(x => x.IncomeSourceID === SelectedISID).DisplayOrder = NextDisplayOrder
    this.client.IncomeSourceAccount.find(x => x.IncomeSourceID === NextID).DisplayOrder = CurrentDisplayOrder

    setTimeout(() => { 
      this.loadgrid()     

      setTimeout(()=> {
        this.gridApi.forEachNode(node => {
          var selectedIncomeSourceID : string = node.data.IncomeSourceID  
          if ( selectedIncomeSourceID === SelectedISID){  
            node.setSelected(true)
            this.gridApi.ensureIndexVisible(Number(node.rowIndex));
          } 
        });        
      }, 50);  

      this.saveClient() //Uncomment Later
      localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(this.client));  
  }, 50);
    

    /* For Each oRow As DataGridViewRow In dgvIncomeSource.Rows
        If oRow.Cells("IncomeSourceID").Value.ToString = SelectedISID Then
            dgvIncomeSource.CurrentCell = dgvIncomeSource.Rows(oRow.Index).Cells("Owner")
            EnableISMoveUpDown()
            Exit For
        End If
    Next */

  }
  }

  movedownBtnClickEvent()
  {
      if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return

      let   SelectedISID =this.gridApi.getSelectedRows()[0]["IncomeSourceID"]  

      if (  typeof this.client.IncomeSourceAccount !== 'undefined' && this.client.IncomeSourceAccount.length>0)
      {
          let selectedIncomeSourceAccount:Array<IncomeSourceAccount>=[]
          selectedIncomeSourceAccount = this.client.IncomeSourceAccount.filter(x => x.ChoiceID == this.selectedChoiceID)

          let sortedIncomeSourceAccount:Array<IncomeSourceAccount>=[]
          // sortedIncomeSourceAccount =selectedIncomeSourceAccount.sort(x => Number(x.DisplayOrder)) 
          sortedIncomeSourceAccount =selectedIncomeSourceAccount.sort((a, b) => Number(a.DisplayOrder) - Number(b.DisplayOrder));

          let bGetNextID :boolean = false
          let NextID :string = ""

          for (var i =0 ; i <=sortedIncomeSourceAccount.length-1 ; ++i) {
            if (bGetNextID)
            {
              NextID = sortedIncomeSourceAccount[i].IncomeSourceID;
              break
            }
  
            if (sortedIncomeSourceAccount[i].IncomeSourceID == SelectedISID) 
            {
                bGetNextID = true
            }
        }  

      let CurerntIncomeSourceAccount :IncomeSourceAccount 
      let NextIncomeSourceAccount: IncomeSourceAccount 
      let CurrentDisplayOrder:Number
      let NextDisplayOrder:Number

      CurerntIncomeSourceAccount = this.client.IncomeSourceAccount.find(x => x.IncomeSourceID === SelectedISID)
      NextIncomeSourceAccount =this.client.IncomeSourceAccount.find(x => x.IncomeSourceID === NextID)

      if(typeof NextIncomeSourceAccount === 'undefined') return // you are in the first row

      CurrentDisplayOrder = CurerntIncomeSourceAccount.DisplayOrder
      NextDisplayOrder = NextIncomeSourceAccount.DisplayOrder 

      this.client.IncomeSourceAccount.find(x => x.IncomeSourceID === SelectedISID).DisplayOrder = NextDisplayOrder
      this.client.IncomeSourceAccount.find(x => x.IncomeSourceID === NextID).DisplayOrder = CurrentDisplayOrder

      setTimeout(() => { 
        this.loadgrid()     

        setTimeout(()=> {
          this.gridApi.forEachNode(node => {
            var selectedIncomeSourceID : string = node.data.IncomeSourceID  
            if ( selectedIncomeSourceID === SelectedISID){  
              node.setSelected(true)
              this.gridApi.ensureIndexVisible(Number(node.rowIndex));
            } 
          });        
        }, 50);  

        this.saveClient() //Uncomment Later
        localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(this.client));  
    }, 50);
    

      /* For Each oRow As DataGridViewRow In dgvIncomeSource.Rows
          If oRow.Cells("IncomeSourceID").Value.ToString = SelectedISID Then
              dgvIncomeSource.CurrentCell = dgvIncomeSource.Rows(oRow.Index).Cells("Owner")
              EnableISMoveUpDown()
              Exit For
          End If
      Next */

    }
  }

  saveClient()
  { 
    this.ldService.saveclient(this.client, this.loggedAgent).subscribe(date =>
      {
        this.alertService.clear()
        this.alertService.success('Successfully saved')
      },
      error => { 
        this.alertService.clear()
        this.alertService.error('Error occured while saving data')
      });
  }

  exitBtnClickEvent()
  {
    localStorage.setItem(LocalStorage.SELECTED_CLIENTID, "");
    //localStorage.setItem(LocalStorage.CURRENT_CLIENT,JSON.stringify(new Client));
    localStorage.removeItem(LocalStorage.CURRENT_CLIENT);
    this.router.navigateByUrl('landing'); 
  }

  //load the superuser dashboard page
	public navigateSuperUserDashboard()
	{
		this.router.navigateByUrl('superuserdashboard'); 
	}
//#endregion

//#region "validation, set data methods"
  validateIncomeSource():boolean
    { 
    
      this.alertService.clear()
      if ((this.iSForm.controls.owner.value == ""  || 
            this.iSForm.controls.owner.value == null ))
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Owner')
        return false
      } 

      if ((this.iSForm.controls.source.value == ""  || 
            this.iSForm.controls.source.value == null ))
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Source')
        return false
      }     

      if ((this.iSForm.controls.startdate.value == ""  || 
      this.iSForm.controls.startdate.value == null ))
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Start Date')
        return false
      } 

      if (this.iSForm.controls.startdate.value != "" && this.iSForm.controls.startdate.value != null
       && this.iSForm.controls.enddate.value != "" && this.iSForm.controls.enddate.value != null)
      { 
        if(this.iSForm.controls.startdate.value == this.iSForm.controls.enddate.value){
          this.alertService.clear()
          this.alertService.error('Start Date and End Date cannot be same')
          return false
        }
        if(this.iSForm.controls.enddate.value != -1 && this.iSForm.controls.enddate.value != "" && this.iSForm.controls.enddate.value != null && this.iSForm.controls.startdate.value > this.iSForm.controls.enddate.value){
          this.alertService.clear()
          this.alertService.error('Invalid Start Date/End Date')
          return false
        }
      } 

      let tempColaPct:any = this.iSForm.controls.colapct.value 
      if(isNaN(tempColaPct.replace('%','')))
      {
        this.alertService.clear()
        this.alertService.error('Invalid COLA %')
        return false
      }

      let tempSuvivorPct:any = this.iSForm.controls.survivorbenefitpct.value 
      if(isNaN(tempSuvivorPct.replace('%','')))
      {
        this.alertService.clear()
        this.alertService.error('Invalid Survivor Benefit %')
        return false
      }


      let tempMonthlyAmount:any =typeof this.iSForm.controls.monthlyamount.value  === 'undefined' ? "0" : this.iSForm.controls.monthlyamount.value  // this.iSForm.controls.monthlyamount.value 
        
      if(isNaN(tempMonthlyAmount.toString().replace('$','').replace(/,/g,'')))
      {
        this.alertService.clear()
        this.alertService.error('Invalid Monthly Amount')
        return false
      } 

      //TODO
      /*

              ' check for duplicate source for new records
              If cbOwner.Tag = "" Then

                  var tempIncomeSource As List(Of Models.IncomeSourceAccount)
                  tempIncomeSource = oClient.IncomeSourceAccount.Where(Function(x) x.ChoiceID.ToString = mSelectedChoiceID.ToString And x.Owner = cbOwner.Text And x.Source = cbSource.Text).ToList
                  If tempIncomeSource.Count > 0 Then
                      ErrorIncomeSources.SetError(cbSource, "Source already exists.")
                      bResult = False
                  End If
              End If
      */
      return true
  }  
  
  setIncomeSource()
  { 
      this.client   = JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) as Client;  

      var oIncomeSourceAccount : IncomeSourceAccount = new  IncomeSourceAccount()
      var IncomeSourceID : string =  this.commonFunctions.getnewguid() 
      var CurrentIncomeSourceID  : string

      var dDOB : Date 

      if (typeof this.client.SpouseFirstName === 'undefined')
      {
        dDOB = new Date(this.client.ClientDOB)
      }
      else
      {
            if (this.iSForm.controls.owner.value.toString().trim().replace(" ","")==this.client.SpouseFirstName.toString().trim() + this.client.SpouseLastName.toString().trim())
            {
              dDOB = new Date(this.client.SpouseDOB)
            }
            else
            {
              dDOB = new Date(this.client.ClientDOB)
            }
      }

      let StartYear:number
      let StartMonth :number
      let EndYear:number
      let EndMonth:number
      let StartDate:Date = new Date(dDOB)
      let EndDate :Date = new Date(dDOB)

      StartDate.setMonth(dDOB.getMonth()+this.iSForm.controls.startdate.value)
    
      StartMonth = StartDate.getMonth()+1 // as month start from 0 for January
      StartYear = StartDate.getFullYear()
      StartDate = new Date(StartYear,StartMonth-1,1) // Make start date to start from 1st of the month
 
      if (typeof this.iSForm.controls.enddate.value === 'undefined' || this.iSForm.controls.enddate.value==-1)
      {
        EndMonth = 11  // December 
        EndYear = 9999
        EndDate = new Date(EndYear,EndMonth ,31)  
      }
      else
      {
        EndDate.setMonth(dDOB.getMonth()+this.iSForm.controls.enddate.value)
        EndMonth = EndDate.getMonth()+1 // as month start from 0 for January
        EndYear = EndDate.getFullYear()

        EndDate = new Date(EndYear,EndMonth-1,1) // Make start date to start from 1st of the month
      }

      
      if ( this.currentISID == "") //New IncomeSource
      {
 
          CurrentIncomeSourceID = IncomeSourceID 

          oIncomeSourceAccount.IncomeSourceID = IncomeSourceID
          oIncomeSourceAccount.ChoiceID = this.selectedChoiceID
          oIncomeSourceAccount.Owner = this.iSForm.controls.owner.value 
          oIncomeSourceAccount.Source = this.iSForm.controls.source.value 
          oIncomeSourceAccount.StartYear = StartYear.toString()
          oIncomeSourceAccount.EndYear = EndYear.toString()
          oIncomeSourceAccount.COLAFlag = this.iSForm.controls.cola.value == 1 ? true : false
          oIncomeSourceAccount.COLAPct = this.iSForm.controls.colapct.value.replace("%","")  
          oIncomeSourceAccount.SurvivorBenefitFlag = this.iSForm.controls.survivorbenefit.value == 1 ? true : false  
          oIncomeSourceAccount.SurvivorBenefitPct = this.iSForm.controls.survivorbenefitpct.value.replace("%","")    
          oIncomeSourceAccount.AnnualAmount =Number(this.iSForm.controls.monthlyamount.value.toString().replace("$","").replace(/,/g,''))*12
          oIncomeSourceAccount.IncomeForLifeComment = ""
          oIncomeSourceAccount.DisplayOrder = this.getNextOrder(Constants.TABLE_NAME.IncomeSources)

          oIncomeSourceAccount.StartDate = formatDate(new Date(StartDate), 'MM/dd/yyyy', 'en')  
          oIncomeSourceAccount.EndDate = formatDate(new Date(EndDate), 'MM/dd/yyyy', 'en')  

         // this.client.IncomeSourceAccount.push(oIncomeSourceAccount)
          this.incomeSourceAccountList.push(oIncomeSourceAccount)
        }
      else{  

         // oIncomeSourceAccount = this.client.IncomeSourceAccount.find( x=> x.IncomeSourceID  === this.currentISID)
          oIncomeSourceAccount = this.incomeSourceAccountList.find( x=> x.IncomeSourceID  === this.currentISID)
         
            
          CurrentIncomeSourceID = oIncomeSourceAccount.IncomeSourceID
 
          oIncomeSourceAccount.ChoiceID = this.selectedChoiceID
          oIncomeSourceAccount.Owner =  this.iSForm.controls.owner.value 
          oIncomeSourceAccount.Source = this.iSForm.controls.source.value 
          oIncomeSourceAccount.StartYear = StartYear.toString()
          oIncomeSourceAccount.EndYear = EndYear.toString()
          oIncomeSourceAccount.COLAFlag = this.iSForm.controls.cola.value == 1 ? true : false
          oIncomeSourceAccount.COLAPct = this.iSForm.controls.colapct.value.replace("%","")  
          oIncomeSourceAccount.SurvivorBenefitFlag =this.iSForm.controls.survivorbenefit.value == 1 ? true : false  
          oIncomeSourceAccount.SurvivorBenefitPct = this.iSForm.controls.survivorbenefitpct.value.replace("%","")    
          oIncomeSourceAccount.AnnualAmount = Number(this.iSForm.controls.monthlyamount.value.toString().replace("$","").replace(/,/g,''))*12
          oIncomeSourceAccount.IncomeForLifeComment = ""
         
          oIncomeSourceAccount.StartDate = formatDate(new Date(StartDate), 'MM/dd/yyyy', 'en')  
          oIncomeSourceAccount.EndDate = formatDate(new Date(EndDate), 'MM/dd/yyyy', 'en') 
      } 

      this.client.IncomeSourceAccount = this.incomeSourceAccountList
      this.currentISID = CurrentIncomeSourceID

    /*  LoadIncomeSourceGrid()

      'select saved row on grid 
      For Each oRow As DataGridViewRow In dgvIncomeSource.Rows
          If oRow.Cells("IncomeSourceID").Value.ToString = CurrentIncomeSourceID.ToString Then
              oRow.Selected = True
              dgvIncomeSource.CurrentCell = dgvIncomeSource.Rows(oRow.Index).Cells("Owner")
              EnableISMoveUpDown()
              Exit For
          End If
      Next  */
  } 
//#endregion

CommaFormatted(event) {
    // skip for arrow keys
    if(event.which >= 37 && event.which <= 40) return;

/*      this.monthlyAmount =  event.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") */ //removed cents

      this.monthlyAmount =  event.target.value
                          .replace(/\D/g, "")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")                      
}
}
