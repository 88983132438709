export class IndexBlend {
    constructor( 
        public IndexBlendID?: string,
        public IndexBlendName?: string,
        public RebalanceFrequency?: string,
        public BeginDate?: string,
        public IsMyIndexBlend?: any,
        public IndexCount?: any,
        public CreatedBy?: string,
        public CreatedOn?: Date,
        public bDeleted?: any,
        public DeletedBy?: string,
        public DeletedOn?: Date, 
        public IndexBlendDetail?: any
    ) {}
}

export class IndexBlendDetail {
    constructor( 
        public IndexBlendDetailID?: string,
        public IndexBlendID?: string,
        public IndexID?: string,
        public IndexName?: string,
        public IndexBeginDate?: string,
        public Allocation?: any
    ) {}
}
