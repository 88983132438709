import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../../util/localstorage.service'; 
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Choice } from '../../models/choice.model';
import { Client } from '../../models/client.model';
import { Agent } from '../../models/agent.model';
import { IncomeFromAsset } from '../../models/income-from-asset.model';
import { ClientInfoDataService } from './../client-info/client-info-data.service';
import { AlertService } from '../../util/alert/alert.service';
import { CommonFunctions } from '../../util/common-functions';
import { Constants } from '../../util/constants' 
import { formatDate } from '@angular/common'; 
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { GridOptions } from 'ag-grid-community'
import { CustomHeader} from '../../util//customHeader'
import { AssetType } from '../../models/asset-type.model';

@Component({
  selector: 'app-income-assets',
  templateUrl: './income-assets.component.html',
  styleUrls: ['./income-assets.component.scss']
})
export class IncomeAssertComponent implements OnInit {

//#region "Variable declaration"
  public isShowDashboard: boolean = false
  public selectedChoiceID: string = "";
  public ownerlist :Array<any> = []
  public startyearlist :Array<any> = []
  public endyearlist :Array<any> = []
  public owner: any
  public source:any
  public startdatemonth:any
  public enddatemonth:any
  public client:Client
  public loggedAgent: Agent 
  public incomeFromAssetsList : Array<IncomeFromAsset> =  []
  public incomeFromAsset  :  IncomeFromAsset = new IncomeFromAsset
  public colaFlag:any
  //public survivorBenefitFlag:any
  public colaPct:any
  public riderCharge:any
 // public survivorBenefitPct:any
  public monthlyAmount : any
  public annualAmount:any
  public activeTabName :any  
  public bISCopyMode :boolean
  public showLTC:boolean 
  public bhasLTC:number  //any 
  public LTCWaitingPeriod:any
  public LTCBegin:any
  public LTCEnd:any
  public LTCMultiplier:any

  public iFAForm: FormGroup;
  public hasSpouse:boolean

  private gridApi: any;
  private gridColumnApi: any;
  public gridOptions : GridOptions 
  public currentSelectedColumn;

	public columnDefs: any;
  public rowData: any;
  
  private mIFAStartDate:Date
 // private mISEndDate:Date
  private currentIFAID:string

  private commonFunctions: CommonFunctions = new CommonFunctions
  orderID :number = 0  

  public title: any
  public assetType: Number
  public assettypeslist :Array<AssetType> = []
  public deposit:any
  public bonusFlag: any
  public bonusPct:any
  public rollupHow:any
  public rollupPct:any

  public accountValue:any
  public payoutPct:any
  public survivorshipHow:any
  public survivorshipPct: any

  public clientInitialDeposit: any
  public clientMonthlyDeposit: any
  public clientMonthlyDepositGrowthRate: any
  public clientMonthlyDepositStartDate : any
  public clientMonthlyDepositEndDate : any
  public spouseInitialDeposit: any
  public spouseMonthlyDeposit: any
  public spouseMonthlyDepositGrowthRate: any
  public spouseMonthlyDepositStartDate :any
  public spouseMonthlyDepositEndDate : any
  public payoutStartsOn : Date
  public isPresentedToClient: boolean = false
  public isDepositValReadOnly: boolean = false
//#endregion

//#region "constructor,  and ngOnInit"
  constructor( private router: Router,  private ldService: ClientInfoDataService,
    private formBuilder: FormBuilder, private alertService: AlertService) {  
      
      this.gridOptions = { 
        components: {
          agColumnHeader: CustomHeader
        },
        defaultColDef: { 
          headerComponentParams: {
            setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
            getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          } 
        } 
      };
      
      this.columnDefs = [
      /* { 
         headerName: 'IncomeSourceID', 
         field: 'IncomeSourceID'//, 
        // sortable: true, 
         //width: 125,
        // filter: true //,
        // cellClass: "rag-gray"
       },*/
       { 
         headerName: 'Owner', 
         field: 'Owner'//, 
        // sortable: true, 
        // width: 125,
        // filter: true//,
         //cellClass: "rag-blue"
       },
       { 
         headerName: 'Source', 
         field: 'Source'//, 
        // sortable: true, 
        // width: 125,
        // filter: true,
        // cellClass: "rag-blue"
       },
       { 
         headerName: 'Account Value', 
         field: 'AccountValue',
         cellStyle: {textAlign: "right"}//,  
        // sortable: true, 
        // filter: true,
        // cellClass: "rag-yellow"
       }//,
       /* { 
         headerName: 'Annual Amount', 
         field: 'AnnualAmount',
         cellStyle: {textAlign: "right"}
         //, 
        // sortable: true, 
        // filter: true,
         //cellClass: "rag-yellow"
       }   */
     ];
   }

 ngOnInit() {
  let agent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent;
  if(agent.UserRoleID== Constants.UserRoleID.Superuser)
    this.isShowDashboard = true
   let clientFromStorage: Client = new Client
   this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
   this.selectedChoiceID = localStorage.getItem(LocalStorage.SELECTED_CHOICEID)  
    
   this.client   = JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) as Client;  

 
   if (typeof this.client.IncomeFromAsset === 'undefined') 
       this.incomeFromAssetsList = [] 
   else
       this.incomeFromAssetsList = JSON.parse(JSON.stringify(this.client.IncomeFromAsset ));


 // this.client.IncomeSourceAccount.filter(x => x.ChoiceID == this.selectedChoiceID).forEach((element:IncomeSourceAccount) => { 
    // console.log(element.IncomeSourceID) 
  // });

  this.activeTabName = this.client.Choice.find(x => x.ChoiceID == this.selectedChoiceID).ChoiceName
   
  this.alertService.clear()
  if (this.activeTabName !== Constants.CURRENT_CHOICE_NAME)
  {      
     this.alertService.warn('Warning! You are editing the '+ this.activeTabName +' Tab, new tabs should be created from the '+ Constants.CURRENT_CHOICE_NAME +' tab.')      
  } 
    
   this.loadIFAStaticDataForNew()
   this.loadIFAOfDefferalList(this.client.ClientDOB,null)
   this.getAssetType()

  this.iFAForm = this.formBuilder.group({
     owner: new FormControl(null) ,
     title: new FormControl(null),
     assettype: new FormControl(null),
     deposit: new FormControl(null),
     bonusflag: new FormControl(null),
     bonuspct:new FormControl(null),
     startdate: new FormControl(null), // months of deferal
     rolluphow: new FormControl(null),
     rolluppct: new FormControl(null), 
     accountvalue: new FormControl(null),
     payoutpct : new FormControl(null),
     survivorshiphow : new  FormControl(null),
     survivorshippct : new FormControl(null),   
     cola: new FormControl(null), 
     colapct: new FormControl(null), 
     ridercharge: new FormControl(null),
     HasLTC: new FormControl(null), 
     LTCWaitingPeriod: new FormControl(null), 
     LTCBegin: new FormControl(null), 
     LTCEnd: new FormControl(null), 
     LTCMultiplier: new FormControl(null),  
     clientInitialDeposit: new FormControl(null), 
     clientMonthlyDeposit: new FormControl(null),
     spouseInitialDeposit: new FormControl(null), 
     spouseMonthlyDeposit: new FormControl(null), 
     clientMonthlyDepositGrowthRate: new FormControl(null), 
     spouseMonthlyDepositGrowthRate: new FormControl(null), 
     clientMonthlyDepositStartDate: new FormControl(null),  
     clientMonthlyDepositEndDate: new FormControl(null),  
     spouseMonthlyDepositStartDate: new FormControl(null), 
     spouseMonthlyDepositEndDate: new FormControl(null), 
   } );   

   let currentChoice = this.client.Choice.find(x => x.ChoiceID == this.selectedChoiceID) 
   if(currentChoice.PresentedToClient){
     this.isPresentedToClient = true
     this.iFAForm.disable()
   }
 }
//#endregion

//#region Ag grid
  onGridReady(params: any) { 
   this.gridApi  = params.api;
   this.gridColumnApi = params.columnApi; 
   setTimeout(() => { 
       this.loadgrid()     
       //params.api.sizeColumnsToFit();
   }, 50);

   setTimeout(()=> {
     params.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));  
     if (params.api.getDisplayedRowCount()>0)
     {
       var selectedIFAID : string = params.api.getSelectedRows()[0]["IFAID"];
       this.loadIFA(selectedIFAID,false);   
     } 
  }, 500);  
   //params.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true)); 

  /*  params.api.forEachNode( function(node) {
     // select the node
     node.setSelected(true);
   }); */ 
 }
 
 loadgrid()
 {  
    let tempString: Array<any>=[] 
    if (  typeof this.client.IncomeFromAsset !== 'undefined' && this.client.IncomeFromAsset.length>0)
    {
      
        let selectedIncomeFromAssets:Array<IncomeFromAsset>=[]
        selectedIncomeFromAssets = this.client.IncomeFromAsset.filter(x => x.ChoiceID == this.selectedChoiceID) as Array<IncomeFromAsset>
        let sortedIncomeFromAssets:Array<IncomeFromAsset>=[]
        sortedIncomeFromAssets =selectedIncomeFromAssets.sort((a, b) => Number(a.DisplayOrder) - Number(b.DisplayOrder));
        sortedIncomeFromAssets.forEach((element:IncomeFromAsset) => { 
        let accountValue = Number(element.AccountValue) 
        
         /*  tempString.push({IFAID: element.IFAID, Owner:element.Owner,
          Source: element.Source, AccountValue: '$' + accountValue.toFixed(2)})
          });  */ 
        tempString.push({IFAID: element.IFAID, Owner:element.Owner,
          Source: element.Source, AccountValue: this.commonFunctions.currencyFormat(accountValue)})
        });         
    }
      this.rowData=tempString 
 }

 gridSizeChanged(params: any) { 
   params.api.sizeColumnsToFit();
 }


 public onCellClicked(event: any) {
  var index = event.rowIndex
  // console.log('[onCellClicked] (event.data) : ' + JSON.stringify(event.data)); 
  if(this.rowData.findIndex(x=> x.IFAID  === "") != -1 && this.rowData.findIndex(x=> x.IFAID === "") != index){
    if (confirm("You have unsaved data. Are you sure you want to select this record?")) {  
      this.rowData.splice(this.rowData.findIndex(x=> x.IFAID == ""), 1) 
      this.gridOptions.api.setRowData(this.rowData); 
      var selectedIncomeSourceID : string = event.data.IFAID
      this.loadIFA(selectedIncomeSourceID,false) 
      this.gridOptions.onRowDataChanged = params => {
        this.gridApi.forEachNode(node => {
          if ( node.rowIndex === index ){ 
            node.setSelected(true)
          } 
        }); 
      }  
           
    }
  } else{
    event.node.setSelected(true);
    var selectedIncomeSourceID : string = event.data.IFAID
    this.loadIFA(selectedIncomeSourceID,false) 
  }
    
}

getCurrentSelectedColumn() {
  return this.currentSelectedColumn;
}
  
setCurrentSelectedColumn(colId) {
  this.currentSelectedColumn = colId;
}
//#endregion

//#region "on input field changes"
onSelectionChanged(event: any){  

    if (this.bISCopyMode) return
    // var selectedIncomeSourceID : string = event.data.IncomeSourceID
    if (typeof this.gridApi.getSelectedRows()[0] === 'undefined') return
  
    if (this.gridApi.getSelectedRows()[0]["IFAID"]==="")
    {
      this.loadIFAStaticDataForNew()
    }
    else
    {
      this.loadIFA(this.gridApi.getSelectedRows()[0]["IFAID"],false)
    }
} 

changeCOLAFlag(colaFlag: any){
  if (colaFlag == '1') { 
    this.incomeFromAsset.COLAFlag =true
  }
  else
  {
    this.incomeFromAsset.COLAFlag =false
    this.colaPct = "0.00%"
  }
}

changeBonusFlag(bonusFlag: any){
    if (bonusFlag == '1') { 
      this.incomeFromAsset.BonusFlag =true
    }
    else
    {
      this.incomeFromAsset.BonusFlag =false
      this.bonusPct = "0.00%"
    }
    this.calculatePayoutValue()
} 

changeSurvivorshipHow(survivorshipHow: any){
    this.incomeFromAsset.SurvivorshipHow =survivorshipHow
    if (survivorshipHow == 'Individual') { 
      this.survivorshipPct = "0.00%" 
    } 
} 

riderChargeClicked(){
  this.riderCharge = ""
}

onRiderChargeTextChange(){
  this.riderCharge = this.iFAForm.controls.ridercharge.value.toString().replace("%", '') 
}

changeDeposit(value: string){
  this.deposit = this.commonFunctions.currencyFormat(Number(value.replace('$','').replace(/,/g,'')))
  this.calculatePayoutValue()
}

onDepositTextChange(): void {   
    this.calculatePayoutValue()
}

changeClientInitialDeposit(value: string){
  this.clientInitialDeposit = this.commonFunctions.currencyFormat(Number(value.replace('$','').replace(/,/g,'')))
  this.CalculateDepositFromMonthlyDeposits() 
}

onClientInitialDepositTextChange(): void {   
  this.CalculateDepositFromMonthlyDeposits() 
}

changeClientMonthlyDeposit(value: string){
    this.clientMonthlyDeposit = this.commonFunctions.currencyFormat(Number(value.replace('$','').replace(/,/g,'')))
    this.CalculateDepositFromMonthlyDeposits() 
}

onClientMonthlyDepositTextChange(): void {   
    this.CalculateDepositFromMonthlyDeposits() 
}

changeSpouseInitialDeposit(value: string){
  this.spouseInitialDeposit = this.commonFunctions.currencyFormat(Number(value.replace('$','').replace(/,/g,'')))
  this.CalculateDepositFromMonthlyDeposits()
}

onSpouseInitialDepositTextChange(): void {   
  this.CalculateDepositFromMonthlyDeposits()
}

changeSpouseMonthlyDeposit(value: string){
    this.spouseMonthlyDeposit = this.commonFunctions.currencyFormat(Number(value.replace('$','').replace(/,/g,'')))
    this.CalculateDepositFromMonthlyDeposits()
}

onSpouseMonthlyDepositTextChange(): void {   
    this.CalculateDepositFromMonthlyDeposits()
}

onPayoutpctTextChange(){
    this.calculatePayoutValue()
}

onClientMonthlyDepositGrowthRateTextChange(){
  if(this.hasSpouse){
    this.spouseMonthlyDepositGrowthRate = this.iFAForm.controls.clientMonthlyDepositGrowthRate.value
  }
  this.CalculateDepositFromMonthlyDeposits()
}

onSpouseMonthlyDepositGrowthRateTextChange(){
  this.clientMonthlyDepositGrowthRate = this.iFAForm.controls.spouseMonthlyDepositGrowthRate.value
  this.CalculateDepositFromMonthlyDeposits()
}

changeBonusPct(){
    this.calculatePayoutValue()
}

changeMonthsIfDeferral(){
    this.calculatePayoutValue()
}

changeRollupHow(){
    this.calculatePayoutValue()
}

changeRollupPct(){
    this.calculatePayoutValue()
}

OnClientMonthlyDepositStartDateChange(event: any) {
  this.clientMonthlyDepositStartDate = event;  
  this.CalculateDepositFromMonthlyDeposits()
}

OnClientMonthlyDepositEndDateChange(event: any) {
  this.clientMonthlyDepositEndDate = event; 
  this.CalculateDepositFromMonthlyDeposits()
}

OnSpouseMonthlyDepositStartDateChange(event: any) {
  this.spouseMonthlyDepositStartDate = event; 
  this.CalculateDepositFromMonthlyDeposits()
}

OnSpouseMonthlyDepositEndDateChange(event: any) {
  this.spouseMonthlyDepositEndDate = event; 
  this.CalculateDepositFromMonthlyDeposits()
}

ownersClicked(){ 
  /*  if (!this.bISCopyMode)
   this.incomeSourceAccount.Owner=""
*/
}
 
sourcesClicked(){ 
 // if (!this.bISCopyMode)
 //   this.incomeSourceAccount.Source=""
     // this.incomeSourceAccount.Source=""
}

colapctsClicked(){
  this.colaPct=""
}

bonuspctsClicked(){
  this.bonusPct=""
}

rolluppctsClicked(){
  this.rollupPct=""
}

payoutpctsClicked(){
  this.payoutPct=""
}

clientMonthlyDepositGrowthRateClicked(){
    this.clientMonthlyDepositGrowthRate =""
}

spouseMonthlyDepositGrowthRateClicked(){
    this.spouseMonthlyDepositGrowthRate=""
}

survivorshippctsClicked(){
  this.survivorshipPct=""
}

ownerSelectedValueChanged(ownerVal: any){  
 if (ownerVal=="") return 
   this.intializeIFADetails()
   if (typeof this.client.SpouseFirstName === 'undefined')
   {
     this.loadIFAOfDefferalList(this.client.ClientDOB,null) 
   }
 else
 {
     if (ownerVal.toString().trim().replace(" ","")==this.client.SpouseFirstName.toString().trim() + this.client.SpouseLastName.toString().trim())
       this.loadIFAOfDefferalList(this.client.SpouseDOB,null) 
     else  
     this.loadIFAOfDefferalList(this.client.ClientDOB,null) 
 }
       
}
/* monthlyAmountChanged(monthlyamt:any)
 { 

   if(!isNaN(monthlyamt.toString().replace('$','').replace(/,/g,'')))
   {
     this.annualAmount = this.commonFunctions.currencyFormat(Number(this.iFAForm.controls.monthlyamount.value.toString().replace("$","").replace(/,/g,''))*12) + " yearly"  
   }  
   else
   {
     this.annualAmount = Number(0).toFixed(2)+ " yearly"  
   }
   
 } */
//#endregion

//#region "load data and initializing data"
loadIFAStaticDataForNew(){   
  this.ownerlist =[]
  this.ownerlist.push({text: this.client.ClientFirstName.trim() + " " + this.client.ClientLastName.trim() })
   
  if (this.client.SpouseFirstName != "" && this.client.SpouseLastName != "" && 
        typeof this.client.SpouseFirstName !== 'undefined'  && 
        typeof this.client.SpouseLastName !== 'undefined' )  
 
  { 
    this.hasSpouse=true
    this.ownerlist.push({text: this.client.SpouseFirstName.trim() + " " + this.client.SpouseLastName.trim() })
    this.ownerlist.push({text: this.client.ClientFirstName.trim().substring(0,1) + "&" + this.client.SpouseFirstName.trim().substring(0,1) + " " + this.client.SpouseLastName.trim() })
  }
  else
  {
    this.hasSpouse=false
  } 
  this.owner = ""
  this. intializeIFADetails()
  
}

intializeIFADetails(){ 
   this.mIFAStartDate = new Date()
   //this.mISEndDate = new Date(8640000000000000) //max date 

   this.colaFlag = 0
   this.colaPct = "0.00%"
   this.survivorshipHow = "Individual"
   this.survivorshipPct = "0.00%"
   this.bonusFlag = 0
   this.bonusPct= "0.00%"
   this.rollupHow ="Compound"
   this.rollupPct= "0.00%"
   this.payoutPct = "0.00%"
   this.deposit ="$" + "0" // "0.00" remove cents
   this.clientInitialDeposit ="$" + "0" 
   this.clientMonthlyDeposit ="$" + "0" 
   this.clientMonthlyDepositGrowthRate = "0.00%"
   this.clientMonthlyDepositStartDate =null
   var currentyear = new Date().getFullYear()
   let clientage = Number(currentyear - new Date(this.client.ClientDOB).getFullYear())
   this.clientMonthlyDepositEndDate = new Date(new Date().setFullYear(currentyear + Number(Constants.LAST_YEAR) - clientage))
   this.spouseInitialDeposit ="$" + "0" 
   this.spouseMonthlyDeposit ="$" + "0" 
   this.spouseMonthlyDepositGrowthRate = "0.00%"
   this.spouseMonthlyDepositStartDate =null
   this.spouseMonthlyDepositEndDate =null
   let spouseage = Number(currentyear - new Date(this.client.SpouseDOB).getFullYear())
   this.spouseMonthlyDepositEndDate = new Date(new Date().setFullYear(currentyear + Number(Constants.LAST_YEAR) - spouseage))
 
   this.accountValue = "$" + "0" // "0.00" remove cents
   this.riderCharge = "0.00%"
   this.currentIFAID =""

   this.startdatemonth = ""
   this.enddatemonth = -1

   this.monthlyAmount = "$" + "0" + " monthly"
   this.annualAmount = "$" + "0" + " yearly"

   //LTC 
   this.showLTC  = false
   this.bhasLTC  = 0
   this.LTCWaitingPeriod = "" 
   this.LTCBegin = "" 
   this.LTCEnd  = ""
   this.LTCMultiplier = "" 

  // this.owner = ""
   this.title = ""  
   this.assetType = null
   if (typeof this.client.IncomeFromAsset === 'undefined') 
       this.incomeFromAssetsList  = [] 

 
 // cbSource.Text = ""  

  this.bISCopyMode = false 
}

loadIFA(selectedIFAID:string,bCopy:boolean){

    this.incomeFromAsset = new IncomeFromAsset()
    this.incomeFromAsset= this.client.IncomeFromAsset.find(x => x.IFAID === selectedIFAID)
      
    this.owner = this.incomeFromAsset.Owner
    this.title = this.incomeFromAsset.Source
    this.assetType = this.incomeFromAsset.AssetType
    this.mIFAStartDate =new Date(this.incomeFromAsset.StartDate)
     var currentyear = new Date().getFullYear()
    //this.mISEndDate = new Date(this.incomeFromAsset.EndDate) 

    if (bCopy)
    {
        this.currentIFAID =""
    }
    else
    {
        this.currentIFAID = this.incomeFromAsset.IFAID
    }



    if (typeof this.client.SpouseLastName === 'undefined' )
    { 
      this.loadIFAOfDefferalList(this.client.ClientDOB, this.incomeFromAsset)
      this.startdatemonth = this.getAgeInMonths(this.client.ClientDOB,new Date(this.incomeFromAsset.StartDate))+ Number(this.incomeFromAsset.YearsOfDeferral)
      // this.enddatemonth = this.getAgeInMonths(this.client.SpouseDOB,new Date(this.incomeFromAsset.EndDate))
    }
    else
    {
        if (this.incomeFromAsset.Owner.trim().replace(" ","")==this.client.SpouseFirstName.trim() + this.client.SpouseLastName.trim())
        {      
          this.loadIFAOfDefferalList(this.client.SpouseDOB, this.incomeFromAsset)      
          this.startdatemonth = this.getAgeInMonths(this.client.SpouseDOB,new Date(this.incomeFromAsset.StartDate))+ Number(this.incomeFromAsset.YearsOfDeferral)
          // this.enddatemonth = this.getAgeInMonths(this.client.SpouseDOB,new Date(this.incomeFromAsset.EndDate))
      
        }
        else
        { 
          this.loadIFAOfDefferalList(this.client.ClientDOB, this.incomeFromAsset)
          this.startdatemonth = this.getAgeInMonths(this.client.ClientDOB,new Date(this.incomeFromAsset.StartDate))  + Number(this.incomeFromAsset.YearsOfDeferral)
          //this.enddatemonth = this.getAgeInMonths(this.client.SpouseDOB,new Date(this.incomeFromAsset.EndDate))
        }  
    } 
    this.startdatemonth=this.startyearlist.find(x=>x.value===this.startdatemonth).value 

    /* if (new Date(this.incomeFromAsset.EndDate).getFullYear()!=9999)
    { 
        this.enddatemonth=this.endyearlist.find(x=>x.value===this.enddatemonth).value
    }
    else
    {
      this.enddatemonth = -1
    }  */

  
    //Inital Deposit
    if(this.incomeFromAsset.ClientMonthlyDeposit == null || this.incomeFromAsset.ClientInitialDeposit == undefined){
      this.clientInitialDeposit = this.commonFunctions.currencyFormat(0)
    } else{
      this.clientInitialDeposit = this.commonFunctions.currencyFormat(Number(this.incomeFromAsset.ClientInitialDeposit)) 
    }

    if(this.incomeFromAsset.SpouseMonthlyDeposit == null || this.incomeFromAsset.SpouseInitialDeposit == undefined){
      this.spouseInitialDeposit = this.commonFunctions.currencyFormat(0)
    } else{
      this.spouseInitialDeposit = this.commonFunctions.currencyFormat(Number(this.incomeFromAsset.SpouseInitialDeposit)) 
    }

    //Monthly Deposit 
    if(this.incomeFromAsset.ClientMonthlyDeposit == null || this.incomeFromAsset.ClientMonthlyDeposit == undefined){
      this.clientMonthlyDeposit = this.commonFunctions.currencyFormat(0)
    } else{
      this.clientMonthlyDeposit = this.commonFunctions.currencyFormat(Number(this.incomeFromAsset.ClientMonthlyDeposit)) 
    }
    
    if(this.incomeFromAsset.SpouseMonthlyDeposit == null || this.incomeFromAsset.SpouseMonthlyDeposit == undefined){
      this.spouseMonthlyDeposit = this.commonFunctions.currencyFormat(0)
    } else{
      this.spouseMonthlyDeposit = this.commonFunctions.currencyFormat(Number(this.incomeFromAsset.SpouseMonthlyDeposit)) 
    }

    if(this.incomeFromAsset.ClientMonthlyDepositGrowthRate == null || this.incomeFromAsset.ClientMonthlyDepositGrowthRate == undefined){
      this.clientMonthlyDepositGrowthRate = Number(0).toFixed(2) + "%"
    } else{
      this.clientMonthlyDepositGrowthRate = Number(this.incomeFromAsset.ClientMonthlyDepositGrowthRate).toFixed(2) + "%" 
    }

    if(this.incomeFromAsset.SpouseMonthlyDepositGrowthRate == null || this.incomeFromAsset.SpouseMonthlyDepositGrowthRate == undefined){
      this.spouseMonthlyDepositGrowthRate = Number(0).toFixed(2) + "%"
    } else{
      this.spouseMonthlyDepositGrowthRate = Number(this.incomeFromAsset.SpouseMonthlyDepositGrowthRate).toFixed(2) + "%" 
    }
    
    
    if (this.incomeFromAsset.ClientMonthlyDepositStartDate == null   ){
      this.clientMonthlyDepositStartDate = null
    }
    else{
      this.clientMonthlyDepositStartDate=new Date(this.incomeFromAsset.ClientMonthlyDepositStartDate)
    }
  
    if (this.incomeFromAsset.ClientMonthlyDepositEndDate == null   ){
      let clientage = Number(currentyear - new Date(this.client.ClientDOB).getFullYear())
      this.clientMonthlyDepositEndDate = new Date(new Date().setFullYear(currentyear + Number(Constants.LAST_YEAR) - clientage))
    }
    else{
      this.clientMonthlyDepositEndDate = new Date(this.incomeFromAsset.ClientMonthlyDepositEndDate)
    }

    if (this.incomeFromAsset.SpouseMonthlyDepositStartDate == null   ){
      this.spouseMonthlyDepositStartDate = null
    }
    else{
      this.spouseMonthlyDepositStartDate = new Date(this.incomeFromAsset.SpouseMonthlyDepositStartDate)
    }

    if (this.incomeFromAsset.SpouseMonthlyDepositEndDate == null   ){
      let spouseage = Number(currentyear - new Date(this.client.ClientDOB).getFullYear())
      this.spouseMonthlyDepositEndDate = new Date(new Date().setFullYear(currentyear + Number(Constants.LAST_YEAR) - spouseage))
    }
    else{
      this.spouseMonthlyDepositEndDate = new Date(this.incomeFromAsset.SpouseMonthlyDepositEndDate)
    }

  //Setting Deposit after setting monthly deposits to prevent override from calculated values
    this.deposit = this.commonFunctions.currencyFormat(Number(this.incomeFromAsset.Deposit)) //"$" +  Number(this.incomeFromAsset.Deposit).toFixed(2)
    this.iFAForm.controls.deposit.setValue(this.deposit)
    
    if (this.incomeFromAsset.BonusFlag == true) { 
      this.bonusFlag = 1
      this.bonusPct = Number(this.incomeFromAsset.BonusPct).toFixed(2) + "%" 
    }
    else{
        this.bonusFlag = 0
        this.bonusPct = "0.00%"
    }    

    this.rollupHow = this.incomeFromAsset.RollUpHow 
    this.rollupPct = Number(this.incomeFromAsset.RollUpPct).toFixed(2) + "%"  

    this.accountValue = this.commonFunctions.currencyFormat(Number(this.incomeFromAsset.AccountValue)) //"$"+ Number(this.incomeFromAsset.AccountValue).toFixed(2)
    this.payoutPct =   Number(this.incomeFromAsset.PayoutPct).toFixed(2) + "%"  

    // calculate annual and monthly ammount
    let dpayoutpct :number
    dpayoutpct = Number(this.incomeFromAsset.PayoutPct)
  
    let tempAnnualAmount : number = (Number(this.incomeFromAsset.AccountValue) * dpayoutpct)/100
    let tempMonthlyAmount  : number =tempAnnualAmount/12

    this.annualAmount = this.commonFunctions.currencyFormat(Number(tempAnnualAmount ))  + " yearly" 
    this.monthlyAmount = this.commonFunctions.currencyFormat(Number(tempMonthlyAmount))  + " monthly" 

  
    if (this.incomeFromAsset.COLAFlag == true) { 
        this.colaFlag = 1
        this.colaPct = Number(this.incomeFromAsset.COLAPct).toFixed(2) + "%" 
    }
    else{
        this.colaFlag = 0 
        this.colaPct = "0.00%"
    }    

    this.riderCharge = Number(this.incomeFromAsset.RiderCharge).toFixed(2) + "%"

    if (this.incomeFromAsset.SurvivorshipHow == "Joint") { 
      this.survivorshipHow = this.incomeFromAsset.SurvivorshipHow
      this.survivorshipPct = Number(this.incomeFromAsset.SurvivorshipPct).toFixed(2) + "%" 
    }
    else{ 
      this.survivorshipHow = this.incomeFromAsset.SurvivorshipHow
      this.survivorshipPct = "0.00%"
    }      

    //LTC function  
    this.bhasLTC  =  this.incomeFromAsset.HasLTC== true ? 1: 0 
    if ( this.incomeFromAsset.HasLTC  ==  true)
    { 
        this.showLTC = true 
        this.LTCWaitingPeriod = this.startyearlist[1].value + this.incomeFromAsset.LTCNotAvailableTime
        this.LTCBegin = this.startyearlist[1].value + this.incomeFromAsset.LTCNotAvailableFor
        this.LTCEnd = this.startyearlist[1].value + this.incomeFromAsset.LTCPeriod
        this.LTCMultiplier = this.incomeFromAsset.LTCMultiplier
    }
    else
    {  
        this.showLTC = false
        this.LTCWaitingPeriod = ""
        this.LTCBegin  = ""
        this.LTCEnd = ""
        this.LTCMultiplier = ""
    }

    //load copied data row to the grid 
    if (bCopy)
    {

      this.loadgrid()
  
      let accountvalue = Number(this.incomeFromAsset.AccountValue) 

      this.rowData.push({IFAID: "",Owner: this.incomeFromAsset.Owner,
      Source: this.incomeFromAsset.Source, AccountValue: this.commonFunctions.currencyFormat(Number(accountvalue))})

      setTimeout(()=> {
      //this.gridApi.forEachNode(node => node.rowIndex ? (this.gridApi.getDisplayedRowCount()-1) : node.setSelected(true));  
      this.gridApi.forEachNode(node => {
        if ( node.rowIndex  === (this.gridApi.getDisplayedRowCount()-1) ){ 
          node.setSelected(true)
          this.gridApi.ensureIndexVisible(Number(node.rowIndex));
        } 
      }); 
    
    }, 50);   
        
    }    
  
      
}

searchinarray(nameKey, myArray){
   for (var i=0; i < myArray.length; i++) {
    
       if (myArray[i].value === nameKey) {
         //console.log(myArray[i].value)
           return myArray[i].value;
       }
   }
}

loadIFAOfDefferalList(DOB: Date, oIncomeFromAssets : IncomeFromAsset)
  {
  
      var CurrentAge : number
      var CurrentYear : number
      var CurrentMonth : number
      var CurrentAgeInMonths : number

      var StartYear : number = -99
      var StartMonth : number = -99

      this.startyearlist = []
      this.endyearlist = [] 

      if (oIncomeFromAssets!=null) 
      {
          if( this.mIFAStartDate.getFullYear() <= new Date().getFullYear() )
          {
              StartYear = this.mIFAStartDate.getFullYear()
              StartMonth =  this.mIFAStartDate.getMonth() +1
          }
          else
          {
              StartYear = new Date().getFullYear()
              StartMonth = new Date().getMonth() +1
          } 


          CurrentAge = StartYear - new Date(DOB).getFullYear()
          CurrentYear = StartYear  
          // StartMonth = 1   

          //CurrentAgeInMonths =this.getAgeInMonths(DOB, StartYear +"/" + StartMonth + "/"+ 1) // DateDiff(DateInterval.Month, DOB, New Date(StartYear, StartMonth, 1))
          CurrentAgeInMonths =this.getAgeInMonths(DOB, new Date(StartYear, StartMonth-1, 1)) //NOTE have to pass Startdate-1 as javascript start month from 0
            
        }
      else
      {    

        // alert(new Date().getMonth())
        CurrentAge =  new Date().getFullYear() - new Date(DOB).getFullYear()
        CurrentYear =  new Date().getFullYear() 
        StartYear = CurrentYear
        StartMonth =  new Date().getMonth()+1 // add one as javascript start month from 0 

        CurrentAgeInMonths = this.getAgeInMonths(DOB, new Date()) // DateDiff(DateInterval.Month, DOB, Date.Today)
      } 
      

      this.startyearlist.push({value:"", text:""})
      this.endyearlist.push({value:-1, text:""})

      var count : number = 0
      // For i As Integer = CurrentAge To 100
      for (let i = CurrentAge; i <= 100; i++)
      { 
          if (CurrentYear == StartYear)  
          { 
            CurrentMonth = StartMonth
          }
          else
          {
            CurrentMonth = 1
          } 

          //For iMonth As Integer = CurrentMonth To 12
          for (let iMonth = CurrentMonth; iMonth <= 12; iMonth++)
          {
              var MonthString : String = "" 
              switch (iMonth)
              {
                  case 1:
                      MonthString = "January"
                      break;
                  case 2:
                      MonthString = "February"
                      break;
                  case 3:
                      MonthString = "March"
                      break;
                  case 4:
                      MonthString = "April"
                      break;
                  case 5:
                      MonthString = "May"
                      break;
                  case 6:
                      MonthString = "June"
                      break;
                  case 7:
                      MonthString = "July"
                      break;
                  case 8:
                      MonthString = "August"
                      break;
                  case 9:
                      MonthString = "September"
                      break;
                  case 10:
                      MonthString = "October"
                      break;
                  case 11:
                      MonthString = "November"
                      break;
                  case 12:
                      MonthString = "December"
                      break;
              } 
            
              var displayAge : number
              displayAge = Math.floor( CurrentAgeInMonths / 12 )
              
              // 26, (September 2022) AGE xx.

              this.startyearlist.push({value:CurrentAgeInMonths, text: count + ", ("+ MonthString + " " + CurrentYear + ")"  + " AGE " + displayAge})
              //this.startyearlist.push({value:CurrentAgeInMonths, text: count + " ("+ MonthString + "," + CurrentYear + ")"  })
              this.endyearlist.push({value:CurrentAgeInMonths, text:  MonthString + "," + CurrentYear + " " + "(Age=" + displayAge + ")"})

              //comboSourceStartYear.Add(CurrentAgeInMonths, MonthString & "," & CurrentYear & " " & "(Age=" & displayAge & ")")
              //comboSourceEndYear.Add(CurrentAgeInMonths, MonthString & "," & CurrentYear & " " & "(Age=" & displayAge & ")")
              CurrentAgeInMonths += 1 
              count += 1 
          } 
          CurrentAge += 1
          CurrentYear += 1 

      }   
}

getAgeInMonths(dob:Date,toThisDate: any) {
   var toDate = new Date(toThisDate);
   var birthDate= new Date(dob);
   var years = toDate.getFullYear() - birthDate.getFullYear(); 
   
   var m = toDate.getMonth() - birthDate.getMonth(); 
   var age = years * 12 + m; 
   return age;
}

getNextOrder(incometype :string) : any
{ 
     var currentOrderID :number = 0
     if (incometype == Constants.TABLE_NAME.IncomeSources)  
     { 
       /* if (typeof this.client.SpouseFirstName === 'undefined')
           currentOrderID = 0 
       else
       {
         var tempIncomeSourceAccount :Array<IncomeSourceAccount> =[]
         tempIncomeSourceAccount = this.client.IncomeSourceAccount.filter(x=> x.ChoiceID= this.selectedChoiceID) 

         if (tempIncomeSourceAccount.length > 0)  
           currentOrderID = Math.max.apply(Math, tempIncomeSourceAccount.map(function(o) { return o.DisplayOrder; }))
             //currentOrderID = tempIncomeSourceAccount.Max(Function(g) g.DisplayOrder)
         else
             currentOrderID = 0 
       } */
     
     }
     else  if (incometype == Constants.TABLE_NAME.IncomeFromAssets)  
     {
        //####################################### 
        if (typeof this.client.IncomeFromAsset === 'undefined')
                  currentOrderID = 0  + 20 //Adding 20 to make sure new IncomeFromAssest goes to last
        else
        {
          var tempIncomeFromAssets :Array<IncomeFromAsset> =[]
          tempIncomeFromAssets = this.client.IncomeFromAsset.filter(x=> x.ChoiceID= this.selectedChoiceID) 

          if (tempIncomeFromAssets.length > 0)  
              currentOrderID = Math.max.apply(Math, tempIncomeFromAssets.map(function(o) { return o.DisplayOrder; })) + 20 //Adding 20 to make sure new IncomeFromAssest goes to last
          else
              currentOrderID = 0  + 20 //Adding 20 to make sure new IncomeFromAssest goes to last
        }
        //#######################################  


        /* var tempIncomeFromAsset As New List(Of Models.IncomeFromAsset)
         tempIncomeFromAsset = oClient.IncomeFromAsset.Where(Function(x) x.ChoiceID.ToString = mSelectedChoiceID.ToString()).ToList
         If tempIncomeFromAsset.Count > 0 Then
             currentOrderID = tempIncomeFromAsset.Max(Function(g) g.DisplayOrder) + 20 ' Adding 20 to make sure new IncomeFromAssest goes to last
         Else
             currentOrderID = 0 + 20 ' Adding 20 to make sure new IncomeFromAssest goes to last
         End If*/
     }
     else if (incometype == Constants.TABLE_NAME.Choice)     
     {      
       /*  if (this.client.Choice.length > 0 )
             currentOrderID = Math.max.apply(Math, this.client.Choice.map(function(o) { return o.DisplayOrder; }))
         else
             currentOrderID = 0  */        
     }


     this.orderID = currentOrderID + 1 //iOrder + 1
     return this.orderID

}

getAssetType(){
  this.alertService.clear()
  this.ldService.getassettypes(this.loggedAgent).subscribe(data => {
    this.assettypeslist = data
   },
   error => { 
     this.alertService.error('An error occurred')
   });
}
//#endregion

//#region "button click events"
saveBtnClickEvent()
  {  
    // Validation
    if (this.validateIFA()){

    //Assign Data  
    this.setIFA()  

    //Save Client to database
    this.saveClient() 

    this.bISCopyMode =false

    localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(this.client));  
    this.loadgrid()    

    setTimeout(()=> {

      this.gridApi.forEachNode(node => {
        var tempselectedIFAID : string = node.data.IFAID
        if ( tempselectedIFAID === this.currentIFAID){  
          node.setSelected(true)
          this.gridApi.ensureIndexVisible(Number(node.rowIndex));
        } 
      });    
  
    /*  this.gridApi.forEachNode(node => {
      if ( node.rowIndex  === (this.gridApi.getDisplayedRowCount()-1) ){ 
        node.setSelected(true)
      } 
    });  */
  
  }, 50);   

  } 
}

cancelBtnClickEvent()
  {
    this.owner = ""
    this.loadIFAStaticDataForNew()
}

saveClient(){
  this.ldService.saveclient(this.client, this.loggedAgent).subscribe(date =>
    {
      this.alertService.clear()
      this.alertService.success('Successfully saved')
    },
    error => {  
      this.alertService.clear()
      this.alertService.error('Error occured while saving data')
      });
}

exitBtnClickEvent()
  {
    localStorage.setItem(LocalStorage.SELECTED_CLIENTID, "");
    //localStorage.setItem(LocalStorage.CURRENT_CLIENT,JSON.stringify(new Client));
    localStorage.removeItem(LocalStorage.CURRENT_CLIENT);
    this.router.navigateByUrl('landing'); 
}

newBtnClickEvent(){ 

    this.owner = ""
    this.loadIFAStaticDataForNew()
    this.loadgrid()

    this.rowData.push({IFAID: "",Owner: "",
      Source: "", AccountValue: '$' + Number(0)}) 

    setTimeout(()=> {
      //this.gridApi.forEachNode(node => node.rowIndex ? (this.gridApi.getDisplayedRowCount()-1) : node.setSelected(true));  
      this.gridApi.forEachNode(node => {
        if ( node.rowIndex  === (this.gridApi.getDisplayedRowCount()-1) ){ 
          node.setSelected(true)
        } 
      }); 
    
    }, 50);    

  
}

copyBtnClickEvent(){
    if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return

    if (this.gridApi.getSelectedRows()[0]["IFAID"]==="")
    {
      this.loadIFAStaticDataForNew()
      return
    } 

    this.bISCopyMode = true
    this.loadIFA(this.gridApi.getSelectedRows()[0]["IFAID"],true)
    this.currentIFAID =""
    
}

deleteBtnClickEvent(){ 
      
    if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return

    let selectedID = this.gridApi.getSelectedRows()[0]["IFAID"]     

    if (confirm("Are you sure you want to delete selected record?")) {   

      if(selectedID == ""){
        this.rowData.splice(this.rowData.findIndex(x=> x.IFAID  === selectedID), 1)
      }else{
        this.incomeFromAssetsList.splice(this.incomeFromAssetsList.findIndex(x=> x.IFAID  === selectedID), 1)
      }
    
      setTimeout(()=> {  
        this.client.IncomeFromAsset = this.incomeFromAssetsList
        
          //Save Client to database
          this.saveClient()  

          localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(this.client));  
          this.owner = ""
          this.loadIFAStaticDataForNew()
          this.loadgrid()    

          setTimeout(()=> {
            //this.gridApi.forEachNode(node => node.rowIndex ? (this.gridApi.getDisplayedRowCount()-1) : node.setSelected(true));  
            this.gridApi.forEachNode(node => {
              if ( node.rowIndex  === 0 ){ 
                node.setSelected(true)
              } 
            });           
          }, 50);   
      }, 50);   

    
    }
}

moveupBtnClickEvent(){
   if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return

   let   SelectedIFAID =this.gridApi.getSelectedRows()[0]["IFAID"]  

   if (  typeof this.client.IncomeFromAsset !== 'undefined' && this.client.IncomeFromAsset.length>0)
   {
       let selectedIncomeFromAssets:Array<IncomeFromAsset>=[]
       selectedIncomeFromAssets = this.client.IncomeFromAsset.filter(x => x.ChoiceID == this.selectedChoiceID)

       let sortedIncomeFromAssets:Array<IncomeFromAsset>=[]
       sortedIncomeFromAssets =selectedIncomeFromAssets.sort((a, b) => Number(a.DisplayOrder) - Number(b.DisplayOrder));

       let bGetNextID :boolean = false
       let NextID :string = ""

       for (var i = sortedIncomeFromAssets.length-1; i >=0  ; --i) {
         if (bGetNextID)
         {
            NextID = sortedIncomeFromAssets[i].IFAID;
            break
         }

         if (sortedIncomeFromAssets[i].IFAID == SelectedIFAID) 
         {
             bGetNextID = true
         }
     }  

   let CurerntIncomeFromAssets: IncomeFromAsset 
   let NextIncomeFromAssets :IncomeFromAsset
   let CurrentDisplayOrder:Number
   let NextDisplayOrder:Number

   CurerntIncomeFromAssets = this.client.IncomeFromAsset.find(x => x.IFAID === SelectedIFAID)
   NextIncomeFromAssets =this.client.IncomeFromAsset.find(x => x.IFAID === NextID)

   if(typeof NextIncomeFromAssets === 'undefined') return // you are in the first row

   CurrentDisplayOrder = CurerntIncomeFromAssets.DisplayOrder
   NextDisplayOrder = NextIncomeFromAssets.DisplayOrder

   this.client.IncomeFromAsset.find(x => x.IFAID === SelectedIFAID).DisplayOrder = NextDisplayOrder
   this.client.IncomeFromAsset.find(x => x.IFAID === NextID).DisplayOrder = CurrentDisplayOrder

   setTimeout(() => { 
     this.loadgrid()     

     setTimeout(()=> {
       this.gridApi.forEachNode(node => {
         var tempselectedIFAID : string = node.data.IFAID
         if ( tempselectedIFAID === SelectedIFAID){  
           node.setSelected(true)
           this.gridApi.ensureIndexVisible(Number(node.rowIndex));
         } 
       });        
     }, 50);  

     this.saveClient() //Uncomment Later
     localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(this.client));  
 }, 50);
  

   /* For Each oRow As DataGridViewRow In dgvIncomeSource.Rows
       If oRow.Cells("IncomeSourceID").Value.ToString = SelectedISID Then
           dgvIncomeSource.CurrentCell = dgvIncomeSource.Rows(oRow.Index).Cells("Owner")
           EnableISMoveUpDown()
           Exit For
       End If
   Next */

 }
}

movedownBtnClickEvent(){

    if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return

    let   SelectedIFAID =this.gridApi.getSelectedRows()[0]["IFAID"]  

    if (  typeof this.client.IncomeFromAsset !== 'undefined' && this.client.IncomeFromAsset.length>0)
    {
        let selectedIncomeFromAssets:Array<IncomeFromAsset>=[]
        selectedIncomeFromAssets = this.client.IncomeFromAsset.filter(x => x.ChoiceID == this.selectedChoiceID)

        let sortedIncomeFromAssets:Array<IncomeFromAsset>=[]
        sortedIncomeFromAssets =selectedIncomeFromAssets.sort((a, b) => Number(a.DisplayOrder) - Number(b.DisplayOrder));

        let bGetNextID :boolean = false
        let NextID :string = "" 

        for (var i = 0 ; i <=sortedIncomeFromAssets.length-1 ; ++i)  {
          if (bGetNextID)
          {
            NextID = sortedIncomeFromAssets[i].IFAID;
            break
          }

          if (sortedIncomeFromAssets[i].IFAID == SelectedIFAID) 
          {
              bGetNextID = true
          }
      }  

    let CurerntIncomeFromAssets: IncomeFromAsset 
    let NextIncomeFromAssets :IncomeFromAsset 
    let CurrentDisplayOrder:Number
    let NextDisplayOrder:Number

    CurerntIncomeFromAssets = this.client.IncomeFromAsset.find(x => x.IFAID === SelectedIFAID)
    NextIncomeFromAssets =this.client.IncomeFromAsset.find(x => x.IFAID === NextID)

    if(typeof NextIncomeFromAssets === 'undefined') return // you are in the first row

    CurrentDisplayOrder = CurerntIncomeFromAssets.DisplayOrder
    NextDisplayOrder = NextIncomeFromAssets.DisplayOrder

    this.client.IncomeFromAsset.find(x => x.IFAID === SelectedIFAID).DisplayOrder = NextDisplayOrder
    this.client.IncomeFromAsset.find(x => x.IFAID === NextID).DisplayOrder = CurrentDisplayOrder

    setTimeout(() => { 
      this.loadgrid()     

      setTimeout(()=> {
        this.gridApi.forEachNode(node => {
          var tempselectedIFAID : string = node.data.IFAID
          if ( tempselectedIFAID === SelectedIFAID){  
            node.setSelected(true)
            this.gridApi.ensureIndexVisible(Number(node.rowIndex));
          } 
        });        
      }, 50);  

      this.saveClient() //Uncomment Later
      localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(this.client));  
  }, 50); 

    //#############33
      

      /* For Each oRow As DataGridViewRow In dgvIncomeSource.Rows
          If oRow.Cells("IncomeSourceID").Value.ToString = SelectedISID Then
              dgvIncomeSource.CurrentCell = dgvIncomeSource.Rows(oRow.Index).Cells("Owner")
              EnableISMoveUpDown()
              Exit For
          End If
      Next */

    }
}

radioLTCClick(value: any) {  
  if (value=="true") 
  {
    this.showLTC = true
    this.bhasLTC = 1
  }  
  else
  {
    this.showLTC = false
    this.bhasLTC = 0
  }
}

//load the superuser dashboard page
public navigateSuperUserDashboard()
	{
		this.router.navigateByUrl('superuserdashboard'); 
}
//#endregion

//#region "validation and setIFa methods"
validateIFA():boolean{ 
    this.alertService.clear() 
    if ((this.iFAForm.controls.owner.value == ""  || 
          this.iFAForm.controls.owner.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Owner')
      return false
    } 

    if ((this.iFAForm.controls.title.value == ""  || 
          this.iFAForm.controls.title.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Title/Description')
      return false
    }     

    if ((this.iFAForm.controls.assettype.value == "" || 
    this.iFAForm.controls.assettype.value == null) && this.iFAForm.controls.assettype.value != 0)
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Asset Type')
      return false
    } 

    if ((this.iFAForm.controls.startdate.value == ""  || 
    this.iFAForm.controls.startdate.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Months of Deferral')
      return false
    } 


    let tempColaPct:any = this.iFAForm.controls.colapct.value 
    if(isNaN(tempColaPct.replace('%','')))
    {
      this.alertService.clear()
      this.alertService.error('Invalid COLA %')
      return false
    }

    let tempRiderCharge:any = this.iFAForm.controls.ridercharge.value 
    if(isNaN(tempRiderCharge.replace('%','')))
    {
      this.alertService.clear()
      this.alertService.error('Invalid Rider Charge %')
      return false
    }

    let tempSuvivorPct:any = this.iFAForm.controls.survivorshippct.value 
    if(isNaN(tempSuvivorPct.replace('%','')))
    {
      this.alertService.clear()
      this.alertService.error('Invalid Survivorship %')
      return false
    }

    let tempbonuspct:any = this.iFAForm.controls.bonuspct.value 
    if(isNaN(tempbonuspct.replace('%','')))
    {
      this.alertService.clear()
      this.alertService.error('Invalid Bonus %')
      return false
    }

    let temprolluppct:any = this.iFAForm.controls.rolluppct.value 
    if(isNaN(temprolluppct.replace('%','')))
    {
      this.alertService.clear()
      this.alertService.error('Invalid Asset Growth Rate %')
      return false
    }

    let temppayoutpct:any = this.iFAForm.controls.payoutpct.value 
    if(isNaN(temppayoutpct.replace('%','')))
    {
      this.alertService.clear()
      this.alertService.error('Invalid Payout %')
      return false
    }


    let tempdeposit:any =typeof this.iFAForm.controls.deposit.value  === 'undefined' ? "0" : this.iFAForm.controls.deposit.value    
      
    if(isNaN(tempdeposit.toString().replace('$','').replace(/,/g,'')))
    {
      this.alertService.clear()
      this.alertService.error('Invalid Deposit')
      return false
    } 

    let tempclientinitialdeposit:any =typeof this.iFAForm.controls.clientInitialDeposit.value  === 'undefined' ? "0" : this.iFAForm.controls.clientInitialDeposit.value    
      
    if(isNaN(tempclientinitialdeposit.toString().replace('$','').replace(/,/g,'')))
    {
      this.alertService.clear()
      this.alertService.error('Invalid Client Initial Deposit')
      return false
    } 

    let tempspouseinitialdeposit:any =typeof this.iFAForm.controls.spouseInitialDeposit.value  === 'undefined' ? "0" : this.iFAForm.controls.spouseInitialDeposit.value    
      
    if(isNaN(tempspouseinitialdeposit.toString().replace('$','').replace(/,/g,'')))
    {
      this.alertService.clear()
      this.alertService.error('Invalid Spouse Initial Deposit')
      return false
    } 


    let tempclientmonthlydeposit:any =typeof this.iFAForm.controls.clientMonthlyDeposit.value  === 'undefined' ? "0" : this.iFAForm.controls.clientMonthlyDeposit.value    
      
    if(isNaN(tempclientmonthlydeposit.toString().replace('$','').replace(/,/g,'')))
    {
      this.alertService.clear()
      this.alertService.error('Invalid Client Monthly Deposit')
      return false
    } 

    let tempspousemonthlydeposit:any =typeof this.iFAForm.controls.spouseMonthlyDeposit.value  === 'undefined' ? "0" : this.iFAForm.controls.spouseMonthlyDeposit.value    
      
    if(isNaN(tempspousemonthlydeposit.toString().replace('$','').replace(/,/g,'')))
    {
      this.alertService.clear()
      this.alertService.error('Invalid Spouse Monthly Deposit')
      return false
    } 

    let tempclientMonthlyDepositGrowthRate:any = this.iFAForm.controls.clientMonthlyDepositGrowthRate.value 
    if(isNaN(tempclientMonthlyDepositGrowthRate.replace('%','')))
    {
      this.alertService.clear()
      this.alertService.error('Invalid Client Monthly Deposit Growth Rate')
      return false
    }

    let tempspouseMonthlyDepositGrowthRate:any = this.iFAForm.controls.spouseMonthlyDepositGrowthRate.value 
    if(isNaN(tempspouseMonthlyDepositGrowthRate.replace('%','')))
    {
      this.alertService.clear()
      this.alertService.error('Invalid Spouse Monthly Deposit Growth Rate')
      return false
    }

    //validate client monthly deposit date range   
    //this.calculatePayoutValue() // calculatePayoutValue function is called in Validate to update payoutStartsOn
    if (this.clientMonthlyDepositStartDate =='Invalid Date') 
    {
      this.alertService.clear()
      this.alertService.error('Invalid client monthly deposit start date')
      return false
    } 

    if (this.clientMonthlyDepositEndDate =='Invalid Date') 
    {
      this.alertService.clear()
      this.alertService.error('Invalid client monthly deposit end date')
      return false
    } 

    if (Number(tempclientmonthlydeposit.toString().replace('$','').replace(/,/g,''))>0)
    {
      if ( this.clientMonthlyDepositStartDate == null || this.clientMonthlyDepositStartDate == 'undefined' ){  

        this.alertService.clear()
        this.alertService.error('Invalid client monthly deposit start date')
        return false
      } 
      

      if ( this.clientMonthlyDepositEndDate != null && this.clientMonthlyDepositEndDate != 'undefined' ){
        if (this.clientMonthlyDepositStartDate>=this.clientMonthlyDepositEndDate) 
        {
          this.alertService.clear()
          this.alertService.error('Invalid client monthly deposit Start/End Date')
          return false
        }
      }
    }



    //validate spouse monthly deposit date range    
    if (this.spouseMonthlyDepositStartDate =='Invalid Date') 
    {
       this.alertService.clear()
       this.alertService.error('Invalid spouse monthly deposit start date')
       return false
    } 
 
     if (this.spouseMonthlyDepositEndDate =='Invalid Date') 
    {
       this.alertService.clear()
       this.alertService.error('Invalid spouse monthly deposit end date')
       return false
    } 
    
    if (Number(tempspousemonthlydeposit.toString().replace('$','').replace(/,/g,''))>0)
    {
      if ( this.spouseMonthlyDepositStartDate == null || this.spouseMonthlyDepositStartDate == 'undefined' ){  

        this.alertService.clear()
        this.alertService.error('Invalid spouse monthly deposit start date')
        return false
      } 

      if ( this.spouseMonthlyDepositEndDate != null && this.spouseMonthlyDepositEndDate != 'undefined' ){
        if (this.spouseMonthlyDepositStartDate>=this.spouseMonthlyDepositEndDate) 
        {
          this.alertService.clear()
          this.alertService.error('Invalid spouse monthly deposit Start/End Date')
          return false
        }
      }
    }
    


   //Validate LTC function 
   if (this.bhasLTC==1)
   { 
      
      if ((this.iFAForm.controls.LTCWaitingPeriod.value == ""  || 
            this.iFAForm.controls.LTCWaitingPeriod.value == null ))
            {
              this.alertService.clear()
              this.alertService.error('Invalid LTC benefit waiting period')
              return false
            }
      else{
          let tempLTCWaitingPeriod:any = this.iFAForm.controls.LTCWaitingPeriod.value 
          if(isNaN(tempLTCWaitingPeriod))
          {
            this.alertService.clear()
            this.alertService.error('Invalid LTC benefit waiting period')
            return false
          }
      }

      if ((this.iFAForm.controls.LTCBegin.value == ""  || 
            this.iFAForm.controls.LTCBegin.value == null ))
            {
              this.alertService.clear()
              this.alertService.error('Invalid LTC benefit begin time')
              return false
            }
      else{
          let tempLTCBegin:any = this.iFAForm.controls.LTCBegin.value 
          if(isNaN(tempLTCBegin))
          {
            this.alertService.clear()
            this.alertService.error('Invalid LTC benefit begin time')
            return false
          } else if(this.iFAForm.controls.LTCBegin.value < this.iFAForm.controls.LTCWaitingPeriod.value){
            this.alertService.clear()
            this.alertService.error('Time that LTC benefit begin cannot be less than the waiting period')
            return false
          }
      }

      if ((this.iFAForm.controls.LTCEnd.value == ""  || 
          this.iFAForm.controls.LTCEnd.value == null ))
          {
            this.alertService.clear()
            this.alertService.error('Invalid LTC benefit end')
            return false
          }
      else{
          let tempLTCEnd:any = this.iFAForm.controls.LTCEnd.value 
          if(isNaN(tempLTCEnd))
          {
            this.alertService.clear()
            this.alertService.error('Invalid LTC benefit end')
            return false
          }else if(parseInt(this.iFAForm.controls.LTCEnd.value) < parseInt(this.iFAForm.controls.LTCBegin.value)){
            this.alertService.clear()
            this.alertService.error('Time that LTC benefit end cannot be less than the LTC benefit begin')
            return false
          }
      }
      if ((this.iFAForm.controls.LTCMultiplier.value == ""  || 
            this.iFAForm.controls.LTCMultiplier.value == null ))
          {
            this.alertService.clear()
            this.alertService.error('Invalid LTC multiplier')
            return false
          }
      else{
          let tempLTCMultiplier:any = this.iFAForm.controls.LTCMultiplier.value 
          if(isNaN(tempLTCMultiplier))
          {
            this.alertService.clear()
            this.alertService.error('Invalid LTC multiplier')
            return false
          }
      }

   }
 

   //TODO
   /*

           ' check for duplicate source for new records
           If cbOwner.Tag = "" Then

               var tempIncomeSource As List(Of Models.IncomeSourceAccount)
               tempIncomeSource = oClient.IncomeSourceAccount.Where(Function(x) x.ChoiceID.ToString = mSelectedChoiceID.ToString And x.Owner = cbOwner.Text And x.Source = cbSource.Text).ToList
               If tempIncomeSource.Count > 0 Then
                   ErrorIncomeSources.SetError(cbSource, "Source already exists.")
                   bResult = False
               End If
           End If
   */
   return true
}  
 
setIFA(){ 
      this.client   = JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) as Client;  

      var oIncomeFromAsset : IncomeFromAsset = new  IncomeFromAsset()
      var IFAID : string =  this.commonFunctions.getnewguid() 
      var CurrentIFAID  : string
      var currentyear = new Date().getFullYear()

      var dDOB : Date

      if (typeof this.client.SpouseFirstName === 'undefined')
      {
        dDOB = new Date(this.client.ClientDOB)
      }
      else
      {
            if (this.iFAForm.controls.owner.value.toString().trim().replace(" ","")==this.client.SpouseFirstName.toString().trim() + this.client.SpouseLastName.toString().trim())
            {
              dDOB = new Date(this.client.SpouseDOB)
            }
            else
            {
              dDOB = new Date(this.client.ClientDOB)
            }
      }  

      let tempclientinitialdeposit = this.iFAForm.controls.clientInitialDeposit.value.toString().replace("$","").replace(/,/g,'')
      let tempclientmonthlydeposit = this.iFAForm.controls.clientMonthlyDeposit.value.toString().replace("$","").replace(/,/g,'')
      if((tempclientinitialdeposit == 0 || tempclientinitialdeposit == null) && (tempclientmonthlydeposit == 0 || tempclientmonthlydeposit == null)){
        this.iFAForm.controls.clientMonthlyDepositGrowthRate.setValue("0.00%")
        this.clientMonthlyDepositGrowthRate = "0.00%"
      }
      let tempspouseinitialdeposit = this.iFAForm.controls.spouseInitialDeposit.value.toString().replace("$","").replace(/,/g,'')
      let tempspousemonthlydeposit = this.iFAForm.controls.spouseMonthlyDeposit.value.toString().replace("$","").replace(/,/g,'')
      if((tempspouseinitialdeposit == 0 || tempspouseinitialdeposit == null) && (tempspousemonthlydeposit == 0 || tempspousemonthlydeposit == null)){
        this.iFAForm.controls.spouseMonthlyDepositGrowthRate.setValue("0.00%")
        this.spouseMonthlyDepositGrowthRate = "0.00%"
      }
      let StartYear:number
      let StartMonth :number 
      let StartDate:Date = new Date(dDOB) 
  

      StartDate.setMonth(dDOB.getMonth()+this.iFAForm.controls.startdate.value)
  
    
      StartMonth = StartDate.getMonth()+1 // as month start from 0 for January
      StartYear = StartDate.getFullYear()
      StartDate = new Date(StartYear,StartMonth-1,1) // Make start date to start from 1st of the month
      let dMonthsOfDefferal:any = this.getAgeInMonths(this.mIFAStartDate,StartDate)  
      
      if ( this.currentIFAID == "") //New Income from asset
      {
          CurrentIFAID = IFAID

          oIncomeFromAsset.IFAID = IFAID
          oIncomeFromAsset.ChoiceID = this.selectedChoiceID
          oIncomeFromAsset.Owner = this.iFAForm.controls.owner.value 
          oIncomeFromAsset.Source = this.iFAForm.controls.title.value 
          oIncomeFromAsset.AssetType = Number(this.iFAForm.controls.assettype.value) 
          oIncomeFromAsset.Deposit = this.iFAForm.controls.deposit.value.toString().replace("$","").replace(/,/g,'') 
          oIncomeFromAsset.BonusFlag = this.iFAForm.controls.bonusflag.value == 1 ? true : false
          oIncomeFromAsset.BonusPct =this.iFAForm.controls.bonuspct.value.replace("%","")  
          oIncomeFromAsset.YearsOfDeferral = dMonthsOfDefferal
          oIncomeFromAsset.StartYear = StartYear.toString()
          oIncomeFromAsset.RollUpHow =this.iFAForm.controls.rolluphow.value 
          oIncomeFromAsset.RollUpPct = this.iFAForm.controls.rolluppct.value.replace("%","")  
          oIncomeFromAsset.AccountValue = this.accountValue.toString().replace("$","").replace(/,/g,'') 
          oIncomeFromAsset.PayoutPct = this.iFAForm.controls.payoutpct.value.replace("%","")  
          oIncomeFromAsset.SurvivorshipHow =  this.iFAForm.controls.survivorshiphow.value 
          oIncomeFromAsset.SurvivorshipPct = this.iFAForm.controls.survivorshippct.value.replace("%","")  
          oIncomeFromAsset.COLAFlag =  this.iFAForm.controls.cola.value == 1 ? true : false
          oIncomeFromAsset.COLAPct =this.iFAForm.controls.colapct.value.replace("%","")  
          oIncomeFromAsset.RiderCharge = this.iFAForm.controls.ridercharge.value.replace("%","")
          oIncomeFromAsset.DisplayOrder = this.getNextOrder(Constants.TABLE_NAME.IncomeFromAssets)
          oIncomeFromAsset.StartDate = formatDate(new Date(this.mIFAStartDate), 'MM/dd/yyyy', 'en') 

        //LTC

          oIncomeFromAsset.HasLTC =  this.bhasLTC == 1 ? true : false
          if (this.bhasLTC == 1)
          {
            oIncomeFromAsset.LTCNotAvailableTime =   this.iFAForm.controls.LTCWaitingPeriod.value - this.startyearlist[1].value
            oIncomeFromAsset.LTCNotAvailableFor =  this.iFAForm.controls.LTCBegin.value - this.startyearlist[1].value
            oIncomeFromAsset.LTCMultiplier =  this.iFAForm.controls.LTCMultiplier.value 
            oIncomeFromAsset.LTCPeriod  =  this.iFAForm.controls.LTCEnd.value - this.startyearlist[1].value
          }
          else
          {
            oIncomeFromAsset.LTCNotAvailableTime =  0
            oIncomeFromAsset.LTCNotAvailableFor =  0
            oIncomeFromAsset.LTCMultiplier =  0
            oIncomeFromAsset.LTCPeriod  =  0
          } 

          //Monthly Deposit 
          oIncomeFromAsset.ClientInitialDeposit = this.iFAForm.controls.clientInitialDeposit.value.toString().replace("$","").replace(/,/g,'')
          oIncomeFromAsset.ClientMonthlyDeposit = this.iFAForm.controls.clientMonthlyDeposit.value.toString().replace("$","").replace(/,/g,'')
          oIncomeFromAsset.ClientMonthlyDepositStartDate = this.clientMonthlyDepositStartDate
          if((this.clientMonthlyDepositEndDate == null || this.clientMonthlyDepositEndDate == undefined) && tempclientmonthlydeposit != 0 && tempclientmonthlydeposit != null){
            let clientage = Number(currentyear - new Date(this.client.ClientDOB).getFullYear())
            this.clientMonthlyDepositEndDate = new Date(new Date().setFullYear(currentyear + Number(Constants.LAST_YEAR) - clientage))
          }
          oIncomeFromAsset.ClientMonthlyDepositEndDate = this.clientMonthlyDepositEndDate
          oIncomeFromAsset.ClientMonthlyDepositGrowthRate = this.iFAForm.controls.clientMonthlyDepositGrowthRate.value.replace("%","")  
          oIncomeFromAsset.SpouseInitialDeposit = this.iFAForm.controls.spouseInitialDeposit.value.toString().replace("$","").replace(/,/g,'')
          oIncomeFromAsset.SpouseMonthlyDeposit = this.iFAForm.controls.spouseMonthlyDeposit.value.toString().replace("$","").replace(/,/g,'')
          oIncomeFromAsset.SpouseMonthlyDepositStartDate = this.spouseMonthlyDepositStartDate
          if((this.spouseMonthlyDepositEndDate == null || this.spouseMonthlyDepositEndDate == undefined) && tempspousemonthlydeposit != 0 && tempspousemonthlydeposit != null){
            let clientage = Number(currentyear - new Date(this.client.SpouseDOB).getFullYear())
            this.spouseMonthlyDepositEndDate = new Date(new Date().setFullYear(currentyear + Number(Constants.LAST_YEAR) - clientage))
          }


          oIncomeFromAsset.SpouseMonthlyDepositEndDate = this.spouseMonthlyDepositEndDate
          oIncomeFromAsset.SpouseMonthlyDepositGrowthRate = this.iFAForm.controls.spouseMonthlyDepositGrowthRate.value.replace("%","")  
          
          this.incomeFromAssetsList.push(oIncomeFromAsset)
        }
      else{  

        oIncomeFromAsset = this.incomeFromAssetsList.find( x=> x.IFAID  === this.currentIFAID)
        
        CurrentIFAID = oIncomeFromAsset.IFAID
      
        oIncomeFromAsset.ChoiceID = this.selectedChoiceID
        oIncomeFromAsset.Owner = this.iFAForm.controls.owner.value 
        oIncomeFromAsset.Source = this.iFAForm.controls.title.value 
        oIncomeFromAsset.AssetType = Number(this.iFAForm.controls.assettype.value) 
        oIncomeFromAsset.Deposit = this.iFAForm.controls.deposit.value.toString().replace("$","").replace(/,/g,'') 
        oIncomeFromAsset.BonusFlag = this.iFAForm.controls.bonusflag.value == 1 ? true : false
        oIncomeFromAsset.BonusPct =this.iFAForm.controls.bonuspct.value.replace("%","")  
        oIncomeFromAsset.YearsOfDeferral = dMonthsOfDefferal
        oIncomeFromAsset.StartYear = StartYear.toString()
        oIncomeFromAsset.RollUpHow =this.iFAForm.controls.rolluphow.value 
        oIncomeFromAsset.RollUpPct = this.iFAForm.controls.rolluppct.value.replace("%","")  
        oIncomeFromAsset.AccountValue = this.accountValue.toString().replace("$","").replace(/,/g,'') 
        oIncomeFromAsset.PayoutPct = this.iFAForm.controls.payoutpct.value.replace("%","")  
        oIncomeFromAsset.SurvivorshipHow =  this.iFAForm.controls.survivorshiphow.value 
        oIncomeFromAsset.SurvivorshipPct = this.iFAForm.controls.survivorshippct.value.replace("%","")  
        oIncomeFromAsset.COLAFlag =  this.iFAForm.controls.cola.value == 1 ? true : false
        oIncomeFromAsset.COLAPct =this.iFAForm.controls.colapct.value.replace("%","")   
        oIncomeFromAsset.RiderCharge = this.iFAForm.controls.ridercharge.value.replace("%","")
        //LTC 
        oIncomeFromAsset.HasLTC = this.bhasLTC == 1 ? true : false 
        if (this.bhasLTC == 1)
        {
          oIncomeFromAsset.LTCNotAvailableTime =   this.iFAForm.controls.LTCWaitingPeriod.value - this.startyearlist[1].value
          oIncomeFromAsset.LTCNotAvailableFor =  this.iFAForm.controls.LTCBegin.value - this.startyearlist[1].value
          oIncomeFromAsset.LTCMultiplier =  this.iFAForm.controls.LTCMultiplier.value 
          oIncomeFromAsset.LTCPeriod  =  this.iFAForm.controls.LTCEnd.value - this.startyearlist[1].value
        }
        else
        {
          oIncomeFromAsset.LTCNotAvailableTime =  0
          oIncomeFromAsset.LTCNotAvailableFor =  0
          oIncomeFromAsset.LTCMultiplier =  0
          oIncomeFromAsset.LTCPeriod  =  0
        }

        //Monthly Deposit 
        oIncomeFromAsset.ClientInitialDeposit = this.iFAForm.controls.clientInitialDeposit.value.toString().replace("$","").replace(/,/g,'')
        oIncomeFromAsset.ClientMonthlyDeposit = this.iFAForm.controls.clientMonthlyDeposit.value.toString().replace("$","").replace(/,/g,'')
        oIncomeFromAsset.ClientMonthlyDepositStartDate = this.clientMonthlyDepositStartDate
        if((this.clientMonthlyDepositEndDate == null || this.clientMonthlyDepositEndDate == undefined) && tempclientmonthlydeposit != 0 && tempclientmonthlydeposit != null){
          let clientage = Number(currentyear - new Date(this.client.ClientDOB).getFullYear())
          this.clientMonthlyDepositEndDate = new Date(new Date().setFullYear(currentyear + Number(Constants.LAST_YEAR) - clientage))
        }
        oIncomeFromAsset.ClientMonthlyDepositEndDate = this.clientMonthlyDepositEndDate
        oIncomeFromAsset.ClientMonthlyDepositGrowthRate = this.iFAForm.controls.clientMonthlyDepositGrowthRate.value.replace("%","")  
        oIncomeFromAsset.SpouseInitialDeposit = this.iFAForm.controls.spouseInitialDeposit.value.toString().replace("$","").replace(/,/g,'')
        oIncomeFromAsset.SpouseMonthlyDeposit = this.iFAForm.controls.spouseMonthlyDeposit.value.toString().replace("$","").replace(/,/g,'')
        oIncomeFromAsset.SpouseMonthlyDepositStartDate = this.spouseMonthlyDepositStartDate
        if((this.spouseMonthlyDepositEndDate == null || this.spouseMonthlyDepositEndDate == undefined) && tempspousemonthlydeposit != 0 && tempspousemonthlydeposit != null){
          let clientage = Number(currentyear - new Date(this.client.SpouseDOB).getFullYear())
          this.spouseMonthlyDepositEndDate = new Date(new Date().setFullYear(currentyear + Number(Constants.LAST_YEAR) - clientage))
        }
        oIncomeFromAsset.SpouseMonthlyDepositEndDate = this.spouseMonthlyDepositEndDate
        oIncomeFromAsset.SpouseMonthlyDepositGrowthRate = this.iFAForm.controls.spouseMonthlyDepositGrowthRate.value.replace("%","") 
      } 

      this.client.IncomeFromAsset = this.incomeFromAssetsList 
      this.currentIFAID = CurrentIFAID
      
} 
//#endregion

//#region "calculations"
calculatePayoutValue()
  {
   let dPayoutVal : number
   let dDepositAmount: number
  // let dClientMonthlyDepositAmount: number
   //let dSpouseMonthlyDepositAmount: number
   let dTotalDepositAmount: number
   let dBonusPercentage: number
   let dAssetGrowthRate : number
   let dMonthsOfDefferal: number
   let dYearsOfDefferal: number //calculated by deviding MonthsOfDefferal from 12 


   let tempDepositAmount:any =typeof this.iFAForm.controls.deposit.value  === 'undefined' ? "0" : this.iFAForm.controls.deposit.value  
       
   if(!isNaN(tempDepositAmount.toString().replace('$','').replace(/,/g,'')))
   {
      dDepositAmount = tempDepositAmount.toString().replace('$','').replace(/,/g,'')
   } 
   else
   {
      dDepositAmount =0
   }  

  /* Commenting monthly deposit changes here in payout val
   let tempClientMonthlyDepositAmount:any =typeof this.iFAForm.controls.clientMonthlyDeposit.value  === 'undefined' ? "0" : this.iFAForm.controls.clientMonthlyDeposit.value  
       
 
   if(!isNaN(tempClientMonthlyDepositAmount.toString().replace('$','').replace(/,/g,'')))
   {
    dClientMonthlyDepositAmount = tempClientMonthlyDepositAmount.toString().replace('$','').replace(/,/g,'')
   } 
   else
   {
    dClientMonthlyDepositAmount =0
   } 

   let tempSpouseMonthlyDepositAmount:any =typeof this.iFAForm.controls.spouseMonthlyDeposit.value  === 'undefined' ? "0" : this.iFAForm.controls.spouseMonthlyDeposit.value  
       
   if(!isNaN(tempSpouseMonthlyDepositAmount.toString().replace('$','').replace(/,/g,'')))
   {
    dSpouseMonthlyDepositAmount = tempSpouseMonthlyDepositAmount.toString().replace('$','').replace(/,/g,'')
   } 
   else
   {
    dSpouseMonthlyDepositAmount =0
   }  */

   //Adding all deposit amonths for payout value
   dTotalDepositAmount = Number(dDepositAmount)//+Number(dClientMonthlyDepositAmount*12)+Number(dSpouseMonthlyDepositAmount*12)  Commenting monthly deposit changes here in payout val
   //Bonus 
     if (this.iFAForm.controls.bonusflag.value == "1") 
     {  
         dBonusPercentage = this.iFAForm.controls.bonuspct.value.toString().replace("%", '') 
        // dPayoutVal = Number(dDepositAmount) + Number((dDepositAmount * dBonusPercentage) / 100) 
         dPayoutVal = Number(dTotalDepositAmount) + Number((dTotalDepositAmount * dBonusPercentage) / 100) // Using total deposit amount
     }    
     else{

        // dPayoutVal = dDepositAmount
         dPayoutVal = dTotalDepositAmount // Using total deposit amount
     }  

     //Asset Feature. 
     dAssetGrowthRate = this.iFAForm.controls.rolluppct.value.toString().replace("%", '') 

     //Month of Derreral 

     let DOB: Date
     let selectedMonthVal:any = this.iFAForm.controls.startdate.value
     if (typeof this.client.SpouseLastName === 'undefined' )
       { 
         DOB = new Date(this.client.ClientDOB)
      }
     else
     {
         if (this.iFAForm.controls.owner.value.trim().replace(" ","")==this.client.SpouseFirstName.trim() + this.client.SpouseLastName.trim())
         {      
            DOB = new Date(this.client.SpouseDOB)
         }
         else
         { 
            DOB = new Date(this.client.ClientDOB)
        }  
     } 

     let selectedDate : Date = new Date(DOB)
     selectedDate.setMonth(DOB.getMonth()+this.iFAForm.controls.startdate.value) 
     dMonthsOfDefferal = this.getAgeInMonths(this.mIFAStartDate,selectedDate) 

     //assign payoutStartsOn - this will be using to validate monthly deposit start date
     this.payoutStartsOn = selectedDate
     this.payoutStartsOn.setDate(1) 

     dYearsOfDefferal = dMonthsOfDefferal / 12 

  

     if (this.iFAForm.controls.rolluphow.value == "Compound") {
         // A = P((1 + r)^n - 1)  
        dPayoutVal = Number(dPayoutVal) + Number((dPayoutVal) * ( Math.pow((1 + (dAssetGrowthRate / 100)), dYearsOfDefferal) - 1))
     
      }else if (this.iFAForm.controls.rolluphow.value ==  "Simple") {
         //A = Prt
         dPayoutVal = Number(dPayoutVal)+ Number(dPayoutVal * (dAssetGrowthRate / 100) * dYearsOfDefferal)
     } 
 
    this.accountValue =this.commonFunctions.currencyFormat(Number( dPayoutVal))  
 
    // calculate annual and monthly ammount
    let dpayoutpct :number
    dpayoutpct = this.iFAForm.controls.payoutpct.value.toString().replace("%", '') 
  
    let tempAnnualAmount : number = (dPayoutVal * dpayoutpct)/100
    let tempMonthlyAmount  : number =tempAnnualAmount/12

    this.annualAmount = this.commonFunctions.currencyFormat(Number(tempAnnualAmount ))  + " yearly" 
    this.monthlyAmount = this.commonFunctions.currencyFormat(Number(tempMonthlyAmount))  + " monthly" 
}

CalculateDepositFromMonthlyDeposits(){
      let finalDepositValue: number 
      let totalDeposit : number =0 
      let hasClientDepositChanges : boolean = false 
      let hasSpouseDepositChanges : boolean = false
      let tempMonthlyDepositGrowthRate:any
      let startDate :Date = new Date()  
      let endDate : Date= new Date()

      //calculate client monthly deposit amounts
      let tempclientmonthlydeposit:any =typeof this.iFAForm.controls.clientMonthlyDeposit.value  === 'undefined' ? "0" : this.iFAForm.controls.clientMonthlyDeposit.value    
      
      if(!(isNaN(tempclientmonthlydeposit.toString().replace('$','').replace(/,/g,''))))
      { 
        if(!(isNaN(this.iFAForm.controls.clientMonthlyDepositGrowthRate.value.replace('%',''))))
        {
          if(this.clientMonthlyDepositGrowthRate != "0.00%"){
            tempMonthlyDepositGrowthRate = this.clientMonthlyDepositGrowthRate
          }
          if ((this.clientMonthlyDepositStartDate !='Invalid Date') && (this.clientMonthlyDepositEndDate !='Invalid Date') &&
              (this.clientMonthlyDepositStartDate != null) && (this.clientMonthlyDepositStartDate != 'undefined')) 
            { 
              if (this.clientMonthlyDepositStartDate<this.clientMonthlyDepositEndDate) 
              {
                hasClientDepositChanges=true

                  //let tempTotalClientDeposit : number = 0
                  startDate = new Date(this.clientMonthlyDepositStartDate )  
                  endDate = new Date(this.clientMonthlyDepositEndDate)

                  startDate.setDate(1)
                  endDate.setDate(1) 
              } 
            }               
        }
      } 

      
      //calculate spouse monthly deposits
      let tempspousemonthlydeposit:any =typeof this.iFAForm.controls.spouseMonthlyDeposit.value  === 'undefined' ? "0" : this.iFAForm.controls.spouseMonthlyDeposit.value    
      if(this.hasSpouse){
        if(!(isNaN(tempspousemonthlydeposit.toString().replace('$','').replace(/,/g,''))))
        { 
          if(!(isNaN(this.iFAForm.controls.spouseMonthlyDepositGrowthRate.value.replace('%',''))))
          {
            if(this.spouseMonthlyDepositGrowthRate != "0.00%"){
              tempMonthlyDepositGrowthRate = this.spouseMonthlyDepositGrowthRate
            }
            if ((this.spouseMonthlyDepositStartDate !='Invalid Date') && (this.spouseMonthlyDepositEndDate !='Invalid Date') &&
                (this.spouseMonthlyDepositStartDate != null) && (this.spouseMonthlyDepositStartDate != 'undefined')) 
              { 
                if (this.spouseMonthlyDepositStartDate<this.spouseMonthlyDepositEndDate) 
                {
                  hasSpouseDepositChanges=true

                  // let tempTotalspouseDeposit : number = 0
                  if(this.spouseMonthlyDepositStartDate<this.clientMonthlyDepositStartDate){
                    startDate  = new Date(this.spouseMonthlyDepositStartDate)
                  }
                  if(this.spouseMonthlyDepositEndDate>this.clientMonthlyDepositEndDate){
                    endDate  = new Date(this.spouseMonthlyDepositEndDate)
                  }
                    startDate.setDate(1)
                    endDate.setDate(1)
                } 
              }               
          }
        }  
      }


      if(hasClientDepositChanges || hasSpouseDepositChanges){
        let noOfMonths : number = this.MonthDiff(startDate,endDate)  + 1// added 1 month to count current month too
        let clientInitialDepositStartMonth: number
        let spouseInitialDepositStartMonth: number
        let clientInitialDepositEndMonth: number
        let spouseInitialDepositEndMonth: number
        let thisYearMonthCount : number = 0 


        for (var i=0;i< noOfMonths; i++)
        {  
          let currentMonth : number  = startDate.getMonth() +1 + i  // Added 1 as getMonth start from 0
          let monthModulus : number = currentMonth % 12

          if(hasClientDepositChanges){
            clientInitialDepositStartMonth = this.MonthDiff(startDate,this.clientMonthlyDepositStartDate)        
            clientInitialDepositEndMonth = this.MonthDiff(startDate,this.clientMonthlyDepositEndDate)

            if(!(isNaN(this.iFAForm.controls.clientInitialDeposit.value.toString().replace('$','').replace(/,/g,''))))
            { 
              if(i == clientInitialDepositStartMonth){
                totalDeposit += Number(this.iFAForm.controls.clientInitialDeposit.value.toString().replace('$','').replace(/,/g,''))
              }
            }
            if(clientInitialDepositStartMonth <= i && i <= clientInitialDepositEndMonth){
              totalDeposit += Number(tempclientmonthlydeposit.toString().replace('$','').replace(/,/g,''))
            }
          }

          if(hasSpouseDepositChanges){
            spouseInitialDepositStartMonth = this.MonthDiff(startDate,this.spouseMonthlyDepositStartDate)
            spouseInitialDepositEndMonth = this.MonthDiff(startDate,this.spouseMonthlyDepositEndDate)
            if(!(isNaN(this.iFAForm.controls.spouseInitialDeposit.value.toString().replace('$','').replace(/,/g,''))))
            { 
              if(i == spouseInitialDepositStartMonth){
                totalDeposit += Number(this.iFAForm.controls.spouseInitialDeposit.value.toString().replace('$','').replace(/,/g,''))
              }
            }
            if(spouseInitialDepositStartMonth <= i && i <= spouseInitialDepositEndMonth){
              totalDeposit += Number(tempspousemonthlydeposit.toString().replace('$','').replace(/,/g,''))
            }
          }


          // thisYearMonthCount +=1
          // if (monthModulus==0 || i==noOfMonths-1) // calculate interest for the year in month of December OR for last month
          // { 
          //   let thisYearInterest : number 
          //   thisYearInterest = (totalDeposit * Number(tempMonthlyDepositGrowthRate.replace('%','')) /100)/12*thisYearMonthCount
          //   totalDeposit += thisYearInterest 
          //   thisYearMonthCount = 0
          // }   
          if(tempMonthlyDepositGrowthRate != undefined){
            totalDeposit += (totalDeposit * (Number(tempMonthlyDepositGrowthRate.replace('%','')) /100/12)) 
          }
        }
      } 
      finalDepositValue = totalDeposit  
      if (hasClientDepositChanges==true || hasSpouseDepositChanges==true){
        this.isDepositValReadOnly = true
      }else{
        this.isDepositValReadOnly = false
      }
      if (this.isDepositValReadOnly && finalDepositValue != 0)
      {
        this.deposit = this.commonFunctions.currencyFormat(finalDepositValue)
      }
        
        this.iFAForm.controls.deposit.setValue(this.deposit)
        if ((this.iFAForm.controls.owner.value == ""  || 
              this.iFAForm.controls.owner.value == null || 
              this.iFAForm.controls.owner.value == 'undefined' ))
        {
          // no owner... doing nothing
        }
        else 
        {
          this.calculatePayoutValue()
        }
      //}
}

MonthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() + 
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}
//#endregion

CommaFormatted(event) {
  // skip for arrow keys
/*   if(event.which >= 37 && event.which <= 40) return;  
  this.deposit =  event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")       */ 
        
        
    if(event.which >= 37 && event.which <= 40) return;  

    let value =  this.commonFunctions.AddThousandSeparatorFormat(event.target.value)     
    if (event.target.id==="deposit") {
      this.deposit = value
    } else if (event.target.id==="clientMonthlyDeposit") {
      this.clientMonthlyDeposit = value
    } else if (event.target.id==="spouseMonthylDeposit") {
      this.spouseMonthlyDeposit = value
    } else if (event.target.id==="clientInitialDeposit") {
      this.clientInitialDeposit = value
    } else if (event.target.id==="spouseInitialDeposit") {
      this.spouseInitialDeposit = value
    }          

}
}
