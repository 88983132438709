<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg">
	<app-header></app-header>
</nav>
<div class="main-panel pt-0 pb-4">
    <div class="container-fluid" #main [style.padding-top.px]="paddingtop">
        <div class="col-sm-12 px-0 align-self-end">
          <div class="row align-items-center">
            <div class="col-sm-9">
              <h3 class="light-header bold" [hidden] = "!ismytickerblend">
                {{ 'MYTICKERBLEND.LBL_TITLE' | translate}}
              </h3>
              <h3 class="light-header bold" [hidden] = "ismytickerblend">
                {{ 'TICKERBLEND.LBL_TITLE' | translate}}
              </h3>
            </div>
            <div class="col-sm-3">
              <button type="button" class="btn btn-primary btn-sm float-right" (click)="exitBtnClickEvent()">
                <i class="fas fa-sign-out"></i> Exit
              </button>
              <button type="button" class="btn btn-primary btn-sm mr-1 float-right" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
                {{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
              </button>
            </div>
          </div>
          <hr class="mt-0 mb-3" />
        </div>
        <div class="pt-3">
          <div class="container border rounded p-3 bold"> 
          <!--TickerBlend List Start-->
          <h5 class="light-header bold" [hidden] = "!ismytickerblend">
            {{ 'MYTICKERBLEND.LBL_TABLE_TITLE' | translate}}
            <div class="float-right">
              <button type="button" class="btn btn-primary btn-sm float-right ml-1" (click)="deleteBtnClickEvent()">
                <i class="fas fa-minus"></i> Delete
              </button>
              <button type="button" class="btn btn-primary btn-sm float-right ml-1" (click)="copyBtnClickEvent()" >
                  <i class="fas fa-copy"></i> Copy
                </button>
              <button type="button" class="btn btn-primary btn-sm float-right ml-1" (click)="newBtnClickEvent()">
                <i class="fas fa-plus"></i> New
              </button>
            </div>
          </h5>
          <h5 class="light-header bold" [hidden] = "ismytickerblend">
            {{ 'TICKERBLEND.LBL_TABLE_TITLE' | translate}}
            <div class="float-right">
              <button type="button" class="btn btn-primary btn-sm float-right ml-1" (click)="deleteBtnClickEvent()">
                <i class="fas fa-minus"></i> Delete
              </button>
              <button type="button" class="btn btn-primary btn-sm float-right ml-1" (click)="copyBtnClickEvent()" >
                  <i class="fas fa-copy"></i> Copy
                </button>
              <button type="button" class="btn btn-primary btn-sm float-right ml-1" (click)="newBtnClickEvent()">
                <i class="fas fa-plus"></i> New
              </button>
            </div>
          </h5>
          <hr class="mt-0 mb-3" />
          
          <div id="grid-wrapper" class="col-sm-12 px-0" style="width: 100%; overflow: hidden;">
              <div class="col-sm-12 px-0" >
                <ag-grid-angular style="width: 100%; height: 300px; " class="ag-theme-balham bold"
                  [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" [rowData]="rowData"
                  (gridSizeChanged)="gridSizeChanged($event)"
                  (cellClicked)="onCellClicked($event)" 
                  [headerHeight]="60" [gridOptions]="gridOptions" >
                </ag-grid-angular>
              </div>
          </div> 
          </div> 
          <div class="container border rounded p-3 bold">
            <h5 class="light-header bold" [hidden] = "!ismytickerblend">
              {{ 'MYTICKERBLEND.LBL_FORM_TITLE' | translate}}
            </h5>
            <h5 class="light-header bold" [hidden] = "ismytickerblend">
              {{ 'TICKERBLEND.LBL_FORM_TITLE' | translate}}
            </h5>
            <hr class="mt-0 mb-3" />
            <form [formGroup]="iSForm">
              <div class="form-group row">
                <label for="Name" class="col-sm-2 col-form-label">Name</label>
                <div class="col-sm-6"> 
                   <input type="text" class="form-control bold" id="name"  autocomplete="off"
                         [(ngModel)]="name"  formControlName="name"/> 
                </div>
              </div>
              <div class="form-group row">
                <label for="Tickers" class="col-sm-2 col-form-label">Ticker Symbol</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control bold" id="tickersymbol"  autocomplete="off"
                    [(ngModel)]="tickersymbol" (ngModelChange)="TickerToUppercase($event)" formControlName="tickersymbol"/>
                </div>
                <div class="col-sm-2">
                    <button type="button" class="btn btn-primary float-right btn-sm" (click)="addTickerSymbol()">
                      <i class="far fa-check-circle"></i> Select Symbol
                    </button>
                </div>
              </div>
              <div class="row" style="padding-left: 1.5%">
              <div id="grid-wrapper" class="col-sm-11 px-0" style="width: 100%; overflow: hidden;">
                <div class="col-sm-12 px-0" >
                  <ag-grid-angular style="width: 100%; height: 200px; " class="ag-theme-balham bold"
                    [columnDefs]="columnDefs2" (gridReady)="onGridReady2($event)" [rowData]="rowData2"
                    (gridSizeChanged)="gridSizeChanged($event)" [pinnedBottomRowData]="pinnedBottomRowData"
                    (cellValueChanged)="onCellValueChanged($event)" (cellClicked)="onTickerClicked($event)"
                    [headerHeight]="40" [gridOptions]="gridOptions2" >
                  </ag-grid-angular>
                </div>
              </div>
              <div class="col-sm-1 px-0">
                <div class="float-left">
                <button type="button" class="btn btn-primary btn-sm float-right ml-1" (click)="deleteTickers()">
                  <i class="fas fa-minus"></i> Delete
                </button>
                </div>
              </div>
              </div>
              <div class="mt-0 mb-3"></div>
              <div class="form-group row">
                <label for="RebalanceFrequency" class="col-sm-2 col-form-label">Rebalance Frequency</label>
                <div class="col-sm-6">
                  <select #RebFreq class="form-control bold" id="rebalancefrequency"
                  [(ngModel)]="rebalancefrequency" formControlName="rebalancefrequency">
                  <option value='Monthly'>Monthly</option>
                  <option value='Quartely'>Quarterly</option>
                  <option value='Annually'>Annually</option>
                  </select> 
                </div>
              </div>
              <div class="form-group row">
                <label for="Begin_date" class="col-sm-2 col-form-label">Begin Date</label>
                <div class="col-sm-6">
                  <input type="text" class="form-control bold" bsDatepicker [ngModel] = "begindate"
                  (bsValueChange)="onBeginDateChange($event)" formControlName="begindate"
                  value="{{ begindate | date:'MM/dd/yyyy' }}"
                  [bsConfig]="{ containerClass: 'theme-default' }"
                  id="begindate">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-0">
                  <button type="button" class="btn btn-primary btn-sm" (click)="saveBtnClickEvent()" [disabled]="invalidDataVal"> <i class="far fa-save"></i> Save</button>
                </div>
                <div class="form-group col-md-0">
                  <button type="button" class="btn btn-primary btn-sm" (click)="cancelBtnClickEvent()"> <i class="far fa-times"></i> Cancel</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>