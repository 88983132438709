export class LongevityPlanSummary {
    constructor( 
        public TotalNoOfOrganizations?: Number,
        public TotalNoOfAgents?: Number,
        public TotalNoOfClients?: Number,
        public TotalNoOfPlans?: Number,
        public PlansRunningOutOfMoneyHierarchy?: PlanRunningOutOfMoneyHierarchy
    ) {}
}

export class PlanRunningOutOfMoneyHierarchy {     
    constructor( 
        public OrganizationHierarchyList?: Array<OrganizationHierarchy>
    ) {}
}

export class OrganizationHierarchy {     
    constructor( 
        public OrganizationID?: String,
        public OrganizationName?: String,
        public isCollapse?: boolean,
        public AgentHierarchyList?: Array<AgentHierarchy>
    ) {}
}

export class AgentHierarchy {     
    constructor( 
        public AgentID?: String,
        public AgentName?: String,
        public ClientHierarchyList?: Array<ClientHierarchy>
    ) {}
}

export class ClientHierarchy {     
    constructor( 
        public ClientID?: String,
        public ClientName?: String,
        public PlanHierarchyList?: Array<PlanHierarchy>
    ) {}
}

export class PlanHierarchy {     
    constructor( 
        public ChoiceID?: String,
        public PlanName?: String,
        public AssetHierarchyList?: Array<AssetHierarchy>
    ) {}
}

export class AssetHierarchy {     
    constructor( 
        public IFAID?: String,
        public AssetName?: String
    ) {}
}
