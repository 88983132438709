import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../util/localstorage.service'; 
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Agent } from '../models/agent.model';
import { Ftastrategy, FTAStrategyDetail } from '../models/ftastrategy.model';
import { ClientInfoDataService } from './../appComp/client-info/client-info-data.service';
import { AlertService } from '../util/alert/alert.service';
import { CommonFunctions } from '../util/common-functions';
import { GridOptions } from 'ag-grid-community'
import { CustomHeader} from '../util//customHeader'
import { FtastrategyDataService } from './ftastrategy-data.service';
import { BsModalService,BsModalRef}   from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { Constants } from '../util/constants';
  
@Component({
  selector: 'app-ftastrategy',
  templateUrl: './ftastrategy.component.html',
  styleUrls: ['./ftastrategy.component.scss']
})

export class FTAStrategyComponent implements OnInit {
  
  public paddingtop: number = 60;
  public selectedChoiceID: string = "";
  public id: any
  public identity: any
  public name:any
  public disclosure:any
  public commondisclosure:any
  public createdby:any
  public createdon:any
  public begindate:any
  public splitdate:any
  public separationname:any
  public file:any
  public fileToUpload: FormData;
  public loggedAgent: Agent 
  public updateMode :boolean
  public copyMode :boolean
  public deleteMode: boolean
  public activeTabName :any 
  public iSForm: FormGroup;
  private hasSpouse:boolean
  public FTAStrategy:Ftastrategy
  private gridApi: any;
  private gridApi2: any;
  private gridColumnApi: any;
  private gridColumnApi2: any;

  public columnDefs: any;
  public columnDefs2: any;
  public rowData: any;	
  public rowData2: any;	
  public gridOptions : GridOptions 
  public gridOptions2 : GridOptions 
  public currentSelectedColumn;
  public strategyDataValueList = []
  public invalidDataVal = false
  public isShowDashboard: boolean = false
  private commonFunctions: CommonFunctions = new CommonFunctions
  orderID :number = 0  

  @ViewChild('editFTAStrategyDetails') editFTAStrategyDetails: TemplateRef<any>
  @ViewChild('editCommonDisclosure') editCommonDisclosure: TemplateRef<any>
  public ModalRef : BsModalRef 

  constructor( private router: Router,
    private formBuilder: FormBuilder, private alertService: AlertService, private ftaStrategiesService: FtastrategyDataService,
    private bsModalService :BsModalService) { 

      this.loggedAgent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent;

      this.gridOptions = { 
        components: {
          agColumnHeader: CustomHeader
        },
        defaultColDef: { 
          headerComponentParams: {
            setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
            getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          } 
        } 
      };

      this.columnDefs = [
        { 
          headerName: 'ID', 
          field: 'Id',
          hide: true
          //sortable: true, 
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'ID', 
          field: 'Identity',
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Name', 
          field: 'Name',
          cellStyle: {textAlign: "left"} 
          // sortable: true, 
          // width: 125,
          // filter: true,
          // cellClass: "rag-blue"
        },
        { 
          headerName: 'Begin Date', 
          field: 'BeginDate',
          cellStyle: {textAlign: "left"}//,  
          // sortable: true, 
          // filter: true,
          // cellClass: "rag-yellow"
        } ,
        { 
          headerName: 'Split Date', 
          field: 'SplitDate',
          cellStyle: {textAlign: "left"}//,  
          // sortable: true, 
          // filter: true,
          // cellClass: "rag-yellow"
        } ,
        { 
          headerName: 'Separation Name', 
          field: 'SeparationName',
          cellStyle: {textAlign: "left"}//,  
          // sortable: true, 
          // filter: true,
          // cellClass: "rag-yellow"
        } ,
        { 
          headerName: 'Created By', 
          field: 'CreatedBy',
          hide: true,  
         // sortable: true, 
         // filter: true,
         // cellClass: "rag-yellow"
        },
        { 
          headerName: 'Created On', 
          field: 'CreatedOn',
          hide: true,  
         // sortable: true, 
         // filter: true,
         // cellClass: "rag-yellow"
        }
      ];



      this.gridOptions2 = { 
        components: {
          agColumnHeader: CustomHeader
        },
        defaultColDef: { 
          headerComponentParams: {
            setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
            getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          } 
        } 
      };

      this.columnDefs2 = [
        { 
          headerName: 'FTAStrategyDetailID', 
          field: 'FTAStrategyDetailID',
          hide: true,
          //sortable: true, 
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'FTAStrategyID', 
          field: 'FTAStrategyID',
          hide: true
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Data Date', 
          field: 'DataDate',
          cellStyle: {textAlign: "left"}
          // sortable: true, 
          //width: 50,
          // filter: true,
          // cellClass: "rag-blue"
        },
        { 
          headerName: 'Data Value', 
          field: 'DataValue',
          cellStyle: {textAlign: "right"},
          editable: true,
          //width: 50,
          // sortable: true, 
          // filter: true,
          // cellClass: "rag-yellow"
        }
      ];
    }

  ngOnInit() {

   this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
   if(this.loggedAgent.UserRoleID== Constants.UserRoleID.Superuser)
    this.isShowDashboard = true

   this.iSForm = this.formBuilder.group({
			identity: new FormControl(null) ,
      name: new FormControl(null),
      begindate: new FormControl(null),
      splitdate: new FormControl(null),
      separationname: new FormControl(null),
      disclosure: new FormControl(null),
      commondisclosure: new FormControl(null),
      file: new FormControl(null)
    } );   
 
    this.loadData()
  }

  loadData()
	{
    this.ftaStrategiesService.getftastrategies(this.loggedAgent).subscribe(data => this.getData(data))
	}

  public getData(data: any) {
    let strategies: Array<any>=[] 
    data.forEach((element: any) => {
      //let client: Client = element
      let Ftastrategy: Ftastrategy = element
      Ftastrategy.FTAStrategyID = element.FTAStrategyID
      Ftastrategy.FTAStrategyIdentity = element.FTAStrategyIdentity
      Ftastrategy.FTAStrategyName = element.FTAStrategyName
      var datePipe = new DatePipe('en-US');
      Ftastrategy.BeginDate = datePipe.transform(element.BeginDate, 'MM/dd/yyyy').toString()
      if(new Date(element.SplitDate).toLocaleDateString() != new Date('0001-01-01T00:00:00Z').toLocaleDateString()){
        Ftastrategy.SplitDate = datePipe.transform(element.SplitDate, 'MM/dd/yyyy').toString()
      }else{
        Ftastrategy.SplitDate = ""
      }
      Ftastrategy.SeparationName = element.SeparationName
      Ftastrategy.Disclosure = element.Disclosure
      Ftastrategy.CreatedBy = element.CreatedBy
      Ftastrategy.CreatedOn = element.CreatedOn

      strategies.push({Id: Ftastrategy.FTAStrategyID, Identity: Ftastrategy.FTAStrategyIdentity ,Name: Ftastrategy.FTAStrategyName,
      BeginDate: Ftastrategy.BeginDate,SplitDate: Ftastrategy.SplitDate,SeparationName: Ftastrategy.SeparationName, Disclosure: Ftastrategy.Disclosure, CreatedBy: Ftastrategy.CreatedBy,
      CreatedOn: Ftastrategy.CreatedOn})
  
    setTimeout(()=> {
      this.gridApi.forEachNode(node => {
        if ( node.rowIndex  === 0 ){ 
          node.setSelected(true)
          this.id = node.data.Id
          this.identity = node.data.Identity
          this.name = node.data.Name
          this.begindate = node.data.BeginDate
          this.splitdate = node.data.SplitDate
          this.separationname = node.data.SeparationName
          this.disclosure = node.data.Disclosure
          this.createdby = node.data.CreatedBy
          this.createdon = node.data.CreatedOn
          this.updateMode = true
          this.deleteMode = false
          this.copyMode = false
        } 
      }); 
    
    }, 50); 

    });
    this.rowData = strategies;
    this.intializeFTAStrategies();
    return this.rowData;
  }

  public getStrategyDetails(data: any) {
    let strategyDetails: Array<any>=[] 
    data.forEach((element: any) => {
      let FTAStrategyDetail: FTAStrategyDetail = element
      FTAStrategyDetail.FTAStrategyDetailID = element.FTAStrategyDetailID
      FTAStrategyDetail.FTAStrategyID = element.FTAStrategyID
      var datePipe = new DatePipe('en-US');
      FTAStrategyDetail.DataDate = datePipe.transform(element.DataDate, 'MM/dd/yyyy');
      FTAStrategyDetail.DataValue = element.DataValue

      strategyDetails.push({FTAStrategyDetailID: FTAStrategyDetail.FTAStrategyDetailID, FTAStrategyID: FTAStrategyDetail.FTAStrategyID ,
        DataDate: FTAStrategyDetail.DataDate, DataValue: FTAStrategyDetail.DataValue})
  
    if(strategyDetails.length != 0){
      setTimeout(()=> {
        this.gridApi2.forEachNode(node => {
          if ( node.rowIndex  === 0 ){ 
            node.setSelected(true)
          } 
        }); 
      
      }, 50); 
    }

    });
    
    this.rowData2 = strategyDetails;
    return this.rowData2;
  }

  intializeFTAStrategies()
  {
    this.id = ""
    this.identity = ""
    this.disclosure = ""
    this.begindate = ""
    this.splitdate = "" 
    this.separationname = "" 
    this.name = ""  
    this.createdby = "" 
    this.createdon = "" 

    this.updateMode = false 
  }

  onGridReady(params: any) { 
		this.gridApi  = params.api;
		this.gridColumnApi = params.columnApi; 
		setTimeout(() => { 
        this.loadData()     
      	//params.api.sizeColumnsToFit();
    }, 50);

  }

  onGridReady2(params: any) { 
		this.gridApi2  = params.api;
		this.gridColumnApi2 = params.columnApi; 
  }

  onBeginDateChange(event){
    this.begindate = event
  }

  onSplitDateChange(event){
    this.splitdate = event
  }

  getCurrentSelectedColumn() {
    return this.currentSelectedColumn;
    }
    
  setCurrentSelectedColumn(colId) {
    this.currentSelectedColumn = colId;
    }


  loadFTAStrategiesStaticDataForNew()
  {   
    this.intializeFTAStrategies()   
  }

  gridSizeChanged(params: any) {
		params.api.sizeColumnsToFit();
  }
  
  public onCellClicked(event: any) {
    this.invalidDataVal = false
    this.updateMode = true; 
    this.copyMode = true; 
    this.deleteMode = false; 
    event.node.setSelected(true);
    var selectedStrategyID : string = event.data.Id
    var selectedStrategyIdentity : string = event.data.Identity
    var selectedStrategyName : string = event.data.Name
    var selectedStrategyBeginDate : string = event.data.BeginDate
    var selectedStrategySplitDate : string = event.data.SplitDate
    var selectedStrategySeparationName : string = event.data.SeparationName
    var selectedStrategyDisclosure : string = event.data.Disclosure
    var selectedStrategyCreatedBy : string = event.data.CreatedBy
    var selectedStrategyCreatedOn : string = event.data.CreatedOn
    this.loadStrategy(selectedStrategyID,selectedStrategyIdentity,selectedStrategyName, selectedStrategyBeginDate,selectedStrategySplitDate,
      selectedStrategySeparationName, selectedStrategyDisclosure,selectedStrategyCreatedBy,selectedStrategyCreatedOn) 
  }

  loadStrategy(selectedStrategyID:string,selectedStrategyIdentity:string,selectedStrategyName:string,selectedStrategyBeginDate:string,selectedStrategySplitDate:string,
    selectedStrategySeparationName:string, selectedStrategyDisclosure:string,selectedStrategyCreatedBy:string,selectedStrategyCreatedOn:string)
  {
    this.id = selectedStrategyID;
    this.identity = selectedStrategyIdentity;
    this.name = selectedStrategyName;
    this.begindate = selectedStrategyBeginDate
    this.splitdate = selectedStrategySplitDate
    this.separationname = selectedStrategySeparationName
    this.disclosure = selectedStrategyDisclosure;
    this.createdby = selectedStrategyCreatedBy;
    this.createdon = selectedStrategyCreatedOn;
  }

  searchinarray(nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
     
        if (myArray[i].value === nameKey) {
          //console.log(myArray[i].value)
            return myArray[i].value;
        }
    }
}

saveBtnClickEvent()
{  
  if (this.validateFTAStrategy()){
    this.FTAStrategy = new Ftastrategy()
    this.FTAStrategy.FTAStrategyIdentity = this.identity
    this.FTAStrategy.FTAStrategyName = this.name
    this.FTAStrategy.BeginDate =  new Date(this.begindate).toLocaleDateString() 
    if(this.splitdate == null){
      this.FTAStrategy.SplitDate = ""
    } else{
      this.FTAStrategy.SplitDate =  new Date(this.splitdate).toLocaleDateString() 
    }
    this.FTAStrategy.SeparationName = this.separationname
    this.FTAStrategy.Disclosure = this.disclosure
    if(this.updateMode){
      this.FTAStrategy.FTAStrategyID =  this.id
      this.FTAStrategy.CreatedBy = this.createdby
      this.FTAStrategy.CreatedOn = this.createdon
      //if another row is clicked after adding a new row or copied a row
      if(this.id == ""){
        this.FTAStrategy.FTAStrategyID =  this.commonFunctions.getnewguid() 
        this.FTAStrategy.CreatedBy = this.loggedAgent.AgentID
        this.FTAStrategy.CreatedOn = new Date()
      }
    } else{
      this.FTAStrategy.FTAStrategyID =  this.commonFunctions.getnewguid() 
      this.FTAStrategy.CreatedBy = this.loggedAgent.AgentID
      this.FTAStrategy.CreatedOn = new Date()
    }
  
  if(this.deleteMode)
  {
    this.FTAStrategy.bDeleted = true
    this.FTAStrategy.DeletedBy = this.loggedAgent.AgentID
    this.FTAStrategy.DeletedOn = new Date()
  } else{
    this.FTAStrategy.bDeleted = false
  }
  this.ftaStrategiesService.saveStrategy(this.FTAStrategy, this.loggedAgent).subscribe(date =>
    {
      this.alertService.clear()
      if(this.updateMode && !this.deleteMode){
        this.alertService.success('Successfully updated')
      } else if(this.deleteMode){
        this.alertService.success('Successfully deleted')
      } else{
        this.alertService.success('Successfully saved')
      }
      
      this.loadData()
    },
    error => { 
      this.alertService.clear()
      this.alertService.error('Error occured while saving data')
      this.loadData()
     });
    }
}

cancelBtnClickEvent()
{
  this.alertService.clear()
  this.invalidDataVal = false
  this.identity = ""
  this.id = ""
  this.loadFTAStrategiesStaticDataForNew()
  this.gridOptions.api.deselectAll();
}

validateFTAStrategy():boolean
  { 
    this.alertService.clear()
    if ((this.iSForm.controls.identity.value == ""  || 
      this.iSForm.controls.identity.value == null))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Id')
      return false
    } 

    let invalidID
    this.gridApi.forEachNode(node => {
      if ((node.data.Identity == this.iSForm.controls.identity.value && this.id == "")
      || (node.data.Identity ==  this.iSForm.controls.identity.value && this.id != node.data.Id)){ 
          invalidID = true
      } 
    }); 
    if (invalidID)
    { 
      this.alertService.clear()
      this.alertService.error('Id already exist')
      return false
    } 

    if ((this.iSForm.controls.name.value == ""  || 
          this.iSForm.controls.name.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Name')
      return false
    }     

    if (this.iSForm.controls.begindate.value == ""  || 
    this.iSForm.controls.begindate.value == null  || 
    new Date(this.iSForm.controls.begindate.value).toLocaleDateString()  == new Date('0001-01-01T00:00:00Z').toLocaleDateString())
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Begin Date')
      return false
    }
    return true
  }  
  
  exitBtnClickEvent()
  {
    localStorage.setItem(LocalStorage.SELECTED_CLIENTID, "");
    localStorage.removeItem(LocalStorage.CURRENT_CLIENT);
    this.router.navigateByUrl('landing'); 
  }

  //load the superuser dashboard page
	public navigateSuperUserDashboard()
	{
		this.router.navigateByUrl('superuserdashboard'); 
	}

  newBtnClickEvent()
  { 
    this.invalidDataVal = false
    this.alertService.clear()
    if(this.rowData.length != 0){
      let node
      node = this.rowData[this.rowData.length - 1]
      if(node.Id ==""){
        this.rowData.splice(this.rowData.indexOf(node),1)
        this.gridOptions.api.setRowData(this.rowData);
      }
    }   
    this.rowData.push({Id: "", Identity: "" ,Name: "",
      BeginDate: "",SplitDate: "",SeparationName: "", Disclosure: "", CreatedBy: "", CreatedOn: ""})
      this.gridOptions.api.setRowData(this.rowData);
    setTimeout(()=> { 
      this.gridApi.forEachNode(node => {
        node.setSelected(true) 
      });    
    }, 50);
    this.loadFTAStrategiesStaticDataForNew()
    this.deleteMode = false   
    this.updateMode = false 
    this.rowData2 = []
    if(this.rowData2.length != 0){
      this.gridOptions2.api.setRowData(this.rowData2);
    }
  }

  copyBtnClickEvent()
  {
    this.invalidDataVal = false
    this.alertService.clear()
    if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return
    if(this.rowData.length != 0){
      let node
      node = this.rowData[this.rowData.length - 1]
      if(node.Id ==""){
        this.rowData.splice(this.rowData.indexOf(node),1)
        this.gridOptions.api.setRowData(this.rowData);
      }
    }
    this.rowData.push({Id: "" ,Identity: "" ,Name: this.name,
      BeginDate: this.begindate,SplitDate: this.splitdate, SeparationName: this.separationname, Disclosure: this.disclosure})
    this.gridOptions.api.setRowData(this.rowData);
    setTimeout(()=> { 
      this.gridApi.forEachNode(node => {
          node.setSelected(true)   
      }); 
    }, 50);
    this.updateMode = false
    this.identity = ""
    this.id = ""
    this.rowData2 = []
    if(this.rowData2.length != 0){
      this.gridOptions2.api.setRowData(this.rowData2);
    }
  }

  deleteBtnClickEvent()
  {     
    this.invalidDataVal = false
    this.alertService.clear()
    if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return    
    if(this.rowData.length != 0){
      let node
      node = this.rowData[this.rowData.length - 1]
      if(node.Id ==""){
        this.rowData.splice(this.rowData.indexOf(node),1)
        this.gridOptions.api.setRowData(this.rowData);
        this.loadData()
      }
    }
    if (confirm("Are you sure you want to delete selected record?")) { 
      if(this.id != "" && this.id != undefined){
        this.ftaStrategiesService.getblendbystrategyid(this.id, this.loggedAgent).subscribe(data => {
          if (data != true){
            this.deleteMode = true
            this.updateMode = true
            this.saveBtnClickEvent()
            this.rowData2 = []
            if(this.rowData2.length != 0){
              this.gridOptions2.api.setRowData(this.rowData2);
            }
          } else{
            this.alertService.clear()
            this.alertService.error('Cannot delete selected strategy. It is used for blends')
          }
        });
      }
    }
  }

  editBtnClickEvent()
  {
    this.alertService.clear()
    if(this.id != "" && this.id != undefined){
      this.ftaStrategiesService.getftastrategydetailsbyid(this.id, this.loggedAgent).subscribe(data => {
        if(data.length == 0){
          this.alertService.info('No data available')
        } else{
          this.ModalRef = this.bsModalService.show(this.editFTAStrategyDetails)
          this.getStrategyDetails(data)
        } 
      });
        
    } else{
      this.alertService.info('No data available')
    }
  }

  editCommonDisclosureBtn(){
    this.alertService.clear()
    this.ftaStrategiesService.getsettings(this.loggedAgent).subscribe(data => {
      this.commondisclosure = data.CommonStrategyDisclosure
      this.ModalRef = this.bsModalService.show(this.editCommonDisclosure)
    },
      error => { 
        this.alertService.error('Error occured')
    });
    
  }

  onCellValueChanged(event: any)
  {
    this.alertService.clear()
    if(event.newValue.match(/^[+\-]?(\.\d+|\d+(\.\d+)?)$/)){   
    this.strategyDataValueList.forEach(element => {
      if ( element.FTAStrategyDetailID == event.data.FTAStrategyDetailID){ 
          this.strategyDataValueList.splice(this.strategyDataValueList.indexOf(element),1)
      } 
    });
    if(event.oldValue != event.newValue){     
      let FTAStrategyDetailList: FTAStrategyDetail = event.data 
      FTAStrategyDetailList.FTAStrategyDetailID = event.data.FTAStrategyDetailID
      FTAStrategyDetailList.FTAStrategyID = event.data.FTAStrategyID
      FTAStrategyDetailList.DataDate = new Date(event.data.DataDate).toLocaleDateString()
      FTAStrategyDetailList.DataValue = event.data.DataValue

      this.strategyDataValueList.push(FTAStrategyDetailList)
      this.invalidDataVal = false
    }    
    } else{
      this.alertService.clear()
      this.invalidDataVal = true
      this.alertService.error('Invalid value')
    }
    return this.strategyDataValueList
  }

  saveStrategyDetails()
  {
    this.saveBtnClickEvent()
    this.ftaStrategiesService.saveStrategyDetails(this.strategyDataValueList, this.loggedAgent).subscribe(data =>
      {
          this.alertService.clear()
          this.alertService.success('Successfully saved')
          this.cancelModal()
          this.loadData()
          
      },
      error => { 
        this.alertService.clear()
        this.alertService.error('Error occured while saving data')
       });
  }

  saveCommonDisclosure(){
    const formData: FormData = new FormData();
    formData.append("CommonStrategyDisclosure", this.commondisclosure);
    this.ftaStrategiesService.updateCommonDisclosure(formData, this.loggedAgent).subscribe(data =>
      {
          this.alertService.clear()
          this.alertService.success('Successfully updated')
          this.cancelDisclosureModal()         
      },
      error => { 
        this.alertService.clear()
        this.alertService.error('Error occured while saving data')
       });
  }

  cancelModal(){
    this.alertService.clear()
    this.invalidDataVal = false
    this.ModalRef.hide()
    this.rowData2 = []
    this.gridOptions2.api.setRowData(this.rowData2);
  }

  cancelDisclosureModal(){
    this.alertService.clear()
    this.ModalRef.hide()
  }

  handleFileInput(event: any) {
	this.fileToUpload = null
    let data: any
    data = event.target.files[0];
	if(data != null && data != undefined){
		const formData: FormData = new FormData();
		formData.append("fileUpload", data);
		formData.append("agentid", this.loggedAgent.AgentID);
		this.fileToUpload = formData
	}
  }
  
  uploadFileToActivity() {
  this.alertService.clear();
	if(this.fileToUpload != null && this.fileToUpload != undefined){
		this.alertService.info('Please wait.. file is uploading')
		this.ftaStrategiesService.postFile(this.fileToUpload,this.loggedAgent).subscribe(data => {
      if(data.Status == "202"){   
			this.alertService.clear();
			(<HTMLInputElement>document.getElementById('file')).value = "";
      this.alertService.success('Successfully uploaded')
      this.fileToUpload = null
			this.loadData()
		  }
		  }, error => {
        this.alertService.clear();
        if(error.error.ErrorMessage == Constants.FieldError.InvalidFieldDataType){
          this.alertService.error('File does not have a date column. Please add the date column and try again')
        } else{
          this.alertService.error('Error occured while saving data')
        }
		  });
	} else{
		this.alertService.clear();
		this.alertService.error('No file is selected')
	}
  }
  
}
function sortArray(prop){
  return function(a,b){
     if (a[prop] > b[prop]){
         return 1;
     } else if(a[prop] < b[prop]){
         return -1;
     }
     return 0;
  }
}