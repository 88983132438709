<div class="navbar navbar-light">
	<div class="navbar-brand">
		<span *ngIf="optionType.isHamburger" class="slide-bar-span d-inline-block align-top mr-2" (click)="showHideSlideBar()">
			<i class="fas fa-bars fa-fw slide-bar-i" aria-hidden="true"></i>
		</span>
		<img src="../../assets/images/app_logo.png" width="auto" height="30" class="d-inline-block align-top ml-1 mr-1"
			alt="">
		<!-- <div class="d-inline-block align-top vertical-line ml-1 mr-1"></div> -->
		<!-- <h5 class="d-inline-block align-top ml-1 mr-1">{{projectName}}</h5> -->
	</div>
	<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
		aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
		(click)="showToggleMenu()">
		<i class="fa fa-ellipsis-v"></i>
	</button>
	<div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{'toggle-border-top': isToggleMenu}">
		<ul class="navbar-nav mr-auto">
		</ul>
		<div  *ngIf="optionType.isTopRightMenu" class="form-inline my-2 my-lg-0 float-right">
			<!-- <button *ngIf="!minimalOption" class="btn notification-btn btn-sm my-2 my-sm-0 mr-1 ml-2 border-0"
				(click)="isShowSearch = !isShowSearch">
				<i class="fal fa-search fa-2x"></i>
			</button> -->
			<!-- <div class="my-2 my-sm-0 mr-1 ml-2">
				<span class="btn notification-btn btn-sm my-2 my-sm-0 mr-1 ml-2 border-0"
					(click)="menuContentPO.toggle()">
					<img class="mx-auto" height="30" width="auto" src="../../assets/icons/menu_icon.svg" alt=""
						[popover]="menuContent" containerClass="menu-custom" placement="bottom"
						#menuContentPO="bs-popover" container="body" [outsideClick]="true" triggers="">
				</span>
			</div> -->
			<!-- <div class="my-2 my-sm-0 mr-1 ml-2">
				<span class="btn notification-btn btn-sm my-2 my-sm-0 mr-1 ml-2 border-0"
					(click)="showNotification(); isOpen = !isOpen" [popover]="notificationContent" [isOpen]="isOpen"
					containerClass="notification-custom" placement="bottom" #notificationPO="bs-popover"
					container="body" [outsideClick]="true" triggers="">
					<i class="fal fa-bell fa-lg" aria-hidden="true"></i>
					<span class="badge badge-danger badge-pill badge-notify animated faster"
						[ngClass]="{'fadeIn': notificationCount>0, 'fadeOut': notificationCount==0 }">{{notificationCount}}</span>
				</span>
			</div> -->
			<div *ngIf="showAllocationDropdown" class="dropdown"> 
				<button class="btn btn btn-sm dropdown-toggle black-dd-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					Allocation 
				</button>
				<div class="dropdown-menu dropdown-menu-right black-dd" aria-labelledby="dropdownMenuButton">
					<a  class="dropdown-item" (click)="AllocationRequest()">Allocation Request </a>
					<a  class="dropdown-item" (click)="DepositWithdrawCash()">Deposit/Withdraw Cash</a>
				</div>
			</div>
			 
			&nbsp;&nbsp;
			<div *ngIf="showProductsDropdown" class="dropdown">
					<button class="btn btn btn-sm dropdown-toggle black-dd-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					  Products 
					</button>
					<div class="dropdown-menu dropdown-menu-right black-dd" aria-labelledby="dropdownMenuButton">
					  <a *ngIf="isAdmin" class="dropdown-item" (click)="FTAStrategies()">FTA Strategies</a>
					  <a *ngIf="isAdmin" class="dropdown-item" (click)="Blends()">Site Blends</a>
					  <a  class="dropdown-item" (click)="MyBlends()">My Blends</a>
					  <a *ngIf="isAdmin" class="dropdown-item" (click)="IndexBlends()">Site Index Blends</a>
					  <a  class="dropdown-item" (click)="MyIndexBlends()">My Index Blends</a>
					  <a *ngIf="isAdmin" class="dropdown-item" (click)="TickerBlends()">Site Ticker Blends</a>
					  <a  class="dropdown-item" (click)="MyTickerBlends()">My Ticker Blends</a>
					</div>
			</div>
			&nbsp;&nbsp;
			<div *ngIf="showReportsDropdown" class="dropdown">
				<button class="btn btn btn-sm dropdown-toggle black-dd-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				  Reports 
				</button>
				<div class="dropdown-menu dropdown-menu-right black-dd" aria-labelledby="dropdownMenuButton">
				  <a  class="dropdown-item" (click)="RiskGraphReports()">Saved Risk Graphs</a>
				  <a  class="dropdown-item" (click)="InvestmentReports()">Saved Investment Reports</a>
				  <a  class="dropdown-item" (click)="ArchivedReports()">Archived Old Investment Reports</a>
				</div>
			</div> 
		</div>

		<button *ngIf="showTrainingResources" tooltip="Training Resources" class="btn btn-outline-header-primary btn-sm btn-circle my-2 my-sm-0 mr-1 ml-2" (click)="openModal()">
		<span><i class="fas fa-video" aria-hidden="true"></i></span>
		</button>

		<ng-template #rightSideBar> 
			<div class="modal-header">
				<h3 class="modal-title light-header bold" style="font-size: 20px">Training Resources</h3>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelModal()">
					<span aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="container">
					<div class="row p-3">    
						<div class="col-md-12 ">
							<div class="card">
								<div class="card-content p-2">
									<span class="card-title"><h6>Longevity - Client Information</h6></span>                    
								</div>
								<div class="card-image">
									<div class="embed-responsive embed-responsive-16by9">
									<iframe width="560" height="315" src="https://player.vimeo.com/video/367742421" frameborder="0" allowfullscreen></iframe>
									</div>	
								</div>
							</div>
						</div>
					</div>

					<div class="row p-3">    
						<div class="col-md-12 ">
							<div class="card">
								<div class="card-content p-2">
									<span class="card-title"><h6>Longevity - Income Source</h6></span>                    
								</div>
								<div class="card-image">
									<div class="embed-responsive embed-responsive-16by9">
									<iframe width="560" height="315" src="https://player.vimeo.com/video/367742473" frameborder="0" allowfullscreen></iframe>
									</div>	
								</div>
							</div>
						</div>
					</div>

					<div class="row p-3">    
						<div class="col-md-12 ">
							<div class="card">
								<div class="card-content p-2">
									<span class="card-title"><h6>Longevity - Income from Assets</h6></span>                    
								</div>
								<div class="card-image">
									<div class="embed-responsive embed-responsive-16by9">
									<iframe width="560" height="315" src="https://player.vimeo.com/video/367742548" frameborder="0" allowfullscreen></iframe>
									</div>	
								</div>
							</div>
						</div>
					</div>

					<div class="row p-3">    
						<div class="col-md-12 ">
							<div class="card">
								<div class="card-content p-2">
									<span class="card-title"><h6>Longevity - Inflation Offset</h6></span>                    
								</div>
								<div class="card-image">
									<div class="embed-responsive embed-responsive-16by9">
									<iframe width="560" height="315" src="https://player.vimeo.com/video/367742652" frameborder="0" allowfullscreen></iframe>
									</div>	
								</div>
							</div>
						</div>
					</div>

					<div class="row p-3">    
						<div class="col-md-12 ">
							<div class="card">
								<div class="card-content p-2">
									<span class="card-title"><h6>Longevity - He/She Dies Tab</h6></span>                    
								</div>
								<div class="card-image">
									<div class="embed-responsive embed-responsive-16by9">
									<iframe width="560" height="315" src="https://player.vimeo.com/video/367742762" frameborder="0" allowfullscreen></iframe>
									</div>	
								</div>
							</div>
						</div>
					</div>

					<div class="row p-3">    
						<div class="col-md-12 ">
							<div class="card">
								<div class="card-content p-2">
									<span class="card-title"><h6>Longevity - Spend Down Protection</h6></span>                    
								</div>
								<div class="card-image">
									<div class="embed-responsive embed-responsive-16by9">
									<iframe width="560" height="315" src="https://player.vimeo.com/video/367742832" frameborder="0" allowfullscreen></iframe>
									</div>	
								</div>
							</div>
						</div>
					</div>

					<div class="row p-3">    
						<div class="col-md-12 ">
							<div class="card">
								<div class="card-content p-2">
									<span class="card-title"><h6>Longevity - Showing it to a Prospect</h6></span>                    
								</div>
								<div class="card-image">
									<div class="embed-responsive embed-responsive-16by9">
									<iframe width="560" height="315" src="https://player.vimeo.com/video/367742937" frameborder="0" allowfullscreen></iframe>
									</div>	
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-template>

		<button class="btn btn-outline-header-primary btn-sm btn-circle my-2 my-sm-0 mr-1 ml-2"
				(click)="userSettingsPO.toggle()">
				<span [popover]="userSettingsContent" containerClass="user-setting" placement="bottom"
					#userSettingsPO="bs-popover" triggers="" container="body" [outsideClick]="true">{{loggedInUser.firstName | slice:0:1}}{{loggedInUser.lastName |
					slice:0:1}}</span>
		</button>

	</div>
</div>

<ng-template #userSettingsContent>
	<div class="card col-sm-12 rounded-0 p-0 border-top-0 border-left-0 border-right-0 mt-2 mb-1">
		<div class="card-body row p-0">
			<div class="float-left mx-3">
				<span class="btn btn-outline-header-primary btn-circle btn-sm float-left mt-1">
					{{loggedInUser.firstName | slice:0:1}}{{loggedInUser.lastName | slice:0:1}}</span>
			</div>
			<div class="float-left col">
				<div class="row">
					<div class="col-sm-12 pl-0">
						<p class="float-left mb-0"> {{loggedInUser.firstName + ' ' + loggedInUser.lastName}}</p>
					</div>
					<!-- <div (click)="userSettingsPO.toggle()">
						<div class="col-sm-12 pl-0 mt-2" (click)="openModal(userProfilerTemplate, 'lg')">
							<small class="float-left mb-2 text-muted">View Profile</small>
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</div>
	<!-- <div *ngIf="!minimalOption" class="row my-1 mx-0 py-1 px-3 user-settings-item" (click)="changeProject()">
		<div class="float-left mx-2 text-muted">
			<i class="fa fa-tasks" aria-hidden="true"></i>
		</div>
		<div class="float-left mx-2">
			<p class="m-0 ">{{ 'HEADER.MENU_CHANGE_PROJECT' | translate }}</p>
		</div>
	</div> -->
	<!-- <div *ngIf="!minimalOption" class="row my-1 mx-0 py-1 px-3 user-settings-item"
		(click)="setasDefault(defaultBtnIcon)">
		<div class="float-left mx-2 text-muted">
			<i class="fas " aria-hidden="true"
				[ngClass]="{'fa-check-circle': defaultBtnIcon, 'fa-times-circle': !defaultBtnIcon}"></i>
		</div>
		<div class="float-left mx-2">
			<p class="m-0 ">{{ setDefaultBtnText }}</p>
		</div>
	</div> -->
	<!-- <hr *ngIf="!minimalOption" class="m-1" /> -->
	<div class="row my-1 mx-0 py-1 px-3 user-settings-item" (click)="signOutBtnClickEvent()">
		<div class="float-left mx-2 text-muted">
			<i class="fa fa-sign-out" aria-hidden="true"></i>
		</div>
		<div class="float-left mx-2">
			<p class="m-0 ">Log Out</p>
		</div>
	</div>
	<hr class="m-1" />
	<div class="row my-1 mx-0 py-1 px-3 user-settings-item">
		<div class="float-left mx-2 text-muted">
			<i class="fa fa-info-circle" aria-hidden="true"></i>
		</div>
		<div class="float-left mx-2">
			<p class="m-0 ">App Version - {{appVersion}}</p>
		</div>
	</div>
	<hr class="m-1" />
	<div class="row my-1 mx-0 py-1 px-3 user-settings-item">
		<div class="float-left mx-2 text-muted">
			<i class="fa fa-info-circle" aria-hidden="true"></i>
		</div>
		<div class="float-left mx-2">
			<p class="m-0 ">Service Version - {{serviceVersion}}</p>
		</div>
	</div>
	<!-- <div class="row my-1 mx-0 py-1 px-3 user-settings-item">
		<div class="float-left mx-2 text-muted small">
			<i class="fa fa-info-circle" aria-hidden="true"></i>
		</div>
		<div class="float-left mx-2">
			<p class="m-0 small">{{ 'HEADER.MENU_SERVERVERSION' | translate }} - {{serverVersion}}</p>
		</div>
	</div> -->
</ng-template>