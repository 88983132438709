import { LocalStorage } from '../util/localstorage.service';
import { AlertService } from '../util/alert/alert.service';
import { Injectable } from '@angular/core';
import { BusinessCalculatorInput, Employee } from '../models/business-calculator-input.model';
import { CommonFunctions } from '../util/common-functions';

@Injectable({
	providedIn: 'root'
})
export class FP4FACommonFunctionsService {
        constructor(private alertService: AlertService){}
        
        public businesscalculatorinput: BusinessCalculatorInput 
        private commonFunctions: CommonFunctions = new CommonFunctions 
        
        public ValidateSaveBusinessPlan() {
                this.alertService.clear()
                localStorage.setItem(LocalStorage.HAS_FINANCIAL_PRODUCT_ERRORS, "0");
                localStorage.setItem(LocalStorage.HAS_REP_DATA_ERRORS, "0");
                localStorage.setItem(LocalStorage.HAS_COMPANY_CONFIG_ERRORS, "0");

                let noofyears = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_INPUT)).NoOfYears
                let businessplanname = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_INPUT)).BusinessPlanName
                let financialproductdetails = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_INPUT)).FinancialProducts.FinancialProductDetails
                let repdata = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_INPUT)).RepData
                let productsalesmix:number = 0
                let repdatavalidation:boolean = true
                let reppaypercentagevalidation:boolean = true
                financialproductdetails.forEach((e:any) => { 
                        productsalesmix = productsalesmix + parseFloat(e.ProductSalesMix)
                })
                if(repdata != null && repdata != [] && repdata != undefined && repdata.length != 0){
                        repdata.forEach((e:any) => { 
                                if((e.IsOwner == false && e.bAddedToEmployee == true) && (e.RepFirstName == null || e.RepFirstName == undefined || e.RepFirstName == ""
                                || e.RepLastName == null || e.RepLastName == undefined || e.RepLastName == "")){
                                        repdatavalidation = false
                                }
                                if(e.IsOwner == false && e.RepPayPercentage > 100){
                                        reppaypercentagevalidation = false
                                }
                        })
                }
                if( businessplanname == "" || businessplanname == undefined || businessplanname == null){
                        this.alertService.error('Invalid Business Plan Name')
                        localStorage.setItem(LocalStorage.HAS_COMPANY_CONFIG_ERRORS, "1");
                        return false
                } else if( noofyears == "0" || noofyears == undefined || noofyears == null){
                        this.alertService.error('Invalid No of Years')
                        localStorage.setItem(LocalStorage.HAS_COMPANY_CONFIG_ERRORS, "1");
                        return false
                } else if( financialproductdetails.length == 0 || financialproductdetails.length == undefined || financialproductdetails.length == null){
                        this.alertService.error('Financial products should contain at least one annuity')
                        localStorage.setItem(LocalStorage.HAS_FINANCIAL_PRODUCT_ERRORS, "1");
                        return false
                } else if( productsalesmix > 100 || productsalesmix < 100){
                        this.alertService.error('Product sales mix total should be 100%')
                        localStorage.setItem(LocalStorage.HAS_FINANCIAL_PRODUCT_ERRORS, "1");
                        return false
                } else if(!repdatavalidation){
                        this.alertService.error('Rep First Name, Rep Last Name fields are mandatory')
                        localStorage.setItem(LocalStorage.HAS_REP_DATA_ERRORS, "1");
                        return false
                } else if(!reppaypercentagevalidation){
                        this.alertService.error("Rep pay percentage should not exceed 100")
                        localStorage.setItem(LocalStorage.HAS_REP_DATA_ERRORS, "1");
                        return false
                } else{
                        return true
                }
                
        }

        //Add an employee record for all the new rep 2+ data
        public AddEmployees(){
                let selectedRepData;
                this.businesscalculatorinput = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_INPUT))
                this.businesscalculatorinput.RepData.forEach(element => {
                selectedRepData = element
                if(selectedRepData.IsOwner == false && selectedRepData.bAddedToEmployee == true){
                let employeetypes = JSON.parse(localStorage.getItem(LocalStorage.EMPLOYEE_TYPES));
                var salestypeid = employeetypes.find(x => x.EmployeeType === "Sales").EmployeeTypeID;
                let empdetail = this.businesscalculatorinput.EmployeeDetails.find( x=> x.EmployeeTypeID  == salestypeid) 
                
                //Create employee counts array
                var noofyears = this.businesscalculatorinput.NoOfYears.toString()
                let tempstartdate = new Date(this.businesscalculatorinput.StartDate)
                var startyear = tempstartdate.getFullYear().toString()
                var startmonth = "1"
                var endyear = (tempstartdate.getFullYear() + parseInt(noofyears)).toString()
                var endmonth = "12"

                if(selectedRepData.RepStartDate != null && selectedRepData.RepStartDate != undefined){
                        startyear = (new Date(selectedRepData.RepStartDate).getFullYear()).toString();
                        startmonth = (new Date(selectedRepData.RepStartDate).getMonth() + 1).toString();
                }

                if(selectedRepData.RepEndDate != null && selectedRepData.RepEndDate != undefined){
                        endyear = (new Date(selectedRepData.RepEndDate).getFullYear()).toString();
                        endmonth = (new Date(selectedRepData.RepEndDate).getMonth() + 1).toString();
                }

                var i
                var j
                for(i = parseInt(startyear); i < parseInt(endyear)+1; i++){
                        let currentstartmonth = 1
                        let currentendmonth = 12
                        if(i == parseInt(startyear)){currentstartmonth = parseInt(startmonth)}
                        if(i == parseInt(endyear)){currentendmonth = parseInt(endmonth)}
                        for(j = currentstartmonth; j < currentendmonth+1; j++){
                        let employeecount = empdetail.EmployeeCounts.find(x => x.Year === i.toString() && x.Month === j.toString())
                        if(employeecount != undefined){employeecount.EmpCount = parseInt(employeecount.EmpCount.toString()) + 1;}
                        }
                }
                        
                //Create an employee
                let employee = empdetail.Employees.find( x=> x.RepDataID === selectedRepData.RepDataID) 
                var employeenotexists = false
                if(empdetail.Employees.findIndex( x=> x.RepDataID === selectedRepData.RepDataID) == -1){employeenotexists = true}
                if(employeenotexists){
                        employee = new Employee()
                        employee.EmployeeID = this.commonFunctions.getnewguid() 
                        employee.EmployeeDetailID = empdetail.EmployeeCounts[0].EmployeeDetailID
                        employee.RepDataID = selectedRepData.RepDataID
                }
                employee.EmployeeFirstName = selectedRepData.RepFirstName
                employee.EmployeeLastName = selectedRepData.RepLastName
                if(selectedRepData.BaseSalary != null || selectedRepData.BaseSalary != "" && selectedRepData.BaseSalary != undefined){
                        employee.AnnualSalary = parseFloat(this.commonFunctions.RemoveThousandSeparatorFormat(selectedRepData.BaseSalary))
                } else{
                        employee.AnnualSalary = 0
                }
                employee.StartYear = startyear
                employee.StartMonth = startmonth
                if(selectedRepData.RepEndDate == null || selectedRepData.RepEndDate == undefined || selectedRepData.RepEndDate == ""){
                        employee.EndYear = ""
                        employee.EndMonth = ""
                }else{
                        employee.EndYear = endyear
                        employee.EndMonth = endmonth
                }
                if(employeenotexists){empdetail.Employees.push(employee)}
                let empindex = empdetail.Employees.findIndex( x=> x.RepDataID === selectedRepData.RepDataID)
                empdetail.Employees[empindex] = employee
                let empdetailindex = this.businesscalculatorinput.EmployeeDetails.findIndex( x=> x.EmployeeTypeID === salestypeid)
                this.businesscalculatorinput.EmployeeDetails[empdetailindex] = empdetail

                //Adding businesscalculatorinput to the local storage
                localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
                localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
                }
                });
                return true
                }
                
}