import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../util/localstorage.service'; 
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Agent } from '../models/agent.model';
import { AlertService } from '../util/alert/alert.service';
import { BsModalService,BsModalRef}   from 'ngx-bootstrap/modal';
import { SharedServiceService } from '../util/shared-service.service';
import { MondayDepositWithdrawCashDataService } from './mondaydepositwithdrawcash-data.service';
import { MondayDepositWithrawalCash } from '../models/monday-deposit-withdraw-cash.model';
import { GridOptions } from 'ag-grid-community'
import { CustomHeader} from '../util//customHeader'
import { Constants } from '../util/constants.js';
import { MondayDepositWithdrawCashCustomCellComponent } from './monday-deposit-withdraw-cash-custom-cell/monday-deposit-withdraw-cash-custom-cell.component';

@Component({
  selector: 'app-mondaydepositwithdrawcash',
  templateUrl: './mondaydepositwithdrawcash.component.html',
  styleUrls: ['./mondaydepositwithdrawcash.component.scss']
})

export class MondayDepositWithdrawCashComponent implements OnInit {
  
  public paddingtop: number = 60;
  public selectedChoiceID: string = ""; 
  public loggedAgent: Agent 
  public iSForm: FormGroup;
  public iSFormDepositWithdrawCashDetails: FormGroup;
  public uploadforms: FormData;
  public fileInfos: Array<any> = [];
  public file: string;
  public mondaydepositwithdrwalcashmodel: MondayDepositWithrawalCash

  public name:any
  public advisorsArray: Array<any>
  public advisorname: any
  public companyname:any
  public login:any
  public accountname: any
  public accountnumber: any
  public action: any
  public type: any 
  public amount: any 
  //public paymentday: any
  public paymentfrequency: any
  public depositpaymentday: any
  public depositpaymentdates: Array<any>;
  public withdrawalpaymentday: any
  public withdrawalpaymentdates: Array<any>;
  public notes: any
  public showpaymentday: boolean = false
  public isDeposit: boolean = false
  public disablesubmitbtn: boolean = false
  public isShowDashboard: boolean = false
  getRowStyle: (params: any) => { "font-weight": string; };
  pinnedBottomRowData: any;
  frameworkComponents: { customPinnedRowRenderer: any; };

  //ag grid
  public gridOptions0 : GridOptions 
  public columnDefs0: any;
  private gridApi0: any;
  private gridColumnApi0: any;
  public rowData0: any;
  public currentSelectedColumn;
  public ModalRef : BsModalRef 

  //#region allocation request history detail fields
  public submissionDateInPopUp: any
  public showSubmissionDate:boolean = true
  public groupInPopUp: any
  public showGroup:boolean = true
  public advisorNameInPopUp: any
  public showAdvisorName:boolean = true
  public companyNameInPopUp: any
  public showCompanyName:boolean = true
  public loginInPopUp: any
  public showLogin:boolean = true
  public accountNameInPopUp: any
  public showAccountName:boolean = true
  public accountNumberInPopUp: any
  public showAccountNumber:boolean = true
  public amountInPopUp: any
  public showAmount:boolean = true
  public typeInPopUp: any
  public showType:boolean = true
  public paymentFrequencyInPopUp: any
  public showPaymentFrequency:boolean = true
  public paymentStatusInPopUp: any
  public showPaymentStatus:boolean = true
  public nextPaymentDateInPopUp: any
  public showNextPaymentDate:boolean = true
  public notesInPopUp: any
  public showNotes:boolean = true
  //#endregion

  @ViewChild('show_deposit_withdraw_cash_details') show_deposit_withdraw_cash_details: TemplateRef<any>

  constructor( private router: Router,
    private formBuilder: FormBuilder, private alertService: AlertService,
    private bsModalService :BsModalService, private mondaydepositwithdrawcashdataservice: MondayDepositWithdrawCashDataService,
    private sharedService: SharedServiceService) {

    this.gridOptions0 = { 
        context: {
          componentParent: this
        },
        components: {
          agColumnHeader: CustomHeader
        },
        defaultColDef: { 
          headerComponentParams: {
            setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
            getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          } 
        },
        groupHeaderHeight: 30,
        enableColResize: true
      };

      this.columnDefs0 = [
        { 
          headerName: 'Submission Date', 
          field: 'SubmissionDate',
          width: 80,
          cellStyle: {textAlign: "right"},
          lockPosition: true
        },
        { 
          headerName: 'Type / Action', 
          field: 'Group',
          width: 100,
          cellStyle: {textAlign: "left"},
          lockPosition: true
        },
        { 
          headerName: 'Payment Frequency', 
          field: 'PaymentFrequency',
          width: 100,
          cellStyle: {textAlign: "left"},
          lockPosition: true
        },
        { 
          headerName: 'Account Name', 
          field: 'AccountName',
          width: 100,
          cellStyle: {textAlign: "left"},
          lockPosition: true
        },
        { 
          headerName: 'Account Number', 
          field: 'AccountNumber',
          width: 100,
          cellStyle: {textAlign: "right"},
          lockPosition: true
        },,
        // { 
        //   headerName: 'Company Name', 
        //   field: 'CompanyName',
        //   editable: true,
        //   width: 100,
        //   cellStyle: {textAlign: "left"},
        //   lockPosition: TextTrackCueList
        // },
        // { 
        //   headerName: 'Login', 
        //   field: 'Login',
        //   width: 100, 
        //   cellStyle: {textAlign: "left"},
        //   lockPosition: true
        // },
        { 
          headerName: '', 
          field: '',
          width: 45, 
          cellStyle: {textAlign: "center"},
          lockPosition: true,
          cellRendererFramework: MondayDepositWithdrawCashCustomCellComponent,
        }       
      ];
      
    } 
    
    gridSizeChanged(params: any) {
      params.api.sizeColumnsToFit();
    }

  ngOnInit() {

   this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
   if(this.loggedAgent.UserRoleID== Constants.UserRoleID.Superuser)
    this.isShowDashboard = true

   this.login = this.loggedAgent.FirstName + " " + this.loggedAgent.LastName
   this.companyname = this.loggedAgent.OrganizationName
   //#region deposit withdraw cash fields in the form
   this.iSForm = this.formBuilder.group({
      name: new FormControl(null) ,
      advisorname: new FormControl(null) ,
      companyname: new FormControl(null), 
      login: new FormControl(null),
      accountname: new FormControl(null) ,
      accountnumber: new FormControl(null) ,
      action: new FormControl(null) ,
      type: new FormControl(null) , 
      amount : new FormControl(null),
      paymentfrequency: new FormControl(null),
      depositpaymentday: new FormControl(null),
      withdrawalpaymentday: new FormControl(null),
      notes: new FormControl(null)
    } );   
    //#endregion

    //#region deposit withdraw cash history detail fields in the pop up
    this.iSFormDepositWithdrawCashDetails = this.formBuilder.group({
      submissionDateInPopUp: new FormControl(null) ,
      groupInPopUp: new FormControl(null) ,
      advisorNameInPopUp: new FormControl(null), 
      companyNameInPopUp: new FormControl(null),
      loginInPopUp: new FormControl(null) ,
      accountNameInPopUp: new FormControl(null) ,
      accountNumberInPopUp: new FormControl(null) ,
      amountInPopUp: new FormControl(null) , 
      typeInPopUp : new FormControl(null),
      paymentStatusInPopUp: new FormControl(null),
      nextPaymentDateInPopUp: new FormControl(null),
      notesInPopUp: new FormControl(null)
    } );
    //#endregion
    this.getAdvisors() 

    this.createPaymentDatesArray();
  }

  onGridReady0(params: any) { 
    this.rowData0 = []
		this.gridApi0  = params.api;
    this.gridColumnApi0 = params.columnApi; 
    this.gridApi0.showLoadingOverlay()  
    this.getDepositWithdrawHistory()  
    this.gridOptions0.api.setRowData(this.rowData0);
    //params.columnApi.autoSizeAllColumns();
    this.gridOptions0.api.sizeColumnsToFit();
  }

  getCurrentSelectedColumn() {
    return this.currentSelectedColumn;
  }
    
  setCurrentSelectedColumn(colId) {
    this.currentSelectedColumn = colId;
  }
 
  getDepositWithdrawHistory(){
    this.rowData0 = []
    this.gridApi0.showLoadingOverlay()
    this.alertService.info('Previous deposit/withdraw cash requests are loading. Please wait.')
    var Login = this.loggedAgent.FirstName + " " + this.loggedAgent.LastName
    var CompanyName = this.loggedAgent.OrganizationName

    const formData: FormData = new FormData();
    formData.append("Login", Login);
    formData.append("CompanyName", CompanyName);
    this.mondaydepositwithdrawcashdataservice.getdepositwithdrawhistory(formData, this.loggedAgent).subscribe(data =>
    {
      var depositwithdrawcashhistorydata = JSON.parse(data)
      var SubmissionDate
      var Group
      var AdvisorName
      var CompanyName
      var Login
      var AccountName
      var AccountNumber
      var Amount
      var Type
      var PaymentFrequency
      var PaymentStatus
      var NextPaymentDate
      var Notes
      if(depositwithdrawcashhistorydata != undefined){
        depositwithdrawcashhistorydata.items_page_by_column_values.items.forEach(item => {
            //add values to rowdata array
            Group = item.group.title
            item.column_values.forEach(cols => {
            if(cols.id == Constants.MondayDepositWithdrawCashColumns.SubmissionDate_ID){
              if(cols.text != null && cols.text != "" && cols.text != undefined){
                var dateObj = new Date(cols.text.split(" ")[0])
                var date = dateObj.getUTCDate()
                var month = dateObj.getUTCMonth() + 1
                var year = dateObj.getUTCFullYear()
                SubmissionDate = month.toString() + "/" + date.toString() + "/" + year.toString()
              }else{
                SubmissionDate = ""
              }
            }
            if(cols.id == Constants.MondayDepositWithdrawCashColumns.AdvisorName_ID){
              AdvisorName = cols.text
            }
            else if(cols.id == Constants.MondayDepositWithdrawCashColumns.CompanyName){
              CompanyName = cols.text
            }
            else if(cols.id == Constants.MondayDepositWithdrawCashColumns.Login){
              Login = cols.text
            }
            else if(cols.id == Constants.MondayDepositWithdrawCashColumns.AccountName_ID){
              AccountName = cols.text
            }
            else if(cols.id == Constants.MondayDepositWithdrawCashColumns.AccountNumber_ID){
              AccountNumber = cols.text
            }
            else if(cols.id == Constants.MondayDepositWithdrawCashColumns.Amount_ID){
              Amount = cols.text.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",").toString()
            }
            else if(cols.id == Constants.MondayDepositWithdrawCashColumns.Type_ID){
              Type = cols.text
            }
            else if(cols.id == Constants.MondayDepositWithdrawCashColumns.PaymentFrequency_ID){
              PaymentFrequency = cols.text
            }
            else if(cols.id == Constants.MondayDepositWithdrawCashColumns.PaymentStatus_ID){
              PaymentStatus = cols.text
            }
            else if(cols.id == Constants.MondayDepositWithdrawCashColumns.NextPaymentDate_ID){
              NextPaymentDate = cols.text
            }
            else if(cols.id == Constants.MondayDepositWithdrawCashColumns.Notes_ID){
              Notes = cols.text
            }
          });
          this.rowData0.push({
            SubmissionDate: SubmissionDate,
            Group: Group,
            AdvisorName: AdvisorName,
            CompanyName: CompanyName,
            Login: Login,
            AccountName: AccountName,
            AccountNumber: AccountNumber,
            Amount: Amount,
            Type: Type,
            PaymentFrequency: PaymentFrequency,
            PaymentStatus: PaymentStatus,
            NextPaymentDate: NextPaymentDate,
            Notes: Notes})
        });
      }
      this.gridOptions0.api.setRowData(this.rowData0);
      //this.gridApi0.hideOverlay()
      setTimeout(() => { 
        this.alertService.clear()
      }, 50);
      return this.rowData0
      
    },
    error => { 
        this.alertService.clear()
        this.alertService.error('Error occured')       
    });
  }

  showAllocationHistoryDetails(data){
    //assign data to the fields and hide the field if there is no data for the field
    this.submissionDateInPopUp = data.SubmissionDate
    if(this.submissionDateInPopUp == null || this.submissionDateInPopUp == undefined || this.submissionDateInPopUp == "")
    {this.showSubmissionDate = false}

    if(data.Group.includes("Recurring")){
      this.groupInPopUp = data.Group.split('Recurring ')[1].toString()
    }else{
      this.groupInPopUp = data.Group.split('One Time ')[1].toString()
    }
    if(this.groupInPopUp == null || this.groupInPopUp == undefined || this.groupInPopUp == "")
    {this.showGroup = false}

    this.advisorNameInPopUp = data.AdvisorName
    if(this.advisorNameInPopUp == null || this.advisorNameInPopUp == undefined || this.advisorNameInPopUp == "")
    {this.showAdvisorName = false}

    this.companyNameInPopUp = data.CompanyName
    if(this.companyNameInPopUp == null || this.companyNameInPopUp == undefined || this.companyNameInPopUp == "")
    {this.showCompanyName = false}

    this.loginInPopUp = data.Login
    if(this.loginInPopUp == null || this.loginInPopUp == undefined || this.loginInPopUp == "")
    {this.showLogin = false}

    this.accountNameInPopUp = data.AccountName
    if(this.accountNameInPopUp == null || this.accountNameInPopUp == undefined || this.accountNameInPopUp == "")
    {this.showAccountName = false}

    this.accountNumberInPopUp = data.AccountNumber
    if(this.accountNumberInPopUp == null || this.accountNumberInPopUp == undefined || this.accountNumberInPopUp == "")
    {this.showAccountNumber = false}

    this.amountInPopUp = data.Amount
    if(this.amountInPopUp == null || this.amountInPopUp == undefined || this.amountInPopUp == "")
    {this.showAmount = false}

    this.typeInPopUp = data.Type
    if(this.typeInPopUp == null || this.typeInPopUp == undefined || this.typeInPopUp == "")
    {this.showType = false}

    this.paymentFrequencyInPopUp = data.paymentFrequency
    if(this.paymentFrequencyInPopUp == null || this.paymentFrequencyInPopUp == undefined || this.paymentFrequencyInPopUp == "")
    {this.showPaymentFrequency = false}

    this.paymentStatusInPopUp = data.PaymentStatus
    if(this.paymentStatusInPopUp == null || this.paymentStatusInPopUp == undefined || this.paymentStatusInPopUp == "")
    {this.showPaymentStatus = false}

    this.nextPaymentDateInPopUp = data.NextPaymentDate
    if(this.nextPaymentDateInPopUp == null || this.nextPaymentDateInPopUp == undefined || this.nextPaymentDateInPopUp == "")
    {this.showNextPaymentDate = false}

    this.notesInPopUp = data.Notes
    if(this.notesInPopUp == null || this.notesInPopUp == undefined || this.notesInPopUp == "")
    {this.showNotes = false}
    this.ModalRef = this.bsModalService.show(this.show_deposit_withdraw_cash_details)
  }

  cancelModal(){
    this.ModalRef.hide()
  }
  
  getAdvisors(){
    this.advisorsArray = []
    this.mondaydepositwithdrawcashdataservice.getcompanyadvisors(this.loggedAgent.OrganizationID, this.loggedAgent).subscribe(data =>
    {
      this.alertService.clear()
      if(data != null && data != undefined){
        var i
        for(i=0; i < data.length; i++){
          let AdvisorName = data[i].FirstName + " " + data[i].LastName
          this.advisorsArray.push({id: AdvisorName, text: AdvisorName})
        } 
      }
      if(this.advisorsArray[0] != undefined){
        this.advisorname = this.advisorsArray[0].id
      }
      return this.advisorsArray
      
    },
    error => { 
        this.alertService.clear()
        this.alertService.error('Error occured')       
    });
  } 

  validateMondayDepositWithdrawCashForm():boolean
  { 
    this.alertService.clear()
    if ((this.iSForm.controls.advisorname.value == ""  || 
      this.iSForm.controls.advisorname.value == null))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Advisor Name')
      return false
    } 

    if ((this.iSForm.controls.companyname.value == ""  || 
      this.iSForm.controls.companyname.value == null))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Company Name')
      return false
    }

    if ((this.iSForm.controls.login.value == ""  || 
      this.iSForm.controls.login.value == null))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Login')
      return false
    }

    if ((this.iSForm.controls.accountname.value == ""  || 
          this.iSForm.controls.accountname.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Account Name')
      return false
    }   
 
    if ((this.iSForm.controls.accountnumber.value == ""  || 
          this.iSForm.controls.accountnumber.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Account Number')
      return false
    } 


    if ((this.iSForm.controls.action.value == ""  || this.iSForm.controls.action.value == "---" ||
          this.iSForm.controls.action.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Action')
      return false
    } 
    
    if ((this.iSForm.controls.amount.value == ""  || this.iSForm.controls.amount.value == "---" ||
    this.iSForm.controls.amount.value == null ))
    { 
    this.alertService.clear()
    this.alertService.error('Invalid Amount')
    return false
    }  

    if ((this.iSForm.controls.type.value == ""  || this.iSForm.controls.type.value == "---" ||
          this.iSForm.controls.type.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Type')
      return false
    }  

    if(this.showpaymentday){
      if ((this.iSForm.controls.paymentfrequency.value == ""  || this.iSForm.controls.paymentfrequency.value == "---" ||
      this.iSForm.controls.paymentfrequency.value == null ))
      {
        this.alertService.clear()
        this.alertService.error('Invalid Payment Frequency')
        return false
      }


      if(this.action == "Deposit")
      {
        if ((this.iSForm.controls.depositpaymentday.value == ""  || this.iSForm.controls.depositpaymentday.value == "---" ||
        this.iSForm.controls.depositpaymentday.value == null ))
        {
          this.alertService.clear()
          this.alertService.error('Invalid Payment Date')
          return false
        }
      }
      else
      {
        if ((this.iSForm.controls.withdrawalpaymentday.value == ""  || this.iSForm.controls.withdrawalpaymentday.value == "---" ||
        this.iSForm.controls.withdrawalpaymentday.value == null ))
        {
          this.alertService.clear()
          this.alertService.error('Invalid Payment Date')
          return false
        }
      }
      
    }


   /*  if ((this.fileInfos == undefined  || this.fileInfos.length == 0 ||
      this.fileInfos == null ))
    { 
      this.alertService.clear()
      this.alertService.error('No File is Selected')
      return false
    }   */

    return true
  }  


  initializeFormData(){
    this.advisorname = this.advisorsArray[0].id
    this.login = this.loggedAgent.FirstName + " " + this.loggedAgent.LastName
    this.companyname = this.loggedAgent.OrganizationName
    this.accountname = ""
    this.accountnumber = ""
    this.action = ""
    this.type = "" 
    this.amount =""  
    this.paymentfrequency =""
    this.depositpaymentday =""
    this.withdrawalpaymentday =""
    this.notes = ""
    this.showpaymentday = false
    this.isDeposit = false
  }

  createPaymentDatesArray() {
    var currentDate = new Date();
    this.depositpaymentdates = [];
    this.withdrawalpaymentdates = [];

    var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var currentMonth = monthNames[currentDate.getMonth()];
    var nextMonth = monthNames[(currentDate.getMonth() + 1) % 12];

    var depositDates = [
      { PaymentDate: "1st", Day: 1 },
      { PaymentDate: "5th", Day: 5 },
      { PaymentDate: "10th", Day: 10 },
      { PaymentDate: "15th", Day: 15 },
      { PaymentDate: "20th", Day: 20 },
      { PaymentDate: "25th", Day: 25 },
    ];

    var withdrawalDates = [
      { PaymentDate: "5th", Day: 5 },
      { PaymentDate: "25th", Day: 25 },
    ];

    function addOrdinalSuffix(day: number): string {
      if (day > 3 && day < 21) return day + "th";
      switch (day % 10) {
        case 1: return day + "st";
        case 2: return day + "nd";
        case 3: return day + "rd";
        default: return day + "th";
      }
    }

    var createDisplayedDate = (day: number) => {
      return day > currentDate.getDate()
        ? `${addOrdinalSuffix(day)} of ${currentMonth}`
        : `${addOrdinalSuffix(day)} of ${nextMonth}`;
    };

    depositDates.forEach((date) => {
      var displayedDate = createDisplayedDate(date.Day);
      this.depositpaymentdates.push({ PaymentDate: addOrdinalSuffix(date.Day), DisplayedDate: displayedDate });
    });

    withdrawalDates.forEach((date) => {
      var displayedDate = createDisplayedDate(date.Day);
      this.withdrawalpaymentdates.push({ PaymentDate: addOrdinalSuffix(date.Day), DisplayedDate: displayedDate });
    });

    function sortByMonthAndDay(a, b) {
      var [aDay, aMonth] = a.DisplayedDate.split(' of ');
      var [bDay, bMonth] = b.DisplayedDate.split(' of ');

      var aMonthIndex = monthNames.indexOf(aMonth);
      var bMonthIndex = monthNames.indexOf(bMonth);

      if (aMonthIndex !== bMonthIndex) {
        return aMonthIndex - bMonthIndex;
      } else {
        return parseInt(aDay) - parseInt(bDay);
      }
    }

    this.depositpaymentdates.sort(sortByMonthAndDay);
    this.withdrawalpaymentdates.sort(sortByMonthAndDay);
  }

  saveMondayDepositWithdrawCashDetails(){
    if (this.validateMondayDepositWithdrawCashForm()){
      this.disablesubmitbtn = true
      this.alertService.info('Deposit/withdraw cash request is submitting. Please wait.')
      this.mondaydepositwithdrwalcashmodel = new MondayDepositWithrawalCash()
      this.mondaydepositwithdrwalcashmodel.AdvisorName = this.advisorname
      this.mondaydepositwithdrwalcashmodel.CompanyName = this.companyname
      this.mondaydepositwithdrwalcashmodel.Login = this.login
      this.mondaydepositwithdrwalcashmodel.AccountName = this.accountname
      this.mondaydepositwithdrwalcashmodel.AccountNumber = this.accountnumber
      this.mondaydepositwithdrwalcashmodel.Action = this.action 
      this.mondaydepositwithdrwalcashmodel.Type = this.type 
      this.mondaydepositwithdrwalcashmodel.PaymentFrequency = this.paymentfrequency
      if(this.action == "Deposit"){
        if(this.depositpaymentday != null && this.depositpaymentday != undefined){
            this.mondaydepositwithdrwalcashmodel.PaymentDay = this.depositpaymentday.slice(0,-2)
        }  
      }
      else
      {
        if(this.withdrawalpaymentday != null && this.withdrawalpaymentday != undefined){
          this.mondaydepositwithdrwalcashmodel.PaymentDay = this.withdrawalpaymentday.slice(0,-2)
      } 
      }
      this.mondaydepositwithdrwalcashmodel.Amount = this.amount.replace(/,/g,'') 
      this.mondaydepositwithdrwalcashmodel.Notes = this.notes
      this.mondaydepositwithdrwalcashmodel.Email = this.loggedAgent.Email

      //console.log(this.mondaydepositwithdrwalcashmodel)
  
      this.mondaydepositwithdrawcashdataservice.DepositWithdrawCash(this.mondaydepositwithdrwalcashmodel, this.loggedAgent).subscribe(date =>
      {
        this.alertService.clear()
        this.alertService.success('Successfully saved')
        this.disablesubmitbtn = false
        this.initializeFormData()
        this.getDepositWithdrawHistory()
      },
      error => { 
        if (error.error.toLowerCase().includes("an asynchronous module or handler completed while an asynchronous operation was still pending")) // To handle An asynchronous module or handler completed while an asynchronous operation was still pending.
          {
            this.alertService.success('Successfully saved')
            this.disablesubmitbtn = false
            this.initializeFormData()
            this.getDepositWithdrawHistory()
          } else{
            this.alertService.error('Error occured while saving data')
            this.disablesubmitbtn = false
          }
       }); 

    }
    
  } 

  FilterInput(event){

    // skip for arrow keys
    if(event.which >= 37 && event.which <= 40) return;
  
    switch (event.currentTarget.id) {
      case "amount":
        let valueWithoutCommas = event.target.value.replace(/\D/g, "");
        if (Number(valueWithoutCommas) > 0) {
            this.amount = valueWithoutCommas.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            this.amount = ''; 
        }
        break;
      default:
    }  

  /*   if(event.key != "Backspace" && event.key != "Tab" && event.key != "Enter"){
      this.alertService.clear();
      if(/^\d+$/.test(event.key)){
        this.alertService.clear();
      } else{
        this.alertService.error('Please enter a number');

      switch (event.currentTarget.id) { 
        case "amount":
          this.amount = ""
          break;
        default:
      }
    }
    } else{
      this.alertService.clear();
    } */
  }

  clearAlert(){
    this.alertService.clear();
  }

  /* resetAccountType(event){
    if(event.currentTarget.value == "---"){
      this.accounttype = ""
    }
  }

  resetInstructionType(event){
    if(event.currentTarget.value == "---"){
      this.instructiontype = ""
    }
  }
 */

  resetType(event){
    if(event.currentTarget.value != null && event.currentTarget.value != undefined){
      if(this.type == "Recurring"){
        this.showpaymentday = true
      }else{
        this.showpaymentday = false
      }
    } else {
      this.showpaymentday = false
    }
  }

  changeAction(event){ 
    if(event.currentTarget.value != null && event.currentTarget.value != undefined){
      if(this.action == "Deposit"){
        this.isDeposit = true
      }else{
        this.isDeposit = false
      }
    } else {
      this.isDeposit = false
    }
  } 

  exitBtnClickEvent()
  {
    if(JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) != null){
      this.sharedService.changeSideBarRequest()   
			this.router.navigateByUrl('home')
    } else{
      this.router.navigateByUrl('landing'); 
    }
    
  } 

  //load the superuser dashboard page
	public navigateSuperUserDashboard()
	{
		this.router.navigateByUrl('superuserdashboard'); 
	}
}