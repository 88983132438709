export class Agent {
    constructor( 
        public AgentID?: string,
        public OrganizationID?: string,
        public Office?: string,
        public FirstName?: string,
        public LastName?: string,
        public Email?: string,
        public Phone?: string,
        public Password?: string,
        public UserRoleID?: string,
        public Disclaimer?: string,
        public bDeleted?: boolean,
        public DeletedBy?: string,
        public DeletedOn?: Date,
        public OrganizationName?: string,
        public UserRoleName?: string, 
        public AgreedToClickwrap?: boolean,
        public bAdvisor?: boolean,
        public bShowBizIQ?: boolean,
        public bTrialUser?: boolean,
        public TrialStartDate?: string,
        public TrialEndDate?: string,
        public bTrainingCompleted?: boolean,
        public bMarketer?: boolean,
        public bShowOldInvestmentReport?: boolean,
        public bShowNewInvestmentReport?: boolean
    ) {}
}