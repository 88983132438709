export class SavedReport {
    constructor( 
        public ReportID?: string,
        public ClientID?: string,
        public ClientName?: string,
        public ReportName?: string,
        public ReportType?: string,
        public CreatedBy?: string,
        public CreatedOn?: Date
    ) {}
}