import {
	Component,
	OnInit,
	ElementRef,
	ViewChild, 
	SimpleChanges,
	ViewContainerRef,
	ComponentRef,
	ComponentFactoryResolver,
	OnDestroy,
	TemplateRef
} from '@angular/core';
import { Router } from '@angular/router'; 
import { Constants } from '../../util/constants'; 
import { LocalStorage } from '../../util/localstorage.service'; 
import { Agent } from '../../models/agent.model'; 
import Stepper from 'bs-stepper';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { BusinessCalculatorInput, EmployeeCount, EmployeeDetail, TravelMealsTrainingPerYear, RentPerYear, Employee, BusinessInsurance } from 'src/app/models/business-calculator-input.model';
import { AlertService } from 'src/app/util/alert/alert.service';
import { debug } from 'util';
import { TargetLocator } from 'selenium-webdriver';
import { CalculatorComponent } from '../calculator/calculator.component';
import { debugOutputAstAsTypeScript, IfStmt } from '@angular/compiler';
import { SharedServiceService } from '../../util/shared-service.service';
import { FP4FADataService } from '../fp4fa-data.service';
import { CommonFunctions } from 'src/app/util/common-functions';
import { BsModalService,BsModalRef}   from 'ngx-bootstrap/modal';
import { BusinessCalculatorDefaults } from 'src/app/models/business-calculator-defaults.model';
import { FP4FACommonFunctionsService } from '../fp4fa-common-functions.service';


@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.scss'] 
})


export class ExpenseComponent implements OnInit {

  name = 'Angular';
  public isSideBar: boolean = true
  private stepper: Stepper;
  public iSForm: FormGroup;
  public businesscalculatorinput: BusinessCalculatorInput;
  public isShowDashboard: boolean = false
  
  //payroll taxes
  public socialsecurity: string;
  public medicare: string;
  public federalunemployment: string;
  public stateunemployment: string;
  public socialsecuritydefault: string;
  public medicaredefault: string;
  public federalunemploymentdefault: string;
  public stateunemploymentdefault: string;
  public noofyears: string;

  //other payroll items
  public COLAIncrease: string;
  public staffperformancebonuspool: string;
  public insurancebenefit: string
  public retirementbenefit: string
  public reppaypercentage: string;

  //employee
  public empDetailsArray: Array<EmployeeDetail>;
  public empCountArray: Array<EmployeeCount> = []
  public years: Array<number>
  public emptype: string;
  public employeetype: string;
  public annualsalary: string;
  public moreinfo: string;
  public hidemoreinfo: boolean = true
  public hidesummary: boolean = false
  public employeetypesArray: Array<any> = []
  public employeesArray: Array<any> = []
  public saveactionshow :boolean = true
  public startyear: string
  public startmonth: string
  public endyear: string
  public endmonth: string
  public emptyendmonth: boolean
  public emptyendyear: boolean
  public Employee: Employee
  public repdataid: string
  public employeefirstname: string
  public employeelastname: string

  //other expenses
  public itbase: string;
  public itperee: string;
  public licensingbase: string;
  public licensingrate: string;
  public complianceservices: string;
  public suppliesbase: string;
  public suppliesperee: string;
  public other: string;
  public proservices: string;
  public travelmealstrainingperyear: Array<TravelMealsTrainingPerYear> = [];
  public totalsquarefootageperyr: string

  //rent
  public costperft2peryr: string
  public rent: Array<RentPerYear> = []

  //insurance
  public businessinsurances: Array<BusinessInsurance> = []
  public EandOinsurance:string
  public businessliabilityinsurance: string;
  public workerscompensationinsurance: string;
  public otherinsurancecosts: string;

  //for fill down
  public fillDownValue: any
  public currentColType: string

  public currentStepperIndex: any  
  public currentStepperName:any  
  private commonFunctions: CommonFunctions = new CommonFunctions 

  public closemodel: boolean

  status: boolean = false;
  resizestatus: boolean = false;

  @ViewChild('templateaddEmployeeData') templateaddEmployeeData: TemplateRef<any>
  @ViewChild('templatefilldown') templatefilldown: TemplateRef<any>
  public ModalRef : BsModalRef 
//#region  Calculator Methods */
  resizeRightbar()
  {
    this.resizestatus = !this.resizestatus;          
  }

  toggleRightbar()
  {
	if(this.fp4facommonFunctions.ValidateSaveBusinessPlan()){
		if(!this.status){
			this.alertService.info('Calculator is loading. Please wait.')
		}
		this.fp4facommonFunctions.AddEmployees()
		this.FP4FAdataService.savebusinessplan(this.loggedAgent, this.businesscalculatorinput).subscribe(data => {
		localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
			this.status = !this.status; 
			if(this.status){
			this.closeBottombar()
			this.sharedService.loadCalculatorRequest()
			}else
			{
				this.showBottombar() 
			}  
		},
		error => { 
			this.alertService.error('An error occurred while saving Business Plan')
		});
	}
  }

  refreshCalculator()
  {
	this.alertService.info('Calculator is loading. Please wait.')
	this.SaveBusinessPlan(false)
    this.sharedService.loadCalculatorRequest()   
  }
//#endregion

//#region Stepper Methods 
next() 
{ 
  if (this.currentStepperIndex==5)
  {
	this.toggleRightbar()
  }
  else
  {
	this.stepper.next();
	this.currentStepperIndex +=1
	this.changeStepper(this.currentStepperIndex)
  }    
}

onSubmit() 
{
  return false;
}

changeStepper(currentindex)
{
	this.alertService.clear()
	// calling save business plans
	this.SaveBusinessPlan(false)

    //show save button on stepper changes- when calculator is hidden
    if (this.status==true)
    {
      this.closeBottombar() 
    }   
    else
    {
      this.showBottombar() 
	}
	
  this.currentStepperIndex = currentindex
  switch (this.currentStepperIndex)
  {
	case 0:
	  this.currentStepperName = "Employees" 
	  break;
	case 1:
	  this.currentStepperName = "Payroll Taxes" 
	  break;
	case 2:   
	  this.currentStepperName = "Other Payroll Items" 
	  break;
	case 3:
	  this.currentStepperName = "Rent" 
	  break;
	case 4:  
	  this.currentStepperName = "Business Insurance" 
	  this.setInsurancePerYearVals()
	  break;
	case 5:   
	  this.currentStepperName = "Other Expenses" 
	  break;
  }
}
//#endregion

//#region Save Action Button related Methods...
closeBottombar(){
	this.saveactionshow = false;
  }
  
  showBottombar(){
	this.saveactionshow = true;
  }
  
  SaveBusinessPlan(showBusinessPlanSavedAlert)
  {
	this.alertService.clear()
	if(this.fp4facommonFunctions.ValidateSaveBusinessPlan()){
		this.fp4facommonFunctions.AddEmployees()
		this.FP4FAdataService.savebusinessplan(this.loggedAgent, this.businesscalculatorinput).subscribe(data => {
			
			if(showBusinessPlanSavedAlert){
				this.alertService.success('Business Plan successfully saved.')
			}
			localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
		},
		error => { 
			this.alertService.error('An error occurred while saving Business Plan')
		});
	}
  }
  
  //#endregion
	 
//#region Constructor 
	private selectClientID:any
	public loggedAgent: Agent 

	constructor(private resolver: ComponentFactoryResolver ,private router: Router,
		private alertService: AlertService, private formBuilder: FormBuilder,private sharedService: SharedServiceService,
		private FP4FAdataService: FP4FADataService, private bsModalService :BsModalService,private fp4facommonFunctions: FP4FACommonFunctionsService) {  
		this.loggedAgent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent;

		this.businesscalculatorinput = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_INPUT)) as BusinessCalculatorInput;
		this.noofyears = this.businesscalculatorinput.NoOfYears != null 
		? this.businesscalculatorinput.NoOfYears.toString() : "5"

		//payroll taxes db values
		if(this.businesscalculatorinput.PayrollTaxes != null && this.businesscalculatorinput.PayrollTaxes != undefined){
			this.socialsecurity = this.businesscalculatorinput.PayrollTaxes.SocialSecurity != null 
			? this.businesscalculatorinput.PayrollTaxes.SocialSecurity.toString() : "0"
			this.medicare = this.businesscalculatorinput.PayrollTaxes.Medicare != null 
			? this.businesscalculatorinput.PayrollTaxes.Medicare.toString() : "0"
			this.federalunemployment = this.businesscalculatorinput.PayrollTaxes.FederalUnemployment != null 
			? this.businesscalculatorinput.PayrollTaxes.FederalUnemployment.toString() : "0"
			this.stateunemployment = this.businesscalculatorinput.PayrollTaxes.StateUnemployment != null 
			? this.businesscalculatorinput.PayrollTaxes.StateUnemployment.toString() : "0"
		}

		//payroll taxes defaults
		let tmpbusinesscalculatorinput = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_DEFAULTS)) as BusinessCalculatorDefaults
		this.socialsecuritydefault = tmpbusinesscalculatorinput.SocialSecurity != null 
		? tmpbusinesscalculatorinput.SocialSecurity.toString().replace(/\"/g, "") : "0"
		this.medicaredefault = tmpbusinesscalculatorinput.Medicare != null 
		? tmpbusinesscalculatorinput.Medicare.toString().replace(/\"/g, "") : "0"
		this.federalunemploymentdefault = tmpbusinesscalculatorinput.FederalUnemployment != null 
		? tmpbusinesscalculatorinput.FederalUnemployment.toString().replace(/\"/g, "") : "0"
		this.stateunemploymentdefault = tmpbusinesscalculatorinput.StateUnemployment != null 
		? tmpbusinesscalculatorinput.StateUnemployment.toString().replace(/\"/g, "") : "0"


		//other payroll items
		if(this.businesscalculatorinput.OtherPayrollItems != null && this.businesscalculatorinput.OtherPayrollItems != undefined){
			this.COLAIncrease = this.businesscalculatorinput.OtherPayrollItems.COLAIncrease != null 
			? this.businesscalculatorinput.OtherPayrollItems.COLAIncrease.toString() : "0"
			this.staffperformancebonuspool = this.businesscalculatorinput.OtherPayrollItems.StaffPerformanceBonusPool != null 
			? this.businesscalculatorinput.OtherPayrollItems.StaffPerformanceBonusPool.toString() : "0"
			this.insurancebenefit = this.businesscalculatorinput.OtherPayrollItems.InsuranceBenefits != null 
			? this.commonFunctions.AddThousandSeparatorFormat(this.businesscalculatorinput.OtherPayrollItems.InsuranceBenefits) : "0"
			this.retirementbenefit = this.businesscalculatorinput.OtherPayrollItems.RetirementBenefits != null 
			? this.businesscalculatorinput.OtherPayrollItems.RetirementBenefits.toString() : "0"
		}
		
		//rep pay percentage
		/* this.reppaypercentage = this.businesscalculatorinput.RepPayPercentage != null 
		? this.businesscalculatorinput.RepPayPercentage.toString() : "0" */

		//other expenses
		if(this.businesscalculatorinput.OtherExpenses != null && this.businesscalculatorinput.OtherExpenses != undefined){
			this.itbase = this.businesscalculatorinput.OtherExpenses.ITBase != null 
			? this.commonFunctions.AddThousandSeparatorFormat(this.businesscalculatorinput.OtherExpenses.ITBase).toString(): "0"
			this.itperee = this.businesscalculatorinput.OtherExpenses.ITPerEE != null 
			? this.commonFunctions.AddThousandSeparatorFormat(this.businesscalculatorinput.OtherExpenses.ITPerEE).toString(): "0"
			this.licensingbase = this.businesscalculatorinput.OtherExpenses.LicensingBase != null 
			? this.commonFunctions.AddThousandSeparatorFormat(this.businesscalculatorinput.OtherExpenses.LicensingBase).toString(): "0"
			this.licensingrate = this.businesscalculatorinput.OtherExpenses.LicensingRate != null 
			? this.commonFunctions.AddThousandSeparatorFormat(this.businesscalculatorinput.OtherExpenses.LicensingRate).toString(): "0"
			this.proservices = this.businesscalculatorinput.OtherExpenses.ProServices != null 
			? this.commonFunctions.AddThousandSeparatorFormat(this.businesscalculatorinput.OtherExpenses.ProServices).toString(): "0"
			this.other = this.businesscalculatorinput.OtherExpenses.Other != null 
			? this.commonFunctions.AddThousandSeparatorFormat(this.businesscalculatorinput.OtherExpenses.Other).toString(): "0"
			this.suppliesbase = this.businesscalculatorinput.OtherExpenses.SuppliesBase != null 
			? this.commonFunctions.AddThousandSeparatorFormat(this.businesscalculatorinput.OtherExpenses.SuppliesBase).toString(): "0"
			this.suppliesperee = this.businesscalculatorinput.OtherExpenses.SuppliesPerEE != null 
			? this.commonFunctions.AddThousandSeparatorFormat(this.businesscalculatorinput.OtherExpenses.SuppliesPerEE).toString(): "0"
			this.complianceservices = this.businesscalculatorinput.OtherExpenses.ComplianceServices != null 
			? this.commonFunctions.AddThousandSeparatorFormat(this.businesscalculatorinput.OtherExpenses.ComplianceServices).toString(): "0"
		}

		//creating the employee count array 
		let i;
		this.years = []
		let year = new Date().getFullYear();
		for(i = 0; i < parseInt(this.noofyears); i++){
			this.years.push(year)
			year = year + 1						
		}	
		this.empCountArray = []
		this.empCountArray.push({Year: "", Month: "1" ,EmpCount: 0},
			{Year: "", Month: "2" ,EmpCount: 0},				
			{Year: "", Month: "3" ,EmpCount: 0},
			{Year: "", Month: "4" ,EmpCount: 0},
			{Year: "", Month: "5" ,EmpCount: 0},
			{Year: "", Month: "6" ,EmpCount: 0},
			{Year: "", Month: "7" ,EmpCount: 0},
			{Year: "", Month: "8" ,EmpCount: 0},
			{Year: "", Month: "9" ,EmpCount: 0},
			{Year: "", Month: "10" ,EmpCount: 0},
			{Year: "", Month: "11" ,EmpCount: 0},
			{Year: "", Month: "12" ,EmpCount: 0})


		//creating the insurance per year
		if(this.businesscalculatorinput.BusinessInsurances == undefined ||
			this.businesscalculatorinput.BusinessInsurances.length == 0){
				this.businessinsurances = []
				let year = new Date().getFullYear();
				for(i = 0; i < parseInt(this.noofyears); i++){
					let businessinsurance = new BusinessInsurance
					businessinsurance.Year = year.toString()
					businessinsurance.EAndOInsurance = 0
					businessinsurance.BusinessLiabilityInsurance = 0
					businessinsurance.WorkersCompensationInsurance = 0
					businessinsurance.OtherInsuranceCosts = 0
					this.businessinsurances.push(businessinsurance)
					year = year + 1						
				}
		} else{
			this.businesscalculatorinput.BusinessInsurances.splice(parseInt(this.noofyears))
			this.businessinsurances = this.businesscalculatorinput.BusinessInsurances
			if(this.businesscalculatorinput.BusinessInsurances.length < parseInt(this.noofyears)){
				let yr = new Date().getFullYear() + this.businesscalculatorinput.BusinessInsurances.length;
				for(i = this.businesscalculatorinput.BusinessInsurances.length; i < parseInt(this.noofyears); i++){
					let businessinsurance = new BusinessInsurance
					businessinsurance.Year = yr.toString()
					businessinsurance.EAndOInsurance = 0
					businessinsurance.BusinessLiabilityInsurance = 0
					businessinsurance.WorkersCompensationInsurance = 0
					businessinsurance.OtherInsuranceCosts = 0
					this.businessinsurances.push(businessinsurance)
					yr = yr + 1	
				}
			}
			this.businesscalculatorinput.BusinessInsurances = this.businessinsurances 
			localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
		}
		
		//creating the travel meals training per year
		if(this.businesscalculatorinput.OtherExpenses != null && this.businesscalculatorinput.OtherExpenses != undefined){
			if(this.businesscalculatorinput.OtherExpenses.TravelMealsTraining == undefined ||
				this.businesscalculatorinput.OtherExpenses.TravelMealsTraining.length == 0){
					this.travelmealstrainingperyear = []
					let year = new Date().getFullYear();
					for(i = 0; i < parseInt(this.noofyears); i++){
						let travelmealstrainingperyearval = new TravelMealsTrainingPerYear
						travelmealstrainingperyearval.Year = year.toString()
						travelmealstrainingperyearval.EventCost = 0
						travelmealstrainingperyearval.TrainingCost = 0
						this.travelmealstrainingperyear.push(travelmealstrainingperyearval)
						year = year + 1	
					}
			} else{
				this.businesscalculatorinput.OtherExpenses.TravelMealsTraining.splice(parseInt(this.noofyears))
				this.travelmealstrainingperyear = this.businesscalculatorinput.OtherExpenses.TravelMealsTraining
				if(this.businesscalculatorinput.OtherExpenses.TravelMealsTraining.length < parseInt(this.noofyears)){
					let y = new Date().getFullYear() + this.businesscalculatorinput.OtherExpenses.TravelMealsTraining.length;
					for(i = this.businesscalculatorinput.OtherExpenses.TravelMealsTraining.length; i < parseInt(this.noofyears); i++){
						let travelmealstrainingperyearval = new TravelMealsTrainingPerYear
						travelmealstrainingperyearval.Year = y.toString()
						travelmealstrainingperyearval.EventCost = 0
						travelmealstrainingperyearval.TrainingCost = 0
						this.travelmealstrainingperyear.push(travelmealstrainingperyearval)
						y = y + 1	
					}
				}
				this.businesscalculatorinput.OtherExpenses.TravelMealsTraining = this.travelmealstrainingperyear
				localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
			}
		}

		//creating employees array
		this.createEmployeesArray()
	
		//creating the rent array
		if(this.businesscalculatorinput.Rent == undefined ||
			this.businesscalculatorinput.Rent.length == 0){
				this.rent = []
				let year = new Date().getFullYear();
				for(i = 0; i < parseInt(this.noofyears); i++){
					let rentperyear = new RentPerYear
					rentperyear.Year = year.toString()
					rentperyear.TotalSquareFootage = 0
					rentperyear.Cost = 0
					this.rent.push(rentperyear)
					year = year + 1						
				}
		} else{
			this.businesscalculatorinput.Rent.splice(parseInt(this.noofyears))
			this.rent = this.businesscalculatorinput.Rent
			if(this.businesscalculatorinput.Rent.length < parseInt(this.noofyears)){
				let yr = new Date().getFullYear() + this.businesscalculatorinput.Rent.length;
				for(i = this.businesscalculatorinput.Rent.length; i < parseInt(this.noofyears); i++){
					let rentperyear = new RentPerYear
					rentperyear.Year = yr.toString()
					rentperyear.TotalSquareFootage = 0
					rentperyear.Cost = 0
					this.rent.push(rentperyear)
					yr = yr + 1	
				}
			}
			this.businesscalculatorinput.Rent = this.rent
			localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
		}
		
		let employeetypes = JSON.parse(localStorage.getItem(LocalStorage.EMPLOYEE_TYPES));
		this.employeetypesArray = []
		if(employeetypes != null && employeetypes != undefined){
			for(i=0; i < employeetypes.length; i++){
				if(employeetypes[i].EmployeeType != "Other"){
				this.employeetypesArray.push({id: employeetypes[i].EmployeeTypeID, text: employeetypes[i].EmployeeType})
				}
			} 	
		}
		this.employeetypesArray.push(
		{id: employeetypes.findIndex(x => x.EmployeeTypeID === "Other").EmployeeTypeID, text: "Other"})
		this.emptype = this.employeetypesArray[0].id
			  
	
		
		this.iSForm = this.formBuilder.group({
			socialsecurity: new FormControl(null) ,
			medicare: new FormControl(null),
			federalunemployment: new FormControl(null),
			stateunemployment: new FormControl(null),
			COLAIncrease: new FormControl(null),
			staffperformancebonuspool: new FormControl(null),
			reppaypercentage: new FormControl(null),
			insurance: new FormControl(null),
			annualsalary: new FormControl(null),
			moreinfo: new FormControl(null),
			emptype: new FormControl(null),
			costperft2peryr: new FormControl(null),
			employeetype: new FormControl(null),
			employeefirstname: new FormControl(null),
			employeelastname: new FormControl(null),
			startyear: new FormControl(null),
			startmonth: new FormControl(null),
			endyear: new FormControl(null),
			endmonth: new FormControl(null),
			insurancebenefit: new FormControl(null),
			retirementbenefit: new FormControl(null),
			itbase: new FormControl(null),
			itperee: new FormControl(null),
			licensingbase: new FormControl(null),
			licensingrate: new FormControl(null),
			compliance: new FormControl(null),
			suppliesbase: new FormControl(null),
			suppliesperee: new FormControl(null),
			other: new FormControl(null),
			proservices: new FormControl(null),
			complianceservices: new FormControl(null),
			totalsquarefootageperyr: new FormControl(null),
			EandOinsurance: new FormControl(null),
			businessliabilityinsurance: new FormControl(null),
			workerscompensationinsurance: new FormControl(null),
			otherinsurancecosts: new FormControl(null),
			fillDownValue: new FormControl(null)
		} ); 	
	}
//#endregion

//#region ngOnDestroy, ngOnInit,ngAfterViewInit
	ngOnDestroy()
	{
		this.alertService.clear(); 
	} 

	ngOnInit() { 
    this.stepper = new Stepper(document.querySelector('#stepper-expense'), {
      linear: false,
      animation: true
	}) 
	this.currentStepperIndex =0
	this.changeStepper(this.currentStepperIndex)
	let agent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent;
	if(agent.UserRoleID== Constants.UserRoleID.Superuser)
		this.isShowDashboard = true
	}
    //this.calculatorComponent.LoadCalculator();
  
	ngAfterViewInit(){
		setTimeout(() => { 
			if(this.businesscalculatorinput.EmployeeDetails.length != 0){
				this.empDetailsArray = this.businesscalculatorinput.EmployeeDetails
				
			}
			this.setTravelMealsTrainingPerYearVals()
			this.setRentPerYearVals()
			this.removeEmployeesByNoOfYears()
			this.extendEmpCounts()
			if(this.employeetypesArray != undefined){
				this.setEmployeeType(this.employeetypesArray[0].id)
			}
		}, 50);
	} 
	actionshow: boolean = false;
	onFocus(){
		this.actionshow = !this.actionshow;
	  }
	  onBlur(){
		this.actionshow = false;
	  }
//#endregion
	 
//#region validations for all the inputs
	Validations(event){
		this.alertService.clear()
		let value :any =this.commonFunctions.RemoveThousandSeparatorFormat(event.target.value)
		var regexp = /^[0-9\.\-\/]+$/;
		if(event.target.id == "itbase"){
			this.itbase = this.commonFunctions.AddThousandSeparatorFormat(this.itbase)
			this.businesscalculatorinput.OtherExpenses.ITBase = parseFloat(this.commonFunctions.RemoveThousandSeparatorFormat(this.itbase))
		} else if(event.target.id == "itperee"){
			this.itperee = this.commonFunctions.AddThousandSeparatorFormat(this.itperee)
			this.businesscalculatorinput.OtherExpenses.ITPerEE = parseFloat(this.commonFunctions.RemoveThousandSeparatorFormat(this.itperee))
		} else if (event.target.id == "licensingbase"){
			this.licensingbase = this.commonFunctions.AddThousandSeparatorFormat(this.licensingbase)
			this.businesscalculatorinput.OtherExpenses.LicensingBase = parseFloat(this.commonFunctions.RemoveThousandSeparatorFormat(this.licensingbase))
		} else if (event.target.id == "other"){
			this.other = this.commonFunctions.AddThousandSeparatorFormat(this.other)
			this.businesscalculatorinput.OtherExpenses.Other = parseFloat(this.commonFunctions.RemoveThousandSeparatorFormat(this.other)) 
		} else if (event.target.id == "complianceservices"){
			this.complianceservices = this.commonFunctions.AddThousandSeparatorFormat(this.complianceservices)
			this.businesscalculatorinput.OtherExpenses.ComplianceServices = parseFloat(this.commonFunctions.RemoveThousandSeparatorFormat(this.complianceservices))
		} else if (event.target.id == "proservices"){
			this.proservices = this.commonFunctions.AddThousandSeparatorFormat(this.proservices)
			this.businesscalculatorinput.OtherExpenses.ProServices = parseFloat(this.commonFunctions.RemoveThousandSeparatorFormat(this.proservices))
		} else if (event.target.id == "suppliesbase"){
			this.suppliesbase = this.commonFunctions.AddThousandSeparatorFormat(this.suppliesbase)
			this.businesscalculatorinput.OtherExpenses.SuppliesBase = parseFloat(this.commonFunctions.RemoveThousandSeparatorFormat(this.suppliesbase)) 
		} else if (event.target.id == "suppliesperee"){
			this.suppliesperee = this.commonFunctions.AddThousandSeparatorFormat(this.suppliesperee)
			this.businesscalculatorinput.OtherExpenses.SuppliesPerEE = parseFloat(this.commonFunctions.RemoveThousandSeparatorFormat(this.suppliesperee))
		}else if(event.target.id == "annualsalary"){
			this.annualsalary = this.commonFunctions.AddThousandSeparatorFormat(this.annualsalary)
		} else if(event.target.id == "insurancebenefit"){
			this.insurancebenefit = this.commonFunctions.AddThousandSeparatorFormat(this.insurancebenefit)
			this.businesscalculatorinput.OtherPayrollItems.InsuranceBenefits =  parseFloat(this.commonFunctions.RemoveThousandSeparatorFormat(this.insurancebenefit))
		} else{
			if(value>100){
				this.alertService.error("Percentage should not exceed 100");
				event.target.value = 0
			} else{
				if(event.target.id == "socialsecurity"){
					this.socialsecurity = this.commonFunctions.AddThousandSeparatorFormat(this.socialsecurity)		
					this.businesscalculatorinput.PayrollTaxes.SocialSecurity =  this.commonFunctions.RemoveThousandSeparatorFormat(this.socialsecurity)
				} else if(event.target.id == "medicare"){
					this.medicare = this.commonFunctions.AddThousandSeparatorFormat(this.medicare)
					this.businesscalculatorinput.PayrollTaxes.Medicare =  this.commonFunctions.RemoveThousandSeparatorFormat(this.medicare)
				} else if(event.target.id == "federalunemployment"){
					this.federalunemployment = this.commonFunctions.AddThousandSeparatorFormat(this.federalunemployment)
					this.businesscalculatorinput.PayrollTaxes.FederalUnemployment =  this.commonFunctions.RemoveThousandSeparatorFormat(this.federalunemployment)
				} else if(event.target.id == "stateunemployment"){
					this.stateunemployment = this.commonFunctions.AddThousandSeparatorFormat(this.stateunemployment)
					this.businesscalculatorinput.PayrollTaxes.StateUnemployment =  this.commonFunctions.RemoveThousandSeparatorFormat(this.stateunemployment)
				} else if(event.target.id == "COLAIncrease"){
					this.COLAIncrease = this.commonFunctions.AddThousandSeparatorFormat(this.COLAIncrease) 
					this.businesscalculatorinput.OtherPayrollItems.COLAIncrease =  this.commonFunctions.RemoveThousandSeparatorFormat(this.COLAIncrease)
				} else if(event.target.id == "staffperformancebonuspool"){
					this.staffperformancebonuspool = this.commonFunctions.AddThousandSeparatorFormat(this.staffperformancebonuspool)
					this.businesscalculatorinput.OtherPayrollItems.StaffPerformanceBonusPool =  this.commonFunctions.RemoveThousandSeparatorFormat(this.staffperformancebonuspool)
				} else if(event.target.id == "retirementbenefit"){
					this.retirementbenefit = this.commonFunctions.AddThousandSeparatorFormat(this.retirementbenefit)
					this.businesscalculatorinput.OtherPayrollItems.RetirementBenefits =  this.commonFunctions.RemoveThousandSeparatorFormat(this.retirementbenefit)
				} else if(event.target.id == "licensingrate"){
					this.licensingrate = this.commonFunctions.AddThousandSeparatorFormat(this.licensingrate)
					this.businesscalculatorinput.OtherExpenses.LicensingRate =  this.commonFunctions.RemoveThousandSeparatorFormat(this.licensingrate)
				} 
				/* else if(event.target.id == "reppaypercentage" && event.target.value != ""){
					if(!(regexp.test(event.target.value))){
						this.reppaypercentage = parseFloat(this.reppaypercentage).toString().replace(/[^0-9.]/g, "")
					}
					this.businesscalculatorinput.RepPayPercentage =  parseFloat(this.reppaypercentage)
				} */
			}
		}
		localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
		localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");

	}
//#endregion

//#region validations for add employee fields
employeeValidations(){	
	this.alertService.clear()
	this.emptyendyear = false
	this.emptyendmonth = false

	let emptyendyear = this.endyear == "" || this.endyear == null || this.endyear == undefined
	let emptyendmonth = this.endmonth == "" || this.endmonth == null|| this.endmonth == undefined

	if(this.employeetype == "" || this.employeetype == null){
		this.alertService.error("Invalid Employee Type");
		return false
	}else if(this.employeefirstname == "" || this.employeefirstname == null){
		this.alertService.error("Invalid Employee First Name");
		return false

	}else if(this.employeelastname == "" || this.employeelastname == null){
		this.alertService.error("Invalid Employee Last Name");
		return false

	}else if(this.annualsalary == "" || this.annualsalary == null || (this.annualsalary == "0" &&
	(this.repdataid == null || this.repdataid == undefined || this.repdataid == ""))){
		this.alertService.error("Invalid Annual Salary");
		return false

	}else if(this.startyear == "" || this.startyear == null){
		this.alertService.error("Invalid Start Year");
		return false

	}else if(this.startmonth == "" || this.startmonth == null){
		this.alertService.error("Invalid Start Month");
		return false

	}else if(parseInt(this.startyear) > parseInt(this.endyear) || (emptyendyear && !emptyendmonth)){
		this.alertService.error("Invalid End Year");
		return false

	}else if((!emptyendyear && emptyendmonth) || (parseInt(this.startyear) == parseInt(this.endyear)) && (parseInt(this.endmonth) < parseInt(this.startmonth))){
		this.alertService.error("Invalid End Month");
		return false

	}else if(emptyendmonth && emptyendyear){
		this.endyear = this.years[this.years.length - 1].toString()
		this.endmonth = "12"
		this.emptyendmonth = true
		this.emptyendyear = true
		return true

	} else{
		return true
	}
}
//#endregion
	
//#region employee related methods
	setEmployeeType(event){
		if(event.target == undefined){
			this.emptype = event
		} else{
			this.emptype = event.target.value
		}
		if(this.employeetypesArray != undefined){
			this.employeetypesArray.forEach((e:any) => {
				if(this.emptype == e.id && e.text == "Other"){
					this.hidemoreinfo = false
				} else{
					this.hidemoreinfo = true
				}
			})
		}
		if(this.empDetailsArray != null && this.empDetailsArray != undefined){
			var index
			for(index = 0; index < this.empDetailsArray.length; index++){
				var details = this.empDetailsArray[index]
				if(details.EmployeeTypeID == this.emptype){
					if(details.Employees.length != 0){
						this.hidesummary = true
					} else{
						this.hidesummary = false
					}
					if(details.MoreInfo != null || details.MoreInfo != undefined){
						this.moreinfo = details.MoreInfo
					}
					details.EmployeeCounts.forEach((element:any) => {
						let tmp = element.Year.toString() + "," + element.Month.toString()
						let count = (<HTMLInputElement>document.getElementById(tmp))
						if(count != null || count != undefined){
							if(element.EmpCount != "" && element.EmpCount != null && element.EmpCount != "NaN"){
								count.value = element.EmpCount;
							} else{
								count.value = "0"
							}
						}
					})
					this.createEmployeesArray()
					return false
				} else if(index == this.empDetailsArray.length - 1){
					let i;
					let year = new Date().getFullYear();
					let empCounts: Array<EmployeeCount> = []
					for(i = 0; i < parseInt(this.noofyears); i++){
						empCounts.push({Year: year.toString(), Month: "1" ,EmpCount: 0},
						{Year: year.toString(), Month: "2" ,EmpCount: 0},				
						{Year: year.toString(), Month: "3" ,EmpCount: 0},
						{Year: year.toString(), Month: "4" ,EmpCount: 0},
						{Year: year.toString(), Month: "5" ,EmpCount: 0},
						{Year: year.toString(), Month: "6" ,EmpCount: 0},
						{Year: year.toString(), Month: "7" ,EmpCount: 0},
						{Year: year.toString(), Month: "8" ,EmpCount: 0},
						{Year: year.toString(), Month: "9" ,EmpCount: 0},
						{Year: year.toString(), Month: "10" ,EmpCount: 0},
						{Year: year.toString(), Month: "11" ,EmpCount: 0},
						{Year: year.toString(), Month: "12" ,EmpCount: 0})
		
						year = year + 1	
					}
					this.empDetailsArray.push({EmployeeTypeID: this.emptype, 
						EmployeeCounts: empCounts});
					this.setEmployeeType(event)
				}
				
			}
		}
		// setTimeout(() => { 
		// 	this.alertService.clear()
		// }, 100);
	}

	setMoreInfo(){
		this.alertService.clear()
		if(this.employeetypesArray != undefined){
			var other = this.employeetypesArray.findIndex(x => x.text === "Other");
		}
		var index;
		for(index = 0; index < this.empDetailsArray.length; index++){
			var details = this.empDetailsArray[index]
			if(details.EmployeeTypeID == this.employeetypesArray[other].id){
				details.MoreInfo = this.moreinfo
			}
			this.businesscalculatorinput.EmployeeDetails = this.empDetailsArray
		}

		localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
		localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
	}

	getEmpCount(event,year,month){
		if(this.empDetailsArray != null && this.empDetailsArray != undefined){
			var index
			for(index = 0; index < this.empDetailsArray.length; index++){
				var details = this.empDetailsArray[index]
				if(details.EmployeeTypeID == this.emptype){
					details.EmployeeCounts.forEach((empcount:any) => {
						if(empcount.Year == year && empcount.Month == month){
							let tmp = empcount.Year.toString() + "," + empcount.Month.toString()
							let count = (<HTMLInputElement>document.getElementById(tmp))
							if(event.target.value == "" || event.target.value == null){
								count.value = ""
								empcount.EmpCount = 0
							} else{
								count.value = event.target.value.replace(/\D/g, "")
								empcount.EmpCount = parseInt(event.target.value.replace(/\D/g, ""))
							}
							
						}
					})
					this.businesscalculatorinput.EmployeeDetails = this.empDetailsArray
					localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
					//localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
					return false
				} 
			}
		} else{
			this.empDetailsArray = []
			let i;
			let year = new Date().getFullYear();
			let empCounts: Array<EmployeeCount> = []
			for(i = 0; i < parseInt(this.noofyears); i++){
				empCounts.push({Year: year.toString(), Month: "1" ,EmpCount: 0},
				{Year: year.toString(), Month: "2" ,EmpCount: 0},				
				{Year: year.toString(), Month: "3" ,EmpCount: 0},
				{Year: year.toString(), Month: "4" ,EmpCount: 0},
				{Year: year.toString(), Month: "5" ,EmpCount: 0},
				{Year: year.toString(), Month: "6" ,EmpCount: 0},
				{Year: year.toString(), Month: "7" ,EmpCount: 0},
				{Year: year.toString(), Month: "8" ,EmpCount: 0},
				{Year: year.toString(), Month: "9" ,EmpCount: 0},
				{Year: year.toString(), Month: "10" ,EmpCount: 0},
				{Year: year.toString(), Month: "11" ,EmpCount: 0},
				{Year: year.toString(), Month: "12" ,EmpCount: 0})

				year = year + 1	
			}
			this.empDetailsArray.push({EmployeeTypeID: this.emptype,
				EmployeeCounts: empCounts});
			this.getEmpCount(event,year,month);
		}
	}


	addEmployee(){
		this.alertService.clear()
		this.initializeVariables()
		this.employeetype = this.emptype
		this.ModalRef = this.bsModalService.show(this.templateaddEmployeeData)
		this.closemodel = true
	}

	cancelModal(status){ 
		this.alertService.clear()
		this.initializeVariables()
		if(this.ModalRef != undefined){
			this.ModalRef.hide()
			this.closemodel = false
			if(status == "empadded"){
				//this.alertService.success("Employee Added Successfully");
				//this.setEmployeeType(this.employeetypesArray[this.employeetypesArray.findIndex(x => x.text === emptype)].id)
			}
		}
	}

	initializeVariables(){
		this.repdataid = ""
		this.employeefirstname = ""
		this.employeelastname = ""
		this.annualsalary = "0"
		this.startyear = "" 
		this.startmonth = ""
		this.endyear = ""
		this.endmonth = ""
		this.createEmployeesArray()
	}


	saveEmployee(empObject, saveemployee){
		// setTimeout(() => { 
		// 	this.alertService.clear()
		// }, 50);
		let empid = "none"
		if(saveemployee == false){
			this.employeetype = empObject.EmployeeTypeID
			empid = empObject.EmployeeID
			this.repdataid = empObject.RepDataID
			this.employeefirstname = empObject.EmployeeFirstName
			this.employeelastname = empObject.EmployeeLastName
			this.annualsalary = this.commonFunctions.AddThousandSeparatorFormat(empObject.AnnualSalary)
			this.startyear = empObject.StartYear
			this.startmonth = empObject.StartMonth
			this.endyear = empObject.EndYear
			this.endmonth = empObject.EndMonth
		}
		let emptype = this.employeetype
		if(this.employeeValidations() == true){
			let tmparray = []
			var i;
			for(i = parseInt(this.startyear); i < parseInt(this.endyear)+1; i++){
				var j;
				for(j = 1; j < 13; j++){
					if(i == parseInt(this.endyear) && j == parseInt(this.endmonth)+1){
						break
					} else if((i == parseInt(this.startyear) && j > parseInt(this.startmonth)-1) ||
					i != parseInt(this.startyear)){
						tmparray.push({year: i, month: j})
					}
				}
				
			}
			if(this.empDetailsArray != null && this.empDetailsArray != undefined){
				var index
				for(index = 0; index < this.empDetailsArray.length; index++){
					var details = this.empDetailsArray[index]
					if(details.EmployeeTypeID == this.employeetype){
						details.EmployeeCounts.forEach((element:any) => {
							tmparray.forEach((e:any) => {
								if(element.Year == e.year.toString() && element.Month == e.month.toString()){
									if(!saveemployee){
										if(element.EmpCount != 0){element.EmpCount = element.EmpCount - 1};
									} else{
										element.EmpCount = element.EmpCount + 1;
									}
								}
								
							})
						}) 
						if(!saveemployee && empid != "none"){
							details.Employees.splice(details.Employees.findIndex(x => x.EmployeeID === empid),1)
							
						} else{
							this.Employee = new Employee()
							this.Employee.EmployeeID = this.commonFunctions.getnewguid() 
							this.Employee.RepDataID = this.repdataid
							this.Employee.EmployeeFirstName = this.employeefirstname
							this.Employee.EmployeeLastName = this.employeelastname
							this.Employee.AnnualSalary = parseFloat(this.commonFunctions.RemoveThousandSeparatorFormat(this.annualsalary))
							this.Employee.StartYear = this.startyear
							this.Employee.StartMonth = this.startmonth
							if(this.emptyendmonth){
								this.endmonth = ""
							}
							if(this.emptyendyear){
								this.endyear = ""
							}
							this.Employee.EndYear = this.endyear
							this.Employee.EndMonth = this.endmonth
							this.Employee.EmployeeDetailID = details.EmployeeCounts[0].EmployeeDetailID
							if(details.Employees == null || details.Employees == undefined){
								details.Employees = []
							}
							details.Employees.push(this.Employee)

						}
					}					
				}
				this.businesscalculatorinput.EmployeeDetails = this.empDetailsArray
				localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
				localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
				//this.setEmployeeType(this.employeetypesArray[0].id)
				this.setEmployeeType(this.employeetype)
				this.emptype = emptype
			}
			if(this.closemodel){
				this.cancelModal("empadded")
			} 
			
		}
		
	}

	deleteEmployee(empObject,saveemployee){
		// setTimeout(() => { 
		this.alertService.clear()
		// }, 50);
		let emptype = empObject.EmployeeTypeID
		let repid = empObject.RepDataID
		let index = this.businesscalculatorinput.RepData.findIndex( x=> x.RepDataID === repid) 
		if(repid != null && repid != "" && repid != undefined && index != -1){
			this.alertService.info("Selected employee can be deleted only from the Rep Data")
		} else{
			if (confirm("Are you sure you want to delete selected employee?")) { 
				this.saveEmployee(empObject,saveemployee)
				this.initializeVariables()
				this.emptype = emptype
				this.alertService.success("Employee Deleted Successfully");
			}
		}
	}

	createEmployeesArray(){
		// setTimeout(() => { 
		// 	this.alertService.clear()
		// }, 100);
		if(this.businesscalculatorinput.EmployeeDetails != undefined && this.businesscalculatorinput.EmployeeDetails.length != 0){
				this.employeesArray = []
				let monthsarray = ["January", "February", "March", "April", "May", "June",
				"July", "August", "September", "October", "November", "December"]
				this.businesscalculatorinput.EmployeeDetails.forEach((e:any) => {
					if(e.Employees != null && e.Employees != undefined && e.EmployeeTypeID == this.emptype){
						e.Employees.forEach((employee:any) => {
							employee.EmployeeTypeID = e.EmployeeTypeID
							employee.StartMonthInWords = monthsarray[parseInt(employee.StartMonth) - 1]
							if(employee.EndMonth != "" && employee.EndMonth != null){
								employee.EndMonthInWords = monthsarray[parseInt(employee.EndMonth)- 1]
							}
							 
							if(employee.EndYear == "" || employee.EndYear == null){
								employee.EmptyEndYear = true
								employee.Description = employee.EmployeeFirstName + " " + employee.EmployeeLastName + " starts in " + employee.StartYear + " " + employee.StartMonthInWords + " - Annual Salary $" + employee.AnnualSalary
							}else{
								employee.EmrdptyEndYear = false
								employee.Description = employee.EmployeeFirstName + " " + employee.EmployeeLastName + " starts in " 
								+ employee.StartYear + " " + employee.StartMonthInWords +
								 " and ends in " + employee.EndYear + " " + employee.EndMonthInWords + " -" + " Annual Salary $" + employee.AnnualSalary
							}
							this.employeesArray.push(employee)
						})
					} 
				})
		}
	}

	//add emp counts for the employees having no end year or end month
	extendEmpCounts(){
		// setTimeout(() => { 
		// 	this.alertService.clear()
		// }, 50);
		if(this.empDetailsArray != null && this.empDetailsArray != undefined){
			var index
			for(index = 0; index < this.empDetailsArray.length; index++){
				var details = this.empDetailsArray[index]
				if(details.EmployeeCounts != null && details.EmployeeCounts != undefined){
					details.EmployeeCounts.forEach((empcount:any) => {
						empcount.EmpCount = 0
					}) 
				}	
				
			}
		}


		if(this.empDetailsArray != null && this.empDetailsArray != undefined){
			var index
			for(index = 0; index < this.empDetailsArray.length; index++){
				var details = this.empDetailsArray[index]
				this.employeetype = details.EmployeeTypeID
				let tmpemployeearray = details.Employees
				details.Employees = []
					if(tmpemployeearray != null && tmpemployeearray != undefined){
						tmpemployeearray.forEach((element:any) => {
						this.repdataid = element.RepDataID
						this.employeefirstname = element.EmployeeFirstName
						this.employeelastname = element.EmployeeLastName
						this.annualsalary = this.commonFunctions.AddThousandSeparatorFormat(element.AnnualSalary)
						this.startyear = element.StartYear
						this.startmonth = element.StartMonth
						this.endyear = element.EndYear
						this.endmonth = element.EndMonth						
						this.saveEmployee(element,true)
						this.initializeVariables()
					})				
				}
			}
		}
	}

	//remove the employees when decreasing the no of years
	removeEmployeesByNoOfYears(){
		if(this.empDetailsArray != null && this.empDetailsArray != undefined){
			var index
			for(index = 0; index < this.empDetailsArray.length; index++){
				var details = this.empDetailsArray[index]
				if(details.EmployeeCounts != null && details.EmployeeCounts != undefined){
					details.EmployeeCounts.forEach((empcount:any) => {
						if(parseInt(empcount.Year) > this.years[this.years.length - 1]){
							empcount.EmpCount = 0
						}
					}) 
				}	
				if(details.Employees != null && details.Employees != undefined){
					let tmparray = []
					details.Employees.forEach((emp:any) => {
						if(parseInt(emp.EndYear) > this.years[this.years.length - 1]){
							emp.EndYear = this.years[this.years.length - 1]
							emp.EndMonth = 12
						}
						if(!(parseInt(emp.StartYear) > this.years[this.years.length - 1])){
							tmparray.push(emp)
						}
					})
					details.Employees = tmparray;
				}
				
			}
			this.businesscalculatorinput.EmployeeDetails = this.empDetailsArray
			localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
			//localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
			//this.createEmployeesArray()
		}
	}

//#endregion

//#region other expenses related methods
	setTravelMealsTrainingPerYearVals(){
		this.travelmealstrainingperyear.forEach((e:any) => {
			let tmptravel = "TravelMealsPerYear," + e.Year
			let tmptraining = "TrainingPerYear," + e.Year
			let travelcount = (<HTMLInputElement>document.getElementById(tmptravel))
			let trainingcount = (<HTMLInputElement>document.getElementById(tmptraining))
			travelcount.value = e.EventCost != null
			? this.commonFunctions.AddThousandSeparatorFormat(e.EventCost) : 0 
			trainingcount.value = e.TrainingCost != null
			? this.commonFunctions.AddThousandSeparatorFormat(e.TrainingCost) : 0		
		})
	}

	setTravelMealsTrainingExpenses(event){
		this.travelmealstrainingperyear.forEach((e:any) => {
			if(e.Year == event.target.id.split(',')[1]){
				if(event.target.id.split(',')[0] == "TravelMealsPerYear"){
					e.EventCost = this.commonFunctions.RemoveThousandSeparatorFormat(event.target.value)
				} else{
					e.TrainingCost = this.commonFunctions.RemoveThousandSeparatorFormat(event.target.value)
				}
				
			}			
		})
		this.setTravelMealsTrainingPerYearVals()
		this.businesscalculatorinput.OtherExpenses.TravelMealsTraining = this.travelmealstrainingperyear
		localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
		localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
	}

//#endregion

//#region rent related methods
	setRentPerYearVals(){
		this.rent.forEach((e:any) => {
			let tmpTotalSquareFootage = "TotalSquareFootage," + e.Year
			let tmpCost = "Cost," + e.Year
			let TotalSquareFootage = (<HTMLInputElement>document.getElementById(tmpTotalSquareFootage))
			let Cost = (<HTMLInputElement>document.getElementById(tmpCost))
			TotalSquareFootage.value = e.TotalSquareFootage != null
			? this.commonFunctions.AddThousandSeparatorFormat(e.TotalSquareFootage): 0
			Cost.value = e.Cost != null
			? this.commonFunctions.AddThousandSeparatorFormat(e.Cost): 0 
		})
	}

	setRentPerYear(event){
		this.rent.forEach((e:any) => {
			let rent = event.target.id.split(',')
			if(e.Year == rent[1]){
				if(rent[0] == "TotalSquareFootage"){
					e.TotalSquareFootage = this.commonFunctions.RemoveThousandSeparatorFormat(event.target.value)
				} else if(rent[0] == "Cost"){
					e.Cost = this.commonFunctions.RemoveThousandSeparatorFormat(event.target.value)
				}
				
			}			
		})
		this.setRentPerYearVals()
		this.businesscalculatorinput.Rent = this.rent
		localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
		localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
	}
//#endregion

//#region insurance related methods
setInsurancePerYearVals(){
	// setTimeout(() => { 
	// 	this.alertService.clear()
	// }, 50);
	this.businessinsurances.forEach((e:any) => {
		let tmpeandoinsurance = "EAndOInsurance," + e.Year
		let tmpbusinessliabilityinsurance = "BusinessLiabilityInsurance," + e.Year
		let tmpworkerscompensationinsurance = "WorkersCompensationInsurance," + e.Year
		let tmpotherinsurancecosts = "OtherInsuranceCosts," + e.Year
		let eandoinsurancecount = (<HTMLInputElement>document.getElementById(tmpeandoinsurance))
		let businessliabilityinsurancecount = (<HTMLInputElement>document.getElementById(tmpbusinessliabilityinsurance))
		let workerscompensationinsurancecount = (<HTMLInputElement>document.getElementById(tmpworkerscompensationinsurance))
		let otherinsurancecostscount = (<HTMLInputElement>document.getElementById(tmpotherinsurancecosts))
		eandoinsurancecount.value = e.EAndOInsurance != null
		? this.commonFunctions.AddThousandSeparatorFormat(e.EAndOInsurance) : 0 
		businessliabilityinsurancecount.value = e.BusinessLiabilityInsurance != null
		? this.commonFunctions.AddThousandSeparatorFormat(e.BusinessLiabilityInsurance) : 0	
		workerscompensationinsurancecount.value = e.WorkersCompensationInsurance != null
		? this.commonFunctions.AddThousandSeparatorFormat(e.WorkersCompensationInsurance) : 0 
		otherinsurancecostscount.value = e.OtherInsuranceCosts != null
		? this.commonFunctions.AddThousandSeparatorFormat(e.OtherInsuranceCosts) : 0	
	})
}

setBusinessInsurances(event){
	this.alertService.clear()
	this.businessinsurances.forEach((e:any) => {
		if(e.Year == event.target.id.split(',')[1]){
			if(event.target.id.split(',')[0] == "EAndOInsurance"){
				e.EAndOInsurance = this.commonFunctions.RemoveThousandSeparatorFormat(event.target.value)
			} else if(event.target.id.split(',')[0] == "BusinessLiabilityInsurance"){
				e.BusinessLiabilityInsurance = this.commonFunctions.RemoveThousandSeparatorFormat(event.target.value)
			} else if(event.target.id.split(',')[0] == "WorkersCompensationInsurance"){
				e.WorkersCompensationInsurance = this.commonFunctions.RemoveThousandSeparatorFormat(event.target.value)
			} else if(event.target.id.split(',')[0] == "OtherInsuranceCosts"){
				e.OtherInsuranceCosts = this.commonFunctions.RemoveThousandSeparatorFormat(event.target.value)
			}
			
		}			
	})
	this.setInsurancePerYearVals()
	this.businesscalculatorinput.BusinessInsurances = this.businessinsurances
	localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
	localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
}

CommaFormatted(){
	this.fillDownValue = this.commonFunctions.AddThousandSeparatorFormat(this.fillDownValue)
}

OpenFillDownModal(template: TemplateRef<any>, colType: string) { 
	 this.alertService.clear()
	 this.currentColType = colType;
	 this.fillDownValue ="";
	 this.ModalRef = this.bsModalService.show(this.templatefilldown);
}

FillDown()
{
  this.alertService.clear()
  //validate 
  if (this.fillDownValue == undefined || this.fillDownValue == "")
  { 
    this.alertService.error('Please enter fill down value.');
  } else{
	switch (this.currentColType)
	{
	  case 'EAndOInsurance':
		  this.businessinsurances.forEach( (element) => {
		  element.EAndOInsurance = this.fillDownValue;
		}); 
		break; 
	  case 'BusinessLiabilityInsurance':
		  this.businessinsurances.forEach( (element) => {
		  element.BusinessLiabilityInsurance = this.fillDownValue;
		}); 
		break; 
	  case 'WorkersCompensationInsurance':
		  this.businessinsurances.forEach( (element) => {
		  element.WorkersCompensationInsurance = this.fillDownValue;
		}); 
		break; 
	  case 'OtherInsuranceCosts':
		  this.businessinsurances.forEach( (element) => {
		  element.OtherInsuranceCosts = this.fillDownValue;
		}); 
		break;   
	}    
  
	//Adding businesscalculatorinput to the local storage
	this.setInsurancePerYearVals()
	this.businesscalculatorinput.BusinessInsurances = this.businessinsurances
	localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
	localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
	this.ModalRef.hide(); 
  }
}

// FillDownDefault(colType: string)
// {

//   this.currentColType = colType;
//   let businesscalculatordefaults   = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_DEFAULTS)) as BusinessCalculatorDefaults;

//   switch (this.currentColType)
//   {
// 	case 'EAndOInsurance':
// 		this.businessinsurances.forEach( (element) => {
//         element.EAndOInsurance = businesscalculatordefaults.EAndOInsurance;
//       }); 
//       break; 
//     case 'BusinessLiabilityInsurance':
// 		this.businessinsurances.forEach( (element) => {
//         element.BusinessLiabilityInsurance = businesscalculatordefaults.BusinessLiabilityInsurance;
//       }); 
//       break; 
//     case 'WorkersCompensationInsurance':
// 		this.businessinsurances.forEach( (element) => {
//         element.WorkersCompensationInsurance = businesscalculatordefaults.WorkersCompensationInsurance;
//       }); 
//       break; 
//     case 'OtherInsuranceCosts':
// 		this.businessinsurances.forEach( (element) => {
//         element.OtherInsuranceCosts = businesscalculatordefaults.OtherInsuranceCosts;
//       }); 
// 	  break;      
//   }  
//   //Adding businesscalculatorinput to the local storage
//   this.setInsurancePerYearVals()
//   this.businesscalculatorinput.BusinessInsurances = this.businessinsurances
//   localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));

// }

ClearDown(colType: string)
{
  this.alertService.clear()
  this.currentColType = colType;

  switch (this.currentColType)
  {
	case 'EAndOInsurance':
		this.businessinsurances.forEach( (element) => {
        element.EAndOInsurance = 0;
      }); 
      break; 
    case 'BusinessLiabilityInsurance':
		this.businessinsurances.forEach( (element) => {
        element.BusinessLiabilityInsurance = 0;
      }); 
      break; 
    case 'WorkersCompensationInsurance':
		this.businessinsurances.forEach( (element) => {
        element.WorkersCompensationInsurance = 0;
      }); 
      break; 
    case 'OtherInsuranceCosts':
		this.businessinsurances.forEach( (element) => {
        element.OtherInsuranceCosts = 0;
      }); 
	  break;     
  }   
  //Adding businesscalculatorinput to the local storage
  this.setInsurancePerYearVals()
  this.businesscalculatorinput.BusinessInsurances = this.businessinsurances
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
}

FillFirstValue(){
	this.alertService.clear()

	let EAndOInsurance = this.businessinsurances[0].EAndOInsurance
	let BusinessLiabilityInsurance = this.businessinsurances[0].BusinessLiabilityInsurance
	let WorkersCompensationInsurance = this.businessinsurances[0].WorkersCompensationInsurance
	let OtherInsuranceCosts = this.businessinsurances[0].OtherInsuranceCosts

	let i;
	for(i = 1; i < this.businessinsurances.length; i++){
		let e = this.businessinsurances[i];
		e.EAndOInsurance = EAndOInsurance
		e.BusinessLiabilityInsurance = BusinessLiabilityInsurance
		e.WorkersCompensationInsurance = WorkersCompensationInsurance
		e.OtherInsuranceCosts = OtherInsuranceCosts	
	}  

	this.setInsurancePerYearVals()
	this.businesscalculatorinput.BusinessInsurances = this.businessinsurances
	localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
	localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
}
//#endregion

exitBtnClickEvent()
{
  this.router.navigateByUrl('landingfp4fa'); 
}

//load the superuser dashboard page
public navigateSuperUserDashboard()
{
	this.router.navigateByUrl('superuserdashboard'); 
}
}





