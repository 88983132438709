<div class="main-panel pt-0 pb-4">
	<div class="container-fluid">
		<div class="col-sm-12 px-0">
			<h3 class="light-header bold">
				{{ 'INCSOU.LBL_TITLE' | translate}}
				<button type="button" class="btn btn-primary btn-sm float-right" (click)="exitBtnClickEvent()">
					<i class="fas fa-sign-out"></i> Exit
				</button>
				<button type="button" class="btn btn-primary btn-sm mr-1 float-right" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
					{{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
				</button>
			</h3>
			<hr class="mt-0 mb-1" />
			<div class="pt-3">
				<div class="container border rounded p-3 bold"> 
				<!--Account List Start-->
				<h5 class="light-header bold">
					{{ 'INCSOU.LBL_TABLE_TITLE' | translate}}
					<small class=" text-muted mx-1 vertical-line">|</small>
					<small class="h6 text-muted ml-2 bold"  >{{ activeTabName | translate}}
						<small class="h6 text-muted mx-1 vertical-line" *ngIf="isPresentedToClient">| </small>
						<span style="color: red" *ngIf="isPresentedToClient"> Presented to client</span>
						<!-- <div class="d-inline-block align-top vertical-line ml-1 mr-1"></div> -->
					</small>

					<div class="float-right">
						<button type="button" class="btn btn-primary btn-sm float-right ml-1" (click)="deleteBtnClickEvent()"
						[disabled]="isPresentedToClient">
							<i class="fas fa-minus"></i> Delete
						</button>
						<button type="button" class="btn btn-primary btn-sm float-right ml-1" (click)="copyBtnClickEvent()" 
						[disabled]="isPresentedToClient">
								<i class="fas fa-copy"></i> Copy
							</button>
						<button type="button" class="btn btn-primary btn-sm float-right ml-1" (click)="newBtnClickEvent()"
						[disabled]="isPresentedToClient">
							<i class="fas fa-plus"></i> New
						</button>
						
					</div>
				</h5>
				<hr class="mt-0 mb-3" />
				
				<div id="grid-wrapper" class="col-sm-12 px-0" style="width: 100%; overflow: hidden;">
						<div class="col-sm-11 px-0" style="float: left;" >
							<ag-grid-angular style="width: 100%; height: 300px; " class="ag-theme-balham bold" [rowData]="rowData"
								[columnDefs]="columnDefs" (gridReady)="onGridReady($event)" 
								(gridSizeChanged)="gridSizeChanged($event)"
								(cellClicked)="onCellClicked($event)"
								(selectionChanged)="onSelectionChanged($event)" 
								[headerHeight]="60" [gridOptions]="gridOptions" >
							</ag-grid-angular>
						</div>
						<div class="col-sm-1 px-0"  style="float: right; " > 
							<br><br><br>
								<div style=" display:table;  "  >
										<button type="button" style="width: 80px; margin-bottom: 2px;"  class="btn btn-primary btn-sm float-right ml-2"
												(click)="moveupBtnClickEvent()" [disabled]="isPresentedToClient">
												Move <i class="fas fa-angle-up"></i>
										</button> 
								</div> 
								<div style="display:table;" >
										<button type="button" style="width: 80px;" class="btn btn-primary btn-sm float-right ml-2"
											(click)="movedownBtnClickEvent()" [disabled]="isPresentedToClient">
												Move <i class="fas fa-angle-down"></i>
										</button>
								</div> 
							</div>
					</div> 
				</div> 
<!-- 
					<div style="width: 100%; overflow: hidden;">
							<div class="col-sm-10 px-0" style="float: left;background-color: red" > Left </div>
							<div style="background-color: blue"> Right </div>
						</div>
 -->
					
<!-- [enableRangeSelection]="true" -->
				<!--Account Details end-->
				<div class="container border rounded p-3 bold">
					<h5 class="light-header bold">
						{{ 'INCSOU.LBL_FORM_TITLE' | translate}}
					</h5>
					<hr class="mt-0 mb-3" />
					<form [formGroup]="iSForm">

						<!--	<div class="select-editable   form-group row ">
									<select onchange="this.nextElementSibling.value=this.value" >
										<option value=""></option>
										 
										<option value='Employment Income'>Employment Income</option>
										<option value='Social Security'>Social Security</option>
										<option value='Pension'>Pension</option>
										<option value=''>Or type in any value you want</option>
									</select>
									<input type="text" name="format" value="" />
								</div>-->


						<div class="form-group row">
							<label for="Owner" class="col-sm-2 col-form-label">Owner</label>
							<div class="col-sm-10"> 
								<!--   <select class="form-control" id="owner" 
									 [(ngModel)]="incomeSourceAccount.Owner" formControlName="owner"> 
									 <option></option>
									<option *ngFor="let item of ownerlist" [ngValue]="item.text">
										{{item.text}}
									</option>									
								</select>   --> 
 
								 <input type="text" list="owners" class="form-control bold" id="owner"  autocomplete="off"
											 [(ngModel)]="owner"  formControlName="owner"
											 (click)="ownersClicked()" 
											 (change)="ownerSelectedValueChanged(owner)" />
               						<datalist id="owners"  > 
										<option *ngFor="let item of ownerlist" [ngValue]="item.text">
												{{item.text}}
										</option>		
									</datalist>  
							</div>
						</div>
						<div class="form-group row">
							<label for="Source" class="col-sm-2 col-form-label">Source</label>
							<div class="col-sm-10">
								<input type="text" list="sources" class="form-control bold" id="source"   autocomplete="off"
											[(ngModel)]="source"  formControlName="source"
											(change)="sourcesClicked()" />
								<datalist id="sources" > 
										<option value='Employment Income'>Employment Income</option>
										<option value='Social Security'>Social Security</option>
										<option value='Pension'>Pension</option>
										<option value=''>Or type in any value you want</option>	
								</datalist>  

								<!-- <select class="form-control" id="source"  [(ngModel)]="incomeSourceAccount.Source"
										formControlName="source">  
									<option value='Employment Income'>Employment Income</option>
									<option value='Social Security'>Social Security</option>
									<option value='Pension'>Pension</option>
									<option value=''>Or type in any value you want</option>
								</select> -->
							</div>
						</div>
						<div class="form-group row">
							<label for="Start" class="col-sm-2 col-form-label">Start</label>
							<div class="col-sm-10">
								<select class="form-control bold" id="Start1"
								[(ngModel)]="startdatemonth" formControlName="startdate"> >
										<option *ngFor="let item of startyearlist" [ngValue]="item.value">
												{{item.text}}
										</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label for="End" class="col-sm-2 col-form-label">End</label>
							<div class="col-sm-10">
								<select class="form-control bold" id="End1"
								[(ngModel)]="enddatemonth" formControlName="enddate"> 
										<option *ngFor="let item of endyearlist" [ngValue]="item.value">
												{{item.text}}
										</option>
								</select>
							</div>
						</div>
						<div class="form-row">
							<label for="COLA" class="col-sm-2 col-form-label">COLA</label>
							<div class="form-group col-sm-4 ml-1">
								<select #cola class="form-control bold" id="cola"  (change)="changeCOLAFlag(cola.value)"
								[(ngModel)]="colaFlag" formControlName="cola">
								<option value='0'>No</option>
								<option value='1'>Yes</option>
								</select>
							</div>
							<div class="col">

								<input type="text" list="colapcts" class="form-control bold" id="colapct"  autocomplete="off"
									[(ngModel)]="colaPct"  formControlName="colapct"
									(click)="colapctsClicked()" 
									/>
								<datalist id="colapcts"  > 
										<option>0.00%</option>
										<option>1.00%</option>
										<option>2.00%</option>
										<option>3.00%</option>
										<option>4.00%</option>
										<option>5.00%</option>
										<option>6.00%</option>
										<option>7.00%</option> 
										<option >Or type in any value you want</option>
								</datalist>  
								<!-- <select class="form-control" id="End1"   
								[(ngModel)]="colaPct" formControlName="colapct" >
									<option>0.00%</option>
									<option>1.00%</option>
									<option>2.00%</option>
									<option>3.00%</option>
									<option>4.00%</option>
									<option>5.00%</option>
									<option>6.00%</option>
									<option>7.00%</option> 
									<option >Or type in any value you want</option>
								</select> -->
							</div>
							<div class="col">
								<label for="COLA % year" class="col-sm-10 col-form-label">COLA % Year</label>
							</div>
						</div>
						<div class="form-row" [hidden]="isSurvivorBenefitActive">
							<label for="SurvivorBenefit" class="col-sm-2 col-form-label">Survivor Benefit</label>
							<div class="form-group col-sm-4 ml-1">

								<select #survivorbenefit class="form-control bold" id="survivorbenefit"  (change)="changeSurvivorBenefitFlag(survivorbenefit.value)"
									[(ngModel)]="survivorBenefitFlag" formControlName="survivorbenefit">
									<option value='0'>No</option>
									<option value='1'>Yes</option>
								</select> 
							</div>
							<div class="col"> <!--[disabled]="!incomeSourceAccount.SurvivorBenefitFlag" -->
								<input type="text" list="survivorbenefitpcts" class="form-control bold"  autocomplete="off"
								id="survivorbenefitpct"  
								[(ngModel)]="survivorBenefitPct"  formControlName="survivorbenefitpct"
								(click)="survivorbenefitpctsClicked()" 
								/>
								<datalist id="survivorbenefitpcts"  > 
										<option>0.00%</option>
										<option>25.00%</option>
										<option>50.00%</option>
										<option>75.00%</option>
										<option>80.00%</option>
										<option>100.00%</option> 
										<option >Or type in any value you want</option>
								</datalist>  
							
								<!-- 	<select class="form-control" id="End1"  
									[(ngModel)]="survivorBenefitPct" formControlName="survivorbenefitpct" > 
									<option>0.00%</option>
									<option>25.00%</option>
									<option>50.00%</option>
									<option>75.00%</option>
									<option>80.00%</option>
									<option>100.00%</option> 
									<option >Or type in any value you want</option>
								</select> -->
							</div>
							<div class="col">
								<label for="benefit survivor" class="col-sm-10 col-form-label">% to benefit
									survivor</label>
							</div>
						</div>
						<div class="form-group row">
							<label for="inputEmail3" class="col-sm-2 col-form-label">Monthly Amount</label>
							<div class="col-sm-7 pr-2">
								<input type="text" class="form-control bold" id="monthlyamount" 
								[(ngModel)]="monthlyAmount"   formControlName="monthlyamount"
								(change)="monthlyAmountChanged(monthlyAmount)" 
								(input)="onMonthlyAmountTextChange($event.target.value)" 
								(keyup)="CommaFormatted($event)">
							</div>
							<div class="col">
								<!--<label for="benefit survivor" class="col-sm-8 col-form-label pl-0" 
									[(ngModel)]="annulAmount" formControlName="annualamount"> </label>-->
									<input type="text" class="form-control bold" id="annualamount" 
									[(ngModel)]="annualAmount" formControlName="annualamount" readonly>	
								<!--	<label for="benefit survivor" class="col-sm-4 col-form-label">yearly</label>-->
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col-md-0">
								<button type="button" class="btn btn-primary btn-sm" (click)="saveBtnClickEvent()"
								[disabled]="isPresentedToClient"> <i class="far fa-save"></i> Save</button>
							</div>
							<div class="form-group col-md-0">
								<button type="button" class="btn btn-primary btn-sm" (click)="cancelBtnClickEvent()"
								[disabled]="isPresentedToClient"> <i class="far fa-times"></i> Cancel</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>