import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../util/localstorage.service'; 
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Agent } from '../models/agent.model';
import { AlertService } from '../util/alert/alert.service';
import { BsModalService,BsModalRef}   from 'ngx-bootstrap/modal';
import { SharedServiceService } from '../util/shared-service.service';
import { MondayallocationrequestDataService } from './mondayallocationrequest-data.service';
import { MondayAllocation } from '../models/monday-allocation.model';
import { GridOptions } from 'ag-grid-community'
import { CustomHeader} from '../util//customHeader'
import { Constants } from '../util/constants.js';
import { MondayAllocationCustomCellComponent } from './monday-allocation-custom-cell/monday-allocation-custom-cell.component';
import { OrderArrayByTypePipe } from '../util/order-array-by-type.pipe';

@Component({
  selector: 'app-mondayallocationrequest',
  templateUrl: './mondayallocationrequest.component.html',
  styleUrls: ['./mondayallocationrequest.component.scss']
})

export class MondayAllocationRequestComponent implements OnInit {
  
  public paddingtop: number = 60;
  public selectedChoiceID: string = ""; 
  public loggedAgent: Agent 
  public iSForm: FormGroup;
  public iSFormAllocationRequestDetails: FormGroup;
  public uploadforms: FormData;
  public fileInfos: Array<any> = [];
  public file: string;
  public mondayallocation: MondayAllocation

  public name:any
  public advisorsArray: Array<any>
  public advisorname: any
  public companyname:any
  public login:any
  public lastname:any
  public firstname:any
  public lastnameclient2:any
  public firstnameclient2:any
  public accountname: any
  public accounttype: any
  public accountnumber: any
  public accounttypequalified: any
  public accounttypenonqualified: any
  public accounttypeentity: any
  public accountclassification: any
  public showAccountTypeQualified: boolean = true
  public showAccountTypeNonQualified: boolean = true
  public showAccountTypeEntity: boolean = true
  public custodian:any
  public instructiontype: any
  public highyieldcorporatebond: any
  public strategicenhancedbond: any
  public logicinvestsleepwellbond: any
  public cashiskingstrategy: any
  public strategichedgedincome: any
  public economiccycle: any
  public foundation: any
  public logicinvestglobalsector: any
  public logicinvestcountryrotation: any
  // public shareholdertotalreturn: any
  public primedividendinternational: any
  public usprimedividendjr: any
  public strategicmidcap: any
  public usprimedividend: any
  public NASDAQleaders: any
  public sectorrotation: any
  public valuediscount: any
  public valueexposure :any
  public accountsize: any
  public pendingannuities: any
  public heldpositions: any
  public notes: any
  private gridApi0: any;
  private gridColumnApi0: any;
  private gridApi1: any;
  private gridColumnApi1: any;
  private gridApi2: any;
  private gridColumnApi2: any;
  private gridApi3: any;
  private gridColumnApi3: any;
  public columnDefs0: any;
  public columnDefs1: any;
  public columnDefs2: any;
  public columnDefs3: any;
  public rowData0: any;	
  public rowData1: any;	
  public rowData2: any;
  public rowData3: any;
  public gridOptions0 : GridOptions 
  public gridOptions1 : GridOptions 
  public gridOptions2 : GridOptions 
  public gridOptions3 : GridOptions 
  public currentSelectedColumn;
  public totalPercentage: number = 0
  public showClient2Details:boolean = false
  private clearalert:boolean = false
  public disablesubmitbtn: boolean = false
  public isShowDashboard: boolean = false
  public ModalRef : BsModalRef 

  //#region allocation request history detail fields
  public submissionDateInPopUp: any
  public showSubmissionDate:boolean = true
  public groupInPopUp: any
  public showGroup:boolean = true
  public advisorNameInPopUp: any
  public showAdvisorName:boolean = true
  public companyNameInPopUp: any
  public showCompanyName:boolean = true
  public loginInPopUp: any
  public showLogin:boolean = true
  public accountClassificationInPopUp: any
  public showAccountClassification:boolean = true
  public accountNameInPopUp: any
  public showAccountName:boolean = true
  public accountNumberInPopUp: any
  public showAccountNumber:boolean = true
  public custodianInPopUp: any
  public showCustodian:boolean = true
  public instructionTypeInPopUp: any
  public showInstructionType:boolean = true
  public accountSizeInPopUp: any
  public showAccountSize:boolean = true
  public accountTypeInPopUp: any
  public showAccountType:boolean = true
  public pendingAnnuitiesInPopUp: any
  public showPendingAnnuities:boolean = true
  public strategicHedgedIncomeInPopUp: any
  public showStrategicHedgedIncome:boolean = true
  public highYieldCorporateBondInPopUp: any
  public showHighYieldCorporateBond:boolean = true
  public strategicEnhancedBondInPopUp: any
  public showStrategicEnhancedBond:boolean = true
  public sleepWellBondInPopUp: any
  public showSleepWellBond:boolean = true
  public cashIsKingStrategyInPopUp: any
  public showCashIsKingStrategy:boolean = true
  public uSPrimeDividendJrInPopUp: any
  public showUSPrimeDividendJr:boolean = true
  public foundationInPopUp: any
  public showFoundation:boolean = true
  public uSPrimeDividendInPopUp: any
  public showUSPrimeDividend:boolean = true
  public globalSectorInPopUp: any
  public showGlobalSector:boolean = true
  public primeDividendInternationalInPopUp: any
  public showPrimeDividendInternational:boolean = true
  public economicCycleInPopUp: any
  public showEconomicCycle:boolean = true
  public strategicMidCapInPopUp: any
  public showStrategicMidCap:boolean = true
  public countryRotationInPopUp: any
  public showCountryRotation:boolean = true
  // public shareholderTotalReturnInPopUp: any
  // public showShareholderTotalReturn:boolean = true
  public sectorRotationInPopUp: any
  public showSectorRotation:boolean = true
  public nASDAQLeadersInPopUp: any
  public showNASDAQLeaders:boolean = true
  public valueDiscountInPopUp: any
  public showValueDiscount:boolean = true
  public valueExposureInPopUp: any
  public showValueExposure:boolean = true
  public heldPositionsInPopUp: any
  public showHeldPositions:boolean = true
  public notesInPopUp: any
  public showNotes:boolean = true
  public showLowerRiskStrategiesLabel:boolean = true
  public showModerateRiskStrategiesLabel:boolean = true
  public showGrowthStrategiesLabel:boolean = true
  //#endregion

  @ViewChild('show_allocation_request_details') show_allocation_request_details: TemplateRef<any>

  getRowStyle: (params: any) => { "font-weight": string; };
  pinnedBottomRowData: any;
  frameworkComponents: { customPinnedRowRenderer: any; };

  constructor( private router: Router,
    private formBuilder: FormBuilder, private alertService: AlertService,
    private bsModalService :BsModalService, private mondayallocationrequestdataservice: MondayallocationrequestDataService,
    private sharedService: SharedServiceService) {

      this.gridOptions0 = { 
        context: {
          componentParent: this
        },
        components: {
          agColumnHeader: CustomHeader
        },
        defaultColDef: { 
          headerComponentParams: {
            setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
            getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          } 
        },
        groupHeaderHeight: 30,
        enableColResize: true
      };

      this.columnDefs0 = [
        { 
          headerName: 'Submission Date', 
          field: 'SubmissionDate',
          width: 90,
          cellStyle: {textAlign: "right"},
          lockPosition: true
        },
        { 
          headerName: 'Group Name', 
          field: 'Group',
          width: 100,
          cellStyle: {textAlign: "left"},
          lockPosition: true
        },
        { 
          headerName: 'Account Name', 
          field: 'AccountName',
          width: 100,
          cellStyle: {textAlign: "left"},
          lockPosition: true
        },
        { 
          headerName: 'Account Number', 
          field: 'AccountNumber',
          width: 100,
          cellStyle: {textAlign: "right"},
          lockPosition: true
        },
        { 
          headerName: 'Account Size', 
          field: 'AccountSize',
          width: 100,
          cellStyle: {textAlign: "right"},
          lockPosition: true
        },
        // { 
        //   headerName: 'Company Name', 
        //   field: 'CompanyName',
        //   editable: true,
        //   width: 100,
        //   cellStyle: {textAlign: "left"},
        //   lockPosition: TextTrackCueList
        // },
        // { 
        //   headerName: 'Login', 
        //   field: 'Login',
        //   width: 100, 
        //   cellStyle: {textAlign: "left"},
        //   lockPosition: true
        // },
        { 
          headerName: '', 
          field: '',
          width: 40, 
          cellStyle: {textAlign: "center"},
          lockPosition: true,
          cellRendererFramework: MondayAllocationCustomCellComponent,
        }     
      ];

      this.gridOptions1 = { 
        components: {
          agColumnHeader: CustomHeader
        },
        defaultColDef: { 
          headerComponentParams: {
            setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
            getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          } 
        },
        groupHeaderHeight: 30,
        enableColResize: true
      };

      this.columnDefs1 = [
        { 
          headerName: 'Investment Name', 
          field: 'InvestmentName',
          //sortable: true, 
          width: 200,
          cellStyle: {textAlign: "left"},
          lockPosition: true
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Min. Investment', 
          field: 'MinInvestment',
          width: 110,
          cellStyle: {textAlign: "right"},
          lockPosition: true
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: '%', 
          field: 'Percentage',
          editable: true,
          width: 60,
          cellStyle: {textAlign: "right"},
          lockPosition: true
          // sortable: true, 
          // filter: true,
          // cellClass: "rag-blue"
        },
        { 
          headerName: 'Mgmt Fee', 
          field: 'MgmtFee',
          width: 80, 
          cellStyle: {textAlign: "right"},
          lockPosition: true
          // sortable: true, 
          // filter: true,
          // cellClass: "rag-yellow"
        }        
      ];

      this.gridOptions2 = { 
          components: {
            agColumnHeader: CustomHeader
          },
          defaultColDef: { 
            headerComponentParams: {
              setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
              getSelectedColumn: () =>  this.getCurrentSelectedColumn()
            } 
          },
          //alignedGrids: [this.gridOptions1],
          groupHeaderHeight: 30,
          enableColResize: true  
      };
  
      this.columnDefs2 = [
        { 
          headerName: 'Investment Name', 
          field: 'InvestmentName',
          width: 200,
          cellStyle: {textAlign: "left"},
          lockPosition: true
          //sortable: true, 
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Min. Investment', 
          field: 'MinInvestment',
          width: 110,
          cellStyle: {textAlign: "right"},
          lockPosition: true
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: '%', 
          field: 'Percentage',
          editable: true,
          width: 60,
          cellStyle: {textAlign: "right"},
          lockPosition: true
          // sortable: true, 
          // filter: true,
          // cellClass: "rag-blue"
        },
        { 
          headerName: 'Mgmt Fee', 
          field: 'MgmtFee',
          width: 80,
          cellStyle: {textAlign: "right"},
          lockPosition: true
          // sortable: true, 
          // filter: true,
          // cellClass: "rag-yellow"
        }
      ];

      this.gridOptions3 = { 
        components: {
            agColumnHeader: CustomHeader
        },
        defaultColDef: { 
          headerComponentParams: {
              setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
              getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          } 
        },
        //alignedGrids: [this.gridOptions2],
        groupHeaderHeight: 30, 
        enableColResize: true  
      };
    
      this.columnDefs3 = [
          {
              headerName: 'Investment Name', 
              field: 'InvestmentName',
              width: 200,
              lockPosition: true,
              //sortable: true, 
              // width: 125,
              // filter: true//,
              //cellClass: "rag-blue"
              cellStyle:  function(params) {
                if (params.node.rowPinned){
                  return {backgroundColor: "#dcddde", textAlign: "right"}
                }else{
                  return {textAlign: "left"}
                }
                }
          },
          { 
              headerName: 'Min. Investment', 
              field: 'MinInvestment',
              width: 110,
              lockPosition: true,
              // filter: true//,
              //cellClass: "rag-blue"
              cellStyle:  function(params) {
                if (params.node.rowPinned){
                  return {backgroundColor: "#dcddde", textAlign: "left"}
                }else{
                  return {textAlign: "right"}
                }
              },
            },
            { 
              headerName: '%', 
              field: 'Percentage',
              width: 60,
              lockPosition: true,
              // sortable: true, 
              // filter: true,
              // cellClass: "rag-blue"
              cellStyle:  function(params) {
                if (params.node.rowPinned){
                  return {backgroundColor: "#dcddde", textAlign: "right"}
                }else{
                  return {textAlign: "right"}
                }
              },
              editable: function(params) {
                  if (params.node.rowPinned){
                    return false;
                  }else{
                    return true;
                  }
                }
              },
            { 
              headerName: 'Mgmt Fee', 
              field: 'MgmtFee',
              width: 80,
              cellStyle:  function(params) {
                if (params.node.rowPinned){
                  return {backgroundColor: "#dcddde", textAlign: "right"}
                }else{
                  return {textAlign: "right"}
                }
              }, 
                lockPosition: true
                // sortable: true, 
                // filter: true,
                // cellClass: "rag-yellow"
            },
        ];

      this.getRowStyle = function(params) {
          if (params.node.rowPinned) {
            return { "font-weight": "bold"};
          }
      };
    }

    onPinnedRowBottomCount() {
      var footerRowsToFloat = 10;
      var count = Number(footerRowsToFloat);
      var rows = createData(count, "Bottom", this.totalPercentage + "%");
      setTimeout(()=> {
      this.gridApi3.setPinnedBottomRowData(rows);
      }, 50); 
    }

    gridSizeChanged(params: any) {
      params.api.sizeColumnsToFit();
    }

  ngOnInit() {

   this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
   if(this.loggedAgent.UserRoleID== Constants.UserRoleID.Superuser)
    this.isShowDashboard = true

   this.login = this.loggedAgent.FirstName + " " + this.loggedAgent.LastName
    //#region allocation request fields in the form
   this.companyname = this.loggedAgent.OrganizationName
   this.iSForm = this.formBuilder.group({
      name: new FormControl(null) ,
      advisorname: new FormControl(null) ,
      companyname: new FormControl(null), 
      login: new FormControl(null),
      lastname: new FormControl(null),
      firstname: new FormControl(null),
      lastnameclient2: new FormControl(null),
      firstnameclient2: new FormControl(null),
      accountname: new FormControl(null) ,
      accounttype: new FormControl(null) ,
      accountclassification: new FormControl(null) ,
      accountnumber: new FormControl(null) ,
      accounttypequalified: new FormControl(null) ,
      accounttypenonqualified: new FormControl(null) ,
      accounttypeentity: new FormControl(null) ,
      custodian: new FormControl(null),
      instructiontype: new FormControl(null) ,
      highyieldcorporatebond: new FormControl(null) ,
      strategicenhancedbond: new FormControl(null) ,
      logicinvestsleepwellbond: new FormControl(null) ,
      cashiskingstrategy: new FormControl(null) ,
      strategichedgedincome: new FormControl(null) ,
      economiccycle: new FormControl(null) ,
      foundation: new FormControl(null) ,
      logicinvestglobalsector: new FormControl(null) ,
      logicinvestcountryrotation: new FormControl(null) ,
      // shareholdertotalreturn: new FormControl(null) ,
      primedividendinternational: new FormControl(null) ,
      usprimedividendjr: new FormControl(null) ,
      strategicmidcap: new FormControl(null) ,
      usprimedividend: new FormControl(null) ,
      NASDAQleaders: new FormControl(null) ,
      sectorrotation: new FormControl(null) ,
      valuediscount: new FormControl(null) ,
      valueexposure : new FormControl(null) ,
      accountsize : new FormControl(null),
      pendingannuities: new FormControl(null) ,
      heldpositions: new FormControl(null) ,
      notes: new FormControl(null) ,
      file: new FormControl(null)
    } ); 
    //#endregion

    //#region allocation request history detail fields in the pop up
    this.iSFormAllocationRequestDetails = this.formBuilder.group({
      submissionDateInPopUp: new FormControl(null) ,
      groupInPopUp: new FormControl(null) ,
      advisorNameInPopUp: new FormControl(null) ,
      companyNameInPopUp: new FormControl(null), 
      loginInPopUp: new FormControl(null),
      accountClassificationInPopUp: new FormControl(null),
      accountNameInPopUp: new FormControl(null),
      accountNumberInPopUp: new FormControl(null),
      custodianInPopUp: new FormControl(null),
      instructionTypeInPopUp: new FormControl(null) ,
      accountSizeInPopUp: new FormControl(null) ,
      accountTypeInPopUp: new FormControl(null) ,
      pendingAnnuitiesInPopUp: new FormControl(null) ,
      strategicHedgedIncomeInPopUp: new FormControl(null) ,
      highYieldCorporateBondInPopUp: new FormControl(null) ,
      strategicEnhancedBondInPopUp: new FormControl(null) ,
      sleepWellBondInPopUp: new FormControl(null),
      cashIsKingStrategyInPopUp: new FormControl(null),
      uSPrimeDividendJrInPopUp: new FormControl(null) ,
      foundationInPopUp: new FormControl(null) ,
      uSPrimeDividendInPopUp: new FormControl(null) ,
      globalSectorInPopUp: new FormControl(null) ,
      primeDividendInternationalInPopUp: new FormControl(null) ,
      economicCycleInPopUp: new FormControl(null) ,
      strategicMidCapInPopUp: new FormControl(null) ,
      countryRotationInPopUp: new FormControl(null) ,
      // shareholderTotalReturnInPopUp: new FormControl(null) ,
      sectorRotationInPopUp: new FormControl(null) ,
      nASDAQLeadersInPopUp: new FormControl(null) ,
      valueDiscountInPopUp: new FormControl(null) ,
      valueExposureInPopUp: new FormControl(null) ,
      heldPositionsInPopUp: new FormControl(null) ,
      notesInPopUp: new FormControl(null) 
    } ); 
    //#endregion

    this.getAdvisors()
    this.pinnedBottomRowData = createData(1, "Bottom", this.totalPercentage + "%");
    if(this.gridApi3 != undefined){
      this.gridApi3.setPinnedBottomRowData(this.pinnedBottomRowData);
    }
  }

//#region ag grid related methods
  onGridReady0(params: any) { 
    this.rowData0 = []
		this.gridApi0  = params.api;
    this.gridColumnApi0 = params.columnApi;  
    this.gridApi0.showLoadingOverlay()  
    this.getAllocationHistory() 
    this.gridOptions0.api.sizeColumnsToFit();
  }
  
  onGridReady1(params: any) { 
    this.rowData1 = []
		this.gridApi1  = params.api;
		this.gridColumnApi1 = params.columnApi;  
    this.rowData1.push({InvestmentName: "Strategic Hedged Income",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "High Yield Corporate Bond",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Strategic Enhanced Bond",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Sleep Well Bond",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Cash is King Strategy",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"})    
    this.gridOptions1.api.hideOverlay()
    this.gridOptions1.api.setRowData(this.rowData1);
    //params.columnApi.autoSizeAllColumns();
    this.gridOptions1.api.sizeColumnsToFit();
  }

  onGridReady2(params: any) { 
    this.rowData2 = []
		this.gridApi2  = params.api;
		this.gridColumnApi2 = params.columnApi;  
    this.rowData2.push({InvestmentName: "Foundation",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "U. S. Prime Dividend",MinInvestment: "$20,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "U. S. Prime Dividend Jr",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Global Sector",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Prime Dividend International",MinInvestment: "$5,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Economic Cycle",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Strategic Mid Cap",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Country Rotation",MinInvestment: "$5,000",Percentage: "",MgmtFee: "0.75%"},
                      // {InvestmentName: "Shareholder Total Return",MinInvestment: "$20,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Sector Growth Strategy",MinInvestment: "$5,000",Percentage: "",MgmtFee: "0.75%"})    
    this.gridOptions2.api.hideOverlay()
    this.gridOptions2.api.setRowData(this.rowData2);
    //params.columnApi.autoSizeAllColumns();
    this.gridOptions2.api.sizeColumnsToFit();
  }

  onGridReady3(params: any) { 
    this.rowData3 = []
		this.gridApi3  = params.api;
		this.gridColumnApi3 = params.columnApi;  
    this.rowData3.push({InvestmentName: "NASDAQ Leaders",MinInvestment: "$20,000",Percentage: "",MgmtFee: "0.75%"}, 
                      {InvestmentName: "Value Discount",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Broad Value",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"})    
    this.gridOptions3.api.hideOverlay()
    this.gridOptions3.api.setRowData(this.rowData3);
    //params.columnApi.autoSizeAllColumns();
    this.gridOptions3.api.sizeColumnsToFit();
  }

  getCurrentSelectedColumn() {
    return this.currentSelectedColumn;
  }
    
  setCurrentSelectedColumn(colId) {
    this.currentSelectedColumn = colId;
  }

  onCellValueChanged(event: any)
  {
    this.gridOptions1.api.stopEditing();
    this.gridOptions2.api.stopEditing();
    this.gridOptions3.api.stopEditing();
    
    this.totalPercentage = 0
    this.rowData1.forEach(data => {
      if(data.Percentage != ""){
        this.totalPercentage = this.totalPercentage + Number(data.Percentage)
      }  
    });
    this.rowData2.forEach(data => {
      if(data.Percentage != ""){
        this.totalPercentage = this.totalPercentage + Number(data.Percentage)
      } 
    });
    this.rowData3.forEach(data => {
      if(data.Percentage != ""){
        this.totalPercentage = this.totalPercentage + Number(data.Percentage)
      } 
    });
    setTimeout(()=> {
      this.pinnedBottomRowData = createData(1, "Bottom", this.totalPercentage+ "%");
      if(this.clearalert){
        this.alertService.clear()
      }
      this.gridOptions1.api.setRowData(this.rowData1);
      this.gridOptions2.api.setRowData(this.rowData2);
      this.gridOptions3.api.setRowData(this.rowData3);
      this.gridApi3.setPinnedBottomRowData(this.pinnedBottomRowData);
    }, 50);
    if(event.newValue != ""){

      if(event.newValue.match(/^\d+$/)){
        if((this.totalPercentage < 100) || (this.totalPercentage == 100)){   
  
          switch (event.data.InvestmentName) {
            case "High Yield Corporate Bond":
              this.highyieldcorporatebond = event.newValue
              break;
            case "Strategic Enhanced Bond": 
              this.strategicenhancedbond = event.newValue
              break;
            case "Sleep Well Bond":
              this.logicinvestsleepwellbond = event.newValue
              break;
            case "Cash is King Strategy":
              this.cashiskingstrategy = event.newValue
              break;
            case "Strategic Hedged Income":
              this.strategichedgedincome = event.newValue
              break;
            case "Economic Cycle":
              this.economiccycle = event.newValue
              break;
            case "Foundation":
              this.foundation = event.newValue
              break;
            case "Global Sector":
              this.logicinvestglobalsector = event.newValue
              break;
            case "Country Rotation":
              this.logicinvestcountryrotation = event.newValue
              break;
            case "Prime Dividend International":
              this.primedividendinternational = event.newValue
              break;
            case "Strategic Mid Cap":
              this.strategicmidcap = event.newValue
              break;
            case "U. S. Prime Dividend":
              this.usprimedividend = event.newValue
              break;
            case "U. S. Prime Dividend Jr":
              this.usprimedividendjr= event.newValue
              break;
            // case "Shareholder Total Return":
            //   this.shareholdertotalreturn= event.newValue
            //   break;
            case "NASDAQ Leaders":
              this.NASDAQleaders = event.newValue
              break;
            case "Sector Growth Strategy":
              this.sectorrotation = event.newValue
              break;
            case "Value Discount":
              this.valuediscount = event.newValue
              break;
            case "Broad Value":
              this.valueexposure = event.newValue
              break;

             
            default:
          }  
          
          //Validate for Minimum Investment amount
          this.ValidateMinimumInvestment(event.data.MinInvestment,event.newValue,event.data.InvestmentName)
          
        } else{
          this.alertService.clear()
          this.alertService.error('Total % should not exceed 100%')
        }
      } else{
        this.alertService.clear()
        event.newValue.replace(/\D/g, "")
        this.alertService.error('Please enter a whole number for the percentage')
      }
    }
  
  }

  getAllocationHistory(){
    this.gridApi0.showLoadingOverlay()
    this.alertService.info('Previous allocation requests are loading. Please wait.')
    this.rowData0 = []
    const formData: FormData = new FormData();
    formData.append("Email", this.loggedAgent.Email);
    this.mondayallocationrequestdataservice.getallocationhistory(formData, this.loggedAgent).subscribe(data =>
    {
      var allocationhistorydata = JSON.parse(data)
      var SubmissionDate
      var Group
      var AdvisorName
      var CompanyName
      var Login
      var AccountName
      var AccountNumber
      var AccountClassification
      var AccountType
      var Custodian
      var InstructionType
      var Poeple
      var HighYieldCorporateBond
      var StrategicEnhancedBond
      var LogicInvestSleepWellBond
      var CashIsKingStrategy
      var StrategicHedgedIncome
      var EconomicCycle
      var Foundation
      var LogicInvestGlobalSector
      var LogicInvestCountryRotation
      var PrimeDividendInternational
      var StrategicMidCap
      var USPrimeDividend
      var NASDAQLeaders
      var SectorRotation
      var ValueDiscount
      var USPrimeDividendJr
      var ValueExposure
      // var ShareholderTotalReturn
      var AccountSize
      var PendingAnnuities
      var HeldPositions
      var Notes
      var Email

    if(allocationhistorydata.data != undefined){
      allocationhistorydata.data.items_page_by_column_values.items.forEach(item => {
          //add values to rowdata array
          Group = item.group.title
          item.column_values.forEach(cols => {
          if(cols.id == Constants.MondayAllocationColumns.SubmissionDate_ID){
            if(cols.text != null && cols.text != "" && cols.text != undefined){
              var dateObj = new Date(cols.text.split(" ")[0])
              var date = dateObj.getUTCDate()
              var month = dateObj.getUTCMonth() + 1
              var year = dateObj.getUTCFullYear()
              SubmissionDate = month.toString() + "/" + date.toString() + "/" + year.toString()
            }else{
              SubmissionDate = ""
            }
          }
          if(cols.id == Constants.MondayAllocationColumns.AdvisorName_ID){
            AdvisorName = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.CompanyName){
            CompanyName = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.Login){
            Login = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.AccountName_ID){
            AccountName = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.AccountNumber_ID){
            AccountNumber = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.AccountClassification_ID){
            AccountClassification = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.AccountType_ID){
            AccountType = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.Custodian_ID){
            Custodian = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.InstructionType_ID){
            InstructionType = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.Poeple_ID){
            Poeple = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.HighYieldCorporateBond_ID){
            HighYieldCorporateBond = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.StrategicEnhancedBond_ID){
            StrategicEnhancedBond = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.LogicInvestSleepWellBond_ID){
            LogicInvestSleepWellBond = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.CashIsKingStrategy_ID){
            CashIsKingStrategy = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.StrategicHedgedIncome_ID){
            StrategicHedgedIncome = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.EconomicCycle_ID){
            EconomicCycle = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.Foundation_ID){
            Foundation = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.LogicInvestGlobalSector_ID){
            LogicInvestGlobalSector = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.LogicInvestCountryRotation_ID){
            LogicInvestCountryRotation = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.PrimeDividendInternational_ID){
            PrimeDividendInternational = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.PrimeDividendInternational_ID){
            PrimeDividendInternational = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.StrategicMidCap_ID){
            StrategicMidCap = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.USPrimeDividend_ID){
            USPrimeDividend = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.NASDAQLeaders_ID){
            NASDAQLeaders = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.SectorRotation_ID){
            SectorRotation = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.ValueDiscount_ID){
            ValueDiscount = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.USPrimeDividendJr_ID){
            USPrimeDividendJr = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.ValueExposure_ID){
            ValueExposure = cols.text
          }
          // else if(cols.id == Constants.MondayAllocationColumns.ShareholderTotalReturn_ID){
          //   ShareholderTotalReturn = cols.text
          // }
          else if(cols.id == Constants.MondayAllocationColumns.AccountSize_ID){
            AccountSize = cols.text.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",").toString()
          }
          else if(cols.id == Constants.MondayAllocationColumns.PendingAnnuities_ID){
            PendingAnnuities = cols.text.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",").toString()
          }
          else if(cols.id == Constants.MondayAllocationColumns.HeldPositions_ID){
            HeldPositions = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.Notes_ID){
            Notes = cols.text
          }
          else if(cols.id == Constants.MondayAllocationColumns.Email_ID){
            Email = cols.text
          }
        });
        this.rowData0.push({
          SubmissionDate: SubmissionDate,
          Group: Group,
          AdvisorName: AdvisorName,
          CompanyName: CompanyName,
          Login: Login,
          AccountName: AccountName,
          AccountNumber: AccountNumber,
          AccountClassification: AccountClassification,
          AccountType: AccountType,
          Custodian: Custodian,
          InstructionType: InstructionType,
          Poeple: Poeple,
          HighYieldCorporateBond: HighYieldCorporateBond,
          StrategicEnhancedBond: StrategicEnhancedBond,
          LogicInvestSleepWellBond: LogicInvestSleepWellBond,
          CashIsKingStrategy: CashIsKingStrategy,
          StrategicHedgedIncome: StrategicHedgedIncome,
          EconomicCycle: EconomicCycle,
          Foundation: Foundation,
          LogicInvestGlobalSector: LogicInvestGlobalSector,
          LogicInvestCountryRotation: LogicInvestCountryRotation,
          PrimeDividendInternational: PrimeDividendInternational,
          StrategicMidCap: StrategicMidCap,
          USPrimeDividend: USPrimeDividend,
          NASDAQLeaders: NASDAQLeaders,
          SectorRotation: SectorRotation,
          ValueDiscount: ValueDiscount,
          USPrimeDividendJr: USPrimeDividendJr,
          ValueExposure: ValueExposure,
          // ShareholderTotalReturn: ShareholderTotalReturn,
          AccountSize: AccountSize,
          PendingAnnuities: PendingAnnuities,
          HeldPositions: HeldPositions,
          Notes: Notes,
          Email: Email})
      });
    }
      this.gridOptions0.api.setRowData(this.rowData0);
      //this.gridApi0.hideOverlay()
      setTimeout(() => { 
        this.alertService.clear()
      }, 50); 
      return this.rowData0
    },
    error => { 
        this.alertService.clear()
        this.alertService.error('Error occured')       
    });
  }
//#endregion

  showAllocationHistoryDetails(data){
    this.resetPopupFields()
    //assign data to the fields and hide the field if there is no data for the field
    this.submissionDateInPopUp = data.SubmissionDate
    if(this.submissionDateInPopUp == null || this.submissionDateInPopUp == undefined || this.submissionDateInPopUp == "")
    {this.showSubmissionDate = false}

    this.groupInPopUp = data.Group
    if(this.groupInPopUp == null || this.groupInPopUp == undefined || this.groupInPopUp == "")
    {this.showGroup = false}

    this.advisorNameInPopUp = data.AdvisorName
    if(this.advisorNameInPopUp == null || this.advisorNameInPopUp == undefined || this.advisorNameInPopUp == "")
    {this.showAdvisorName = false}

    this.companyNameInPopUp = data.CompanyName
    if(this.companyNameInPopUp == null || this.companyNameInPopUp == undefined || this.companyNameInPopUp == "")
    {this.showCompanyName = false}

    this.loginInPopUp = data.Login
    if(this.loginInPopUp == null || this.loginInPopUp == undefined || this.loginInPopUp == "")
    {this.showLogin = false}

    this.accountNameInPopUp = data.AccountName
    if(this.accountNameInPopUp == null || this.accountNameInPopUp == undefined || this.accountNameInPopUp == "")
    {this.showAccountName = false}

    this.accountNumberInPopUp = data.AccountNumber
    if(this.accountNumberInPopUp == null || this.accountNumberInPopUp == undefined || this.accountNumberInPopUp == "")
    {this.showAccountNumber = false}

    this.accountClassificationInPopUp = data.AccountClassification
    if(this.accountClassificationInPopUp == null || this.accountClassificationInPopUp == undefined || this.accountClassificationInPopUp == "")
    {this.showAccountClassification = false}

    this.accountTypeInPopUp = data.AccountType
    if(this.accountTypeInPopUp == null || this.accountTypeInPopUp == undefined || this.accountTypeInPopUp == "")
    {this.showAccountType = false}

    this.custodianInPopUp = data.Custodian
    if(this.custodianInPopUp == null || this.custodianInPopUp == undefined || this.custodianInPopUp == "")
    {this.showCustodian = false}

    this.instructionTypeInPopUp = data.InstructionType
    if(this.instructionTypeInPopUp == null || this.instructionTypeInPopUp == undefined || this.instructionTypeInPopUp == "")
    {this.showInstructionType = false}

    this.highYieldCorporateBondInPopUp = data.HighYieldCorporateBond
    if(this.highYieldCorporateBondInPopUp == null || this.highYieldCorporateBondInPopUp == undefined || this.highYieldCorporateBondInPopUp == "")
    {this.showHighYieldCorporateBond = false}
    else{
      this.highYieldCorporateBondInPopUp = data.HighYieldCorporateBond + "%"
    }

    this.strategicEnhancedBondInPopUp = data.StrategicEnhancedBond
    if(this.strategicEnhancedBondInPopUp == null || this.strategicEnhancedBondInPopUp == undefined || this.strategicEnhancedBondInPopUp == "")
    {this.showStrategicEnhancedBond = false}
    else{
      this.strategicEnhancedBondInPopUp = data.StrategicEnhancedBond + "%"
    }

    this.sleepWellBondInPopUp = data.LogicInvestSleepWellBond
    if(this.sleepWellBondInPopUp == null || this.sleepWellBondInPopUp == undefined || this.sleepWellBondInPopUp == "")
    {this.showSleepWellBond = false}
    else{
      this.sleepWellBondInPopUp = data.LogicInvestSleepWellBond + "%"
    }

    this.cashIsKingStrategyInPopUp = data.CashIsKingStrategy
    if(this.cashIsKingStrategyInPopUp == null || this.cashIsKingStrategyInPopUp == undefined || this.cashIsKingStrategyInPopUp == "")
    {this.showCashIsKingStrategy = false}
    else{
      this.cashIsKingStrategyInPopUp = data.CashIsKingStrategy + "%"
    }

    this.strategicHedgedIncomeInPopUp = data.StrategicHedgedIncome
    if(this.strategicHedgedIncomeInPopUp == null || this.strategicHedgedIncomeInPopUp == undefined || this.strategicHedgedIncomeInPopUp == "")
    {this.showStrategicHedgedIncome = false}
    else{
      this.strategicHedgedIncomeInPopUp = data.StrategicHedgedIncome + "%"
    }

    this.economicCycleInPopUp = data.EconomicCycle
    if(this.economicCycleInPopUp == null || this.economicCycleInPopUp == undefined || this.economicCycleInPopUp == "")
    {this.showEconomicCycle = false}
    else{
      this.economicCycleInPopUp = data.EconomicCycle + "%"
    }

    this.foundationInPopUp = data.Foundation
    if(this.foundationInPopUp == null || this.foundationInPopUp == undefined || this.foundationInPopUp == "")
    {this.showFoundation = false}
    else{
      this.foundationInPopUp = data.Foundation + "%"
    }

    this.globalSectorInPopUp = data.LogicInvestGlobalSector
    if(this.globalSectorInPopUp == null || this.globalSectorInPopUp == undefined || this.globalSectorInPopUp == "")
    {this.showGlobalSector = false}
    else{
      this.globalSectorInPopUp = data.LogicInvestGlobalSector + "%"
    }

    this.countryRotationInPopUp = data.LogicInvestCountryRotation
    if(this.countryRotationInPopUp == null || this.countryRotationInPopUp == undefined || this.countryRotationInPopUp == "")
    {this.showCountryRotation = false}
    else{
      this.countryRotationInPopUp = data.LogicInvestCountryRotation + "%"
    }

    this.primeDividendInternationalInPopUp = data.PrimeDividendInternational
    if(this.primeDividendInternationalInPopUp == null || this.primeDividendInternationalInPopUp == undefined || this.primeDividendInternationalInPopUp == "")
    {this.showPrimeDividendInternational = false}
    else{
      this.primeDividendInternationalInPopUp = data.PrimeDividendInternational + "%"
    }

    this.strategicMidCapInPopUp = data.StrategicMidCap
    if(this.strategicMidCapInPopUp == null || this.strategicMidCapInPopUp == undefined || this.strategicMidCapInPopUp == "")
    {this.showStrategicMidCap = false}
    else{
      this.strategicMidCapInPopUp = data.StrategicMidCap + "%"
    }

    this.uSPrimeDividendInPopUp = data.USPrimeDividend
    if(this.uSPrimeDividendInPopUp == null || this.uSPrimeDividendInPopUp == undefined || this.uSPrimeDividendInPopUp == "")
    {this.showUSPrimeDividend = false}
    else{
      this.uSPrimeDividendInPopUp = data.USPrimeDividend + "%"
    }

    this.nASDAQLeadersInPopUp = data.NASDAQLeaders
    if(this.nASDAQLeadersInPopUp == null || this.nASDAQLeadersInPopUp == undefined || this.nASDAQLeadersInPopUp == "")
    {this.showNASDAQLeaders = false}
    else{
      this.nASDAQLeadersInPopUp = data.NASDAQLeaders + "%"
    }

    this.sectorRotationInPopUp = data.SectorRotation
    if(this.sectorRotationInPopUp == null || this.sectorRotationInPopUp == undefined || this.sectorRotationInPopUp == "")
    {this.showSectorRotation = false}
    else{
      this.sectorRotationInPopUp = data.SectorRotation + "%"
    }

    this.valueDiscountInPopUp = data.ValueDiscount
    if(this.valueDiscountInPopUp == null || this.valueDiscountInPopUp == undefined || this.valueDiscountInPopUp == "")
    {this.showValueDiscount = false}
    else{
      this.valueDiscountInPopUp = data.ValueDiscount + "%"
    }

    this.uSPrimeDividendJrInPopUp = data.USPrimeDividendJr
    if(this.uSPrimeDividendJrInPopUp == null || this.uSPrimeDividendJrInPopUp == undefined || this.uSPrimeDividendJrInPopUp == "")
    {this.showUSPrimeDividendJr = false}
    else{
      this.uSPrimeDividendJrInPopUp = data.USPrimeDividendJr + "%"
    }

    this.valueExposureInPopUp = data.ValueExposure
    if(this.valueExposureInPopUp == null || this.valueExposureInPopUp == undefined || this.valueExposureInPopUp == "")
    {this.showValueExposure = false}
    else{
      this.valueExposureInPopUp = data.ValueExposure + "%"
    }

    // this.shareholderTotalReturnInPopUp = data.ShareholderTotalReturn
    // if(this.shareholderTotalReturnInPopUp == null || this.shareholderTotalReturnInPopUp == undefined || this.shareholderTotalReturnInPopUp == "")
    // {this.showShareholderTotalReturn = false}
    // else{
    //   this.shareholderTotalReturnInPopUp = data.ShareholderTotalReturn + "%"
    // }

    this.accountSizeInPopUp = data.AccountSize
    if(this.accountSizeInPopUp == null || this.accountSizeInPopUp == undefined || this.accountSizeInPopUp == "")
    {this.showAccountSize = false}

    this.pendingAnnuitiesInPopUp = data.PendingAnnuities
    if(this.pendingAnnuitiesInPopUp == null || this.pendingAnnuitiesInPopUp == undefined || this.pendingAnnuitiesInPopUp == "")
    {this.showPendingAnnuities = false}

    this.heldPositionsInPopUp = data.HeldPositions
    if(this.heldPositionsInPopUp == null || this.heldPositionsInPopUp == undefined || this.heldPositionsInPopUp == "")
    {this.showHeldPositions = false}

    this.notesInPopUp = data.Notes
    if(this.notesInPopUp == null || this.notesInPopUp == undefined || this.notesInPopUp == "")
    {this.showNotes = false}

    if(this.showStrategicHedgedIncome == false && this.showStrategicEnhancedBond == false
      && this.showHighYieldCorporateBond == false && this.showSleepWellBond == false && this.showCashIsKingStrategy == false)
    {this.showLowerRiskStrategiesLabel = false}

    if(this.showFoundation == false && this.showUSPrimeDividend == false
      && this.showUSPrimeDividendJr == false && this.showGlobalSector == false
      && this.showPrimeDividendInternational == false && this.showEconomicCycle == false
      && this.showStrategicMidCap == false && this.showCountryRotation == false
      && this.showSectorRotation == false)
      // && this.showShareholderTotalReturn == false && this.showSectorRotation == false)
    {this.showModerateRiskStrategiesLabel = false}

    if(this.showNASDAQLeaders == false && this.showValueDiscount == false
      && this.showValueExposure == false)
    {this.showGrowthStrategiesLabel = false}
    this.ModalRef = this.bsModalService.show(this.show_allocation_request_details)
  }

  resetPopupFields(){
    this.showSubmissionDate = true;
    this.showGroup = true;
    this.showAdvisorName = true;
    this.showCompanyName = true;
    this.showLogin = true;
    this.showAccountName = true;
    this.showAccountNumber = true;
    this.showAccountClassification = true;
    this.showAccountType = true;
    this.showCustodian = true;
    this.showInstructionType = true;
    this.showHighYieldCorporateBond = true;
    this.showStrategicEnhancedBond = true;
    this.showSleepWellBond = true;
    this.showCashIsKingStrategy = true;
    this.showStrategicHedgedIncome = true;
    this.showEconomicCycle = true;
    this.showFoundation = true;
    this.showGlobalSector = true;
    this.showCountryRotation = true;
    this.showPrimeDividendInternational = true;
    this.showStrategicMidCap = true;
    this.showUSPrimeDividend = true;
    this.showNASDAQLeaders = true;
    this.showSectorRotation = true;
    this.showValueDiscount = true;
    this.showUSPrimeDividendJr = true;
    this.showValueExposure = true;
    this.showAccountSize = true;
    this.showPendingAnnuities = true;
    this.showHeldPositions = true;
    this.showNotes = true;
    this.showLowerRiskStrategiesLabel = true;
    this.showModerateRiskStrategiesLabel = true;
    this.showGrowthStrategiesLabel = true;
  }

  cancelModal(){
    this.ModalRef.hide()
  }

  getAdvisors(){
    this.advisorsArray = []
    this.mondayallocationrequestdataservice.getcompanyadvisors(this.loggedAgent.OrganizationID, this.loggedAgent).subscribe(data =>
    {
      this.alertService.clear()
      if(data != null && data != undefined){
        var i
        for(i=0; i < data.length; i++){
          let AdvisorName = data[i].FirstName + " " + data[i].LastName
          this.advisorsArray.push({id: AdvisorName, text: AdvisorName})
        } 
      }
      if(this.advisorsArray[0] != undefined){
        this.advisorname = this.advisorsArray[0].id
      }
      return this.advisorsArray
      
    },
    error => { 
        this.alertService.clear()
        this.alertService.error('Error occured')       
    });
  }

  ValidateMinimumInvestment(minInvetment,newValue,investmentName):boolean
  { 
     let currentAccountSize 
     let currentPendingAnnuity
     let currentTotalAccountSize
     let currentMinInvestment = Number(minInvetment.replace('$','').replace(/,/g,''))
     let currentInvetment 
    // if ((isNaN(this.accountsize) || this.accountsize == undefined || this.accountsize == null ))
     if (this.accountsize == undefined || this.accountsize == null )
     { 
       currentAccountSize = 0;
     }  
     else 
     {
       currentAccountSize = Number(this.accountsize.replace('$','').replace(/,/g,''));
     }
 

     //if ((isNaN(this.pendingannuities) || this.pendingannuities == undefined || this.pendingannuities == null ))
     if (this.pendingannuities == undefined || this.pendingannuities == null )
     { 
       currentPendingAnnuity = 0;
     }  
     else 
     {
       currentPendingAnnuity = Number(this.pendingannuities.replace('$','').replace(/,/g,''));
     }
     currentTotalAccountSize = currentAccountSize - currentPendingAnnuity;
     if (currentTotalAccountSize < 0){ 
      this.alertService.clear()
      this.alertService.error('Pending annuity should be less than the account size');
      return false
      } 
     currentInvetment = (currentTotalAccountSize*Number(newValue))/100
     if (currentInvetment<currentMinInvestment){ 
         this.alertService.error('Investment is less than the minimum value for ' + investmentName);
         return false
     } 
     else 
      return true;
  }

  validateMondayAllocationForm():boolean
  { 
    let percentageval = false
    if ((this.iSForm.controls.advisorname.value == ""  || 
      this.iSForm.controls.advisorname.value == null))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Advisor Name')
      return false
    } 

    if ((this.iSForm.controls.companyname.value == ""  || 
      this.iSForm.controls.companyname.value == null))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Company Name')
      return false
    }

    if ((this.iSForm.controls.login.value == ""  || 
      this.iSForm.controls.login.value == null))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Login')
      return false
    }

    if ((this.iSForm.controls.lastname.value == ""  || 
          this.iSForm.controls.lastname.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Client Last Name')
      return false
    }  

    if ((this.iSForm.controls.firstname.value == ""  || 
          this.iSForm.controls.firstname.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Client First Name')
      return false
    }  

    if(this.showClient2Details){
      if ((this.iSForm.controls.lastnameclient2.value == ""  || 
      this.iSForm.controls.lastnameclient2.value == null ))
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Client 2 Last Name')
        return false
      }  

      if ((this.iSForm.controls.firstnameclient2.value == ""  || 
            this.iSForm.controls.firstnameclient2.value == null ))
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Client 2 First Name')
        return false
      }  
    }

    if ((this.iSForm.controls.accountclassification.value == ""  || this.iSForm.controls.accountclassification.value == "---" ||
    this.iSForm.controls.accountclassification.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Account Classification')
      return false
    }

    if (((this.accounttypequalified == "" || this.accounttypequalified == "---" || this.accounttypequalified == null) &&
      (this.accounttypenonqualified == "" || this.accounttypenonqualified == "---" || this.accounttypenonqualified == null) &&
      (this.accounttypeentity == "" || this.accounttypeentity == "---" || this.accounttypeentity == null)))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Account Type')
      return false
    } 

    if ((this.iSForm.controls.accountname.value == ""  || 
          this.iSForm.controls.accountname.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Account Name')
      return false
    }   
 
    if ((this.iSForm.controls.accountnumber.value == ""  || 
          this.iSForm.controls.accountnumber.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Account Number')
      return false
    }  

    if ((this.iSForm.controls.custodian.value == ""  || this.iSForm.controls.custodian.value == "---" ||
          this.iSForm.controls.custodian.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Custodian')
      return false
    } 
    

    if ((this.iSForm.controls.instructiontype.value == ""  || this.iSForm.controls.instructiontype.value == "---" ||
          this.iSForm.controls.instructiontype.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Instruction Type')
      return false
    } 
    this.rowData1.forEach(data => {
      if(!data.Percentage.match(/^\d+$/) && data.Percentage != ""){
        percentageval = true
      }  
    });
    this.rowData2.forEach(data => {
      if(!data.Percentage.match(/^\d+$/) && data.Percentage != ""){
        percentageval = true
      } 
    });
    this.rowData3.forEach(data => {
      if(!data.Percentage.match(/^\d+$/) && data.Percentage != ""){
        percentageval = true
      } 
    });
    if(percentageval){
      this.alertService.clear()
      this.alertService.error('Please enter a whole number for the percentage')
      return false
    }
    if ((this.totalPercentage > 100))
    { 
      this.alertService.clear()
      this.alertService.error('Total % should not exceed 100%')
      return false
    }
    if ((isNaN(this.totalPercentage) || this.totalPercentage == undefined || this.totalPercentage == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid percentage value')
      return false
    } else{
      if(this.totalPercentage > 100 || this.totalPercentage < 100){
        this.alertService.clear()
        this.alertService.error('Percentage value should be 100%')
        return false
      }
    }
    // if (this.iSForm.controls.highyieldcorporatebond.value != ""  && 
    //       this.iSForm.controls.highyieldcorporatebond.value != null && !(/^\d+$/.test(this.iSForm.controls.highyieldcorporatebond.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid High Yield Corporate Bond Value')
    //   return false
    // }

    // if (this.iSForm.controls.strategicenhancedbond.value != ""  && 
    //       this.iSForm.controls.strategicenhancedbond.value != null && !(/^\d+$/.test(this.iSForm.controls.strategicenhancedbond.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Strategic Enhanced Bond Value')
    //   return false
    // }


    // if (this.iSForm.controls.logicinvestsleepwellbond.value != ""  && 
    //       this.iSForm.controls.logicinvestsleepwellbond.value != null && !(/^\d+$/.test(this.iSForm.controls.logicinvestsleepwellbond.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Logic Invest Sleep Well Bond Value')
    //   return false
    // }


    // if (this.iSForm.controls.strategichedgedincome.value != ""  && 
    //       this.iSForm.controls.strategichedgedincome.value != null && !(/^\d+$/.test(this.iSForm.controls.strategichedgedincome.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Strategic Hedged Income Value')
    //   return false
    // }


    // if (this.iSForm.controls.economiccycle.value != ""  && 
    //       this.iSForm.controls.economiccycle.value != null && !(/^\d+$/.test(this.iSForm.controls.economiccycle.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Economic Cycle Value')
    //   return false
    // }


    // if (this.iSForm.controls.foundation.value != ""  && 
    //       this.iSForm.controls.foundation.value != null && !(/^\d+$/.test(this.iSForm.controls.foundation.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Foundation Value')
    //   return false
    // }


    // if (this.iSForm.controls.logicinvestglobalsector.value != ""  && 
    //       this.iSForm.controls.logicinvestglobalsector.value != null && !(/^\d+$/.test(this.iSForm.controls.logicinvestglobalsector.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Logic Invest Global Sector Value')
    //   return false
    // }


    // if (this.iSForm.controls.primedividendinternational.value != ""  && 
    //       this.iSForm.controls.primedividendinternational.value != null && !(/^\d+$/.test(this.iSForm.controls.primedividendinternational.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Prime Dividend International Value')
    //   return false
    // }

    // if (this.iSForm.controls.strategicmidcap.value != ""  && 
    //       this.iSForm.controls.strategicmidcap.value != null && !(/^\d+$/.test(this.iSForm.controls.strategicmidcap.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Strategic Mid Cap Value')
    //   return false
    // }


    // if (this.iSForm.controls.usprimedividend.value != ""  && 
    //       this.iSForm.controls.usprimedividend.value != null && !(/^\d+$/.test(this.iSForm.controls.usprimedividend.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid U.S Prime Dividend Value')
    //   return false
    // }    


    // if (this.iSForm.controls.NASDAQleaders.value != ""  && 
    //       this.iSForm.controls.NASDAQleaders.value != null && !(/^\d+$/.test(this.iSForm.controls.NASDAQleaders.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid NASDAQ Leaders Value')
    //   return false
    // }

    // if (this.iSForm.controls.sectorrotation.value != ""  && 
    //       this.iSForm.controls.sectorrotation.value != null && !(/^\d+$/.test(this.iSForm.controls.sectorrotation.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Sector Rotation Value')
    //   return false
    // }


    // if (this.iSForm.controls.valuediscount.value != ""  && 
    //       this.iSForm.controls.valuediscount.value != null && !(/^\d+$/.test(this.iSForm.controls.valuediscount.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Value Discount Value')
    //   return false
    // }


    if ((this.fileInfos == undefined  || this.fileInfos.length == 0 ||
      this.fileInfos == null ))
    { 
      this.alertService.clear()
      this.alertService.error('No file is selected')
      return false
    } else{
      var allowedExtensions = ['pdf', 'doc', 'docx', 'xlsx', 'pptx', 'jpg', 'jpeg', 'png', 'txt']; 
      for (let i = 0; i < this.fileInfos.length; i++) {
        var file = this.fileInfos[i];
        var fileExtension = file.name.split('.').pop().toLowerCase();
        // Check if the file extension is not allowed
        if (!allowedExtensions.includes(fileExtension)) {
          this.alertService.error(`File type .${fileExtension} is not allowed`);
          return false
        }
      };
    }

    //Minimum investment validation
    let passedMinInvestmentValidation: boolean = true;
    this.rowData1.forEach(data => {
      if(data.Percentage != ""){ 
        if (!this.ValidateMinimumInvestment(data.MinInvestment,Number(data.Percentage),data.InvestmentName))
        {
          passedMinInvestmentValidation = false;
        }
      }  
    });

    this.rowData2.forEach(data => {
      if(data.Percentage != ""){ 
        if (!this.ValidateMinimumInvestment(data.MinInvestment,Number(data.Percentage),data.InvestmentName))
        {
          passedMinInvestmentValidation = false;
        }
      }  
    });

    this.rowData3.forEach(data => {
      if(data.Percentage != ""){ 
        if (!this.ValidateMinimumInvestment(data.MinInvestment,Number(data.Percentage),data.InvestmentName))
        {
          passedMinInvestmentValidation = false;
        }
      }  
    });

    if (!passedMinInvestmentValidation)
    {
      return false;
    }
    return true
  }  

  initializeFormData(){
    this.advisorname = this.advisorsArray[0].id
    this.login = this.loggedAgent.FirstName + " " + this.loggedAgent.LastName
    this.companyname = this.loggedAgent.OrganizationName
    this.lastname = ""
    this.firstname = ""
    this.lastnameclient2 = ""
    this.firstnameclient2 = ""
    this.accountname = ""
    this.accountnumber = ""
    this.accountclassification = ""
    this.accounttypequalified = ""
    this.accounttypenonqualified = ""
    this.accounttypeentity = ""
    this.showClient2Details = false
    this.custodian = ""
    this.instructiontype = ""
    this.highyieldcorporatebond = ""
    this.strategicenhancedbond = ""
    this.logicinvestsleepwellbond =""
    this.cashiskingstrategy =""
    this.strategichedgedincome = ""
    this.economiccycle = ""
    this.foundation = ""
    this.logicinvestglobalsector = ""
    this.logicinvestcountryrotation = ""
    this.primedividendinternational = ""
    this.strategicmidcap = ""
    this.usprimedividend = ""
    this.usprimedividendjr = ""
    // this.shareholdertotalreturn = ""
    this.NASDAQleaders = ""
    this.sectorrotation = ""  
    this.valuediscount = ""
    this.valueexposure =""
    this.accountsize =""
    this.pendingannuities = ""
    this.heldpositions = ""
    this.notes = ""
    this.file = ""
    this.totalPercentage = 0
    this.fileInfos = []
    this.rowData1.forEach(data => {
      data.Percentage = ""
    });
    this.rowData2.forEach(data => {
      data.Percentage = ""
    });
    this.rowData3.forEach(data => {
      data.Percentage = ""
    });
    this.gridOptions1.api.setRowData(this.rowData1);
    this.gridOptions2.api.setRowData(this.rowData2);
    this.gridOptions3.api.setRowData(this.rowData3);
    this.pinnedBottomRowData = createData(1, "Bottom", this.totalPercentage + "%");
    if(this.gridApi3 != undefined){
      this.gridApi3.setPinnedBottomRowData(this.pinnedBottomRowData);
    }
  }

  assignStratergies(){
    this.clearalert = true

    this.gridOptions1.api.stopEditing();
    this.gridOptions2.api.stopEditing();
    this.gridOptions3.api.stopEditing();

    this.gridOptions1.api.setRowData(this.rowData1);
    this.gridOptions2.api.setRowData(this.rowData2);
    this.gridOptions3.api.setRowData(this.rowData3);

    this.totalPercentage = 0

    this.rowData1.forEach(data => {
      if(data.Percentage != ""){
        this.totalPercentage = this.totalPercentage + Number(data.Percentage)
      } 
      switch (data.InvestmentName) {
        case "High Yield Corporate Bond":
          this.highyieldcorporatebond = data.Percentage
          break;
        case "Strategic Enhanced Bond": 
          this.strategicenhancedbond = data.Percentage
          break;
        case "Sleep Well Bond":
          this.logicinvestsleepwellbond = data.Percentage
          break;
        case "Cash is King Strategy":
          this.cashiskingstrategy = data.Percentage
          break;
        case "Strategic Hedged Income":
          this.strategichedgedincome = data.Percentage
          break;
         default:
      } 
    });
    this.rowData2.forEach(data => {
      if(data.Percentage != ""){
        this.totalPercentage = this.totalPercentage + Number(data.Percentage)
      } 
      switch (data.InvestmentName) {
        case "Economic Cycle":
          this.economiccycle = data.Percentage
          break;
        case "Foundation":
          this.foundation = data.Percentage
          break;
        case "Global Sector":
          this.logicinvestglobalsector = data.Percentage
          break;
        case "Country Rotation":
          this.logicinvestcountryrotation = data.Percentage
          break;
        case "Prime Dividend International":
          this.primedividendinternational = data.Percentage
          break;
        case "Strategic Mid Cap":
          this.strategicmidcap = data.Percentage
          break;
        case "U. S. Prime Dividend":
          this.usprimedividend = data.Percentage
          break;
        case "U. S. Prime Dividend Jr":
          this.usprimedividendjr= data.Percentage
          break;
        // case "Employment Trends Strategy":
        //   this.shareholdertotalreturn= data.Percentage
        //   break;   
        default:
      }
    });
    this.rowData3.forEach(data => {
      if(data.Percentage != ""){
        this.totalPercentage = this.totalPercentage + Number(data.Percentage)
      } 
      switch (data.InvestmentName) {
        case "NASDAQ Leaders":
          this.NASDAQleaders = data.Percentage
          break;
        case "Sector Growth Strategy":
          this.sectorrotation = data.Percentage
          break;
        case "Value Discount":
          this.valuediscount = data.Percentage
          break;
        case "Broad Value":
          this.valueexposure = data.Percentage
          break;
        default:
      }
    });
    this.clearalert = false
    //return true
  }

  saveMondayAllocationDetails(){
    this.alertService.clear()
    this.assignStratergies()
      
      if (this.validateMondayAllocationForm()){
          this.disablesubmitbtn = true
          this.alertService.info('Allocation request is submitting. Please wait.')
        
        if (this.accountsize == undefined || this.accountsize == null )
        { 
          this.accountsize = "";
        }  
    
        if (this.pendingannuities == undefined || this.pendingannuities == null )
        { 
          this.pendingannuities = "";
        }  

        this.mondayallocation = new MondayAllocation()
        this.mondayallocation.AdvisorName = this.advisorname
        this.mondayallocation.CompanyName = this.companyname
        this.mondayallocation.Login = this.login
        this.mondayallocation.Email = this.loggedAgent.Email
        this.mondayallocation.AccountName = this.accountname
        this.mondayallocation.AccountNumber = this.accountnumber
        this.mondayallocation.AccountClassification = this.accountclassification
        let accounttypequalified = (this.accounttypequalified == "" || this.accounttypequalified == null || this.accounttypequalified == undefined) 
        let accounttypenonqualified = (this.accounttypenonqualified == "" || this.accounttypenonqualified == null || this.accounttypenonqualified == undefined)
        let accounttypeentity = (this.accounttypeentity == "" || this.accounttypeentity == null || this.accounttypeentity == undefined) 
        
        if(accounttypequalified && accounttypeentity && this.accounttypenonqualified != "" && this.accounttypenonqualified != null && this.accounttypenonqualified != undefined){
          this.mondayallocation.AccountType = this.accounttypenonqualified
        } else if(accounttypenonqualified && accounttypeentity && this.accounttypequalified != "" && this.accounttypequalified != null && this.accounttypequalified != undefined){
          this.mondayallocation.AccountType = this.accounttypequalified
        } else if(accounttypequalified && accounttypenonqualified && this.accounttypeentity != "" && this.accounttypeentity != null && this.accounttypeentity != undefined){
          this.mondayallocation.AccountType = this.accounttypeentity
        }
        this.mondayallocation.Custodian = this.custodian
        this.mondayallocation.InstructionType = this.instructiontype
        this.mondayallocation.HighYieldCorporateBond = this.highyieldcorporatebond
        this.mondayallocation.StrategicEnhancedBond = this.strategicenhancedbond
        this.mondayallocation.LogicInvestSleepWellBond = this.logicinvestsleepwellbond
        this.mondayallocation.CashIsKingStrategy = this.cashiskingstrategy
        this.mondayallocation.StrategicHedgedIncome = this.strategichedgedincome
        this.mondayallocation.EconomicCycle = this.economiccycle
        this.mondayallocation.Foundation = this.foundation
        this.mondayallocation.LogicInvestGlobalSector = this.logicinvestglobalsector
        this.mondayallocation.LogicInvestCountryRotation = this.logicinvestcountryrotation
        this.mondayallocation.PrimeDividendInternational = this.primedividendinternational
        this.mondayallocation.StrategicMidCap = this.strategicmidcap
        this.mondayallocation.USPrimeDividend = this.usprimedividend
        this.mondayallocation.USPrimeDividendJr = this.usprimedividendjr
        // this.mondayallocation.ShareholderTotalReturn = this.shareholdertotalreturn
        this.mondayallocation.NASDAQLeaders = this.NASDAQleaders
        this.mondayallocation.SectorRotation = this.sectorrotation
        this.mondayallocation.ValueDiscount = this.valuediscount
        this.mondayallocation.ValueExposure = this.valueexposure
        this.mondayallocation.AccountSize = this.accountsize.toString().replace(/,/g,'')
        this.mondayallocation.PendingAnnuities = this.pendingannuities.toString().replace(/,/g,'')
        this.mondayallocation.HeldPositions = this.heldpositions
        this.mondayallocation.Notes = this.notes
  
        const allocationdata: FormData = new FormData();
        this.fileInfos.forEach(data => {
          allocationdata.append("fileUploads", data);
        }); 
        allocationdata.append("mondayallocation", JSON.stringify(this.mondayallocation));

        this.mondayallocationrequestdataservice.mondayAllocationRequestData(allocationdata, this.loggedAgent).subscribe(date =>
        {
          this.alertService.clear()
          this.alertService.success('Successfully saved')
          this.disablesubmitbtn = false
          this.initializeFormData()
          this.getAllocationHistory()
        },
        error => { 
          this.alertService.clear()
          if (error.error.toLowerCase().includes("an asynchronous module or handler completed while an asynchronous operation was still pending")) // To handle An asynchronous module or handler completed while an asynchronous operation was still pending.
          {
            this.alertService.success('Successfully saved')
            this.disablesubmitbtn = false
            this.initializeFormData()
            this.getAllocationHistory()
          } else{
            this.alertService.error('Error occured while saving data')
            this.disablesubmitbtn = false
          }
          
        });

      }
    
  }


  handleFileInput(event: any) {
    if (event.target.files != null && event.target.files != undefined) {
      for (let i = 0; i < event.target.files.length; i++) {
        var newFile = event.target.files[i];
        var isDuplicate = this.fileInfos.some(existingFile => existingFile.name === newFile.name && existingFile.size === newFile.size);
  
        if (!isDuplicate) {
          this.fileInfos.push(newFile);
        } else {
          this.alertService.error(`File ${newFile.name} is already existing`);
        }
      }
    }
  }
  

  removeFile(file){
    this.fileInfos.forEach(data => {
      if(file == data){
        this.fileInfos.splice(this.fileInfos.indexOf(data),1)
      }
    }); 
  }

  FilterInput(event){ 
    // skip for arrow keys
    if(event.which >= 37 && event.which <= 40) return;
  
    switch (event.currentTarget.id) {
      case "pendingannuities":
        this.pendingannuities = event.target.value
                  .replace(/\D/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")  
        break;
        case "accountsize":
          let valueWithoutCommas = event.target.value.replace(/\D/g, "");
          if (Number(valueWithoutCommas) > 0) {
              this.accountsize = valueWithoutCommas.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else {
              this.accountsize = ''; 
          }
          break;
      
      default:
    }  
  }

  /* FilterInput(event){
    if(event.key != "Backspace" && event.key != "Tab" && event.key != "Enter"){
      this.alertService.clear();
      if(/^\d+$/.test(event.key)){
        this.alertService.clear();
      } else{
        this.alertService.error('Please enter a number');

      switch (event.currentTarget.id) {
        case "highyieldcorporatebond":
          this.highyieldcorporatebond = ""
          break;
        case "strategicenhancedbond": 
          this.strategicenhancedbond = ""
          break;
        case "logicinvestsleepwellbond":
          this.logicinvestsleepwellbond = ""
          break;
        case "strategichedgedincome":
          this.strategichedgedincome = ""
          break;
        case "economiccycle":
          this.economiccycle = ""
          break;
        case "foundation":
          this.foundation = ""
          break;
        case "logicinvestglobalsector":
          this.logicinvestglobalsector = ""
          break;
        case "logicinvestcountryrotation":
          this.logicinvestcountryrotation = ""
          break;
        case "primedividendinternational":
          this.primedividendinternational = ""
          break;
        case "strategicmidcap":
          this.strategicmidcap = ""
          break;
        case "usprimedividend":
          this.usprimedividend = ""
          break;
        case "NASDAQleaders":
          this.NASDAQleaders = ""
          break;
        case "sectorrotation":
          this.sectorrotation = ""
          break;
        case "valuediscount":
          this.valuediscount = ""
          break;
        case "pendingannuities":
          this.pendingannuities = ""
          break;
        case "accountsize":
          this.accountsize = ""
          break;
        default:
      }
    }
    } else{
      this.alertService.clear();
    }
  } */

  clearAlert(){
    this.alertService.clear();
  }

  resetAccountType(event){
    if(event.currentTarget.value == "---"){
      this.accounttypequalified = ""
      this.accounttypenonqualified = ""
      this.accounttypeentity = ""
    }else{
      this.accounttype = event.currentTarget.value
    }
    this.GenerateAccountName();
  }

  resetAccountClassification(event){
    if(event.currentTarget.value == "---"){
      this.accountclassification = ""
      this.accounttypequalified = ""
      this.accounttypenonqualified = ""
      this.accounttypeentity = ""
      this.showAccountTypeQualified = true
      this.showAccountTypeNonQualified = true
      this.showAccountTypeEntity = true
    } else if(event.currentTarget.value == "Qualified"){
      this.showAccountTypeQualified = false
      this.showAccountTypeNonQualified = true
      this.showAccountTypeEntity = true
    } else if(event.currentTarget.value == "Non-Qualified"){
      this.showAccountTypeQualified = true
      this.showAccountTypeNonQualified = false
      this.showAccountTypeEntity = true
    } else{
      this.showAccountTypeQualified = true
      this.showAccountTypeNonQualified = true
      this.showAccountTypeEntity = false
    }
  }

  GenerateAccountName()
  {
    let accounttype
    let accounttypequalified = (this.accounttypequalified == "" || this.accounttypequalified == null || this.accounttypequalified == undefined) 
    let accounttypenonqualified = (this.accounttypenonqualified == "" || this.accounttypenonqualified == null || this.accounttypenonqualified == undefined)
    let accounttypeentity = (this.accounttypeentity == "" || this.accounttypeentity == null || this.accounttypeentity == undefined) 
    
    if(accounttypequalified && accounttypeentity && this.accounttypenonqualified != "" && this.accounttypenonqualified != null && this.accounttypenonqualified != undefined){
      accounttype = this.accounttypenonqualified
    } else if(accounttypenonqualified && accounttypeentity && this.accounttypequalified != "" && this.accounttypequalified != null && this.accounttypequalified != undefined){
      accounttype = this.accounttypequalified
    } else if(accounttypequalified && accounttypenonqualified && this.accounttypeentity != "" && this.accounttypeentity != null && this.accounttypeentity != undefined){
      accounttype = this.accounttypeentity
    }
     
    this.accountname="";
    if ((this.lastname!="") && (this.lastname != null) && (this.lastname != undefined))
    {
      this.accountname = this.lastname  
    }
    if ((this.firstname!="") && (this.firstname != null) && (this.firstname != undefined))
    {
      this.accountname = this.accountname + (this.accountname=="" ? this.firstname  :" " + this.firstname)  
    }
    if ((this.lastnameclient2 !="") && (this.lastnameclient2 != null) && (this.lastnameclient2 != undefined) && (this.lastname != this.lastnameclient2))
    {
      this.accountname = this.accountname + (this.accountname=="" ? this.lastnameclient2  :" & " + this.lastnameclient2)
    }
    if ((this.firstnameclient2 !="") && (this.firstnameclient2 != null) && (this.firstnameclient2 != undefined))
    {
      if (this.lastname != this.lastnameclient2)
      {
        this.accountname = this.accountname + (this.accountname=="" ? this.firstnameclient2  :" " + this.firstnameclient2)
      } else{
        this.accountname = this.accountname + (this.accountname=="" ? this.firstnameclient2  :" & " + this.firstnameclient2)
      }
    }
    if ((accounttype!="") && (accounttype != null) && (accounttype != undefined))
    {
      this.accountname = this.accountname + " " + accounttype  
    }

     
  }

  resetCustodian(event){
    if(event.currentTarget.value == "---"){
      this.custodian = ""
    }
  }

  resetInstructionType(event){
    if(event.currentTarget.value == "---"){
      this.instructiontype = ""
    }
  }

  exitBtnClickEvent()
  {
    if(JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) != null){
      this.sharedService.changeSideBarRequest()   
			this.router.navigateByUrl('home')
    } else{
      this.router.navigateByUrl('landing'); 
    } 
  }

  //load the superuser dashboard page
	public navigateSuperUserDashboard()
	{
		this.router.navigateByUrl('superuserdashboard'); 
  }
  
  ValidateMinInvestmentOnFocusOut()
  {
   //Minimum investment validation
   this.alertService.clear()
   let passedMinInvestmentValidation: boolean = true;
   this.rowData1.forEach(data => {
     if(data.Percentage != ""){ 
       if (!this.ValidateMinimumInvestment(data.MinInvestment,Number(data.Percentage),data.InvestmentName))
       {
         passedMinInvestmentValidation = false;
       }
     }  
   });

   this.rowData2.forEach(data => {
     if(data.Percentage != ""){ 
       if (!this.ValidateMinimumInvestment(data.MinInvestment,Number(data.Percentage),data.InvestmentName))
       {
         passedMinInvestmentValidation = false;
       }
     }  
   });

   this.rowData3.forEach(data => {
     if(data.Percentage != ""){ 
       if (!this.ValidateMinimumInvestment(data.MinInvestment,Number(data.Percentage),data.InvestmentName))
       {
         passedMinInvestmentValidation = false;
       }
     }  
   });
  }

  addClient2(){
    this.showClient2Details = true
    this.GenerateAccountName()
  }

  removeClient2(){
    this.firstnameclient2 = ""
    this.lastnameclient2 = ""
    this.showClient2Details = false
    this.GenerateAccountName()
  }
}



function createData(count, prefix, totalPercentatge) {
  var result = [];
  for (var i = 0; i < count; i++) {
    result.push({
        InvestmentName: "Total Investment: ",
        Percentage:  totalPercentatge
    });
  }
  return result;
}