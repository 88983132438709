export class MondayDepositWithrawalCash {
    constructor(
        public AdvisorName?: string,
        public CompanyName?: string,
        public Login?: string,
        public AccountName?: string,
        public AccountNumber?: string,
        public Action?: string,
        public Type?: string, 
        public PaymentFrequency?: string, 
        public PaymentDay?: string,
        public Amount?: string,
        public Notes?: string,
        public Email?: string
    ) {}
}