<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg">
	<app-header></app-header>
</nav>
<div class="main-panel pt-0 pb-4">
	<div class="container-fluid" #main [style.padding-top.px]="paddingtop">
		<div class="col-sm-12 px-0 align-self-end">
			<div class="row align-items-center">
				<div class="col-sm-6">
					<h3 class="light-header bold">
						{{ 'LANDINGFP4FA.LBL_TITLE' | translate}}
					</h3>
				</div>
				<div class="col-sm-6 text-right">
					
					<button type="button" class="btn btn-primary btn-sm  mr-1 at-right " (click)="createNewBusinessPlan()">
						{{ 'LANDINGFP4FA.BTN_NEW_PLAN' | translate }}
					</button> 
					<button type="button" class="btn btn-primary btn-sm " (click)="loadRetirementPlans()">
						{{ 'LANDING.LBL_TITLE' | translate }}
					</button>		
					<button type="button" class="btn btn-primary btn-sm ml-1" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
						{{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
					</button>				
				</div> 
			</div>
			<hr class="mt-0 mb-3" />
		</div>
		<!-- comment all table related logics, because user may need different kind of UI element in this page -->
		<!-- <div id="grid-wrapper" class="col-sm-12 px-0">
			<ag-grid-angular style="width: 100%; height: 200px;" class="ag-theme-balham" [rowData]="rowData"
				[columnDefs]="columnDefs" (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)">
			</ag-grid-angular>
		</div> -->
		<div class="col-sm-12 px-0">
			<div class="form-group mx-3">
				<input type="text" class="form-control bold" id="searchBusinessPlans" placeholder="Search by business plan name"
					[(ngModel)]="searchTerm">
			</div>
			<div class="card-deck">
				<div *ngFor="let businessPlan of businessPlansArray | businessplanFilter:searchTerm; let i=index" class="col-sm-3 mt-1">
					<div class="card mt-1" (click)="sampleClickEvent(businessPlan)">
						<div class="card-body p-1">
							<h5 class="m-0 bold">
								{{ businessPlan.BusinessPlanName}}
							<button type="button" class="float-right mt-4" container="body" id="delete" tooltip ="Delete"
										class="btn btn-outline-primary btn-myxs float-right"  (click)="deleteClickEvent($event,businessPlan)" >
								<i class="fas fa-trash-alt float-right mt-1 mb-1" aria-hidden="true"></i>
							</button>		
							</h5>	
						</div> 
					</div> 
				</div>
			</div>
		</div>
	</div>
</div>