<div class="main-panel pt-0 pb-4">
	<div class="container-fluid">
		<div class="col-sm-12 px-0">
			<h5 class="light-header bold">
				{{ 'Income for Life Options' | translate}}
				<button type="button" class="btn btn-primary btn-sm float-right" (click)="exitBtnClickEvent()">
					<i class="fas fa-sign-out"></i> Exit
				</button>
				<button type="button" class="btn btn-primary btn-sm mr-1 float-right" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
					{{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
				</button>
			</h5>
			<div class="container bold"  *ngIf="showhide" >
				<div class="row">
					<div class="col"><input #default type="radio" name="reportoption" value="default"
							[checked]="(this.currentChoice.ReportKind === 'Rpt_Default')"
							(click)="radioClick(default.value)"> Default </div>
					<div class="col"><input #client_passes type="radio" name="reportoption" value="client_passes"
							[checked]="(this.currentChoice.ReportKind === 'Rpt_ClientDies')"
							(click)="radioClick(client_passes.value)"> Client Passes </div>
					<div class="col"> <input #spouse_passes type="radio" name="reportoption" value="spouse_passes"
							[checked]="(this.currentChoice.ReportKind === 'Rpt_SpouseDies')"
							(click)="radioClick(spouse_passes.value)" [disabled]="!hasSpouse"> Spouse Passes </div>
					<div class="w-100"></div>
				</div>
				<div class="row">
						<div class="col-2" style="margin-left: 10px;"></div>
					<div class="col-1" style="float: right; margin-left:115px; padding-top:8px;"><label for="clientage">At Age</label></div>
					<div class="col-3">
						<!-- [(ngModel)]="clientage" formControlName="clientage"   -->
						
						<select style=" margin-left:-35px" class="form-control bold"  id="clientage" (ngModelChange)="changeClientPassesAt($event)"
							[(ngModel)]="clientDiesAt">
							<option *ngFor="let item of clientagelist" [ngValue]="item.value">
								{{item.text}}
							</option>
						</select>
					</div>
					<div class="col-1"></div>
					<div class="col-1" style="float: right;  margin-left:-90px; padding-top: 8px;"><label for="clientage">At Age</label></div>
					<div class="col-3">
						<!-- [(ngModel)]="spouseage" formControlName="spouseage"   -->
						<select style=" margin-left:-35px" class="form-control" id="spouseage bold" (ngModelChange)="changeSpousePassesAt($event)"
							[(ngModel)]="spouseDiesAt">
							<option *ngFor="let item of spouseagelist" [ngValue]="item.value">
								{{item.text}}
							</option>
						</select>
					</div>
				</div>
			</div>

			<div class="form-row">
				<div class="form-group col-md-0">
					<button type="button" class="btn btn-primary btn-sm" (click)="showHideBtnClickEvent()" [disabled]="disablePresentedToClientBtn"> 
						<i class="far {{showhide === true ? 'fa-angle-double-up':  'fa-angle-double-down' }}"></i>	
						{{showhide === true ? 'Hide Options':  'Show Options' }}					
					</button>
				</div>						
				<div class="form-group col-md-0">
					<button type="button" class="btn btn-primary btn-sm" (click)="saveReportBtnClickEvent()"> <i class="far fa-save"></i> Save</button>
				</div>
				<div class="form-group col-md-0">
					<button type="button" class="btn btn-primary btn-sm" (click)="printBtnClickEvent()" [disabled]="disablePrintBtn"> <i class="fas fa-print"></i> Print</button>
				</div>
				<div class="form-group col-md-0">
					<button type="button" class="btn btn-primary btn-sm" (click)="presentedToClientBtnClickEvent()" [disabled]="disablePresentedToClientBtn"> <i class="fas fa-presentation"></i> Presented To Client</button>
				</div>
				<div class="form-group col-md-0">
					<button type="button" class="btn btn-primary btn-sm" (click)="undoPresentedToClientBtnClickEvent()" [hidden]="!disablePresentedToClientBtn"> <i class="fas fa-undo"></i> Undo Presented To Client</button>
				</div>
			</div>
			<h3 class="light-header bold">
				{{ 'LONGRE.LBL_TITLE' | translate}}
				<small class="h6 text-muted ml-2 bold">{{ this.currentChoice.ReportTitleLine2 | translate}}
					<!-- <div class="d-inline-block align-top vertical-line ml-1 mr-1"></div> -->
				</small>
				<small class=" text-muted mx-1 vertical-line">|</small>
				<small class="h6 text-muted ml-1 bold">{{ this.currentChoice.ReportTitleLine3 | translate}}</small>
				
				<small class="h6 text-muted ml-1 float-right bold">{{ this.SelectedSum | translate}}</small>
				<!-- <button type="button" class="close" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				  </button> -->

				 
			</h3>
			 
					 
			 
			<hr class="mt-0 mb-3" />
		</div>
		<div id="grid-wrapper" class="col-sm-12 px-0">
			<ag-grid-angular  style="width: 100%;"  [style.height.px]="gridHeight"  class="ag-theme-balham bold"  
				  (gridReady)="onGridReady($event)" (gridSizeChanged)="gridSizeChanged($event)"
				[headerHeight]="70" [enableRangeSelection]="true"
				(selectionChanged)="onSelectionChanged($event)" 
				(cellClicked)="onCellClicked($event)"
				(rangeSelectionChanged) = "onRangeSelectionChanged($event)"
				(columnMoved)="onColumnMoved()"
				[gridOptions]="gridOptions" 
				[suppressDragLeaveHidesColumns] = "true" >
			</ag-grid-angular>
		</div>
	</div>
</div>