<div class="my-login-page">
	<section class="h-100">
		<div class="container h-100">
			<div class="row h-100">
				<div class="card-wrapper">
					<div class="mt-3 mb-3 text-center" style="position: relative">
						<img src="../../../assets/images/app_logo_with_icon.png" alt="" width="auto" height="auto">
						<b><label style="position: absolute;bottom: 15px; right: 187px;">Version {{ appVersion}}</label></b>
					</div>
					<div class="card fat">
						<div class="card-body">
							<h4 class="card-title">{{ 'LOGIN.LBL_TITLE' | translate }}</h4>
							<form>
								<div class="form-group">
									<label for="userName">{{ 'LOGIN.LBL_USER_NAME' | translate }}</label>
									<input #login_username id="login_username" type="text" class="form-control"
										name="userName" value=""  placeholder="{{'LOGIN.LBL_USER_NAME' | translate}}" 
										[(ngModel)]="loginCredential.Email" >
								</div>
								<div class="form-group">
									<label for="password">{{ 'LOGIN.LBL_PASSWORD' | translate }}
										<a class="link-primary float-right" (click)="resetPasswordEvent()">
											Reset Password
										</a>
									</label>
									<div style="position:relative">
										<div class="input-with-icon">
											<input #login_password id="login_password"
												[type]="showPassword ? 'text' : 'password'" class="form-control"
												name="password" required="" data-eye="" style="padding-right: 60px;"
												autocomplete="off" placeholder="{{'LOGIN.LBL_PASSWORD' | translate}}"
												[(ngModel)]="loginCredential.Password" >
											<div class="btn btn-default icon" (click)="showPassword = !showPassword">
												<i class="fa" aria-hidden="true"
													[ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
											</div>
											<input type="hidden" id="passeye-0">
										</div>
									</div>
								</div>
								<div class="form-group">
									<!-- <alert></alert> -->
								</div>
								<div class="form-group mt-4 mb-3">
									<button id="login_submit" type="submit" class="btn btn-primary btn-block"
										(click)="loginBtnClickEvent()">
										{{ 'LOGIN.BTN_LOGIN' | translate }}
									</button>
								</div>
								<div class="form-group mt-4 mb-4">
									<!-- <hr /> -->
								</div>
							</form>
							<!-- <div class="form-group mt-3 text-center">
								<button id="login_openlogin" class="btn btn-light btn-block custom-google-btn shadow-sm"
									(click)="doGoogleAuthentication()">
									<img src="../../../assets/images/google_logo.svg" width="8%" height="8%"
										class="mr-4" />{{
									'LOGIN.GOOGLE_SIGN_IN' | translate }}
								</button>
							</div> -->
						</div>
					</div>
					<div class="footer">
						{{ 'LOGIN.LBL_FOOTER' | translate }}
					</div>

					<div  class="agreementpopup"  *ngIf="showAgreement" scrollTop= 0> 
						<div style="text-align: justify; padding: 10px;">
							<h4><b><p>Longevity Software Services Terms of Use</p></b></h4>							
							<b><p>EFFECTIVE: October 14, 2019</p></b> 
							<br/>
							<h5><b><p>AGREEMENT TO TERMS </p></b></h5>
							<p>These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity, and Advisormax, LLC, concerning your access to and use of the www.longevitysoftware.com website as well as any other mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the &ldquo;Site&rdquo;).</p>
							<p>You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms of Use. If you do not agree with all of these Terms of Use, then you are expressly prohibited from using the Site and you must discontinue use immediately.</p>
							<p>Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason.</p>
							<h5><b><p>INTELLECTUAL PROPERTY RIGHTS </p></b></h5>
							<p>Unless otherwise indicated, the Site is the proprietary property of Advisormax and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the &ldquo;Content&rdquo;) and the trademarks, service marks, and logos contained therein (the &ldquo;Marks&rdquo;) are owned or controlled by it or licensed to Advisormax, LLC, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, foreign jurisdictions, and international conventions.</p>
							<p>The Content and the Marks are provided on the Site &ldquo;AS IS&rdquo; for your information and personal use only. Except as expressly provided in these Terms of Use, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without the express written permission of Advisormax, LLC.</p>
							<h5><b><p>LICENSE AND SITE ACCESS</p></b></h5>
							<p>Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access solely for your use. We reserve all rights not expressly granted to you in and to the Site, the Content and the Marks.</p>
							<h5><b><p>USER REPRESENTATIONS </p></b></h5>
							<p>By using the Site, you represent and warrant that:</p>
							<p>(1) all registration information you submit will be true, accurate, current, and complete;</p>
							<p>(2) you will maintain the accuracy of such information and promptly update such registration information as necessary;</p>
							<p>(3) you have the legal capacity and you agree to comply with these Terms of Use;</p>
							<p>(4) you will not access the Site through automated or non-human means, whether through a bot, script, or otherwise;</p>
							<p>(5) you will not use the Site for any illegal or unauthorized purpose;</p>
							<p>(6) your use of the Site will not violate any applicable law or regulation.</p>
							<p>If you provide any information that is untrue, inaccurate, not current, or incomplete, Advisormax has the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).</p>
							<h5><b><p>SITE MANAGEMENT </p></b></h5>
							<p>We reserve the right, but not the obligation, to:</p>
							<p>(1) monitor the Site for violations of these Terms of Use;</p>
							<p>(2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities;</p>
							<p>(3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your stored data or any portion thereof;</p>
							<p>(4) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.</p>
							<h5><b><p>PRIVACY POLICY </p></b></h5>
							<p>We care about data privacy and security. Please review our Privacy Policy <a target="_blank"  routerLink="/privacypolicy">bhfmprivacypolicy.com</a>. By using the Site, you agree to be bound by our Privacy Policy, which is incorporated into these Terms of Use. Please be advised the Site is hosted in the United States.</p>
							<p>If you access the Site from the European Union, Asia, or any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the United States, then through your continued use of the Site, you are transferring your data to the United States, and you expressly consent to have your data transferred to and processed in the United States.</p>
							<h5><b><p>TERM AND TERMINATION </p></b></h5>
							<p>These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE [YOUR ACCOUNT AND] ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION</p>
							<h5><b><p>DISCLAIMER </p></b></h5>
							<p>THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE&rsquo;S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5)</p>
							<p>ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE.</p>
							<h5><b><p>LIMITATIONS OF LIABILITY </p></b></h5>
							<p>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
							<p>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO $0.</p>
							<h5><b><p>INDEMNIFICATION </p></b></h5>
							<p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys&rsquo; fees and expenses, made by any third party due to or arising out of: (1) use of the Site; (2) breach of these Terms of Use; (3) any breach of your representations and warranties set forth in these Terms of Use; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the Site with whom you connected via the Site.</p>
							<p>Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.</p>
							<h5><b><p>ASSIGNMENT</p></b></h5>
							<p>Advisormax shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or consent required. However, you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
							<h5><b><p>ENTIRE AGREEMENT</p></b></h5>
							<p>These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between&nbsp;FTA and you in relation to your use of this Website, and supersede all prior agreements and understandings with respect to the same.</p>
							<h5><b><p>GOVERNING LAW &amp; JURISDICTION</p></b></h5>
							<p>These Terms will be governed by and construed in accordance with the laws of the state of&nbsp;Missouri, and you submit to the non-exclusive jurisdiction of the state and federal courts located in&nbsp;Missouri&nbsp;for the resolution of any disputes.</p>
						 
						
					
						</div>
						<div class="form-row text-center mr-5">
							<div class="col-12">
								<button type="button" class="btn btn-primary btn-sm  mr-2" (click)="rejectBtnClickEvent()">Do Not Agree</button>
								<button type="button" class="btn btn-primary btn-sm" (click)="acceptBtnClickEvent()">Agree</button>
							</div>
						</div>
					
					</div>

					<div  class="trialusertrainingpopup"  *ngIf="showTrialUserTraining" scrollTop= 0>  
						<div class="pt-3"  style="width:100%; height:90%; background-color:transparent;"> 
						  <iframe src="https://player.vimeo.com/video/597405018?autoplay=1?h=3db5f8b323" frameborder="0" style="width:100%; height:100%;">
						  </iframe> 
						</div>
						<!-- <div style="text-align: justify; padding: 10px;">
							<iframe src="https://player.vimeo.com/video/367742421"    frameborder="0" allowfullscreen  ></iframe>
						</div> -->
						 
						<div class="form-row text-right m-4  position-absolute bottom-align right-align" >
							<div class="col-12">
								
								<button type="button" class="btn btn-primary btn-sm  mr-2" (click)="CloseTrainingBtnClickEvent()">Close Training Video</button> 
							</div>
						</div>
					</div> 
				</div>
			</div>
		</div>
	</section>
</div>