import { Component, OnInit , ViewChild, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { LandingDataService } from './landing-data.service';
import { DynamicSidebarService } from '../sidebar/dynamic-sidebar.service';
import { AlertService } from '../util/alert/alert.service';
import { LocalStorage } from '../util/localstorage.service';
import { SharedServiceService } from '../util/shared-service.service';
import { Constants } from '../util/constants.js';
import { Client } from '../models/client.model';
import { Agent } from '../models/agent.model';


import { ClientInfoDataService } from '../appComp/client-info/client-info-data.service';


@Component({
	selector: 'app-landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit { 

	public loggedAgent : Agent 
	public paddingtop: number = 60;
	public bDeleteClicked : boolean = false
	public isShowFP4FA: boolean = false
	public isShowDashboard: boolean = false
	// private gridApi: any;
	// private gridColumnApi: any;

	// public columnDefs: any;
	// public rowData: any;

	public clientList: Array<Client> = []
	public searchTerm: string = ''

	constructor(private router: Router, 
					private ldService: LandingDataService,
					private ldClientService: ClientInfoDataService,
					 private alertService: AlertService, 
					  private dynSidebarService: DynamicSidebarService,
					  private sharedService: SharedServiceService) {
		// this.columnDefs = [
		// 	{
		// 		headerName: 'Project Name',
		// 		field: 'project_name',
		// 		sortable: true,
		// 		filter: true,
		// 		resizable: true
		// 	},
		// 	{
		// 		headerName: 'Created Date',
		// 		field: 'created_date',
		// 		sortable: true,
		// 		filter: true
		// 	},
		// 	{
		// 		headerName: '',
		// 		field: 'edit',
		// 		sortable: true,
		// 		filter: true
		// 	},
		// 	{
		// 		headerName: '',
		// 		field: 'delete',
		// 		sortable: true,
		// 		filter: true
		// 	}
		// ];

		//console.log(localStorage.getItem(LocalStorage.LOGGED_AGENT))
		this.loggedAgent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent;
		if ((this.loggedAgent != null) && (this.loggedAgent != undefined)) { 
			// check whther the user allowed to see FP4FA
			if (this.loggedAgent.bShowBizIQ == true)
				this.isShowFP4FA = true
			else
				this.isShowFP4FA = false   
		}

		//this.ldService.getclientsbyagentorganization(this.loggedAgent).subscribe(data => this.getData(data))
		this.loadData()
	}

	ngOnInit() {
		localStorage.setItem(LocalStorage.FP4FA, ""); 
		let agent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent;
		if(agent.UserRoleID== Constants.UserRoleID.Superuser)
			this.isShowDashboard = true
	}

	loadData()
	{
		this.ldService.getclientsbyagentorganization(this.loggedAgent).subscribe(data => this.getData(data))
	}

	private getData(data: any) {
		this.clientList = []
		data.forEach((element: any) => {
			let client: Client = element
			client.ClientFullName = element.ClientFirstName + ' ' + element.ClientLastName
			this.clientList.push(client)
		});
	}

	// onGridReady(params: any) {
	// 	this.gridApi = params.api;
	// 	this.gridColumnApi = params.columnApi;

	// 	setTimeout(() => {
	// 		this.rowData = [
	// 			{
	// 				project_name: 'Al Raymond',
	// 				created_date: '02/09/2018',
	// 				edit: '',
	// 				delete: ''
	// 			},
	// 			{
	// 				project_name: 'Alan Abernathy',
	// 				created_date: '03/12/2018',
	// 				edit: '',
	// 				delete: ''
	// 			},
	// 			{
	// 				project_name: 'Allan Shickman',
	// 				created_date: '04/08/2018',
	// 				edit: '',
	// 				delete: ''
	// 			},
	// 			{
	// 				project_name: 'Andrei Laszlo',
	// 				created_date: '05/21/2018',
	// 				edit: '',
	// 				delete: ''
	// 			}
	// 		]
	// 		params.api.sizeColumnsToFit();
	// 	}, 2000);
	// }

	/* public onCellClicked(event: any) {
		// console.log('[onCellClicked] (event.data) : ' + JSON.stringify(event.data));
		// console.table(event.data)
		this.router.navigateByUrl('home')
	} */

	public sampleClickEvent(client: Client) {   

		if (this.bDeleteClicked==true) // return if the delete is clicked
		{ 
			this.bDeleteClicked = false 
		}
		else
		{ 
			localStorage.setItem(LocalStorage.SELECTED_CLIENTID, client.ClientID); 
			this.ldClientService.getclientbyid(client.ClientID, this.loggedAgent).subscribe(data => 
			{ 
				let client:Client = data   
				localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(client));  
				this.sharedService.changeSideBarRequest()   
				this.router.navigateByUrl('home')		
			},
			error => {
				this.alertService.clear()
				this.alertService.error('Error occured while retrieving data')
				});  
		}
	}

	public deleteClickEvent(event:any, client: Client)
	{   
		this.bDeleteClicked = true
		if (confirm("Are you sure you want to delete selected client?")) {  
			
			//this.deleteClient(client.ClientID)
			client.DeletedBy = this.loggedAgent.AgentID
			this.ldClientService.deleteclient(client, this.loggedAgent).subscribe(date =>
				{  
				 	this.loadData()
				},
				error => {
				  this.alertService.clear()
				  this.alertService.error('Error occured while deleting data')
				 });  
			
		}		
		//else  
		//	event.stopPropagation();
		 
		 
	} 

	public createNewClient() {  
		localStorage.setItem(LocalStorage.SELECTED_CLIENTID, ""); 
		//localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(new Client)); 
		localStorage.removeItem(LocalStorage.CURRENT_CLIENT);
		this.sharedService.changeSideBarRequest()   
		this.router.navigateByUrl('home')
	}

	public loadFP4FA()
	{
		localStorage.setItem(LocalStorage.FP4FA, "fp4fa"); 
		this.router.navigateByUrl('landingfp4fa'); 
	}

	//load the superuser dashboard page
	public navigateSuperUserDashboard()
	{
		this.router.navigateByUrl('superuserdashboard'); 
	}
}
