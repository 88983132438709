import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';  
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule , ReactiveFormsModule  } from '@angular/forms';  
//import { NgSelectModule } from '@ng-select/ng-select';

 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LandingComponent } from './landing/landing.component';
import { PrivacyPolicyComponent } from './privacypolicy/privacypolicy.component';

//translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

//bootstrap components
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';

//app components
import { HomeComponent } from './home/home.component';
import { ClientInfoComponent } from './appComp/client-info/client-info.component';
import { IncomeSourceComponent } from './appComp/income-source/income-source.component';
import { IncomeAssertComponent } from './appComp/income-assets/income-assets.component';
import { IncomeLifeReportComponent } from './appComp/income-life-report/income-life-report.component';
import { RiskGraphComponent } from './appComp/risk-graph/risk-graph.component';
import { InvestmentReportComponent } from './appComp/investment-report/investment-report.component';
import { OldInvestmentReportComponent } from './appComp/old-investment-report/old-investment-report.component';
import { WealthReportComponent } from './appComp/wealth-report/wealth-report.component';
import { FTAStrategyComponent } from './ftastrategy/ftastrategy.component'; 

//ngx-bootstrap 
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// util
//import { AlertComponent } from './util/alert/alert.component'; 

//pipes
import { ClientFilterPipe } from './util/client-filter.pipe';
import { BusinessPlanFilterPipe } from './util/business-plan-filter.pipe';
import { OrderArrayByTypePipe } from './util/order-array-by-type.pipe';

//aggrid
import { AgGridModule } from 'ag-grid-angular';
import { AlertComponent } from './util/alert/alert.component';
import { LicenseManager } from "ag-grid-enterprise/main";


//import { TagInputModule } from 'ngx-chips'; 
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { BlendComponent } from './blend/blend.component';
import { ReportsComponent } from './reports/reports.component';
import { CustomCellComponent } from './reports/custom-cell/custom-cell.component';
import { IndexBlendComponent } from './index-blend/index-blend.component';
import { TickerBlendComponent } from './ticker-blend/ticker-blend.component';
import { MondayAllocationRequestComponent } from './mondayallocationrequest/mondayallocationrequest.component';
import { MondayDepositWithdrawCashComponent } from './mondaydepositwithdrawcash/mondaydepositwithdrawcash.component';
import { FP4FAComponent } from './fp4fa/fp4fa.component';
import { CompanyConfigurationComponent } from './fp4fa/company-configuration/company-configuration.component';
import { ExpenseComponent } from './fp4fa/expense/expense.component';
import { RevenueComponent } from './fp4fa/revenue/revenue.component';
import { CalculatorComponent } from './fp4fa/calculator/calculator.component';
import { MarketingProductionComponent } from './fp4fa/marketing-production/marketing-production.component';
import { DashboardComponent } from './fp4fa/dashboard/dashboard.component';

import { ChartModule } from 'angular-highcharts';
import { Landingfp4faComponent } from './fp4fa/landingfp4fa/landingfp4fa.component';
import { SuperuserDashboardComponent } from './superuser-dashboard/superuser-dashboard.component';
import { MondayAllocationCustomCellComponent } from './mondayallocationrequest/monday-allocation-custom-cell/monday-allocation-custom-cell.component';
import { MondayDepositWithdrawCashCustomCellComponent } from './mondaydepositwithdrawcash/monday-deposit-withdraw-cash-custom-cell/monday-deposit-withdraw-cash-custom-cell.component';

// import * as highstock from 'highcharts/modules/stock.src';
// import stock from 'highcharts/modules/stock.src';
// import more from 'highcharts/highcharts-more.src';

LicenseManager.setLicenseKey("BHFM_Andrew_Claus_single_1_Devs__12_September_2020_[v2]_MTU5OTg2ODgwMDAwMA==d26d7c6f7896e35b74aeae058acefcd2");
export function HttpLoaderFactory(HttpClient: HttpClient) {
	return new TranslateHttpLoader(HttpClient, "i18n/", ".json");
}

// export function highchartsModules() {
// 	// apply Highcharts Modules to this array
// 	return [stock, more];
//   }

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		HeaderComponent,
		SidebarComponent,
		LandingComponent,
		PrivacyPolicyComponent,
		HomeComponent,
		ClientInfoComponent,
		IncomeSourceComponent,
		IncomeAssertComponent,
		IncomeLifeReportComponent,
		OrderArrayByTypePipe,
		ClientFilterPipe,
		BusinessPlanFilterPipe,
		AlertComponent,
		RiskGraphComponent,  
		InvestmentReportComponent, 
		OldInvestmentReportComponent,
		WealthReportComponent,
		FTAStrategyComponent, 
		BlendComponent, 
		ReportsComponent, 
		CustomCellComponent, 
		IndexBlendComponent, 
		TickerBlendComponent, 
		MondayAllocationRequestComponent ,
		MondayDepositWithdrawCashComponent, 
		FP4FAComponent, 
		CompanyConfigurationComponent, 
		ExpenseComponent, 
		RevenueComponent, 
		MarketingProductionComponent, 
		CalculatorComponent, 
		DashboardComponent, 
		Landingfp4faComponent, 
		SuperuserDashboardComponent,
		WealthReportComponent,
		MondayAllocationCustomCellComponent,
		MondayDepositWithdrawCashCustomCellComponent
		
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		PopoverModule.forRoot(),
		CollapseModule.forRoot(),
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		AgGridModule.withComponents([]),
		FormsModule,
		//NgSelectModule,
		ReactiveFormsModule ,
		BsDatepickerModule.forRoot() ,
		TabsModule.forRoot(),
		BsDropdownModule.forRoot(),
		//TagInputModule,
		SelectDropDownModule,
		TooltipModule.forRoot(),
		ModalModule.forRoot(),
		ChartModule 
	],
	entryComponents: [
		ClientInfoComponent,
		IncomeSourceComponent,
		IncomeAssertComponent,
		IncomeLifeReportComponent,
		RiskGraphComponent,  
		InvestmentReportComponent,
		OldInvestmentReportComponent,
		WealthReportComponent,
		FTAStrategyComponent,
		CustomCellComponent,
		MondayAllocationCustomCellComponent,
		MondayDepositWithdrawCashCustomCellComponent
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
