import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../util/localstorage.service'; 
import { Constants } from '../util/constants.js';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Agent } from '../models/agent.model';
import { Blend, BlendDetail } from '../models/blend.model';
import { Ftastrategy, FTAStrategyDetail } from '../models/ftastrategy.model';
import { AlertService } from '../util/alert/alert.service';
import { CommonFunctions } from '../util/common-functions';
import { GridOptions } from 'ag-grid-community'
import { CustomHeader} from '../util//customHeader'
import { BlendDataService } from './blend-data.service';
import { BsModalService,BsModalRef}   from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { FtastrategyDataService } from '../ftastrategy/ftastrategy-data.service';
import { SharedServiceService } from '../util/shared-service.service';
  
@Component({
  selector: 'app-blend',
  templateUrl: './blend.component.html',
  styleUrls: ['./blend.component.scss']
})

export class BlendComponent implements OnInit {
  
  public paddingtop: number = 60;
  public selectedChoiceID: string = "";
  public blenddetails: Array<any>
  public id: any
  public copiedid: any
  public rebalancefrequency: any = "Monthly"
  public name:any
  public ismyblend:any
  public productcount:any
  public product:any
  public selectedProducts: any
  public selectedproductid: any
  public productlist: Array<any>
  public previousBeginDate
  public createdby:any
  public createdon:any
  public begindate:any
  public loggedAgent: Agent 
  public updateMode :boolean
  public copyMode :boolean
  public deleteMode: boolean
  public change: boolean
  public activeTabName :any 
  public iSForm: FormGroup;
  public total: number
  public totalPercentatge: number
  public commonDate:any
  private hasSpouse:boolean
  public Blend:Blend
  public BlendDetail:BlendDetail
  private gridApi: any;
  private gridApi2: any;
  private gridColumnApi: any;
  private gridColumnApi2: any;

  public columnDefs: any;
  public columnDefs2: any;
  public rowData: any;	
  public rowData2: any;	
  public gridOptions : GridOptions 
  public gridOptions2 : GridOptions 
  public currentSelectedColumn;
  public strategyDataValueList = []
  public invalidDataVal = false
  public isShowDashboard: boolean = false
  
  private commonFunctions: CommonFunctions = new CommonFunctions
  orderID :number = 0  

  @ViewChild('editFTAStrategyDetails') editFTAStrategyDetails: TemplateRef<any>
  public ModalRef : BsModalRef 
  getRowStyle: (params: any) => { "font-weight": string; };
  pinnedBottomRowData: any;
  frameworkComponents: { customPinnedRowRenderer: any; };

  constructor( private router: Router,
    private formBuilder: FormBuilder, private alertService: AlertService, private blendsService: BlendDataService,
    private bsModalService :BsModalService, private ftaStrategiesService: FtastrategyDataService,
    private sharedService: SharedServiceService) { 

    this.loggedAgent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent;

      this.gridOptions = { 
        components: {
          agColumnHeader: CustomHeader
        },
        defaultColDef: { 
          headerComponentParams: {
            setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
            getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          } 
        } 
      };

      this.columnDefs = [
        { 
          headerName: 'ID', 
          field: 'Id',
          hide: true
          //sortable: true, 
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Name', 
          field: 'BlendName',
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Product Count', 
          field: 'ProductCount',
          cellStyle: {textAlign: "left"} 
          // sortable: true, 
          // width: 125,
          // filter: true,
          // cellClass: "rag-blue"
        },
        { 
          headerName: 'Rebalance Frequency', 
          field: 'RebalanceFrequency',
          cellStyle: {textAlign: "left"} 
          // sortable: true, 
          // width: 125,
          // filter: true,
          // cellClass: "rag-blue"
        },
        { 
          headerName: 'Begin Date', 
          field: 'BeginDate',
          cellStyle: {textAlign: "left"}//,  
          // sortable: true, 
          // filter: true,
          // cellClass: "rag-yellow"
        },
        { 
          headerName: 'Created By', 
          field: 'CreatedBy',
          hide: true,  
         // sortable: true, 
         // filter: true,
         // cellClass: "rag-yellow"
        },
        { 
          headerName: 'Created On', 
          field: 'CreatedOn',
          hide: true,  
         // sortable: true, 
         // filter: true,
         // cellClass: "rag-yellow"
        }
      ];



      this.gridOptions2 = { 
        components: {
          agColumnHeader: CustomHeader
        },
        defaultColDef: { 
          headerComponentParams: {
            setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
            getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          } 
        } 
      };

      this.columnDefs2 = [
        { 
          headerName: 'BlendDetailID', 
          field: 'BlendDetailID',
          hide: true,
          //sortable: true, 
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'BlendID', 
          field: 'BlendID',
          hide: true
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'FTAStrategyID', 
          field: 'FTAStrategyID',
          hide: true
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Name', 
          field: 'ProductName',
          cellStyle:  function(params) {
            if (params.node.rowPinned){
              return {backgroundColor: "#dcddde", textAlign: "left"}
            }else{
              return {textAlign: "lefy"}
            }
          },
          // sortable: true, 
          //width: 50,
          // filter: true,
          // cellClass: "rag-blue"
        },
        { 
          headerName: 'Begin Date', 
          field: 'ProductBeginDate',
          cellStyle:  function(params) {
            if (params.node.rowPinned){
              return {backgroundColor: "#dcddde", textAlign: "left"}
            }else{
              return {textAlign: "left"}
            }
          },  
          // sortable: true, 
          // filter: true,
          // cellClass: "rag-yellow"
        },
        { 
          headerName: 'Allocation', 
          field: 'Allocation',
          cellStyle:  function(params) {
            if (params.node.rowPinned){
              return {backgroundColor: "#dcddde", textAlign: "right"}
            }else{
              return {textAlign: "right"}
            }
          },
          valueFormatter: function(params) {
            return params.value + '%'; 
          },
          editable: function(params) {
            if (params.node.rowPinned){
              return false;
            }else{
              return true;
            }
          }
          
        }
      ];

      this.getRowStyle = function(params) {
        if (params.node.rowPinned) {
          return { "font-weight": "bold"};
        }
      };

    }

    onPinnedRowBottomCount() {
      var footerRowsToFloat = 10;
      var count = Number(footerRowsToFloat);
      var rows = createData(count, "Bottom", this.total, this.totalPercentatge, this.begindate);
      setTimeout(()=> {
      this.gridApi2.setPinnedBottomRowData(rows);
    }, 50); 
    }

  ngOnInit() {

   this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
   if(this.loggedAgent.UserRoleID== Constants.UserRoleID.Superuser)
    this.isShowDashboard = true
   this.iSForm = this.formBuilder.group({
			name: new FormControl(null) ,
      products: new FormControl(null),
      begindate: new FormControl(null),
      ismyblend: new FormControl(null),
      rebalancefrequency: new FormControl(null)
    } );   
 
	
    this.loadData()
  }

  loadData()
	{
		this.ismyblend = JSON.parse(localStorage.getItem(LocalStorage.ISMYBLEND))
		this.blendsService.getblends(this.loggedAgent, this.ismyblend).subscribe(data => this.getData(data))
	}

  public getData(data: any) {
    let blends: Array<any>=[] 
    data.forEach((element: any) => {
      //let client: Client = element
      let Blend: Blend = element
      Blend.BlendID = element.BlendID
      Blend.BlendName = element.BlendName
      Blend.ProductCount = element.ProductCount
      Blend.RebalanceFrequency = element.RebalanceFrequency
      var datePipe = new DatePipe('en-US');
      Blend.BeginDate = datePipe.transform(element.BeginDate, 'MM/dd/yyyy');
      Blend.IsMyBlend = element.IsMyBlend
      Blend.CreatedBy = element.CreatedBy
      Blend.CreatedOn = element.CreatedOn

      blends.push({Id: Blend.BlendID, BlendName: Blend.BlendName ,ProductCount: Blend.ProductCount,
        RebalanceFrequency: Blend.RebalanceFrequency, BeginDate: Blend.BeginDate, IsMyBlend: Blend.IsMyBlend,
      CreatedBy: Blend.CreatedBy, CreatedOn: Blend.CreatedOn})

    });
    setTimeout(()=> {
      this.gridApi.forEachNode(node => {
        if ( node.rowIndex  === 0 ){ 
          node.setSelected(true)
          this.id = node.data.Id
          this.name = node.data.BlendName
          this.rebalancefrequency = node.data.RebalanceFrequency
          this.productcount = node.data.ProductCount
          this.begindate = node.data.BeginDate
          this.previousBeginDate = node.data.BeginDate
          this.createdby = node.data.CreatedBy
          this.createdon = node.data.CreatedOn
          this.updateMode = true
          this.deleteMode = false
          this.copyMode = false
          this.copiedid = ""
          if(this.id != "" && this.id != undefined){
            this.blendsService.getblenddetailsbyid(this.id, this.loggedAgent).subscribe(data => this.getBlendDetails(data))
          }
        } 
      }); 
    
    }, 50); 

    
    this.rowData = blends;
    this.initializeBlends();
    this.getProductList()
    return this.rowData;
  }

  public getBlendDetails(data: any) {
    this.total = 0
    this.totalPercentatge = 0
    this.gridOptions2.api.showLoadingOverlay()
    this.getProductList()
    let blendDetails: Array<any>=[] 
    data.forEach((element: any) => {
      let BlendDetail: BlendDetail = element
      BlendDetail.BlendDetailID = element.BlendDetailID
      BlendDetail.BlendID = element.BlendID
      BlendDetail.FTAStrategyID = element.FTAStrategyID
      this.productlist.forEach((product: any) => {
        if(product.FTAStrategyID == BlendDetail.FTAStrategyID){

          BlendDetail.ProductName = product.FTAStrategyName
          var datePipe = new DatePipe('en-US');
          BlendDetail.ProductBeginDate = datePipe.transform(product.BeginDate, 'MM/dd/yyyy');

          this.selectedproductid = product.FTAStrategyID
        }
      })
      BlendDetail.Allocation = element.Allocation
      this.totalPercentatge = this.totalPercentatge + parseInt(element.Allocation)
      

      blendDetails.push({BlendDetailID: BlendDetail.BlendDetailID, BlendID: BlendDetail.BlendID ,
        FTAStrategyID: BlendDetail.FTAStrategyID, ProductName: BlendDetail.ProductName, ProductBeginDate: BlendDetail.ProductBeginDate
        , Allocation: BlendDetail.Allocation})   

    });
    
    setTimeout(()=> {
      this.gridApi2.forEachNode(node => {
        if ( node.rowIndex  === 0 ){ 
          node.setSelected(true)
        } 
      }); 
    
    }, 50);
    this.gridOptions2.api.hideOverlay()
    if(blendDetails.length != 0){
      if(blendDetails[blendDetails.length - 1].ProductName == undefined){
        this.gridOptions2.api.showLoadingOverlay()
        if(this.id != "" && this.id != undefined){
          this.blendsService.getblenddetailsbyid(this.id, this.loggedAgent).subscribe(data => this.getBlendDetails(data))
        }
      } else{
        this.gridOptions2.api.hideOverlay()
        this.rowData2 = blendDetails;
        if(this.rowData2 != null && this.rowData2 != undefined){
        this.total = this.rowData2.length
        this.getCommonDate()
        this.pinnedBottomRowData = createData(1, "Bottom", this.total, this.totalPercentatge, this.commonDate);
        this.gridOptions2.api.setRowData(this.rowData2); 
        this.gridApi2.setPinnedBottomRowData(this.pinnedBottomRowData);
        }  
      }
    }
    setTimeout(() => {this.gridApi.sizeColumnsToFit();});
  }
  

  public getProductList(){
    this.ftaStrategiesService.getftastrategies(this.loggedAgent).subscribe(data => 
			{ 
        this.productlist = data   
			},
		error => {
				this.alertService.clear()
				this.alertService.error('Error occured while retrieving data')
				}); 
  }

  public addProduct() {
    if(this.selectedProducts != "" && this.selectedProducts != undefined){
      this.alertService.clear()
      let data = this.selectedProducts
      var datePipe = new DatePipe('en-US');
      let BeginDate = datePipe.transform(data.BeginDate, 'MM/dd/yyyy');
      let i: number = 0;
      let add: boolean = true
      if(this.rowData2 == undefined){
        this.rowData2 = []
      }
      for(i = 0; i < this.rowData2.length; i++){
        if(this.rowData2[i].FTAStrategyID == this.selectedProducts.FTAStrategyID){
            this.alertService.error('Product already exist')
            add = false
            this.selectedProducts = ""
            break
        }
      }

      if(add){
        this.rowData2.push({BlendDetailID: this.commonFunctions.getnewguid() , BlendID: this.id ,
          FTAStrategyID: data.FTAStrategyID, ProductName: data.FTAStrategyName, ProductBeginDate: BeginDate
          , Allocation: 0})
        this.getProductList()
        this.total = this.rowData2.length
        this.totalPercentatge  = this.totalPercentatge + 0
        this.getCommonDate()
        this.begindate = this.commonDate
      }
      
      setTimeout(()=> {
        this.getCommonDate()
        this.pinnedBottomRowData = createData(1, "Bottom", this.total, this.totalPercentatge, this.commonDate);
        this.gridOptions2.api.setRowData(this.rowData2);
        this.gridApi2.setPinnedBottomRowData(this.pinnedBottomRowData);
        this.total = this.rowData2.length
        this.gridApi2.forEachNode(node => {
          if ( node.rowIndex  === 0 ){ 
            node.setSelected(true)
            this.selectedproductid = node.data.FTAStrategyID
          } 
        }); 
      
      }, 50); 
      this.selectedProducts = []
      this.getProductList()
    }
  }

public getCommonDate(){
  let date = ""
  this.commonDate = ""
  this.rowData2.forEach((element: any) =>{
    let newDate = null
    newDate = new Date(element.ProductBeginDate);
    if(newDate > date){
      date = newDate
    }
  })
  var datePipe = new DatePipe('en-US');
  this.commonDate = datePipe.transform(date, 'MM/dd/yyyy');
}

public deleteProducts(){
  this.invalidDataVal = false
  this.totalPercentatge = 0
  this.rowData2.forEach((element: any) =>{
    if(element.FTAStrategyID == this.selectedproductid){
      this.rowData2.splice(this.rowData2.indexOf(element),1)
    }
  })
  this.gridOptions2.api.setRowData(this.rowData2); 
  this.total = this.rowData2.length
  setTimeout(()=> {
    this.gridApi2.forEachNode(node => {
      this.selectedproductid = node.data.FTAStrategyID
      this.selectedProducts = ""
      this.totalPercentatge = this.totalPercentatge + parseInt(node.data.Allocation)
    }); 
    this.getCommonDate()
    this.begindate = this.commonDate
    this.pinnedBottomRowData = createData(1, "Bottom", this.total, this.totalPercentatge, this.commonDate);
    this.gridOptions2.api.setRowData(this.rowData2); 
    this.gridApi2.setPinnedBottomRowData(this.pinnedBottomRowData);

    this.gridApi2.forEachNode(node => { 
      if ( node.rowIndex  === 0 ){
        node.setSelected(true)  
        this.selectedproductid = node.data.FTAStrategyID
      }
      if(!node.data.Allocation.toString().match(/^[+\-]?(\.\d+|\d+(\.\d+)?)$/)){
        this.invalidDataVal = true
      }
      
    }); 
  }, 50);

}

  initializeBlends()
  {
    this.id = ""
    this.name = ""
    this.productcount = ""
    this.rebalancefrequency = "Monthly"
    this.begindate = ""  
    this.createdby = "" 
    this.createdon = "" 
    this.productlist = []
    this.selectedProducts = ""
    this.totalPercentatge = 0
    this.total = 0
    this.pinnedBottomRowData = createData(1, "Bottom", this.total, this.totalPercentatge, null);
    if(this.gridApi2 != undefined){
      this.gridApi2.setPinnedBottomRowData(this.pinnedBottomRowData);
    }
  this.updateMode = false 
  }

  onGridReady(params: any) { 
		this.gridApi  = params.api;
		this.gridColumnApi = params.columnApi; 
		setTimeout(() => { 
        this.loadData()     
      	//params.api.sizeColumnsToFit();
    }, 50);

  }

  onGridReady2(params: any) { 
		this.gridApi2  = params.api;
		this.gridColumnApi2 = params.columnApi; 
  }

  onBeginDateChange(event){
    this.begindate = event
  }

  getCurrentSelectedColumn() {
    return this.currentSelectedColumn;
    }
    
  setCurrentSelectedColumn(colId) {
    this.currentSelectedColumn = colId;
    }


  loadBlendsStaticDataForNew()
  {   
    this.initializeBlends()   
  }

  gridSizeChanged(params: any) {
		params.api.sizeColumnsToFit();
  }

  public onProductClicked(event: any){
    event.node.setSelected(true);
    this.selectedproductid = event.data.FTAStrategyID
  }
  
  public onCellClicked(event: any) {
    this.invalidDataVal = false
    this.alertService.clear()
    if(event.data.Id == ""){
      this.blendsService.getblenddetailsbyid(this.copiedid, this.loggedAgent).subscribe(data => this.getBlendDetails(data))
    }
    if(event.data.BlendName == ""){
      this.rowData2 = []
      this.gridOptions2.api.setRowData(this.rowData2);
      this.loadBlendsStaticDataForNew()
    }
    this.updateMode = true; 
    this.copyMode = true; 
    this.deleteMode = false; 
    if(event.data.Id != "" && event.data.Id != undefined){
      this.blendsService.getblenddetailsbyid(event.data.Id, this.loggedAgent).subscribe(data => this.getBlendDetails(data))
    }
    event.node.setSelected(true);
    var selectedBlendID : string = event.data.Id
    var selectedBlendName : string = event.data.BlendName
    var selectedBlendProductCount : string = event.data.ProductCount
    var selectedBlendRebalanceFrequency : string = event.data.RebalanceFrequency
    var selectedBlendBeginDate : string = event.data.BeginDate
    var selectedBlendIsMyBlend : string = event.data.IsMyBlend
    var selectedBlendCreatedBy : string = event.data.CreatedBy
    var selectedBlendCreatedOn : string = event.data.CreatedOn
    this.loadBlend(selectedBlendID,selectedBlendName,selectedBlendProductCount, selectedBlendRebalanceFrequency, selectedBlendBeginDate,
      selectedBlendIsMyBlend, selectedBlendCreatedBy,selectedBlendCreatedOn) 
  }

  loadBlend(selectedBlendID:string,selectedBlendName:string,selectedBlendProductCount:string,selectedBlendRebalanceFrequency:string,selectedBlendBeginDate:string,
    selectedBlendIsMyBlend:string,selectedBlendCreatedBy:string,selectedBlendCreatedOn:string)
  {
    this.id = selectedBlendID;
    this.name = selectedBlendName;
    this.productcount = selectedBlendProductCount;
    this.rebalancefrequency = selectedBlendRebalanceFrequency;
    this.begindate = selectedBlendBeginDate;
    this.previousBeginDate = selectedBlendBeginDate
    this.createdby = selectedBlendCreatedBy;
    this.createdon = selectedBlendCreatedOn;
  }

  searchinarray(nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
     
        if (myArray[i].value === nameKey) {
          //console.log(myArray[i].value)
            return myArray[i].value;
        }
    }
}

saveBtnClickEvent()
{  
  if (this.validateBlend()){
    //if another row is clicked after adding a new row or copied a row
    if(this.id == "" || this.createdby == "" || this.createdon == ""){
      this.id =  this.commonFunctions.getnewguid() 
      this.createdby = this.loggedAgent.AgentID
      this.createdon = new Date()
    }
    this.blenddetails =[]
    this.rowData2.forEach((element: any) =>{
      this.BlendDetail = new BlendDetail()
      this.BlendDetail.BlendDetailID = this.commonFunctions.getnewguid()
      this.BlendDetail.BlendID = this.id
      this.BlendDetail.FTAStrategyID = element.FTAStrategyID
      this.BlendDetail.Allocation = element.Allocation
      this.blenddetails.push(this.BlendDetail)
    })

    this.Blend = new Blend()
    this.Blend.BlendName = this.name
    this.Blend.ProductCount = this.productcount
    this.Blend.RebalanceFrequency = this.rebalancefrequency
    this.Blend.BeginDate =  new Date(this.begindate).toLocaleDateString() 
	  this.ismyblend = JSON.parse(localStorage.getItem(LocalStorage.ISMYBLEND))
    this.Blend.IsMyBlend = this.ismyblend
    this.Blend.BlendDetail = this.blenddetails
    if(this.updateMode){
      this.Blend.BlendID =  this.id
      this.Blend.CreatedBy = this.createdby
      this.Blend.CreatedOn = this.createdon
    } else{
      this.Blend.BlendID =  this.id
      this.Blend.CreatedBy = this.loggedAgent.AgentID
      this.Blend.CreatedOn = new Date()
    }
  
  if(this.deleteMode)
  {
    this.Blend.bDeleted = true
    this.Blend.DeletedBy = this.loggedAgent.AgentID
    this.Blend.DeletedOn = new Date()
  } else{
    this.Blend.bDeleted = false
  }
  this.blendsService.saveBlend(this.Blend, this.loggedAgent).subscribe(date =>
    {
      this.alertService.clear()
      if(this.updateMode && !this.deleteMode){
        this.alertService.success('Successfully updated')
      } else if(this.deleteMode){
        this.alertService.success('Successfully deleted')
      } else{
        this.alertService.success('Successfully saved')
      }
      
      this.loadData()
      this.blenddetails = []
    },
    error => { 
      this.alertService.clear()
      this.alertService.error('Error occured while saving data')
      this.loadData()
     });
    }
}

cancelBtnClickEvent()
{
  this.alertService.clear()
  this.invalidDataVal = false
  this.id = ""
  this.loadBlendsStaticDataForNew()
  this.rowData2 = []
  this.selectedProducts = []
  this.getProductList()
  this.gridOptions.api.deselectAll();
}

validateBlend():boolean
  { 
    if(!this.deleteMode){
      this.alertService.clear()
      if ((this.iSForm.controls.name.value == ""  || 
        this.iSForm.controls.name.value == null))
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Name')
        return false
      } 
      if (this.rowData2.length == 0)
      { 
        this.alertService.clear()
        this.alertService.error('Invalid product count')
        return false
      }
      var i
      for(i = 0; i < this.rowData2.length; i++){
        if (this.rowData2[i].Allocation == 0)
        { 
          this.alertService.clear()
          this.alertService.error('Invalid Allocation')
          return false
        }
      }
      if ((this.iSForm.controls.rebalancefrequency.value == ""  || 
            this.iSForm.controls.rebalancefrequency.value == null ))
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Rebalance Frequency')
        return false 
      }     
  
      if ((this.iSForm.controls.begindate.value == ""  || 
      this.iSForm.controls.begindate.value == null ))
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Begin Date')
        return false
      }
    }

    return true
  }  
  
  exitBtnClickEvent()
  {
    if(JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) != null){
      this.sharedService.changeSideBarRequest()   
			this.router.navigateByUrl('home')
    } else{
      this.router.navigateByUrl('landing'); 
    }
    
  }

  //load the superuser dashboard page
	public navigateSuperUserDashboard()
	{
		this.router.navigateByUrl('superuserdashboard'); 
	}

  newBtnClickEvent()
  { 
    this.invalidDataVal = false
    this.alertService.clear()
    if(this.rowData.length != 0){
      let node
      node = this.rowData[this.rowData.length - 1]
      if(node.CreatedOn ==""){
        this.rowData.splice(this.rowData.indexOf(node),1)
      } 
    } 
    this.loadBlendsStaticDataForNew()
    this.id = this.commonFunctions.getnewguid()
    this.rowData.push({Id: this.id, BlendName: "" ,ProductCount: "", RebalanceFrequency: "",
      BeginDate: "", IsMyBlend: "", CreatedBy: "", CreatedOn: ""})
    this.gridOptions.api.setRowData(this.rowData);
    this.gridApi.forEachNode(node => {
        node.setSelected(true)          
    }); 
    this.deleteMode = false   
    this.updateMode = false
    this.getProductList()
    this.rowData2 = []
    this.gridOptions2.api.setRowData(this.rowData2);
  }

  copyBtnClickEvent()
  {
    this.invalidDataVal = false
    this.alertService.clear()
    this.copiedid = ""
    if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return
      this.updateMode = false
      if(this.rowData.length != 0){
        let node
        node = this.rowData[this.rowData.length - 1]
        if(node.CreatedOn ==""){
          this.rowData.splice(this.rowData.indexOf(node),1)
        } 
      } 
      if(this.id != "" && this.id != undefined){
        this.copiedid = this.id
        this.blendsService.getblenddetailsbyid(this.id, this.loggedAgent).subscribe(data => this.getBlendDetails(data))
      }
      this.id = this.commonFunctions.getnewguid()
      var datePipe = new DatePipe('en-US');

      this.rowData.push({Id: "", BlendName: this.name ,ProductCount: this.productcount, RebalanceFrequency: this.rebalancefrequency,
      BeginDate: datePipe.transform(this.begindate, 'MM/dd/yyyy'), IsMyBlend: "", CreatedBy: "", CreatedOn: ""})
      this.gridOptions.api.setRowData(this.rowData); 
      this.gridApi.forEachNode(node => {
        node.setSelected(true)          
    });
  }

  deleteBtnClickEvent()
  {     
    this.invalidDataVal = false
    this.alertService.clear()
    if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return    
    if (confirm("Are you sure you want to delete selected record?")) {  
      if(this.rowData.length != 0){
        let node
        node = this.rowData[this.rowData.length - 1]
        if(node.CreatedOn ==""){
          this.rowData.splice(this.rowData.indexOf(node),1)
          this.gridOptions.api.setRowData(this.rowData);
          this.loadData()
        } else{
          this.deleteMode = true
          this.updateMode = true
          this.saveBtnClickEvent()
        }
      }   
      this.rowData2 = []
      this.gridOptions2.api.setRowData(this.rowData2);
    }
  }

  editBtnClickEvent()
  {
    
    this.ModalRef = this.bsModalService.show(this.editFTAStrategyDetails)
  }

  onCellValueChanged(event: any)
  {
    this.alertService.clear()
    if(event.newValue.match(/^[+\-]?(\.\d+|\d+(\.\d+)?)$/)){ 
      if(event.oldValue.toString().match(/^[+\-]?(\.\d+|\d+(\.\d+)?)$/)){
        this.totalPercentatge = this.totalPercentatge - parseInt(event.oldValue) 
      }
      this.totalPercentatge = this.totalPercentatge + parseInt(event.newValue)  
      this.pinnedBottomRowData = createData(1, "Bottom", this.total, this.totalPercentatge, this.commonDate);
      this.gridOptions2.api.setRowData(this.rowData2); 
      this.gridApi2.setPinnedBottomRowData(this.pinnedBottomRowData);
      setTimeout(()=> { 
        this.gridApi2.forEachNode(node => {
          if ( node.rowIndex  === 0 ){ 
            node.setSelected(true)
          } 
        }); 
      
      }, 50);
      this.invalidDataVal = false
    } else{
      this.alertService.clear()
      this.invalidDataVal = true
      this.alertService.error('Invalid value')
    }

    setTimeout(()=> { 
        this.gridApi2.forEachNode(node => {
            if(!node.data.Allocation.toString().match(/^[+\-]?(\.\d+|\d+(\.\d+)?)$/)){
              this.invalidDataVal = true
            }
        }); 
      
      }, 50);
  }
}
function createData(count, prefix, total, totalPercentatge, commonDate) {
  var result = [];
  if(commonDate == null){
    var datePipe = new DatePipe('en-US');
    commonDate = datePipe.transform(new Date(), 'MM/dd/yyyy');
  }
  var datePipe = new DatePipe('en-US');
  var today = datePipe.transform(new Date(), 'MM/dd/yyyy');
  for (var i = 0; i < count; i++) {
    result.push({
      ProductName: total + " Allocations: " + commonDate + " - " + today,
      Allocation:  totalPercentatge
    });
  }
  return result;
}