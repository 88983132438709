import {
	Component,
	OnInit,
	ElementRef,
	ViewChild, 
	ViewContainerRef,
	ComponentRef,
	ComponentFactoryResolver,
	OnDestroy
} from '@angular/core';
import { Router } from '@angular/router'; 
import { Constants } from '../../util/constants'; 
import { LocalStorage } from '../../util/localstorage.service'; 
import { Agent } from '../../models/agent.model'; 
 
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { BusinessCalculatorInput } from 'src/app/models/business-calculator-input.model';
import { BusinessCalculatorOutput } from 'src/app/models/business-calculator-output.model';
import { AlertService } from 'src/app/util/alert/alert.service';
import { CalculatorComponent } from '../calculator/calculator.component';
import { FP4FADataService } from '../fp4fa-data.service';
import { SharedServiceService } from '../../util/shared-service.service';
import { FP4FACommonFunctionsService } from '../fp4fa-common-functions.service';


@Component({
  selector: 'app-company-configuration',
  templateUrl: './company-configuration.component.html',
  styleUrls: ['./company-configuration.component.scss'] 
})


export class CompanyConfigurationComponent implements OnInit  {

  name = 'Angular'; 
  public iSForm: FormGroup;
  public noofyears: string;
  public startingcash: string;
  public businesscalculatorinput: BusinessCalculatorInput;
  public businesscalculatoroutput: BusinessCalculatorOutput; 
  public businessplanname: string
  public businessplanid: string
  public addbusinessplan: boolean = true
  public saveactionshow :boolean = true
  public businessPlansArray: Array<any> = []
  public existingbusinessplanid: string
  public isShowDashboard: boolean = false
  
  status: boolean = false;
  toggleRightbar(){
    this.status = !this.status;       
}
resizestatus: boolean = false;
resizeRightbar(){
    this.resizestatus = !this.resizestatus;
        
} 
   
  onSubmit() {
	  //Test comment Dinithi
    return false;
  }

//#region Save Action Button related Methods...
closeBottombar(){
	this.saveactionshow = false;
  }
  
  showBottombar(){
	this.saveactionshow = true;
  }
  
   SaveBusinessPlan(showBusinessPlanSavedAlert)
  {
	this.alertService.clear()
	if(this.fp4facommonFunctions.ValidateSaveBusinessPlan()){
		this.fp4facommonFunctions.AddEmployees()
		this.FP4FAdataService.savebusinessplan(this.loggedAgent, this.businesscalculatorinput).subscribe(data => {
		if(showBusinessPlanSavedAlert){
			this.alertService.success('Business Plan successfully saved.')
		}
			localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
		},
		error => { 
			this.alertService.error('An error occurred while saving Business Plan')
		});
	}
  }
  
  //#endregion
	public constants: any = Constants

	private selectClientID:any
	public loggedAgent: Agent 

	constructor(private resolver: ComponentFactoryResolver,  
		   private alertService: AlertService,
		  private router: Router, private formBuilder: FormBuilder , 
		  private FP4FAdataService: FP4FADataService,
		  private sharedService: SharedServiceService,private fp4facommonFunctions: FP4FACommonFunctionsService) 
	{  
			 
	}

	ngOnInit() { 
		this.loggedAgent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent;	
		if(this.loggedAgent.UserRoleID== Constants.UserRoleID.Superuser)
			this.isShowDashboard = true

		this.intializeData();
		
		this.iSForm = this.formBuilder.group({
			noofyears: new FormControl(null) ,
			startingcash: new FormControl(null),
			businessplanname: new FormControl(null),
			existingbusinessplanid: new FormControl(null)
		} );  
	}

	intializeData(){	
		this.businesscalculatorinput = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_INPUT)) as BusinessCalculatorInput;
		this.businessplanname = this.businesscalculatorinput.BusinessPlanName != null 
		? this.businesscalculatorinput.BusinessPlanName.toString() : ""
		this.noofyears = this.businesscalculatorinput.NoOfYears != null 
		? this.businesscalculatorinput.NoOfYears.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
		this.startingcash = this.businesscalculatorinput.StartingCash != null 
		? this.businesscalculatorinput.StartingCash.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
	}


	// ngOnDestroy()
	// {
	// 	this.alertService.clear();
	// } +

	 
	onNoOfYearsChange(event){
		this.alertService.clear()
		if(event.code == "Backspace" || event.code == "Delete"){
			this.businesscalculatorinput.NoOfYears =  0
			localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
			localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
		} else{
			if(event.which >= 37 && event.which <= 40) return;
			this.noofyears = event.target.value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")  
			this.businesscalculatorinput.NoOfYears =  parseInt(this.noofyears.replace(/\D/g, ""))
			localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
			localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
	
			//Load business calculator input and output when changing number of years
			//this.AddBusinessPlan();
			this.getBusinessCalculatorInputWithDefaults()
		}
	}

	onStartingCash(event){
		this.alertService.clear()
		if(event.which >= 37 && event.which <= 40) return;
		this.startingcash = event.target.value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")  
		this.businesscalculatorinput.StartingCash =  parseInt(this.startingcash.replace(/\D/g, ""))
		localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
		localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
	}

	onBusinessPlanNameChange(event){
		this.alertService.clear() 
		this.businesscalculatorinput.BusinessPlanName =  this.businessplanname
		localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
		localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
	}

	getBusinessCalculatorInputWithDefaults()
	{
		this.alertService.clear()
		this.FP4FAdataService.getbusinesscalculatorinputwithdefaults(this.loggedAgent, this.businesscalculatorinput).subscribe(data => {
			this.businesscalculatorinput  = data 
			this.businesscalculatorinput.PayrollTaxes.FederalUnemployment = 0
			this.businesscalculatorinput.PayrollTaxes.Medicare = 0
			this.businesscalculatorinput.PayrollTaxes.SocialSecurity = 0
			this.businesscalculatorinput.PayrollTaxes.StateUnemployment = 0

	 		localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
			localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
	 		this.FP4FAdataService.generatebusinesscalculator(this.loggedAgent , this.businesscalculatorinput).subscribe(data => {
	 			let businesscalculatoroutput: BusinessCalculatorOutput  
	 			businesscalculatoroutput  = data 	 
				 localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_OUTPUT, JSON.stringify(businesscalculatoroutput));  
				 
				 //Load data
				 this.intializeData()
	 		},
	 		error => { 
				this.alertService.error('An error occurred while getting FP4FA data')
	 		});   
			
	 	},
	 	error => { 
	 		this.alertService.error('An error occurred while getting FP4FA data')
	 	});	 
	 } 

	LoadMarketingProduction()
	{
		this.alertService.clear()   
		if(this.fp4facommonFunctions.ValidateSaveBusinessPlan()){  
			this.fp4facommonFunctions.AddEmployees()
			this.FP4FAdataService.savebusinessplan(this.loggedAgent, this.businesscalculatorinput).subscribe(data => { 
			//this.alertService.success('Business Plan successfully saved.')
			localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0"); 
			this.sharedService.changeComponentsRequest(Constants.COMP_NAME.marketing_production)
					
			},
			error => { 
				this.alertService.error('An error occurred while saving Business Plan')
			});
		} 		
	}

	AddBusinessPlan(){
		this.alertService.clear()

		//Check for the un saved data
		 let hasUnsavedData = localStorage.getItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA);
		 if (hasUnsavedData =="1")
		 {
			let planname = this.businesscalculatorinput.BusinessPlanName
			if(planname == undefined || planname == null){ // No plan name added
				planname = ""
			}
			else{
				planname = " '" + planname + "'"
			}

			if (confirm("You have unsaved data for the current plan" + planname +". Do you want to save them?")) { 
				
				//this.SaveBusinessPlan()
				// Note : Calling SaveBusinessPlan did not worked here as we need to load new plan ONLY after the response of service save method
				// Therefore done the validation and called service here again and load new plan inside successful response
				if(this.fp4facommonFunctions.ValidateSaveBusinessPlan()){ 
					this.fp4facommonFunctions.AddEmployees()
					this.FP4FAdataService.savebusinessplan(this.loggedAgent, this.businesscalculatorinput).subscribe(data => { 
						localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
						this.alertService.success('Business Plan successfully saved.')
						this.alertService.clear()
						this.LoadNewBusinessPlan();
					},
					error => { 
						this.alertService.error('An error occurred while saving Business Plan')
					});
				}
				
			}
			else // don't save just clear the unsavedata flag
			{
				localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
				this.LoadNewBusinessPlan()
			}
			
		 }
		 else { // No unsaved data just load new plan
			this.LoadNewBusinessPlan()
		}

		/* this.addbusinessplan = true
			this.businesscalculatorinput = new BusinessCalculatorInput()
			this.businesscalculatorinput.OrganizationID = this.loggedAgent.OrganizationID
			this.businesscalculatorinput.OwnerID = this.loggedAgent.AgentID
			this.getBusinessCalculatorInputWithDefaults() */
		
		/* 	this.bizIQdataService.getbusinesscalculatorinputwithdefaults(this.loggedAgent, this.businesscalculatorinput).subscribe(data => {
			this.businesscalculatorinput  = data 
			localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
			this.bizIQdataService.generatebusinesscalculator(this.loggedAgent , this.businesscalculatorinput).subscribe(data => {
				this.businesscalculatoroutput = new BusinessCalculatorOutput  
				this.businesscalculatoroutput  = data 	 
				localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_OUTPUT, JSON.stringify(this.businesscalculatoroutput)); 
				this.intializeData()
				this.businessplanname = ""
			},
			error => { 
				this.alertService.error('An error occurred while getting BizIQ data')
			});   
			
		},
		error => { 
			this.alertService.error('An error occurred while getting BizIQ data')
		}); */
	}

	LoadNewBusinessPlan(){
		this.addbusinessplan = true
		this.businesscalculatorinput = new BusinessCalculatorInput()
		this.businesscalculatorinput.OrganizationID = this.loggedAgent.OrganizationID
		this.businesscalculatorinput.OwnerID = this.loggedAgent.AgentID
		this.getBusinessCalculatorInputWithDefaults()
	}

	EditBusinessPlan(){
		this.alertService.clear()
		this.addbusinessplan = false
		this.FP4FAdataService.getallbusinessplansbyagentid(this.loggedAgent).subscribe(data => {
			this.alertService.clear()
			this.businessPlansArray = []
			this.businessPlansArray.push({id: 0, text: "Select Business Plan Name"})
			if(data != null && data != undefined){
			  var i
			  for(i=0; i < data.length; i++){
				this.businessPlansArray.push({id: data[i].BusinessPlanID, text: data[i].BusinessPlanName})
			  } 
			}
			this.existingbusinessplanid = this.businessPlansArray[0].id
			return this.businessPlansArray   	
		},
		error => { 
			this.alertService.error('An error occurred while getting FP4FA data')
		});
	}

	setBusinessPlanId(e: any){
		this.alertService.clear()
		if(e.target.value == "0"){
			this.AddBusinessPlan()
			this.addbusinessplan = false
		} else{
			//Check for the un saved data
			let hasUnsavedData = localStorage.getItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA);
			if (hasUnsavedData =="1")
			{
			let planname = this.businesscalculatorinput.BusinessPlanName
			if(planname == undefined || planname == null){ // No plan name added
				planname = ""
			}
			else{
				planname = " '" + planname + "'"
			}

			if (confirm("You have unsaved data for the current plan" + planname +". Do you want to save them?")) { 
				
				//this.SaveBusinessPlan()
				// Note : Calling SaveBusinessPlan did not worked here as we need to load new plan ONLY after the response of service save method
				// Therefore done the validation and called service here again and load new plan inside successful response
				if(this.fp4facommonFunctions.ValidateSaveBusinessPlan()){
					this.fp4facommonFunctions.AddEmployees()
					this.FP4FAdataService.savebusinessplan(this.loggedAgent, this.businesscalculatorinput).subscribe(data => { 
						localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
						this.alertService.success('Business Plan successfully saved.')
						this.LoadExistingBusinessPlan();
					},
					error => { 
						this.alertService.error('An error occurred while saving Business Plan')
					});
				}
					
			}
			else // don't save just clear the unsavedata flag
			{
				localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
				this.LoadExistingBusinessPlan();
			}
				
		}
		else { // No unsaved data just load selected  plan
			this.LoadExistingBusinessPlan();
		}



			/* this.businesscalculatorinput = new BusinessCalculatorInput()
			this.FP4FAdataService.getbusinessplanbyid(this.existingbusinessplanid, this.loggedAgent).subscribe(data => {
				this.businesscalculatorinput  = data 
				localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput)); 
				localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
				this.FP4FAdataService.generatebusinesscalculator(this.loggedAgent , this.businesscalculatorinput).subscribe(data => {
					this.businesscalculatoroutput = new BusinessCalculatorOutput  
					this.businesscalculatoroutput  = data 	 
					localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_OUTPUT, JSON.stringify(this.businesscalculatoroutput)); 
					this.intializeData()
				},
				error => { 
					this.alertService.error('An error occurred while getting FP4FA data')
				});   
				
			},
			error => { 
				this.alertService.error('An error occurred while getting FP4FA data')
			}); */
		}
	}

	LoadExistingBusinessPlan()
	{
		this.businesscalculatorinput = new BusinessCalculatorInput()
		this.FP4FAdataService.getbusinessplanbyid(this.existingbusinessplanid, this.loggedAgent).subscribe(data => {
			this.businesscalculatorinput  = data 
			localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput)); 
			localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
			this.FP4FAdataService.generatebusinesscalculator(this.loggedAgent , this.businesscalculatorinput).subscribe(data => {
				this.businesscalculatoroutput = new BusinessCalculatorOutput  
				this.businesscalculatoroutput  = data 	 
				localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_OUTPUT, JSON.stringify(this.businesscalculatoroutput)); 
				this.intializeData()
			},
			error => { 
				this.alertService.error('An error occurred while getting FP4FA data')
			});   
			
		},
		error => { 
			this.alertService.error('An error occurred while getting FP4FA data')
		});
	}

	deleteBusinessPlan(){
		this.alertService.clear()
		if(this.existingbusinessplanid == "0"){
			this.alertService.error('Pleease select a Business Plan to delete')
		} else{
			if (confirm("Are you sure you want to delete selected Business Plan?")) { 
				this.FP4FAdataService.deletebusinessplanbyid(this.loggedAgent, this.businesscalculatorinput).subscribe(data => {
					localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
					this.AddBusinessPlan()  
					this.addbusinessplan = false
					this.intializeData()
					this.businessplanname = ""
					this.existingbusinessplanid = this.businessPlansArray[0].id
					this.alertService.success('Deleted the Business Plan Successfully')
					this.EditBusinessPlan()
				},
				error => { 
					this.alertService.error('An error occurred while getting FP4FA data')
				});
			}
		}

	}
	
exitBtnClickEvent()
{
  this.router.navigateByUrl('landingfp4fa'); 
}

//load the superuser dashboard page
public navigateSuperUserDashboard()
{
	this.router.navigateByUrl('superuserdashboard'); 
}
}




