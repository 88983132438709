
<div class="container-fluid">
	<div class="row">
	
		<main role="main" class="px-0 col-md-10 vh-100 main-wrapper" [ngClass]="{'col-md-10': isSideBar, 'col-md-12': !isSideBar}" >
      
      
      <div class="row fp4fa-wrapper h-100 w-100 p-4 m-0" [ngClass]="{'expand': status, 'resize': !status, 'status resize': status && resizestatus  }" >

     <div class="scroll-wrapper w-100" >
        <button type="button" class="btn btn-primary btn-sm float-right mr-3" (click)="exitBtnClickEvent()">
            <i class="fas fa-sign-out"></i> Exit
        </button>
        <button type="button" class="btn btn-primary btn-sm mr-1 float-right" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
          {{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
        </button>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Marketing / Production</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{currentStepperName}}</li>
          </ol>
        </nav>
<div class="row vh-100 w-100 m-0 justify-content-center">
  <div class="col-md-12">
    <div id="stepper-revenue" class="bs-stepper ml-0">
      <div class="bs-stepper-header table-responsive">
        <div class="step" data-target="#wizard-fp" (click)="changeStepper(0)">
          <button class="step-trigger">
            <span class="bs-stepper-label">Financial Products</span>
          </button>
        </div>
        <div class="line"></div>
        <div class="step" data-target="#wizard-rd" (click)="changeStepper(1)">
          <button class="step-trigger">
            <span class="bs-stepper-label">Rep Data</span>
          </button>
        </div>
        
      </div>
      <div class="row w-100 m-0 p-0 bs-stepper-content">
        <form class="w-100" (ngSubmit)="onSubmit()">
          <div id="wizard-fp" class="content">             
            <div class="card widget mb-3 mt-3">       
              <div class="card-body p-5">
                <form  [formGroup]="financialProductsForm"> 
                  <div class="form-group col-md-3 pl-0">
                    <label class="small mb-1" for="">Firm Net Advisory Fee<i class="fas fa-question-circle ml-2 text-muted" tooltip="Annual NET AUM fees paid to your firm (Typically, advisors in our program charge 1.95% annual fee, 0.75% goes to FTA, 1.24% is paid to your firm.  1.24% is the number for this entry in this case.) "></i></label> 
                      <div class="input-group value-helper">
                        <input type="text" class="form-control text-right" placeholder="" 
                        formControlName="totalAUMFee" [(ngModel)]="totalAUMFee" id="totalAUMFee"
                        (keyup)="CommaFormatted($event)" 
                        (change)="AssignFinancialProducts($event,0)" autocomplete="off"
                        aria-describedby="basic-addon1">
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon1">%</span>
                        </div> 
                      </div>
                  </div>
                  <hr/>
                    <div class="row mt-3 mb-0">
                        <div class="col-md-11 bg-white text-right ml-5">
                          <button type="button" class="btn btn-primary btn-sm at-right" (click)="AddNewAnnuity()"> Add New Annuity</button>
                        </div> 
                    </div>
                    <div class="row ">
                      <div class="col-md-10">
                        <div class="table mt-3">
                          <table class="table">
                            <thead>
                              <tr>
								                <th scope="row" style="vertical-align: top;">
                                  <div class="row-head-with-more position-relative">   
                                    <label class="float-left" for="">Annuity Name<i class="fas fa-question-circle ml-2 text-muted" tooltip="Name of annuity product"></i></label>         
                                  </div>
                                </th>
                                <th scope="row" style="vertical-align: top;width: 26%;">
                                  <div class="row-head-with-more position-relative">
                                      <label class="float-left" for="">Commission Level on Annuity Sales<i class="fas fa-question-circle ml-2 text-muted" tooltip="Commission percentage received on new annuity sale "></i></label>          
                                  </div>
                                </th>
                                <th scope="row" style="vertical-align: top;width: 21%;">
                                  <div class="row-head-with-more position-relative">
                                      <label class="float-left" for="">Annuity Trail Commission <i class="fas fa-question-circle ml-2 text-muted" tooltip="Commission percentage paid in subsequent years "></i></label>             
                                  </div>
                                </th>
                                <th scope="row" style="vertical-align: top;">
                                  <div class="row-head-with-more position-relative">
                                      <label class="float-left" for="">Trail Years<i class="fas fa-question-circle ml-2 text-muted" tooltip="# of years of annuity trail "></i></label>             
                                  </div>
                                </th>
                                <th scope="row" style="vertical-align: top;width: 16%;">
                                  <div class="row-head-with-more position-relative">
                                      <label class="float-left" for="">Product Sales Mix<i class="fas fa-question-circle ml-2 text-muted" tooltip="% of total annuity sales"></i></label>              
                                  </div>
                                </th>
                                <th scope="row" style="vertical-align: top;width: 6%;"></th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of annuities;">
                                <td><input type="text" class="form-control" id="annuityName,{{item.FinancialProductDetailID}}" value="{{item.AnnuityName}}"
                                  (keyup)="AssignFinancialProducts($event,item.FinancialProductDetailID)" placeholder="" autocomplete="off"/></td>
								                <td>
                                  <div class="input-group value-helper">
                                    <input type="text" class="form-control" id="commissionpercentageOnANN,{{item.FinancialProductDetailID}}" value="{{item.CommissionpercentageOnANN}}"
                                    (keyup)="AssignFinancialProducts($event,item.FinancialProductDetailID)" placeholder="" autocomplete="off" style="text-align:right;"/>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon1">%</span>
                                    </div>
                                  </div>
                                </td>
								                <td>
                                  <div class="input-group value-helper">
                                    <input type="text" class="form-control" id="trailPercentage,{{item.FinancialProductDetailID}}" value="{{item.TrailPercentage}}"
                                    (keyup)="AssignFinancialProducts($event,item.FinancialProductDetailID)" placeholder="" autocomplete="off" style="text-align:right;"/>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon1">%</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div class="input-group value-helper">
                                    <input type="text" class="form-control br" id="trailYears,{{item.FinancialProductDetailID}}" value="{{item.TrailYears}}"
                                    (keyup)="AssignFinancialProducts($event,item.FinancialProductDetailID)" placeholder="" autocomplete="off" style="text-align:right;"/>
                                  </div>
                                </td>
                                <td>
                                  <div class="input-group value-helper">
                                    <input type="text" class="form-control" id="productSalesMix,{{item.FinancialProductDetailID}}" value="{{item.ProductSalesMix}}"
                                    (keyup)="AssignFinancialProducts($event,item.FinancialProductDetailID)" placeholder="" autocomplete="off" style="text-align:right;"/>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon1">%</span>
                                    </div>
                                  </div>
                                </td>
                                <td><button type="button" class="btn btn-primary btn-sm" (click)="DeleteAnnuity(item.FinancialProductDetailID)" style="margin-right: 30px;">Delete</button>
                                </td>
                              </tr>                    
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                </form>
              </div>
              
            </div>
            <div class="form-group   mt-2 mb-0">
              <button class="btn  float-right common-btn ghoust-btn" (click)="next()"> Continue <i class="fas fa-arrow-right pl-2"></i></button>
              <!--a class="btn btn-primary float-right" href="index.html">Login</a-->
            </div>            
          </div>
          
          <ng-template #templatefillacross> 
              <div class="modal-header">
                <h4 class="modal-title pull-left">Fill Across</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form [formGroup]="fillAcrossForm">
                  <div class="form-group row">
                    <label for="inputPassword" class="col-sm-2 col-form-label">Value</label>
                    <div class="col-sm-3">
                      <input type="text" class="form-control" id="fillAcrossValue"  
                      [(ngModel)]="fillAcrossValue" formControlName="fillAcrossValue" (keyup)="CommaFormatted($event)"  >
                    </div>
                  </div>
                  <div class="form-group row">
                  
                    <div class="col-md-3  ml-auto">
                      <button type="submit" class="btn btn-primary  mb-2 w-100"  (click)="FillAcross()">Fill</button>
                    </div>
                  </div>
                </form>
              </div> 
          </ng-template>

          <ng-template #templatefillacrossallyears> 
            <div class="modal-header">
              <h4 class="modal-title pull-left">Fill Across All Years</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="fillAcrossForm">
                <div class="form-group row">
                  <label for="inputPassword" class="col-sm-2 col-form-label">Value</label>
                  <div class="col-sm-3">
                    <input type="text" class="form-control" id="fillAcrossValue"  
                    [(ngModel)]="fillAcrossValue" formControlName="fillAcrossValue" (keyup)="CommaFormatted($event)"  >
                  </div>
                </div>
                <div class="form-group row">
                
                  <div class="col-md-3  ml-auto">
                    <button type="submit" class="btn btn-primary  mb-2 w-100"  (click)="FillAcrossAllYears()">Fill</button>
                  </div>
                </div>
              </form>
            </div> 
        </ng-template>

          <form [formGroup]="repdataForm">
          <div id="wizard-rd" class="content ">
          <div class="card widget mb-3 mt-3">
           
              <div class="card-body p-5 ">
                <!--<div class="row">
                  <div class="col-md-4 p-0">
                    <div class="form-group">
                      <label for="">Agent</label>
                      <select class="form-control" >
                        <option>Receptionist</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="mt-4 p-3">
                        <input type="checkbox" value="">
                        owner
                      </label>
                    </div>
                  </div>
                </div>-->
                <div class="row  p-0 mb-3">
                  <div class="col-md-12 pl-0 pr-0">
                    <!-- <button type="button" class="btn btn-primary m-0 mr-2" (click)="AddRep(true)">Add 1st Rep </button> -->
                    <button type="button" class="btn btn-primary m-0 mr-2" (click)="AddRep(false)">Add Rep </button>
                  </div>
                  <div *ngIf= "bHaveReps" class="right-align">
                    <button class="btn btn-primary m-0 mr-2" type="button" tooltip="You must click “Calculate” in order to generate results" (click)="CalculateRepDataSubTotals()"><i class="fal fa-calculator-alt pr-1"></i> Calculate </button>
                    <button class="btn btn-primary m-0 mr-2" type="button" tooltip="Click here to delete selected rep" (click)="DeleteSelectedRep()"><i class="far fa-trash-alt pr-1"></i> Delete Selected Rep </button>                  
                 
                  <!--   <button class="btn btn-link green-link   mr-5" type="button" tooltip="Fill all future years with this years data" (click)="FillAllWithCurrentPageData()"><i class="fas fa-plus-circle"></i> Fill All </button>
                    <button class="btn btn-link green-link   mr-3" type="button" tooltip="You must click “Calculate” in order to generate results" (click)="CalculateRepDataSubTotals()"><i class="fal fa-calculator-alt pr-1"></i> Calculate </button>
                    <button class="btn btn-link green-link   mr-3" type="button" tooltip="Click here to delete selected rep" (click)="DeleteSelectedRep()"><i class="far fa-trash-alt pr-1"></i> Delete Selected Rep </button>                  
                 -->
                  </div>
                </div>
                <div class="row mb-6">
                  <ul class="nav nav-pills border-tab">
                    <li *ngFor="let rep of repdatalist, let i=index" class="nav-item">
                      <a  [ngClass]="(rep.AgentID==selectedRepID)?'nav-link active':'nav-link'"  (click)="ChangeRep(rep.AgentID)">Rep {{i+1}}</a>
                    </li>
                  </ul>  
                </div>

                <div class="form-row pt-1 pb-4">
                  <label class="pl-1 pr-3 pt-3" for="">Marketing Channel
                    <!-- <i class="fas fa-question-circle ml-2 text-muted" tooltip=""></i> -->
                  </label>
                  <div class="col-md-3 pl-3 pr-0 pt-2">
                    <select class="form-control"
                      (change)=setMarketingChannel($event) [(ngModel)]="marketingChannel" formControlName="marketingChannel"> 
                      <option *ngFor="let marketingchannelitem of marketingchannelsArray" 
                        [value]="marketingchannelitem.id">
                        {{marketingchannelitem.text}}
                      </option>
                    </select>
                  </div>
                </div>
                <div *ngIf="!bOwner && workshops"  >
                    <div class="form-row pt-4 pb-4">
                      <div class="col-md-4 mb-3">
                        <label for="repfirstname">Rep First Name<i class="fas fa-question-circle ml-2 text-muted" tooltip="Enter the first name of the sales rep "></i></label>
                        <input type="text" class="form-control" id="repFirstName"  
                              formControlName="repFirstName" [(ngModel)]="repFirstName"  (change)="AssignRep2Info()" >
                      </div>
                      <div class="col-md-4 mb-3">
                        <label for="replastname">Rep Last Name<i class="fas fa-question-circle ml-2 text-muted" tooltip="Enter the last name of the sales rep "></i></label>
                        <input type="text" class="form-control" id="repLastName"  
                              formControlName="repLastName" [(ngModel)]="repLastName"  (change)="AssignRep2Info()" >
                      </div>
                      <div class="col-md-4 mb-3">
                        <label for="basesalary">Base Salary<i class="fas fa-question-circle ml-2 text-muted" tooltip="Annual gross salary for employee "></i></label>
                        <div class="input-group value-helper">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">$</span>
                          </div>
                          <input type="text" class="form-control br" placeholder="" 
                          formControlName="baseSalary" [(ngModel)]="baseSalary" id="baseSalary"
                          (keyup)="CommaFormatted($event)" autocomplete="off"
                          (change)="AssignRep2Info()" aria-describedby="basic-addon1"> 
                        </div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <label for="repstartdate">Start Date<i class="fas fa-question-circle ml-2 text-muted" tooltip="Date of employment "></i></label>
                        <div class="input-group">
                          <div class="input-group">
                            <input type="text" class="form-control" bsDatepicker   (bsValueChange)="AssignRepStartDate($event)"
                            [bsValue]= "repStartDate"  
                            [bsConfig]="{ containerClass: 'theme-default' }"
                            id="repstartdate">
                          </div>
                        </div>  
                      </div>
                      <div class="col-md-4 mb-3">
                        <label for="rependdate">End Date<i class="fas fa-question-circle ml-2 text-muted" tooltip="Date of termination "></i></label>

                        <div class="input-group">
                          <div class="input-group">
                            <input type="text" class="form-control" bsDatepicker  (bsValueChange)="AssignRepEndDate($event)"
                            [bsValue]= "repEndDate"    
                            [bsConfig]="{ containerClass: 'theme-default' }"
                            id="rependdate">
                          </div>
                        </div> 
                        
                        <!--  <div class="input-group">
                            <input type="text" class="form-control bold" bsDatepicker (bsValueChange)="onClientDOBChange($event)"
                            [bsValue]= "client.ClientDOB"    value="{{ client.ClientDOB | date:'MM/dd/yyyy' }}"
                            [bsConfig]="{ containerClass: 'theme-default' }"
                            id="clientDOB">
                          </div> -->                      
                        
  
                      </div>
                      <div class="col-md-4 mb-3">
                        <!-- <label for="validationCustom01">First name</label>
                        <input type="text" class="form-control" id="validationCustom01" placeholder="First name" value="Mark"  >  -->
                        <label  for="">Rep Pay Percentage<i class="fas fa-question-circle ml-2 text-muted" tooltip="Rep share of commissions earned "></i></label>                      
                        <div class="input-group value-helper">
                          <input type="text" class="form-control" placeholder="" 
                          formControlName="repPayPercentage" [(ngModel)]="repPayPercentage" id="repPayPercentage" (change)="AssignRep2Info()"
                          (keyup)="CommaFormatted($event)" aria-describedby="basic-addon1" style="text-align:right;">
                          <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon1">%</span>
                          </div>
                          
                        </div>
                      </div>
                    </div> 
                </div>             
                
                <div *ngIf= "bHaveReps">
                  <div  *ngIf= "workshops" class="row">
                    <div class="right-align mr-4">
                      <button class="btn btn-primary m-0 mr-2" type="button" tooltip="Fill all future years with this years data" (click)="FillAllWithCurrentPageData()"><i class="fas fa-plus-circle"></i> Fill All </button>
                    </div>
                    <tabset class="w-100">
                    <tab   *ngFor="let year of yeardatalist" heading="{{ year.Year }}" [active]="(year.Year == selectedYear)"     (selectTab)="ChangeYear(year.Year)" id="{{ year.Year }}">
                      <div class="table-responsive">
                        <table class="table rep-tabel">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Jan</th>
                              <th scope="col">Feb</th>
                              <th scope="col">Mar</th>
                              <th scope="col">Apr</th>
                              <th scope="col">May</th>
                              <th scope="col">Jun</th>
                              <th scope="col">Jul</th>
                              <th scope="col">Aug</th>
                              <th scope="col">Sep</th>
                              <th scope="col">Oct</th>
                              <th scope="col">Nov</th>
                              <th scope="col">Dec</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Number of events in given month ">Classes Per Month</span>                                
                                  <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'classPerMonth')">Fill across value</button></li> 
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacrossallyears,'classPerMonth')">Fill across all years</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('classPerMonth')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcross('classPerMonth')">Clear across values</a></li>  
                                    </ul>
                                  </div>
                                </div>
                              </th>

                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="classPerMonthJan" formControlName="classPerMonthJan" id="classPerMonthJan" (keyup)="CommaFormatted($event)" (change)="AssignClassesPerMonth(1)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="classPerMonthFeb" formControlName="classPerMonthFeb" id="classPerMonthFeb" (keyup)="CommaFormatted($event)" (change)="AssignClassesPerMonth(2)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="classPerMonthMar" formControlName="classPerMonthMar" id="classPerMonthMar" (keyup)="CommaFormatted($event)" (change)="AssignClassesPerMonth(3)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="classPerMonthApr" formControlName="classPerMonthApr" id="classPerMonthApr" (keyup)="CommaFormatted($event)" (change)="AssignClassesPerMonth(4)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="classPerMonthMay" formControlName="classPerMonthMay" id="classPerMonthMay" (keyup)="CommaFormatted($event)" (change)="AssignClassesPerMonth(5)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="classPerMonthJun" formControlName="classPerMonthJun" id="classPerMonthJun" (keyup)="CommaFormatted($event)" (change)="AssignClassesPerMonth(6)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="classPerMonthJul" formControlName="classPerMonthJul" id="classPerMonthJul" (keyup)="CommaFormatted($event)" (change)="AssignClassesPerMonth(7)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="classPerMonthAug" formControlName="classPerMonthAug" id="classPerMonthAug" (keyup)="CommaFormatted($event)" (change)="AssignClassesPerMonth(8)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="classPerMonthSep" formControlName="classPerMonthSep" id="classPerMonthSep" (keyup)="CommaFormatted($event)" (change)="AssignClassesPerMonth(9)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="classPerMonthOct" formControlName="classPerMonthOct" id="classPerMonthOct" (keyup)="CommaFormatted($event)" (change)="AssignClassesPerMonth(10)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="classPerMonthNov" formControlName="classPerMonthNov" id="classPerMonthNov" (keyup)="CommaFormatted($event)" (change)="AssignClassesPerMonth(11)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="classPerMonthDec" formControlName="classPerMonthDec" id="classPerMonthDec" (keyup)="CommaFormatted($event)" (change)="AssignClassesPerMonth(12)" /></td>
                            </tr>
                            <tr class="default-value">
                              <th scope="row"><span tooltip="One half of the total number of events ">Mail Drop per month </span></th>                            
                              <td class="text-right"><span style="margin-right: 12px;">{{mailDropPerMonthJan}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{mailDropPerMonthFeb}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{mailDropPerMonthMar}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{mailDropPerMonthApr}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{mailDropPerMonthMay}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{mailDropPerMonthJun}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{mailDropPerMonthJul}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{mailDropPerMonthAug}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{mailDropPerMonthSep}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{mailDropPerMonthOct}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{mailDropPerMonthNov}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{mailDropPerMonthDec}}</span></td> 
                            </tr>
                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Mailing cost assumed to cover 2 events ">Cost per mailer ($)</span>                               
                                  <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'costPerMailer')">Fill across value</button></li> 
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacrossallyears,'costPerMailer')">Fill across all years</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('costPerMailer')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcross('costPerMailer')">Clear across values</a></li>  
                                    </ul>
                                  </div>
                                </div>
                              </th>

                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerMailerJan" formControlName="costPerMailerJan" id="costPerMailerJan" (keyup)="CommaFormatted($event)" (change)="AssignCostPerMailer(1)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerMailerFeb" formControlName="costPerMailerFeb" id="costPerMailerFeb" (keyup)="CommaFormatted($event)" (change)="AssignCostPerMailer(2)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerMailerMar" formControlName="costPerMailerMar" id="costPerMailerMar" (keyup)="CommaFormatted($event)" (change)="AssignCostPerMailer(3)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerMailerApr" formControlName="costPerMailerApr" id="costPerMailerApr" (keyup)="CommaFormatted($event)" (change)="AssignCostPerMailer(4)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerMailerMay" formControlName="costPerMailerMay" id="costPerMailerMay" (keyup)="CommaFormatted($event)" (change)="AssignCostPerMailer(5)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerMailerJun" formControlName="costPerMailerJun" id="costPerMailerJun" (keyup)="CommaFormatted($event)" (change)="AssignCostPerMailer(6)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerMailerJul" formControlName="costPerMailerJul" id="costPerMailerJul" (keyup)="CommaFormatted($event)" (change)="AssignCostPerMailer(7)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerMailerAug" formControlName="costPerMailerAug" id="costPerMailerAug" (keyup)="CommaFormatted($event)" (change)="AssignCostPerMailer(8)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerMailerSep" formControlName="costPerMailerSep" id="costPerMailerSep" (keyup)="CommaFormatted($event)" (change)="AssignCostPerMailer(9)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerMailerOct" formControlName="costPerMailerOct" id="costPerMailerOct" (keyup)="CommaFormatted($event)" (change)="AssignCostPerMailer(10)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerMailerNov" formControlName="costPerMailerNov" id="costPerMailerNov" (keyup)="CommaFormatted($event)" (change)="AssignCostPerMailer(11)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerMailerDec" formControlName="costPerMailerDec" id="costPerMailerDec" (keyup)="CommaFormatted($event)" (change)="AssignCostPerMailer(12)" /></td>
                            </tr>
                            <tr>
                              
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Room Rental and Materials Costs ">Cost per event ($)</span>                          
                                  <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'costPerEvent')">Fill across value</button></li> 
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacrossallyears,'costPerEvent')">Fill across all years</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('costPerEvent')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcross('costPerEvent')">Clear across values</a></li>  
                                    </ul>
                                  </div>
                                </div>
                              </th>

                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerEventJan" formControlName="costPerEventJan" id="costPerEventJan" (keyup)="CommaFormatted($event)" (change)="AssignCostPerEvent(1)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerEventFeb" formControlName="costPerEventFeb" id="costPerEventFeb" (keyup)="CommaFormatted($event)" (change)="AssignCostPerEvent(2)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerEventMar" formControlName="costPerEventMar" id="costPerEventMar" (keyup)="CommaFormatted($event)" (change)="AssignCostPerEvent(3)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerEventApr" formControlName="costPerEventApr" id="costPerEventApr" (keyup)="CommaFormatted($event)" (change)="AssignCostPerEvent(4)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerEventMay" formControlName="costPerEventMay" id="costPerEventMay" (keyup)="CommaFormatted($event)" (change)="AssignCostPerEvent(5)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerEventJun" formControlName="costPerEventJun" id="costPerEventJun" (keyup)="CommaFormatted($event)" (change)="AssignCostPerEvent(6)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerEventJul" formControlName="costPerEventJul" id="costPerEventJul" (keyup)="CommaFormatted($event)" (change)="AssignCostPerEvent(7)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerEventAug" formControlName="costPerEventAug" id="costPerEventAug" (keyup)="CommaFormatted($event)" (change)="AssignCostPerEvent(8)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerEventSep" formControlName="costPerEventSep" id="costPerEventSep" (keyup)="CommaFormatted($event)" (change)="AssignCostPerEvent(9)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerEventOct" formControlName="costPerEventOct" id="costPerEventOct" (keyup)="CommaFormatted($event)" (change)="AssignCostPerEvent(10)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerEventNov" formControlName="costPerEventNov" id="costPerEventNov" (keyup)="CommaFormatted($event)" (change)="AssignCostPerEvent(11)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerEventDec" formControlName="costPerEventDec" id="costPerEventDec" (keyup)="CommaFormatted($event)" (change)="AssignCostPerEvent(12)" /></td>
                            </tr>
                          
                            <tr class="default-value bordered">
                              <th scope="row"><span tooltip="Sum of Room Rental, Materials Costs, and Mailers in a given month ">Total Cost per month ($)</span></th>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalCostPerMonthJan}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalCostPerMonthFeb}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalCostPerMonthMar}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalCostPerMonthApr}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalCostPerMonthMay}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalCostPerMonthJun}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalCostPerMonthJul}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalCostPerMonthAug}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalCostPerMonthSep}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalCostPerMonthOct}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalCostPerMonthNov}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalCostPerMonthDec}}</span></td> 
                            </tr>
                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="BU is a Buying Unit – One individual client or one couple ">Total BU Registered/class</span>                        
                                  <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'totalBURegisteredPerClass')">Fill across value</button></li> 
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacrossallyears,'totalBURegisteredPerClass')">Fill across all years</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('totalBURegisteredPerClass')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcross('totalBURegisteredPerClass')">Clear across values</a></li>  
                                    </ul>
                                  </div>
                                </div>
                              </th>

                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalBURegisteredPerClassJan" formControlName="totalBURegisteredPerClassJan" id="totalBURegisteredPerClassJan" (keyup)="CommaFormatted($event)" (change)="AssignTotalBURegisteredPerClass(1)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalBURegisteredPerClassFeb" formControlName="totalBURegisteredPerClassFeb" id="totalBURegisteredPerClassFeb" (keyup)="CommaFormatted($event)" (change)="AssignTotalBURegisteredPerClass(2)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalBURegisteredPerClassMar" formControlName="totalBURegisteredPerClassMar" id="totalBURegisteredPerClassMar" (keyup)="CommaFormatted($event)" (change)="AssignTotalBURegisteredPerClass(3)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalBURegisteredPerClassApr" formControlName="totalBURegisteredPerClassApr" id="totalBURegisteredPerClassApr" (keyup)="CommaFormatted($event)" (change)="AssignTotalBURegisteredPerClass(4)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalBURegisteredPerClassMay" formControlName="totalBURegisteredPerClassMay" id="totalBURegisteredPerClassMay" (keyup)="CommaFormatted($event)" (change)="AssignTotalBURegisteredPerClass(5)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalBURegisteredPerClassJun" formControlName="totalBURegisteredPerClassJun" id="totalBURegisteredPerClassJun" (keyup)="CommaFormatted($event)" (change)="AssignTotalBURegisteredPerClass(6)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalBURegisteredPerClassJul" formControlName="totalBURegisteredPerClassJul" id="totalBURegisteredPerClassJul" (keyup)="CommaFormatted($event)" (change)="AssignTotalBURegisteredPerClass(7)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalBURegisteredPerClassAug" formControlName="totalBURegisteredPerClassAug" id="totalBURegisteredPerClassAug" (keyup)="CommaFormatted($event)" (change)="AssignTotalBURegisteredPerClass(8)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalBURegisteredPerClassSep" formControlName="totalBURegisteredPerClassSep" id="totalBURegisteredPerClassSep" (keyup)="CommaFormatted($event)" (change)="AssignTotalBURegisteredPerClass(9)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalBURegisteredPerClassOct" formControlName="totalBURegisteredPerClassOct" id="totalBURegisteredPerClassOct" (keyup)="CommaFormatted($event)" (change)="AssignTotalBURegisteredPerClass(10)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalBURegisteredPerClassNov" formControlName="totalBURegisteredPerClassNov" id="totalBURegisteredPerClassNov" (keyup)="CommaFormatted($event)" (change)="AssignTotalBURegisteredPerClass(11)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalBURegisteredPerClassDec" formControlName="totalBURegisteredPerClassDec" id="totalBURegisteredPerClassDec" (keyup)="CommaFormatted($event)" (change)="AssignTotalBURegisteredPerClass(12)" /></td>
                            </tr>
                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="% rate expected to confirm but not attend. We expect a 25-30% cancel rate on average ">Cancel / Non-attendance rate</span>                      
                                  <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'cancelRate')">Fill across value</button></li> 
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacrossallyears,'cancelRate')">Fill across all years</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('cancelRate')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcross('cancelRate')">Clear across values</a></li>  
                                    </ul>
                                  </div>
                                </div>
                              </th>

                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="cancelRateJan" formControlName="cancelRateJan" id="cancelRateJan" (keyup)="CommaFormatted($event)" (change)="AssignCancelRate(1)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="cancelRateFeb" formControlName="cancelRateFeb" id="cancelRateFeb" (keyup)="CommaFormatted($event)" (change)="AssignCancelRate(2)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="cancelRateMar" formControlName="cancelRateMar" id="cancelRateMar" (keyup)="CommaFormatted($event)" (change)="AssignCancelRate(3)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="cancelRateApr" formControlName="cancelRateApr" id="cancelRateApr" (keyup)="CommaFormatted($event)" (change)="AssignCancelRate(4)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="cancelRateMay" formControlName="cancelRateMay" id="cancelRateMay" (keyup)="CommaFormatted($event)" (change)="AssignCancelRate(5)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="cancelRateJun" formControlName="cancelRateJun" id="cancelRateJun" (keyup)="CommaFormatted($event)" (change)="AssignCancelRate(6)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="cancelRateJul" formControlName="cancelRateJul" id="cancelRateJul" (keyup)="CommaFormatted($event)" (change)="AssignCancelRate(7)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="cancelRateAug" formControlName="cancelRateAug" id="cancelRateAug" (keyup)="CommaFormatted($event)" (change)="AssignCancelRate(8)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="cancelRateSep" formControlName="cancelRateSep" id="cancelRateSep" (keyup)="CommaFormatted($event)" (change)="AssignCancelRate(9)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="cancelRateOct" formControlName="cancelRateOct" id="cancelRateOct" (keyup)="CommaFormatted($event)" (change)="AssignCancelRate(10)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="cancelRateNov" formControlName="cancelRateNov" id="cancelRateNov" (keyup)="CommaFormatted($event)" (change)="AssignCancelRate(11)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="cancelRateDec" formControlName="cancelRateDec" id="cancelRateDec" (keyup)="CommaFormatted($event)" (change)="AssignCancelRate(12)" /></td>
                            </tr>
                            
                            <tr class="default-value">
                              <th scope="row"><span tooltip="Number of buying units who attend workshop ">Total Attendees</span></th>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalAttendeesJan}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalAttendeesFeb}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalAttendeesMar}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalAttendeesApr}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalAttendeesMay}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalAttendeesJun}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalAttendeesJul}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalAttendeesAug}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalAttendeesSep}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalAttendeesOct}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalAttendeesNov}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalAttendeesDec}}</span></td> 
                            </tr>
                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="% of attendees who will book a first appointment. We expect 50-75% booking rate on average ">Booking rate (%)</span>                  
                                  <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'bookingRate')">Fill across value</button></li> 
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacrossallyears,'bookingRate')">Fill across all years</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('bookingRate')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcross('bookingRate')">Clear across values</a></li>  
                                    </ul>
                                  </div>
                                </div>
                              </th>

                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="bookingRateJan" formControlName="bookingRateJan" id="bookingRateJan" (keyup)="CommaFormatted($event)" (change)="AssignBookingRate(1)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="bookingRateFeb" formControlName="bookingRateFeb" id="bookingRateFeb" (keyup)="CommaFormatted($event)" (change)="AssignBookingRate(2)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="bookingRateMar" formControlName="bookingRateMar" id="bookingRateMar" (keyup)="CommaFormatted($event)" (change)="AssignBookingRate(3)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="bookingRateApr" formControlName="bookingRateApr" id="bookingRateApr" (keyup)="CommaFormatted($event)" (change)="AssignBookingRate(4)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="bookingRateMay" formControlName="bookingRateMay" id="bookingRateMay" (keyup)="CommaFormatted($event)" (change)="AssignBookingRate(5)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="bookingRateJun" formControlName="bookingRateJun" id="bookingRateJun" (keyup)="CommaFormatted($event)" (change)="AssignBookingRate(6)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="bookingRateJul" formControlName="bookingRateJul" id="bookingRateJul" (keyup)="CommaFormatted($event)" (change)="AssignBookingRate(7)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="bookingRateAug" formControlName="bookingRateAug" id="bookingRateAug" (keyup)="CommaFormatted($event)" (change)="AssignBookingRate(8)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="bookingRateSep" formControlName="bookingRateSep" id="bookingRateSep" (keyup)="CommaFormatted($event)" (change)="AssignBookingRate(9)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="bookingRateOct" formControlName="bookingRateOct" id="bookingRateOct" (keyup)="CommaFormatted($event)" (change)="AssignBookingRate(10)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="bookingRateNov" formControlName="bookingRateNov" id="bookingRateNov" (keyup)="CommaFormatted($event)" (change)="AssignBookingRate(11)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="bookingRateDec" formControlName="bookingRateDec" id="bookingRateDec" (keyup)="CommaFormatted($event)" (change)="AssignBookingRate(12)" /></td>
                            </tr>
                            
                            <tr class="default-value">
                              <th scope="row"><span tooltip="Based on # of attendees and % who book 1st appt ">Number of first appointments</span></th>
                              <td class="text-right"><span style="margin-right: 12px;">{{numberOfFirstApptsJan}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{numberOfFirstApptsFeb}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{numberOfFirstApptsMar}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{numberOfFirstApptsApr}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{numberOfFirstApptsMay}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{numberOfFirstApptsJun}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{numberOfFirstApptsJul}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{numberOfFirstApptsAug}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{numberOfFirstApptsSep}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{numberOfFirstApptsOct}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{numberOfFirstApptsNov}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{numberOfFirstApptsDec}}</span></td> 
                            </tr>
                            
                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Percentage of 1st appointments who become clients. We expect 40-60% close rate on average ">Close rate </span>               
                                  <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'closeRate')">Fill across value</button></li> 
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacrossallyears,'closeRate')">Fill across all years</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('closeRate')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcross('closeRate')">Clear across values</a></li>  
                                    </ul>
                                  </div>
                                </div>
                              </th>

                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="closeRateJan" formControlName="closeRateJan" id="closeRateJan" (keyup)="CommaFormatted($event)" (change)="AssignCloseRate(1)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="closeRateFeb" formControlName="closeRateFeb" id="closeRateFeb" (keyup)="CommaFormatted($event)" (change)="AssignCloseRate(2)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="closeRateMar" formControlName="closeRateMar" id="closeRateMar" (keyup)="CommaFormatted($event)" (change)="AssignCloseRate(3)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="closeRateApr" formControlName="closeRateApr" id="closeRateApr" (keyup)="CommaFormatted($event)" (change)="AssignCloseRate(4)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="closeRateMay" formControlName="closeRateMay" id="closeRateMay" (keyup)="CommaFormatted($event)" (change)="AssignCloseRate(5)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="closeRateJun" formControlName="closeRateJun" id="closeRateJun" (keyup)="CommaFormatted($event)" (change)="AssignCloseRate(6)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="closeRateJul" formControlName="closeRateJul" id="closeRateJul" (keyup)="CommaFormatted($event)" (change)="AssignCloseRate(7)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="closeRateAug" formControlName="closeRateAug" id="closeRateAug" (keyup)="CommaFormatted($event)" (change)="AssignCloseRate(8)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="closeRateSep" formControlName="closeRateSep" id="closeRateSep" (keyup)="CommaFormatted($event)" (change)="AssignCloseRate(9)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="closeRateOct" formControlName="closeRateOct" id="closeRateOct" (keyup)="CommaFormatted($event)" (change)="AssignCloseRate(10)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="closeRateNov" formControlName="closeRateNov" id="closeRateNov" (keyup)="CommaFormatted($event)" (change)="AssignCloseRate(11)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="closeRateDec" formControlName="closeRateDec" id="closeRateDec" (keyup)="CommaFormatted($event)" (change)="AssignCloseRate(12)" /></td>
                            </tr>
                            
                            <tr class="default-value bordered">
                              <th scope="row"><span tooltip="Close rate x # of 1st appts ">1st appointments converting to close </span></th>
                              <td class="text-right"><span style="margin-right: 12px;">{{firstApptsConvertingToCloseJan}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{firstApptsConvertingToCloseFeb}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{firstApptsConvertingToCloseMar}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{firstApptsConvertingToCloseApr}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{firstApptsConvertingToCloseMay}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{firstApptsConvertingToCloseJun}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{firstApptsConvertingToCloseJul}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{firstApptsConvertingToCloseAug}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{firstApptsConvertingToCloseSep}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{firstApptsConvertingToCloseOct}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{firstApptsConvertingToCloseNov}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{firstApptsConvertingToCloseDec}}</span></td> 
                            </tr>
                            
                            <tr class="default-value bordered">
                              <th scope="row"><span tooltip="Total number of client closes in a given month ">Closes per month</span></th>
                              <td class="text-right"><span style="margin-right: 12px;">{{closesPerMonthJan}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{closesPerMonthFeb}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{closesPerMonthMar}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{closesPerMonthApr}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{closesPerMonthMay}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{closesPerMonthJun}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{closesPerMonthJul}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{closesPerMonthAug}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{closesPerMonthSep}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{closesPerMonthOct}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{closesPerMonthNov}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{closesPerMonthDec}}</span></td> 
                            </tr>

                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Total value of a client’s assets at close ">Total assets per close ($)</span>            
                                  <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'totalAssetsPerClose')">Fill across value</button></li> 
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacrossallyears,'totalAssetsPerClose')">Fill across all years</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('totalAssetsPerClose')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcross('totalAssetsPerClose')">Clear across values</a></li>  
                                    </ul>
                                  </div>
                                </div>
                              </th>

                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalAssetsPerCloseJan" formControlName="totalAssetsPerCloseJan" id="totalAssetsPerCloseJan" (keyup)="CommaFormatted($event)" (change)="AssignTotalAssetsPerClose(1)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalAssetsPerCloseFeb" formControlName="totalAssetsPerCloseFeb" id="totalAssetsPerCloseFeb" (keyup)="CommaFormatted($event)" (change)="AssignTotalAssetsPerClose(2)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalAssetsPerCloseMar" formControlName="totalAssetsPerCloseMar" id="totalAssetsPerCloseMar" (keyup)="CommaFormatted($event)" (change)="AssignTotalAssetsPerClose(3)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalAssetsPerCloseApr" formControlName="totalAssetsPerCloseApr" id="totalAssetsPerCloseApr" (keyup)="CommaFormatted($event)" (change)="AssignTotalAssetsPerClose(4)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalAssetsPerCloseMay" formControlName="totalAssetsPerCloseMay" id="totalAssetsPerCloseMay" (keyup)="CommaFormatted($event)" (change)="AssignTotalAssetsPerClose(5)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalAssetsPerCloseJun" formControlName="totalAssetsPerCloseJun" id="totalAssetsPerCloseJun" (keyup)="CommaFormatted($event)" (change)="AssignTotalAssetsPerClose(6)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalAssetsPerCloseJul" formControlName="totalAssetsPerCloseJul" id="totalAssetsPerCloseJul" (keyup)="CommaFormatted($event)" (change)="AssignTotalAssetsPerClose(7)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalAssetsPerCloseAug" formControlName="totalAssetsPerCloseAug" id="totalAssetsPerCloseAug" (keyup)="CommaFormatted($event)" (change)="AssignTotalAssetsPerClose(8)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalAssetsPerCloseSep" formControlName="totalAssetsPerCloseSep" id="totalAssetsPerCloseSep" (keyup)="CommaFormatted($event)" (change)="AssignTotalAssetsPerClose(9)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalAssetsPerCloseOct" formControlName="totalAssetsPerCloseOct" id="totalAssetsPerCloseOct" (keyup)="CommaFormatted($event)" (change)="AssignTotalAssetsPerClose(10)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalAssetsPerCloseNov" formControlName="totalAssetsPerCloseNov" id="totalAssetsPerCloseNov" (keyup)="CommaFormatted($event)" (change)="AssignTotalAssetsPerClose(11)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalAssetsPerCloseDec" formControlName="totalAssetsPerCloseDec" id="totalAssetsPerCloseDec" (keyup)="CommaFormatted($event)" (change)="AssignTotalAssetsPerClose(12)" /></td>
                          </tr>

                            <tr class="bordered"> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Percentage of assets that client agrees to put into annuities ">Percent of assets to Annuity (%)</span>    
                                  <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'percentOfAssetsToANN')">Fill across value</button></li> 
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacrossallyears,'percentOfAssetsToANN')">Fill across all years</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('percentOfAssetsToANN')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcross('percentOfAssetsToANN')">Clear across values</a></li>  
                                    </ul>
                                  </div>
                                </div>
                              </th>

                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="percentOfAssetsToANNJan" formControlName="percentOfAssetsToANNJan" id="percentOfAssetsToANNJan" (keyup)="CommaFormatted($event)" (change)="AssignPercentOfAssetsToANN(1)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="percentOfAssetsToANNFeb" formControlName="percentOfAssetsToANNFeb" id="percentOfAssetsToANNFeb" (keyup)="CommaFormatted($event)" (change)="AssignPercentOfAssetsToANN(2)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="percentOfAssetsToANNMar" formControlName="percentOfAssetsToANNMar" id="percentOfAssetsToANNMar" (keyup)="CommaFormatted($event)" (change)="AssignPercentOfAssetsToANN(3)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="percentOfAssetsToANNApr" formControlName="percentOfAssetsToANNApr" id="percentOfAssetsToANNApr" (keyup)="CommaFormatted($event)" (change)="AssignPercentOfAssetsToANN(4)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="percentOfAssetsToANNMay" formControlName="percentOfAssetsToANNMay" id="percentOfAssetsToANNMay" (keyup)="CommaFormatted($event)" (change)="AssignPercentOfAssetsToANN(5)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="percentOfAssetsToANNJun" formControlName="percentOfAssetsToANNJun" id="percentOfAssetsToANNJun" (keyup)="CommaFormatted($event)" (change)="AssignPercentOfAssetsToANN(6)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="percentOfAssetsToANNJul" formControlName="percentOfAssetsToANNJul" id="percentOfAssetsToANNJul" (keyup)="CommaFormatted($event)" (change)="AssignPercentOfAssetsToANN(7)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="percentOfAssetsToANNAug" formControlName="percentOfAssetsToANNAug" id="percentOfAssetsToANNAug" (keyup)="CommaFormatted($event)" (change)="AssignPercentOfAssetsToANN(8)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="percentOfAssetsToANNSep" formControlName="percentOfAssetsToANNSep" id="percentOfAssetsToANNSep" (keyup)="CommaFormatted($event)" (change)="AssignPercentOfAssetsToANN(9)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="percentOfAssetsToANNOct" formControlName="percentOfAssetsToANNOct" id="percentOfAssetsToANNOct" (keyup)="CommaFormatted($event)" (change)="AssignPercentOfAssetsToANN(10)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="percentOfAssetsToANNNov" formControlName="percentOfAssetsToANNNov" id="percentOfAssetsToANNNov" (keyup)="CommaFormatted($event)" (change)="AssignPercentOfAssetsToANN(11)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="percentOfAssetsToANNDec" formControlName="percentOfAssetsToANNDec" id="percentOfAssetsToANNDec" (keyup)="CommaFormatted($event)" (change)="AssignPercentOfAssetsToANN(12)" /></td>
                          </tr>
                            
                            <tr class="default-value">
                              <th scope="row"><span tooltip="Average assets under management per new client ">AUM per close ($)</span></th>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMPerCloseJan}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMPerCloseFeb}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMPerCloseMar}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMPerCloseApr}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMPerCloseMay}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMPerCloseJun}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMPerCloseJul}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMPerCloseAug}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMPerCloseSep}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMPerCloseOct}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMPerCloseNov}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMPerCloseDec}}</span></td> 
                            </tr>

                            
                            <tr class="default-value bordered">
                              <th scope="row"><span tooltip="Average Annuity Premium amount per new client ">ANN per close ($)</span></th>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNPerCloseJan}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNPerCloseFeb}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNPerCloseMar}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNPerCloseApr}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNPerCloseMay}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNPerCloseJun}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNPerCloseJul}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNPerCloseAug}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNPerCloseSep}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNPerCloseOct}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNPerCloseNov}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNPerCloseDec}}</span></td> 
                            </tr>
                          
                            
                            <tr class="default-value bordered">
                              <th scope="row"><span tooltip="Average monthly fee income per client per month ">Fee per client per month ($)</span></th>
                              <td class="text-right"><span style="margin-right: 12px;">{{feePerClientPerMonthJan}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{feePerClientPerMonthFeb}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{feePerClientPerMonthMar}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{feePerClientPerMonthApr}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{feePerClientPerMonthMay}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{feePerClientPerMonthJun}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{feePerClientPerMonthJul}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{feePerClientPerMonthAug}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{feePerClientPerMonthSep}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{feePerClientPerMonthOct}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{feePerClientPerMonthNov}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{feePerClientPerMonthDec}}</span></td> 
                            </tr>
                            <!-- <tr class="default-value bordered">
                              <th scope="row"><span tooltip="# of years of annuity trail ">Trail years</span></th>
                              <td class="text-right"><span style="margin-right: 12px;">{{trailYearsJan}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{trailYearsFeb}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{trailYearsMar}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{trailYearsApr}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{trailYearsMay}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{trailYearsJun}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{trailYearsJul}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{trailYearsAug}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{trailYearsSep}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{trailYearsOct}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{trailYearsNov}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{trailYearsDec}}</span></td> 
                            </tr> -->

                          <!--  <tr class="default-value-green">
                              <th scope="row"><span tooltip="Total new sale commission per client ">Annuity Commission Revenue Per close ($)</span></th>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommRevPerCloseJan}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommRevPerCloseFeb}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommRevPerCloseMar}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommRevPerCloseApr}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommRevPerCloseMay}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommRevPerCloseJun}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommRevPerCloseJul}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommRevPerCloseAug}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommRevPerCloseSep}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommRevPerCloseOct}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommRevPerCloseNov}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommRevPerCloseDec}}</span></td> 
                            </tr> -->

                            <tr class="default-value-green bordered">
                              <th scope="row"><span tooltip="Total trail commission per year per client ">Annuity Trail Per Year ($)</span></th>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailPerYearJan}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailPerYearFeb}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailPerYearMar}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailPerYearApr}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailPerYearMay}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailPerYearJun}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailPerYearJul}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailPerYearAug}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailPerYearSep}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailPerYearOct}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailPerYearNov}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailPerYearDec}}</span></td> 
                            </tr>

                            <tr class="default-value-green">
                              <th scope="row"><span tooltip="Total AUM revenue generated per client per month ">AUM fee per month ($)</span></th>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMFeePerMonthJan}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMFeePerMonthFeb}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMFeePerMonthMar}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMFeePerMonthApr}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMFeePerMonthMay}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMFeePerMonthJun}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMFeePerMonthJul}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMFeePerMonthAug}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMFeePerMonthSep}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMFeePerMonthOct}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMFeePerMonthNov}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMFeePerMonthDec}}</span></td> 
                            </tr>                          

                            <tr class="default-value-green">
                              <th scope="row"><span tooltip="Total Annuity commission revenue per month ">Annuity comm per month ($)</span></th>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommPerMonthJan}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommPerMonthFeb}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommPerMonthMar}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommPerMonthApr}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommPerMonthMay}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommPerMonthJun}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommPerMonthJul}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommPerMonthAug}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommPerMonthSep}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommPerMonthOct}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommPerMonthNov}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommPerMonthDec}}</span></td> 
                            </tr>

                            <tr class="default-value-green">
                              <th scope="row"><span tooltip="Total Annuity trail commission revenue per month ">Annuity Trail for Future years per month ($)</span></th>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailForFutureYearsPerMonthJan}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailForFutureYearsPerMonthFeb}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailForFutureYearsPerMonthMar}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailForFutureYearsPerMonthApr}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailForFutureYearsPerMonthMay}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailForFutureYearsPerMonthJun}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailForFutureYearsPerMonthJul}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailForFutureYearsPerMonthAug}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailForFutureYearsPerMonthSep}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailForFutureYearsPerMonthOct}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailForFutureYearsPerMonthNov}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailForFutureYearsPerMonthDec}}</span></td> 
                            </tr>

                            
                            
                          </tbody>
                        </table>
                      </div>
                    </tab>   
                    </tabset>
                  </div>
                  <div *ngIf= "!workshops" class="row">
                    <div id="additionalchannelstable" [ngClass]="{'col-md-10': status, 'col-md-8': status==false}">
                    <tabset class="w-100">
                      <div class="table-responsive">
                        <table class="table rep-tabel">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col" class="pl-5">Radio Spots</th>
                              <th scope="col">Radio Shows</th>
                              <th scope="col">TV</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Total $ amount spent on channel per month">Channel Expenditure</span>                                
                                  <!-- <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'channelExpenditure')">Fill across value</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('channelExpenditureAC')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcrossAC('channelExpenditureAC')">Clear across values</a></li>  
                                    </ul>
                                  </div> -->
                                </div>
                              </th>

                              <td class="pl-5"><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="channelExpenditureRadioSpots" formControlName="channelExpenditureRadioSpots" id="channelExpenditureRadioSpots" (keyup)="CommaFormatted($event)" (change)="AssignACChannelExpenditure(radioSpots)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="channelExpenditureRadioShows" formControlName="channelExpenditureRadioShows" id="channelExpenditureRadioShows" (keyup)="CommaFormatted($event)" (change)="AssignACChannelExpenditure(radioShows)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="channelExpenditureTV" formControlName="channelExpenditureTV" id="channelExpenditureTV" (keyup)="CommaFormatted($event)" (change)="AssignACChannelExpenditure(tv)" /></td>
                            </tr>

                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Average $ amount per spot/show/etc">Cost Per spot/show/etc </span>                                
                                  <!-- <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'costPerEventAC')">Fill across value</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('costPerEventAC')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcrossAC('costPerEventAC')">Clear across values</a></li>  
                                    </ul>
                                  </div> -->
                                </div>
                              </th>

                              <td class="pl-5" ><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerEventRadioSpots" formControlName="costPerEventRadioSpots" id="costPerEventRadioSpots" (keyup)="CommaFormatted($event)" (change)="AssignACCostPerEvent(radioSpots)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerEventRadioShows" formControlName="costPerEventRadioShows" id="costPerEventRadioShows" (keyup)="CommaFormatted($event)" (change)="AssignACCostPerEvent(radioShows)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="costPerEventTV" formControlName="costPerEventTV" id="costPerEventTV" (keyup)="CommaFormatted($event)" (change)="AssignACCostPerEvent(tv)" /></td>
                            </tr> 

                            <tr class="default-value">
                              <th scope="row"><span tooltip="Total number of times ads air per month">Totals Spots Per Month </span></th>                            
                              <td class="text-right pl-5"><span style="margin-right: 12px;">{{totalEventsPerMonthRadioSpots}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalEventsPerMonthRadioShows}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalEventsPerMonthTV}}</span></td>
                            </tr>
                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Average number of leads per spot/show/et">Leads per spot/show/etc </span>                               
                                  <!-- <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'leadsPerEventAC')">Fill across value</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('leadsPerEventAC')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcrossAC('leadsPerEventAC')">Clear across values</a></li>  
                                    </ul>
                                  </div> -->
                                </div>
                              </th>

                              <td class="pl-5"><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="leadsPerEventRadioSpots" formControlName="leadsPerEventRadioSpots" id="leadsPerEventRadioSpots" (keyup)="CommaFormatted($event)" (change)="AssignACLeadsPerEvent(radioSpots)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="leadsPerEventRadioShows" formControlName="leadsPerEventRadioShows" id="leadsPerEventRadioShows" (keyup)="CommaFormatted($event)" (change)="AssignACLeadsPerEvent(radioShows)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="leadsPerEventTV" formControlName="leadsPerEventTV" id="leadsPerEventTV" (keyup)="CommaFormatted($event)" (change)="AssignACLeadsPerEvent(tv)" /></td>
                            </tr>
                            <tr>
                              
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="% of leads qualified as prospects">% Leads converted to prospects</span>                          
                                  <!-- <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'leadsConvertedToProspectsAC')">Fill across value</button></li>  
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('leadsConvertedToProspectsAC')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcrossAC('leadsConvertedToProspectsAC')">Clear across values</a></li>  
                                    </ul>
                                  </div> -->
                                </div>
                              </th>

                              <td class="pl-5"><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="leadsConvertedToProspectsRadioSpots" formControlName="leadsConvertedToProspectsRadioSpots" id="leadsConvertedToProspectsRadioSpots" (keyup)="CommaFormatted($event)" (change)="AssignACLeadsConvertedToProspects(radioSpots)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="leadsConvertedToProspectsRadioShows" formControlName="leadsConvertedToProspectsRadioShows" id="leadsConvertedToProspectsRadioShows" (keyup)="CommaFormatted($event)" (change)="AssignACLeadsConvertedToProspects(radioShows)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="leadsConvertedToProspectsTV" formControlName="leadsConvertedToProspectsTV" id="leadsConvertedToProspectsTV" (keyup)="CommaFormatted($event)" (change)="AssignACLeadsConvertedToProspects(tv)" /></td>
                            </tr>
                          
                            <tr class="default-value bordered">
                              <th scope="row"><span tooltip="Average total new leads generated by this channel each month">Total new prospects per month</span></th>
                              <td class="text-right pl-5"><span style="margin-right: 12px;">{{totalNewProspectsPerMonthRadioSpots}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalNewProspectsPerMonthRadioShows}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{totalNewProspectsPerMonthTV}}</span></td> 
                            </tr>
                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Buying units who book EW or workshop">Number of EW/101 Booked </span>                        
                                  <!-- <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'numberOfEW101BookedAC')">Fill across value</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('numberOfEW101BookedAC')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcrossAC('numberOfEW101BookedAC')">Clear across values</a></li>  
                                    </ul>
                                  </div> -->
                                </div>
                              </th>

                              <td class="pl-5"><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="numberOfEW101BookedRadioSpots" formControlName="numberOfEW101BookedRadioSpots" id="numberOfEW101BookedRadioSpots" (keyup)="CommaFormatted($event)" (change)="AssignACNumberOfEW101Booked(radioSpots)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="numberOfEW101BookedRadioShows" formControlName="numberOfEW101BookedRadioShows" id="numberOfEW101BookedRadioShows" (keyup)="CommaFormatted($event)" (change)="AssignACNumberOfEW101Booked(radioShows)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="numberOfEW101BookedTV" formControlName="numberOfEW101BookedTV" id="numberOfEW101BookedTV" (keyup)="CommaFormatted($event)" (change)="AssignACNumberOfEW101Booked(tv)" /></td>
                            </tr>
                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Buying units who attend EW or workshop">Number of EW/101 Attended</span>                      
                                  <!-- <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'numberOfEW101AttendedAC')">Fill across value</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('numberOfEW101AttendedAC')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcrossAC('numberOfEW101AttendedAC')">Clear across values</a></li>  
                                    </ul>
                                  </div> -->
                                </div>
                              </th>

                              <td class="pl-5"><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="numberOfEW101AttendedRadioSpots" formControlName="numberOfEW101AttendedRadioSpots" id="numberOfEW101AttendedRadioSpots" (keyup)="CommaFormatted($event)" (change)="AssignACNumberOfEW101Attended(radioSpots)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="numberOfEW101AttendedRadioShows" formControlName="numberOfEW101AttendedRadioShows" id="numberOfEW101AttendedRadioShows" (keyup)="CommaFormatted($event)" (change)="AssignACNumberOfEW101Attended(radioShows)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="numberOfEW101AttendedTV" formControlName="numberOfEW101AttendedTV" id="numberOfEW101AttendedTV" (keyup)="CommaFormatted($event)" (change)="AssignACNumberOfEW101Attended(tv)" /></td>
                            </tr>

                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Buying units who book 1st appt">1st Appointment Booked </span>                  
                                  <!-- <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'firstAppointmentBookedAC')">Fill across value</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('firstAppointmentBookedAC')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcrossAC('firstAppointmentBookedAC')">Clear across values</a></li>  
                                    </ul>
                                  </div> -->
                                </div>
                              </th>

                              <td class="pl-5"><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="firstAppointmentBookedRadioSpots" formControlName="firstAppointmentBookedRadioSpots" id="firstAppointmentBookedRadioSpots" (keyup)="CommaFormatted($event)" (change)="AssignACFirstAppointmentBooked(radioSpots)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="firstAppointmentBookedRadioShows" formControlName="firstAppointmentBookedRadioShows" id="firstAppointmentBookedRadioShows" (keyup)="CommaFormatted($event)" (change)="AssignACFirstAppointmentBooked(radioShows)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="firstAppointmentBookedTV" formControlName="firstAppointmentBookedTV" id="firstAppointmentBookedTV" (keyup)="CommaFormatted($event)" (change)="AssignACFirstAppointmentBooked(tv)" /></td>
                            </tr>
                            
                      
                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Buying units who attend 1st appt">1st Appointment Attended </span>                  
                                  <!-- <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'firstAppointmentAttendedAC')">Fill across value</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('firstAppointmentAttendedAC')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcrossAC('firstAppointmentAttendedAC')">Clear across values</a></li>  
                                    </ul>
                                  </div> -->
                                </div>
                              </th>
  
                              <td class="pl-5"><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="firstAppointmentAttendedRadioSpots" formControlName="firstAppointmentAttendedRadioSpots" id="firstAppointmentAttendedRadioSpots" (keyup)="CommaFormatted($event)" (change)="AssignACFirstAppointmentAttended(radioSpots)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="firstAppointmentAttendedRadioShows" formControlName="firstAppointmentAttendedRadioShows" id="firstAppointmentAttendedRadioShows" (keyup)="CommaFormatted($event)" (change)="AssignACFirstAppointmentAttended(radioShows)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="firstAppointmentAttendedTV" formControlName="firstAppointmentAttendedTV" id="firstAppointmentAttendedTV" (keyup)="CommaFormatted($event)" (change)="AssignACFirstAppointmentAttended(tv)" /></td>
                            </tr>

                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Buying units who book 2nd appt">2nd Appointment Booked </span>                  
                                  <!-- <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'secondAppointmentBookedAC')">Fill across value</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('secondAppointmentBookedAC')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcrossAC('secondAppointmentBookedAC')">Clear across values</a></li>  
                                    </ul>
                                  </div> -->
                                </div>
                              </th>

                              <td class="pl-5"><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="secondAppointmentBookedRadioSpots" formControlName="secondAppointmentBookedRadioSpots" id="secondAppointmentBookedRadioSpots" (keyup)="CommaFormatted($event)" (change)="AssignACSecondAppointmentBooked(radioSpots)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="secondAppointmentBookedRadioShows" formControlName="secondAppointmentBookedRadioShows" id="secondAppointmentBookedRadioShows" (keyup)="CommaFormatted($event)" (change)="AssignACSecondAppointmentBooked(radioShows)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="secondAppointmentBookedTV" formControlName="secondAppointmentBookedTV" id="secondAppointmentBookedTV" (keyup)="CommaFormatted($event)" (change)="AssignACSecondAppointmentBooked(tv)" /></td>
                            </tr>
                            
                      
                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Buying units who attend 2nd appt">2nd Appointment Attended </span>                  
                                  <!-- <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'secondAppointmentAttendedAC')">Fill across value</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('secondAppointmentAttendedAC')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcrossAC('secondAppointmentAttendedAC')">Clear across values</a></li>  
                                    </ul>
                                  </div> -->
                                </div>
                              </th>
  
                              <td class="pl-5"><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="secondAppointmentAttendedRadioSpots" formControlName="secondAppointmentAttendedRadioSpots" id="secondAppointmentAttendedRadioSpots" (keyup)="CommaFormatted($event)" (change)="AssignACSecondAppointmentAttended(radioSpots)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="secondAppointmentAttendedRadioShows" formControlName="secondAppointmentAttendedRadioShows" id="secondAppointmentAttendedRadioShows" (keyup)="CommaFormatted($event)" (change)="AssignACSecondAppointmentAttended(radioShows)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="secondAppointmentAttendedTV" formControlName="secondAppointmentAttendedTV" id="secondAppointmentAttendedTV" (keyup)="CommaFormatted($event)" (change)="AssignACSecondAppointmentAttended(tv)" /></td>
                            </tr>


                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Buying units who book 3rd appt">3rd Appointment Booked </span>                  
                                  <!-- <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'thirdAppointmentBookedAC')">Fill across value</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('thirdAppointmentBookedAC')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcrossAC('thirdAppointmentBookedAC')">Clear across values</a></li>  
                                    </ul>
                                  </div> -->
                                </div>
                              </th>

                              <td class="pl-5"><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="thirdAppointmentBookedRadioSpots" formControlName="thirdAppointmentBookedRadioSpots" id="thirdAppointmentBookedRadioSpots" (keyup)="CommaFormatted($event)" (change)="AssignACThirdAppointmentBooked(radioSpots)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="thirdAppointmentBookedRadioShows" formControlName="thirdAppointmentBookedRadioShows" id="thirdAppointmentBookedRadioShows" (keyup)="CommaFormatted($event)" (change)="AssignACThirdAppointmentBooked(radioShows)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="thirdAppointmentBookedTV" formControlName="thirdAppointmentBookedTV" id="thirdAppointmentBookedTV" (keyup)="CommaFormatted($event)" (change)="AssignACThirdAppointmentBooked(tv)" /></td>
                            </tr>
                            
                      
                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Buying units who attend 3rd appt">3rd Appointment Attended </span>                  
                                  <!-- <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'thirdAppointmentAttendedAC')">Fill across value</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('thirdAppointmentAttendedAC')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcrossAC('thirdAppointmentAttendedAC')">Clear across values</a></li>  
                                    </ul>
                                  </div> -->
                                </div>
                              </th>
  
                              <td class="pl-5"><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="thirdAppointmentAttendedRadioSpots" formControlName="thirdAppointmentAttendedRadioSpots" id="thirdAppointmentAttendedRadioSpots" (keyup)="CommaFormatted($event)" (change)="AssignACThirdAppointmentAttended(radioSpots)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="thirdAppointmentAttendedRadioShows" formControlName="thirdAppointmentAttendedRadioShows" id="thirdAppointmentAttendedRadioShows" (keyup)="CommaFormatted($event)" (change)="AssignACThirdAppointmentAttended(radioShows)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="thirdAppointmentAttendedTV" formControlName="thirdAppointmentAttendedTV" id="thirdAppointmentAttendedTV" (keyup)="CommaFormatted($event)" (change)="AssignACThirdAppointmentAttended(tv)" /></td>
                            </tr>
                              
                        
                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Number of new clients per month">Closed </span>                  
                                  <!-- <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'closedAC')">Fill across value</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('closedAC')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcrossAC('closedAC')">Clear across values</a></li>  
                                    </ul>
                                  </div> -->
                                </div>
                              </th>
    
                              <td class="pl-5"><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="closedRadioSpots" formControlName="closedRadioSpots" id="closedRadioSpots" (keyup)="CommaFormatted($event)" (change)="AssignACClosed(radioSpots)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="closedRadioShows" formControlName="closedRadioShows" id="closedRadioShows" (keyup)="CommaFormatted($event)" (change)="AssignACClosed(radioShows)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="closedTV" formControlName="closedTV" id="closedTV" (keyup)="CommaFormatted($event)" (change)="AssignACClosed(tv)" /></td>
                            </tr>

                            <tr class="default-value">
                              <th scope="row"><span tooltip="Number of new clients divided by total monthly leads ">Closing %</span></th>
                              <td class="text-right pl-5"><span style="margin-right: 12px;">{{closingPercentageRadioSpots}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{closingPercentageRadioShows}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{closingPercentageTV}}</span></td>
                            </tr>


                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Average $ amount of assets for each new clien">Total assets per close ($) </span>                  
                                  <!-- <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'totalAssetsPerCloseAC')">Fill across value</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('totalAssetsPerCloseAC')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcrossAC('totalAssetsPerCloseAC')">Clear across values</a></li>  
                                    </ul>
                                  </div> -->
                                </div>
                              </th>
    
                              <td class="pl-5"><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalAssetsPerCloseRadioSpots" formControlName="totalAssetsPerCloseRadioSpots" id="totalAssetsPerCloseRadioSpots" (keyup)="CommaFormatted($event)" (change)="AssignACTotalAssetsPerClose(radioSpots)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalAssetsPerCloseRadioShows" formControlName="totalAssetsPerCloseRadioShows" id="totalAssetsPerCloseRadioShows" (keyup)="CommaFormatted($event)" (change)="AssignACTotalAssetsPerClose(radioShows)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="totalAssetsPerCloseTV" formControlName="totalAssetsPerCloseTV" id="totalAssetsPerCloseTV" (keyup)="CommaFormatted($event)" (change)="AssignACTotalAssetsPerClose(tv)" /></td>
                            </tr>


                            <tr> 
                              <th scope="row">
                                <div class="row-head-with-more position-relative">
                                  <span tooltip="Percentage of assets that client agrees to put into annuities">Percent of assets to Annuity (%) </span>                  
                                  <!-- <div class="btn-group position-absolute more-actions" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="btn" aria-controls="dropdown-basic" tooltip="Click the three dots to open additional options"><i class="fal fa-ellipsis-v"></i></button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                      <li role="menuitem"><button class="btn" (click)="OpenFillAcrossModal(templatefillacross,'percentOfAssetsToANNAC')">Fill across value</button></li> 
                                      <li role="menuitem"><a class="dropdown-item" (click)="FillAcrossDefault('percentOfAssetsToANNAC')">Fill across default value</a></li>
                                      <li role="menuitem"><a class="dropdown-item" (click)="ClearAcrossAC('percentOfAssetsToANNAC')">Clear across values</a></li>  
                                    </ul>
                                  </div> -->
                                </div>
                              </th>
    
                              <td class="pl-5"><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="percentOfAssetsToANNRadioSpots" formControlName="percentOfAssetsToANNRadioSpots" id="percentOfAssetsToANNRadioSpots" (keyup)="CommaFormatted($event)" (change)="AssignACPercentOfAssetsToANN(radioSpots)"  /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="percentOfAssetsToANNRadioShows" formControlName="percentOfAssetsToANNRadioShows" id="percentOfAssetsToANNRadioShows" (keyup)="CommaFormatted($event)" (change)="AssignACPercentOfAssetsToANN(radioShows)" /></td>
                              <td><input type="text" class="form-control text-right"  placeholder="" [(ngModel)]="percentOfAssetsToANNTV" formControlName="percentOfAssetsToANNTV" id="percentOfAssetsToANNTV" (keyup)="CommaFormatted($event)" (change)="AssignACPercentOfAssetsToANN(tv)" /></td>
                            </tr>

                            <tr class="default-value">
                              <th scope="row"><span tooltip="Average assets under management per new client ">AUM per close ($)</span></th>
                              <td class="text-right pl-5"><span style="margin-right: 12px;">{{aUMPerCloseRadioSpots}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMPerCloseRadioShows}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMPerCloseTV}}</span></td>
                            </tr>

                            
                            <tr class="default-value bordered">
                              <th scope="row"><span tooltip="Average Annuity Premium amount per new client ">ANN per close ($)</span></th>
                              <td class="text-right pl-5"><span style="margin-right: 12px;">{{aNNPerCloseRadioSpots}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNPerCloseRadioShows}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNPerCloseTV}}</span></td>
                            </tr>
                          
                            
                            <tr class="default-value bordered">
                              <th scope="row"><span tooltip="Average monthly fee income per client per month ">Fee per client per month ($)</span></th>
                              <td class="text-right pl-5"><span style="margin-right: 12px;">{{feePerClientPerMonthRadioSpots}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{feePerClientPerMonthRadioShows}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{feePerClientPerMonthTV}}</span></td>
                            </tr>
                            

                            <tr class="default-value-green bordered">
                              <th scope="row"><span tooltip="Total trail commission per year per client ">Annuity Trail Per Year ($)</span></th>
                              <td class="text-right pl-5"><span style="margin-right: 12px;">{{aNNTrailPerYearRadioSpots}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailPerYearRadioShows}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailPerYearTV}}</span></td> 
                            </tr>

                            <tr class="default-value-green">
                              <th scope="row"><span tooltip="Total AUM revenue generated per client per month ">AUM fee per month ($)</span></th>
                              <td class="text-right pl-5"><span style="margin-right: 12px;">{{aUMFeePerMonthRadioSpots}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMFeePerMonthRadioShows}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aUMFeePerMonthTV}}</span></td>
                            </tr>                          

                            <tr class="default-value-green">
                              <th scope="row"><span tooltip="Total Annuity commission revenue per month ">Annuity comm per month ($)</span></th>
                              <td class="text-right pl-5"><span style="margin-right: 12px;">{{aNNCommPerMonthRadioSpots}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommPerMonthRadioShows}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNCommPerMonthTV}}</span></td>
                            </tr>

                            <tr class="default-value-green">
                              <th scope="row"><span tooltip="Total Annuity trail commission revenue per month ">Annuity Trail for Future years per month ($)</span></th>
                              <td class="text-right pl-5"><span style="margin-right: 12px;">{{aNNTrailForFutureYearsPerMonthRadioSpots}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailForFutureYearsPerMonthRadioShows}}</span></td>
                              <td class="text-right"><span style="margin-right: 12px;">{{aNNTrailForFutureYearsPerMonthTV}}</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
   
                    </tabset>
                    </div>
                  </div>
                </div>
              </div>             
            </div> 
            <div class="form-group   mt-4 mb-0">
              <button (click)="next()" class="btn  float-right common-btn ghoust-btn"> Continue <i class="fas fa-arrow-right pl-2"></i></button>
            </div>
          </div>        
        </form>       
        <div class="row w-100 m-0 p-0 bs-stepper-content">
          <form   class="w-100" (ngSubmit)="onSubmit()">
            <div id="wizard-or" class="content  w-100 mt-3 p-0"> 
              <div class="form-group   mt-2 mb-0">
                <button class="btn  float-right common-btn ghoust-btn" (click)="next()"> Generate Business Planning Calculator <i class="fas fa-arrow-right pl-2"></i></button>
                <!--a class="btn btn-primary float-right" href="index.html">Login</a-->
              </div>             
            </div>
          </form>
        </div>
        </form>
      </div>
    </div>    
  </div>
</div>    
</div>  
 <div class="bottom-bar d-flex hide" [ngClass]="{'visible': saveactionshow, 'notvisible': !saveactionshow  }">  
  <div class="row action-bar align-self-end position-relative">
    <span class="close d-block p-1" (click)="closeBottombar()"><i class="fal fa-times"></i></span>
    <div class="col-md-6">
      <label class="small mb-1 text-muted onelinelabel" for=""><i class="fas fa-info-circle icon-1x pr-2"></i> Click here to save your Business Plan.</label>
    </div>
    <div class="col-md-6">
      <button class="btn  float-right common-btn border-btn" (click)="SaveBusinessPlan(true)"> Save Business Plan </button>
    </div>
  </div> 
</div>  
<div class="right-bar">
  <div class="right-bar-wrapper w-100">
    <div class="tab-list">
      <ul class="list-group list-group-flush" tooltip="Show Business Planning Calculator" data-placement="left" [hidden]="status">
        <li class="list-group-item tab" style="border-width: 0 0 0px;" (click)="toggleRightbar()"><i class="fas fa-calculator"></i></li>
        <li class="list-group-item tab icon-btn" style="border-width: 0 0 0px;" (click)="toggleRightbar()" ><i class="fas fa-arrow-left fa-lg" [hidden]="status"></i></li>
      </ul>
      <ul class="list-group list-group-flush" tooltip="Hide Business Planning Calculator" data-placement="right" [hidden]="!status">
        <li class="list-group-item tab" style="border-width: 0 0 0px;" (click)="toggleRightbar()"><i class="fas fa-calculator" data-placement="right" style="font-size: 24px"></i></li>
        <li class="list-group-item tab icon-btn" style="border-width: 0 0 0px;" (click)="toggleRightbar()"><i class="fas fa-arrow-right fa-lg" [hidden]="!status"></i></li>
      </ul>
    </div>
    <header>
      <div class="btn-group m-1" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-link common-btn icon-btn move-left" (click)="resizeRightbar()"><i class="fas fa-arrow-alt-circle-left fa-lg" tooltip="Click Here to view results" data-placement="bottom" style="font-size: 24px"></i></button>
        <button type="button" class="btn btn-link common-btn icon-btn move-right" (click)="resizeRightbar()"><i class="fas fa-arrow-alt-circle-right fa-lg" tooltip="Click Here to view inputs and results side by side" data-placement="bottom" style="font-size: 24px"></i></button>
        <p class="d-inline p-2 m-0 bold" style="font-size: 16px">Business Planning Calculator</p>
        <button type="button" class="btn btn-link green-link common-btn icon-btn" (click)="refreshCalculator()"><i class="fas fa-redo pr-2 fa-lg"></i> <p class="d-inline p-2 m-0" style="font-size: 16px">Refresh </p></button>
      </div>
      
    </header>
  <app-calculator></app-calculator>
</div>
</div>
</div>
</main>
</div>
</div>

<ng-template #templatefilldown> 
  <div class="modal-header">
    <h4 class="modal-title pull-left">Fill Down</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="fillDownForm">
      <div class="form-group row">
        <label for="inputPassword" class="col-sm-2 col-form-label">Value</label>
        <div class="col-sm-3">
          <input type="text" class="form-control" id="fillDownValue"  
          [(ngModel)]="fillDownValue" formControlName="fillDownValue" (keyup)="CommaFormatted($event)"  >
        </div>
      </div>
      <div class="form-group row">
      
        <div class="col-md-3  ml-auto">
          <button type="submit" class="btn btn-primary  mb-2 w-100"  (click)="FillDown()">Fill</button>
        </div>
      </div>
    </form>
  </div> 
</ng-template>