import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from 'src/app/util/constants';

@Component({
  selector: 'app-monday-deposit-withdraw-cash-custom-cell',
  templateUrl: './monday-deposit-withdraw-cash-custom-cell.component.html'
})

export class MondayDepositWithdrawCashCustomCellComponent implements OnInit {
  data: any;
  params: any;
  constructor(private http: HttpClient, private router: Router) {}

  agInit(params) {
    this.params = params;
    this.data = params.value;
  }

  ngOnInit() {}
  
  showDetails() {
    this.params.context.componentParent.showAllocationHistoryDetails(this.params.data);
   }
}

export type CellAction = (params) => void;