import {
	Component,
	OnInit,
	ElementRef,
	ViewChild, 
	SimpleChanges,
	ViewContainerRef,
  ComponentRef,
  ComponentFactoryResolver,
  OnDestroy,
  TemplateRef
} from '@angular/core';
import { Router } from '@angular/router'; 
import { Constants } from '../util/constants'; 
import { LocalStorage } from '../util/localstorage.service'; 
import { Agent } from '../models/agent.model'; 
import Stepper from 'bs-stepper';
import { AlertService } from 'src/app/util/alert/alert.service';
import { CommonFunctions } from '../util/common-functions';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms'; 
import { SharedServiceService } from '../util/shared-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Chart, StockChart } from 'angular-highcharts';
import { BusinessCalculatorOutput } from 'src/app/models/business-calculator-output.model';
import { BusinessCalculatorDefaults } from 'src/app/models/business-calculator-defaults.model';
import { BusinessCalculatorInput } from 'src/app/models/business-calculator-input.model';
import { BusinessPlanDashboard } from 'src/app/models/buiness-plan-dashboard.model';
import { SuperuserDashboardDataService } from './superuser-dashboard-data.service';
import { PlanRunningOutOfMoneyHierarchy } from '../models/longevity-plan-summary.model';
import { PlanOfAgent } from '../models/organization-plan-summary.model';


@Component({
  selector: 'app-superuser-dashboard',
  templateUrl: './superuser-dashboard.component.html',
  styleUrls: ['./superuser-dashboard.component.scss']
})


export class SuperuserDashboardComponent implements OnInit  {

//#region "Variable declaration"
  name = 'Angular';
  public isSideBar: boolean = true
  public modalRef: BsModalRef;
  public paddingtop: number = 60;
  public loggedAgent: Agent 
  public currentStepperIndex: any  
  public currentStepperName:any
  public plansrunningoutofmoney: PlanRunningOutOfMoneyHierarchy;
  public organizations: Array<any>;
  public iSForm: FormGroup;
  public isShowFP4FA: boolean = false
  status: boolean = false;
  resizestatus: boolean = false;
  public organizationID: string;
  public collapseOrganization: boolean = false;
  public collapseAll: boolean = false;
  public collapseOrExpand: string = "Expand";

  //longevity summary related variables
  public totalorganizations: Number = 0;
  public totalagents: Number = 0;
  public totalclients: Number = 0;
  public totalplans: Number = 0;

  //organization summary related variables
  public totalnoofagents: Number = 0;
  public totalnoofclients: Number = 0;
  public totalnoofplans: Number = 0;
  public planspresentedtoclient: Number = 0;
  public plansOfAgents: Array<PlanOfAgent> = [];
  public selectedorganization: string;
  public selectedagent: string;

  //plans chart
  public agents: Array<any>;
  public plansDone: Array<any>;
  public plansPresented: Array<any>;
  public agentsForGraph: Array<any>;

  public plans: any;
//#endregion

//#region "constructor,  and ngOnInit"
  constructor(private resolver: ComponentFactoryResolver,private router: Router,
              private alertService: AlertService,
              private formBuilder: FormBuilder,private sharedService: SharedServiceService,
              private modalService: BsModalService,
              private superuserDashboardDataService: SuperuserDashboardDataService) 
  {

    this.iSForm = this.formBuilder.group({
      year: new FormControl(null),
      businessplanname: new FormControl(null),
			existingbusinessplanid: new FormControl(null)
		} ); 
  }

  ngOnDestroy()
  {
    this.alertService.clear();
  } 

  ngOnInit() 
  { 
    this.loggedAgent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
		if ((this.loggedAgent != null) && (this.loggedAgent != undefined)) { 
			// check whther the user allowed to see FP4FA
			if (this.loggedAgent.bShowBizIQ == true)
				this.isShowFP4FA = true
			else
				this.isShowFP4FA = false   
    }
    this.plansrunningoutofmoney = new PlanRunningOutOfMoneyHierarchy()
    this.plansOfAgents = []
    this.agents = []

    this.getOrganizations()
    this.getLongevityPlanSummary()
  }  
//#endregion

//#region "longevity summary"
getLongevityPlanSummary()
{
  this.alertService.clear()
  this.alertService.info('Loading data. Please wait.')	
  this.superuserDashboardDataService.getlongevityplansummary(this.loggedAgent).subscribe(data => {
    this.totalorganizations  = data.TotalNoOfOrganizations
    this.totalagents = data.TotalNoOfAgents
    this.totalplans  = data.TotalNoOfPlans
    this.totalclients  = data.TotalNoOfClients
    this.plansrunningoutofmoney = data.PlansRunningOutOfMoneyHierarchy
    this.alertService.clear()
    this.collapseAllOrganizations()
   },
   error => { 
     this.alertService.error('An error occurred')
   });	 
} 
//#endregion

//#region "organization summary"
  getOrganizations()
	{
		this.alertService.clear()
		this.superuserDashboardDataService.getorganizations(this.loggedAgent).subscribe(data => {
			this.organizations  = data
	 	},
	 	error => { 
	 		this.alertService.error('An error occurred')
	 	});	 
  } 
  
  onSelectOrganization(){
    this.getOrganizationPlanSummary(this.selectedorganization)
  }

  collapseOrganizationByID(OrganizationID){
    this.collapseOrganization = this.plansrunningoutofmoney.OrganizationHierarchyList.find(x => x.OrganizationID === OrganizationID).isCollapse
    this.plansrunningoutofmoney.OrganizationHierarchyList.find(x => x.OrganizationID === OrganizationID).isCollapse = !this.collapseOrganization
  }

  collapseAllOrganizations(){
    this.plansrunningoutofmoney.OrganizationHierarchyList.forEach(element => {
      element.isCollapse = true
    });
  }

  expandAllOrganizations(){
    this.plansrunningoutofmoney.OrganizationHierarchyList.forEach(element => {
      element.isCollapse = false
    });
  }

getOrganizationPlanSummary(organizationId)
{
  this.alertService.clear()
  this.superuserDashboardDataService.getorganizationplansummary(this.loggedAgent,organizationId).subscribe(data => {
    this.totalnoofagents  = data.TotalNoOfAgents
    this.totalnoofclients = data.TotalNoOfClients
    this.totalnoofplans  = data.TotalNoOfPlans
    this.planspresentedtoclient  = data.PlansPresentedToClient
 
    this.agents = []
    this.plansDone = []
    this.plansPresented = []
    this.agentsForGraph = []
    this.plansOfAgents = []

    data.PlansOfAgents.forEach(element => {
      this.plansOfAgents.push({AgentID: element.AgentID, AgentName: element.AgentName,NoOfPlansDone: element.NoOfPlansDone, 
        NoOfPlansPresentedToClient: element.NoOfPlansPresentedToClient})
      this.agents.push({Id: element.AgentID, Name: element.AgentName})
      this.agentsForGraph.push(element.AgentName)
      this.plansDone.push(element.NoOfPlansDone)
      this.plansPresented.push(element.NoOfPlansPresentedToClient)
    });
    this.preparePlansChart(this.plansDone, this.plansPresented);
   },
   error => { 
     this.alertService.error('An error occurred')
   });	 
} 

onSelectAgent(){
  var tempagent
  if(this.selectedagent != "All"){
    if(this.plansOfAgents != undefined && this.plansOfAgents != null){
      tempagent = this.plansOfAgents.find(x => x.AgentID == this.selectedagent)
      this.plansDone = []
      this.plansPresented = []
      this.agentsForGraph.push(tempagent.AgentName)
      this.plansDone.push(tempagent.NoOfPlansDone)
      this.plansPresented.push(tempagent.NoOfPlansPresentedToClient)
      this.preparePlansChart(this.plansDone, this.plansPresented);
    }
    
  }
}
//#endregion

//#region plans chart related methods
preparePlansChart(plansDone: any, plansPresented: any) {
  this.alertService.clear()
  this.plans = new Chart({
    chart: {
      type: 'coloumn'
    },
    title: {
      text: '',
      style: {
       font: '14px "Eagle"'
      }
    },
    plotOptions: {
      bar: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: true
          },
          showInLegend: true
      }
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: this.agentsForGraph
    },

    yAxis: {
        title: {
            text: ''
        }
    },
    colors: ['#21ad92', '#ebbe0c'],
    series: [
      {
        name: "Plans Done",
        type: 'column',
        data: plansDone
      },
      {
        name: "Plans Presented to Client",
        type: 'column',
        data: plansPresented
      }
    ]
  });
}
//#endregion

//redirect to retirement plans
public loadRetirementPlans()
{
  localStorage.setItem(LocalStorage.FP4FA, ""); 
  this.router.navigateByUrl('landing'); 
}

//redirect to fp4fa
public loadFP4FA()
{
  localStorage.setItem(LocalStorage.FP4FA, "fp4fa"); 
  this.router.navigateByUrl('landingfp4fa'); 
}

exitBtnClickEvent()
{
  this.router.navigateByUrl('landingfp4fa'); 
}
}




