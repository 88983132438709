import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	AfterContentInit,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewContainerRef,
	ComponentRef,
	ComponentFactoryResolver
} from '@angular/core';
import { Router } from '@angular/router';
import { AppCompLoaderService } from '../appComp/app-comp-loader.service';
import { Constants } from '../util/constants'; 
import { LocalStorage } from '../util/localstorage.service';
import { DynamicSidebarService } from '../sidebar/dynamic-sidebar.service'; 
import { Agent } from '../models/agent.model';
import { SharedServiceService } from '../util/shared-service.service';

import { ClientInfoDataService } from '../appComp/client-info/client-info-data.service';
import { BusinessCalculatorInput } from '../models/business-calculator-input.model';

@Component({
	selector: 'app-fp4fa',
	templateUrl: './fp4fa.component.html',
	styleUrls: ['./fp4fa.component.scss']
  })
  
  
  export class FP4FAComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {

	public paddingtop: number = 60;
	@ViewChild('navPanel') navPanel: ElementRef

	component: any;
    @ViewChild('longHost', { read: ViewContainerRef }) componentHost: ViewContainerRef
	componentRef: ComponentRef<Component>

	public constants: any = Constants
	public isSideBar: boolean = true
	public moduletype: any

	private selectClientID:any
	public loggedAgent: Agent 

	constructor(private resolver: ComponentFactoryResolver, private aclService: AppCompLoaderService,
		 private dynSidebarService: DynamicSidebarService,
		 private sharedService: SharedServiceService,private router: Router) { 
		
		this.loggedAgent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent;
		// check whther the user is Investment Only - Only have forms access
		if (this.loggedAgent.UserRoleID== Constants.UserRoleID.InvestmentOnly){
			this.router.navigateByUrl('landing'); 
		}

		//check whether the user have permission to access FP4FA 
		if (this.loggedAgent.bShowBizIQ == false){
			this.router.navigateByUrl('landing'); 
		}

		this.component = this.aclService.loadComponentByName(Constants.COMP_NAME.dashboard)
	}

	ngOnInit() { 
		let businesscalculatorinput = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_INPUT)) as BusinessCalculatorInput;
		if(businesscalculatorinput.BusinessPlanName == undefined || businesscalculatorinput.BusinessPlanName == ""
		|| businesscalculatorinput.BusinessPlanName == null){
			localStorage.setItem(LocalStorage.COMPANY_CONFIG, "1");
			this.changeSidebar({componentType: null, selectedItem: "Company Configuration"})
		} else{
			localStorage.setItem(LocalStorage.COMPANY_CONFIG, "0");
		}
	}

	public changeSidebar(event: any) {
		//console.log('[changeSideBarItem] (event) ' + JSON.stringify(event));
        this.component = this.aclService.loadComponentByName(event.selectedItem)
        this.updateComponent()
	}

	ngAfterContentInit(): void {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.updateComponent()
	}

	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
		
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		if (this.componentRef) {
			this.componentRef.destroy()
		}
	}

	updateComponent() {
		this.componentHost.clear()
		const authFormFactory = this.resolver.resolveComponentFactory(this.component)
		const componentRef = this.componentHost.createComponent(authFormFactory);
	}

}
