<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg">
	<app-header></app-header>
</nav>
<div class="main-panel pt-0 pb-4">
    <div class="container-fluid" #main [style.padding-top.px]="paddingtop">
        <div class="col-sm-12 px-0 align-self-end">
          <div class="row align-items-center">
            <div class="col-sm-9">
              <h3 class="light-header bold">
                Deposit/Withdraw Cash
              </h3>
            </div>
            <div class="col-sm-3">
              <button type="button" class="btn btn-primary btn-sm float-right" (click)="exitBtnClickEvent()">
                <i class="fas fa-sign-out"></i> Exit
              </button>
              <button type="button" class="btn btn-primary btn-sm mr-1 float-right" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
                {{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
              </button>
            </div>
          </div>
          <hr class="mt-0 mb-3" />
        </div>
        <div class="pt-3 row">
            <div class="col-sm-3">
            </div>
            <div class="col-sm-6">
              <div class="container border rounded bold">
                <div id="grid-wrapper" class="col-sm-12 px-0" style="width: 100%; overflow: hidden;">
                    <h4 class="light-header bold pt-2 pb-2">
                      Deposit/Withdraw Cash History
                    </h4>
                  <div class="col-sm-12 px-0" >
                    <ag-grid-angular style="width: 100%; height:400px; " class="ag-theme-balham bold"
                      [columnDefs]="columnDefs0" (gridReady)="onGridReady0($event)" [rowData]="rowData0"
                      [headerHeight]="40" [gridOptions]="gridOptions0"
                      (gridSizeChanged)="gridSizeChanged($event)">
                    </ag-grid-angular>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="pt-3 row">
          <div class="col-sm-3">
          </div>
          <div class="col-sm-6">
          <div class="container border rounded bold">
            <h4 class="light-header bold p-4">
              Deposit/Withdraw Cash
            </h4>
            <h6 class="light-header bold p-4">
              If you are changing the allocation, use the allocation request form - you must submit a new allocation.
            </h6> 
          <form [formGroup]="iSForm" class="p-4">
            <!-- <div class="form-group row">
              <label for="advisorname" class="col-sm-12 col-form-label">Advisor Name</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12"> 
                  <input type="text" class="form-control bold" id="advisorname"  autocomplete="off"
                       [(ngModel)]="advisorname"  formControlName="advisorname"/> 
                  <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div> --> 

            <div class="form-group row">
              <label for="advisorname" class="col-sm-12 col-form-label">Advisor Name</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <select name="advisorname" class="form-control bold col-sm-6" style="font-size: 14px;" 
                 [(ngModel)]="advisorname" formControlName="advisorname">
                    <option *ngFor="let advisor of advisorsArray" 
                    [value]="advisor.id">
                      {{advisor.text}}
                    </option>
                </select>
                <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row">
              <label for="accountname" class="col-sm-12 col-form-label">Company Name</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12"> 
                  <input type="text" class="form-control bold" id="companyname"  autocomplete="off"
                       [(ngModel)]="companyname"  formControlName="companyname" disabled/> 
                  <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row">
              <label for="login" class="col-sm-12 col-form-label">Login</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12"> 
                  <input type="text" class="form-control bold" id="login"  autocomplete="off"
                       [(ngModel)]="login"  formControlName="login" disabled/> 
                  <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row">
              <label for="accountname" class="col-sm-12 col-form-label">Account Name</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12"> 
                  <input type="text" class="form-control bold" id="accountname"  autocomplete="off"
                       [(ngModel)]="accountname"  formControlName="accountname" maxlength="50"/> 
                  <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row">
              <label for="accountnumber" class="col-sm-12 col-form-label">Account Number</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12"> 
                    <input type="text" class="form-control bold" id="accountnumber"  autocomplete="off"
                       [(ngModel)]="accountnumber"  formControlName="accountnumber" maxlength="20"/> 
                    <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row">
              <label for="action" class="col-sm-12 col-form-label">Action</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <select name="action" class="form-control bold col-sm-6" style="font-size: 14px;" 
                placeholder="Select your answer" [(ngModel)]="action" formControlName="action"
                (change)="changeAction($event)"> 
                    <option>Deposit</option>
                    <option>Withdrawal</option> 
                </select>
                <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row">
              <label for="amount" class="col-sm-12 col-form-label">Amount</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12"> 
                 <input type="text" class="form-control bold col-sm-6" id="amount"  autocomplete="off"
                       [(ngModel)]="amount" type='text' formControlName="amount"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()" maxlength="20"  min="1"/> 
                <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row">
              <label for="type" class="col-sm-12 col-form-label">Type</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <select name="type" class="form-control bold col-sm-6" style="font-size: 14px;" 
                placeholder="Select your answer" [(ngModel)]="type" formControlName="type"
                (change)="resetType($event)"> 
                    <option>One Time</option>
                    <option>Recurring</option> 
                </select>
                <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div *ngIf="showpaymentday">
            <div class="form-group row">
              <label for="type" class="col-sm-12 col-form-label">Payment Frequency</label>
            </div>
            <div class="form-group row" >
              <div class="col-sm-12">
                <select name="paymentfrequency" class="form-control bold col-sm-6" style="font-size: 14px;" 
                placeholder="Select your answer"  [(ngModel)]="paymentfrequency" formControlName="paymentfrequency" > 
                    <option>Monthly</option>
                    <option>Quarterly</option> 
                    <option>Annually</option>
                </select>
                <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div  class="form-group row"  >
              <label for="type" class="col-sm-12 col-form-label">Payment Date</label>
            </div>
            <div  [hidden]="isDeposit" class="form-group row" >
              <div class="col-sm-12">
                <select name="paymentday" class="form-control bold col-sm-6" style="font-size: 14px;" 
                placeholder="Select your answer"   [(ngModel)]="withdrawalpaymentday" formControlName="withdrawalpaymentday" > 
                    <option *ngFor="let item of withdrawalpaymentdates;" [value]="item.PaymentDate">
                      {{item.DisplayedDate}}
                    </option>
                </select>
                <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div   [hidden]="!isDeposit" class="form-group row" >
              <div class="col-sm-12">
                <select name="paymentday" class="form-control bold col-sm-6" style="font-size: 14px;" 
                placeholder="Select your answer"  [(ngModel)]="depositpaymentday" formControlName="depositpaymentday" >
                    <option *ngFor="let item of depositpaymentdates;" [value]="item.PaymentDate">
                      {{item.DisplayedDate}}
                    </option>
                </select>
                <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>
          </div>

           <!--  <div class="form-group row" [hidden]="!showpaymentday">
              <div class="col-sm-12">
                <select name="paymentday" class="form-control bold col-sm-6" style="font-size: 14px;" 
                placeholder="Select your answer" [(ngModel)]="paymentday" formControlName="paymentday"
                 > 
                    <option>5<span style="text-transform:uppercase;">th</span></option>
                    <option>25<span style="text-transform:uppercase;">th</span></option> 
                </select>
                <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div> -->

           <!--  <div class="form-group row">
              <label for="accounttype" class="col-sm-12 col-form-label">Account Type</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <select name="accounttype" class="form-control bold col-sm-6" style="font-size: 14px;" 
                placeholder="Select your answer" [(ngModel)]="accounttype" formControlName="accounttype"
                (change)="resetAccountType($event)">
                    <option>---</option>  
                    <option>Qualified (IRA, Roth, BDA, 401k, etc.)</option>
                    <option>Non-Qualified (Individual/Joint)</option>
                    <option>Entity (Trust, Corp., FLP, etc.)</option>
                    <option>Other</option>
                </select>
                <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>


            <div class="form-group row">
              <label for="instructiontype" class="col-sm-12 col-form-label">Instruction Type</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                  <select name="instructiontype" class="form-control bold col-sm-6" style="font-size: 14px;" 
                  placeholder="Select your answer" [(ngModel)]="instructiontype" formControlName="instructiontype"
                  (change)="resetInstructionType($event)">
                    <option>---</option>   
                    <option>New Investment</option>
                      <option>Re-Allocation</option>
                  </select>
                  <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>  -->

           <!--  <div class="form-group row">
              <label for="instructiontype" class="col-sm-12 col-form-label pb-0">Lower Risk Strategies</label>
            </div>
            <div id="grid-wrapper" class="col-sm-12 px-0" style="width: 100%; overflow: hidden;">
              <div class="col-sm-12 px-0" >
                <ag-grid-angular style="width: 100%; height: 160px; " class="ag-theme-balham bold"
                  [columnDefs]="columnDefs1" (gridReady)="onGridReady1($event)" [rowData]="rowData1"
                  [headerHeight]="40" [gridOptions]="gridOptions1" (cellValueChanged)="onCellValueChanged($event)"
                  (gridSizeChanged)="gridSizeChanged($event)">
                </ag-grid-angular>
              </div>
            </div>

            <div class="form-group row">
              <label for="instructiontype" class="col-sm-12 col-form-label pb-0">Moderate Risk Strategies</label>
            </div>
            <div id="grid-wrapper" class="col-sm-12 px-0" style="width: 100%; overflow: hidden;">
              <div class="col-sm-12 px-0" >
                <ag-grid-angular style="width: 100%; height: 240px; " class="ag-theme-balham bold"
                  [columnDefs]="columnDefs2" (gridReady)="onGridReady2($event)" [rowData]="rowData2"
                  [headerHeight]="40" [gridOptions]="gridOptions2" (cellValueChanged)="onCellValueChanged($event)"
                  (gridSizeChanged)="gridSizeChanged($event)">
                </ag-grid-angular>
              </div>
            </div>

            <div class="form-group row">
              <label for="instructiontype" class="col-sm-12 col-form-label pb-0">Growth Strategies</label>
            </div>
            <div id="grid-wrapper" class="col-sm-12 px-0" style="width: 100%; overflow: hidden;">
              <div class="col-sm-12 px-0 pb-4" >
                <ag-grid-angular style="width: 100%; height: 160px; " class="ag-theme-balham bold"
                  [columnDefs]="columnDefs3" (gridReady)="onGridReady3($event)" [rowData]="rowData3" [pinnedBottomRowData]="pinnedBottomRowData"
                  [headerHeight]="40" [gridOptions]="gridOptions3" (cellValueChanged)="onCellValueChanged($event)"
                  (gridSizeChanged)="gridSizeChanged($event)">
                </ag-grid-angular>
              </div>
            </div> -->

           

           <!--  <div class="form-group row">
              <label for="pendingannuities" class="col-sm-12 col-form-label">Pending Annuities</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="pendingannuities"  autocomplete="off"
                       [(ngModel)]="pendingannuities" type='number' formControlName="pendingannuities"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()" 
                       (focusout)="ValidateMinInvestmentOnFocusOut()" /> 
              </div>
            </div>



            <div class="form-group row">
              <label for="heldpositions" class="col-sm-12 col-form-label">Held Positions</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12"> 
                 <input type="text" class="form-control bold" id="heldpositions"  autocomplete="off"
                       [(ngModel)]="heldpositions"  formControlName="heldpositions"/> 
              </div>
            </div>

            <div class="form-group row">
                <label for="uploadforms" class="col-sm-12 col-form-label">Upload Forms</label>
                <br/>
                <label for="percentage" class="col-sm-12">Upload the complete account allocation request form, and the signed ADV acknowledgement if it is a new account</label>
              </div>
            <div class="form-group row">
                <div class="col-sm-12"> 
                  <input type="file" class="form-control bold col-sm-6" id="file" autocomplete="off" [(ngModel)]="file" formControlName="file"
                    (change)="handleFileInput($event)" value="file" multiple/> 
                    <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
                </div>          
            </div>

            <div class="form-group pt-3">
              <div *ngFor="let file of fileInfos" class="row">
                  <label class="col-sm-10 col-form-label">{{ file.name }}</label>                 
                  <button type="button" class="close" (click)= "removeFile(file)">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
            </div>


            <div class="form-group row">
                <label for="notes" class="col-sm-12 col-form-label">Notes</label>
            </div>
            <div class="form-group row">
                <div class="col-sm-12"> 
                    <textarea class="form-control bold"  rows="6" [(ngModel)]="notes" formControlName="notes"
                    name="notes" id="notes"></textarea> 
                </div>
            </div> -->
            <div class="form-group row">
              <label for="notes" class="col-sm-12 col-form-label">Notes</label>
            </div>
            <div class="form-group row">
                <div class="col-sm-12"> 
                    <textarea class="form-control bold"  rows="6" [(ngModel)]="notes" formControlName="notes"
                    name="notes" id="notes"></textarea> 
                </div>
            </div>
            <div class="form-group row float-right p-3">
              <button type="button" class="btn btn-primary btn-sm" (click)="saveMondayDepositWithdrawCashDetails()" [disabled]="disablesubmitbtn">
                  <i class="fas fa-sign-out"></i> Submit
              </button>
            </div>
          </form>
        </div>
        </div>
        </div>
      </div>
    </div>

    <ng-template #show_deposit_withdraw_cash_details>
      <div class="modal-md">
        <div class="modal-header">
            <h5 class="modal-title">Deposit Withdraw Cash Details</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelModal()">
              <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
        <div class="container-fluid bold">
              <div>
                <div class="container border rounded p-3 bold">
                    <form [formGroup]="iSFormDepositWithdrawCashDetails">
                      <div class="form-group row" [hidden]="!showSubmissionDate">
                        <label for="submissionDateInPopUp" class="col-sm-5 col-form-label">Submission Date</label>
                        <div class="col-sm-7"> 
                           <input type="text" class="form-control bold" id="submissionDateInPopUp" autocomplete="off"
                           [(ngModel)]="submissionDateInPopUp" formControlName="submissionDateInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showAdvisorName">
                        <label for="advisorNameInPopUp" class="col-sm-5 col-form-label">Advisor Name</label>
                        <div class="col-sm-7"> 
                           <input type="text" class="form-control bold" id="advisorNameInPopUp" autocomplete="off"
                           [(ngModel)]="advisorNameInPopUp" formControlName="advisorNameInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showCompanyName">
                        <label for="companyNameInPopUp" class="col-sm-5 col-form-label">Company Name</label>
                        <div class="col-sm-7"> 
                           <input type="text" class="form-control bold" id="companyNameInPopUp" autocomplete="off"
                           [(ngModel)]="companyNameInPopUp" formControlName="companyNameInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showLogin">
                        <label for="loginInPopUp" class="col-sm-5 col-form-label">Login</label>
                        <div class="col-sm-7"> 
                           <input type="text" class="form-control bold" id="loginInPopUp" autocomplete="off"
                           [(ngModel)]="loginInPopUp" formControlName="loginInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showAccountName">
                        <label for="accountNameInPopUp" class="col-sm-5 col-form-label">Account Name</label>
                        <div class="col-sm-7"> 
                           <input type="text" class="form-control bold" id="accountNameInPopUp" autocomplete="off"
                           [(ngModel)]="accountNameInPopUp" formControlName="accountNameInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showAccountNumber">
                        <label for="accountNumberInPopUp" class="col-sm-5 col-form-label">Account Number</label>
                        <div class="col-sm-7"> 
                           <input type="text" class="form-control bold" id="accountNumberInPopUp" autocomplete="off"
                           [(ngModel)]="accountNumberInPopUp" formControlName="accountNumberInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showGroup">
                        <label for="groupInPopUp" class="col-sm-5 col-form-label">Action</label>
                        <div class="col-sm-7"> 
                           <input type="text" class="form-control bold" id="groupInPopUp" autocomplete="off"
                           [(ngModel)]="groupInPopUp" formControlName="groupInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showAmount">
                        <label for="amountInPopUp" class="col-sm-5 col-form-label">Amount</label>
                        <div class="col-sm-7"> 
                           <input type="text" class="form-control bold" id="amountInPopUp" autocomplete="off"
                           [(ngModel)]="amountInPopUp" formControlName="amountInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showType">
                        <label for="typeInPopUp" class="col-sm-5 col-form-label">Type</label>
                        <div class="col-sm-7"> 
                           <input type="text" class="form-control bold" id="typeInPopUp" autocomplete="off"
                           [(ngModel)]="typeInPopUp" formControlName="typeInPopUp" disabled/> 
                        </div>
                      </div>
                      <!-- <div class="form-group row" [hidden]="!showPaymentStatus">
                        <label for="paymentStatusInPopUp" class="col-sm-5 col-form-label">Payment Status</label>
                        <div class="col-sm-7"> 
                           <input type="text" class="form-control bold" id="paymentStatusInPopUp" autocomplete="off"
                           [(ngModel)]="paymentStatusInPopUp" formControlName="paymentStatusInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showNextPaymentDate">
                        <label for="nextPaymentDateInPopUp" class="col-sm-5 col-form-label">Next Payment Date</label>
                        <div class="col-sm-7"> 
                           <input type="text" class="form-control bold" id="nextPaymentDateInPopUp" autocomplete="off"
                           [(ngModel)]="nextPaymentDateInPopUp" formControlName="nextPaymentDateInPopUp" disabled/> 
                        </div>
                      </div> -->
                      <div class="form-group row" [hidden]="!showNotes">
                        <label for="notesInPopUp" class="col-sm-5 col-form-label">Notes</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="notesInPopUp" autocomplete="off"
                           [(ngModel)]="notesInPopUp" formControlName="notesInPopUp" disabled/>   
                        </div>
                      </div>
                    </form>
                  </div>
              </div>
          </div>
        </div>
      </div>
      </ng-template>