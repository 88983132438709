<div class="main-panel pt-0 pb-4">
	<div class="container-fluid">
		<div class="col-sm-12 px-0">
			<h3 class="light-header bold">
				{{ 'INCASS.LBL_TITLE' | translate}}
				<button type="button" class="btn btn-primary btn-sm float-right" (click)="exitBtnClickEvent()">
					<i class="fas fa-sign-out"></i> Exit
				</button>
				<button type="button" class="btn btn-primary btn-sm mr-1 float-right" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
					{{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
				</button>
			</h3>
			<hr class="mt-0 mb-1" />
			<div class="pt-3">
				<div class="container border rounded p-3 bold">
					<!--Account List Start-->
					<h5 class="light-header bold">
						{{ 'INCASS.LBL_TABLE_TITLE' | translate}}
						<small class=" text-muted mx-1 vertical-line">|</small>
						<small class="h6 text-muted ml-2 bold">{{ activeTabName | translate}} 
							<small class="h6 text-muted mx-1 vertical-line" *ngIf="isPresentedToClient">| </small>
							<span style="color: red" *ngIf="isPresentedToClient"> Presented to client</span>
							<!-- <div class="d-inline-block align-top vertical-line ml-1 mr-1"></div> -->
						</small>


						<div class="float-right">
							<button type="button" class="btn btn-primary btn-sm float-right ml-1"
								(click)="deleteBtnClickEvent()" [disabled]="isPresentedToClient">
								<i class="fas fa-minus"></i> Delete
							</button>
							<button type="button" class="btn btn-primary btn-sm float-right ml-1"
								(click)="copyBtnClickEvent()" [disabled]="isPresentedToClient">
								<i class="fas fa-copy"></i> Copy
							</button>
							<button type="button" class="btn btn-primary btn-sm float-right ml-1"
								(click)="newBtnClickEvent()" [disabled]="isPresentedToClient">
								<i class="fas fa-plus"></i> New
							</button>

						</div>
					</h5>
					<hr class="mt-0 mb-3" />
					<div id="grid-wrapper" class="col-sm-12 px-0" style="width: 100%; overflow: hidden;">
						<div class="col-sm-11 px-0" style="float: left; width: 100%;">
							<ag-grid-angular style="width: 100%; height: 300px; " class="ag-theme-balham bold"
								[rowData]="rowData" [columnDefs]="columnDefs" (gridReady)="onGridReady($event)"
								(gridSizeChanged)="gridSizeChanged($event)" (cellClicked)="onCellClicked($event)"
								(selectionChanged)="onSelectionChanged($event)" [headerHeight]="60"
								[gridOptions]="gridOptions">
							</ag-grid-angular>
						</div>
						<div class="col-sm-1 px-0" style="float: right">
							<br><br><br>
							<div style=" display:table;  ">
								<button type="button" style="width: 80px; margin-bottom: 2px;"
									class="btn btn-primary btn-sm float-right ml-2" (click)="moveupBtnClickEvent()"
									[disabled]="isPresentedToClient">
									Move <i class="fas fa-angle-up"></i>
								</button>
							</div>
							<div style="display:table;">
								<button type="button" style="width: 80px;  margin-bottom: 2px;"
									class="btn btn-primary btn-sm float-right ml-2" (click)="movedownBtnClickEvent()"
									[disabled]="isPresentedToClient">
									Move <i class="fas fa-angle-down"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
				<!--Account List end-->

				<!--Account Details end-->
				<div class="container border rounded p-3 bold">
					<h5 class="light-header bold">
						{{ 'INCSOU.LBL_FORM_TITLE' | translate}}
					</h5>
					<hr class="mt-0 mb-3" />
					<form [formGroup]="iFAForm">
						<div class="form-group row">
							<label for="Owner" class="col-sm-2 col-form-label">Owner</label>
							<div class="col-sm-10">
								<!--   <select class="form-control" id="owner" 
											 [(ngModel)]="incomeSourceAccount.Owner" formControlName="owner"> 
											 <option></option>
											<option *ngFor="let item of ownerlist" [ngValue]="item.text">
												{{item.text}}
											</option>									
										</select>   -->

								<input type="text" list="owners" class="form-control bold" id="owner" autocomplete="off"
									[(ngModel)]="owner" formControlName="owner" (click)="ownersClicked()"
									(change)="ownerSelectedValueChanged(owner)" />
								<datalist id="owners">
									<option *ngFor="let item of ownerlist" [ngValue]="item.text">
										{{item.text}}
									</option>
								</datalist>
							</div>
						</div>
						<div class="form-group row">
							<label for="title" class="col-sm-2 col-form-label">Title/Description</label>
							<div class="col-sm-10">
								<input type="text" class="form-control bold" id="title" [(ngModel)]="title"
									formControlName="title">
							</div>
						</div>
						<div class="form-group row">
							<label for="title" class="col-sm-2 col-form-label">Asset Type</label>
							<div class="col-sm-10">
								<select class="form-control bold" id="assettype" [(ngModel)]="assetType"
									formControlName="assettype">
									<!-- <option value='InvestmentAccount'>Investment Account</option>
									<option value='PrivatePension'>Private Pension</option>
									<option value='LegacyAsset'>Legacy Asset</option> -->
									<option *ngFor="let item of assettypeslist;" [value]="item.AssetTypeID">
										{{item.AssetType}}
									</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label for="Deposit" class="col-sm-2 col-form-label">Deposit</label>
							<div class="col-sm-10">
								<input type="text" class="form-control bold" id="Deposit" [(ngModel)]="deposit"
									formControlName="deposit" (change)="changeDeposit($event.target.value)"
									(input)="onDepositTextChange()" 
									(keyup)="CommaFormatted($event)" [readonly]="isDepositValReadOnly">
							</div>
						</div>
						<div class="form-row">
							<label for="bonus" class="col-sm-2 col-form-label">Bonus</label>
							<div class="form-group col-sm-4">

								<select #bonus class="form-control ml-1 bold" id="bonus" (change)="changeBonusFlag(bonus.value)"
									[(ngModel)]="bonusFlag" formControlName="bonusflag">
									<option value='0'>No</option>
									<option value='1'>Yes</option>
								</select>
							</div>
							<div class="col">
								<input type="text" list="bonuspcts" class="form-control bold" id="bonuspct"
									autocomplete="off" [(ngModel)]="bonusPct" formControlName="bonuspct"
									(click)="bonuspctsClicked()" (input)="changeBonusPct()" />
								<datalist id="bonuspcts">
									<option>0.00%</option>
									<option>1.00%</option>
									<option>2.00%</option>
									<option>3.00%</option>
									<option>4.00%</option>
									<option>5.00%</option>
									<option>6.00%</option>
									<option>7.00%</option>
									<option>Or type in any value you want</option>
								</datalist>
							</div>
							<div class="col">
								<label for="COLA % year" class="col-sm-10 col-form-label"> Bonus %</label>
							</div>
						</div>
						<!-- <div class="form-row">
							<label for="COLA" class="col-sm-2 col-form-label">Months of Deferral</label>
							<div class="form-group col-sm-7">
								<select class="form-control" id="mod">
									<option>120 (Janaury,2028)</option>
								</select>
							</div>
						</div> -->
						<div class="form-row">
							<label for="Start" class="col-sm-2 col-form-label">Months of Deferral</label>
							<div class="form-group  col-sm-4 ml-1">
								<select class="form-control bold" id="Start1" (change)="changeMonthsIfDeferral()"
									[(ngModel)]="startdatemonth" formControlName="startdate"> >
									<option *ngFor="let item of startyearlist" [ngValue]="item.value">
										{{item.text}}
									</option>
								</select>
							</div>
						</div>
						<div class="form-row">
							<label for="COLA" class="col-sm-2 col-form-label">Asset Feature</label>
							<div class="form-group col-sm-4">
								<select class="form-control ml-1 bold" id="rolluphow" (change)="changeRollupHow()"
									[(ngModel)]="rollupHow" formControlName="rolluphow">
									<option value='Compound'>Compound</option>
									<option value='Simple'>Simple</option>
								</select>
							</div>
							<div class="col">
								<input type="text" list="rolluppcts" class="form-control bold" id="rolluppct"
									autocomplete="off" [(ngModel)]="rollupPct" formControlName="rolluppct"
									(click)="rolluppctsClicked()" (input)="changeRollupPct()" />
								<datalist id="rolluppcts">
									<option>0.00%</option>
									<option>1.00%</option>
									<option>2.00%</option>
									<option>3.00%</option>
									<option>4.00%</option>
									<option>5.00%</option>
									<option>6.00%</option>
									<option>7.00%</option>
									<option>Or type in any value you want</option>
								</datalist>
							</div>
							<div class="col">
								<label for="benefit survivor" class="col-sm-10 col-form-label">Asset Growth Rate
									%</label>
							</div>
						</div>
						<div class="form-row">
							<label for="input" class="col-sm-2 col-form-label">Income Asset Payout Value</label>
							<div class="form-group col-sm-4">
								<input type="text" class="form-control ml-1 bold" [(ngModel)]="accountValue"
									formControlName="accountvalue" readonly>
							</div>
							<div class="col">
								<input type="text"  class="form-control bold" [(ngModel)]="annualAmount"  [ngModelOptions]="{standalone: true}" readonly /> 
							</div>
							<div class="col">
									<input type="text"  class="form-control bold" [(ngModel)]="monthlyAmount" [ngModelOptions]="{standalone: true}" readonly /> 
							</div>


						</div>
						<div class="form-row">
							<label for="payout" class="col-sm-2 col-form-label">Payout %</label>
							<div class="form-group col-sm-4">
								<input type="text" list="payoutpcts" class="form-control ml-1 bold" id="payoutpct"
									autocomplete="off" [(ngModel)]="payoutPct" formControlName="payoutpct"
									(click)="payoutpctsClicked()"
									(input)="onPayoutpctTextChange()"  />
								<datalist id="payoutpcts">
									<option>0.00%</option>
									<option>1.00%</option>
									<option>2.00%</option>
									<option>3.00%</option>
									<option>4.00%</option>
									<option>5.00%</option>
									<option>6.00%</option>
									<option>7.00%</option>
									<option>Or type in any value you want</option>
								</datalist>
							</div>
							<div class="col">
								<!-- <label for="Begins on Start Year" class="col-sm-10 col-form-label"> Begins on Start
									Year</label> -->
							</div>
						</div>
						<div class="form-row">
							<label for="Survivorship" class="col-sm-2 col-form-label">Survivorship</label>
							<div class="form-group col-sm-4">
								<select #survivorship class="form-control ml-1 bold" id="survivorship"
									(change)="changeSurvivorshipHow(survivorship.value)" [(ngModel)]="survivorshipHow"
									formControlName="survivorshiphow">
									<option value='Individual'>Individual</option>
									<option value='Joint'>Joint</option>
								</select>
							</div>
							<div class="col">
								<input type="text" list="survivorshippcts" class="form-control bold" id="survivorshippct"
									autocomplete="off" [(ngModel)]="survivorshipPct" formControlName="survivorshippct"
									(click)="survivorshippctsClicked()" />
								<datalist id="survivorshippcts">
										<option>0.00%</option>
										<option>25.00%</option>
										<option>50.00%</option>
										<option>75.00%</option>
										<option>80.00%</option>
										<option>100.00%</option> 
										<option >Or type in any value you want</option>
								</datalist>
							</div>
							<div class="col">
								<label for="% to Survivor" class="col-sm-10 col-form-label"> % to Survivor</label>
							</div>
						</div>
						<div class="form-row">
							<label for="COLA" class="col-sm-2 col-form-label">COLA</label>
							<div class="form-group col-sm-4">
								<select #cola class="form-control ml-1 bold" id="cola" (change)="changeCOLAFlag(cola.value)"
									[(ngModel)]="colaFlag" formControlName="cola">
									<option value='0'>No</option>
									<option value='1'>Yes</option>
								</select>
							</div>
							<div class="col">

								<input type="text" list="colapcts" class="form-control bold" id="colapct" autocomplete="off"
									[(ngModel)]="colaPct" formControlName="colapct" (click)="colapctsClicked()" />
								<datalist id="colapcts">
									<option>0.00%</option>
									<option>1.00%</option>
									<option>2.00%</option>
									<option>3.00%</option>
									<option>4.00%</option>
									<option>5.00%</option>
									<option>6.00%</option>
									<option>7.00%</option>
									<option>Or type in any value you want</option>
								</datalist>
								<!-- <select class="form-control" id="End1"   
									[(ngModel)]="colaPct" formControlName="colapct" >
										<option>0.00%</option>
										<option>1.00%</option>
										<option>2.00%</option>
										<option>3.00%</option>
										<option>4.00%</option>
										<option>5.00%</option>
										<option>6.00%</option>
										<option>7.00%</option> 
										<option >Or type in any value you want</option>
									</select> -->
							</div>
							<div class="col">
								<label for="COLA % year" class="col-sm-10 col-form-label">COLA % Year</label>
							</div>
						</div> 




						<div class="form-row">
							<label for="ridercharge" class="col-sm-2 col-form-label">Rider Charge %</label>
							<div class="form-group col-sm-4">
								<input type="text" list="ridercharges" class="form-control ml-1 bold" id="ridercharge"
									autocomplete="off" [(ngModel)]="riderCharge" formControlName="ridercharge"
									(click)="riderChargeClicked()"
									(input)="onRiderChargeTextChange()"  />
								<datalist id="ridercharges">
									<option>0.00%</option>
									<option>1.00%</option>
									<option>2.00%</option>
									<option>3.00%</option>
									<option>4.00%</option>
									<option>5.00%</option>
									<option>6.00%</option>
									<option>7.00%</option>
									<option>Or type in any value you want</option>
								</datalist>
							</div>
							<div class="col">
								<!-- <label for="Begins on Start Year" class="col-sm-10 col-form-label"> Begins on Start
									Year</label> -->
							</div>
						</div>
						<br/>
						<div class="form-row">
							<div class="form-group col-sm-6">
								<h6 class="light-header bold">
									Client's Monthly Deposit 
								</h6>
									<hr class="mt-0 mb-3" /> 
							</div>
							<div class="form-group col-sm-6">
								<h6 class="light-header bold">
										Spouse's Monthly Deposit 
								</h6>
									<hr class="mt-0 mb-3" />  
							</div>
						</div>
						
						<div class="form-row">
							<label for="clientInitialDeposit" class="col-sm-2 col-form-label"> Initial Deposit</label>
							<div class="form-group  col-sm-4">
								<input type="text" class="form-control  ml-1 bold" id="clientInitialDeposit" [(ngModel)]="clientInitialDeposit"
									formControlName="clientInitialDeposit" (change)="changeClientInitialDeposit($event.target.value)"
									(input)="onClientInitialDepositTextChange()" 
									(keyup)="CommaFormatted($event)" >
							</div>  
							<label for="spouseInitialDeposit" class="col-sm-3 col-form-label pl-3"> Initial Deposit</label>
							<div class="form-group col-sm-3">
								<input type="text" class="form-control bold" id="spouseInitialDeposit" [(ngModel)]="spouseInitialDeposit"
									formControlName="spouseInitialDeposit" (change)="changeSpouseInitialDeposit($event.target.value)"
									(input)="onSpouseInitialDepositTextChange()" 
									(keyup)="CommaFormatted($event)" [readonly]="(this.hasSpouse == false )">
							</div>				
						</div>
						<div class="form-row">
							<label for="clientDeposit" class="col-sm-2 col-form-label">Amount</label>
							<div class="form-group  col-sm-4">
								<input type="text" class="form-control  ml-1 bold" id="clientDeposit" [(ngModel)]="clientMonthlyDeposit"
									formControlName="clientMonthlyDeposit" (change)="changeClientMonthlyDeposit($event.target.value)"
									(input)="onClientMonthlyDepositTextChange()" 
									(keyup)="CommaFormatted($event)" >
							</div>  
							
							<!-- <div class="form-group col-sm-4">
								<input type="text" list="payoutpcts" class="form-control ml-1 bold" id="payoutpct"
									autocomplete="off" [(ngModel)]="payoutPct" formControlName="payoutpct"
									(click)="payoutpctsClicked()"
									(input)="onPayoutpctTextChange()"  />
							</div> -->	
							<label for="spouseDeposit" class="col-sm-3 col-form-label pl-3"> Amount</label>
							<div class="form-group col-sm-3">
								<input type="text" class="form-control bold" id="spouseDeposit" [(ngModel)]="spouseMonthlyDeposit"
									formControlName="spouseMonthlyDeposit" (change)="changeSpouseMonthlyDeposit($event.target.value)"
									(input)="onSpouseMonthlyDepositTextChange()" 
									(keyup)="CommaFormatted($event)" [readonly]="(this.hasSpouse == false )">
							</div>
							<!-- <div class="form-group col-sm-3">
								<input type="text" list="payoutpcts" class="form-control  bold" id="payoutpct"
									autocomplete="off" [(ngModel)]="payoutPct" formControlName="payoutpct"
									(click)="payoutpctsClicked()"
									(input)="onPayoutpctTextChange()"  />
							</div>		 -->					
						</div>
						<div class="form-row">
							<label for="clientMonthlyDepositGrowthRate" class="col-sm-2 col-form-label">Growth Rate</label>
							<div class="form-group col-sm-4">
								<input type="text" list="clientMonthlyDepositGrowthRateList" class="form-control ml-1 bold" id="clientMonthlyDepositGrowthRate"
									autocomplete="off" [(ngModel)]="clientMonthlyDepositGrowthRate" formControlName="clientMonthlyDepositGrowthRate"
									(click)="clientMonthlyDepositGrowthRateClicked()"
									(input)="onClientMonthlyDepositGrowthRateTextChange()"  />
								<datalist id="clientMonthlyDepositGrowthRateList">
									<option>0.00%</option>
									<option>1.00%</option>
									<option>2.00%</option>
									<option>3.00%</option>
									<option>4.00%</option>
									<option>5.00%</option>
									<option>6.00%</option>
									<option>7.00%</option>
									<option>Or type in any value you want</option>
								</datalist>
							</div>

							<label for="spouseMonthlyDepositGrowthRate" class="col-sm-3 col-form-label pl-3">Growth Rate</label>
							<div class="form-group col-sm-3">
								<input type="text" list="spouseMonthlyDepositGrowthRateList" class="form-control bold" id="spouseMonthlyDepositGrowthRate"
									autocomplete="off" [(ngModel)]="spouseMonthlyDepositGrowthRate" formControlName="spouseMonthlyDepositGrowthRate"
									(click)="spouseMonthlyDepositGrowthRateClicked()"
									(input)="onSpouseMonthlyDepositGrowthRateTextChange()" [readonly]="(this.hasSpouse == false )" />
								<datalist id="spouseMonthlyDepositGrowthRateList" >
									<option>0.00%</option>
									<option>1.00%</option>
									<option>2.00%</option>
									<option>3.00%</option>
									<option>4.00%</option>
									<option>5.00%</option>
									<option>6.00%</option>
									<option>7.00%</option>
									<option>Or type in any value you want</option>
								</datalist>
							</div>
							 
						</div>
						<div class="form-row">
							<label for="clientMonthlyDepositStartDate" class="col-sm-2 col-form-label">Start Date</label>
							<div class="form-group col-sm-4">									
								<input type="text" class="form-control ml-1 bold" bsDatepicker (bsValueChange)="OnClientMonthlyDepositStartDateChange($event)"
								[bsValue]= " clientMonthlyDepositStartDate"    value="{{  clientMonthlyDepositStartDate | date:'MM/dd/yyyy' }}"
								[bsConfig]="{ containerClass: 'theme-default' }"
								id="clientMonthlyDepositStartDate" [disabled]="isPresentedToClient">
							</div>
							<label for="spouseMonthlyDepositStartDate" class="col-sm-3 col-form-label pl-3"> Start Date</label>
							<div class="form-group col-sm-3">									
								<input type="text" class="form-control bold" bsDatepicker (bsValueChange)="OnSpouseMonthlyDepositStartDateChange($event)"
								[bsValue]= " spouseMonthlyDepositStartDate" value="{{  spouseMonthlyDepositStartDate | date:'MM/dd/yyyy' }}"
								[bsConfig]="{ containerClass: 'theme-default' }"
								id="spouseMonthlyDepositStartDate" [disabled]="(this.hasSpouse == false || isPresentedToClient)">
							</div>
						</div>	
						<div class="form-row">
							<label for="clientMonthlyDepositEndDate" class="col-sm-2 col-form-label">End Date</label>
							<div class="form-group col-sm-4">									
								<input type="text" class="form-control ml-1 bold" bsDatepicker (bsValueChange)="OnClientMonthlyDepositEndDateChange($event)"
								[bsValue]= "clientMonthlyDepositEndDate" value="{{ clientMonthlyDepositEndDate | date:'MM/dd/yyyy' }}"
								[bsConfig]="{ containerClass: 'theme-default' }"
								id="clientMonthlyDepositEndDate" [disabled]="isPresentedToClient">
							</div>
							<label for="spouseMonthlyDepositEndDate" class="col-sm-3 col-form-label pl-3"> End Date</label>
							<div class="form-group col-sm-3">									
								<input type="text" class="form-control bold" bsDatepicker (bsValueChange)="OnSpouseMonthlyDepositEndDateChange($event)"
								[bsValue]= "spouseMonthlyDepositEndDate"    value="{{ spouseMonthlyDepositEndDate | date:'MM/dd/yyyy' }}"
								[bsConfig]="{ containerClass: 'theme-default' }"
								id="spouseMonthlyDepositEndDate"  [disabled]="(this.hasSpouse == false || isPresentedToClient)">
							</div>
						</div>	  
						 
						<br/>
						<div class="form-group row">
							<label for="LTCoption" class="col-sm-4 col-form-label">Does this instrument have a LTC function?</label>
							<div class="col-sm-1">
								<input #ltcNo type="radio" name="HasLTC" value="false"  formControlName="HasLTC"    [checked]="(this.bhasLTC == 0 )"
									(click)="radioLTCClick(ltcNo.value)" > <label for="No" class="col-sm-1 col-form-label"> No </label> </div>	
									 <div class="col-sm-1"> 
							 	<input #ltcYes type="radio" name="HasLTC" value="true"  formControlName="HasLTC"     [checked]="(this.bhasLTC == 1 )"
								 	(click)="radioLTCClick(ltcYes.value)" > <label for="Yes" class="col-sm-1 col-form-label"> Yes</label>  	
									</div>					
						</div>

						<div *ngIf="showLTC"> 
							<div class="form-row">
								<label for="input" class="col-sm-4 col-form-label">Waiting period before LTC can be turned on?</label>
								<div class="form-group  col-sm-4">
									<select class="form-control bold" id="LTCWaitingPeriod"
										[(ngModel)]="LTCWaitingPeriod" formControlName="LTCWaitingPeriod"> >
										<option *ngFor="let item of startyearlist" [ngValue]="item.value">
											{{item.text}}
										</option>
									</select>
								</div>
								<div class="col">
									<label for="months" class="col-sm-2 col-form-label"> month</label>
								</div>  
							</div>

							<div class="form-row">
								<label for="input" class="col-sm-4 col-form-label">When should LTC benefit begin?</label>
								<div class="form-group  col-sm-4">
									<select class="form-control bold" id="LTCBegin"
										[(ngModel)]="LTCBegin" formControlName="LTCBegin"> >
										<option *ngFor="let item of startyearlist" [ngValue]="item.value">
											{{item.text}}
										</option>
									</select>
								</div>
								<div class="col">
									<label for="months" class="col-sm-2 col-form-label"> month</label>
								</div>  
							</div>

							<div class="form-row">
								<label for="input" class="col-sm-4 col-form-label">When should LTC benefit end?</label>
								<div class="form-group  col-sm-4">
									<select class="form-control bold" id="LTCEnd"
										[(ngModel)]="LTCEnd" formControlName="LTCEnd"> >
										<option *ngFor="let item of startyearlist" [ngValue]="item.value">
											{{item.text}}
										</option>
									</select>
								</div>
								<div class="col">
									<label for="months" class="col-sm-2 col-form-label"> month</label>
								</div>  
							</div>

							<div class="form-row">
								<label for="input" class="col-sm-4 col-form-label">How much does the payment increase?</label>
								<div class="form-group col-sm-2">
									<input type="text" class="form-control bold" id="LTCMultiplier" [(ngModel)]="LTCMultiplier"
										formControlName="LTCMultiplier"  >
								</div>
								<div class="col">
									<label for="LTC multiplier" class="col-sm-4 col-form-label"> LTC multiplier</label>
								</div>  
							</div>
						</div> 

						<div class="form-row">
							<div class="form-group col-md-0">
								<button type="button" class="btn btn-primary btn-sm"
									(click)="saveBtnClickEvent()" [disabled]="isPresentedToClient"> 
									<i class="far fa-save"></i> Save</button>
							</div>
							<div class="form-group col-md-0">
								<button type="button" class="btn btn-primary btn-sm"
									(click)="cancelBtnClickEvent()" [disabled]="isPresentedToClient"> <i class="far fa-times"></i> Cancel</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>