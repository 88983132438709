import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../util/localstorage.service'; 
import { Constants } from '../util/constants.js';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Agent } from '../models/agent.model';
import { IndexBlend, IndexBlendDetail } from '../models/index-blend.model';
import { AlertService } from '../util/alert/alert.service';
import { CommonFunctions } from '../util/common-functions';
import { GridOptions } from 'ag-grid-community'
import { CustomHeader} from '../util//customHeader'
import { IndexBlendDataService } from './index-blend-data.service';
import { BsModalService,BsModalRef}   from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { SharedServiceService } from '../util/shared-service.service';
  
@Component({
  selector: 'app-index-blend',
  templateUrl: './index-blend.component.html',
  styleUrls: ['./index-blend.component.scss']
})

export class IndexBlendComponent implements OnInit {
  
  public paddingtop: number = 60;
  public selectedChoiceID: string = "";
  public indexblenddetails: Array<any>
  public id: any
  public copiedid: any
  public rebalancefrequency: any = "Monthly"
  public name:any
  public ismyindexblend:any
  public indexcount:any
  public index:any
  public selectedIndexes: any
  public selectedindexid: any
  public indexlist: Array<any>
  public previousBeginDate
  public createdby:any
  public createdon:any
  public begindate:any
  public loggedAgent: Agent 
  public updateMode :boolean
  public copyMode :boolean
  public deleteMode: boolean
  public change: boolean
  public activeTabName :any 
  public iSForm: FormGroup;
  public total: number
  public totalPercentatge: number
  public commonDate:any
  private hasSpouse:boolean
  public IndexBlend:IndexBlend
  public IndexBlendDetail:IndexBlendDetail
  private gridApi: any;
  private gridApi2: any;
  private gridColumnApi: any;
  private gridColumnApi2: any;

  public columnDefs: any;
  public columnDefs2: any;
  public rowData: any;	
  public rowData2: any;	
  public gridOptions : GridOptions 
  public gridOptions2 : GridOptions 
  public currentSelectedColumn;
  public strategyDataValueList = []
  public invalidDataVal = false
  public isShowDashboard: boolean = false
  private commonFunctions: CommonFunctions = new CommonFunctions
  orderID :number = 0  

  @ViewChild('editIndexDetails') editIndexDetails: TemplateRef<any>
  public ModalRef : BsModalRef 
  getRowStyle: (params: any) => { "font-weight": string; };
  pinnedBottomRowData: any;
  frameworkComponents: { customPinnedRowRenderer: any; };

  constructor( private router: Router,
    private formBuilder: FormBuilder, private alertService: AlertService, private indexblendsService: IndexBlendDataService,
    private bsModalService :BsModalService,
    private sharedService: SharedServiceService) { 

      this.loggedAgent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent;

      this.gridOptions = { 
        components: {
          agColumnHeader: CustomHeader
        },
        defaultColDef: { 
          headerComponentParams: {
            setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
            getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          } 
        } 
      };

      this.columnDefs = [
        { 
          headerName: 'ID', 
          field: 'Id',
          hide: true
          //sortable: true, 
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Name', 
          field: 'IndexBlendName',
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Index Count', 
          field: 'IndexCount',
          cellStyle: {textAlign: "left"} 
          // sortable: true, 
          // width: 125,
          // filter: true,
          // cellClass: "rag-blue"
        },
        { 
          headerName: 'Rebalance Frequency', 
          field: 'RebalanceFrequency',
          cellStyle: {textAlign: "left"} 
          // sortable: true, 
          // width: 125,
          // filter: true,
          // cellClass: "rag-blue"
        },
        { 
          headerName: 'Begin Date', 
          field: 'BeginDate',
          cellStyle: {textAlign: "left"}//,  
          // sortable: true, 
          // filter: true,
          // cellClass: "rag-yellow"
        },
        { 
          headerName: 'Created By', 
          field: 'CreatedBy',
          hide: true,  
         // sortable: true, 
         // filter: true,
         // cellClass: "rag-yellow"
        },
        { 
          headerName: 'Created On', 
          field: 'CreatedOn',
          hide: true,  
         // sortable: true, 
         // filter: true,
         // cellClass: "rag-yellow"
        }
      ];



      this.gridOptions2 = { 
        components: {
          agColumnHeader: CustomHeader
        },
        defaultColDef: { 
          headerComponentParams: {
            setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
            getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          } 
        } 
      };

      this.columnDefs2 = [
        { 
          headerName: 'IndexBlendDetailID', 
          field: 'IndexBlendDetailID',
          hide: true,
          //sortable: true, 
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'IndexBlendID', 
          field: 'IndexBlendID',
          hide: true
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'IndexID', 
          field: 'IndexID',
          hide: true
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Name', 
          field: 'IndexName',
          cellStyle:  function(params) {
            if (params.node.rowPinned){
              return {backgroundColor: "#dcddde", textAlign: "left"}
            }else{
              return {textAlign: "left"}
            }
          },
          // sortable: true, 
          //width: 50,
          // filter: true,
          // cellClass: "rag-blue"
        },
        { 
          headerName: 'Begin Date', 
          field: 'IndexBeginDate',
          cellStyle:  function(params) {
            if (params.node.rowPinned){
              return {backgroundColor: "#dcddde", textAlign: "left"}
            }else{
              return {textAlign: "left"}
            }
          },  
          // sortable: true, 
          // filter: true,
          // cellClass: "rag-yellow"
        },
        { 
          headerName: 'Allocation', 
          field: 'Allocation',
          cellStyle:  function(params) {
            if (params.node.rowPinned){
              return {backgroundColor: "#dcddde", textAlign: "right"}
            }else{
              return {textAlign: "right"}
            }
          },
          valueFormatter: function(params) {
            return params.value + '%'; 
          },
          editable: function(params) {
            if (params.node.rowPinned){
              return false;
            }else{
              return true;
            }
          }
          
        }
      ];

      this.getRowStyle = function(params) {
        if (params.node.rowPinned) {
          return { "font-weight": "bold"};
        }
      };

    }

    onPinnedRowBottomCount() {
      var footerRowsToFloat = 10;
      var count = Number(footerRowsToFloat);
      var rows = createData(count, "Bottom", this.total, this.totalPercentatge, this.begindate);
      setTimeout(()=> {
      this.gridApi2.setPinnedBottomRowData(rows);
    }, 50); 
    }

  ngOnInit() {

   this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
   if(this.loggedAgent.UserRoleID== Constants.UserRoleID.Superuser)
    this.isShowDashboard = true
   this.iSForm = this.formBuilder.group({
			name: new FormControl(null) ,
      indexes: new FormControl(null),
      begindate: new FormControl(null),
      ismyindexblend: new FormControl(null),
      rebalancefrequency: new FormControl(null)
    } );   
 
	
    this.loadData()
  }

  loadData()
	{
		this.ismyindexblend = JSON.parse(localStorage.getItem(LocalStorage.ISMYINDEXBLEND))
		this.indexblendsService.getindexblends(this.loggedAgent, this.ismyindexblend).subscribe(data => this.getData(data))
	}

  public getData(data: any) {
    let indexblends: Array<any>=[] 
    data.forEach((element: any) => {
      //let client: Client = element
      let IndexBlend: IndexBlend = element
      IndexBlend.IndexBlendID = element.IndexBlendID
      IndexBlend.IndexBlendName = element.IndexBlendName
      IndexBlend.IndexCount = element.IndexCount
      IndexBlend.RebalanceFrequency = element.RebalanceFrequency
      var datePipe = new DatePipe('en-US');
      IndexBlend.BeginDate = datePipe.transform(element.BeginDate, 'MM/dd/yyyy');
      IndexBlend.IsMyIndexBlend = element.IsMyIndexBlend
      IndexBlend.CreatedBy = element.CreatedBy
      IndexBlend.CreatedOn = element.CreatedOn

      indexblends.push({Id: IndexBlend.IndexBlendID, IndexBlendName: IndexBlend.IndexBlendName ,IndexCount: IndexBlend.IndexCount,
        RebalanceFrequency: IndexBlend.RebalanceFrequency, BeginDate: IndexBlend.BeginDate, IsMyIndexBlend: IndexBlend.IsMyIndexBlend,
      CreatedBy: IndexBlend.CreatedBy, CreatedOn: IndexBlend.CreatedOn})

    });
    setTimeout(()=> {
      this.gridApi.forEachNode(node => {
        if ( node.rowIndex  === 0 ){ 
          node.setSelected(true)
          this.id = node.data.Id
          this.name = node.data.IndexBlendName
          this.rebalancefrequency = node.data.RebalanceFrequency
          this.indexcount = node.data.IndexCount
          this.begindate = node.data.BeginDate
          this.previousBeginDate = node.data.BeginDate
          this.createdby = node.data.CreatedBy
          this.createdon = node.data.CreatedOn
          this.updateMode = true
          this.deleteMode = false
          this.copyMode = false
          this.copiedid = ""
          if(this.id != "" && this.id != undefined){
            this.indexblendsService.getindexblenddetailsbyid(this.id, this.loggedAgent).subscribe(data => this.getIndexBlendDetails(data))
          }
        } 
      }); 
    
    }, 50); 

    
    this.rowData = indexblends;
    this.initializeIndexBlends();
    this.getIndexList()
    return this.rowData;
  }

  public getIndexBlendDetails(data: any) {
    this.total = 0
    this.totalPercentatge = 0
    this.gridOptions2.api.showLoadingOverlay()
    this.getIndexList()
    let indexblendDetails: Array<any>=[] 
    data.forEach((element: any) => {
      let IndexBlendDetail: IndexBlendDetail = element
      IndexBlendDetail.IndexBlendDetailID = element.IndexBlendDetailID
      IndexBlendDetail.IndexBlendID = element.IndexBlendID
      IndexBlendDetail.IndexID = element.IndexID
      this.indexlist.forEach((index: any) => {
        if(index.IndexID == IndexBlendDetail.IndexID){

          IndexBlendDetail.IndexName = index.IndexName
          var datePipe = new DatePipe('en-US');
          IndexBlendDetail.IndexBeginDate = datePipe.transform(index.BeginDate, 'MM/dd/yyyy');

          this.selectedindexid = index.IndexID
        }
      })
      IndexBlendDetail.Allocation = element.Allocation
      this.totalPercentatge = this.totalPercentatge + parseInt(element.Allocation)
      

      indexblendDetails.push({IndexBlendDetailID: IndexBlendDetail.IndexBlendDetailID, IndexBlendID: IndexBlendDetail.IndexBlendID ,
        IndexID: IndexBlendDetail.IndexID, IndexName: IndexBlendDetail.IndexName, IndexBeginDate: IndexBlendDetail.IndexBeginDate
        , Allocation: IndexBlendDetail.Allocation})   

    });
    
    setTimeout(()=> {
      this.gridApi2.forEachNode(node => {
        if ( node.rowIndex  === 0 ){ 
          node.setSelected(true)
        } 
      }); 
    
    }, 50);
    this.gridOptions2.api.hideOverlay()
    if(indexblendDetails.length != 0){
      if(indexblendDetails[indexblendDetails.length - 1].IndexName == undefined){
        this.gridOptions2.api.showLoadingOverlay()
        if(this.id != "" && this.id != undefined){
          this.indexblendsService.getindexblenddetailsbyid(this.id, this.loggedAgent).subscribe(data => this.getIndexBlendDetails(data))
        }
      } else{
        this.gridOptions2.api.hideOverlay()
        this.rowData2 = indexblendDetails;
        if(this.rowData2 != null && this.rowData2 != undefined){
        this.total = this.rowData2.length
        this.getCommonDate()
        this.pinnedBottomRowData = createData(1, "Bottom", this.total, this.totalPercentatge, this.commonDate);
        this.gridOptions2.api.setRowData(this.rowData2); 
        this.gridApi2.setPinnedBottomRowData(this.pinnedBottomRowData);
        }  
      }
    }
    setTimeout(() => {this.gridApi.sizeColumnsToFit();});
  }
  

  public getIndexList(){
    this.indexblendsService.getindexes(this.loggedAgent).subscribe(data => 
			{ 
        this.indexlist = data   
			},
		error => {
				this.alertService.clear()
				this.alertService.error('Error occured while retrieving data')
				}); 
  }

  public addIndex() {
    if(this.selectedIndexes != "" && this.selectedIndexes != undefined){
      this.alertService.clear()
      let data = this.selectedIndexes
      var datePipe = new DatePipe('en-US');
      let BeginDate = datePipe.transform(data.BeginDate, 'MM/dd/yyyy');
      let i: number = 0;
      let add: boolean = true
      if(this.rowData2 == undefined){
        this.rowData2 = []
      }
      for(i = 0; i < this.rowData2.length; i++){
        if(this.rowData2[i].IndexID == this.selectedIndexes.IndexID){
            this.alertService.error('Index already exist')
            add = false
            this.selectedIndexes = ""
            break
        }
      }

      if(add){
        this.rowData2.push({IndexBlendDetailID: this.commonFunctions.getnewguid() , IndexBlendID: this.id ,
          IndexID: data.IndexID, IndexName: data.IndexName, IndexBeginDate: BeginDate
          , Allocation: 0})
        this.getIndexList()
        this.total = this.rowData2.length
        this.totalPercentatge  = this.totalPercentatge + 0
        this.getCommonDate()
        this.begindate = this.commonDate
      }
      
      setTimeout(()=> {
        this.getCommonDate()
        this.pinnedBottomRowData = createData(1, "Bottom", this.total, this.totalPercentatge, this.commonDate);
        this.gridOptions2.api.setRowData(this.rowData2);
        this.gridApi2.setPinnedBottomRowData(this.pinnedBottomRowData);
        this.total = this.rowData2.length
        this.gridApi2.forEachNode(node => {
          if ( node.rowIndex  === 0 ){ 
            node.setSelected(true)
            this.selectedindexid = node.data.IndexID
          } 
        }); 
      
      }, 50); 
      this.selectedIndexes = []
      this.getIndexList()
    }
  }

public getCommonDate(){
  let date = ""
  this.commonDate = ""
  this.rowData2.forEach((element: any) =>{
    let newDate = null
    newDate = new Date(element.IndexBeginDate);
    if(newDate > date){
      date = newDate
    }
  })
  var datePipe = new DatePipe('en-US');
  this.commonDate = datePipe.transform(date, 'MM/dd/yyyy');
}

public deleteIndexes(){
  this.invalidDataVal = false
  this.totalPercentatge = 0
  this.rowData2.forEach((element: any) =>{
    if(element.IndexID == this.selectedindexid){
      this.rowData2.splice(this.rowData2.indexOf(element),1)
    }
  })
  this.gridOptions2.api.setRowData(this.rowData2); 
  this.total = this.rowData2.length
  setTimeout(()=> {
    this.gridApi2.forEachNode(node => {
      this.selectedindexid = node.data.IndexID
      this.selectedIndexes = ""
      this.totalPercentatge = this.totalPercentatge + parseInt(node.data.Allocation)
    }); 
    this.getCommonDate()
    this.begindate = this.commonDate
    this.pinnedBottomRowData = createData(1, "Bottom", this.total, this.totalPercentatge, this.commonDate);
    this.gridOptions2.api.setRowData(this.rowData2); 
    this.gridApi2.setPinnedBottomRowData(this.pinnedBottomRowData);

    this.gridApi2.forEachNode(node => { 
      if ( node.rowIndex  === 0 ){
        node.setSelected(true)   
        this.selectedindexid = node.data.IndexID
      }
      if(!node.data.Allocation.toString().match(/^[+\-]?(\.\d+|\d+(\.\d+)?)$/)){
        this.invalidDataVal = true
      }
      
    }); 
  }, 50);

}

  initializeIndexBlends()
  {
    this.id = ""
    this.name = ""
    this.indexcount = ""
    this.rebalancefrequency = "Monthly"
    this.begindate = ""  
    this.createdby = "" 
    this.createdon = "" 
    this.indexlist = []
    this.selectedIndexes = ""
    this.totalPercentatge = 0
    this.total = 0
    this.pinnedBottomRowData = createData(1, "Bottom", this.total, this.totalPercentatge, null);
    if(this.gridApi2 != undefined){
      this.gridApi2.setPinnedBottomRowData(this.pinnedBottomRowData);
    }
  this.updateMode = false 
  }

  onGridReady(params: any) { 
		this.gridApi  = params.api;
		this.gridColumnApi = params.columnApi; 
		setTimeout(() => { 
        this.loadData()     
      	//params.api.sizeColumnsToFit();
    }, 50);

  }

  onGridReady2(params: any) { 
		this.gridApi2  = params.api;
		this.gridColumnApi2 = params.columnApi; 
  }

  onBeginDateChange(event){
    this.begindate = event
  }

  getCurrentSelectedColumn() {
    return this.currentSelectedColumn;
    }
    
  setCurrentSelectedColumn(colId) {
    this.currentSelectedColumn = colId;
    }


  loadIndexBlendsStaticDataForNew()
  {   
    this.initializeIndexBlends()   
  }

  gridSizeChanged(params: any) {
		params.api.sizeColumnsToFit();
  }

  public onIndexClicked(event: any){
    event.node.setSelected(true);
    this.selectedindexid = event.data.IndexID
  }
  
  public onCellClicked(event: any) {
    this.invalidDataVal = false
    this.alertService.clear()
    if(event.data.Id == ""){
      this.indexblendsService.getindexblenddetailsbyid(this.copiedid, this.loggedAgent).subscribe(data => this.getIndexBlendDetails(data))
    }
    if(event.data.IndexBlendName == ""){
      this.rowData2 = []
      this.gridOptions2.api.setRowData(this.rowData2);
      this.loadIndexBlendsStaticDataForNew()
    }
    this.updateMode = true; 
    this.copyMode = true; 
    this.deleteMode = false; 
    if(event.data.Id != "" && event.data.Id != undefined){
      this.indexblendsService.getindexblenddetailsbyid(event.data.Id, this.loggedAgent).subscribe(data => this.getIndexBlendDetails(data))
    }
    event.node.setSelected(true);
    var selectedIndexBlendID : string = event.data.Id
    var selectedIndexBlendName : string = event.data.IndexBlendName
    var selectedIndexBlendIndexCount : string = event.data.IndexCount
    var selectedIndexBlendRebalanceFrequency : string = event.data.RebalanceFrequency
    var selectedIndexBlendBeginDate : string = event.data.BeginDate
    var selectedIndexBlendIsMyIndexBlend : string = event.data.IsMyIndexBlend
    var selectedIndexBlendCreatedBy : string = event.data.CreatedBy
    var selectedIndexBlendCreatedOn : string = event.data.CreatedOn
    this.loadIndexBlend(selectedIndexBlendID,selectedIndexBlendName,selectedIndexBlendIndexCount, selectedIndexBlendRebalanceFrequency, selectedIndexBlendBeginDate,
      selectedIndexBlendIsMyIndexBlend, selectedIndexBlendCreatedBy,selectedIndexBlendCreatedOn) 
  }

  loadIndexBlend(selectedIndexBlendID:string,selectedIndexBlendName:string,selectedIndexBlendIndexCount:string,selectedIndexBlendRebalanceFrequency:string,selectedIndexBlendBeginDate:string,
    selectedIndexBlendIsMyIndexBlend:string,selectedIndexBlendCreatedBy:string,selectedIndexBlendCreatedOn:string)
  {
    this.id = selectedIndexBlendID;
    this.name = selectedIndexBlendName;
    this.indexcount = selectedIndexBlendIndexCount;
    this.rebalancefrequency = selectedIndexBlendRebalanceFrequency;
    this.begindate = selectedIndexBlendBeginDate;
    this.previousBeginDate = selectedIndexBlendBeginDate
    this.createdby = selectedIndexBlendCreatedBy;
    this.createdon = selectedIndexBlendCreatedOn;
  }

  searchinarray(nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
     
        if (myArray[i].value === nameKey) {
          //console.log(myArray[i].value)
            return myArray[i].value;
        }
    }
}

saveBtnClickEvent()
{  
  if (this.validateIndexBlend()){
    //if another row is clicked after adding a new row or copied a row
    if(this.id == "" || this.createdby == "" || this.createdon == ""){
      this.id =  this.commonFunctions.getnewguid() 
      this.createdby = this.loggedAgent.AgentID
      this.createdon = new Date()
    }
    this.indexblenddetails =[]
    this.rowData2.forEach((element: any) =>{
      this.IndexBlendDetail = new IndexBlendDetail()
      this.IndexBlendDetail.IndexBlendDetailID = this.commonFunctions.getnewguid()
      this.IndexBlendDetail.IndexBlendID = this.id
      this.IndexBlendDetail.IndexID = element.IndexID
      this.IndexBlendDetail.Allocation = element.Allocation
      this.indexblenddetails.push(this.IndexBlendDetail)
    })

    this.IndexBlend = new IndexBlend()
    this.IndexBlend.IndexBlendName = this.name
    this.IndexBlend.IndexCount = this.indexcount
    this.IndexBlend.RebalanceFrequency = this.rebalancefrequency
    this.IndexBlend.BeginDate =  new Date(this.begindate).toLocaleDateString() 
	  this.ismyindexblend = JSON.parse(localStorage.getItem(LocalStorage.ISMYINDEXBLEND))
    this.IndexBlend.IsMyIndexBlend = this.ismyindexblend
    this.IndexBlend.IndexBlendDetail = this.indexblenddetails
    if(this.updateMode){
      this.IndexBlend.IndexBlendID =  this.id
      this.IndexBlend.CreatedBy = this.createdby
      this.IndexBlend.CreatedOn = this.createdon
    } else{
      this.IndexBlend.IndexBlendID =  this.id
      this.IndexBlend.CreatedBy = this.loggedAgent.AgentID
      this.IndexBlend.CreatedOn = new Date()
    }
  
  if(this.deleteMode)
  {
    this.IndexBlend.bDeleted = true
    this.IndexBlend.DeletedBy = this.loggedAgent.AgentID
    this.IndexBlend.DeletedOn = new Date()
  } else{
    this.IndexBlend.bDeleted = false
  }
  this.indexblendsService.saveIndexBlend(this.IndexBlend, this.loggedAgent).subscribe(date =>
    {
      this.alertService.clear()
      if(this.updateMode && !this.deleteMode){
        this.alertService.success('Successfully updated')
      } else if(this.deleteMode){
        this.alertService.success('Successfully deleted')
      } else{
        this.alertService.success('Successfully saved')
      }
      
      this.loadData()
      this.indexblenddetails = []
    },
    error => { 
      this.alertService.clear()
      this.alertService.error('Error occured while saving data')
      this.loadData()
     });
    }
}

cancelBtnClickEvent()
{
  this.alertService.clear()
  this.invalidDataVal = false
  this.id = ""
  this.loadIndexBlendsStaticDataForNew()
  this.rowData2 = []
  this.selectedIndexes = []
  this.getIndexList()
  this.gridOptions.api.deselectAll();
}

validateIndexBlend():boolean
  { 
    if(!this.deleteMode){
      this.alertService.clear()
      if ((this.iSForm.controls.name.value == ""  || 
        this.iSForm.controls.name.value == null))
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Name')
        return false
      } 
      if (this.rowData2.length == 0)
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Index count')
        return false
      }
      var i
      for(i = 0; i < this.rowData2.length; i++){
        if (this.rowData2[i].Allocation == 0)
        { 
          this.alertService.clear()
          this.alertService.error('Invalid Allocation')
          return false
        }
      }
      if ((this.iSForm.controls.rebalancefrequency.value == ""  || 
            this.iSForm.controls.rebalancefrequency.value == null ))
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Rebalance Frequency')
        return false 
      }     
  
      if ((this.iSForm.controls.begindate.value == ""  || 
      this.iSForm.controls.begindate.value == null ))
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Begin Date')
        return false
      }
    }

    return true
  }  
  
  exitBtnClickEvent()
  {
    if(JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) != null){
      this.sharedService.changeSideBarRequest()   
			this.router.navigateByUrl('home')
    } else{
      this.router.navigateByUrl('landing'); 
    }
    
  }

  //load the superuser dashboard page
	public navigateSuperUserDashboard()
	{
		this.router.navigateByUrl('superuserdashboard'); 
	}

  newBtnClickEvent()
  { 
    this.invalidDataVal = false
    this.alertService.clear()
    if(this.rowData.length != 0){
      let node
      node = this.rowData[this.rowData.length - 1]
      if(node.CreatedOn ==""){
        this.rowData.splice(this.rowData.indexOf(node),1)
      } 
    } 
    this.loadIndexBlendsStaticDataForNew()
    this.id = this.commonFunctions.getnewguid()
    this.rowData.push({Id: this.id, IndexBlendName: "" ,IndexCount: "", RebalanceFrequency: "",
      BeginDate: "", IsMyIndexBlend: "", CreatedBy: "", CreatedOn: ""})
    this.gridOptions.api.setRowData(this.rowData);
    this.gridApi.forEachNode(node => {
        node.setSelected(true)          
    }); 
    this.deleteMode = false   
    this.updateMode = false
    this.getIndexList()
    this.rowData2 = []
    this.gridOptions2.api.setRowData(this.rowData2);
  }

  copyBtnClickEvent()
  {
    this.invalidDataVal = false
    this.alertService.clear()
    this.copiedid = ""
    if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return
      this.updateMode = false
      if(this.rowData.length != 0){
        let node
        node = this.rowData[this.rowData.length - 1]
        if(node.CreatedOn ==""){
          this.rowData.splice(this.rowData.indexOf(node),1)
        } 
      } 
      if(this.id != "" && this.id != undefined){
        this.copiedid = this.id
        this.indexblendsService.getindexblenddetailsbyid(this.id, this.loggedAgent).subscribe(data => this.getIndexBlendDetails(data))
      }
      this.id = this.commonFunctions.getnewguid()
      var datePipe = new DatePipe('en-US');
      
      this.rowData.push({Id: "", IndexBlendName: this.name ,IndexCount: this.indexcount, RebalanceFrequency: this.rebalancefrequency,
      BeginDate: datePipe.transform(this.begindate, 'MM/dd/yyyy'), IsMyIndexBlend: "", CreatedBy: "", CreatedOn: ""})
      this.gridOptions.api.setRowData(this.rowData); 
      this.gridApi.forEachNode(node => {
        node.setSelected(true)          
    });
  }

  deleteBtnClickEvent()
  {     
    this.invalidDataVal = false
    this.alertService.clear()
    if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return    
    if (confirm("Are you sure you want to delete selected record?")) {  
      if(this.rowData.length != 0){
        let node
        node = this.rowData[this.rowData.length - 1]
        if(node.CreatedOn ==""){
          this.rowData.splice(this.rowData.indexOf(node),1)
          this.gridOptions.api.setRowData(this.rowData);
          this.loadData()
        } else{
          this.deleteMode = true
          this.updateMode = true
          this.saveBtnClickEvent()
        }
      }   
      this.rowData2 = []
      this.gridOptions2.api.setRowData(this.rowData2);
    }
  }

  editBtnClickEvent()
  {
    
    this.ModalRef = this.bsModalService.show(this.editIndexDetails)
  }

  onCellValueChanged(event: any)
  {
    this.alertService.clear()
    if(event.newValue.match(/^[+\-]?(\.\d+|\d+(\.\d+)?)$/)){ 
      if(event.oldValue.toString().match(/^[+\-]?(\.\d+|\d+(\.\d+)?)$/)){
        this.totalPercentatge = this.totalPercentatge - parseInt(event.oldValue) 
      }
      this.totalPercentatge = this.totalPercentatge + parseInt(event.newValue)  
      this.pinnedBottomRowData = createData(1, "Bottom", this.total, this.totalPercentatge, this.commonDate);
      this.gridOptions2.api.setRowData(this.rowData2); 
      this.gridApi2.setPinnedBottomRowData(this.pinnedBottomRowData);
      setTimeout(()=> { 
        this.gridApi2.forEachNode(node => {
          if ( node.rowIndex  === 0 ){ 
            node.setSelected(true)
          } 
        }); 
      
      }, 50);
      this.invalidDataVal = false
    } else{
      this.alertService.clear()
      this.invalidDataVal = true
      this.alertService.error('Invalid value')
    }

    setTimeout(()=> { 
        this.gridApi2.forEachNode(node => {
            if(!node.data.Allocation.toString().match(/^[+\-]?(\.\d+|\d+(\.\d+)?)$/)){
              this.invalidDataVal = true
            }
        }); 
      
      }, 50);
  }
}
function createData(count, prefix, total, totalPercentatge, commonDate) {
  var result = [];
  if(commonDate == null){
    var datePipe = new DatePipe('en-US');
    commonDate = datePipe.transform(new Date(), 'MM/dd/yyyy');
  }
  var datePipe = new DatePipe('en-US');
  var today = datePipe.transform(new Date(), 'MM/dd/yyyy');
  for (var i = 0; i < count; i++) {
    result.push({
      IndexName: total + " Allocations: " + commonDate + " - " + today,
      Allocation:  totalPercentatge
    });
  }
  return result;
}