<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg">
	<app-header></app-header>
</nav>
<div class="main-panel pt-0 pb-4">
	<div class="container-fluid" #main [style.padding-top.px]="paddingtop">
		<div class="col-sm-12 px-0 align-self-end">
			<div class="row align-items-center">
				<div class="col-sm-6">
					<h3 class="light-header bold">
						{{ 'LANDING.LBL_TITLE' | translate}}
					</h3>
				</div>
				<div class="col-sm-6 text-right">
					
					<button type="button" class="btn btn-primary btn-sm mr-1 at-right " (click)="createNewClient()">
						{{ 'LANDING.BTN_NEW_DOCUMENT' | translate }}
					</button> 
					<button *ngIf="isShowFP4FA" type="button" class="btn btn-primary btn-sm " (click)="loadFP4FA()">
						{{ 'FP4FA.LBL_TITLE' | translate }}
					</button>
					<button type="button" class="btn btn-primary btn-sm ml-1" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
						{{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
					</button>	
				</div> 
			</div>
			<hr class="mt-0 mb-3" />
		</div>
		<!-- comment all table related logics, because user may need different kind of UI element in this page -->
		<!-- <div id="grid-wrapper" class="col-sm-12 px-0">
			<ag-grid-angular style="width: 100%; height: 200px;" class="ag-theme-balham" [rowData]="rowData"
				[columnDefs]="columnDefs" (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)">
			</ag-grid-angular>
		</div> -->
		<div class="col-sm-12 px-0">
			<div class="form-group mx-3">
				<input type="text" class="form-control bold" id="searchClients" placeholder="Search by client name"
					[(ngModel)]="searchTerm">
			</div>
			<div class="card-deck">
				<div *ngFor="let client of clientList | clientFilter:searchTerm; let i=index" class="col-sm-3 mt-1">
					<div class="card" (click)="sampleClickEvent(client)">
						<div class="card-body p-1">
							<h5 class="m-0  bold">
								{{ client.ClientLastName + ' ' + client.ClientFirstName }}
							</h5>
							<p class="text-muted my-1 bold">
								{{ client.SpouseLastName + ' ' + client.SpouseFirstName }}
							</p>  
							
						</div> 
						<div style=" text-align: right;margin-right: 5px; margin-bottom: 5px;"  >
							<!-- <i class="fas fa-trash-alt" aria-hidden="true" (click)="deleteClickEvent(client)"></i> -->
							<button type="button"   container="body" id="delete" tooltip ="Delete"
							  class="btn btn-outline-primary btn-myxs"  (click)="deleteClickEvent($event,client)" >
							<i class="fas fa-trash-alt" aria-hidden="true"></i>
						</button>
						</div>
					</div> 
					
				</div>
			</div>
		</div>
	</div>
</div>