export class IncomeForLife {
    constructor(
        public ChoiceID?: string,
        public DisplayOrder?: Number,
        public IncomeID?: string,
        public Owner?: string,
        public SourceType?: string,
        public Source?: string,
        public AssetType?:  Number,
        public StartYear?: string,
        public EndYear?: string,
        public COLAFlag?: boolean,
        public COLAPct?: Number,
        public SurvivorBenefitFlag?: string,
        public SurvivorBenefitPct?: Number,
        public Amount?: Number,
        public PayoutPct?: Number,
        public StartDate?: string,
        public EndDate?: string,
        public HasLTC?: boolean,
        public LTCNotAvailableFor?: Number,
        public LTCMultiplier?: Number,
        public LTCPeriod ?: Number,
        public ClientMonthlyDeposit ?: Number,
        public ClientMonthlyDepositStartDate?: Date,
        public ClientMonthlyDepositEndDate?: Date,
        public SpouseMonthlyDeposit ?: Number,
        public SpouseMonthlyDepositStartDate?: Date,
        public SpouseMonthlyDepositEndDate?: Date,

        public Deposit?: string,
        public BonusFlag?: boolean,
        public BonusPct?: Number,
        public RollUpHow?: string,
        public RollUpPct?: Number,
        public MonthsOfDeferral?: string,// Even though this named as YearsOdDeferral it contains MonthsOfDeferral
    ) {}
}