import {
	Component,
	OnInit,
	ElementRef,
	ViewChild, 
	SimpleChanges,
	ViewContainerRef,
  ComponentRef,
  ComponentFactoryResolver,
  OnDestroy,
  TemplateRef
} from '@angular/core';
import { Router } from '@angular/router'; 
import { Constants } from '../../util/constants'; 
import { LocalStorage } from '../../util/localstorage.service'; 
import { Agent } from '../../models/agent.model'; 
import Stepper from 'bs-stepper';
import { TabsetComponent } from 'ngx-bootstrap/tabs'; 
 
import { CalculatorComponent } from '../calculator/calculator.component';
import { BusinessCalculatorInput, FinancialProductDetail, OtherRevenuePerYear, RepDataAddtionalChannel, EmployeeDetail, Employee, EmployeeCount } from '../../models/business-calculator-input.model';
import { BusinessCalculatorOutput } from '../../models/business-calculator-output.model';
import { DataPerYear } from '../../models/business-calculator-output.model';
import { RepData } from '../../models/business-calculator-input.model';
import { RepDataPerYear } from '../../models/business-calculator-input.model';
import { PercentageOfNetIncomePerYear } from '../../models/business-calculator-input.model';
import { AlertService } from 'src/app/util/alert/alert.service';
import { CommonFunctions } from '../../util/common-functions';
import { FP4FADataService } from '../fp4fa-data.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms'; 
import { SharedServiceService } from '../../util/shared-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BusinessCalculatorDefaults } from 'src/app/models/business-calculator-defaults.model';
import { ConditionalExpr } from '@angular/compiler';
import { FP4FACommonFunctionsService } from '../fp4fa-common-functions.service';

@Component({
  selector: 'app-marketing-production',
  templateUrl: './marketing-production.component.html',
  styleUrls: ['./marketing-production.component.scss']
})


export class MarketingProductionComponent implements OnInit  {

//#region "Variable declaration"
  name = 'Angular';
  public isSideBar: boolean = true
  public modalRef: BsModalRef;
  public loggedAgent: Agent 
  public businesscalculatorinput: BusinessCalculatorInput 
  public businesscalculatoroutput: BusinessCalculatorOutput
  public yeardatalist: Array<DataPerYear> = []
  public repdatalist:Array<RepData> = []
  public selectedRepData:RepData
  public selectedRepDataPerYear:RepDataPerYear
  public bHaveReps : boolean = false
  public selectedRepID:any
  public selectedYear:any
  public repdataForm: FormGroup;
  public fillAcrossForm: FormGroup;
  public fillDownForm: FormGroup;
  public financialProductsForm: FormGroup;
  public otherexpensesForm: FormGroup;
  public currentStepperIndex: any   
  public currentStepperName:any  
  public saveactionshow :boolean = true
  public currentRowType: any

  //for repData form controls
  public marketingchannelsArray:Array<any> = []
  public marketingChannel: any
  public workshops : boolean = true

  //for repData workshops controls
  public repFirstName :any
  public repLastName :any
  public baseSalary :any
  public repStartDate:any
  public repEndDate:any
  public repPayPercentage:any
  public bOwner: any
  public classPerMonthJan : any
  public classPerMonthFeb : any
  public classPerMonthMar : any
  public classPerMonthApr : any
  public classPerMonthMay : any
  public classPerMonthJun : any
  public classPerMonthJul : any
  public classPerMonthAug : any
  public classPerMonthSep : any
  public classPerMonthOct : any
  public classPerMonthNov : any
  public classPerMonthDec : any
  public mailDropPerMonthJan : any
  public mailDropPerMonthFeb : any
  public mailDropPerMonthMar : any
  public mailDropPerMonthApr : any
  public mailDropPerMonthMay : any
  public mailDropPerMonthJun : any
  public mailDropPerMonthJul : any
  public mailDropPerMonthAug : any
  public mailDropPerMonthSep : any
  public mailDropPerMonthOct : any
  public mailDropPerMonthNov : any
  public mailDropPerMonthDec : any
  public costPerMailerJan : any
  public costPerMailerFeb : any
  public costPerMailerMar : any
  public costPerMailerApr : any
  public costPerMailerMay : any
  public costPerMailerJun : any
  public costPerMailerJul : any
  public costPerMailerAug : any
  public costPerMailerSep : any
  public costPerMailerOct : any
  public costPerMailerNov : any
  public costPerMailerDec : any 
  public costPerEventJan : any
  public costPerEventFeb : any
  public costPerEventMar : any
  public costPerEventApr : any
  public costPerEventMay : any
  public costPerEventJun : any
  public costPerEventJul : any
  public costPerEventAug : any
  public costPerEventSep : any
  public costPerEventOct : any
  public costPerEventNov : any
  public costPerEventDec : any 
  public totalCostPerMonthJan : any
  public totalCostPerMonthFeb : any
  public totalCostPerMonthMar : any
  public totalCostPerMonthApr : any
  public totalCostPerMonthMay : any
  public totalCostPerMonthJun : any
  public totalCostPerMonthJul : any
  public totalCostPerMonthAug : any
  public totalCostPerMonthSep : any
  public totalCostPerMonthOct : any
  public totalCostPerMonthNov : any
  public totalCostPerMonthDec : any 
  public totalBURegisteredPerClassJan : any
  public totalBURegisteredPerClassFeb : any
  public totalBURegisteredPerClassMar : any
  public totalBURegisteredPerClassApr : any
  public totalBURegisteredPerClassMay : any
  public totalBURegisteredPerClassJun : any
  public totalBURegisteredPerClassJul : any
  public totalBURegisteredPerClassAug : any
  public totalBURegisteredPerClassSep : any
  public totalBURegisteredPerClassOct : any
  public totalBURegisteredPerClassNov : any
  public totalBURegisteredPerClassDec : any 
  public cancelRateJan : any
  public cancelRateFeb : any
  public cancelRateMar : any
  public cancelRateApr : any
  public cancelRateMay : any
  public cancelRateJun : any
  public cancelRateJul : any
  public cancelRateAug : any
  public cancelRateSep : any
  public cancelRateOct : any
  public cancelRateNov : any
  public cancelRateDec : any 
  public totalAttendeesJan : any
  public totalAttendeesFeb : any
  public totalAttendeesMar : any
  public totalAttendeesApr : any
  public totalAttendeesMay : any
  public totalAttendeesJun : any
  public totalAttendeesJul : any
  public totalAttendeesAug : any
  public totalAttendeesSep : any
  public totalAttendeesOct : any
  public totalAttendeesNov : any
  public totalAttendeesDec : any  
  public bookingRateJan : any
  public bookingRateFeb : any
  public bookingRateMar : any
  public bookingRateApr : any
  public bookingRateMay : any
  public bookingRateJun : any
  public bookingRateJul : any
  public bookingRateAug : any
  public bookingRateSep : any
  public bookingRateOct : any
  public bookingRateNov : any
  public bookingRateDec : any 
  public numberOfFirstApptsJan : any
  public numberOfFirstApptsFeb : any
  public numberOfFirstApptsMar : any
  public numberOfFirstApptsApr : any
  public numberOfFirstApptsMay : any
  public numberOfFirstApptsJun : any
  public numberOfFirstApptsJul : any
  public numberOfFirstApptsAug : any
  public numberOfFirstApptsSep : any
  public numberOfFirstApptsOct : any
  public numberOfFirstApptsNov : any
  public numberOfFirstApptsDec : any 
  public closeRateJan : any
  public closeRateFeb : any
  public closeRateMar : any
  public closeRateApr : any
  public closeRateMay : any
  public closeRateJun : any
  public closeRateJul : any
  public closeRateAug : any
  public closeRateSep : any
  public closeRateOct : any
  public closeRateNov : any
  public closeRateDec : any 
  public firstApptsConvertingToCloseJan : any
  public firstApptsConvertingToCloseFeb : any
  public firstApptsConvertingToCloseMar : any
  public firstApptsConvertingToCloseApr : any
  public firstApptsConvertingToCloseMay : any
  public firstApptsConvertingToCloseJun : any
  public firstApptsConvertingToCloseJul : any
  public firstApptsConvertingToCloseAug : any
  public firstApptsConvertingToCloseSep : any
  public firstApptsConvertingToCloseOct : any
  public firstApptsConvertingToCloseNov : any
  public firstApptsConvertingToCloseDec : any  
  public closesPerMonthJan : any
  public closesPerMonthFeb : any
  public closesPerMonthMar : any
  public closesPerMonthApr : any
  public closesPerMonthMay : any
  public closesPerMonthJun : any
  public closesPerMonthJul : any
  public closesPerMonthAug : any
  public closesPerMonthSep : any
  public closesPerMonthOct : any
  public closesPerMonthNov : any
  public closesPerMonthDec : any  
  public totalAssetsPerCloseJan : any
  public totalAssetsPerCloseFeb : any
  public totalAssetsPerCloseMar : any
  public totalAssetsPerCloseApr : any
  public totalAssetsPerCloseMay : any
  public totalAssetsPerCloseJun : any
  public totalAssetsPerCloseJul : any
  public totalAssetsPerCloseAug : any
  public totalAssetsPerCloseSep : any
  public totalAssetsPerCloseOct : any
  public totalAssetsPerCloseNov : any
  public totalAssetsPerCloseDec : any 
  public percentOfAssetsToANNJan : any
  public percentOfAssetsToANNFeb : any
  public percentOfAssetsToANNMar : any
  public percentOfAssetsToANNApr : any
  public percentOfAssetsToANNMay : any
  public percentOfAssetsToANNJun : any
  public percentOfAssetsToANNJul : any
  public percentOfAssetsToANNAug : any
  public percentOfAssetsToANNSep : any
  public percentOfAssetsToANNOct : any
  public percentOfAssetsToANNNov : any
  public percentOfAssetsToANNDec : any 
  public aUMPerCloseJan : any
  public aUMPerCloseFeb : any
  public aUMPerCloseMar : any
  public aUMPerCloseApr : any
  public aUMPerCloseMay : any
  public aUMPerCloseJun : any
  public aUMPerCloseJul : any
  public aUMPerCloseAug : any
  public aUMPerCloseSep : any
  public aUMPerCloseOct : any
  public aUMPerCloseNov : any
  public aUMPerCloseDec : any  
  public aNNPerCloseJan : any
  public aNNPerCloseFeb : any
  public aNNPerCloseMar : any
  public aNNPerCloseApr : any
  public aNNPerCloseMay : any
  public aNNPerCloseJun : any
  public aNNPerCloseJul : any
  public aNNPerCloseAug : any
  public aNNPerCloseSep : any
  public aNNPerCloseOct : any
  public aNNPerCloseNov : any
  public aNNPerCloseDec : any  
  public feePerClientPerMonthJan : any
  public feePerClientPerMonthFeb : any
  public feePerClientPerMonthMar : any
  public feePerClientPerMonthApr : any
  public feePerClientPerMonthMay : any
  public feePerClientPerMonthJun : any
  public feePerClientPerMonthJul : any
  public feePerClientPerMonthAug : any
  public feePerClientPerMonthSep : any
  public feePerClientPerMonthOct : any
  public feePerClientPerMonthNov : any
  public feePerClientPerMonthDec : any  
  public trailYearsJan : any
  public trailYearsFeb : any
  public trailYearsMar : any
  public trailYearsApr : any
  public trailYearsMay : any
  public trailYearsJun : any
  public trailYearsJul : any
  public trailYearsAug : any
  public trailYearsSep : any
  public trailYearsOct : any
  public trailYearsNov : any
  public trailYearsDec : any  
  public aNNCommRevPerCloseJan : any
  public aNNCommRevPerCloseFeb : any
  public aNNCommRevPerCloseMar : any
  public aNNCommRevPerCloseApr : any
  public aNNCommRevPerCloseMay : any
  public aNNCommRevPerCloseJun : any
  public aNNCommRevPerCloseJul : any
  public aNNCommRevPerCloseAug : any
  public aNNCommRevPerCloseSep : any
  public aNNCommRevPerCloseOct : any
  public aNNCommRevPerCloseNov : any
  public aNNCommRevPerCloseDec : any  
  public aNNTrailPerYearJan : any
  public aNNTrailPerYearFeb : any
  public aNNTrailPerYearMar : any
  public aNNTrailPerYearApr : any
  public aNNTrailPerYearMay : any
  public aNNTrailPerYearJun : any
  public aNNTrailPerYearJul : any
  public aNNTrailPerYearAug : any
  public aNNTrailPerYearSep : any
  public aNNTrailPerYearOct : any
  public aNNTrailPerYearNov : any
  public aNNTrailPerYearDec : any  
  public aUMFeePerMonthJan : any
  public aUMFeePerMonthFeb : any
  public aUMFeePerMonthMar : any
  public aUMFeePerMonthApr : any
  public aUMFeePerMonthMay : any
  public aUMFeePerMonthJun : any
  public aUMFeePerMonthJul : any
  public aUMFeePerMonthAug : any
  public aUMFeePerMonthSep : any
  public aUMFeePerMonthOct : any
  public aUMFeePerMonthNov : any
  public aUMFeePerMonthDec : any  
  public aNNCommPerMonthJan : any
  public aNNCommPerMonthFeb : any
  public aNNCommPerMonthMar : any
  public aNNCommPerMonthApr : any
  public aNNCommPerMonthMay : any
  public aNNCommPerMonthJun : any
  public aNNCommPerMonthJul : any
  public aNNCommPerMonthAug : any
  public aNNCommPerMonthSep : any
  public aNNCommPerMonthOct : any
  public aNNCommPerMonthNov : any
  public aNNCommPerMonthDec : any  
  public aNNTrailForFutureYearsPerMonthJan : any
  public aNNTrailForFutureYearsPerMonthFeb : any
  public aNNTrailForFutureYearsPerMonthMar : any
  public aNNTrailForFutureYearsPerMonthApr : any
  public aNNTrailForFutureYearsPerMonthMay : any
  public aNNTrailForFutureYearsPerMonthJun : any
  public aNNTrailForFutureYearsPerMonthJul : any
  public aNNTrailForFutureYearsPerMonthAug : any
  public aNNTrailForFutureYearsPerMonthSep : any
  public aNNTrailForFutureYearsPerMonthOct : any
  public aNNTrailForFutureYearsPerMonthNov : any
  public aNNTrailForFutureYearsPerMonthDec : any 
  
  //for repData additional channels controls
  public radioSpots: string
  public radioShows: string
  public tv: string

  public channelExpenditureRadioSpots : any
  public channelExpenditureRadioShows : any
  public channelExpenditureTV : any 

  public costPerEventRadioSpots : any
  public costPerEventRadioShows : any
  public costPerEventTV : any 

  public totalEventsPerMonthRadioSpots : any
  public totalEventsPerMonthRadioShows : any
  public totalEventsPerMonthTV : any 

  public leadsPerEventRadioSpots : any
  public leadsPerEventRadioShows : any
  public leadsPerEventTV : any 

  public leadsConvertedToProspectsRadioSpots : any
  public leadsConvertedToProspectsRadioShows : any
  public leadsConvertedToProspectsTV : any 

  public totalNewProspectsPerMonthRadioSpots : any
  public totalNewProspectsPerMonthRadioShows : any
  public totalNewProspectsPerMonthTV: any 

  public numberOfEW101BookedRadioSpots : any
  public numberOfEW101BookedRadioShows : any
  public numberOfEW101BookedTV: any 

  public numberOfEW101AttendedRadioSpots : any
  public numberOfEW101AttendedRadioShows : any
  public numberOfEW101AttendedTV : any 

  public firstAppointmentBookedRadioSpots : any
  public firstAppointmentBookedRadioShows : any
  public firstAppointmentBookedTV : any 

  public firstAppointmentAttendedRadioSpots : any
  public firstAppointmentAttendedRadioShows : any
  public firstAppointmentAttendedTV : any 

  public secondAppointmentBookedRadioSpots : any
  public secondAppointmentBookedRadioShows : any
  public secondAppointmentBookedTV : any 

  public secondAppointmentAttendedRadioSpots : any
  public secondAppointmentAttendedRadioShows : any
  public secondAppointmentAttendedTV : any 

  public thirdAppointmentBookedRadioSpots : any
  public thirdAppointmentBookedRadioShows : any
  public thirdAppointmentBookedTV : any 

  public thirdAppointmentAttendedRadioSpots : any
  public thirdAppointmentAttendedRadioShows : any
  public thirdAppointmentAttendedTV : any 

  public closedRadioSpots : any
  public closedRadioShows : any
  public closedTV : any 

  public closingPercentageRadioSpots : any
  public closingPercentageRadioShows : any
  public closingPercentageTV : any 

  public totalAssetsPerCloseRadioSpots : any
  public totalAssetsPerCloseRadioShows : any
  public totalAssetsPerCloseTV : any 

  public percentOfAssetsToANNRadioSpots : any
  public percentOfAssetsToANNRadioShows : any
  public percentOfAssetsToANNTV : any 

  public aUMPerCloseRadioSpots : any
  public aUMPerCloseRadioShows : any
  public aUMPerCloseTV : any

  public aNNPerCloseRadioSpots : any
  public aNNPerCloseRadioShows : any
  public aNNPerCloseTV : any

  public feePerClientPerMonthRadioSpots : any
  public feePerClientPerMonthRadioShows : any
  public feePerClientPerMonthTV : any

  public aNNTrailPerYearRadioSpots : any
  public aNNTrailPerYearRadioShows : any
  public aNNTrailPerYearTV : any
   
  public aUMFeePerMonthRadioSpots : any
  public aUMFeePerMonthRadioShows : any
  public aUMFeePerMonthTV : any 
  
  public aNNCommPerMonthRadioSpots : any
  public aNNCommPerMonthRadioShows : any
  public aNNCommPerMonthTV : any

  public aNNTrailForFutureYearsPerMonthRadioSpots : any
  public aNNTrailForFutureYearsPerMonthRadioShows : any
  public aNNTrailForFutureYearsPerMonthTV : any



  //for fill across
  public fillAcrossValue: any

  //for fill down
  public fillDownValue: any
  
  //for financial products 
  public totalAUMFee : any  
  public commissionpercentageOnANN : any  
  public trailPercentage : any
  public trailYears : any
  public annuities:Array<any> = []
     
  private stepper: Stepper;
  //private calculatorComponent : any
  private commonFunctions: CommonFunctions = new CommonFunctions 

  status: boolean = false;
  resizestatus: boolean = false;

  public isShowDashboard: boolean = false
  //#endregion

//#region "constructor,  and ngOnInit"
  constructor(private resolver: ComponentFactoryResolver,private router: Router,
              private alertService: AlertService,private FP4FAdataService: FP4FADataService,
              private formBuilder: FormBuilder,private sharedService: SharedServiceService,
              private modalService: BsModalService,private fp4facommonFunctions: FP4FACommonFunctionsService) 
  {

  }

  // ngOnDestroy()
  // {
  //     this.alertService.clear();
  // } 

  ngOnInit() 
  { 
    this.loggedAgent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
    if(this.loggedAgent.UserRoleID== Constants.UserRoleID.Superuser)
      this.isShowDashboard = true

    this.businesscalculatorinput   = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_INPUT)) as BusinessCalculatorInput;
    this.businesscalculatoroutput   = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_OUTPUT)) as BusinessCalculatorOutput;

    this.stepper = new Stepper(document.querySelector('#stepper-revenue'), {
      linear: false,
      animation: true
    })
    this.currentStepperIndex =0
	  this.changeStepper(this.currentStepperIndex)
    
    this.repdataForm = this.formBuilder.group({
      marketingChannel : new FormControl(null) , 
      repFirstName  : new FormControl(null) ,
      repLastName  : new FormControl(null) ,
      baseSalary  : new FormControl(null) ,
      repStartDate : new FormControl(null) ,
      repEndDate : new FormControl(null) ,
      repPayPercentage : new FormControl(null) ,

      //Rep data workshops controls
      classPerMonthJan : new FormControl(null) ,
      classPerMonthFeb : new FormControl(null) ,
      classPerMonthMar : new FormControl(null) ,
      classPerMonthApr : new FormControl(null) ,
      classPerMonthMay : new FormControl(null) ,
      classPerMonthJun : new FormControl(null) ,
      classPerMonthJul : new FormControl(null) ,
      classPerMonthAug : new FormControl(null) ,
      classPerMonthSep : new FormControl(null) ,
      classPerMonthOct : new FormControl(null) ,
      classPerMonthNov : new FormControl(null) ,
      classPerMonthDec : new FormControl(null) ,
      mailDropPerMonthJan : new FormControl(null) ,
      mailDropPerMonthFeb : new FormControl(null) ,
      mailDropPerMonthMar : new FormControl(null) ,
      mailDropPerMonthApr : new FormControl(null) ,
      mailDropPerMonthMay : new FormControl(null) ,
      mailDropPerMonthJun : new FormControl(null) ,
      mailDropPerMonthJul : new FormControl(null) ,
      mailDropPerMonthAug : new FormControl(null) ,
      mailDropPerMonthSep : new FormControl(null) ,
      mailDropPerMonthOct : new FormControl(null) ,
      mailDropPerMonthNov : new FormControl(null) ,
      mailDropPerMonthDec : new FormControl(null) ,
      costPerMailerJan : new FormControl(null) ,
      costPerMailerFeb : new FormControl(null) ,
      costPerMailerMar : new FormControl(null) ,
      costPerMailerApr : new FormControl(null) ,
      costPerMailerMay : new FormControl(null) ,
      costPerMailerJun : new FormControl(null) ,
      costPerMailerJul : new FormControl(null) ,
      costPerMailerAug : new FormControl(null) ,
      costPerMailerSep : new FormControl(null) ,
      costPerMailerOct : new FormControl(null) ,
      costPerMailerNov : new FormControl(null) ,
      costPerMailerDec : new FormControl(null) ,
      costPerEventJan : new FormControl(null) ,
      costPerEventFeb : new FormControl(null) ,
      costPerEventMar : new FormControl(null) ,
      costPerEventApr : new FormControl(null) ,
      costPerEventMay : new FormControl(null) ,
      costPerEventJun : new FormControl(null) ,
      costPerEventJul : new FormControl(null) ,
      costPerEventAug : new FormControl(null) ,
      costPerEventSep : new FormControl(null) ,
      costPerEventOct : new FormControl(null) ,
      costPerEventNov : new FormControl(null) ,
      costPerEventDec : new FormControl(null) ,
      totalCostPerMonthJan : new FormControl(null) ,
      totalCostPerMonthFeb : new FormControl(null) ,
      totalCostPerMonthMar : new FormControl(null) ,
      totalCostPerMonthApr : new FormControl(null) ,
      totalCostPerMonthMay : new FormControl(null) ,
      totalCostPerMonthJun : new FormControl(null) ,
      totalCostPerMonthJul : new FormControl(null) ,
      totalCostPerMonthAug : new FormControl(null) ,
      totalCostPerMonthSep : new FormControl(null) ,
      totalCostPerMonthOct : new FormControl(null) ,
      totalCostPerMonthNov : new FormControl(null) ,
      totalCostPerMonthDec : new FormControl(null) ,
      totalBURegisteredPerClassJan : new FormControl(null) ,
      totalBURegisteredPerClassFeb : new FormControl(null) ,
      totalBURegisteredPerClassMar : new FormControl(null) ,
      totalBURegisteredPerClassApr : new FormControl(null) ,
      totalBURegisteredPerClassMay : new FormControl(null) ,
      totalBURegisteredPerClassJun : new FormControl(null) ,
      totalBURegisteredPerClassJul : new FormControl(null) ,
      totalBURegisteredPerClassAug : new FormControl(null) ,
      totalBURegisteredPerClassSep : new FormControl(null) ,
      totalBURegisteredPerClassOct : new FormControl(null) ,
      totalBURegisteredPerClassNov : new FormControl(null) ,
      totalBURegisteredPerClassDec : new FormControl(null) ,  
      cancelRateJan : new FormControl(null) ,
      cancelRateFeb : new FormControl(null) ,
      cancelRateMar : new FormControl(null) ,
      cancelRateApr : new FormControl(null) ,
      cancelRateMay : new FormControl(null) ,
      cancelRateJun : new FormControl(null) ,
      cancelRateJul : new FormControl(null) ,
      cancelRateAug : new FormControl(null) ,
      cancelRateSep : new FormControl(null) ,
      cancelRateOct : new FormControl(null) ,
      cancelRateNov : new FormControl(null) ,
      cancelRateDec : new FormControl(null) ,
      totalAttendeesJan : new FormControl(null) ,
      totalAttendeesFeb : new FormControl(null) ,
      totalAttendeesMar : new FormControl(null) ,
      totalAttendeesApr : new FormControl(null) ,
      totalAttendeesMay : new FormControl(null) ,
      totalAttendeesJun : new FormControl(null) ,
      totalAttendeesJul : new FormControl(null) ,
      totalAttendeesAug : new FormControl(null) ,
      totalAttendeesSep : new FormControl(null) ,
      totalAttendeesOct : new FormControl(null) ,
      totalAttendeesNov : new FormControl(null) ,
      totalAttendeesDec : new FormControl(null) ,
      bookingRateJan : new FormControl(null) ,
      bookingRateFeb : new FormControl(null) ,
      bookingRateMar : new FormControl(null) ,
      bookingRateApr : new FormControl(null) ,
      bookingRateMay : new FormControl(null) ,
      bookingRateJun : new FormControl(null) ,
      bookingRateJul : new FormControl(null) ,
      bookingRateAug : new FormControl(null) ,
      bookingRateSep : new FormControl(null) ,
      bookingRateOct : new FormControl(null) ,
      bookingRateNov : new FormControl(null) ,
      bookingRateDec : new FormControl(null) ,
      numberOfFirstApptsJan : new FormControl(null) ,
      numberOfFirstApptsFeb : new FormControl(null) ,
      numberOfFirstApptsMar : new FormControl(null) ,
      numberOfFirstApptsApr : new FormControl(null) ,
      numberOfFirstApptsMay : new FormControl(null) ,
      numberOfFirstApptsJun : new FormControl(null) ,
      numberOfFirstApptsJul : new FormControl(null) ,
      numberOfFirstApptsAug : new FormControl(null) ,
      numberOfFirstApptsSep : new FormControl(null) ,
      numberOfFirstApptsOct : new FormControl(null) ,
      numberOfFirstApptsNov : new FormControl(null) ,
      numberOfFirstApptsDec : new FormControl(null) ,
      closeRateJan : new FormControl(null) ,
      closeRateFeb : new FormControl(null) ,
      closeRateMar : new FormControl(null) ,
      closeRateApr : new FormControl(null) ,
      closeRateMay : new FormControl(null) ,
      closeRateJun : new FormControl(null) ,
      closeRateJul : new FormControl(null) ,
      closeRateAug : new FormControl(null) ,
      closeRateSep : new FormControl(null) ,
      closeRateOct : new FormControl(null) ,
      closeRateNov : new FormControl(null) ,
      closeRateDec : new FormControl(null) ,
      firstApptsConvertingToCloseJan : new FormControl(null) ,
      firstApptsConvertingToCloseFeb : new FormControl(null) ,
      firstApptsConvertingToCloseMar : new FormControl(null) ,
      firstApptsConvertingToCloseApr : new FormControl(null) ,
      firstApptsConvertingToCloseMay : new FormControl(null) ,
      firstApptsConvertingToCloseJun : new FormControl(null) ,
      firstApptsConvertingToCloseJul : new FormControl(null) ,
      firstApptsConvertingToCloseAug : new FormControl(null) ,
      firstApptsConvertingToCloseSep : new FormControl(null) ,
      firstApptsConvertingToCloseOct : new FormControl(null) ,
      firstApptsConvertingToCloseNov : new FormControl(null) ,
      firstApptsConvertingToCloseDec : new FormControl(null) ,
      closesPerMonthJan : new FormControl(null) ,
      closesPerMonthFeb : new FormControl(null) ,
      closesPerMonthMar : new FormControl(null) ,
      closesPerMonthApr : new FormControl(null) ,
      closesPerMonthMay : new FormControl(null) ,
      closesPerMonthJun : new FormControl(null) ,
      closesPerMonthJul : new FormControl(null) ,
      closesPerMonthAug : new FormControl(null) ,
      closesPerMonthSep : new FormControl(null) ,
      closesPerMonthOct : new FormControl(null) ,
      closesPerMonthNov : new FormControl(null) ,
      closesPerMonthDec : new FormControl(null) ,
      totalAssetsPerCloseJan : new FormControl(null) ,
      totalAssetsPerCloseFeb : new FormControl(null) ,
      totalAssetsPerCloseMar : new FormControl(null) ,
      totalAssetsPerCloseApr : new FormControl(null) ,
      totalAssetsPerCloseMay : new FormControl(null) ,
      totalAssetsPerCloseJun : new FormControl(null) ,
      totalAssetsPerCloseJul : new FormControl(null) ,
      totalAssetsPerCloseAug : new FormControl(null) ,
      totalAssetsPerCloseSep : new FormControl(null) ,
      totalAssetsPerCloseOct : new FormControl(null) ,
      totalAssetsPerCloseNov : new FormControl(null) ,
      totalAssetsPerCloseDec : new FormControl(null) ,
      percentOfAssetsToANNJan : new FormControl(null) ,
      percentOfAssetsToANNFeb : new FormControl(null) ,
      percentOfAssetsToANNMar : new FormControl(null) ,
      percentOfAssetsToANNApr : new FormControl(null) ,
      percentOfAssetsToANNMay : new FormControl(null) ,
      percentOfAssetsToANNJun : new FormControl(null) ,
      percentOfAssetsToANNJul : new FormControl(null) ,
      percentOfAssetsToANNAug : new FormControl(null) ,
      percentOfAssetsToANNSep : new FormControl(null) ,
      percentOfAssetsToANNOct : new FormControl(null) ,
      percentOfAssetsToANNNov : new FormControl(null) ,
      percentOfAssetsToANNDec : new FormControl(null) ,
      aUMPerCloseJan : new FormControl(null) ,
      aUMPerCloseFeb : new FormControl(null) ,
      aUMPerCloseMar : new FormControl(null) ,
      aUMPerCloseApr : new FormControl(null) ,
      aUMPerCloseMay : new FormControl(null) ,
      aUMPerCloseJun : new FormControl(null) ,
      aUMPerCloseJul : new FormControl(null) ,
      aUMPerCloseAug : new FormControl(null) ,
      aUMPerCloseSep : new FormControl(null) ,
      aUMPerCloseOct : new FormControl(null) ,
      aUMPerCloseNov : new FormControl(null) ,
      aUMPerCloseDec : new FormControl(null) ,
      aNNPerCloseJan : new FormControl(null) ,
      aNNPerCloseFeb : new FormControl(null) ,
      aNNPerCloseMar : new FormControl(null) ,
      aNNPerCloseApr : new FormControl(null) ,
      aNNPerCloseMay : new FormControl(null) ,
      aNNPerCloseJun : new FormControl(null) ,
      aNNPerCloseJul : new FormControl(null) ,
      aNNPerCloseAug : new FormControl(null) ,
      aNNPerCloseSep : new FormControl(null) ,
      aNNPerCloseOct : new FormControl(null) ,
      aNNPerCloseNov : new FormControl(null) ,
      aNNPerCloseDec : new FormControl(null) ,
      feePerClientPerMonthJan : new FormControl(null) ,
      feePerClientPerMonthFeb : new FormControl(null) ,
      feePerClientPerMonthMar : new FormControl(null) ,
      feePerClientPerMonthApr : new FormControl(null) ,
      feePerClientPerMonthMay : new FormControl(null) ,
      feePerClientPerMonthJun : new FormControl(null) ,
      feePerClientPerMonthJul : new FormControl(null) ,
      feePerClientPerMonthAug : new FormControl(null) ,
      feePerClientPerMonthSep : new FormControl(null) ,
      feePerClientPerMonthOct : new FormControl(null) ,
      feePerClientPerMonthNov : new FormControl(null) ,
      feePerClientPerMonthDec : new FormControl(null) ,
      trailYearsJan : new FormControl(null) ,
      trailYearsFeb : new FormControl(null) ,
      trailYearsMar : new FormControl(null) ,
      trailYearsApr : new FormControl(null) ,
      trailYearsMay : new FormControl(null) ,
      trailYearsJun : new FormControl(null) ,
      trailYearsJul : new FormControl(null) ,
      trailYearsAug : new FormControl(null) ,
      trailYearsSep : new FormControl(null) ,
      trailYearsOct : new FormControl(null) ,
      trailYearsNov : new FormControl(null) ,
      trailYearsDec : new FormControl(null) ,
      aNNCommRevPerCloseJan : new FormControl(null) ,
      aNNCommRevPerCloseFeb : new FormControl(null) ,
      aNNCommRevPerCloseMar : new FormControl(null) ,
      aNNCommRevPerCloseApr : new FormControl(null) ,
      aNNCommRevPerCloseMay : new FormControl(null) ,
      aNNCommRevPerCloseJun : new FormControl(null) ,
      aNNCommRevPerCloseJul : new FormControl(null) ,
      aNNCommRevPerCloseAug : new FormControl(null) ,
      aNNCommRevPerCloseSep : new FormControl(null) ,
      aNNCommRevPerCloseOct : new FormControl(null) ,
      aNNCommRevPerCloseNov : new FormControl(null) ,
      aNNCommRevPerCloseDec : new FormControl(null) ,
      aNNTrailPerYearJan : new FormControl(null) ,
      aNNTrailPerYearFeb : new FormControl(null) ,
      aNNTrailPerYearMar : new FormControl(null) ,
      aNNTrailPerYearApr : new FormControl(null) ,
      aNNTrailPerYearMay : new FormControl(null) ,
      aNNTrailPerYearJun : new FormControl(null) ,
      aNNTrailPerYearJul : new FormControl(null) ,
      aNNTrailPerYearAug : new FormControl(null) ,
      aNNTrailPerYearSep : new FormControl(null) ,
      aNNTrailPerYearOct : new FormControl(null) ,
      aNNTrailPerYearNov : new FormControl(null) ,
      aNNTrailPerYearDec : new FormControl(null) ,
      aUMFeePerMonthJan : new FormControl(null) ,
      aUMFeePerMonthFeb : new FormControl(null) ,
      aUMFeePerMonthMar : new FormControl(null) ,
      aUMFeePerMonthApr : new FormControl(null) ,
      aUMFeePerMonthMay : new FormControl(null) ,
      aUMFeePerMonthJun : new FormControl(null) ,
      aUMFeePerMonthJul : new FormControl(null) ,
      aUMFeePerMonthAug : new FormControl(null) ,
      aUMFeePerMonthSep : new FormControl(null) ,
      aUMFeePerMonthOct : new FormControl(null) ,
      aUMFeePerMonthNov : new FormControl(null) ,
      aUMFeePerMonthDec : new FormControl(null) ,
      aNNCommPerMonthJan : new FormControl(null) ,
      aNNCommPerMonthFeb : new FormControl(null) ,
      aNNCommPerMonthMar : new FormControl(null) ,
      aNNCommPerMonthApr : new FormControl(null) ,
      aNNCommPerMonthMay : new FormControl(null) ,
      aNNCommPerMonthJun : new FormControl(null) ,
      aNNCommPerMonthJul : new FormControl(null) ,
      aNNCommPerMonthAug : new FormControl(null) ,
      aNNCommPerMonthSep : new FormControl(null) ,
      aNNCommPerMonthOct : new FormControl(null) ,
      aNNCommPerMonthNov : new FormControl(null) ,
      aNNCommPerMonthDec : new FormControl(null) ,
      aNNTrailForFutureYearsPerMonthJan : new FormControl(null) ,
      aNNTrailForFutureYearsPerMonthFeb : new FormControl(null) ,
      aNNTrailForFutureYearsPerMonthMar : new FormControl(null) ,
      aNNTrailForFutureYearsPerMonthApr : new FormControl(null) ,
      aNNTrailForFutureYearsPerMonthMay : new FormControl(null) ,
      aNNTrailForFutureYearsPerMonthJun : new FormControl(null) ,
      aNNTrailForFutureYearsPerMonthJul : new FormControl(null) ,
      aNNTrailForFutureYearsPerMonthAug : new FormControl(null) ,
      aNNTrailForFutureYearsPerMonthSep : new FormControl(null) ,
      aNNTrailForFutureYearsPerMonthOct : new FormControl(null) ,
      aNNTrailForFutureYearsPerMonthNov : new FormControl(null) ,
      aNNTrailForFutureYearsPerMonthDec : new FormControl(null) ,

      //Rep data additional channel controls
      channelExpenditureRadioSpots : new FormControl(null) ,
      channelExpenditureRadioShows : new FormControl(null) ,
      channelExpenditureTV : new FormControl(null) , 
    
      costPerEventRadioSpots : new FormControl(null) ,
      costPerEventRadioShows : new FormControl(null) ,
      costPerEventTV : new FormControl(null) , 
    
      totalEventsPerMonthRadioSpots : new FormControl(null) ,
      totalEventsPerMonthRadioShows : new FormControl(null) ,
      totalEventsPerMonthTV : new FormControl(null) , 
    
      leadsPerEventRadioSpots : new FormControl(null) ,
      leadsPerEventRadioShows : new FormControl(null) ,
      leadsPerEventTV : new FormControl(null) , 
    
      leadsConvertedToProspectsRadioSpots : new FormControl(null) ,
      leadsConvertedToProspectsRadioShows : new FormControl(null) ,
      leadsConvertedToProspectsTV : new FormControl(null) , 
    
      totalNewProspectsPerMonthRadioSpots : new FormControl(null) ,
      totalNewProspectsPerMonthRadioShows : new FormControl(null) ,
      totalNewProspectsPerMonthTV: new FormControl(null) , 
    
      numberOfEW101BookedRadioSpots : new FormControl(null) ,
      numberOfEW101BookedRadioShows : new FormControl(null) ,
      numberOfEW101BookedTV: new FormControl(null) , 
    
      numberOfEW101AttendedRadioSpots : new FormControl(null) ,
      numberOfEW101AttendedRadioShows : new FormControl(null) ,
      numberOfEW101AttendedTV : new FormControl(null) , 
    
      firstAppointmentBookedRadioSpots : new FormControl(null) ,
      firstAppointmentBookedRadioShows : new FormControl(null) ,
      firstAppointmentBookedTV : new FormControl(null) , 
    
      firstAppointmentAttendedRadioSpots : new FormControl(null) ,
      firstAppointmentAttendedRadioShows : new FormControl(null) ,
      firstAppointmentAttendedTV : new FormControl(null) , 
    
      secondAppointmentBookedRadioSpots : new FormControl(null) ,
      secondAppointmentBookedRadioShows : new FormControl(null) ,
      secondAppointmentBookedTV : new FormControl(null) , 
    
      secondAppointmentAttendedRadioSpots : new FormControl(null) ,
      secondAppointmentAttendedRadioShows : new FormControl(null) ,
      secondAppointmentAttendedTV : new FormControl(null) , 
    
      thirdAppointmentBookedRadioSpots : new FormControl(null) ,
      thirdAppointmentBookedRadioShows : new FormControl(null) ,
      thirdAppointmentBookedTV : new FormControl(null) , 
    
      thirdAppointmentAttendedRadioSpots : new FormControl(null) ,
      thirdAppointmentAttendedRadioShows : new FormControl(null) ,
      thirdAppointmentAttendedTV : new FormControl(null) , 
    
      closedRadioSpots : new FormControl(null) ,
      closedRadioShows : new FormControl(null) ,
      closedTV : new FormControl(null) , 
    
      closingPercentageRadioSpots : new FormControl(null) ,
      closingPercentageRadioShows : new FormControl(null) ,
      closingPercentageTV : new FormControl(null) , 
    
      totalAssetsPerCloseRadioSpots : new FormControl(null) ,
      totalAssetsPerCloseRadioShows : new FormControl(null) ,
      totalAssetsPerCloseTV : new FormControl(null) , 
    
      percentOfAssetsToANNRadioSpots : new FormControl(null) ,
      percentOfAssetsToANNRadioShows : new FormControl(null) ,
      percentOfAssetsToANNTV : new FormControl(null) , 
    
      aUMPerCloseRadioSpots : new FormControl(null) ,
      aUMPerCloseRadioShows : new FormControl(null) ,
      aUMPerCloseTV : new FormControl(null) ,
    
      aNNPerCloseRadioSpots : new FormControl(null) ,
      aNNPerCloseRadioShows : new FormControl(null) ,
      aNNPerCloseTV : new FormControl(null) ,
    
      feePerClientPerMonthRadioSpots : new FormControl(null) ,
      feePerClientPerMonthRadioShows : new FormControl(null) ,
      feePerClientPerMonthTV : new FormControl(null) ,
    
      aNNTrailPerYearSpots : new FormControl(null) ,
      aNNTrailPerYearRadioShows : new FormControl(null) ,
      aNNTrailPerYearTV : new FormControl(null) ,
       
      aUMFeePerMonthRadioSpots : new FormControl(null) ,
      aUMFeePerMonthRadioShows : new FormControl(null) ,
      aUMFeePerMonthTV : new FormControl(null) , 
      
      aNNCommPerMonthRadioSpots : new FormControl(null) ,
      aNNCommPerMonthRadioShows : new FormControl(null) ,
      aNNCommPerMonthTV : new FormControl(null) ,
    
      aNNTrailForFutureYearsPerMonthRadioSpots : new FormControl(null) ,
      aNNTrailForFutureYearsPerMonthRadioShows : new FormControl(null) ,
      aNNTrailForFutureYearsPerMonthTV : new FormControl(null) ,
   } ); 


   this.fillAcrossForm = this.formBuilder.group({
    fillAcrossValue : new FormControl(null) 
   });

   this.fillDownForm = this.formBuilder.group({
    fillDownValue : new FormControl(null) 
   });
   
   this.financialProductsForm = this.formBuilder.group({
      totalAUMFee : new FormControl(null) ,
      commissionpercentageOnANN : new FormControl(null) ,
      trailPercentage : new FormControl(null),
      trailYears : new FormControl(null)
    });   
  
    this.yeardatalist = 	this.businesscalculatoroutput.YearData	 
    this.repdatalist =  this.businesscalculatorinput.RepData
 
    if (this.businesscalculatorinput.RepData.length>0)
    {
      this.bHaveReps =true
      this.selectedRepID =this.businesscalculatorinput.RepData.find( x=> x.IsOwner  === true).AgentID
      this.selectedYear = Math.min.apply(Math,  this.yeardatalist.map(function(o) { return o.Year; }))    
      this.LoadRepData()   
      this.LoadRepDataAddtionalchannels() 
      this.CalculationsAC()
    }
    else 
    {
      /*this.bHaveReps =false
      this.selectedRepID =""
      this.selectedYear = ""*/

      //automatically load first rep
      this.bHaveReps =true
      this.AddRep(true)
    }
    this.createMarketingChannelsArr()
    this.LoadFinancialProducts()    
  }
//#endregion

//#region Save Action Button related Methods...
closeBottombar(){
  this.saveactionshow = false;
}

showBottombar(){
  this.saveactionshow = true;
}

SaveBusinessPlan(showBusinessPlanSavedAlert)
{ 
  this.alertService.clear()
  localStorage.setItem(LocalStorage.HAS_FINANCIAL_PRODUCT_ERRORS, "0");
  localStorage.setItem(LocalStorage.HAS_REP_DATA_ERRORS, "0");
	if(this.fp4facommonFunctions.ValidateSaveBusinessPlan()){
    this.fp4facommonFunctions.AddEmployees()
		this.FP4FAdataService.savebusinessplan(this.loggedAgent, this.businesscalculatorinput).subscribe(data => {
		if(showBusinessPlanSavedAlert){
			this.alertService.success('Business Plan successfully saved.')
		}
		localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
		},
		error => { 
			this.alertService.error('An error occurred while saving Business Plan')
    });
	}
}

//#endregion

//#region Stepper Methods 
next() 
{
  if (this.currentStepperIndex==1) //Last Stepper
  {
	this.sharedService.changeComponentsRequest(Constants.COMP_NAME.revenue)
  }
  else
  {
	this.stepper.next();
	this.currentStepperIndex +=1
	this.changeStepper(this.currentStepperIndex)
  }    
}

  onSubmit() 
  {
    return false;
  }

  changeStepper(currentindex)
  {   
    //If there are any Financial Product or Rep Data errors do not allow to navigate to other tabs
    if(!this.fp4facommonFunctions.ValidateSaveBusinessPlan()){
      if (currentindex != 0 && localStorage.getItem(LocalStorage.HAS_FINANCIAL_PRODUCT_ERRORS) =="1")
      { 
          this.stepper.previous()
          this.currentStepperIndex = 0
          this.changeStepper(this.currentStepperIndex);  
          return   
      } else if(currentindex == 0 && localStorage.getItem(LocalStorage.HAS_REP_DATA_ERRORS) =="1"){
          this.stepper.next()
          this.currentStepperIndex = 1
          this.changeStepper(this.currentStepperIndex);  
          return 
      }
    }

    // calling save business plans
    this.SaveBusinessPlan(false) 


    //show save button and expand the rep data view on stepper changes- when calculator is hidden
    if (this.status==true)
    {
      this.closeBottombar() 
    }   
    else
    {
      this.showBottombar() 
    }

    switch (this.currentStepperIndex)
    {
      case 0:
        this.currentStepperName = "Financial Products" 
        break;
      case 1:   
        this.currentStepperName = "Rep Data" 
        break;
    }
  }
//#endregion

//#region  Calculator Methods */
  resizeRightbar()
  {
    this.resizestatus = !this.resizestatus;      
  }

  toggleRightbar()
  {
    var additionalchannelstable = document.getElementById("additionalchannelstable");
    if(this.fp4facommonFunctions.ValidateSaveBusinessPlan()){
      if(!this.status){
        this.alertService.info('Calculator is loading. Please wait.')
      }
      this.fp4facommonFunctions.AddEmployees()
      this.FP4FAdataService.savebusinessplan(this.loggedAgent, this.businesscalculatorinput).subscribe(data => {
      localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
        this.status = !this.status; 
        if(this.status){
        this.closeBottombar()
        this.sharedService.loadCalculatorRequest()
        }else
        {
          this.showBottombar() 
        }  
      },
      error => { 
        this.alertService.error('An error occurred while saving Business Plan')
      });
    }
  }

  refreshCalculator()
  {
    this.alertService.info('Calculator is loading. Please wait.')
    this.SaveBusinessPlan(false)
    this.sharedService.loadCalculatorRequest()   
  }
//#endregion

//#region  Financial Products Methods */
LoadFinancialProducts()
{ 
  this.totalAUMFee = this.commonFunctions.AddThousandSeparatorFormat(this.businesscalculatorinput.FinancialProducts.TotalAUMFee)
  if(this.annuities == undefined){
	this.annuities = []
  }
  if(this.businesscalculatorinput.FinancialProducts.FinancialProductDetails == null || this.businesscalculatorinput.FinancialProducts.FinancialProductDetails == undefined){
    let newFinancialProductDetailID : string =  this.commonFunctions.getnewguid() 
    this.annuities.push({FinancialProductDetailID: newFinancialProductDetailID,AnnuityName: "Default Annuity",CommissionpercentageOnANN: "0",
       TrailPercentage: "0",TrailYears: "0",ProductSalesMix: "0"})
       this.businesscalculatorinput.FinancialProducts.FinancialProductDetails.push({FinancialProductDetailID: newFinancialProductDetailID,
        FinancialProductID: this.businesscalculatorinput.FinancialProducts.FinancialProductID,
        AnnuityName: "Default Annuity",CommissionpercentageOnANN: 0,
       TrailPercentage: 0,TrailYears: 0,ProductSalesMix: 0})
  }else{
    this.CreateFinancialProductDetailArray()
  }
}

AssignFinancialProducts(event:any, FinancialProductDetailID)
{
  this.alertService.clear()
  let stringval
  let intval
  let val = event.target.value
  let id = event.target.id.split(',')[0]
  if(id === "productSalesMix"){
    localStorage.setItem(LocalStorage.HAS_FINANCIAL_PRODUCT_ERRORS, "0");
  }
  
  let htmlelement

  if(id != "totalAUMFee" && id != "annuityName") {
    stringval = this.commonFunctions.AddThousandSeparatorFormat(val)
    if(val != ""){
      intval = parseFloat(this.commonFunctions.RemoveThousandSeparatorFormat(stringval))
    }else{
      intval = 0
    }
    htmlelement = (<HTMLInputElement>document.getElementById(event.target.id));
    htmlelement.value = stringval
  }

  let financialproductdetails = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_INPUT)).FinancialProducts.FinancialProductDetails
  let productsalesmix:number = 0
  financialproductdetails.forEach((e:any) => { 
    if(e.FinancialProductDetailID != FinancialProductDetailID){
      productsalesmix = productsalesmix + parseFloat(e.ProductSalesMix)
    }
  })

  if((id === "productSalesMix" || id === "commissionpercentageOnANN" || id === "trailPercentage") && intval > 100){
      this.alertService.error("Percentage should not exceed 100")
      localStorage.setItem(LocalStorage.HAS_FINANCIAL_PRODUCT_ERRORS, "1");
      stringval = "0"
      intval = 0
      htmlelement.value = stringval
  } else if(id === "productSalesMix" && ((productsalesmix + intval) > 100 || (productsalesmix + intval) < 100)){
      this.alertService.error('Product sales mix total should be 100%')
      localStorage.setItem(LocalStorage.HAS_FINANCIAL_PRODUCT_ERRORS, "1");
  } 
    
    if (id==="totalAUMFee") {
        this.businesscalculatorinput.FinancialProducts.TotalAUMFee = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAUMFee) 
    } else {
        this.businesscalculatorinput.FinancialProducts.FinancialProductDetails.forEach((e:any) => {
          if(e.FinancialProductDetailID == FinancialProductDetailID){
            if (id==="annuityName") {
              e.AnnuityName = val
            }else if (id==="commissionpercentageOnANN") {
                e.CommissionpercentageOnANN = intval 
            }  else if (id==="trailPercentage") {
              e.TrailPercentage = intval 
            }  else if (id==="trailYears") {
              e.TrailYears = intval
            }  else if (id==="productSalesMix") {
              e.ProductSalesMix = intval
            }
          }
        })


        this.annuities.forEach((e:any) => {
          if(e.FinancialProductDetailID == FinancialProductDetailID){
            if (event.target.id==="annuityName") {
              e.AnnuityName = event.target.value
            }else if (event.target.id==="commissionpercentageOnANN") {
                e.CommissionpercentageOnANN = stringval
            }  else if (event.target.id==="trailPercentage") {
              e.TrailPercentage = stringval 
            }  else if (event.target.id==="trailYears") {
              e.TrailYears = stringval
            }  else if (event.target.id==="productSalesMix") {
              e.ProductSalesMix = stringval  
            }
          }
        })
    }
   

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
}

CreateFinancialProductDetailArray(){
  this.businesscalculatorinput.FinancialProducts.FinancialProductDetails.forEach((e:any) => {
    this.annuities.push({FinancialProductDetailID: e.FinancialProductDetailID,AnnuityName: e.AnnuityName,
      CommissionpercentageOnANN: this.commonFunctions.AddThousandSeparatorFormat(e.CommissionpercentageOnANN).toString(),
       TrailPercentage: this.commonFunctions.AddThousandSeparatorFormat(e.TrailPercentage).toString(),
      TrailYears: this.commonFunctions.AddThousandSeparatorFormat(e.TrailYears).toString(),
      ProductSalesMix: this.commonFunctions.AddThousandSeparatorFormat(e.ProductSalesMix).toString()})
  })
}

AddNewAnnuity(){ 
  this.alertService.clear()
  let newFinancialProductDetailID : string =  this.commonFunctions.getnewguid()  
  let oFinancialProductDetail : FinancialProductDetail = new FinancialProductDetail()
  oFinancialProductDetail.FinancialProductDetailID = newFinancialProductDetailID
  oFinancialProductDetail.FinancialProductID = this.businesscalculatorinput.FinancialProducts.FinancialProductID
  oFinancialProductDetail.AnnuityName = ""
  oFinancialProductDetail.CommissionpercentageOnANN = 0
  oFinancialProductDetail.TrailPercentage = 0
  oFinancialProductDetail.TrailYears = 0
  oFinancialProductDetail.ProductSalesMix = 0


  if(this.annuities == undefined){
    this.annuities = []
  }
  this.businesscalculatorinput.FinancialProducts.FinancialProductDetails.push(oFinancialProductDetail)
  this.annuities.push({FinancialProductDetailID: oFinancialProductDetail.FinancialProductDetailID,AnnuityName: "",
        CommissionpercentageOnANN: oFinancialProductDetail.CommissionpercentageOnANN,
        TrailPercentage: oFinancialProductDetail.TrailPercentage,
        TrailYears: oFinancialProductDetail.TrailYears,
        ProductSalesMix: oFinancialProductDetail.ProductSalesMix})
}

DeleteAnnuity(FinancialProductDetailID){
  this.alertService.clear()
  if(this.businesscalculatorinput.FinancialProducts.FinancialProductDetails.length == 1 || this.annuities.length == 1){
    this.alertService.error('Financial products should contain at least one annuity')
  } else{
    this.businesscalculatorinput.FinancialProducts.FinancialProductDetails.splice(this.businesscalculatorinput.FinancialProducts.FinancialProductDetails.findIndex(x => x.FinancialProductDetailID === FinancialProductDetailID),1)
    this.annuities.splice(this.annuities.findIndex(x => x.FinancialProductDetailID === FinancialProductDetailID),1)
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");

  let financialproductdetails = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_INPUT)).FinancialProducts.FinancialProductDetails
  let productsalesmix:number = 0
  financialproductdetails.forEach((e:any) => { 
    if(e.FinancialProductDetailID != FinancialProductDetailID){
      productsalesmix = productsalesmix + parseFloat(e.ProductSalesMix)
    }
  })

 if((productsalesmix > 100 ||productsalesmix < 100) && this.businesscalculatorinput.FinancialProducts.FinancialProductDetails.length != 0 && this.annuities.length != 0){
      this.alertService.error('Product sales mix total should be 100%')
      localStorage.setItem(LocalStorage.HAS_FINANCIAL_PRODUCT_ERRORS, "1");
  } 
}

//#endregion

//#region  Rep Data Common Methods */
createMarketingChannelsArr(){
  this.marketingchannelsArray = []
  this.marketingchannelsArray.push({id: 1, text: "Workshops"},{id: 2, text: "Additional Channels"})
  this.marketingChannel = 1
}

setMarketingChannel(event: any){
  if(this.marketingChannel == "1"){
    this.workshops = true;
  }else{
    this.workshops = false;
  }
}
//#endregion

//#region  Rep Data Methods - Workshops*/
  AddRep(isOwner)
  {     
    if (isOwner) //Adding First Rep - Owner
    {
      if (this.businesscalculatorinput.RepData.length>0) 
      {
        this.alertService.info("Please use Add Rep button to add new reps");
        return;
      }
    }
    else //Adding Other Reps
    {
      if (this.businesscalculatorinput.RepData.length<=0) 
      {
        this.alertService.info("Please add 1st rep");
        return
      }
    }

    //Add new rep data 
    let newAgentID : string =  this.commonFunctions.getnewguid()  
    let oRepData : RepData = new RepData()
    let oRepDataDetails : Array<RepDataPerYear> =  []
    let oRepDataAddtionalChannels : Array<RepDataAddtionalChannel> =  []
   
    oRepData.AgentID = newAgentID
    oRepData.IsOwner = isOwner
    oRepData.bAddedToEmployee = true
    oRepData.BaseSalary = 0
    oRepData.RepDataDetails = oRepDataDetails
    oRepData.RepDataAddtionalChannels = oRepDataAddtionalChannels
    this.businesscalculatorinput.RepData.push(oRepData)
    this.selectedRepID =newAgentID
    this.selectedYear = new Date().getFullYear() 
    this.bHaveReps =true
    this.yeardatalist = 	this.businesscalculatoroutput.YearData	
    this.repdatalist =  this.businesscalculatorinput.RepData 

    //Load default values for RepData 
    this.FP4FAdataService.getbusinesscalculatorinputwithdefaults(this.loggedAgent, this.businesscalculatorinput).subscribe(data => {
        this.businesscalculatorinput  = data 
        localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput)); 
        //localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
        this.LoadRepData()
        this.LoadRepDataAddtionalchannels() 
		},
		error => {  
			this.alertService.error('An error occurred while getting FP4FA data')
		});
  }

  ChangeRep(agentID: any)
  {
    this.selectedRepID = agentID
    this.selectedYear = Math.min.apply(Math,  this.yeardatalist.map(function(o) { return o.Year; }))    
    this.LoadRepData()
    this.LoadRepDataAddtionalchannels() 
    this.CalculationsAC()
  }
  
  ChangeYear(year: any)
  {
    this.selectedYear=year
    this.LoadRepData()
  }

  LoadRepData()
  {
    //Get selected reps selected year data 
    let selectedRepData : RepData 
    let selectedMonthData :  RepDataPerYear   
    selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
      
  
    this.repFirstName = selectedRepData.RepFirstName 
    this.repLastName = selectedRepData.RepLastName 
    this.baseSalary = this.commonFunctions.AddThousandSeparatorFormat(selectedRepData.BaseSalary)
    if(this.baseSalary == "" || this.baseSalary == null || this.baseSalary == undefined){
      this.baseSalary = 0
    }
    if (selectedRepData.RepStartDate == null   ){
      this.repStartDate = null
    }
    else{
      this.repStartDate=new Date(selectedRepData.RepStartDate)
    }
 

    if (selectedRepData.RepEndDate == null){
      this.repEndDate=null
    }
    else{      
      this.repEndDate = new Date(selectedRepData.RepEndDate)
    }  

    this.repPayPercentage = selectedRepData.RepPayPercentage
    this.bOwner = selectedRepData.IsOwner
    
    //Rep data workshops
    for (let m = 1; m <= 12; m++)
    {
      let currentMonth = m
      selectedMonthData =selectedRepData.RepDataDetails.find( y=> y.Year  == this.selectedYear  && y.Month == currentMonth.toString() )  as  RepDataPerYear 
      if (selectedMonthData != undefined)
      { 
        switch (currentMonth)
        {
          case 1:
            this.classPerMonthJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClassesPerMonth)
            this.mailDropPerMonthJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.MailDropPerMonth)
            this.costPerMailerJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerMailer) 
            this.costPerEventJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerEvent)
            this.totalCostPerMonthJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalCostPerMonth)
            this.totalBURegisteredPerClassJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalBURegisteredPerClass)
            this.cancelRateJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CancelRate)
            this.totalAttendeesJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAttendees)
            this.bookingRateJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.BookingRate)
            this.numberOfFirstApptsJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.NumberOfFirstAppts)
            this.closeRateJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CloseRate) 
            this.firstApptsConvertingToCloseJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FirstApptsConvertingToClose)
            this.closesPerMonthJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClosesPerMonth)
            this.totalAssetsPerCloseJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAssetsPerClose)  
            this.percentOfAssetsToANNJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.PercentOfAssetsToANN) 
            this.aUMPerCloseJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMPerClose)
            this.aNNPerCloseJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNPerClose)
            this.feePerClientPerMonthJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FeePerClientPerMonth)
            this.trailYearsJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TrailYears)
            this.aNNCommRevPerCloseJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommRevPerClose)
            this.aNNTrailPerYearJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailPerYear)
            this.aUMFeePerMonthJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMFeePerMonth)
            this.aNNCommPerMonthJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommPerMonth)
            this.aNNTrailForFutureYearsPerMonthJan = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailForFutureYearsPerMonth)
              break;
          case 2:
            this.classPerMonthFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClassesPerMonth)
            this.mailDropPerMonthFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.MailDropPerMonth)
            this.costPerMailerFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerMailer) 
            this.costPerEventFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerEvent)  
            this.totalCostPerMonthFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalCostPerMonth)  
            this.totalBURegisteredPerClassFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalBURegisteredPerClass) 
            this.cancelRateFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CancelRate)
            this.totalAttendeesFeb  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAttendees)
            this.bookingRateFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.BookingRate)
            this.numberOfFirstApptsFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.NumberOfFirstAppts)
            this.closeRateFeb  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CloseRate)
            this.firstApptsConvertingToCloseFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FirstApptsConvertingToClose)
            this.closesPerMonthFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClosesPerMonth)
            this.totalAssetsPerCloseFeb  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAssetsPerClose) 
            this.percentOfAssetsToANNFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.PercentOfAssetsToANN) 
            this.aUMPerCloseFeb  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMPerClose)
            this.aNNPerCloseFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNPerClose)
            this.feePerClientPerMonthFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FeePerClientPerMonth)
            this.trailYearsFeb  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TrailYears) 
            this.aNNCommRevPerCloseFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommRevPerClose)
            this.aNNTrailPerYearFeb  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailPerYear)
            this.aUMFeePerMonthFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMFeePerMonth)
            this.aNNCommPerMonthFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommPerMonth)
            this.aNNTrailForFutureYearsPerMonthFeb = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailForFutureYearsPerMonth)
              break;
          case 3:
            this.classPerMonthMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClassesPerMonth) 
            this.mailDropPerMonthMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.MailDropPerMonth)
            this.costPerMailerMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerMailer) 
            this.costPerEventMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerEvent)  
            this.totalCostPerMonthMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalCostPerMonth)
            this.totalBURegisteredPerClassMar= this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalBURegisteredPerClass)
            this.cancelRateMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CancelRate)
            this.totalAttendeesMar  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAttendees)
            this.bookingRateMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.BookingRate)
            this.numberOfFirstApptsMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.NumberOfFirstAppts)
            this.closeRateMar  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CloseRate)
            this.firstApptsConvertingToCloseMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FirstApptsConvertingToClose)
            this.closesPerMonthMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClosesPerMonth)
            this.totalAssetsPerCloseMar  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAssetsPerClose) 
            this.percentOfAssetsToANNMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.PercentOfAssetsToANN) 
            this.aUMPerCloseMar  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMPerClose)
            this.aNNPerCloseMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNPerClose)
            this.feePerClientPerMonthMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FeePerClientPerMonth)
            this.trailYearsMar  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TrailYears)
            this.aNNCommRevPerCloseMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommRevPerClose)
            this.aNNTrailPerYearMar  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailPerYear)
            this.aUMFeePerMonthMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMFeePerMonth)
            this.aNNCommPerMonthMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommPerMonth)
            this.aNNTrailForFutureYearsPerMonthMar = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailForFutureYearsPerMonth)
              break;
          case 4:
            this.classPerMonthApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClassesPerMonth )
            this.mailDropPerMonthApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.MailDropPerMonth)
            this.costPerMailerApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerMailer) 
            this.costPerEventApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerEvent) 
            this.totalCostPerMonthApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalCostPerMonth)  
            this.totalBURegisteredPerClassApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalBURegisteredPerClass) 
            this.cancelRateApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CancelRate)
            this.totalAttendeesApr  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAttendees)
            this.bookingRateApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.BookingRate)
            this.numberOfFirstApptsApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.NumberOfFirstAppts)
            this.closeRateApr  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CloseRate)
            this.firstApptsConvertingToCloseApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FirstApptsConvertingToClose)
            this.closesPerMonthApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClosesPerMonth)
            this.totalAssetsPerCloseApr  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAssetsPerClose) 
            this.percentOfAssetsToANNApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.PercentOfAssetsToANN) 
            this.aUMPerCloseApr  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMPerClose)
            this.aNNPerCloseApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNPerClose)
            this.feePerClientPerMonthApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FeePerClientPerMonth)
            this.trailYearsApr  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TrailYears)
            this.aNNCommRevPerCloseApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommRevPerClose)
            this.aNNTrailPerYearApr  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailPerYear)
            this.aUMFeePerMonthApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMFeePerMonth)
            this.aNNCommPerMonthApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommPerMonth)
            this.aNNTrailForFutureYearsPerMonthApr = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailForFutureYearsPerMonth)
              break;
          case 5:
            this.classPerMonthMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClassesPerMonth) 
            this.mailDropPerMonthMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.MailDropPerMonth)
            this.costPerMailerMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerMailer) 
            this.costPerEventMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerEvent) 
            this.totalCostPerMonthMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalCostPerMonth) 
            this.totalBURegisteredPerClassMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalBURegisteredPerClass)
            this.cancelRateMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CancelRate)
            this.totalAttendeesMay  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAttendees)
            this.bookingRateMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.BookingRate)
            this.numberOfFirstApptsMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.NumberOfFirstAppts)
            this.closeRateMay  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CloseRate)
            this.firstApptsConvertingToCloseMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FirstApptsConvertingToClose)
            this.closesPerMonthMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClosesPerMonth)
            this.totalAssetsPerCloseMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAssetsPerClose) 
            this.percentOfAssetsToANNMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.PercentOfAssetsToANN) 
            this.aUMPerCloseMay  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMPerClose)
            this.aNNPerCloseMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNPerClose)
            this.feePerClientPerMonthMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FeePerClientPerMonth)
            this.trailYearsMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TrailYears)
            this.aNNCommRevPerCloseMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommRevPerClose)
            this.aNNTrailPerYearMay  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailPerYear)
            this.aUMFeePerMonthMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMFeePerMonth)
            this.aNNCommPerMonthMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommPerMonth)
            this.aNNTrailForFutureYearsPerMonthMay = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailForFutureYearsPerMonth)
              break;
          case 6:
            this.classPerMonthJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClassesPerMonth)
            this.mailDropPerMonthJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.MailDropPerMonth)
            this.costPerMailerJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerMailer) 
            this.costPerEventJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerEvent)  
            this.totalCostPerMonthJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalCostPerMonth) 
            this.totalBURegisteredPerClassJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalBURegisteredPerClass)
            this.cancelRateJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CancelRate)
            this.totalAttendeesJun  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAttendees)
            this.bookingRateJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.BookingRate)
            this.numberOfFirstApptsJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.NumberOfFirstAppts)
            this.closeRateJun  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CloseRate)
            this.firstApptsConvertingToCloseJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FirstApptsConvertingToClose)
            this.closesPerMonthJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClosesPerMonth)
            this.totalAssetsPerCloseJun  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAssetsPerClose) 
            this.percentOfAssetsToANNJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.PercentOfAssetsToANN) 
            this.aUMPerCloseJun  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMPerClose)
            this.aNNPerCloseJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNPerClose)
            this.feePerClientPerMonthJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FeePerClientPerMonth)
            this.trailYearsJun  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TrailYears)
            this.aNNCommRevPerCloseJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommRevPerClose)
            this.aNNTrailPerYearJun  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailPerYear)
            this.aUMFeePerMonthJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMFeePerMonth)
            this.aNNCommPerMonthJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommPerMonth)
            this.aNNTrailForFutureYearsPerMonthJun = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailForFutureYearsPerMonth)
              break;
          case 7:
            this.classPerMonthJul= this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClassesPerMonth )
            this.mailDropPerMonthJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.MailDropPerMonth)
            this.costPerMailerJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerMailer)
            this.costPerEventJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerEvent)   
            this.totalCostPerMonthJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalCostPerMonth) 
            this.totalBURegisteredPerClassJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalBURegisteredPerClass) 
            this.cancelRateJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CancelRate)
            this.totalAttendeesJul  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAttendees)
            this.bookingRateJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.BookingRate)
            this.numberOfFirstApptsJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.NumberOfFirstAppts)
            this.closeRateJul  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CloseRate)
            this.firstApptsConvertingToCloseJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FirstApptsConvertingToClose)
            this.closesPerMonthJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClosesPerMonth)
            this.totalAssetsPerCloseJul  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAssetsPerClose) 
            this.percentOfAssetsToANNJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.PercentOfAssetsToANN) 
            this.aUMPerCloseJul  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMPerClose)
            this.aNNPerCloseJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNPerClose)
            this.feePerClientPerMonthJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FeePerClientPerMonth)
            this.trailYearsJul  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TrailYears)
            this.aNNCommRevPerCloseJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommRevPerClose)
            this.aNNTrailPerYearJul  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailPerYear)
            this.aUMFeePerMonthJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMFeePerMonth)
            this.aNNCommPerMonthJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommPerMonth)
            this.aNNTrailForFutureYearsPerMonthJul = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailForFutureYearsPerMonth)
              break;
          case 8:
            this.classPerMonthAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClassesPerMonth )
            this.mailDropPerMonthAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.MailDropPerMonth)
            this.costPerMailerAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerMailer) 
            this.costPerEventAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerEvent) 
            this.totalCostPerMonthAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalCostPerMonth) 
            this.totalBURegisteredPerClassAug  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalBURegisteredPerClass)
            this.cancelRateAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CancelRate)
            this.totalAttendeesAug  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAttendees)
            this.bookingRateAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.BookingRate)
            this.numberOfFirstApptsAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.NumberOfFirstAppts)
            this.closeRateAug  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CloseRate)
            this.firstApptsConvertingToCloseAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FirstApptsConvertingToClose)
            this.closesPerMonthAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClosesPerMonth)
            this.totalAssetsPerCloseAug  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAssetsPerClose) 
            this.percentOfAssetsToANNAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.PercentOfAssetsToANN) 
            this.aUMPerCloseAug  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMPerClose)
            this.aNNPerCloseAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNPerClose)
            this.feePerClientPerMonthAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FeePerClientPerMonth)
            this.trailYearsAug  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TrailYears)
            this.aNNCommRevPerCloseAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommRevPerClose)
            this.aNNTrailPerYearAug  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailPerYear)
            this.aUMFeePerMonthAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMFeePerMonth)
            this.aNNCommPerMonthAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommPerMonth)
            this.aNNTrailForFutureYearsPerMonthAug = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailForFutureYearsPerMonth)
              break;
          case 9:
            this.classPerMonthSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClassesPerMonth)
            this.mailDropPerMonthSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.MailDropPerMonth)
            this.costPerMailerSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerMailer) 
            this.costPerEventSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerEvent)  
            this.totalCostPerMonthSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalCostPerMonth)  
            this.totalBURegisteredPerClassSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalBURegisteredPerClass)
            this.cancelRateSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CancelRate)
            this.totalAttendeesSep  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAttendees)
            this.bookingRateSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.BookingRate)
            this.numberOfFirstApptsSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.NumberOfFirstAppts)
            this.closeRateSep  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CloseRate)
            this.firstApptsConvertingToCloseSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FirstApptsConvertingToClose)
            this.closesPerMonthSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClosesPerMonth)
            this.totalAssetsPerCloseSep  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAssetsPerClose) 
            this.percentOfAssetsToANNSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.PercentOfAssetsToANN) 
            this.aUMPerCloseSep  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMPerClose)
            this.aNNPerCloseSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNPerClose)
            this.feePerClientPerMonthSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FeePerClientPerMonth)
            this.trailYearsSep  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TrailYears)
            this.aNNCommRevPerCloseSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommRevPerClose)
            this.aNNTrailPerYearSep  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailPerYear)
            this.aUMFeePerMonthSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMFeePerMonth)
            this.aNNCommPerMonthSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommPerMonth)
            this.aNNTrailForFutureYearsPerMonthSep = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailForFutureYearsPerMonth)
              break;
          case 10:
            this.classPerMonthOct = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClassesPerMonth) 
            this.mailDropPerMonthOct = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.MailDropPerMonth)
            this.costPerMailerOct = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerMailer)
            this.costPerEventOct = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerEvent)   
            this.totalCostPerMonthOct = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalCostPerMonth)   
            this.totalBURegisteredPerClassOct = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalBURegisteredPerClass)  
            this.cancelRateOct = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CancelRate)
            this.totalAttendeesOct  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAttendees)
            this.bookingRateOct = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.BookingRate)
            this.numberOfFirstApptsOct = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.NumberOfFirstAppts)
            this.closeRateOct  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CloseRate)
            this.firstApptsConvertingToCloseOct = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FirstApptsConvertingToClose)
            this.closesPerMonthOct = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClosesPerMonth)
            this.totalAssetsPerCloseOct  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAssetsPerClose) 
            this.percentOfAssetsToANNOct = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.PercentOfAssetsToANN) 
            this.aUMPerCloseOct  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMPerClose)
            this.aNNPerCloseOct = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNPerClose)
            this.feePerClientPerMonthOct  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FeePerClientPerMonth)
            this.trailYearsOct   = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TrailYears)
            this.aNNCommRevPerCloseOct  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommRevPerClose)
            this.aNNTrailPerYearOct   = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailPerYear)
            this.aUMFeePerMonthOct  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMFeePerMonth)
            this.aNNCommPerMonthOct  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommPerMonth)
            this.aNNTrailForFutureYearsPerMonthOct  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailForFutureYearsPerMonth)
              break;
          case 11:
            this.classPerMonthNov= this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClassesPerMonth) 
            this.mailDropPerMonthNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.MailDropPerMonth)
            this.costPerMailerNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerMailer) 
            this.costPerEventNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerEvent) 
            this.totalCostPerMonthNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalCostPerMonth) 
            this.totalBURegisteredPerClassNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalBURegisteredPerClass)
            this.cancelRateNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CancelRate)
            this.totalAttendeesNov  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAttendees)
            this.bookingRateNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.BookingRate)
            this.numberOfFirstApptsNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.NumberOfFirstAppts)
            this.closeRateNov  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CloseRate)
            this.firstApptsConvertingToCloseNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FirstApptsConvertingToClose)
            this.closesPerMonthNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClosesPerMonth)
            this.totalAssetsPerCloseNov  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAssetsPerClose) 
            this.percentOfAssetsToANNNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.PercentOfAssetsToANN) 
            this.aUMPerCloseNov  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMPerClose)
            this.aNNPerCloseNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNPerClose)
            this.feePerClientPerMonthNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FeePerClientPerMonth)
            this.trailYearsNov  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TrailYears)
            this.aNNCommRevPerCloseNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommRevPerClose)
            this.aNNTrailPerYearNov  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailPerYear)
            this.aUMFeePerMonthNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMFeePerMonth)
            this.aNNCommPerMonthNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommPerMonth)
            this.aNNTrailForFutureYearsPerMonthNov = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailForFutureYearsPerMonth)
              break;
          case 12:
            this.classPerMonthDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClassesPerMonth) 
            this.mailDropPerMonthDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.MailDropPerMonth)
            this.costPerMailerDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerMailer) 
            this.costPerEventDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CostPerEvent) 
            this.totalCostPerMonthDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalCostPerMonth)   
            this.totalBURegisteredPerClassDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalBURegisteredPerClass)
            this.cancelRateDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CancelRate)
            this.totalAttendeesDec  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAttendees)
            this.bookingRateDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.BookingRate)
            this.numberOfFirstApptsDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.NumberOfFirstAppts)
            this.closeRateDec  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.CloseRate)
            this.firstApptsConvertingToCloseDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FirstApptsConvertingToClose)
            this.closesPerMonthDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ClosesPerMonth)
            this.totalAssetsPerCloseDec  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TotalAssetsPerClose) 
            this.percentOfAssetsToANNDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.PercentOfAssetsToANN) 
            this.aUMPerCloseDec  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMPerClose)
            this.aNNPerCloseDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNPerClose)
            this.feePerClientPerMonthDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.FeePerClientPerMonth)
            this.trailYearsDec  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.TrailYears)
            this.aNNCommRevPerCloseDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommRevPerClose)
            this.aNNTrailPerYearDec  = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailPerYear)
            this.aUMFeePerMonthDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.AUMFeePerMonth)
            this.aNNCommPerMonthDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNCommPerMonth)
            this.aNNTrailForFutureYearsPerMonthDec = this.commonFunctions.AddThousandSeparatorFormat(selectedMonthData.ANNTrailForFutureYearsPerMonth)
              break; 
        } 
      }
    } 
  }

  AssignClassesPerMonth(selectedmonth : any)
  {
    let selectedRepData : RepData 
    let selectedMonthData :  RepDataPerYear   
    selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
    selectedMonthData =selectedRepData.RepDataDetails.find( y=> y.Year  == this.selectedYear  && y.Month == selectedmonth.toString() )  as  RepDataPerYear   
    if (selectedMonthData != undefined)
    { 
      switch (selectedmonth)
      {
        case 1:
            selectedMonthData.ClassesPerMonth = this.commonFunctions.RemoveThousandSeparatorFormat(this.classPerMonthJan)
            break;
        case 2:
            selectedMonthData.ClassesPerMonth = this.commonFunctions.RemoveThousandSeparatorFormat(this.classPerMonthFeb)  
            break;
        case 3:
            selectedMonthData.ClassesPerMonth = this.commonFunctions.RemoveThousandSeparatorFormat(this.classPerMonthMar)  
            break;
        case 4:
            selectedMonthData.ClassesPerMonth = this.commonFunctions.RemoveThousandSeparatorFormat(this.classPerMonthApr)  
            break;
        case 5:
            selectedMonthData.ClassesPerMonth = this.commonFunctions.RemoveThousandSeparatorFormat(this.classPerMonthMay)  
            break;
        case 6:
            selectedMonthData.ClassesPerMonth = this.commonFunctions.RemoveThousandSeparatorFormat(this.classPerMonthJun)  
            break;
        case 7:
            selectedMonthData.ClassesPerMonth = this.commonFunctions.RemoveThousandSeparatorFormat(this.classPerMonthJul)  
            break;
        case 8:
            selectedMonthData.ClassesPerMonth = this.commonFunctions.RemoveThousandSeparatorFormat(this.classPerMonthAug)  
            break;
        case 9:
            selectedMonthData.ClassesPerMonth = this.commonFunctions.RemoveThousandSeparatorFormat(this.classPerMonthSep)  
            break;
        case 10:
            selectedMonthData.ClassesPerMonth = this.commonFunctions.RemoveThousandSeparatorFormat(this.classPerMonthOct)  
            break;
        case 11:
            selectedMonthData.ClassesPerMonth = this.commonFunctions.RemoveThousandSeparatorFormat(this.classPerMonthNov)  
            break;
        case 12:
            selectedMonthData.ClassesPerMonth = this.commonFunctions.RemoveThousandSeparatorFormat(this.classPerMonthDec)  
            break; 
      } 
    }

    //Adding businesscalculatorinput to the local storage
    localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
    localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  }

  AssignCostPerMailer(selectedmonth : any)
  {
    let selectedRepData : RepData 
    let selectedMonthData :  RepDataPerYear   
    selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
    selectedMonthData =selectedRepData.RepDataDetails.find( y=> y.Year  == this.selectedYear  && y.Month == selectedmonth.toString() )  as  RepDataPerYear   
    if (selectedMonthData != undefined)
    { 
      switch (selectedmonth)
      {
        case 1:
            selectedMonthData.CostPerMailer  = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerMailerJan)  
            break;
        case 2:
            selectedMonthData.CostPerMailer = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerMailerFeb)
            break;
        case 3:
            selectedMonthData.CostPerMailer = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerMailerMar)
            break;
        case 4:
            selectedMonthData.CostPerMailer = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerMailerApr)
            break;
        case 5:
            selectedMonthData.CostPerMailer = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerMailerMay)
            break;
        case 6:
            selectedMonthData.CostPerMailer = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerMailerJun)
            break;
        case 7:
            selectedMonthData.CostPerMailer = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerMailerJul)
            break;
        case 8:
            selectedMonthData.CostPerMailer = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerMailerAug)
            break;
        case 9:
            selectedMonthData.CostPerMailer = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerMailerSep)
            break;
        case 10:
            selectedMonthData.CostPerMailer = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerMailerOct)
            break;
        case 11:
            selectedMonthData.CostPerMailer = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerMailerNov)
            break;
        case 12:
            selectedMonthData.CostPerMailer = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerMailerDec)
            break; 
      } 
    }

    //Adding businesscalculatorinput to the local storage
    localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
    localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  }

  AssignCostPerEvent(selectedmonth : any)
  {
    let selectedRepData : RepData 
    let selectedMonthData :  RepDataPerYear   
    selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
    selectedMonthData =selectedRepData.RepDataDetails.find( y=> y.Year  == this.selectedYear  && y.Month == selectedmonth.toString() )  as  RepDataPerYear   
    if (selectedMonthData != undefined)
    { 
      switch (selectedmonth)
      {
        case 1:
            selectedMonthData.CostPerEvent  = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerEventJan)  
            break;
        case 2:
            selectedMonthData.CostPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerEventFeb)
            break;
        case 3:
            selectedMonthData.CostPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerEventMar)
            break;
        case 4:
            selectedMonthData.CostPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerEventApr)
            break;
        case 5:
            selectedMonthData.CostPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerEventMay)
            break;
        case 6:
            selectedMonthData.CostPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerEventJun)
            break;
        case 7:
            selectedMonthData.CostPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerEventJul)
            break;
        case 8:
            selectedMonthData.CostPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerEventAug)
            break;
        case 9:
            selectedMonthData.CostPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerEventSep)
            break;
        case 10:
            selectedMonthData.CostPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerEventOct)
            break;
        case 11:
            selectedMonthData.CostPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerEventNov)
            break;
        case 12:
            selectedMonthData.CostPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerEventDec)
            break; 
      } 
    }

    //Adding businesscalculatorinput to the local storage
    localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
    localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  }

  AssignTotalBURegisteredPerClass(selectedmonth : any)
  {
    let selectedRepData : RepData 
    let selectedMonthData :  RepDataPerYear   
    selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
    selectedMonthData =selectedRepData.RepDataDetails.find( y=> y.Year  == this.selectedYear  && y.Month == selectedmonth.toString() )  as  RepDataPerYear   
    if (selectedMonthData != undefined)
    { 
      switch (selectedmonth)
      {
        case 1:
            selectedMonthData.TotalBURegisteredPerClass  = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalBURegisteredPerClassJan)  
            break;
        case 2:
            selectedMonthData.TotalBURegisteredPerClass = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalBURegisteredPerClassFeb)
            break;
        case 3:
            selectedMonthData.TotalBURegisteredPerClass = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalBURegisteredPerClassMar)
            break;
        case 4:
            selectedMonthData.TotalBURegisteredPerClass = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalBURegisteredPerClassApr)
            break;
        case 5:
            selectedMonthData.TotalBURegisteredPerClass = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalBURegisteredPerClassMay)
            break;
        case 6:
            selectedMonthData.TotalBURegisteredPerClass = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalBURegisteredPerClassJun)
            break;
        case 7:
            selectedMonthData.TotalBURegisteredPerClass = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalBURegisteredPerClassJul)
            break;
        case 8:
            selectedMonthData.TotalBURegisteredPerClass = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalBURegisteredPerClassAug)
            break;
        case 9:
            selectedMonthData.TotalBURegisteredPerClass = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalBURegisteredPerClassSep)
            break;
        case 10:
            selectedMonthData.TotalBURegisteredPerClass = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalBURegisteredPerClassOct)
            break;
        case 11:
            selectedMonthData.TotalBURegisteredPerClass = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalBURegisteredPerClassNov)
            break;
        case 12:
            selectedMonthData.TotalBURegisteredPerClass = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalBURegisteredPerClassDec)
            break; 
      } 
    }

    //Adding businesscalculatorinput to the local storage
    localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
    localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  }

  AssignCancelRate(selectedmonth : any)
  {
    let selectedRepData : RepData 
    let selectedMonthData :  RepDataPerYear   
    selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
    selectedMonthData =selectedRepData.RepDataDetails.find( y=> y.Year  == this.selectedYear  && y.Month == selectedmonth.toString() )  as  RepDataPerYear   
    if (selectedMonthData != undefined)
    { 
      switch (selectedmonth)
      {
        case 1:
            selectedMonthData.CancelRate  = this.commonFunctions.RemoveThousandSeparatorFormat(this.cancelRateJan)  
            break;
        case 2:
            selectedMonthData.CancelRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.cancelRateFeb)
            break;
        case 3:
            selectedMonthData.CancelRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.cancelRateMar)
            break;
        case 4:
            selectedMonthData.CancelRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.cancelRateApr)
            break;
        case 5:
            selectedMonthData.CancelRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.cancelRateMay)
            break;
        case 6:
            selectedMonthData.CancelRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.cancelRateJun)
            break;
        case 7:
            selectedMonthData.CancelRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.cancelRateJul)
            break;
        case 8:
            selectedMonthData.CancelRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.cancelRateAug)
            break;
        case 9:
            selectedMonthData.CancelRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.cancelRateSep)
            break;
        case 10:
            selectedMonthData.CancelRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.cancelRateOct)
            break;
        case 11:
            selectedMonthData.CancelRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.cancelRateNov)
            break;
        case 12:
            selectedMonthData.CancelRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.cancelRateDec)
            break; 
      } 
    }

    //Adding businesscalculatorinput to the local storage
    localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
    localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  }

  AssignTotalAttendees(selectedmonth : any)
  {
    let selectedRepData : RepData 
    let selectedMonthData :  RepDataPerYear   
    selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
    selectedMonthData =selectedRepData.RepDataDetails.find( y=> y.Year  == this.selectedYear  && y.Month == selectedmonth.toString() )  as  RepDataPerYear   
    if (selectedMonthData != undefined)
    { 
      switch (selectedmonth)
      {
        case 1:
            selectedMonthData.TotalAttendees  = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAttendeesJan)  
            break;
        case 2:
            selectedMonthData.TotalAttendees = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAttendeesFeb)
            break;
        case 3:
            selectedMonthData.TotalAttendees = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAttendeesMar)
            break;
        case 4:
            selectedMonthData.TotalAttendees = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAttendeesApr)
            break;
        case 5:
            selectedMonthData.TotalAttendees = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAttendeesMay)
            break;
        case 6:
            selectedMonthData.TotalAttendees = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAttendeesJun)
            break;
        case 7:
            selectedMonthData.TotalAttendees = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAttendeesJul)
            break;
        case 8:
            selectedMonthData.TotalAttendees = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAttendeesAug)
            break;
        case 9:
            selectedMonthData.TotalAttendees = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAttendeesSep)
            break;
        case 10:
            selectedMonthData.TotalAttendees = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAttendeesOct)
            break;
        case 11:
            selectedMonthData.TotalAttendees = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAttendeesNov)
            break;
        case 12:
            selectedMonthData.TotalAttendees = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAttendeesDec)
            break; 
      } 
    }

    //Adding businesscalculatorinput to the local storage
    localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
    localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  }

  AssignBookingRate(selectedmonth : any)
  {
    let selectedRepData : RepData 
    let selectedMonthData :  RepDataPerYear   
    selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
    selectedMonthData =selectedRepData.RepDataDetails.find( y=> y.Year  == this.selectedYear  && y.Month == selectedmonth.toString() )  as  RepDataPerYear   
    if (selectedMonthData != undefined)
    { 
      switch (selectedmonth)
      {
        case 1:
            selectedMonthData.BookingRate  = this.commonFunctions.RemoveThousandSeparatorFormat(this.bookingRateJan)  
            break;
        case 2:
            selectedMonthData.BookingRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.bookingRateFeb)
            break;
        case 3:
            selectedMonthData.BookingRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.bookingRateMar)
            break;
        case 4:
            selectedMonthData.BookingRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.bookingRateApr)
            break;
        case 5:
            selectedMonthData.BookingRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.bookingRateMay)
            break;
        case 6:
            selectedMonthData.BookingRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.bookingRateJun)
            break;
        case 7:
            selectedMonthData.BookingRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.bookingRateJul)
            break;
        case 8:
            selectedMonthData.BookingRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.bookingRateAug)
            break;
        case 9:
            selectedMonthData.BookingRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.bookingRateSep)
            break;
        case 10:
            selectedMonthData.BookingRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.bookingRateOct)
            break;
        case 11:
            selectedMonthData.BookingRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.bookingRateNov)
            break;
        case 12:
            selectedMonthData.BookingRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.bookingRateDec)
            break; 
      } 
    }

    //Adding businesscalculatorinput to the local storage
    localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
    localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  }

  AssignCloseRate(selectedmonth : any)
  {
    let selectedRepData : RepData 
    let selectedMonthData :  RepDataPerYear   
    selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
    selectedMonthData =selectedRepData.RepDataDetails.find( y=> y.Year  == this.selectedYear  && y.Month == selectedmonth.toString() )  as  RepDataPerYear   
    if (selectedMonthData != undefined)
    { 
      switch (selectedmonth)
      {
        case 1:
            selectedMonthData.CloseRate  = this.commonFunctions.RemoveThousandSeparatorFormat(this.closeRateJan)  
            break;
        case 2:
            selectedMonthData.CloseRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.closeRateFeb)
            break;
        case 3:
            selectedMonthData.CloseRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.closeRateMar)
            break;
        case 4:
            selectedMonthData.CloseRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.closeRateApr)
            break;
        case 5:
            selectedMonthData.CloseRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.closeRateMay)
            break;
        case 6:
            selectedMonthData.CloseRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.closeRateJun)
            break;
        case 7:
            selectedMonthData.CloseRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.closeRateJul)
            break;
        case 8:
            selectedMonthData.CloseRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.closeRateAug)
            break;
        case 9:
            selectedMonthData.CloseRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.closeRateSep)
            break;
        case 10:
            selectedMonthData.CloseRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.closeRateOct)
            break;
        case 11:
            selectedMonthData.CloseRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.closeRateNov)
            break;
        case 12:
            selectedMonthData.CloseRate = this.commonFunctions.RemoveThousandSeparatorFormat(this.closeRateDec)
            break; 
      } 
    }

    //Adding businesscalculatorinput to the local storage
    localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
    localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  }

  AssignTotalAssetsPerClose(selectedmonth : any)
  {
    let selectedRepData : RepData 
    let selectedMonthData :  RepDataPerYear   
    selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
    selectedMonthData =selectedRepData.RepDataDetails.find( y=> y.Year  == this.selectedYear  && y.Month == selectedmonth.toString() )  as  RepDataPerYear   
    if (selectedMonthData != undefined)
    { 
      switch (selectedmonth)
      {
        case 1:
            selectedMonthData.TotalAssetsPerClose  = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAssetsPerCloseJan)  
            break;
        case 2:
            selectedMonthData.TotalAssetsPerClose = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAssetsPerCloseFeb)
            break;
        case 3:
            selectedMonthData.TotalAssetsPerClose = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAssetsPerCloseMar)
            break;
        case 4:
            selectedMonthData.TotalAssetsPerClose = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAssetsPerCloseApr)
            break;
        case 5:
            selectedMonthData.TotalAssetsPerClose = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAssetsPerCloseMay)
            break;
        case 6:
            selectedMonthData.TotalAssetsPerClose = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAssetsPerCloseJun)
            break;
        case 7:
            selectedMonthData.TotalAssetsPerClose = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAssetsPerCloseJul)
            break;
        case 8:
            selectedMonthData.TotalAssetsPerClose = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAssetsPerCloseAug)
            break;
        case 9:
            selectedMonthData.TotalAssetsPerClose = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAssetsPerCloseSep)
            break;
        case 10:
            selectedMonthData.TotalAssetsPerClose = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAssetsPerCloseOct)
            break;
        case 11:
            selectedMonthData.TotalAssetsPerClose = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAssetsPerCloseNov)
            break;
        case 12:
            selectedMonthData.TotalAssetsPerClose = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAssetsPerCloseDec)
            break; 
      } 
    }

    //Adding businesscalculatorinput to the local storage
    localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput)); 
    localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1"); 
  }

  AssignPercentOfAssetsToANN(selectedmonth : any)
  {
    let selectedRepData : RepData 
    let selectedMonthData :  RepDataPerYear   
    selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
    selectedMonthData =selectedRepData.RepDataDetails.find( y=> y.Year  == this.selectedYear  && y.Month == selectedmonth.toString() )  as  RepDataPerYear   
    if (selectedMonthData != undefined)
    { 
      switch (selectedmonth)
      {
        case 1:
            selectedMonthData.PercentOfAssetsToANN  = this.commonFunctions.RemoveThousandSeparatorFormat(this.percentOfAssetsToANNJan)  
            break;
        case 2:
            selectedMonthData.PercentOfAssetsToANN = this.commonFunctions.RemoveThousandSeparatorFormat(this.percentOfAssetsToANNFeb)
            break;
        case 3:
            selectedMonthData.PercentOfAssetsToANN = this.commonFunctions.RemoveThousandSeparatorFormat(this.percentOfAssetsToANNMar)
            break;
        case 4:
            selectedMonthData.PercentOfAssetsToANN = this.commonFunctions.RemoveThousandSeparatorFormat(this.percentOfAssetsToANNApr)
            break;
        case 5:
            selectedMonthData.PercentOfAssetsToANN = this.commonFunctions.RemoveThousandSeparatorFormat(this.percentOfAssetsToANNMay)
            break;
        case 6:
            selectedMonthData.PercentOfAssetsToANN = this.commonFunctions.RemoveThousandSeparatorFormat(this.percentOfAssetsToANNJun)
            break;
        case 7:
            selectedMonthData.PercentOfAssetsToANN = this.commonFunctions.RemoveThousandSeparatorFormat(this.percentOfAssetsToANNJul)
            break;
        case 8:
            selectedMonthData.PercentOfAssetsToANN = this.commonFunctions.RemoveThousandSeparatorFormat(this.percentOfAssetsToANNAug)
            break;
        case 9:
            selectedMonthData.PercentOfAssetsToANN = this.commonFunctions.RemoveThousandSeparatorFormat(this.percentOfAssetsToANNSep)
            break;
        case 10:
            selectedMonthData.PercentOfAssetsToANN = this.commonFunctions.RemoveThousandSeparatorFormat(this.percentOfAssetsToANNOct)
            break;
        case 11:
            selectedMonthData.PercentOfAssetsToANN = this.commonFunctions.RemoveThousandSeparatorFormat(this.percentOfAssetsToANNNov)
            break;
        case 12:
            selectedMonthData.PercentOfAssetsToANN = this.commonFunctions.RemoveThousandSeparatorFormat(this.percentOfAssetsToANNDec)
            break; 
      } 
    }

    //Adding businesscalculatorinput to the local storage
    localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput)); 
    localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1"); 
  }

  AssignRep2Info( )
  { 
    let selectedRepData : RepData  
    selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
      
    selectedRepData.RepFirstName  =  this.repFirstName 
    selectedRepData.RepLastName  =  this.repLastName 
    selectedRepData.BaseSalary  =  this.baseSalary 
    if(this.repPayPercentage > 100){
      this.alertService.error("Rep pay percentage should not exceed 100")
    }
    selectedRepData.RepPayPercentage  =  this.repPayPercentage

    //Adding businesscalculatorinput to the local storage
    localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
    localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  }

  AssignRepStartDate(event: any) {  
    this.alertService.clear();
    let selectedRepData : RepData  
    selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)        
    if (event == null || event =='Invalid Date' || event == 'undefined' ){ 
      this.repStartDate =null
      selectedRepData.RepStartDate = null
    }
    else { 
      let enddate = this.repEndDate   
      if (enddate == null || enddate =='Invalid Date' || enddate == 'undefined' ){ 
        //No end date defined just add start date
        this.repStartDate =new Date(event)        
        selectedRepData.RepStartDate  =  this.repStartDate    
        localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1"); // Marked changed only when a real date changed happen 
      }
      else {
        if ((new Date(event) >enddate) || (new Date(event).toDateString() == new Date(enddate).toDateString()))
        {
          this.alertService.error('Invalid Date Range')    
        }
        else{
          this.repStartDate =new Date(event)        
          selectedRepData.RepStartDate  =  this.repStartDate    
          localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1"); // Marked changed only when a real date changed happen 
        } 
      }
    }  
     //Adding businesscalculatorinput to the local storage
     localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput)); 
     //NOT marking HAS_UNSAVED_FP4FA_DATA to 1 here... that is done within else part above
  }
    
  AssignRepEndDate(event: any) {   
      this.alertService.clear();
      let selectedRepData : RepData  
      selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)     
      if (event == null || event =='Invalid Date' || event == 'undefined'){ 
        this.repEndDate =null
        selectedRepData.RepEndDate = null
      }
      else { 
        let startdate = this.repStartDate   
        if (startdate == null || startdate =='Invalid Date' || startdate == 'undefined' ){ 
          //No start date defined just add end date
          this.repEndDate =new Date(event)   
          selectedRepData.RepEndDate  =  this.repEndDate   
          localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1"); // Marked changed only when a real date changed happen   
        }
        else {
          if ((startdate>new Date(event)) || (new Date(event).toDateString() == new Date(startdate).toDateString()))
          {
            this.alertService.error('Invalid Date Range')    
          }
          else{ 
            this.repEndDate =new Date(event)   
            selectedRepData.RepEndDate  =  this.repEndDate   
            localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1"); // Marked changed only when a real date changed happen   
          }
        }
      }   
      //Adding businesscalculatorinput to the local storage
      localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput)); 
      //NOT marking HAS_UNSAVED_FP4FA_DATA to 1 here... that is done within else part above 
     
  }

  FillAllWithCurrentPageData()
  { 
    if (confirm("Are you sure you want to fill all future years with this years data?")){
      let selectedRepData : RepData  
      selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
     
      //loop through all years
      for(let i=0; i<this.yeardatalist.length; i++){ 
        let currentYear: Number = Number(this.yeardatalist[i].Year)
        //fill future years data will current year data
        if (currentYear> this.selectedYear)
        { 
            //loop through all months
            for(let j=1; j<=12; j++){ 
              let currentMonth = j
              //gets data of selected page
              let selectedMonthData :  RepDataPerYear 
              selectedMonthData =selectedRepData.RepDataDetails.find( y=> y.Year  == this.selectedYear.toString()  && y.Month == currentMonth.toString() )  as  RepDataPerYear  
  
              // asign data to future years
              let futureYearData :  RepDataPerYear
              futureYearData =selectedRepData.RepDataDetails.find( y=> y.Year  == currentYear.toString()  && y.Month == currentMonth.toString() )  as  RepDataPerYear
              futureYearData.ClassesPerMonth = selectedMonthData.ClassesPerMonth
              futureYearData.CostPerMailer = selectedMonthData.CostPerMailer
              futureYearData.CostPerEvent = selectedMonthData.CostPerEvent
              futureYearData.TotalBURegisteredPerClass = selectedMonthData.TotalBURegisteredPerClass
              futureYearData.CancelRate = selectedMonthData.CancelRate
              futureYearData.BookingRate = selectedMonthData.BookingRate
              futureYearData.CloseRate = selectedMonthData.CloseRate
              futureYearData.TotalAssetsPerClose = selectedMonthData.TotalAssetsPerClose
              futureYearData.PercentOfAssetsToANN = selectedMonthData.PercentOfAssetsToANN 
            }
          }  
      }
      // calculate subtotals
      this.CalculateRepDataSubTotals()
    }
  }
  
  CalculateRepDataSubTotals()
  { 
    //calculate rep data sub totals
    this.FP4FAdataService.calculaterepdatasubtotals(this.loggedAgent, this.businesscalculatorinput).subscribe(data => {
      this.businesscalculatorinput  = data  
      localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput)); 
      localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1"); 
      this.LoadRepData()
      this.LoadRepDataAddtionalchannels()  
    },
    error => {  
    this.alertService.error('An error occurred while getting FP4FA data')
    });
  }

  DeleteSelectedRep()
  {
    this.alertService.clear()
    let selectedRepData : RepData  
    selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)
    if (selectedRepData.IsOwner ===true)
    {
      this.alertService.error('You cannot delete 1st Rep (Owner)')
    }  
    else
    {
      if (confirm("Are you sure you want to delete selected rep?")) { 
        this.RemoveEmployee()
        this.businesscalculatorinput.RepData.splice(this.businesscalculatorinput.RepData.findIndex( x=> x.AgentID  == this.selectedRepID), 1)
        this.repdatalist =  this.businesscalculatorinput.RepData       
        this.selectedRepID =this.businesscalculatorinput.RepData.find( x=> x.IsOwner  === true).AgentID
        this.selectedYear = Math.min.apply(Math,  this.yeardatalist.map(function(o) { return o.Year; })) 
        localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput)); 
        localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
        this.LoadRepData()
        this.LoadRepDataAddtionalchannels()
      }
    } 
  }
  
  OpenFillAcrossModal(template: TemplateRef<any>, rowType: string) { 
   // console.log(template)
    this.alertService.clear()
    this.currentRowType = rowType;
    this.fillAcrossValue ="";
    this.modalRef = this.modalService.show(template);
  }

FillAcross()
{ 

  let year = ""
  //validate 
  if (this.fillAcrossValue == undefined || this.fillAcrossValue == "")
  { 
    this.alertService.error('Please enter fill across value.');
    return;
  } 

  let selectedRepData : RepData 
  let selectedYearData :  RepDataPerYear []  
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedYearData =selectedRepData.RepDataDetails.filter( y=> y.Year  == this.selectedYear)  as  RepDataPerYear[] 

  switch (this.currentRowType)
  {
    //workshops 
    case 'classPerMonth':
      selectedYearData.forEach( (element) => {
        element.ClassesPerMonth = this.fillAcrossValue;
      }); 
      break; 
    case 'costPerMailer':
      selectedYearData.forEach( (element) => {
        element.CostPerMailer = this.fillAcrossValue;
      }); 
      break; 
    case 'costPerEvent':
      selectedYearData.forEach( (element) => {
        element.CostPerEvent = this.fillAcrossValue;
      }); 
      break; 
    case 'totalBURegisteredPerClass':
      selectedYearData.forEach( (element) => {
        element.TotalBURegisteredPerClass = this.fillAcrossValue;
      }); 
      break;   
    case 'cancelRate':
      selectedYearData.forEach( (element) => {
        element.CancelRate = this.fillAcrossValue;
      }); 
      break; 
    case 'bookingRate':
      selectedYearData.forEach( (element) => {
        element.BookingRate = this.fillAcrossValue;
      }); 
      break; 
    case 'closeRate':
      selectedYearData.forEach( (element) => {
        element.CloseRate = this.fillAcrossValue;
      }); 
      break; 
    case 'totalAssetsPerClose':
      selectedYearData.forEach( (element) => {
        element.TotalAssetsPerClose = this.fillAcrossValue;
      }); 
      break; 
    case 'percentOfAssetsToANN':
      selectedYearData.forEach( (element) => {
        element.PercentOfAssetsToANN = this.fillAcrossValue;
      }); 
      break;    
  }    

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  this.LoadRepData();
  this.modalRef.hide();  

}

FillAcrossAllYears()
{ 

  let year = ""
  //validate 
  if (this.fillAcrossValue == undefined || this.fillAcrossValue == "")
  { 
    this.alertService.error('Please enter fill across value.');
    return;
  } 

  let selectedRepData : RepData 
  let selectedYearData :  RepDataPerYear []  
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  

  for(let i=0; i<this.yeardatalist.length; i++){ 
    let currentYear =  this.yeardatalist[i].Year 
    selectedYearData =selectedRepData.RepDataDetails.filter( y=> y.Year  == currentYear)  as  RepDataPerYear[] 

    switch (this.currentRowType)
    {
      case 'classPerMonth':
        selectedYearData.forEach( (element) => {
          element.ClassesPerMonth = this.fillAcrossValue;
        }); 
        break; 
      case 'costPerMailer':
        selectedYearData.forEach( (element) => {
          element.CostPerMailer = this.fillAcrossValue;
        }); 
        break; 
      case 'costPerEvent':
        selectedYearData.forEach( (element) => {
          element.CostPerEvent = this.fillAcrossValue;
        }); 
        break; 
      case 'totalBURegisteredPerClass':
        selectedYearData.forEach( (element) => {
          element.TotalBURegisteredPerClass = this.fillAcrossValue;
        }); 
        break;   
      case 'cancelRate':
        selectedYearData.forEach( (element) => {
          element.CancelRate = this.fillAcrossValue;
        }); 
        break; 
      case 'bookingRate':
        selectedYearData.forEach( (element) => {
          element.BookingRate = this.fillAcrossValue;
        }); 
        break; 
      case 'closeRate':
        selectedYearData.forEach( (element) => {
          element.CloseRate = this.fillAcrossValue;
        }); 
        break; 
      case 'totalAssetsPerClose':
        selectedYearData.forEach( (element) => {
          element.TotalAssetsPerClose = this.fillAcrossValue;
        }); 
        break; 
      case 'percentOfAssetsToANN':
        selectedYearData.forEach( (element) => {
          element.PercentOfAssetsToANN = this.fillAcrossValue;
        }); 
        break;     
    }    
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  this.LoadRepData();
  this.modalRef.hide();  

}

FillAcrossDefault(rowType: string)
{

  this.currentRowType = rowType;
  let selectedRepData : RepData 
  let selectedYearData :  RepDataPerYear []  
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedYearData =selectedRepData.RepDataDetails.filter( y=> y.Year  == this.selectedYear)  as  RepDataPerYear[] 

  let businesscalculatordefaults   = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_DEFAULTS)) as BusinessCalculatorDefaults;

  switch (this.currentRowType)
  {
    //workshops
    case 'classPerMonth':
      selectedYearData.forEach( (element) => {
        element.ClassesPerMonth = businesscalculatordefaults.ClassesPerMonth;
      }); 
      break; 
    case 'costPerMailer':
      selectedYearData.forEach( (element) => {
        element.CostPerMailer = businesscalculatordefaults.CostPerMailer;
      }); 
      break; 
    case 'costPerEvent':
      selectedYearData.forEach( (element) => {
        element.CostPerEvent = businesscalculatordefaults.CostPerEvent;
      }); 
      break; 
    case 'totalBURegisteredPerClass':
      selectedYearData.forEach( (element) => {
        element.TotalBURegisteredPerClass = businesscalculatordefaults.TotalBURegisteredPerClass;
      }); 
      break;   
    case 'cancelRate':
      selectedYearData.forEach( (element) => {
        element.CancelRate = businesscalculatordefaults.CancelRate;
      }); 
      break; 
    case 'bookingRate':
      selectedYearData.forEach( (element) => {
        element.BookingRate = businesscalculatordefaults.BookingRate;
      }); 
      break; 
    case 'closeRate':
      selectedYearData.forEach( (element) => {
        element.CloseRate = businesscalculatordefaults.CloseRate;
      }); 
      break; 
    case 'totalAssetsPerClose':
      selectedYearData.forEach( (element) => {
        element.TotalAssetsPerClose = businesscalculatordefaults.TotalAssetsPerClose;
      }); 
      break; 
    case 'percentOfAssetsToANN':
      selectedYearData.forEach( (element) => {
        element.PercentOfAssetsToANN = businesscalculatordefaults.PercentOfAssetsToANN;
      }); 
      break;     
  }  
  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput)); 
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1"); 
  this.LoadRepData();

}

ClearAcross(rowType: string)
{
  this.currentRowType = rowType;
  let year = ""
  //validate if the value is an integer
  if(!isNaN(Number(this.currentRowType))){
    year = this.currentRowType
    this.currentRowType = "year"
  }

  let selectedRepData : RepData 
  let selectedYearData :  RepDataPerYear []  
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedYearData =selectedRepData.RepDataDetails.filter( y=> y.Year  == this.selectedYear)  as  RepDataPerYear[] 

  switch (this.currentRowType)
  {
    case 'classPerMonth':
      selectedYearData.forEach( (element) => {
        element.ClassesPerMonth = 0;
      }); 
      break; 
    case 'costPerMailer':
      selectedYearData.forEach( (element) => {
        element.CostPerMailer = 0;
      }); 
      break; 
    case 'costPerEvent':
      selectedYearData.forEach( (element) => {
        element.CostPerEvent = 0;
      }); 
      break; 
    case 'totalBURegisteredPerClass':
      selectedYearData.forEach( (element) => {
        element.TotalBURegisteredPerClass = 0;
      }); 
      break;   
    case 'cancelRate':
      selectedYearData.forEach( (element) => {
        element.CancelRate = 0;
      }); 
      break; 
    case 'bookingRate':
      selectedYearData.forEach( (element) => {
        element.BookingRate = 0;
      }); 
      break; 
    case 'closeRate':
      selectedYearData.forEach( (element) => {
        element.CloseRate = 0;
      }); 
      break; 
    case 'totalAssetsPerClose':
      selectedYearData.forEach( (element) => {
        element.TotalAssetsPerClose = 0;
      }); 
      break; 
    case 'percentOfAssetsToANN':
      selectedYearData.forEach( (element) => {
        element.PercentOfAssetsToANN = 0;
      }); 
      break;     
  }   
  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  this.LoadRepData();
}


OpenFillDownModal(template: TemplateRef<any>, rowType: string) { 
  // console.log(template)
   this.alertService.clear()
   this.currentRowType = rowType;
   this.fillDownValue ="";
   this.modalRef = this.modalService.show(template);
 }
//#endregion

//#region  Rep Data Methods - Additional Channels*/
LoadRepDataAddtionalchannels()
{
  //Assign marketing channel IDs
  this.radioSpots = Constants.MarketingChannels.RadioSpots
  this.radioShows = Constants.MarketingChannels.RadioShows
  this.tv = Constants.MarketingChannels.TV

  //Get selected reps
  let selectedRepData : RepData 
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  

  //Rep data additional channel
  let RepDataAddtionalChannelRadioSpots: RepDataAddtionalChannel
  let RepDataAddtionalChannelRadioShows: RepDataAddtionalChannel
  let RepDataAddtionalChannelTV: RepDataAddtionalChannel

  if(selectedRepData.RepDataAddtionalChannels != undefined){
    RepDataAddtionalChannelRadioSpots = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == this.radioSpots )  as  RepDataAddtionalChannel 
    RepDataAddtionalChannelRadioShows = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == this.radioShows )  as  RepDataAddtionalChannel 
    RepDataAddtionalChannelTV = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == this.tv )  as  RepDataAddtionalChannel 
    if (RepDataAddtionalChannelRadioSpots != undefined)
    { 
        this.channelExpenditureRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.ChannelExpenditure)
        this.costPerEventRadioSpots = this.commonFunctions.   AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.CostPerEvent)
        this.totalEventsPerMonthRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.TotalEventsPerMonth) 
        this.leadsPerEventRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.LeadsPerEvent)
        this.leadsConvertedToProspectsRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.LeadsConvertedToProspects)
        this.totalNewProspectsPerMonthRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.TotalNewProspectsPerMonth)
        this.numberOfEW101BookedRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.NumberOfEW101Booked)
        this.numberOfEW101AttendedRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.NumberOfEW101Attended)
        this.firstAppointmentBookedRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.FirstAppointmentBooked)
        this.firstAppointmentAttendedRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.FirstAppointmentAttended)
        this.secondAppointmentBookedRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.SecondAppointmentBooked) 
        this.secondAppointmentAttendedRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.SecondAppointmentAttended)
        this.thirdAppointmentBookedRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.ThirdAppointmentBooked)  
        this.thirdAppointmentAttendedRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.ThirdAppointmentAttended) 
        this.closedRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.Closed)
        this.closingPercentageRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.ClosingPercentage)
        this.totalAssetsPerCloseRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.TotalAssetsPerClose)
        this.percentOfAssetsToANNRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.PercentOfAssetsToANN)
        this.aUMPerCloseRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.AUMPerClose)
        this.aNNPerCloseRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.ANNPerClose)
        this.feePerClientPerMonthRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.FeePerClientPerMonth)
        this.aNNTrailPerYearRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.ANNTrailPerYear)
        this.aUMFeePerMonthRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.AUMFeePerMonth)
        this.aNNCommPerMonthRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots.ANNCommPerMonth)
        this.aNNTrailForFutureYearsPerMonthRadioSpots = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioSpots .ANNTrailForFutureYearsPerMonth)
    } 

    if (RepDataAddtionalChannelRadioShows != undefined)
    { 
        this.channelExpenditureRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.ChannelExpenditure)
        this.costPerEventRadioShows = this.commonFunctions.   AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.CostPerEvent)
        this.totalEventsPerMonthRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.TotalEventsPerMonth) 
        this.leadsPerEventRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.LeadsPerEvent)
        this.leadsConvertedToProspectsRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.LeadsConvertedToProspects)
        this.totalNewProspectsPerMonthRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.TotalNewProspectsPerMonth)
        this.numberOfEW101BookedRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.NumberOfEW101Booked)
        this.numberOfEW101AttendedRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.NumberOfEW101Attended)
        this.firstAppointmentBookedRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.FirstAppointmentBooked)
        this.firstAppointmentAttendedRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.FirstAppointmentAttended)
        this.secondAppointmentBookedRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.SecondAppointmentBooked) 
        this.secondAppointmentAttendedRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.SecondAppointmentAttended)
        this.thirdAppointmentBookedRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.ThirdAppointmentBooked)  
        this.thirdAppointmentAttendedRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.ThirdAppointmentAttended) 
        this.closedRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.Closed)
        this.closingPercentageRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.ClosingPercentage)
        this.totalAssetsPerCloseRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.TotalAssetsPerClose)
        this.percentOfAssetsToANNRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.PercentOfAssetsToANN)
        this.aUMPerCloseRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.AUMPerClose)
        this.aNNPerCloseRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.ANNPerClose)
        this.feePerClientPerMonthRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.FeePerClientPerMonth)
        this.aNNTrailPerYearRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.ANNTrailPerYear)
        this.aUMFeePerMonthRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.AUMFeePerMonth)
        this.aNNCommPerMonthRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.ANNCommPerMonth)
        this.aNNTrailForFutureYearsPerMonthRadioShows = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelRadioShows.ANNTrailForFutureYearsPerMonth)
    }

    if (RepDataAddtionalChannelTV != undefined)
    { 
        this.channelExpenditureTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.ChannelExpenditure)
        this.costPerEventTV = this.commonFunctions.   AddThousandSeparatorFormat(RepDataAddtionalChannelTV.CostPerEvent)
        this.totalEventsPerMonthTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.TotalEventsPerMonth) 
        this.leadsPerEventTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.LeadsPerEvent)
        this.leadsConvertedToProspectsTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.LeadsConvertedToProspects)
        this.totalNewProspectsPerMonthTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.TotalNewProspectsPerMonth)
        this.numberOfEW101BookedTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.NumberOfEW101Booked)
        this.numberOfEW101AttendedTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.NumberOfEW101Attended)
        this.firstAppointmentBookedTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.FirstAppointmentBooked)
        this.firstAppointmentAttendedTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.FirstAppointmentAttended)
        this.secondAppointmentBookedTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.SecondAppointmentBooked) 
        this.secondAppointmentAttendedTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.SecondAppointmentAttended)
        this.thirdAppointmentBookedTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.ThirdAppointmentBooked)  
        this.thirdAppointmentAttendedTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.ThirdAppointmentAttended) 
        this.closedTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.Closed)
        this.closingPercentageTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.ClosingPercentage)
        this.totalAssetsPerCloseTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.TotalAssetsPerClose)
        this.percentOfAssetsToANNTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.PercentOfAssetsToANN)
        this.aUMPerCloseTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.AUMPerClose)
        this.aNNPerCloseTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.ANNPerClose)
        this.feePerClientPerMonthTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.FeePerClientPerMonth)
        this.aNNTrailPerYearTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.ANNTrailPerYear)
        this.aUMFeePerMonthTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.AUMFeePerMonth)
        this.aNNCommPerMonthTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV.ANNCommPerMonth)
        this.aNNTrailForFutureYearsPerMonthTV = this.commonFunctions.AddThousandSeparatorFormat(RepDataAddtionalChannelTV .ANNTrailForFutureYearsPerMonth)
    }
  }


}

AssignACChannelExpenditure(selectedchannel : any)
{
  let selectedRepData : RepData 
  let selectedAdditionalChannelData :  RepDataAddtionalChannel   
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedAdditionalChannelData = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == selectedchannel.toString() )  as  RepDataAddtionalChannel   
  if (selectedAdditionalChannelData != undefined)
  { 
    switch (selectedchannel)
    {
      case this.radioSpots:
        selectedAdditionalChannelData.ChannelExpenditure = this.commonFunctions.RemoveThousandSeparatorFormat(this.channelExpenditureRadioSpots)
        break;
      case this.radioShows:
        selectedAdditionalChannelData.ChannelExpenditure = this.commonFunctions.RemoveThousandSeparatorFormat(this.channelExpenditureRadioShows)  
        break;
      case this.tv:
        selectedAdditionalChannelData.ChannelExpenditure = this.commonFunctions.RemoveThousandSeparatorFormat(this.channelExpenditureTV)  
        break; 
    } 
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  this.CalculationsAC()
}

AssignACCostPerEvent(selectedchannel : any)
{
  let selectedRepData : RepData 
  let selectedAdditionalChannelData :  RepDataAddtionalChannel   
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedAdditionalChannelData = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == selectedchannel.toString() )  as  RepDataAddtionalChannel   
  if (selectedAdditionalChannelData != undefined)
  { 
    switch (selectedchannel)
    {
      case this.radioSpots:
        selectedAdditionalChannelData.CostPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerEventRadioSpots)
        break;
      case this.radioShows:
        selectedAdditionalChannelData.CostPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerEventRadioShows)  
        break;
      case this.tv:
        selectedAdditionalChannelData.CostPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.costPerEventTV)  
        break; 
    } 
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  this.CalculationsAC()
}

AssignACLeadsPerEvent(selectedchannel : any)
{
  let selectedRepData : RepData 
  let selectedAdditionalChannelData :  RepDataAddtionalChannel   
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedAdditionalChannelData = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == selectedchannel.toString() )  as  RepDataAddtionalChannel   
  if (selectedAdditionalChannelData != undefined)
  { 
    switch (selectedchannel)
    {
      case this.radioSpots:
        selectedAdditionalChannelData.LeadsPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.leadsPerEventRadioSpots)
        break;
      case this.radioShows:
        selectedAdditionalChannelData.LeadsPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.leadsPerEventRadioShows)  
        break;
      case this.tv:
        selectedAdditionalChannelData.LeadsPerEvent = this.commonFunctions.RemoveThousandSeparatorFormat(this.leadsPerEventTV)  
        break; 
    } 
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  this.CalculationsAC()
}

AssignACLeadsConvertedToProspects(selectedchannel : any)
{
  let selectedRepData : RepData 
  let selectedAdditionalChannelData :  RepDataAddtionalChannel   
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedAdditionalChannelData = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == selectedchannel.toString() )  as  RepDataAddtionalChannel   
  if (selectedAdditionalChannelData != undefined)
  { 
    switch (selectedchannel)
    {
      case this.radioSpots:
        selectedAdditionalChannelData.LeadsConvertedToProspects = this.commonFunctions.RemoveThousandSeparatorFormat(this.leadsConvertedToProspectsRadioSpots)
        break;
      case this.radioShows:
        selectedAdditionalChannelData.LeadsConvertedToProspects = this.commonFunctions.RemoveThousandSeparatorFormat(this.leadsConvertedToProspectsRadioShows)  
        break;
      case this.tv:
        selectedAdditionalChannelData.LeadsConvertedToProspects = this.commonFunctions.RemoveThousandSeparatorFormat(this.leadsConvertedToProspectsTV)  
        break; 
    } 
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  this.CalculationsAC()
}

AssignACNumberOfEW101Booked(selectedchannel : any)
{
  let selectedRepData : RepData 
  let selectedAdditionalChannelData :  RepDataAddtionalChannel   
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedAdditionalChannelData = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == selectedchannel.toString() )  as  RepDataAddtionalChannel   
  if (selectedAdditionalChannelData != undefined)
  { 
    switch (selectedchannel)
    {
      case this.radioSpots:
        selectedAdditionalChannelData.NumberOfEW101Booked = this.commonFunctions.RemoveThousandSeparatorFormat(this.numberOfEW101BookedRadioSpots)
        break;
      case this.radioShows:
        selectedAdditionalChannelData.NumberOfEW101Booked = this.commonFunctions.RemoveThousandSeparatorFormat(this.numberOfEW101BookedRadioShows)  
        break;
      case this.tv:
        selectedAdditionalChannelData.NumberOfEW101Booked = this.commonFunctions.RemoveThousandSeparatorFormat(this.numberOfEW101BookedTV)  
        break; 
    } 
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
}

AssignACNumberOfEW101Attended(selectedchannel : any)
{
  let selectedRepData : RepData 
  let selectedAdditionalChannelData :  RepDataAddtionalChannel   
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedAdditionalChannelData = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == selectedchannel.toString() )  as  RepDataAddtionalChannel   
  if (selectedAdditionalChannelData != undefined)
  { 
    switch (selectedchannel)
    {
      case this.radioSpots:
        selectedAdditionalChannelData.NumberOfEW101Attended = this.commonFunctions.RemoveThousandSeparatorFormat(this.numberOfEW101AttendedRadioSpots)
        break;
      case this.radioShows:
        selectedAdditionalChannelData.NumberOfEW101Attended = this.commonFunctions.RemoveThousandSeparatorFormat(this.numberOfEW101AttendedRadioShows)  
        break;
      case this.tv:
        selectedAdditionalChannelData.NumberOfEW101Attended = this.commonFunctions.RemoveThousandSeparatorFormat(this.numberOfEW101AttendedTV)  
        break; 
    } 
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
}

AssignACFirstAppointmentBooked(selectedchannel : any)
{
  let selectedRepData : RepData 
  let selectedAdditionalChannelData :  RepDataAddtionalChannel   
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedAdditionalChannelData = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == selectedchannel.toString() )  as  RepDataAddtionalChannel   
  if (selectedAdditionalChannelData != undefined)
  { 
    switch (selectedchannel)
    {
      case this.radioSpots:
        selectedAdditionalChannelData.FirstAppointmentBooked = this.commonFunctions.RemoveThousandSeparatorFormat(this.firstAppointmentBookedRadioSpots)
        break;
      case this.radioShows:
        selectedAdditionalChannelData.FirstAppointmentBooked = this.commonFunctions.RemoveThousandSeparatorFormat(this.firstAppointmentBookedRadioShows)  
        break;
      case this.tv:
        selectedAdditionalChannelData.FirstAppointmentBooked = this.commonFunctions.RemoveThousandSeparatorFormat(this.firstAppointmentBookedTV)  
        break; 
    } 
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
}

AssignACFirstAppointmentAttended(selectedchannel : any)
{
  let selectedRepData : RepData 
  let selectedAdditionalChannelData :  RepDataAddtionalChannel   
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedAdditionalChannelData = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == selectedchannel.toString() )  as  RepDataAddtionalChannel   
  if (selectedAdditionalChannelData != undefined)
  { 
    switch (selectedchannel)
    {
      case this.radioSpots:
        selectedAdditionalChannelData.FirstAppointmentAttended = this.commonFunctions.RemoveThousandSeparatorFormat(this.firstAppointmentAttendedRadioSpots)
        break;
      case this.radioShows:
        selectedAdditionalChannelData.FirstAppointmentAttended = this.commonFunctions.RemoveThousandSeparatorFormat(this.firstAppointmentAttendedRadioShows)  
        break;
      case this.tv:
        selectedAdditionalChannelData.FirstAppointmentAttended = this.commonFunctions.RemoveThousandSeparatorFormat(this.firstAppointmentAttendedTV)  
        break; 
    } 
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  this.CalculationsAC()
}

AssignACSecondAppointmentBooked(selectedchannel : any)
{
  let selectedRepData : RepData 
  let selectedAdditionalChannelData :  RepDataAddtionalChannel   
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedAdditionalChannelData = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == selectedchannel.toString() )  as  RepDataAddtionalChannel   
  if (selectedAdditionalChannelData != undefined)
  { 
    switch (selectedchannel)
    {
      case this.radioSpots:
        selectedAdditionalChannelData.SecondAppointmentBooked = this.commonFunctions.RemoveThousandSeparatorFormat(this.secondAppointmentBookedRadioSpots)
        break;
      case this.radioShows:
        selectedAdditionalChannelData.SecondAppointmentBooked = this.commonFunctions.RemoveThousandSeparatorFormat(this.secondAppointmentBookedRadioShows)  
        break;
      case this.tv:
        selectedAdditionalChannelData.SecondAppointmentBooked = this.commonFunctions.RemoveThousandSeparatorFormat(this.secondAppointmentBookedTV)  
        break; 
    } 
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
}

AssignACSecondAppointmentAttended(selectedchannel : any)
{
  let selectedRepData : RepData 
  let selectedAdditionalChannelData :  RepDataAddtionalChannel   
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedAdditionalChannelData = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == selectedchannel.toString() )  as  RepDataAddtionalChannel   
  if (selectedAdditionalChannelData != undefined)
  { 
    switch (selectedchannel)
    {
      case this.radioSpots:
        selectedAdditionalChannelData.SecondAppointmentAttended = this.commonFunctions.RemoveThousandSeparatorFormat(this.secondAppointmentAttendedRadioSpots)
        break;
      case this.radioShows:
        selectedAdditionalChannelData.SecondAppointmentAttended = this.commonFunctions.RemoveThousandSeparatorFormat(this.secondAppointmentAttendedRadioShows)  
        break;
      case this.tv:
        selectedAdditionalChannelData.SecondAppointmentAttended = this.commonFunctions.RemoveThousandSeparatorFormat(this.secondAppointmentAttendedTV)  
        break; 
    } 
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
}

AssignACThirdAppointmentBooked(selectedchannel : any)
{
  let selectedRepData : RepData 
  let selectedAdditionalChannelData :  RepDataAddtionalChannel   
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedAdditionalChannelData = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == selectedchannel.toString() )  as  RepDataAddtionalChannel   
  if (selectedAdditionalChannelData != undefined)
  { 
    switch (selectedchannel)
    {
      case this.radioSpots:
        selectedAdditionalChannelData.ThirdAppointmentBooked = this.commonFunctions.RemoveThousandSeparatorFormat(this.thirdAppointmentBookedRadioSpots)
        break;
      case this.radioShows:
        selectedAdditionalChannelData.ThirdAppointmentBooked = this.commonFunctions.RemoveThousandSeparatorFormat(this.thirdAppointmentBookedRadioShows)  
        break;
      case this.tv:
        selectedAdditionalChannelData.ThirdAppointmentBooked = this.commonFunctions.RemoveThousandSeparatorFormat(this.thirdAppointmentBookedTV)  
        break; 
    } 
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
}

AssignACThirdAppointmentAttended(selectedchannel : any)
{
  let selectedRepData : RepData 
  let selectedAdditionalChannelData :  RepDataAddtionalChannel   
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedAdditionalChannelData = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == selectedchannel.toString() )  as  RepDataAddtionalChannel   
  if (selectedAdditionalChannelData != undefined)
  { 
    switch (selectedchannel)
    {
      case this.radioSpots:
        selectedAdditionalChannelData.ThirdAppointmentAttended = this.commonFunctions.RemoveThousandSeparatorFormat(this.thirdAppointmentAttendedRadioSpots)
        break;
      case this.radioShows:
        selectedAdditionalChannelData.ThirdAppointmentAttended = this.commonFunctions.RemoveThousandSeparatorFormat(this.thirdAppointmentAttendedRadioShows)  
        break;
      case this.tv:
        selectedAdditionalChannelData.ThirdAppointmentAttended = this.commonFunctions.RemoveThousandSeparatorFormat(this.thirdAppointmentAttendedTV)  
        break; 
    } 
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
}

AssignACClosed(selectedchannel : any)
{
  let selectedRepData : RepData 
  let selectedAdditionalChannelData :  RepDataAddtionalChannel   
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedAdditionalChannelData = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == selectedchannel.toString() )  as  RepDataAddtionalChannel   
  if (selectedAdditionalChannelData != undefined)
  { 
    switch (selectedchannel)
    {
      case this.radioSpots:
        selectedAdditionalChannelData.Closed = this.commonFunctions.RemoveThousandSeparatorFormat(this.closedRadioSpots)
        break;
      case this.radioShows:
        selectedAdditionalChannelData.Closed = this.commonFunctions.RemoveThousandSeparatorFormat(this.closedRadioShows)  
        break;
      case this.tv:
        selectedAdditionalChannelData.Closed = this.commonFunctions.RemoveThousandSeparatorFormat(this.closedTV)  
        break; 
    } 
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  this.CalculationsAC()
}

AssignACTotalAssetsPerClose(selectedchannel : any)
{
  let selectedRepData : RepData 
  let selectedAdditionalChannelData :  RepDataAddtionalChannel   
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedAdditionalChannelData = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == selectedchannel.toString() )  as  RepDataAddtionalChannel   
  if (selectedAdditionalChannelData != undefined)
  { 
    switch (selectedchannel)
    {
      case this.radioSpots:
        selectedAdditionalChannelData.TotalAssetsPerClose = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAssetsPerCloseRadioSpots)
        break;
      case this.radioShows:
        selectedAdditionalChannelData.TotalAssetsPerClose = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAssetsPerCloseRadioShows)  
        break;
      case this.tv:
        selectedAdditionalChannelData.TotalAssetsPerClose = this.commonFunctions.RemoveThousandSeparatorFormat(this.totalAssetsPerCloseTV)  
        break; 
    } 
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
}

AssignACPercentOfAssetsToANN(selectedchannel : any)
{
  let selectedRepData : RepData 
  let selectedAdditionalChannelData :  RepDataAddtionalChannel   
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  
  selectedAdditionalChannelData = selectedRepData.RepDataAddtionalChannels.find( y=> y.RepDataID  == selectedRepData.RepDataID  && y.MarketingChannelID == selectedchannel.toString() )  as  RepDataAddtionalChannel   
  if (selectedAdditionalChannelData != undefined)
  { 
    switch (selectedchannel)
    {
      case this.radioSpots:
        selectedAdditionalChannelData.PercentOfAssetsToANN = this.commonFunctions.RemoveThousandSeparatorFormat(this.percentOfAssetsToANNRadioSpots)
        break;
      case this.radioShows:
        selectedAdditionalChannelData.PercentOfAssetsToANN = this.commonFunctions.RemoveThousandSeparatorFormat(this.percentOfAssetsToANNRadioShows)  
        break;
      case this.tv:
        selectedAdditionalChannelData.PercentOfAssetsToANN = this.commonFunctions.RemoveThousandSeparatorFormat(this.percentOfAssetsToANNTV)  
        break; 
    } 
  }

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
}

FillAcrossAC()
{
  //validate 
  if (this.fillAcrossValue == undefined || this.fillAcrossValue == "")
  { 
    this.alertService.error('Please enter fill across value.');
    return;
  } 

  let selectedRepData : RepData 
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  

  switch (this.currentRowType)
  {
    //additional channels
    case 'channelExpenditureAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.ChannelExpenditure = this.fillAcrossValue;
      }); 
      break;
    case 'costPerEventAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.CostPerEvent = this.fillAcrossValue;
      }); 
      break;
    case 'leadsPerEventAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.LeadsPerEvent = this.fillAcrossValue;
      }); 
      break;
    case 'leadsConvertedToProspectsAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.LeadsConvertedToProspects = this.fillAcrossValue;
      }); 
      break;
    case 'numberOfEW101BookedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.NumberOfEW101Booked = this.fillAcrossValue;
      }); 
      break;
    case 'numberOfEW101AttendedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.NumberOfEW101Attended = this.fillAcrossValue;
      }); 
      break;
    case 'firstAppointmentBookedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.FirstAppointmentBooked = this.fillAcrossValue;
      }); 
      break;
    case 'firstAppointmentAttendedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.FirstAppointmentAttended = this.fillAcrossValue;
      }); 
      break;
    case 'secondAppointmentBookedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.SecondAppointmentBooked = this.fillAcrossValue;
      }); 
      break;
    case 'secondAppointmentAttendedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.SecondAppointmentAttended = this.fillAcrossValue;
      }); 
      break;
    case 'thirdAppointmentBookedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.ThirdAppointmentBooked = this.fillAcrossValue;
      }); 
      break;
    case 'thirdAppointmentAttendedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.ThirdAppointmentAttended = this.fillAcrossValue;
      }); 
      break;
    case 'closedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.Closed = this.fillAcrossValue;
      }); 
      break;
    case 'totalAssetsPerCloseAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.TotalAssetsPerClose = this.fillAcrossValue;
      }); 
      break;
    case 'percentOfAssetsToANNAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.PercentOfAssetsToANN = this.fillAcrossValue;
      }); 
      break;
  }    

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  this.LoadRepData();
  this.LoadRepDataAddtionalchannels();
  this.CalculationsAC()
  this.modalRef.hide();  

}

ClearAcrossAC(rowType: string)
{
  this.currentRowType = rowType;

  let selectedRepData : RepData 
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  

  switch (this.currentRowType)
  {
    //additional channels
    case 'channelExpenditureAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.ChannelExpenditure = 0;
      }); 
      break;
    case 'costPerEventAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.CostPerEvent = 0;
      }); 
      break;
    case 'leadsPerEventAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.LeadsPerEvent = 0;
      }); 
      break;
    case 'leadsConvertedToProspectsAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.LeadsConvertedToProspects = 0;
      }); 
      break;
    case 'numberOfEW101BookedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.NumberOfEW101Booked = 0;
      }); 
      break;
    case 'numberOfEW101AttendedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.NumberOfEW101Attended = 0;
      }); 
      break;
    case 'firstAppointmentBookedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.FirstAppointmentBooked = 0;
      }); 
      break;
    case 'firstAppointmentAttendedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.FirstAppointmentAttended = 0;
      }); 
      break;
    case 'secondAppointmentBookedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.SecondAppointmentBooked = 0;
      }); 
      break;
    case 'secondAppointmentAttendedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.SecondAppointmentAttended = 0;
      }); 
      break;
    case 'thirdAppointmentBookedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.ThirdAppointmentBooked = 0;
      }); 
      break;
    case 'thirdAppointmentAttendedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.ThirdAppointmentAttended = 0;
      }); 
      break;
    case 'closedAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.Closed = 0;
      }); 
      break;
    case 'totalAssetsPerCloseAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.TotalAssetsPerClose = 0;
      }); 
      break;
    case 'percentOfAssetsToANNAC':
      selectedRepData.RepDataAddtionalChannels.forEach( (element) => {
        element.PercentOfAssetsToANN = 0;
      }); 
      break;
  }   
  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  this.LoadRepData();
  this.LoadRepDataAddtionalchannels();
  this.CalculationsAC()
}

//Additional channel calculations
CalculationsAC(){
  let selectedRepData : RepData 
  selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID)  

  selectedRepData.RepDataAddtionalChannels.forEach(element => {
    let validateCostPerEvent = (Math.round(parseFloat(element.CostPerEvent.toString())) != 0 && element.CostPerEvent != null 
    && element.CostPerEvent != undefined && element.CostPerEvent.toString() != "")
    let validateChannelExpenditure = (element.ChannelExpenditure.toString() != "" && element.ChannelExpenditure != null && element.ChannelExpenditure != undefined)
    let validateTotalEventsPerMonth = (element.TotalEventsPerMonth.toString() != "" && element.TotalEventsPerMonth != null && element.TotalEventsPerMonth != undefined)
    let validateLeadsPerEvent = (element.LeadsPerEvent.toString() != "" && element.LeadsPerEvent != null && element.LeadsPerEvent != undefined)
    let validateLeadsConvertedToProspects = (element.LeadsConvertedToProspects.toString() != "" && element.LeadsConvertedToProspects != null && element.LeadsConvertedToProspects != undefined)
    let validateFirstAppointmentAttended = (Math.round(parseFloat(element.FirstAppointmentAttended.toString())) != 0 && element.FirstAppointmentAttended != null 
    && element.FirstAppointmentAttended != undefined && element.FirstAppointmentAttended.toString() != "")
    let validateClosed = (element.Closed.toString() != "" && element.Closed != null && element.Closed != undefined)

    if(validateCostPerEvent && validateChannelExpenditure){
      element.TotalEventsPerMonth = Math.floor(parseFloat(element.ChannelExpenditure.toString()) / parseFloat(element.CostPerEvent.toString()));
    } else{
      element.TotalEventsPerMonth = 0
    }

    if(validateTotalEventsPerMonth && validateLeadsPerEvent && validateLeadsConvertedToProspects){
      element.TotalNewProspectsPerMonth = Math.floor(parseFloat(element.TotalEventsPerMonth.toString()) * parseFloat(element.LeadsPerEvent.toString())
      * (parseFloat(element.LeadsConvertedToProspects.toString()) / 100));
    }else{
      element.TotalNewProspectsPerMonth = 0
    }

    if(validateFirstAppointmentAttended && validateClosed){
      element.ClosingPercentage = Number(parseFloat((parseFloat(element.Closed.toString()) / parseFloat(element.FirstAppointmentAttended.toString()) * 100).toString()).toFixed(2));
    } else{
      element.ClosingPercentage = 0
    }
  });
  this.LoadRepDataAddtionalchannels()
}
//#endregion

//#region  Employee Methods
RemoveEmployee(){
  let selectedRepData = this.businesscalculatorinput.RepData.find( x=> x.AgentID  == this.selectedRepID) 
  if(selectedRepData.IsOwner == false && selectedRepData.bAddedToEmployee == true){
    let employeetypes = JSON.parse(localStorage.getItem(LocalStorage.EMPLOYEE_TYPES));
    var salestypeid = employeetypes.find(x => x.EmployeeType === "Sales").EmployeeTypeID;
    let empdetail = this.businesscalculatorinput.EmployeeDetails.find( x=> x.EmployeeTypeID  == salestypeid) 
    
    //Create employee counts array
    var noofyears = this.businesscalculatorinput.NoOfYears.toString()
    let tempstartdate = new Date(this.businesscalculatorinput.StartDate)
    var startyear = tempstartdate.getFullYear().toString()
    var startmonth = "1"
    var endyear = (tempstartdate.getFullYear() + parseInt(noofyears)).toString()
    var endmonth = "12"

    if(selectedRepData.RepStartDate != null && selectedRepData.RepStartDate != undefined){
      startyear = (new Date(selectedRepData.RepStartDate).getFullYear()).toString();
      startmonth = (new Date(selectedRepData.RepStartDate).getMonth()).toString();
    }

    if(selectedRepData.RepEndDate != null && selectedRepData.RepEndDate != undefined){
      endyear = (new Date(selectedRepData.RepEndDate).getFullYear()).toString();
      endmonth = (new Date(selectedRepData.RepEndDate).getMonth()).toString();
    }
    
    var i
    var j
    for(i = parseInt(startyear); i < parseInt(endyear)+1; i++){
      let currentstartmonth = 1
      let currentendmonth = 12
      if(i == parseInt(startyear)){currentstartmonth = parseInt(startmonth)}
      if(i == parseInt(endyear)){currentendmonth = parseInt(endmonth)}
      for(j = currentstartmonth; j < currentendmonth+1; j++){
        let employeecount = empdetail.EmployeeCounts.find(x => x.Year === i.toString() && x.Month === j.toString())
        if(employeecount != undefined){employeecount.EmpCount = parseInt(employeecount.EmpCount.toString()) - 1;}
      }
    }
          
    //Remove employee
    empdetail.Employees.splice(empdetail.Employees.findIndex(x=> x.RepDataID === selectedRepData.RepDataID),1)
    
    let index = this.businesscalculatorinput.EmployeeDetails.findIndex( x=> x.EmployeeTypeID === salestypeid)
    this.businesscalculatorinput.EmployeeDetails[index] = empdetail
    //Adding businesscalculatorinput to the local storage
    localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
    localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
  }
}
//#endregion

//#region "Common methods"
CommaFormatted(event: any) {  
    this.alertService.clear()
    // skip for arrow keys 
    if(event.which >= 37 && event.which <= 40) return;  

    let value =  this.commonFunctions.AddThousandSeparatorFormat(event.target.value)     
    if (event.target.id==="baseSalary") {
      this.baseSalary = value
    } else if (event.target.id==="classPerMonthJan") {
      this.repPayPercentage = value
    } else if (event.target.id==="classPerMonthJan") {
      this.classPerMonthJan = value
    } else if (event.target.id==="classPerMonthFeb") {
      this.classPerMonthFeb = value
    } else if (event.target.id==="classPerMonthMar") {
      this.classPerMonthMar = value
    } else if (event.target.id==="classPerMonthApr") {
      this.classPerMonthApr = value 
    } else if (event.target.id==="classPerMonthMay") {
      this.classPerMonthMay = value 
    } else if (event.target.id==="classPerMonthJun") {
      this.classPerMonthJun = value 
    } else if (event.target.id==="classPerMonthJul") {
      this.classPerMonthJul = value 
    } else if (event.target.id==="classPerMonthAug") {
      this.classPerMonthAug = value  
    } else if (event.target.id==="classPerMonthSep") {
      this.classPerMonthSep = value 
    } else if (event.target.id==="classPerMonthOct") {
      this.classPerMonthOct = value 
    } else if (event.target.id==="classPerMonthNov") {
      this.classPerMonthNov = value 
    } else if (event.target.id==="classPerMonthDec") {
      this.classPerMonthDec = value 
    }  else  if (event.target.id==="costPerMailerJan") {
      this.costPerMailerJan = value
    } else if (event.target.id==="costPerMailerFeb") {
      this.costPerMailerFeb = value
    } else if (event.target.id==="costPerMailerMar") {
      this.costPerMailerMar = value
    } else if (event.target.id==="costPerMailerApr") {
      this.costPerMailerApr = value 
    } else if (event.target.id==="costPerMailerMay") {
      this.costPerMailerMay = value 
    } else if (event.target.id==="costPerMailerJun") {
      this.costPerMailerJun = value 
    } else if (event.target.id==="costPerMailerJul") {
      this.costPerMailerJul = value 
    } else if (event.target.id==="costPerMailerAug") {
      this.costPerMailerAug = value  
    } else if (event.target.id==="costPerMailerSep") {
      this.costPerMailerSep = value 
    } else if (event.target.id==="costPerMailerOct") {
      this.costPerMailerOct = value 
    } else if (event.target.id==="costPerMailerNov") {
      this.costPerMailerNov = value 
    } else if (event.target.id==="costPerMailerDec") {
      this.costPerMailerDec = value 
    } else if (event.target.id==="costPerEventJan") {
      this.costPerEventJan = value
    } else if (event.target.id==="costPerEventFeb") {
      this.costPerEventFeb = value
    } else if (event.target.id==="costPerEventMar") {
      this.costPerEventMar = value
    } else if (event.target.id==="costPerEventApr") {
      this.costPerEventApr = value 
    } else if (event.target.id==="costPerEventMay") {
      this.costPerEventMay = value 
    } else if (event.target.id==="costPerEventJun") {
      this.costPerEventJun = value 
    } else if (event.target.id==="costPerEventJul") {
      this.costPerEventJul = value 
    } else if (event.target.id==="costPerEventAug") {
      this.costPerEventAug = value  
    } else if (event.target.id==="costPerEventSep") {
      this.costPerEventSep = value 
    } else if (event.target.id==="costPerEventOct") {
      this.costPerEventOct = value 
    } else if (event.target.id==="costPerEventNov") {
      this.costPerEventNov = value 
    } else if (event.target.id==="costPerEventDec") {
      this.costPerEventDec = value 
    } else if (event.target.id==="totalBURegisteredPerClassJan") {
      this.totalBURegisteredPerClassJan = value
    } else if (event.target.id==="totalBURegisteredPerClassFeb") {
      this.totalBURegisteredPerClassFeb = value
    } else if (event.target.id==="totalBURegisteredPerClassMar") {
      this.totalBURegisteredPerClassMar = value
    } else if (event.target.id==="totalBURegisteredPerClassApr") {
      this.totalBURegisteredPerClassApr = value 
    } else if (event.target.id==="totalBURegisteredPerClassMay") {
      this.totalBURegisteredPerClassMay = value 
    } else if (event.target.id==="totalBURegisteredPerClassJun") {
      this.totalBURegisteredPerClassJun = value 
    } else if (event.target.id==="totalBURegisteredPerClassJul") {
      this.totalBURegisteredPerClassJul = value 
    } else if (event.target.id==="totalBURegisteredPerClassAug") {
      this.totalBURegisteredPerClassAug = value  
    } else if (event.target.id==="totalBURegisteredPerClassSep") {
      this.totalBURegisteredPerClassSep = value 
    } else if (event.target.id==="totalBURegisteredPerClassOct") {
      this.totalBURegisteredPerClassOct = value 
    } else if (event.target.id==="totalBURegisteredPerClassNov") {
      this.totalBURegisteredPerClassNov = value 
    } else if (event.target.id==="totalBURegisteredPerClassDec") {
      this.totalBURegisteredPerClassDec = value 
    } else if (event.target.id==="cancelRateJan") {
      this.cancelRateJan = value
    } else if (event.target.id==="cancelRateFeb") {
      this.cancelRateFeb = value
    } else if (event.target.id==="cancelRateMar") {
      this.cancelRateMar = value
    } else if (event.target.id==="cancelRateApr") {
      this.cancelRateApr = value 
    } else if (event.target.id==="cancelRateMay") {
      this.cancelRateMay = value 
    } else if (event.target.id==="cancelRateJun") {
      this.cancelRateJun = value 
    } else if (event.target.id==="cancelRateJul") {
      this.cancelRateJul = value 
    } else if (event.target.id==="cancelRateAug") {
      this.cancelRateAug = value  
    } else if (event.target.id==="cancelRateSep") {
      this.cancelRateSep = value 
    } else if (event.target.id==="cancelRateOct") {
      this.cancelRateOct = value 
    } else if (event.target.id==="cancelRateNov") {
      this.cancelRateNov = value 
    } else if (event.target.id==="cancelRateDec") {
      this.cancelRateDec = value 
    } else if (event.target.id==="totalAttendeesJan") {
      this.totalAttendeesJan = value
    } else if (event.target.id==="totalAttendeesFeb") {
      this.totalAttendeesFeb = value
    } else if (event.target.id==="totalAttendeesMar") {
      this.totalAttendeesMar = value
    } else if (event.target.id==="totalAttendeesApr") {
      this.totalAttendeesApr = value 
    } else if (event.target.id==="totalAttendeesMay") {
      this.totalAttendeesMay = value 
    } else if (event.target.id==="totalAttendeesJun") {
      this.totalAttendeesJun = value 
    } else if (event.target.id==="totalAttendeesJul") {
      this.totalAttendeesJul = value 
    } else if (event.target.id==="totalAttendeesAug") {
      this.totalAttendeesAug = value  
    } else if (event.target.id==="totalAttendeesSep") {
      this.totalAttendeesSep = value 
    } else if (event.target.id==="totalAttendeesOct") {
      this.totalAttendeesOct = value 
    } else if (event.target.id==="totalAttendeesNov") {
      this.totalAttendeesNov = value 
    } else if (event.target.id==="totalAttendeesDec") {
      this.totalAttendeesDec = value 
    } else if (event.target.id==="bookingRateJan") {
      this.bookingRateJan = value
    } else if (event.target.id==="bookingRateFeb") {
      this.bookingRateFeb = value
    } else if (event.target.id==="bookingRateMar") {
      this.bookingRateMar = value
    } else if (event.target.id==="bookingRateApr") {
      this.bookingRateApr = value 
    } else if (event.target.id==="bookingRateMay") {
      this.bookingRateMay = value 
    } else if (event.target.id==="bookingRateJun") {
      this.bookingRateJun = value 
    } else if (event.target.id==="bookingRateJul") {
      this.bookingRateJul = value 
    } else if (event.target.id==="bookingRateAug") {
      this.bookingRateAug = value  
    } else if (event.target.id==="bookingRateSep") {
      this.bookingRateSep = value 
    } else if (event.target.id==="bookingRateOct") {
      this.bookingRateOct = value 
    } else if (event.target.id==="bookingRateNov") {
      this.bookingRateNov = value 
    } else if (event.target.id==="bookingRateDec") {
      this.bookingRateDec = value 
    } else if (event.target.id==="closeRateJan") {
      this.closeRateJan = value
    } else if (event.target.id==="closeRateFeb") {
      this.closeRateFeb = value
    } else if (event.target.id==="closeRateMar") {
      this.closeRateMar = value
    } else if (event.target.id==="closeRateApr") {
      this.closeRateApr = value 
    } else if (event.target.id==="closeRateMay") {
      this.closeRateMay = value 
    } else if (event.target.id==="closeRateJun") {
      this.closeRateJun = value 
    } else if (event.target.id==="closeRateJul") {
      this.closeRateJul = value 
    } else if (event.target.id==="closeRateAug") {
      this.closeRateAug = value  
    } else if (event.target.id==="closeRateSep") {
      this.closeRateSep = value 
    } else if (event.target.id==="closeRateOct") {
      this.closeRateOct = value 
    } else if (event.target.id==="closeRateNov") {
      this.closeRateNov = value 
    } else if (event.target.id==="closeRateDec") {
      this.closeRateDec = value 
    } else if (event.target.id==="totalAssetsPerCloseJan") {
      this.totalAssetsPerCloseJan = value
    } else if (event.target.id==="totalAssetsPerCloseFeb") {
      this.totalAssetsPerCloseFeb = value
    } else if (event.target.id==="totalAssetsPerCloseMar") {
      this.totalAssetsPerCloseMar = value
    } else if (event.target.id==="totalAssetsPerCloseApr") {
      this.totalAssetsPerCloseApr = value 
    } else if (event.target.id==="totalAssetsPerCloseMay") {
      this.totalAssetsPerCloseMay = value 
    } else if (event.target.id==="totalAssetsPerCloseJun") {
      this.totalAssetsPerCloseJun = value 
    } else if (event.target.id==="totalAssetsPerCloseJul") {
      this.totalAssetsPerCloseJul = value 
    } else if (event.target.id==="totalAssetsPerCloseAug") {
      this.totalAssetsPerCloseAug = value  
    } else if (event.target.id==="totalAssetsPerCloseSep") {
      this.totalAssetsPerCloseSep = value 
    } else if (event.target.id==="totalAssetsPerCloseOct") {
      this.totalAssetsPerCloseOct = value 
    } else if (event.target.id==="totalAssetsPerCloseNov") {
      this.totalAssetsPerCloseNov = value 
    } else if (event.target.id==="totalAssetsPerCloseDec") {
      this.totalAssetsPerCloseDec = value 
    } else if (event.target.id==="percentOfAssetsToANNJan") {
      this.percentOfAssetsToANNJan = value
    } else if (event.target.id==="percentOfAssetsToANNFeb") {
      this.percentOfAssetsToANNFeb = value
    } else if (event.target.id==="percentOfAssetsToANNMar") {
      this.percentOfAssetsToANNMar = value
    } else if (event.target.id==="percentOfAssetsToANNApr") {
      this.percentOfAssetsToANNApr = value 
    } else if (event.target.id==="percentOfAssetsToANNMay") {
      this.percentOfAssetsToANNMay = value 
    } else if (event.target.id==="percentOfAssetsToANNJun") {
      this.percentOfAssetsToANNJun = value 
    } else if (event.target.id==="percentOfAssetsToANNJul") {
      this.percentOfAssetsToANNJul = value 
    } else if (event.target.id==="percentOfAssetsToANNAug") {
      this.percentOfAssetsToANNAug = value  
    } else if (event.target.id==="percentOfAssetsToANNSep") {
      this.percentOfAssetsToANNSep = value 
    } else if (event.target.id==="percentOfAssetsToANNOct") {
      this.percentOfAssetsToANNOct = value 
    } else if (event.target.id==="percentOfAssetsToANNNov") {
      this.percentOfAssetsToANNNov = value 
    } else if (event.target.id==="percentOfAssetsToANNDec") {
      this.percentOfAssetsToANNDec = value 
    } 

    //Additional Channels
    else if (event.target.id==="channelExpenditureRadioSpots") {
      this.channelExpenditureRadioSpots = value
    } else if (event.target.id==="channelExpenditureRadioShows") {
      this.channelExpenditureRadioShows = value
    } else if (event.target.id==="channelExpenditureTV") {
      this.channelExpenditureTV = value
    } else if (event.target.id==="costPerEventRadioSpots") {
      this.costPerEventRadioSpots = value
    } else if (event.target.id==="costPerEventRadioShows") {
      this.costPerEventRadioShows = value
    } else if (event.target.id==="costPerEventTV") {
      this.costPerEventTV = value
    } else if (event.target.id==="totalEventsPerMonthRadioSpots") {
      this.totalEventsPerMonthRadioSpots = value
    } else if (event.target.id==="totalEventsPerMonthRadioShows") {
      this.totalEventsPerMonthRadioShows = value
    } else if (event.target.id==="totalEventsPerMonthTV") {
      this.totalEventsPerMonthTV = value
    } else if (event.target.id==="leadsPerEventRadioSpots") {
      this.leadsPerEventRadioSpots = value
    } else if (event.target.id==="leadsPerEventRadioShows") {
      this.leadsPerEventRadioShows = value
    } else if (event.target.id==="leadsPerEventTV") {
      this.leadsPerEventTV = value
    } else if (event.target.id==="leadsConvertedToProspectsRadioSpots") {
      this.leadsConvertedToProspectsRadioSpots = value
    } else if (event.target.id==="leadsConvertedToProspectsRadioShows") {
      this.leadsConvertedToProspectsRadioShows = value
    } else if (event.target.id==="leadsConvertedToProspectsTV") {
      this.leadsConvertedToProspectsTV = value
    } else if (event.target.id==="totalNewProspectsPerMonthRadioSpots") {
      this.totalNewProspectsPerMonthRadioSpots = value
    } else if (event.target.id==="totalNewProspectsPerMonthRadioShows") {
      this.totalNewProspectsPerMonthRadioShows = value
    } else if (event.target.id==="totalNewProspectsPerMonthTV") {
      this.totalNewProspectsPerMonthTV = value
    } else if (event.target.id==="numberOfEW101BookedRadioSpots") {
      this.numberOfEW101BookedRadioSpots = value
    } else if (event.target.id==="numberOfEW101BookedRadioShows") {
      this.numberOfEW101BookedRadioShows = value
    } else if (event.target.id==="numberOfEW101BookedTV") {
      this.numberOfEW101BookedTV = value
    } else if (event.target.id==="numberOfEW101AttendedRadioSpots") {
      this.numberOfEW101AttendedRadioSpots = value
    } else if (event.target.id==="numberOfEW101AttendedRadioShows") {
      this.numberOfEW101AttendedRadioShows = value
    } else if (event.target.id==="numberOfEW101AttendedTV") {
      this.numberOfEW101AttendedTV = value
    } else if (event.target.id==="firstAppointmentBookedRadioSpots") {
      this.firstAppointmentBookedRadioSpots = value
    } else if (event.target.id==="firstAppointmentBookedRadioShows") {
      this.firstAppointmentBookedRadioShows = value
    } else if (event.target.id==="firstAppointmentBookedTV") {
      this.firstAppointmentBookedTV = value
    } else if (event.target.id==="firstAppointmentAttendedRadioSpots") {
      this.firstAppointmentAttendedRadioSpots = value
    } else if (event.target.id==="firstAppointmentAttendedRadioShows") {
      this.firstAppointmentAttendedRadioShows = value
    } else if (event.target.id==="secondAppointmentBookedRadioSpots") {
      this.secondAppointmentBookedRadioSpots = value
    } else if (event.target.id==="secondAppointmentBookedRadioShows") {
      this.secondAppointmentBookedRadioShows = value
    } else if (event.target.id==="secondAppointmentBookedTV") {
      this.secondAppointmentBookedTV = value
    } else if (event.target.id==="secondAppointmentAttendedRadioSpots") {
      this.secondAppointmentAttendedRadioSpots = value
    } else if (event.target.id==="secondAppointmentAttendedRadioShows") {
      this.secondAppointmentAttendedRadioShows = value
    } else if (event.target.id==="secondAppointmentAttendedTV") {
      this.secondAppointmentAttendedTV = value
    } else if (event.target.id==="thirdAppointmentBookedRadioSpots") {
      this.thirdAppointmentBookedRadioSpots = value
    } else if (event.target.id==="thirdAppointmentBookedRadioShows") {
      this.thirdAppointmentBookedRadioShows = value
    } else if (event.target.id==="thirdAppointmentBookedTV") {
      this.thirdAppointmentBookedTV = value
    } else if (event.target.id==="thirdAppointmentAttendedRadioSpots") {
      this.thirdAppointmentAttendedRadioSpots = value
    } else if (event.target.id==="thirdAppointmentAttendedRadioShows") {
      this.thirdAppointmentAttendedRadioShows = value
    } else if (event.target.id==="thirdAppointmentAttendedTV") {
      this.thirdAppointmentAttendedTV = value
    } else if (event.target.id==="closedRadioSpots") {
      this.closedRadioSpots = value
    } else if (event.target.id==="closedRadioShows") {
      this.closedRadioShows = value
    } else if (event.target.id==="closedTV") {
      this.closedTV = value
    } else if (event.target.id==="closingPercentageRadioSpots") {
      this.closingPercentageRadioSpots = value
    } else if (event.target.id==="closingPercentageRadioShows") {
      this.closingPercentageRadioShows = value
    } else if (event.target.id==="closingPercentageTV") {
      this.closingPercentageTV = value
    } else if (event.target.id==="totalAssetsPerCloseRadioSpots") {
      this.totalAssetsPerCloseRadioSpots = value
    } else if (event.target.id==="totalAssetsPerCloseRadioShows") {
      this.totalAssetsPerCloseRadioShows = value
    } else if (event.target.id==="totalAssetsPerCloseTV") {
      this.totalAssetsPerCloseTV = value
    } else if (event.target.id==="percentOfAssetsToANNRadioSpots") {
      this.percentOfAssetsToANNRadioSpots = value
    } else if (event.target.id==="percentOfAssetsToANNRadioShows") {
      this.percentOfAssetsToANNRadioShows = value
    } else if (event.target.id==="percentOfAssetsToANNTV") {
      this.percentOfAssetsToANNTV = value
    } else if (event.target.id==="aUMPerCloseRadioSpots") {
      this.aUMPerCloseRadioSpots = value
    } else if (event.target.id==="aUMPerCloseRadioShows") {
      this.aUMPerCloseRadioShows = value
    } else if (event.target.id==="aUMPerCloseTV") {
      this.aUMPerCloseTV = value
    } else if (event.target.id==="aNNPerCloseRadioSpots") {
      this.aNNPerCloseRadioSpots = value
    } else if (event.target.id==="aNNPerCloseRadioShows") {
      this.aNNPerCloseRadioShows = value
    } else if (event.target.id==="aNNPerCloseTV") {
      this.aNNPerCloseTV = value
    } else if (event.target.id==="feePerClientPerMonthRadioSpots") {
      this.feePerClientPerMonthRadioSpots = value
    } else if (event.target.id==="feePerClientPerMonthRadioShows") {
      this.feePerClientPerMonthRadioShows = value
    } else if (event.target.id==="feePerClientPerMonthTV") {
      this.feePerClientPerMonthTV = value
    } else if (event.target.id==="aNNTrailPerYearRadioSpots") {
      this.aNNTrailPerYearRadioSpots = value
    } else if (event.target.id==="aNNTrailPerYearRadioShows") {
      this.aNNTrailPerYearRadioShows = value
    } else if (event.target.id==="aNNTrailPerYearTV") {
      this.aNNTrailPerYearTV = value
    } else if (event.target.id==="aUMFeePerMonthRadioSpots") {
      this.aUMFeePerMonthRadioSpots = value
    } else if (event.target.id==="aUMFeePerMonthRadioShows") {
      this.aUMFeePerMonthRadioShows = value
    } else if (event.target.id==="aUMFeePerMonthTV") {
      this.aUMFeePerMonthTV = value
    } else if (event.target.id==="aNNCommPerMonthRadioSpots") {
      this.aNNCommPerMonthRadioSpots = value
    } else if (event.target.id==="aNNCommPerMonthRadioShows") {
      this.aNNCommPerMonthRadioShows = value
    } else if (event.target.id==="aNNCommPerMonthTV") {
      this.aNNCommPerMonthTV = value
    } else if (event.target.id==="aNNTrailForFutureYearsPerMonthRadioSpots") {
      this.aNNTrailForFutureYearsPerMonthRadioSpots = value
    } else if (event.target.id==="aNNTrailForFutureYearsPerMonthRadioShows") {
      this.aNNTrailForFutureYearsPerMonthRadioShows = value
    } else if (event.target.id==="aNNTrailForFutureYearsPerMonthTV") {
      this.aNNTrailForFutureYearsPerMonthTV = value
    } 

    // Fill Acorss
    else if (event.target.id==="fillAcrossValue") {
      this.fillAcrossValue = value 
    }  

    // Fill Down
    else if (event.target.id==="fillDownValue") {
      this.fillDownValue = value 
    } 
    //financial products 
    else if (event.target.id==="totalAUMFee") {
      this.totalAUMFee = value 
    }   
    /*  else if (event.target.id==="percentageofNetIncome") {
      this.percentageofNetIncome = value 
    }   */   
} 
//#endregion

exitBtnClickEvent()
{
  this.router.navigateByUrl('landingfp4fa'); 
}

//load the superuser dashboard page
public navigateSuperUserDashboard()
{
	this.router.navigateByUrl('superuserdashboard'); 
}
}




