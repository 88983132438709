import { Injectable } from "../../../node_modules/@angular/core";
import { Constants} from './constants';

@Injectable({providedIn: 'root'})
export class LocalStorage {
    constructor(){}
    public static LOGGED_AGENT: string = "logged_in_agent";
    public static SELECTED_CLIENTID: string = "selected_client_id";  
    public static CURRENT_CLIENT: string = "Current_client";  
    public static SELECTED_CHOICEID: string = "selected_choice_id";   
    public static ISMYBLEND: string = "ismyblend";
    public static ISMYINDEXBLEND: string = "ismyindexblend";
    public static ISMYTICKERBLEND: string = "ismytickerblend";
    public static REPORT_TYPE: string = "report_type";
    public static SERVICE_VERSION: string = "service_version";

    //BusinessCalculator
    public static FP4FA: string = "fp4fa";
    public static BUSINESS_CALCULATOR_INPUT: string = "business_calculator_input";
    public static BUSINESS_CALCULATOR_OUTPUT: string = "business_calculator_output";
    public static BUSINESS_CALCULATOR_DEFAULTS: string = "business_calculator_defaults";
    public static EMPLOYEE_TYPES: string = "employee types";
    public static HAS_UNSAVED_FP4FA_DATA: string = "has_unsaved_fp4fa_data";
    public static COMPANY_CONFIG: string = "company config";

    //To Handle redirection when pages are having errors on them
    public static HAS_FINANCIAL_PRODUCT_ERRORS: string = "has_financial_product_errors";
    public static HAS_REP_DATA_ERRORS: string = "has_rep_data_errors";
    public static HAS_COMPANY_CONFIG_ERRORS: string = "has_company_config_errors";
}