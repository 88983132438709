import { Component, OnInit,TemplateRef,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'; 
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { ClientInfoDataService } from './../client-info/client-info-data.service';
import { CommonFunctions } from '../../util/common-functions';
import { AlertService } from '../../util/alert/alert.service';
import { Agent } from '../../models/agent.model';  
import { Proxy } from '../../models/proxy.model';  
import { RiskGraphRequest } from '../../models/risk-graph-request.model';  
import { TiingoTickerInfo } from '../../models/tiingo-ticker-info';
import { LocalStorage } from '../../util/localstorage.service';
import { BsModalService,BsModalRef, ModalOptions}   from 'ngx-bootstrap/modal';
import { Constants } from '../../util/constants' 
import { typeaheadAnimation } from 'ngx-bootstrap/typeahead/typeahead-animations';
import {formatDate} from '@angular/common'; 

@Component({
    selector: 'app-risk-graph',
	templateUrl: './risk-graph.component.html',
	styleUrls: ['./risk-graph.component.scss'],
	providers: [DatePipe]
}) 

export class RiskGraphComponent implements OnInit { 
	public riskGraphForm: FormGroup;
	public recommendedProxyForm: FormGroup;
	public addProxyForm: FormGroup;
	public searchResultForm: FormGroup;
	public saveReportForm: FormGroup;
	public loggedAgent: Agent 
	public ModalRef : BsModalRef 
	public recommendedproxies :   Array<Proxy> = []
	public selectedproxies :   Array<Proxy> = []
	public currentticker:Proxy = new Proxy() 
	public showMsg : boolean = false
	public fromDate : Date = new Date("2000/01/01")
	public toDate : Date = new Date()
	public noOfDrawdowns : number = 2
	public fromDateCustom : Date = new Date("2000/01/01")
	public toDateCustom : Date = new Date()
	public customDrawdowns :   Array<string> = []
	public TiingoTickerInfo : Array<TiingoTickerInfo> =[]
	public showsearchresult : boolean = false
	public reportName : any; 
	public bAddAutoGenerated:number = 0
	public isShowDashboard: boolean = false
	public bLogarithmic:number = 2
	private commonFunctions: CommonFunctions = new CommonFunctions


	@ViewChild('recommendedProxyTemplate') recommendedProxyTemplate: TemplateRef<any>
	@ViewChild('addProxyTemplate') addProxyTemplate: TemplateRef<any>
	@ViewChild('searchResultTemplate') searchResultTemplate: TemplateRef<any>
	@ViewChild('saveReportTemplate') saveReportTemplate: TemplateRef<any>
	public config: ModalOptions = { class: 'modal-lg' };

	constructor( private router: Router,  private ldService: ClientInfoDataService,
		private formBuilder: FormBuilder, private alertService: AlertService,
		private bsModalService :BsModalService) { 
		 
	}

ngOnInit() { 
	this.alertService.clear()
	this.riskGraphForm = this.formBuilder.group({
			tickerSymbol: new FormControl(null),
			selectedTickers: new FormControl(null) ,
			fromDate: new FormControl(null) ,
			toDate: new FormControl(null) ,
			noofDropDowns: new FormControl(null),
			fromDateCustom: new FormControl(null) ,
			toDateCustom: new FormControl(null),
			selectedDrawdowns: new FormControl(null),
			addAutoGenerated:  new FormControl(null),
			scale:  new FormControl(null)
		});   

	this.recommendedProxyForm = this.formBuilder.group({
		recommendedTickers: new FormControl(null) 
		});   

	this.addProxyForm = this.formBuilder.group({
			tickerSymbol: new FormControl(null),
			proxySymbol: new FormControl(null) ,
			comment: new FormControl(null)
		});   

	this.searchResultForm = this.formBuilder.group({
			searchresults: new FormControl(null) 
			}); 

	this.saveReportForm = this.formBuilder.group({
			reportName: new FormControl(null) 
			});  
			 

	this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
	if(this.loggedAgent.UserRoleID== Constants.UserRoleID.Superuser)
		this.isShowDashboard = true
}
 

selectBtnClickEvent()
{ 

  this.alertService.clear()
   
   //Validate ticker Symbol
  if (this.riskGraphForm.controls.tickerSymbol.value == "" || 
		   this.riskGraphForm.controls.tickerSymbol.value == null )
	  {  
		  this.alertService.error('Invalid Ticker Symbol')
		  return false
	  } 
	  else 
	  {
		this.ldService.hashistoricaldata(this.riskGraphForm.controls.tickerSymbol.value, this.loggedAgent).subscribe(hasdata => 
			{ 
				if (hasdata)
				{
					let tempSelected : Proxy = new Proxy
					tempSelected.Ticker = this.riskGraphForm.controls.tickerSymbol.value 
					tempSelected.Proxy = this.riskGraphForm.controls.tickerSymbol.value 
					this.selectedproxies.push(tempSelected) 
					this.currentticker = new Proxy

					
				}
				else 
				{ 
					this.ldService.getrecommendedproxies(this.riskGraphForm.controls.tickerSymbol.value, this.loggedAgent).subscribe(data => 
						{ 
							this.recommendedproxies = data   
							 
							if (this.recommendedproxies.length>0)
							{
								//this.addProxyBtnClickEvent(this.recommendedProxyTemplate)
								this.ModalRef = this.bsModalService.show(this.recommendedProxyTemplate)
							}
							else
							{
								this.showMsg = true
								this.addProxyBtnClickEvent(this.addProxyTemplate,true)
							}
						},
					error => {
							this.alertService.clear()
							this.alertService.error('Error occured while retrieving data')
							});  
				} 
			},
			error => {
				//console.log(error)
				this.alertService.clear()
				this.alertService.error('Error occured while retrieving data')
				});  
	  } 
   
} 

searchSymbolBtnClickEvent()
{
	//clear previous selections
	this.searchResultForm = this.formBuilder.group({
		searchresults: new FormControl(null) 
		});   

	this.alertService.clear()
	this.ldService.gettiingoutilitysearch(this.riskGraphForm.controls.tickerSymbol.value, this.loggedAgent).subscribe(data => 
		{ 
			this.TiingoTickerInfo = data   
			if (this.TiingoTickerInfo.length>0)
			{ 
				this.ModalRef = this.bsModalService.show(this.searchResultTemplate, { class: 'modal-lg' })
			}
			else
			{
				this.alertService.clear()
				this.alertService.info('No data found')
			}
			

			// if (this.recommendedproxies.length>0)
			// {
			// 	//this.addProxyBtnClickEvent(this.recommendedProxyTemplate)
			// 	this.ModalRef = this.bsModalService.show(this.recommendedProxyTemplate)
			// }
			// else
			// {
			// 	this.showMsg = true
			// 	this.addProxyBtnClickEvent(this.addProxyTemplate,true)
			// }
		},
	error => {
			this.alertService.clear()
			this.alertService.error('Error occured while retrieving data')
			}); 
}

selectDrawdownBtnClickEvent()
{
	//formatDate(new Date(this.fromDateCustom), 'MM/dd/yyyy', 'en') + "," + formatDate(new Date(this.toDateCustom), 'MM/dd/yyyy', 'en')
	//console.log(this.fromDateCustom + "," + this.toDateCustom)

	if (this.fromDateCustom >= this.toDateCustom  )
	  {  
		  this.alertService.error('Start date should be less than End date.')
		  return false
	  }  

	this.customDrawdowns.push(formatDate(new Date(this.fromDateCustom), 'MM/dd/yyyy', 'en') + " , " + formatDate(new Date(this.toDateCustom), 'MM/dd/yyyy', 'en'))
	
}

saveProxyBtnClickEvent()
{
  this.alertService.clear()
   
   	//Validate ticker Symbol
  	if (this.addProxyForm.controls.tickerSymbol.value == "" || 
		   this.addProxyForm.controls.tickerSymbol.value == null )
	{  
		this.alertService.error('Invalid Ticker Symbol')
		return false
	} 

	if (this.addProxyForm.controls.proxySymbol.value == "" || 
	  this.addProxyForm.controls.proxySymbol.value == null )
	{  
		this.alertService.error('Invalid Proxy Symbol')
		return false
	} 
 

	//Assign values 
	let newProxy : Proxy = new Proxy()
	newProxy.ProxyID =this.commonFunctions.getnewguid() 
	newProxy.Ticker = this.addProxyForm.controls.tickerSymbol.value 
	newProxy.Proxy = this.addProxyForm.controls.proxySymbol.value 
  
	if (this.addProxyForm.controls.comment.value == "" || 
	  this.addProxyForm.controls.comment.value == null )
	{  
		newProxy.Notes = ""
	} 
	else
	{
		newProxy.Notes = this.addProxyForm.controls.comment.value
	}

	
	if (this.loggedAgent.OrganizationID == Constants.FTAOrganizationID) {
		newProxy.IsFTA =  true
	}
	else
	{
		newProxy.IsFTA =  false
	}	
	newProxy.CreatedBy =this.loggedAgent.AgentID
	newProxy.CreatedOn = new Date()



	this.ldService.saveproxy(newProxy, this.loggedAgent).subscribe(data => 
		{ 
			this.alertService.clear()
			//this.alertService.success('Successfully saved') 
 
			// check whether proxy added to previously selected ticker
			let oPrevSelected : Proxy = new Proxy()
			oPrevSelected = this.selectedproxies.find( x=> x.Ticker  === this.currentticker.Ticker) 
			//if (oPrevSelected)
			if (typeof oPrevSelected === 'undefined') 
			{
				let tempSelected : Proxy = new Proxy
				tempSelected.Ticker = newProxy.Ticker  
				tempSelected.Proxy = newProxy.Proxy 
				this.selectedproxies.push(tempSelected) 
			}
			else
			{
				oPrevSelected.Proxy = newProxy.Proxy
			}

			this.currentticker= new Proxy
			this.ModalRef.hide()
		},
	error => {
			this.alertService.clear()
			this.alertService.error('Error occured while retrieving data')
			});  

}

addProxyBtnClickEvent(template : TemplateRef<any>, showmsg : boolean)
{
	this.showMsg = showmsg
	if (this.showMsg == false)
	{ 
		if (this.selectedproxies.length>0)
		{ 
			let oselected : Proxy = new Proxy()
			oselected = this.selectedproxies.find( x=> x.Ticker  === this.riskGraphForm.controls.selectedTickers.value || 
												x.Proxy  === this.riskGraphForm.controls.selectedTickers.value ) 
 
			this.currentticker = new Proxy() 
			this.currentticker.Ticker =   oselected.Ticker 
			this.currentticker.Proxy =   oselected.Proxy 
		}  
	}

	if (typeof this.ModalRef !== 'undefined') 
	{
			this.ModalRef.hide();
	} 
	this.ModalRef = this.bsModalService.show(template)  
}

canceltemplateClickEvent()
{ 
	this.currentticker = new Proxy
	this.ModalRef.hide();
	this.alertService.clear()
}
 

deleteBtnClickEvent()
  { 
	let tempTicker : string = this.riskGraphForm.controls.selectedTickers.value
	this.selectedproxies.splice(this.selectedproxies.findIndex(x=> x.Ticker  === tempTicker), 1)
  }

  deleteDrawdownBtnClickEvent()
  { 
	let tempDrawdown : string = this.riskGraphForm.controls.selectedDrawdowns.value
	const index = this.customDrawdowns.indexOf(tempDrawdown, 0);
		if (index > -1) {
			this.customDrawdowns.splice(index, 1);
		} 
  }

  onFromDateChange(event: any) { 
    this.fromDate = event; 
  }

  onToDateChange(event: any) { 
    this.toDate = event; 
  }

  onFromDateCustomChange(event: any) { 
    this.fromDateCustom = event; 
  }

  onToDateCustomChange(event: any) { 
    this.toDateCustom = event; 
  }

  printReportBtnClickEvent()
  {

	if (this.selectedproxies.length<=0)
	{
		this.alertService.error('Select tickers to generate report.')
		return false
	} 
	
	let tickers : string = ""
	for (var i =0 ; i <=this.selectedproxies.length-1 ; ++i) {
		if (tickers=="")
		{
			tickers = this.selectedproxies[i].Proxy
		}
		else
		{
			tickers =  tickers + "," + this.selectedproxies[i].Proxy
		} 
	} 
	
	let draodowncount : number 
	if ( this.riskGraphForm.controls.noofDropDowns.value <= 0) 
	{
		draodowncount = this.noOfDrawdowns
	}
	else
	{
		draodowncount =this.riskGraphForm.controls.noofDropDowns.value
	}


	let addAuto: boolean
	if (this.riskGraphForm.controls.addAutoGenerated.value == "" || 
		   this.riskGraphForm.controls.addAutoGenerated.value == null )
	{
		addAuto=false
	}
	else
	{
		addAuto=this.riskGraphForm.controls.addAutoGenerated.value
	} 
	 
	 
	let scaleval: number
	if (this.riskGraphForm.controls.scale.value == "" || 
	this.riskGraphForm.controls.scale.value == null || this.riskGraphForm.controls.scale.value == undefined)
	{
		scaleval=this.bLogarithmic
	}
	else
	{
		scaleval=this.riskGraphForm.controls.scale.value
	}
	let riskgraphrequest : RiskGraphRequest = new RiskGraphRequest()
	riskgraphrequest.AgentID = this.loggedAgent.AgentID
	riskgraphrequest.Tickers = tickers
	riskgraphrequest.StartDate = this.fromDate
	riskgraphrequest.EndDate =this.toDate
	riskgraphrequest.DrawdownCount =draodowncount
	riskgraphrequest.CustomDrawdowns = this.customDrawdowns
	riskgraphrequest.bIncludeAutoGenerated = addAuto
	riskgraphrequest.RiskGraphScale = Number(scaleval)
 
	//console.log(tickers.replace(",","-"))
	//console.log( this.commonFunctions.formatDatetime(new Date(), 'yyyy-mm-dd hh:ii:ss'))
 

 
	this.alertService.clear()
	this.alertService.info('Generating PDF report. Please wait.')

	setTimeout(() => { 
		this.ldService.getgeneratedriskgraphpdf(riskgraphrequest, this.loggedAgent).subscribe(data => {  
 
			/*var file = new Blob([data], {type: 'application/pdf'});
			var fileURL = URL.createObjectURL(file);
			window.open(fileURL);*/  

			const blob = new Blob([data], {type: 'application/pdf'}); 
			let a = document.createElement("a"); 
			document.body.appendChild(a);
			let url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = 'RiskGraph_' + tickers.replace(",","-") + "_"+ this.commonFunctions.formatDatetime(new Date(), 'mm-dd-yyyy') +'.pdf'; // gives it a name via an a tag
			a.click();
			window.URL.revokeObjectURL(url);

			this.alertService.clear() 
		},
			error => { 
				this.alertService.clear()
				this.alertService.error('Error occured while generating pdf report')
		}); 
	}, 50);
 
  }

generateReportBtnClickEvent()
  {

	if (this.selectedproxies.length<=0)
	{
		this.alertService.error('Select tickers to generate report.')
		return false
	} 
	
	let tickers : string = ""
	for (var i =0 ; i <=this.selectedproxies.length-1 ; ++i) {
		if (tickers=="")
		{
			tickers = this.selectedproxies[i].Proxy
		}
		else
		{
			tickers =  tickers + "," + this.selectedproxies[i].Proxy
		} 
	} 
	
	let draodowncount : number 
	if ( this.riskGraphForm.controls.noofDropDowns.value <= 0) 
	{
		draodowncount = this.noOfDrawdowns
	}
	else
	{
		draodowncount =this.riskGraphForm.controls.noofDropDowns.value
	}

	let addAuto: boolean
	if (this.riskGraphForm.controls.addAutoGenerated.value == "" || 
		   this.riskGraphForm.controls.addAutoGenerated.value == null )
	{
		addAuto=false
	}
	else
	{
		addAuto=this.riskGraphForm.controls.addAutoGenerated.value
	} 
	let scaleval: number
	if (this.riskGraphForm.controls.scale.value == "" || 
	this.riskGraphForm.controls.scale.value == null || this.riskGraphForm.controls.scale.value == undefined)
	{
		scaleval=this.bLogarithmic
	}
	else
	{
		scaleval=this.riskGraphForm.controls.scale.value
	}
	let riskgraphrequest : RiskGraphRequest = new RiskGraphRequest()
	riskgraphrequest.AgentID = this.loggedAgent.AgentID
	riskgraphrequest.Tickers = tickers
	riskgraphrequest.StartDate = this.fromDate
	riskgraphrequest.EndDate =this.toDate
	riskgraphrequest.DrawdownCount =draodowncount
	riskgraphrequest.CustomDrawdowns = this.customDrawdowns
	riskgraphrequest.bIncludeAutoGenerated=addAuto
	riskgraphrequest.RiskGraphScale = Number(scaleval)
 
	//console.log(tickers.replace(",","-"))
	//console.log( this.commonFunctions.formatDatetime(new Date(), 'yyyy-mm-dd hh:ii:ss'))
 

 
	this.alertService.clear()
	this.alertService.info('Generating report. Please wait.')

	setTimeout(() => { 
		this.ldService.getgeneratedriskgraphpdf(riskgraphrequest, this.loggedAgent).subscribe(data => {  
 
			var file = new Blob([data], {type: 'application/pdf'});
			var fileURL = URL.createObjectURL(file);
			window.open(fileURL); 

			/*const blob = new Blob([data], {type: 'application/pdf'}); 
			let a = document.createElement("a"); 
			document.body.appendChild(a);
			let url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = 'RiskGraph_' + tickers.replace(",","-") + "_"+ this.commonFunctions.formatDatetime(new Date(), 'mm-dd-yyyy') +'.pdf'; // gives it a name via an a tag
			a.click();
			window.URL.revokeObjectURL(url);*/ 

			this.alertService.clear() 
		},
			error => { 
				this.alertService.clear()
				this.alertService.error('Error occured while generating pdf report')
		}); 
	}, 50);
 
  }

  saveReportBtnClickEvent()
  { 

	if (typeof this.reportName === 'undefined' || this.reportName ==="") 
	  {
		this.alertService.error('Please enter report name.')
		return false
	  }
	
	let tickers : string = ""
	for (var i =0 ; i <=this.selectedproxies.length-1 ; ++i) {
		if (tickers=="")
		{
			tickers = this.selectedproxies[i].Proxy
		}
		else
		{
			tickers =  tickers + "," + this.selectedproxies[i].Proxy
		} 
	} 
	
	let draodowncount : number 
	if ( this.riskGraphForm.controls.noofDropDowns.value <= 0) 
	{
		draodowncount = this.noOfDrawdowns
	}
	else
	{
		draodowncount =this.riskGraphForm.controls.noofDropDowns.value
	}

	let addAuto: boolean
	if (this.riskGraphForm.controls.addAutoGenerated.value == "" || 
		   this.riskGraphForm.controls.addAutoGenerated.value == null )
	{
		addAuto=false
	}
	else
	{
		addAuto=this.riskGraphForm.controls.addAutoGenerated.value
	} 
	let scaleval: number
	if (this.riskGraphForm.controls.scale.value == "" || 
	this.riskGraphForm.controls.scale.value == null || this.riskGraphForm.controls.scale.value == undefined)
	{
		scaleval=this.bLogarithmic
	}
	else
	{
		scaleval=this.riskGraphForm.controls.scale.value
	}
	let riskgraphrequest : RiskGraphRequest = new RiskGraphRequest()
	riskgraphrequest.AgentID = this.loggedAgent.AgentID
	riskgraphrequest.Tickers = tickers
	riskgraphrequest.StartDate = this.fromDate
	riskgraphrequest.EndDate =this.toDate
	riskgraphrequest.DrawdownCount =draodowncount
	riskgraphrequest.CustomDrawdowns = this.customDrawdowns
	riskgraphrequest.ReportName = this.reportName
	riskgraphrequest.bIncludeAutoGenerated=addAuto 
	riskgraphrequest.ClientID = localStorage.getItem(LocalStorage.SELECTED_CLIENTID)  
	riskgraphrequest.RiskGraphScale = Number(scaleval)
	//console.log(tickers.replace(",","-"))
	//console.log( this.commonFunctions.formatDatetime(new Date(), 'yyyy-mm-dd hh:ii:ss'))
 

 
	this.alertService.clear()
	this.alertService.info('Saving report. Please wait.')

	setTimeout(() => { 
		this.ldService.saveriskgraph(riskgraphrequest, this.loggedAgent).subscribe(data => {  
 
			this.alertService.clear() 
			this.alertService.success('Successfully saved.')
			this.reportName=""
			this.ModalRef.hide()	
		},
			error => { 
				this.alertService.clear()
				this.alertService.error('Error occured while saving report')
		}); 
	}, 50);
 
  }

  openReportNamePopup()
  {
	this.alertService.clear()
	if (this.selectedproxies.length<=0)
	{
		this.alertService.error('Select tickers to save report.')
		return false
	} 

	this.ModalRef = this.bsModalService.show(this.saveReportTemplate)
  }

cancelBtnClickEvent()
  {
	this.recommendedproxies = []
	this.selectedproxies  = []
	this.currentticker = new Proxy()  
	this.fromDate  = new Date("2000/01/01")
	this.toDate  = new Date()
	this.noOfDrawdowns  = 2
	this.fromDateCustom  = new Date("2000/01/01")
	this.toDateCustom  = new Date()
	this.customDrawdowns = []
	this.reportName=""
	this.bAddAutoGenerated = 0
	this.bLogarithmic = 2
	this.alertService.clear()
  }

openModal(template : TemplateRef<any>)
  {
	this.ModalRef = this.bsModalService.show(template)
  }
 

selectProxyBtnClickEvent()
	{ 
		let tempSelected : Proxy = new Proxy
		tempSelected.Ticker = this.currentticker.Ticker
		tempSelected.Proxy = this.recommendedProxyForm.controls.recommendedTickers.value 
		this.selectedproxies.push(tempSelected) 
		this.currentticker = new Proxy 
		this.ModalRef.hide(); 
	}

selectResultBtnClickEvent()
	{   

	if (this.searchResultForm.controls.searchresults.value === null) 
	{
		this.alertService.clear()
		this.alertService.error('Invalid ticker')
		return
	} 
 
		let tempSelected : Proxy = new Proxy
		tempSelected.Ticker = this.searchResultForm.controls.searchresults.value  
		tempSelected.Proxy = this.searchResultForm.controls.searchresults.value  
		this.selectedproxies.push(tempSelected) 
		this.currentticker = new Proxy
		this.ModalRef.hide(); 
		this.alertService.clear()
	}

TickerToUppercase(event: any) { 
	if (typeof event !== 'undefined') 
		{
			this.currentticker.Ticker = event.toString().toUpperCase(); 
		}
	}

ProxyToUppercase(event: any) { 
	if (typeof event !== 'undefined') 
		{
			this.currentticker.Proxy = event.toString().toUpperCase(); 
		}
	}

	exitBtnClickEvent()
	{
	  localStorage.setItem(LocalStorage.SELECTED_CLIENTID, "");
	  //localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(new Client));
	  localStorage.removeItem(LocalStorage.CURRENT_CLIENT);
	  this.router.navigateByUrl('landing'); 
	}

	//load the superuser dashboard page
	public navigateSuperUserDashboard()
	{
		this.router.navigateByUrl('superuserdashboard'); 
	}
}

